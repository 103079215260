import React, { FC, useEffect, useState, useRef } from 'react';
import '../../../CSS/settings.css'
import '../../../CSS/textfield.css'
import '../../../CSS/purpleBtn.css'
import { TextField, Select, Alert, Snackbar, OutlinedInput, MenuItem } from '@mui/material';
import EditIcon from "../../../IMAGE/editIcon.svg";
import SettingsIcon from '@mui/icons-material/Settings';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CreateProfileIcon from '../../../IMAGE/createProfile.svg';
import styled from '@emotion/styled';
import axios from "axios";
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { setAdvertiserId, setAuthToken, setCurrencySymbol, setMasterCountryArray, setSessionId } from '../../../REDUX/Actions/userActionIndex';
import { RootState } from '../../../REDUX/Reducers/rootReducer';
import { apiURL, secretKey } from '../../../Constant';
import BackCustom from '../../custom/BackCustom';
import { PurpleCustomButton } from '../../custom/PurpleButton';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input'
import { isValidPhoneNumber } from 'react-phone-number-input/mobile'
import "react-phone-input-2/lib/style.css";
const CryptoJS = require('crypto-js')

interface SettingsScreenProps {
    currencySymbol: any;
    authToken: any;
    sessionId: any;
    advertiserId: any;
    masterCountryArray: any;
    type?: string;
    modalView?: boolean
}

const CustomTextField = styled(TextField)({
    width: "100%",
    marginBottom: 10,
    "& .MuiOutlinedInput-root": {
        borderRadius: "2px",
        height: 40,
        paddingTop: 0,
        paddingLeft: 5,
        paddingBottom: 0,
        "& fieldset": {
            border: '1.4px solid rgba(51, 51, 51, 0.3)',
        },
        "&.Mui-focused fieldset": {
            border: '1.4px solid rgba(51, 51, 51, 0.4)',
        },
    },
    '&.MuiTextField-root input': {
        border: 'none',
        outline: 'none',
        paddingLeft: 0,
        paddingTop: 0,
        paddingBottom: 0,
    },
    "& .MuiFormHelperText-root": { color: 'red', paddingLeft: 0, marginBottom: "5px" },
});

const CustomMobileField = styled('input')({
    width: "100%",
    height: 35,
    border: '1.4px solid rgba(51, 51, 51, 0.3)',
    borderRadius: "2px",
    '&:focus': {
        border: "1.4px solid rgba(51, 51, 51, 1)",
        outline: "none"
    }
});

const CustomSelect = styled(Select)({
    width: '100%',
    height: 40,
    borderRadius: "2px",
    marginBottom: 10,
    paddingLeft: 5,
    '& MuiSelect': {
        height: 40,
        paddingTop: 0,
        paddingBottom: 0,
        border: 'none',
        outline: 'none',
    },
    '& fieldset': {
        padding: 0,
        border: '1.4px solid rgba(51, 51, 51, 0.3)',
        outline: 'none',
    },
    '&.Mui-focused fieldset': {
        border: '1.4px solid rgba(51, 51, 51, 0.3)',
        outline: 'none',
    },
    '& .MuiSelect-select:focus': {
        background: 'transparent',
        border: 'none',
        outline: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: '1.4px solid rgba(51, 51, 51, 0.3)',
        outline: 'none',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1.4px solid rgba(51, 51, 51, 0.3)',
        outline: 'none',
    },
});

const CreateAdvertiserScreen: FC<SettingsScreenProps> = (props) => {

    const { currencySymbol, authToken, sessionId, advertiserId, masterCountryArray, modalView = false } = props

    const navigate = useNavigate()
    const location = useLocation()
    const inputRef = useRef<any>(null)

    const [openSession, setOpenSession] = React.useState(false);

    const handleSessionClose = () => {
        setOpenSession(false);
    };

    const minImageSize = 70;
    const maxImageSize = 150;

    const [formState, setFormState] = useState<any>({
        name: "",
        mobile: "",
        email: "",
        taxId: "",
        country: "",
        userType: "",
        orgName: ""
    });

    const [modelOpen, setModelOpen] = useState<string>("")
    const [errorMsgSnackBar, setErrorMsgSnackBar] = useState<any>("")
    const [isProfileUpdate, setProfileUpdate] = useState<boolean>(false)
    const [previousState, setPreviousState] = useState<any>({});
    const [image, setImage] = useState<any | null>("")
    const [nameErr, setNameErr] = useState("");
    const [orgErr, setOrgErr] = useState("");
    const [userTypeErr, setUserTypeErr] = useState("");
    const [mobileErr, setMobileErr] = useState("");
    const [emailErr, setEmailErr] = useState("");
    const [countryErr, setCountryErr] = useState("");
    const [successOpen, setSuccessOpen] = React.useState(false);
    const [createdOpen, setCreatedOpen] = React.useState(false);
    const [errorOpen, setErrorOpen] = React.useState(false);
    const [infoOpen, setInfoOpen] = React.useState(false);
    const [id, setId] = useState<string>("");

    const isValidMobileNumber = (value: string): boolean => {
        return (/^(\+\d{1,3}[- ]?)?\d{10}$/.test(value))
    };

    const getUserDetails = async (adId: any) => {
        try {
            let jsonParams = {
                advertiserId: adId,
            };
            const encryptAdvertiserID = CryptoJS.AES.encrypt(JSON.stringify(advertiserId), secretKey).toString()

            let headers = {
                'Content-Type': 'application/json',
                'advertiserid': encryptAdvertiserID,
                'authtoken': authToken,
                'sessionid': sessionId
            };
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(jsonParams), secretKey).toString();

            const response = await axios.post(`${apiURL}/api/user/getUser`, { params: encryptedData }, { headers: headers });
            if (response.data.status == true) {
                const resData = response.data.data;
                const bytes = CryptoJS.AES.decrypt(resData, secretKey);
                const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
                const parsedData = JSON.parse(decryptedData);
                setId(parsedData['id'])
                setFormState({
                    name: parsedData['Name'],
                    email: parsedData['Email'],
                    mobile: parsedData['MobileNumber'] == "null" ? "" : parsedData['MobileNumber'],
                    taxId: parsedData['TaxID'] == 'null' ? "" : parsedData['TaxID'],
                    country: parsedData['Country'],
                    userType: parsedData['UserType'],
                    orgName: parsedData['OrganizationName']
                });

                setPreviousState({
                    name: parsedData['Name'],
                    email: parsedData['Email'],
                    mobile: parsedData['MobileNumber'] == "null" ? "" : parsedData['MobileNumber'],
                    taxId: parsedData['TaxID'] == 'null' ? "" : parsedData['TaxID'],
                    country: parsedData['Country'],
                    userType: parsedData['UserType'],
                    orgName: parsedData['OrganizationName']
                })

                if (parsedData['ProfilePicture'] == null) {
                    setImage("")
                } else {
                    const uint8Array = new Uint8Array(parsedData['ProfilePicture']['data']);
                    const blob = new Blob([uint8Array]);
                    const dataUrl = URL.createObjectURL(blob);
                    setImage(dataUrl);
                    const blob2 = new Blob([Buffer.from(parsedData['ProfilePicture']['data'])], { type: 'application/octet-stream' });
                    setSelectedFile(blob2)
                }
            } else if (response.data.statuscode == 401) {
                setOpenSession(true)
                setTimeout(() => { navigate("/") }, 2000)
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (location) {
            if (location.state) {
                if (location.state.typeOpen == 'edit') {
                    setModelOpen(location.state.typeOpen)
                    getUserDetails(location.state.advertiserId)
                }
            }
        }
    }, [])

    const userType = [
        {
            name: "Brand",
            value: "brand",
        },
        {
            name: "Agency",
            value: "agency",
        },
        {
            name: "Admin",
            value: "admin",
        }
    ]

    const handleChange = (e: any) => {
        let value = e.target.value;
        switch (e.target.name) {
            case "name":
                setNameErr("");
                setFormState((prevFilters: any) => ({
                    ...prevFilters,
                    name: value,
                }));
                if (value == "" || value == null) {
                    setNameErr("Invalid full name.");
                } else {
                    setFormState((prevFilters: any) => ({
                        ...prevFilters,
                        name: value,
                    }));
                }
                break;
            case "orgName":
                setOrgErr("");
                setFormState((prevFilters: any) => ({
                    ...prevFilters,
                    orgName: value,
                }));
                if (value == "" || value == null) {
                    setOrgErr("Invalid Organisation Name.");
                } else {
                    setFormState((prevFilters: any) => ({
                        ...prevFilters,
                        orgName: value,
                    }));
                }
                break;
            case "userType":
                setUserTypeErr("");
                setFormState((prevFilters: any) => ({
                    ...prevFilters,
                    userType: value
                }));
                if (value == "" || value == null) {
                    setUserTypeErr("Invalid UserType.");
                } else {
                    setFormState((prevFilters: any) => ({
                        ...prevFilters,
                        userType: value
                    }));
                }
                break;
            case "mobile":
                setMobileErr("");
                setFormState((prevFilters: any) => ({
                    ...prevFilters,
                    mobile: value,
                }));
                if (!isValidMobileNumber(value) || value == "" || value == null) {
                    setMobileErr("Invalid Phone number.");
                } else {
                    setFormState((prevFilters: any) => ({
                        ...prevFilters,
                        mobile: value,
                    }));
                }
                break;
            case "email":
                setEmailErr("");
                setFormState((prevFilters: any) => ({
                    ...prevFilters,
                    email: value,
                }));
                const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

                if (!(emailRegex.test(value)) || value == "" || value == null) {
                    setEmailErr("Invalid Email id.");
                } else {
                    setFormState((prevFilters: any) => ({
                        ...prevFilters,
                        email: value,
                    }));
                }
                break;
            case "countryName":
                setCountryErr("");
                if (value == "" || value == null) {
                    setCountryErr("Invalid Country.");
                } else {
                    setFormState((prevFilters: any) => ({
                        ...prevFilters,
                        country: value,
                    }));
                }

                break;
            default:
                break;
        }
    }

    const [selectedFile, setSelectedFile] = useState<any>();

    const OnClickUpdate = async () => {
        if (nameErr != "" || mobileErr != "" || emailErr != "" || orgErr != "") {
            setErrorMsgSnackBar("Invalid Details")
            setErrorOpen(true)
        } else if (previousState.name == formState.name &&
            previousState.email == formState.email &&
            previousState.mobile == formState.mobile &&
            previousState.country == formState.country &&
            previousState.taxId == formState.taxId &&
            previousState.userType == formState.userType &&
            previousState.orgName == formState.orgName &&
            isProfileUpdate == false) {
            setInfoOpen(true)
        } else if (formState.email == "") {
            setErrorMsgSnackBar("Invalid Details")
            setErrorOpen(true)
        }
        else {
            try {
                var bodyFormData = new FormData()
                bodyFormData.append("MobileNumber", formState.mobile)
                bodyFormData.append("Email", formState.email)
                bodyFormData.append("OrganizationName", formState.orgName)
                bodyFormData.append("Name", formState.name)
                bodyFormData.append("Country", formState.country)
                bodyFormData.append("profilePicture", selectedFile)
                bodyFormData.append("Advertiser_Id", location.state.advertiserId)
                bodyFormData.append("TaxID", formState.taxId)
                bodyFormData.append("UserType", formState.userType)

                const encryptAdvertiserID = CryptoJS.AES.encrypt(JSON.stringify(advertiserId), secretKey).toString()

                let headers = {
                    'advertiserid': encryptAdvertiserID,
                    'authtoken': authToken,
                    'sessionid': sessionId
                };
                const response = await axios.post(`${apiURL}/api/user/editUser`, bodyFormData, { headers: headers });
                if (response.data.status == true) {
                    setSuccessOpen(true)
                    setTimeout(() => { navigate("/home/admin/advertisers") }, 2000)
                } else if (response.data.statuscode == 401) {
                    setOpenSession(true)
                    setTimeout(() => { navigate("/") }, 2000)
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const createNewAdvertiser = async () => {
        if (nameErr != "" || mobileErr != "" || emailErr != "" || userTypeErr != "" || countryErr != "" || orgErr != "") {
            setErrorMsgSnackBar("Invalid Details")
            setErrorOpen(true)
        } else if (formState.name == "" ||
            formState.email == "" ||
            formState.mobile == "" ||
            formState.country == "" ||
            formState.userType == "" ||
            formState.orgName == "") {
            setErrorMsgSnackBar("Please fill other details.")
            setErrorOpen(true)
        } else {
            try {
                let jsonParams = {
                    Email: formState.email.toLowerCase(),
                    Name: formState.name,
                    Country: formState.country,
                    UserType: formState.userType,
                    MobileNumber: formState.mobile,
                    OrganizationName: formState.orgName
                };

                const encryptedData = CryptoJS.AES.encrypt(
                    JSON.stringify(jsonParams),
                    secretKey
                ).toString();
                const params = {
                    params: encryptedData,
                };

                const response = await axios.post(`${apiURL}/api/registorUser`, params);
                if (response.data.status == true) {
                    if (response.data.statusCode == 201) {
                        setErrorMsgSnackBar("User already exists!")
                        setErrorOpen(true)
                    } else {
                        // const bytes = CryptoJS.AES.decrypt(response.data.data, secretKey);
                        // const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
                        // const parsedData = JSON.parse(decryptedData);
                        setCreatedOpen(true)
                        setTimeout(() => { navigate("/home/admin/advertisers") }, 2000)
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleFileChange = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            const fileSizeKB = file.size / 1024;

            if (fileSizeKB <= maxImageSize && fileSizeKB >= minImageSize) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    setImage(e.target == null ? "" : e.target.result);
                };
                reader.readAsDataURL(file);
                setSelectedFile(file);
                setProfileUpdate(true)
            } else {
                setErrorMsgSnackBar("Invalid Image Size")
                setErrorOpen(true)
            }
        }
    };

    const handleOnChange = (e: any) => {
        // setMobile("")
        if (e != undefined) {
            setMobileErr("");
            let phoneNumber: any = parsePhoneNumber(e)
            if (phoneNumber) {
                if (isValidPhoneNumber(phoneNumber.number)) {
                    setFormState((prevFilters: any) => ({
                        ...prevFilters,
                        mobile: phoneNumber.number,
                    }));
                } else {
                    setMobileErr("Invalid Mobile Number.");
                }
            } else {
                setMobileErr("Invalid Mobile Number.");
            }
        }
    };

    return (
        <div>
            {!modalView && <div>
                <BackCustom onClick={() => window.history.back()} />
                <div className='heading'>Brands {">"} Create New Brand / Agency</div>
            </div>}
            <div className='white-container'>
                <div className='sub-heading'>Add Profile Picture</div>
                <div className='profile-edit'>
                    {
                        image != "" ? <img src={image} height="80px" width="80px" style={{ borderRadius: "4px" }} /> : <div>
                            <img src={CreateProfileIcon} height="80px" width="80px" style={{ borderRadius: "4px" }} />
                        </div>
                    }
                    <div>
                        <input
                            type="file"
                            id="file-input"
                            name="file-input"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                            ref={inputRef}
                            accept=".png, .jpg, .jpeg"
                        />
                        <label id="file-input-label" htmlFor="file-input" className='choose-file-button choose-file-advertiser'>Choose a file</label>
                        <div className='file-size-text'>Acceptable format jpg, png only <br />
                            Max file size is 500 kb and min size 70 kb
                        </div>
                    </div>
                </div>
                <div className='account-details'>
                    <div className='acc-details-column'>
                        <div>
                            {/* <div>
                                <div className='sub-heading'>
                                    <div>User Name</div>
                                </div>
                                <div>

                                    <div>
                                        <CustomTextField
                                            name='name'
                                            value={formState.name}
                                            onChange={handleChange}
                                            helperText={nameErr}

                                        />
                                        {nameEnable ? <button className='field-update-btn' onClick={() => {
                                            if (formState.name != "" && nameErr == "") {
                                                // setName(name)
                                                // setFormState((prevState) => ({
                                                //     ...prevState,
                                                //     name: value,
                                                //   }));
                                                setNameEnabled(false)
                                                OnClickUpdate()
                                            } else {
                                                setNameErr("Invalid Username")
                                            }
                                        }}>Update</button> : <div></div>}
                                    </div>
                                </div>
                            </div> */}
                            <div>
                                <div>
                                    <div className='sub-heading'>
                                        <div> Account Manager Name</div>
                                    </div>
                                    <div >

                                        <CustomTextField
                                            name='name'
                                            value={formState.name}
                                            onChange={handleChange}
                                            helperText={nameErr}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='sub-heading'>
                                    <div>Country</div>
                                </div>
                                <div >
                                    <CustomSelect
                                        SelectDisplayProps={{ style: { padding: 0 } }}
                                        value={formState.country}
                                        onChange={handleChange}
                                        IconComponent={ExpandMoreIcon}
                                        name='countryName'
                                        disabled={modelOpen == 'edit' ? true : false}
                                    >
                                        {masterCountryArray != null && masterCountryArray.map((item: any) => (
                                            <MenuItem key={item.id} value={item.Country}>{item.Country}</MenuItem>
                                        ))}
                                    </CustomSelect>
                                </div>
                            </div>

                            <div>
                                <div className='sub-heading'>
                                    <div>Brand ID</div>
                                </div>
                                <div >
                                    <div>
                                        <CustomTextField
                                            name='advertiserID'
                                            disabled={true}
                                            placeholder={modelOpen == "edit" ? id : "(Automatic)"}
                                            // value={formState.email}
                                            type='text'
                                        // onChange={handleChange}
                                        // helperText={emailErr}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='acc-details-column'>
                        <div>
                            <div>
                                <div className='sub-heading'>
                                    <div>Organisation Name</div>
                                </div>
                                <div >
                                    <div>
                                        <CustomTextField
                                            name='orgName'
                                            value={formState.orgName}
                                            type='text'
                                            onChange={handleChange}
                                            helperText={orgErr}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className='sub-heading'>
                                    <div>Adding account for:</div>
                                </div>
                                <div >
                                    <CustomSelect
                                        SelectDisplayProps={{ style: { padding: 0 } }}
                                        value={formState.userType}
                                        onChange={handleChange}
                                        IconComponent={ExpandMoreIcon}
                                        name='userType'
                                    >
                                        {userType != null && userType.map((item: any) => (
                                            <MenuItem key={item.value} value={item.value} >{item.name}</MenuItem>
                                        ))}
                                    </CustomSelect>
                                </div>
                            </div>

                            <div>
                                <div className='sub-heading'>
                                    <div>Brand Email ID</div>
                                </div>
                                <div >
                                    <div>
                                        <CustomTextField
                                            name='email'
                                            value={formState.email}
                                            type='email'
                                            onChange={handleChange}
                                            helperText={emailErr}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='acc-details-column'>
                        <div>
                            <div className='sub-heading'>
                                <div>Contact Number</div>
                            </div>
                            <div >
                                {/* <CustomTextField
                                    name='mobile'
                                    type='tel'
                                    value={formState.mobile}
                                    // defaultValue={mobile}
                                    onChange={handleChange}
                                    helperText={mobileErr}
                                /> */}
                                <PhoneInput
                                    limitMaxLength={true}
                                    focusInputOnCountrySelection
                                    // initialValueFormat="national"
                                    international
                                    countryCallingCodeEditable={false}
                                    defaultCountry="IN"
                                    value={formState.mobile}
                                    inputProps={{
                                        name: 'phone',
                                        required: true,
                                        autoFocus: true,
                                    }}
                                    onChange={handleOnChange}
                                    style={{
                                        '&:active': {
                                            border: "none",
                                            outline: "none"
                                        }
                                    }}
                                    inputComponent={CustomMobileField}
                                />
                                {mobileErr != "" && mobileErr != null && (
                                    <div style={{ width: "80%", margin: 0, padding: 0 }}>
                                        <p style={{ color: 'red', fontSize: 12, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', letterSpacing: " 0.03333em", margin: 10 }}>{mobileErr}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ float: "right", display: "flex", alignItems: "flex-end", flexDirection: "column" }}>
                {/* <button
                    className='btn-style'
                    children="Done"
                    onClick={OnClickUpdate}
                ></button> */}
                <div className='sub-heading'>{modelOpen == "edit" ? "Note: The Brand/Agency will be notified of the changes made (if any)." : "Note: Login credentials will be emailed to the Brand/Agency."}</div>
                <PurpleCustomButton children={modelOpen == "edit" ? "Update Details" : "Create Advertiser"} disable={true} onClick={modelOpen == 'edit' ? OnClickUpdate : createNewAdvertiser} />
            </div>
            <Snackbar open={errorOpen} autoHideDuration={6000} onClose={() => setErrorOpen(false)} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                <Alert onClose={() => setErrorOpen(false)} severity="error" sx={{ width: '100%' }}>
                    {errorMsgSnackBar}
                </Alert>
            </Snackbar>
            <Snackbar open={infoOpen} autoHideDuration={6000} onClose={() => setInfoOpen(false)} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                <Alert onClose={() => setInfoOpen(false)} severity="info" sx={{ width: '100%' }}>
                    Details same as previous!
                </Alert>
            </Snackbar>
            <Snackbar open={successOpen} autoHideDuration={6000} onClose={() => setSuccessOpen(false)} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                <Alert onClose={() => setSuccessOpen(false)} severity="success" sx={{ width: '100%' }} >
                    Account Details Updated!
                </Alert>
            </Snackbar>
            <Snackbar open={createdOpen} autoHideDuration={6000} onClose={() => setCreatedOpen(false)} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                <Alert onClose={() => setCreatedOpen(false)} severity="success" sx={{ width: '100%' }} >
                    New {formState.userType} created!
                </Alert>
            </Snackbar>
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    currencySymbol: state.user.currencySymbol,
    authToken: state.user.authToken,
    sessionId: state.user.sessionId,
    advertiserId: state.user.advertiserId,
    masterCountryArray: state.user.masterCountryArray,
});

const mapDispatchToProps = {
    setCurrencySymbol,
    setAdvertiserId,
    setAuthToken,
    setSessionId,
    setMasterCountryArray,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAdvertiserScreen);