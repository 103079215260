import React, { useState, FC, useEffect, ChangeEvent } from "react";
import leftSideBG from "../../IMAGE/leftSideBG.svg";
import rightSideBG from "../../IMAGE/rightSideBG.svg";
import logo from "../../IMAGE/logo.svg";
import "../../CSS/SignIn.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BiSolidErrorCircle } from "react-icons/bi";
import { useSearchParams } from "react-router-dom";
import { getMasterdataFunc } from "../MasterDataFile";
import { apiURL, customCheckBoxColor, secretKey } from "../../Constant";
import { resetState, setAdvertiserId, setAuthToken, setMasterCountryArray, setMasterCouponArray, setMasterLanguageArray, setMasterMMPArray, setMasterMacrosArray, setMasterPublisherArray, setMasterTargetingTypesArray, setRememberMeCheck, setRememberMeEmail, setRememberMePassword, setSelectedAdvertiserId, setSessionId, setUserEmail } from "../../REDUX/Actions/userActionIndex";
import { RootState } from "../../REDUX/Reducers/rootReducer";
import { connect, useDispatch } from "react-redux";
import TagManager from "react-gtm-module";
const CryptoJS = require('crypto-js')

interface SignInProps {
  authToken: any;
  sessionId: any;
  advertiserId: any;
  userEmail: any;
  rememberMeCheck: any;
  rememberMeEmail: any;
  rememberMePassword: any;
  masterCountryArray: any,
  masterLanguageArray: any,
  masterMacrosArray: any,
  mastercouponArray: any,
  masterMMPArray: any,
  masterPublisherArray: any,
  mastertargetingTypesArray: any,
  selectedAdvertiserId: any
}

const SignIn: FC<SignInProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { authToken, sessionId, advertiserId, userEmail, rememberMeEmail, rememberMePassword, rememberMeCheck, masterCountryArray, masterLanguageArray, masterMMPArray, masterMacrosArray, masterPublisherArray, mastercouponArray, mastertargetingTypesArray, selectedAdvertiserId } = props

  const [error, setError] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const logoutShow = searchParams.get("logout");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // const disableBackButton = () => {
  //   const disableBack = (e: Event) => {
  //     e.preventDefault();
  //     window.history.forward();
  //   };
  //   window.addEventListener('popstate', disableBack);
  //   return () => {
  //     window.removeEventListener('popstate', disableBack);
  //   };
  // };

  useEffect(() => {
    const storedEmail = rememberMeEmail;
    const storedPassword = rememberMePassword;
    const storedRememberMe = rememberMeCheck === 'true'

    if (storedRememberMe && storedEmail && storedPassword) {
      setEmail(storedEmail.toLowerCase());
      setPassword(storedPassword);
      setRememberMe(true);
    }
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    switch (e.target.name) {
      case "email":
        setError("");
        const emailRegex =
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (
          !emailRegex.test(value) ||
          value == "" ||
          value == null ||
          value.length == 0
        ) {
          setError("Invalid Email or Password");
        } else {
          setEmail(value.toLowerCase());
        }
        break;
      case "password":
        setError("");
        if (value == "" || value == null || value.length == 0) {
          setError("Invalid Email or Password");
        } else {
          setPassword(value);
        }
        break;
      default:
        break;
    }
  };

  const nextPage = async (event: React.FormEvent) => {
    event.preventDefault();
    if (email == "" || password == "") {
      setError("Invalid Email or Password");
    } else {
      try {
        let jsonParams = {
          Email: email.toLowerCase(),
          Password: password,
        };
        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(jsonParams),
          secretKey
        ).toString();
        const params = {
          params: encryptedData
        }
        const response = await axios.post(`${apiURL}/api/login`, params);

        console.log(response)

        if (response.data.status == true) {
          if (response.data.statuscode == 200) {
            const bytes = CryptoJS.AES.decrypt(response.data.data, secretKey);
            const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
            const parsedData = JSON.parse(decryptedData);
            localStorage.setItem("sessionID", parsedData.sessionID);
            localStorage.setItem("authToken", parsedData.secerateKey);
            localStorage.setItem(
              "advertiserID",
              parsedData.loggedInUserID
            );
            localStorage.setItem("emailID", email.toLowerCase());

            dispatch(setSessionId(parsedData.sessionID))
            dispatch(setAuthToken(parsedData.secerateKey))
            dispatch(setAdvertiserId(parsedData.loggedInUserID))
            dispatch(setUserEmail(email.toLowerCase()))
            dispatch(setAdvertiserId(parsedData.loggedInUserID))
            dispatch(setSelectedAdvertiserId(parsedData.loggedInUserID))

            if (rememberMe) {
              dispatch(setRememberMeEmail(email.toLowerCase()))
              dispatch(setRememberMePassword(password))
            } else {
              dispatch(setRememberMeEmail(""))
              dispatch(setRememberMePassword(""))
            }
            dispatch(setRememberMeCheck(rememberMe.toString()))
            getMasterdataFunc();
            getMasterdata()
            TagManager.dataLayer({
              dataLayer: {
                event: 'signIn',
              }
            })
            navigate("/home");
          }
        } else if (response.data.status == false) {
          if (response.data.statuscode == 200) {
            setError(response.data.successMsg);
          } else if(response.data.statuscode == 401) {
            setError("Please click on Forgot Password to continue!");
          }
        } else {
          setError("Invalid login details!");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    localStorage.setItem("startDate", "")
    localStorage.setItem("endDate", "")
    dispatch(setSessionId(""))
    // getMasterdataFunc();
    // getMasterdata()
  }, []);

  const onClickLogout = async () => {
    localStorage.setItem("startDate", "")
    localStorage.setItem("endDate", "")
    dispatch(setSessionId(""))
    const secretKey = "Adserving123";
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(advertiserId),
      secretKey
    ).toString();
    let headers = {
      "Content-Type": "application/json",
      advertiserid: encryptedData,
      authtoken: authToken,
      sessionid: sessionId,
    };
    await axios
      .get(`${apiURL}/api/logout?advertiserId=${advertiserId}`, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status == true) {
          localStorage.clear();
          dispatch(resetState());

          if (rememberMe) {
            dispatch(setRememberMeEmail(email.toLowerCase()))
            dispatch(setRememberMePassword(password))

          } else {
            dispatch(setRememberMeEmail(""))
            dispatch(setRememberMePassword(""))
          }
        }
      })
      .catch((e) => console.log(e));
  };

  const getMasterdata = async () => {
    // const url = "http://localhost:10056";
    await axios.get(`${apiURL}/api/data/getMasterData`,
    )
      .then(res => {
        const resData = res.data.data;
        const bytes = CryptoJS.AES.decrypt(resData, secretKey);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        const parsedData = JSON.parse(decryptedData);
        if (res.data.status == true) {
          dispatch(setMasterCountryArray(parsedData['country']))
          dispatch(setMasterLanguageArray(JSON.stringify(parsedData['language'])))
          dispatch(setMasterPublisherArray(JSON.stringify(parsedData['publisher'])))
          dispatch(setMasterMMPArray(JSON.stringify(parsedData['MMP'])))
          dispatch(setMasterCouponArray(JSON.stringify(parsedData['coupons'])))
          dispatch(setMasterTargetingTypesArray(JSON.stringify(parsedData['targetingTypes'])))
          dispatch(setMasterMacrosArray(JSON.stringify(parsedData['macros'])))
        }
      })
      .catch((e) => console.log(e))
  }

  useEffect(() => {
    // disableBackButton(); 
    if (logoutShow == "true") {
      onClickLogout();
    }
  }, []);

  return (
    <div className="signIn-main">
      <div className="left-img-div">
        <img src={leftSideBG} alt="leftSide" />
      </div>
      <div className="details-box center-div">
        <img className="admattic-logo" src={logo} alt="logo" />
        <form onSubmit={nextPage} id="sign-in-form" name="sign-in-form">
          <div className="mid-box">
            <h2>SIGN IN</h2>
            {error != "" && error != null && (
              <div className="error-box">
                <BiSolidErrorCircle className="error-icon" />
                <p>{error}</p>
              </div>
            )}
            <div>
              <div className="email-box">
                <label htmlFor="email"> Email</label>
                <input
                  type="text"
                  name="email"
                  defaultValue={email}
                  onChange={handleChange}
                />
              </div>
              <div className="pwd-box">
                <label htmlFor="password">Password</label>
                <div className="hide-button">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    defaultValue={password}
                    onChange={handleChange}
                  />
                  <button
                    type="button"
                    className="eye-button"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <AiFillEyeInvisible className="eye-icon" />
                    ) : (
                      <AiFillEye className="eye-icon" />
                    )}
                  </button>
                </div>
              </div>
              <div style={{ position: "relative", marginTop: "50px" }}></div>
            </div>
            <div className="chk-box">
              <div className="chk-div">
                <Checkbox
                  size="small"
                  checked={rememberMe}
                  className="chk"
                  style={{ color: customCheckBoxColor }}
                  onChange={() => setRememberMe(!rememberMe)}
                />
                <p>Remember Me ?</p>
              </div>
              <a href="/forgotPage">Forgot Password ?</a>
            </div>
            <button className="signIn-btn" type="submit">
              Sign In
            </button>
          </div>
        </form>
        <div className="signup-box">
          <p>New to Admattic?&nbsp;&nbsp;</p>
          <a href="/signUp">Sign Up</a>
        </div>
        {logoutShow == "true" ? (
          <div className="logout-box">You have successful logged out</div>
        ) : null}
      </div>
      <div className="right-img-div">
        <img src={rightSideBG} alt="rightSide" />
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  authToken: state.user.authToken,
  sessionId: state.user.sessionId,
  advertiserId: state.user.advertiserId,
  userEmail: state.user.userEmail,
  rememberMeCheck: state.user.rememberMeCheck,
  rememberMeEmail: state.user.rememberMeEmail,
  rememberMePassword: state.user.rememberMePassword,
  masterCountryArray: state.user.masterCountryArray,
  masterLanguageArray: state.user.masterLanguageArray,
  masterMacrosArray: state.user.masterMacrosArray,
  mastercouponArray: state.user.mastercouponArray,
  masterMMPArray: state.user.masterMMPArray,
  masterPublisherArray: state.user.masterPublisherArray,
  mastertargetingTypesArray: state.user.mastertargetingTypesArray,
  selectedAdvertiserId: state.user.selectedAdvertiserId,
});

const mapDispatchToProps = {
  setAdvertiserId,
  setAuthToken,
  setSessionId,
  setUserEmail,
  setRememberMeEmail,
  setRememberMePassword,
  setRememberMeCheck,
  setMasterCountryArray,
  setMasterCouponArray,
  setMasterLanguageArray,
  setMasterMacrosArray,
  setMasterMMPArray,
  setMasterTargetingTypesArray,
  setMasterPublisherArray,
  resetState,
  setSelectedAdvertiserId
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);