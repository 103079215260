// userActions.ts
import {
  LOCATION_CODE,
  BUDGET_ID,
  CAMPAIGN_ID,
  IMAGE_FILE,
  HTML_FILE,
  VIDEO_FILE,
  STATUS,
  APP_IMAGE_URL,
  PACKAGE_NAME,
  SET_USERNAME,
  CAMPAIGN_SUBTYPE,
  APP_PLATFORM,
  APP_NAME,
  CAMPAIGN_NAME,
  CURRENCY_SYMBOL,
  UserActionTypes,
  AUTH_TOKEN,
  SESSION_ID,
  ADVERTISER_ID,
  USER_EMAIL,
  COUNTRY_CODE,
  COUNTRY_CODE_ADVERTISER,
  TOKEN_EVENT,
  IMPRESSION_URL,
  TRACKING_URL,
  ADD_TOKEN_EVENT_OBJECT,
  REMOVE_TOKEN_EVENT_OBJECT,
  UPDATE_TOKEN,
  UPDATE_EVENT,
  HEADLINE,
  DESCRIPTION,
  VIDEO_URL,
  IMAGE_URL,
  HTML_URL,
  TRACKING,
  LANGUAGE,
  LOCATION,
  COST_PER_INSTALL,
  TARGET,
  FOCUS,
  AD_GROUP_NAME,
  LOGO_URL,
  BUDGET,
  TOTAL_BALANCE,
  REMEMBER_ME_EMAIL,
  REMEMBER_ME_PASSWORD,
  REMEMBER_ME_CHECK,
  MASTER_PUBLISHER_ARRAY,
  MASTER_TARGETING_TYPES_ARRAY,
  MASTER_MMP_ARRAY,
  MASTER_MACROS_ARRAY,
  MASTER_LANGUAGE_ARRAY,
  MASTER_COUPON_ARRAY,
  MASTER_COUNTRY_ARRAY,
  IMAGE_URL_ERROR,
  LOGO_URL_ERROR,
  LOCATION_CHECK,
  RESET_STATE,
  DATA_SOURCE,
  SEGMENT_NAME,
  DATA_TYPE,
  DATA_UPLOAD,
  UPLOADED_DATA_URL,
  DATA_SOURCE_ERROR,
  SEGMENT_NAME_ERROR,
  DATA_TYPE_ERROR,
  DATA_UPLOAD_ERROR,
  UPLOADED_DATA_URL_ERROR,
  TRANSACTION_CURRENCY,
  SELECTED_ADVERTISER_ID,
  SELECTED_DATE_RANGE,
  COMPANY_NAME,
  CAMPAIGN_STATUS,
  ADVERTISER_ID_ADMIN,
  PERFORMANCE_REPORT,
  CLICKS,
  IMPRESSIONS,
  CONVERSION,
  INSTALL,
  PAYOUT,
  CTR,
  CPI,
  DAILY_AVG_BUDGET,
  ADVERTISER_NAME,
  COMPANY_IMAGE,
  // CAMPAIGN_STATUS,
  CREDIT_LIMIT,
  USER_TYPE,
  ADVERTISERS_LIST,
  LAST_BILLED_AMOUNT,
  TOTAL_SPENDS_LAST_MONTH,
  CREATED_AT,
  ACTIVATED_AT,
  CAMPAIGN_TABLE_DATA,
  CROPPED_ID,
  ADVERTISERS_SCREEN_TABLE,
  REPORT_SCREEN_TABLE,
  USER_JOURNEY_ADVERTISER,
  USER_JOURNEY_DATA,
  VIDEO_SOURCE_DB,
  VIDEO_ARRAY_DB,
  VIDEO_DURATION_DB,
  VIDEO_NAME_DB,
  IMAGE_LIST_DB,
  IMAGE_ARRAY_DB,
  HEADLINE_ERROR,
  DESCRIPTION_ERROR,
  TEMP_TRANSACTION_CURRENCY,
  PROTECTED_DATA,

} from "./userActions";

export const setHeadlineError = (value: string): UserActionTypes => ({
  type: HEADLINE_ERROR,
  payload: value,
});

export const setDescriptionError = (value: string): UserActionTypes => ({
  type: DESCRIPTION_ERROR,
  payload: value,
});

export const setVideoSource = (value: string): UserActionTypes => ({
  type: VIDEO_SOURCE_DB,
  payload: value,
});

export const setVideoArray = (value: any): UserActionTypes => ({
  type: VIDEO_ARRAY_DB,
  payload: value,
});

export const setVideoDuration = (value: number): UserActionTypes => ({
  type: VIDEO_DURATION_DB,
  payload: value,
});

export const setVideoName = (value: string): UserActionTypes => ({
  type: VIDEO_NAME_DB,
  payload: value,
});

export const setImageList = (files: any): UserActionTypes => {
  //   const fileArray = Array.from(files); // Convert FileList to Array
  // console.log( "fileArray", fileArray );

  return {
    type: IMAGE_LIST_DB,
    payload: files[0],
  };
};

export const setImageArray = (value: any[]): UserActionTypes => ({
  type: IMAGE_ARRAY_DB,
  payload: value,
});

export const setUserJourneyAd = (value: any): UserActionTypes => ({
  type: USER_JOURNEY_ADVERTISER,
  payload: value,
});

export const setUserJourneyData = (value: any): UserActionTypes => ({
  type: USER_JOURNEY_DATA,
  payload: value,
});

export const setId = (value: any): UserActionTypes => ({
  type: CROPPED_ID,
  payload: value,
});

export const setTableData = (value: any): UserActionTypes => ({
  type: CAMPAIGN_TABLE_DATA,
  payload: value,
});

export const setProtectedData = (value:any):  UserActionTypes =>({
  type: PROTECTED_DATA,
  payload: value,
})

export const setCreatedDate = (value: any): UserActionTypes => ({
  type: CREATED_AT,
  payload: value,
});

export const setActivatedDate = (value: any): UserActionTypes => ({
  type: ACTIVATED_AT,
  payload: value,
});

export const setLastBilledAmount = (value: any): UserActionTypes => ({
  type: LAST_BILLED_AMOUNT,
  payload: value,
});

export const setTotalSpendsLastMonth = (value: any): UserActionTypes => ({
  type: TOTAL_SPENDS_LAST_MONTH,
  payload: value,
});

export const setCompanyImage = (value: string): UserActionTypes => ({
  type: COMPANY_IMAGE,
  payload: value,
});

export const setAdvertiserName = (value: string): UserActionTypes => ({
  type: ADVERTISER_NAME,
  payload: value,
});

export const setClicks = (value: string): UserActionTypes => ({
  type: CLICKS,
  payload: value,
});
export const setImpression = (value: string): UserActionTypes => ({
  type: IMPRESSIONS,
  payload: value,
});
export const setConversion = (value: string): UserActionTypes => ({
  type: CONVERSION,
  payload: value,
});
export const setInstall = (value: string): UserActionTypes => ({
  type: INSTALL,
  payload: value,
});
export const setPayout = (value: string): UserActionTypes => ({
  type: PAYOUT,
  payload: value,
});
export const setCtr = (value: string): UserActionTypes => ({
  type: CTR,
  payload: value,
});
export const setCpi = (value: string): UserActionTypes => ({
  type: CPI,
  payload: value,
});
export const setDailyAvgBudget = (value: any): UserActionTypes => ({
  type: DAILY_AVG_BUDGET,
  payload: value,
});

export const setPerformaceReport = (value: any): UserActionTypes => ({
  type: PERFORMANCE_REPORT,
  payload: value,
});

export const setAdvertiserIdCampaign = (value: string): UserActionTypes => ({
  type: ADVERTISER_ID_ADMIN,
  payload: value,
});

export const setAdvertisersList = (value: any[]): UserActionTypes => ({
  type: ADVERTISERS_LIST,
  payload: value,
});

export const setCampaignStatus = (value: string): UserActionTypes => ({
  type: CAMPAIGN_STATUS,
  payload: value,
});

export const setCompanyName = (value: string): UserActionTypes => ({
  type: COMPANY_NAME,
  payload: value,
});

export const setSelectedAdvertiserId = (value: string): UserActionTypes => ({
  type: SELECTED_ADVERTISER_ID,
  payload: value,
});

export const setTransactionCurrency = (value: string): UserActionTypes => ({
  type: TRANSACTION_CURRENCY,
  payload: value,
});

export const setTempTransactionCurrency = (value: string): UserActionTypes => ({
  type: TEMP_TRANSACTION_CURRENCY,
  payload: value,
})

export const setLocationCheck = (value: string): UserActionTypes => ({
  type: LOCATION_CHECK,
  payload: value,
});

export const setUsername = (username: string): UserActionTypes => ({
  type: SET_USERNAME,
  payload: username,
});

export const setCampaignSubType = (value: string): UserActionTypes => ({
  type: CAMPAIGN_SUBTYPE,
  payload: value,
});

export const setAppPlatform = (value: string): UserActionTypes => ({
  type: APP_PLATFORM,
  payload: value,
});

export const setAppName = (value: string): UserActionTypes => ({
  type: APP_NAME,
  payload: value,
});

export const setCampaignName = (value: string): UserActionTypes => ({
  type: CAMPAIGN_NAME,
  payload: value,
});
export const setPackageName = (value: string): UserActionTypes => ({
  type: PACKAGE_NAME,
  payload: value,
});

export const setAppImageUrl = (value: string): UserActionTypes => ({
  type: APP_IMAGE_URL,
  payload: value,
});

///////////////////////--------CAMPAIGN --- SETTING----------///////////////////////

export const setLocation = (value: string): UserActionTypes => ({
  type: LOCATION,
  payload: value,
});

export const setLanguage = (value: string[]): UserActionTypes => ({
  type: LANGUAGE,
  payload: value,
});

export const setTracking = (value: string): UserActionTypes => ({
  type: TRACKING,
  payload: value,
});

export const setTrackingUrl = (value: string): UserActionTypes => ({
  type: TRACKING_URL,
  payload: value,
});

export const setImpressionUrl = (value: string): UserActionTypes => ({
  type: IMPRESSION_URL,
  payload: value,
});

export const setTokenEvent = (
  updateFunction: { token: string; eventName: string }[]
) => ({
  type: TOKEN_EVENT,
  payload: updateFunction,
});

export const addTokenEventObject = (): UserActionTypes => ({
  type: ADD_TOKEN_EVENT_OBJECT,
});

export const removeTokenEventObject = (index: number): UserActionTypes => ({
  type: REMOVE_TOKEN_EVENT_OBJECT,
  payload: index,
});

export const updateToken = (index: number, token: string): UserActionTypes => ({
  type: UPDATE_TOKEN,
  payload: { index, token },
});

export const updateEvent = (index: number, event: string): UserActionTypes => ({
  type: UPDATE_EVENT,
  payload: { index, event },
});

///////////////////////--------BUDGET----BIDDING----------///////////////////////

export const setBudget = (value: string): UserActionTypes => ({
  type: BUDGET,
  payload: value,
});

export const setFocus = (value: string): UserActionTypes => ({
  type: FOCUS,
  payload: value,
});

export const setTarget = (value: string): UserActionTypes => ({
  type: TARGET,
  payload: value,
});

export const setCostPerInstall = (value: string): UserActionTypes => ({
  type: COST_PER_INSTALL,
  payload: value,
});

///////////////////////-----------------------AD GROUP-------------------/////////////////////

export const setAdGroupName = (value: string): UserActionTypes => ({
  type: AD_GROUP_NAME,
  payload: value,
});

export const setLogoUrl = (value: string): UserActionTypes => ({
  type: LOGO_URL,
  payload: value,
});

export const setHeadline = (value: string[]): UserActionTypes => ({
  type: HEADLINE,
  payload: value,
});

export const setDescription = (value: string[]): UserActionTypes => ({
  type: DESCRIPTION,
  payload: value,
});

export const setImageUrl = (value: string[]): UserActionTypes => ({
  type: IMAGE_URL,
  payload: value,
});

export const setVideoUrl = (value: string[]): UserActionTypes => ({
  type: VIDEO_URL,
  payload: value,
});

export const setHtmlUrl = (value: string[]): UserActionTypes => ({
  type: HTML_URL,
  payload: value,
});

export const setCurrencySymbol = (value: any): UserActionTypes => ({
  type: CURRENCY_SYMBOL,
  payload: value,
});

export const setAuthToken = (value: any): UserActionTypes => ({
  type: AUTH_TOKEN,
  payload: value,
});

export const setSessionId = (value: any): UserActionTypes => ({
  type: SESSION_ID,
  payload: value,
});

export const setAdvertiserId = (value: any): UserActionTypes => ({
  type: ADVERTISER_ID,
  payload: value,
});

export const setUserEmail = (value: any): UserActionTypes => ({
  type: USER_EMAIL,
  payload: value,
});

export const setCountryCode = (value: any): UserActionTypes => ({
  type: COUNTRY_CODE,
  payload: value,
});

export const setCountryCodeAdvertiser = (value: any): UserActionTypes => ({
  type: COUNTRY_CODE_ADVERTISER,
  payload: value,
});

export const setLocationCode = (value: string): UserActionTypes => ({
  type: LOCATION_CODE,
  payload: value,
});
//
export const setBudgetId = (value: string): UserActionTypes => ({
  type: BUDGET_ID,
  payload: value,
});
export const setCampaignId = (value: string): UserActionTypes => ({
  type: CAMPAIGN_ID,
  payload: value,
});

export const setImageFile = (file: string): UserActionTypes => ({
  type: IMAGE_FILE,
  payload: file,
});

export const setVideoFile = (file: File[]): UserActionTypes => ({
  type: VIDEO_FILE,
  payload: file,
});

export const setHtmlFile = (file: File[]): UserActionTypes => ({
  type: HTML_FILE,
  payload: file,
});

export const setStatus = (value: string): UserActionTypes => ({
  type: STATUS,
  payload: value,
});

export const setTotalBalance = (value: any): UserActionTypes => ({
  type: TOTAL_BALANCE,
  payload: value,
});

export const setRememberMeEmail = (value: any): UserActionTypes => ({
  type: REMEMBER_ME_EMAIL,
  payload: value,
});

export const setRememberMePassword = (value: any): UserActionTypes => ({
  type: REMEMBER_ME_PASSWORD,
  payload: value,
});

export const setRememberMeCheck = (value: any): UserActionTypes => ({
  type: REMEMBER_ME_CHECK,
  payload: value,
});

export const setMasterCountryArray = (value: any): UserActionTypes => ({
  type: MASTER_COUNTRY_ARRAY,
  payload: value,
});

export const setMasterCouponArray = (value: any): UserActionTypes => ({
  type: MASTER_COUPON_ARRAY,
  payload: value,
});

export const setMasterLanguageArray = (value: any): UserActionTypes => ({
  type: MASTER_LANGUAGE_ARRAY,
  payload: value,
});

export const setMasterMacrosArray = (value: any): UserActionTypes => ({
  type: MASTER_MACROS_ARRAY,
  payload: value,
});

export const setMasterMMPArray = (value: any): UserActionTypes => ({
  type: MASTER_MMP_ARRAY,
  payload: value,
});

export const setMasterTargetingTypesArray = (value: any): UserActionTypes => ({
  type: MASTER_TARGETING_TYPES_ARRAY,
  payload: value,
});

export const setMasterPublisherArray = (value: any): UserActionTypes => ({
  type: MASTER_PUBLISHER_ARRAY,
  payload: value,
});

export const setLogoError = (value: string): UserActionTypes => ({
  type: LOGO_URL_ERROR,
  payload: value,
});

export const setImageUploadError = (value: string): UserActionTypes => ({
  type: IMAGE_URL_ERROR,
  payload: value,
});

export const setDataSource = (value: string): UserActionTypes => ({
  type: DATA_SOURCE,
  payload: value,
});

export const setSegmentName = (value: string): UserActionTypes => ({
  type: SEGMENT_NAME,
  payload: value,
});

export const setDataType = (value: string): UserActionTypes => ({
  type: DATA_TYPE,
  payload: value,
});

export const setDataUpload = (data: File[]): UserActionTypes => ({
  type: DATA_UPLOAD,
  payload: data,
});

export const setUploadedUrl = (url: string): UserActionTypes => ({
  type: UPLOADED_DATA_URL,
  payload: url,
});

export const setDataSourceError = (value: string): UserActionTypes => ({
  type: DATA_SOURCE_ERROR,
  payload: value,
});

export const setSegmentNameError = (value: string): UserActionTypes => ({
  type: SEGMENT_NAME_ERROR,
  payload: value,
});

export const setDataTypeError = (value: string): UserActionTypes => ({
  type: DATA_TYPE_ERROR,
  payload: value,
});

export const setDataUploadError = (data: File[]): UserActionTypes => ({
  type: DATA_UPLOAD_ERROR,
  payload: data,
});

export const setUploadedUrlError = (url: string): UserActionTypes => ({
  type: UPLOADED_DATA_URL_ERROR,
  payload: url,
});

export const resetState = () => ({
  type: RESET_STATE,
});

export const setSelectedDateRange = (value: object): UserActionTypes => ({
  type: SELECTED_DATE_RANGE,
  payload: value,
});

export const setUserType = (value: any): UserActionTypes => ({
  type: USER_TYPE,
  payload: value,
});

export const setCreditLimit = (value: any): UserActionTypes => ({
  type: CREDIT_LIMIT,
  payload: value,
});

export const setAdvertiserScreenTable = (value: any): UserActionTypes => ({
  type: ADVERTISERS_SCREEN_TABLE,
  payload: value,
});

export const setReportScreenTable = (value: any): UserActionTypes => ({
  type: REPORT_SCREEN_TABLE,
  payload: value,
});
