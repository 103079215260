import React from 'react';
import { IoWallet } from 'react-icons/io5';
import ErrorIcon from '@mui/icons-material/Error';
import { PurpleCustomButton } from '../custom/PurpleButton';
import { useNavigate } from 'react-router-dom';
import '../../CSS/payments.css';

const PaymentFailScreen = () => {
    const navigate = useNavigate();

    return (
        <div>
            <div className='main-heading'><IoWallet style={{ color: "#6900B8", marginRight: 5 }} /><div>Add Funds</div></div>
            <div className="white-box" style={{ width: "96%", padding: "20px", height: "140px", display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>
                <div style={{ display: "flex", alignItems: "center", color: "#FF0047", fontWeight: "700" }}><ErrorIcon style={{ marginRight: "10px" }} />Payment Declined</div>
                <div style={{ color: "#6900B8", fontSize: "22px", fontWeight: "900" }}>Sorry, funds could not be added to your account</div>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ fontSize: "11px", color: "rgba(51, 51, 51, 0.5)", fontWeight: "700" }}>
                    {/* Transaction ID: 002ADVTRANS12210 <a href="">View Details</a> */}
                </div>
                <PurpleCustomButton children="Dashboard" disable={true} onClick={() => navigate('/home')} />
            </div>
        </div>
    );
}

export default PaymentFailScreen;