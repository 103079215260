import React, { FC, useEffect } from "react";
import "./Components/CSS/App.css";
import AppRoutes from "./Components/TSX/route/AppRoutes";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import {store, persistor} from "./Components/REDUX/Store";
import { PersistGate } from "redux-persist/integration/react";
import { initializeGoogleTagManager } from "./Components/GoogleTagManager";

const App: FC = () => {

  useEffect(() => {
    initializeGoogleTagManager()
  }, [])

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
