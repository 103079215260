import React, { FC } from "react";
import styled from "styled-components";
import { NavbarBottomData, NavData } from "./SideNavData";
import { NavbarMenu, NavbarBottomMenu } from "./NavbarItems";

const SideNavBar = styled.div`
     position: fixed;
     height: 100%;
     display: flex;
     z-index: 999;
     flex-direction: column;
     align-items: center;
     width: 16%;
     background-color: white;
     border-right: 3px solid rgba(0, 0, 0, 0.05);
     border-bottom: 3px solid rgba(0, 0, 0, 0.05);
     margin-top:114px;
`;

const Navbar: FC = () => {

  return <>
    <SideNavBar className="menu" >
      <div style={{ paddingBottom: 80, paddingTop: 30, borderBottom: "1px solid #c4c4c4" }}>
        {NavData.map((item, index) => {
          return <NavbarMenu item={item} key={index} />;
        })}
      </div>
      <div>
        {NavbarBottomData.map((item, index) => {
          return <NavbarBottomMenu item={item} key={index} />;
        })}
      </div>
    </SideNavBar>
  </>
}

export default Navbar;