import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { increment, decrement } from "../../REDUX/Actions/counterActionIndex";
import Checkbox from "@mui/material/Checkbox";
import "../../CSS/SignIn.css";
import "../../CSS/CreateCampaign.css";
import CampaignInfo from "../custom/CampaignInfo";
import CampaignSetting from "../custom/CampaignSetting";
import BudgetBinding from "../custom/BudgetBinding";
import Review from "../custom/Review";
import AdGroup from "../custom/AdGroup";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
import { te } from "date-fns/locale";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { apiURL, customCheckBoxColor, secretKey } from "../../Constant";
import { count } from "console";
import { RootState } from "../../REDUX/Reducers/rootReducer";
import {
  setCampaignSubType,
  setAppPlatform,
  setAppName,
  setCampaignName,
  setAppImageUrl,
  setPackageName,
  setLocation,
  setLanguage,
  setTracking,
  setTrackingUrl,
  setImpressionUrl,
  setTokenEvent,
  addTokenEventObject,
  removeTokenEventObject,
  updateToken,
  updateEvent,
  setBudget,
  setFocus,
  setTarget,
  setCostPerInstall,
  setAdGroupName,
  setLogoUrl,
  setHeadline,
  setDescription,
  setImageUrl,
  setVideoUrl,
  setHtmlUrl,
  setLocationCode,
  setCountryCode,
  setCampaignId,
  setBudgetId,
  setStatus,
  setImageFile,
  setVideoFile,
  setHtmlFile,
  setImageUploadError,
  setLogoError,
  setLocationCheck,
  setAdvertiserId,
  setAuthToken,
  setSessionId,
  setDataSource,
  setSegmentName,
  setDataType,
  setDataUpload,
  setUploadedUrl,
  setDataSourceError,
  setSegmentNameError,
  setDataTypeError,
  setDataUploadError,
  setUploadedUrlError,
  setClicks,
  setImpression,
  setConversion,
  setInstall,
  setPayout,
  setCtr,
  setCpi,
  setTransactionCurrency,
  setDailyAvgBudget,
  setTableData,
  setTempTransactionCurrency,
} from "../../REDUX/Actions/userActionIndex";
import { setCount } from "../../REDUX/Actions/counterActionIndex";
import { useDispatch } from "react-redux";
import CustomLoader from "../custom/CustomLoader";
import ConfigureCampaign from "./ConfigureCampaign";
import AddCreatives from "./AddCreatives";
const CryptoJS = require("crypto-js");

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "3px",
  p: 4,
};
const loaderStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};
interface CounterProps {
  count: number;
  increment: () => void;
  decrement: () => void;

  campaignSubtype: string;
  appPlatform: string;
  appName: string;
  campaignName: string;
  appImageUrl: string;
  packageName: string;

  location: string;
  language: string[];
  tracking: string;
  trackingUrl: string;
  impressionUrl: string;
  tokenEvent: {
    token: string;
    eventName: string;
  }[];
  locationCode: string;
  countryCode: string;

  budget: string;
  target: string;
  focus: string;
  costPerInstall: string;

  adGroupName: string;
  logoUrl: string;
  headline: string[];
  description: string[];

  imageUrl: string[];
  videoUrl: string[];
  htmlUrl: string[];
  campaign_Id: string;
  budget_Id: string;
  status: string;
  authToken: string;
  sessionId: string;
  advertiserId: string;

  dataSource: string;
  segmentName: string;
  dataType: string;
  dataUpload: File[];
  uploadedUrl: string;
  transactionCurrency: string;
  adminView?: boolean;
  advertiserIdCampaign: string;
}
const CreateCampaign: React.FC<CounterProps> = ({
  advertiserIdCampaign,
  authToken,
  sessionId,
  advertiserId,
  campaign_Id,
  budget_Id,
  count,
  increment,
  decrement,
  appName,
  campaignSubtype,
  appPlatform,
  campaignName,
  packageName,
  appImageUrl,
  location,
  language,
  tracking,
  trackingUrl,
  impressionUrl,
  tokenEvent,
  locationCode,
  countryCode,
  budget,
  focus,
  target,
  costPerInstall,
  adGroupName,
  logoUrl,
  headline,
  description,
  imageUrl,
  videoUrl,
  htmlUrl,
  status,
  dataSource,
  segmentName,
  dataType,
  uploadedUrl,
  dataUpload,
  transactionCurrency,
  adminView = false,
}) => {
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState<number>(0);
  const [errorCampaignInfo, seterrorCampaignInfo] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const [id, setId] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [idCheck,setIdCheck] = useState(false);
  // const [errorCampaignInfo, seterrorCampaignInfo] = useState(false);
  // const pageChangeClick = () => {
  //   setPageCount(pageCount + 1);

  //   if (pageCount + 1 == 2) {
  //     apiCallFunction();
  //   } else if (pageCount + 1 == 3) {
  //     apiBudget();
  //   } else if (pageCount + 1 == 4) {
  //     saveAdGroup();
  //   }
  // };

  const handleChildData = (data: boolean) => {
    if (data) {
      // setPageCount(pageCount + 1);
      increment();
    }
  };
  const handleCampaignSetting = (data: boolean) => {
    if (data) {
      // setPageCount(pageCount + 1);
      increment();
    }
  };
  const budgetHandling = (data: boolean) => {
    if (data) {
      // setPageCount(pageCount + 1);
      increment();
      // const item = localStorage.getItem("editingData") || "";
      // const campaign = localStorage.getItem("campaignId") || "";

      // if (item != "") {
      //   // const data = JSON.parse(item);
      //   // console.log("createCampaign", data);
      //   UpdateCampaignApi();
      //   budgetUpdate(budget_Id, campaign_Id);
      // } else {
      //   apiCallFunction();
      //   // apiBudget(campaign);
      // }
    }
  };
  const adGroupHandling = (data: string) => {
    if (data == "NO") {
      // setPageCount(pageCount + 1);
      increment();
    } else if (data == "UPDATE") {
      // setPageCount(pageCount + 1);
      increment();
    } else {
      // saveAdGroup();
      // console.log("else")
      window.location.replace(`/home/campaigns/campaignsRecord?id=${data}`);
    }
  };

  const pageBackwardClick = () => {
    // setPageCount(pageCount - 1);
    // decrement();
    window.location.replace("/home/campaigns");
  };

  useEffect(() => {
    // console.log(campaign_Id);
    const searchParams = new URLSearchParams(window.location.search);
    const countParam = searchParams.get("count");
    let convertedNumber = parseInt(countParam != null ? countParam : "0");
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const productId = urlParams.get("id") || "";
    if(productId!=""){
      setIdCheck(true);
    }
    setPageCount(convertedNumber);
    const logoURL = localStorage.setItem("logoUrl", "");
    const item = localStorage.getItem("editingData") || "";
    if (tokenEvent.length == 0) {
      dispatch(setTokenEvent([]));
    }

    if (item != "") {
      const data = JSON.parse(item);

      // console.log("createCampaign", data);
    }
  }, []);

  const erase = () => {
    dispatch(setClicks(""));
    dispatch(setImpression(""));
    dispatch(setConversion(""));
    dispatch(setInstall(""));
    dispatch(setPayout(""));
    dispatch(setCtr(""));
    dispatch(setCpi(""));
    dispatch(setTransactionCurrency(""));
    dispatch(setDailyAvgBudget(""));

    dispatch(setLocationCheck(""));
    dispatch(setImageUploadError(""));
    dispatch(setLogoError(""));
    dispatch(setCount(0));
    dispatch(setStatus("pending"));
    dispatch(setCampaignSubType(""));
    dispatch(setAppPlatform(""));
    dispatch(setAppName(""));
    dispatch(setCampaignName(""));
    dispatch(setAppImageUrl(""));
    dispatch(setPackageName(""));
    dispatch(setTempTransactionCurrency(""));
    //CAMPAIGN SETTING

    dispatch(setLanguage([]));
    dispatch(setLocation(""));
    dispatch(setTracking(""));
    dispatch(setTrackingUrl(""));
    dispatch(setImpressionUrl(""));
    dispatch(removeTokenEventObject(0));
    if (tokenEvent.length > 0) {
      tokenEvent.map((item, index) => {
        dispatch(updateToken(index, ""));
        dispatch(updateEvent(index, ""));
      });
    }

    dispatch(setLocationCode(""));

    // BUDGET BIDDING
    dispatch(setBudget(""));
    dispatch(setFocus(""));
    dispatch(setTarget(""));
    dispatch(setCostPerInstall(""));

    dispatch(setAdGroupName(""));
    dispatch(setLogoUrl(""));
    dispatch(setHeadline([""]));
    dispatch(setDescription([""]));
    dispatch(setImageUrl([]));
    dispatch(setVideoUrl([]));
    dispatch(setHtmlUrl([]));
    dispatch(setImageFile(""));
    // const emptyFile = new File([], 'empty.txt', { type: 'text/plain' });
    dispatch(setVideoFile([]));
    dispatch(setHtmlFile([]));

    dispatch(setCampaignId(""));
    dispatch(setBudgetId(""));

    ///////////////////

    dispatch(setDataSource(""));
    dispatch(setDataSourceError(""));

    dispatch(setSegmentName(""));
    dispatch(setSegmentNameError(""));

    dispatch(setDataType(""));
    dispatch(setDataTypeError(""));

    dispatch(setDataUpload([]));
    dispatch(setDataUploadError([]));

    dispatch(setUploadedUrl(""));
    dispatch(setUploadedUrlError(""));
    localStorage.setItem("editingData", "");
  };

  const pageRedirect = () => {
    localStorage.setItem("htmlUrlArray", "");
    localStorage.setItem("videoUrlArray", "");
    localStorage.setItem("imageUrlArray", "");
    localStorage.setItem("headlines", "");
    localStorage.setItem("description", "");
    setOpen(true);
    setLoader(true);
    // console.log();
    const item = localStorage.getItem("editingData") || "";
    const bal = localStorage.getItem("totalBalance") || "";
    dispatch(setTableData([]));
    if (adminView) {
      alert("Please tell Advertiser to make Payment");
      apiCallFunction();
    } else {
      if (item != "") {
        const data = JSON.parse(item);
        // console.log("createCampaign", );
        setOpen(true);
        setLoader(true);
        UpdateCampaignApi(data.campaignId);
      } else {
        if (budget != null && budget != "" && bal != null && bal != "") {
          if (parseInt(budget) <= parseInt(bal)) {
            apiCallFunction();
          } else {
            // console.log("here 2")
            navigate("/home/addFunds", {
              state: {
                // campaignname: campaignName,
                amount: budget,
                paymentType: "stripe",
                isCampaignPublished: true,
              },
            });
          }
        } else if (budget != null && budget != "") {
          navigate("/home/addFunds", {
            state: {
              // campaignname: campaignName,
              amount: budget,
              paymentType: "stripe",
              isCampaignPublished: true,
            },
          });
        }

        // apiCallFunction();
        // navigate("/home/addFunds", {
        //   state: {
        //     // campaignname: campaignName,
        //     amount: budget,
        //     paymentType: "stripe",
        //     isCampaignPublished: true,
        //   },
        // });
        // apiBudget(campaign);
      }
    }
  };

  const UpdateCampaignApi = (campId: string) => {
    // const advertiserId = localStorage.getItem("advertiserID") || "";
    const email = localStorage.getItem("emailID") || "";
    const campSubtype = localStorage.getItem("campaignSubtype") || "";

    const jsonParam = {
      AdvertiserId: advertiserId,
      CampaignId: campaign_Id,
      Email: email,
      ApplicationName: appName, // harCoded
      CampaignName: campaignName,
      SubType: campaignSubtype,
      AppPlatform: appPlatform,
      AppLookUpDetails: {
        logoUrl: appImageUrl,
        packageName: packageName,
      },
      TrackingPartner: tracking,
      TrackingURL: trackingUrl,
      Token:
        tokenEvent.length > 0
          ? tokenEvent[0].token !== "" && tokenEvent[0].eventName !== ""
            ? tokenEvent
            : []
          : [],
      Currency: countryCode,
      Location: location,
      Status: status,
      Payouts: [
        {
          payout: 0,
          revenue: costPerInstall,
          geo: [locationCode],
        },
      ],
      IsRetargetingSubType: campaignSubtype == "Retargeting" ? true : false,
      RetargetingOptions: {
        dataSource: dataSource,
        segmentName: dataSource != "SKIP" ? segmentName : "",
        dataType: dataSource != "SKIP" ? dataType : "",
        fileUrl: dataSource != "SKIP" ? uploadedUrl : "",
      },
      Languages: language,
    };
    // console.log(jsonParam);
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(jsonParam),
      secretKey
    ).toString();

    const url = `${apiURL}/api/campaigns/updateCampaign`;

    const encryptAdvertiserID = CryptoJS.AES.encrypt(
      JSON.stringify(advertiserId),
      secretKey
    ).toString();

    const header = {
      headers: {
        authtoken: authToken,
        sessionid: sessionId,
        advertiserid: encryptAdvertiserID, // Example authorization header
      },
    };
    if (authToken != "" && sessionId != "" && advertiserId != "") {
      axios
        .post(url, { params: encryptedData }, header)
        .then((res) => {
          // console.log("res", res.data.status);
          if (res.data.status) {
            if (budget_Id != "") {
              budgetUpdate(budget_Id, campaign_Id);
            } else {
              apiBudget(campaign_Id);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // });
  };

  const apiCallFunction = () => {
    const email = localStorage.getItem("emailID") || "";

    const jsonParam = {
      AdvertiserId: adminView ? advertiserIdCampaign : advertiserId,
      Email: email,
      ApplicationName: appName,
      CampaignName: campaignName,
      SubType: campaignSubtype,
      AppPlatform: appPlatform,
      Location: location,
      TrackingPartner: tracking,
      TrackingURL: trackingUrl.endsWith("&")
        ? trackingUrl.slice(0, -1)
        : trackingUrl,
      Token:
        tokenEvent.length > 0
          ? tokenEvent[0].token !== "" && tokenEvent[0].eventName !== ""
            ? tokenEvent
            : []
          : [],
      FinalClickURL: impressionUrl.endsWith("&")
        ? impressionUrl.slice(0, -1)
        : impressionUrl,

      Currency: transactionCurrency,
      Status: "pending",
      Payouts: [
        {
          payout: 0,
          revenue: costPerInstall,
          geo: [locationCode],
        },
      ],

      IsRetargetingSubType: campaignSubtype == "Retargeting" ? true : false,
      RetargetingOptions: {
        dataSource: dataSource,
        segmentName: dataSource != "SKIP" ? segmentName : "",
        dataType: dataSource != "SKIP" ? dataType : "",
        fileUrl: dataSource != "SKIP" ? uploadedUrl : "",
      },
      // BudgetId: string;
      // Description: string;
      Languages: language,
      AppLookUpDetails: {
        logoUrl: appImageUrl,
        packageName: packageName,
      },
      // TrakierCampaignId?: string,
    };

    console.log(jsonParam);
    console.log(advertiserId);

    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(jsonParam),
      secretKey
    ).toString();

    const url = `${apiURL}/api/campaigns`;
    // const url = `http://localhost:10056/api/campaigns`;

    // const authToken = localStorage.getItem("authToken") || "";
    // const sessionID = localStorage.getItem("sessionID") || ""; // console.log(startDate,endDate)
    const encryptAdvertiserID = CryptoJS.AES.encrypt(
      JSON.stringify(advertiserId),
      secretKey
    ).toString();

    // const advertiserId = localStorage.getItem("advertiserID") || "";
    const header = {
      headers: {
        authtoken: authToken,
        sessionid: sessionId,
        advertiserid: encryptAdvertiserID, // Example authorization header
      },
    };
    if (authToken != "" && sessionId != "" && advertiserId != "") {
      axios
        .post(url, { params: encryptedData }, header)
        .then((res) => {
          console.log("res", res);
          if (res.status == 201) {
            // const resData = res.data;
            // const bytes = CryptoJS.AES.decrypt(resData, secretKey);
            // const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
            // const parsedData = JSON.parse(decryptedData);
            // localStorage.setItem("campaignId", res.data.campaignId);

            dispatch(setCampaignId(res.data.campaignId));
            // console.log(res.data)
            apiBudget(res.data.campaignId);

            // return res.data.campaignId
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const saveAdGroup = async (id: string) => {
    // const adGrpName = localStorage.getItem("adGrpName") || "";

    // const htmlUrl = localStorage.getItem("htmlUrlArray") || "";
    // const imageUrl = localStorage.getItem("imageUrlArray") || "";
    // const videoUrl = localStorage.getItem("videoUrlArray") || "";

    // const headline = localStorage.getItem("headlines") || "";
    // const description = localStorage.getItem("description") || "";

    const campaign = localStorage.getItem("campaignId") || "";
    const searchParams = new URLSearchParams(window.location.search);
    const countParam = searchParams.get("id") || "";
    const item = localStorage.getItem("editingData") || "";
    // const navigate = useNavigate()
    // const logoURL = localStorage.getItem("logoUrl") || "";
    let temp = "";
    if (item != "") {
      const data = JSON.parse(item);
      temp = data.id;
      // console.log("createCampaign",data)
    }
    console.log(temp, "asdf", countParam, "adfas", campaign_Id, "adfadf");
    let jsonParam = {
      campaignId: adminView
        ? id
        : temp == ""
        ? countParam != ""
          ? countParam
          : id
        : temp,
      logo: logoUrl,
      name: adGroupName,
      headline: headline,
      description: description,

      // image: imageUrl != "" ? JSON.parse(imageUrl) : [],
      // videos: videoUrl != "" ? JSON.parse(videoUrl) : [],
      // HTML5: htmlUrl != "" ? JSON.parse(htmlUrl) : [],
      image: imageUrl,
      videos: videoUrl,
      HTML5: htmlUrl,
    };
    // const authToken = localStorage.getItem("authToken") || "";
    // const sessionID = localStorage.getItem("sessionID") || ""; // console.log(startDate,endDate)
    // const advertiserId = localStorage.getItem("advertiserID") || "";
    console.log(jsonParam);
    const encryptAdvertiserID = CryptoJS.AES.encrypt(
      JSON.stringify(advertiserId),
      secretKey
    ).toString();

    const header = {
      headers: {
        authtoken: authToken,
        sessionid: sessionId,
        advertiserid: encryptAdvertiserID, // Example authorization header
      },
    };
    // console.log(jsonParam);
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(jsonParam),
      secretKey
    ).toString();

    let url = `${apiURL}/api/campaigns/saveAdGroups`;
    if (authToken != "" && sessionId != "" && advertiserId != "") {
      await axios
        .post(url, { params: encryptedData }, header)
        .then((res) => {
          if (res.data.status) {
            // window.location.replace("/home/campaigns");
            // const budget = localStorage.getItem("budget");
            const campaignName = localStorage.getItem("campaignName");
            const bal = localStorage.getItem("totalBalance");
            // console.log(budget , bal )
            erase();
            adminView
              ? navigate("/home/admin/campaigns")
              : navigate("/home/campaigns");
            // if (budget != null && budget != "" && bal != null && bal != "") {
            //   if (parseInt(budget) <= parseInt(bal)) {
            //     erase();
            //     navigate("/home/campaigns");
            //   } else {
            //     // console.log("here 2")
            //     erase();
            //     navigate("/home/addFunds", {
            //       state: {
            //         // campaignname: campaignName,
            //         amount: budget,
            //         paymentType: "stripe",
            //         isCampaignPublished: true,
            //       },
            //     });
            //   }
            // } else if (budget != null && budget != "") {
            //   navigate("/home/addFunds", {
            //     state: {
            //       // campaignname: campaignName,
            //       amount: budget,
            //       paymentType: "stripe",
            //       isCampaignPublished: true,
            //     },
            //   });
            // }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const checkBoxColor = {
    color: "#6900B8",
    "&.Mui-checked": {
      color: "#6900B8",
    },
  };

  const budgetUpdate = async (budgetId: string, campaign: string) => {
    // const budgetDaily = localStorage.getItem("budget") || "";
    // const trackDpd = localStorage.getItem("trackDpd") || "";
    // // const target = localStorage.getItem("targetCheck") || "";
    // const focusDpd = localStorage.getItem("focusDpd") || "";
    // const installationCost = localStorage.getItem("costPerInstall") || "";
    // const targetApp = localStorage.getItem("targetApp") || "";

    const jsonParam = {
      budgetId: budgetId,
      campaignId: campaign,
      dailyAvgBudget: budget != "" ? parseInt(budget, 10) : 0,
      // targetUserType: trackDpd,
      isSetTargetCoatPerInstall: false,
      // target != "" ? (target == "true" ? true : false) : false,
      targetCostPerInstall: costPerInstall != "" ? parseInt(costPerInstall) : 0,
      focusParam: focus,
      targetingType: target,
      cpiCurrency: transactionCurrency,
    };
    // console.log(jsonParam);
    // const authToken = localStorage.getItem("authToken") || "";
    // const sessionID = localStorage.getItem("sessionID") || ""; // console.log(startDate,endDate)
    // const advertiserId = localStorage.getItem("advertiserID") || "";
    const encryptAdvertiserID = CryptoJS.AES.encrypt(
      JSON.stringify(advertiserId),
      secretKey
    ).toString();

    const header = {
      headers: {
        authtoken: authToken,
        sessionid: sessionId,
        advertiserid: encryptAdvertiserID, // Example authorization header
      },
    };

    let url = `${apiURL}/api/campaigns/updateBudgetDetails`;
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(jsonParam),
      secretKey
    ).toString();
    if (authToken != "" && sessionId != "" && advertiserId != "") {
      await axios
        .post(url, { params: encryptedData }, header)
        .then((res) => {
          if (res.data.status) {
            // window.location.replace("/home/campaigns");
            // const budget = localStorage.getItem("budget");
            const campaignName = localStorage.getItem("campaignName");
            const bal = localStorage.getItem("totalBalance");

            if (budget != null && budget != "" && bal != null && bal != "") {
              if (parseInt(budget) <= parseInt(bal)) {
                // console.log("here")
                erase();
                navigate("/home/campaigns");
              } else {
                // console.log("here 2")
                erase();
                navigate("/home/addFunds", {
                  state: {
                    // campaignname: campaignName,
                    amount: budget,
                    paymentType: "stripe",
                    isCampaignPublished: true,
                  },
                });
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const apiBudget = async (campId: string) => {
    // const budget = localStorage.getItem("budget") || "";
    // const focusDpd = localStorage.getItem("focusDpd") || "";
    // const trackDpd = localStorage.getItem("trackDpd") || "";
    // const installationCost = localStorage.getItem("costPerInstall") || "";
    // const target = localStorage.getItem("targetCheck") || "";
    // const targetApp = localStorage.getItem("targetApp") || "";
    const campaign = localStorage.getItem("campaignId") || "";
    let jsonParam = {
      campaignId: campId != "" ? campId : campaign_Id,
      dailyAvgBudget: budget != "" ? parseInt(budget, 10) : 0,
      // targetUserType: trackDpd,
      isSetTargetCoatPerInstall: false,
      cpiCurrency: transactionCurrency,
      // target != "" ? (target == "true" ? true : false) : false,
      targetCostPerInstall: costPerInstall != "" ? parseInt(costPerInstall) : 0,
      focusParam: focus,
      targetingType: target,
    };
    // const authToken = localStorage.getItem("authToken") || "";
    // const sessionID = localStorage.getItem("sessionID") || ""; // console.log(startDate,endDate)
    // const advertiserId = localStorage.getItem("advertiserID") || "";
    const encryptAdvertiserID = CryptoJS.AES.encrypt(
      JSON.stringify(advertiserId),
      secretKey
    ).toString();

    const header = {
      headers: {
        authtoken: authToken,
        sessionid: sessionId,
        advertiserid: encryptAdvertiserID, // Example authorization header
      },
    };
    console.log(jsonParam);
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(jsonParam),
      secretKey
    ).toString();

    let url = `${apiURL}/api/campaigns/saveBudgetDetails`;
    if (authToken != "" && sessionId != "" && advertiserId != "") {
      await axios
        .post(url, { params: encryptedData }, header)
        .then((res) => {
          // console.log(res.data);
          if (res.data.status) {
            saveAdGroup(campId);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div>
      <div className="campaign-header"
      style={{marginTop: adminView? '20px':'30px'}}
      >
        <div className="column-flex">
          <div className="chk-container">
            <Checkbox
              {...label}
              className="chk-top"
              checked={count == 0 ? false : true}
              style={{ color: customCheckBoxColor }}
            />
            <div
              className="connecting-line"
              style={{ background: count == 0 ? "#c5c5c5" : "#6900b8" }}
            ></div>
          </div>
          <div
            style={{
              //  marginRight: "30px",
              marginLeft: "-25px",
              fontSize: "13px",
              fontWeight: "600",
              color: count >= 0 ? "#6900b8" : "#c5c5c5",
            }}
          >
            {campaignSubtype !== "Digital BillBoards"
              ? "Create Campaign"
              : "Select Campaign"}
          </div>
        </div>

        <div className="column-flex">
          <div className="chk-container">
            <Checkbox
              {...label}
              className="chk-top"
              checked={count <= 1 ? false : true}
              style={{ color: customCheckBoxColor }}
            />
            <div
              className="connecting-line"
              style={{ background: count <= 1 ? "#c5c5c5" : "#6900b8" }}
            ></div>
          </div>
          <div
            style={{
              marginLeft: "-30px",
              fontSize: "13px",
              fontWeight: "600",
              // marginRight: "38px",
              color: count >= 1 ? "#6900b8" : "#c5c5c5",
            }}
          >
            {campaignSubtype !== "Digital BillBoards"
              ? "Campaign Settings"
              : "Configure Campaign"}
          </div>
        </div>

        <div className="column-flex">
          <div
            className="chk-container"
            style={{
              justifyContent:
                campaignSubtype !== "Digital BillBoards"
                  ? "center"
                  : "flex-start",
            }}
          >
            <Checkbox
              {...label}
              className="chk-top"
              checked={count <= 2 ? false : true}
              style={{ color: customCheckBoxColor }}
            />
            {campaignSubtype !== "Digital BillBoards" && (
              <div
                className="connecting-line"
                style={{ background: count <= 2 ? "#c5c5c5" : "#6900b8" }}
              ></div>
            )}
          </div>
          <div
            style={{
              // marginRight: "61px",
              marginLeft: "-26px",
              fontSize: "13px",
              fontWeight: "600",
              color: count >= 2 ? "#6900b8" : "#c5c5c5",
            }}
          >
            {campaignSubtype !== "Digital BillBoards"
              ? "Budget & Bidding"
              : "Add Creatives"}
          </div>
        </div>

        {campaignSubtype !== "Digital BillBoards" && (
          <div className="column-flex">
            <div className="chk-container">
              <Checkbox
                {...label}
                className="chk-top"
                checked={count <= 3 ? false : true}
                style={{ color: customCheckBoxColor }}
              />
              <div
                className="connecting-line"
                style={{ background: count <= 3 ? "#c5c5c5" : "#6900b8" }}
              ></div>
            </div>
            <div
              style={{
                // marginRight: "94px",
                marginLeft: "-5px",
                fontSize: "13px",
                fontWeight: "600",
                color: count >= 3 ? "#6900b8" : "#c5c5c5",
              }}
            >
              AD Group
            </div>
          </div>
        )}

        {campaignSubtype !== "Digital BillBoards" && (
          <div>
            <Checkbox
              {...label}
              className="chk-top"
              checked={count <= 4 ? false : true}
              style={{ color: customCheckBoxColor }}
            />
            <div
              style={{
                color: count >= 4 ? "#6900b8" : "#c5c5c5",
                fontSize: "13px",
                fontWeight: "600",
              }}
            >
              Review
            </div>
          </div>
        )}
      </div>
      {/* <div className="chk-container">
        <div className="slider-width">
          <h4 style={{
            //  marginRight: "30px", 
             color: "#6900b8" }}>
            Create Campaign
          </h4>
          <h4
            style={{
              // marginRight: "38px",
              color: pageCount <= 1 ? "#c5c5c5" : "#6900b8",
            }}
          >
            Campaign Settings
          </h4>
          <h4
            style={{
              // marginRight: "61px",
              color: pageCount <= 2 ? "#c5c5c5" : "#6900b8",
            }}
          >
            Budget & Bidding
          </h4>
          <h4
            style={{
              // marginRight: "94px",
              color: pageCount <= 3 ? "#c5c5c5" : "#6900b8",
            }}
          >
            AD Group
          </h4>
          <h4 style={{ color: pageCount <= 4 ? "#c5c5c5" : "#6900b8" }}>
            Review
          </h4>
        </div>
      </div> */}
      <div
        className="back-Button"
        style={{visibility: idCheck ? "hidden":"visible"}}
        onClick={
          () =>
            count == 0
              ? adminView
                ? window.history.back()
                : navigate("/home/campaigns")
              : decrement() // setPageCount(pageCount - 1)
        }
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <div>
        {count == 0 ? (
          <CampaignInfo
            onClick={handleChildData}
            onBackClick={pageRedirect}
            errorCheck={errorCampaignInfo}
            adminView={adminView}
          />
        ) : null}
        {count == 1 ? (
          campaignSubtype !== "Digital BillBoards" ? (
            <div className="campaign-setting">
              <h5>Campaign Setting</h5>
              <p className="setting-para">
                To reach the right people, start by defining key settings for
                your campaign
              </p>
              <CampaignSetting
                onClick={handleCampaignSetting}
                onClickBack={pageBackwardClick}
              />
            </div>
          ) : (
            <ConfigureCampaign onClick={handleCampaignSetting} />
          )
        ) : null}
        {count == 2 ? (
          campaignSubtype !== "Digital BillBoards" ? (
            <div className="campaign-setting">
              <h5>Budget and Bidding</h5>
              <p className="setting-para">
                Select the budget and bidding options that work best fro your
                goals
              </p>
              <BudgetBinding
                onClick={budgetHandling}
                onBackClick={pageBackwardClick}
              />
              {/* <CampaignSetting onClick={pageChangeClick} /> */}
            </div>
          ) : (
            <AddCreatives />
          )
        ) : null}
        {count == 3 ? (
          <AdGroup
            onBackClick={pageBackwardClick}
            handleClick={adGroupHandling}
          />
        ) : null}
        {count == 4 ? (
          <Review onClick={handleOpen} onBackClick={pageBackwardClick} />
        ) : null}
        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade 
            in={open}
            >
              {loader ? (
                <Box sx={loaderStyle}>
                  <CustomLoader />
                </Box>
              ) : (
                <Box sx={style}>
                  <h2 style={{ marginBottom: "5px", fontSize: "18px" }}>
                    Publish Campaign
                  </h2>
                  <p
                    style={{
                      margin: "10px 0px 40px 0",
                      fontSize: "14px",
                      opacity: 0.5,
                    }}
                  >
                    Are you sure you want to publish campaign
                  </p>
                  <div className="doneButton-box">
                    <button
                      className="purple-btn width-adjust"
                      onClick={pageRedirect}
                    >
                      Publish
                    </button>
                    <button className="border-btn" onClick={handleClose}>
                      Cancel
                    </button>
                  </div>
                </Box>
              )}
            </Fade>
          </Modal>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  count: state.counter.count,
  status: state.user.status,

  campaignSubtype: state.user.campaignSubtype,
  appPlatform: state.user.appPlatform,
  appName: state.user.appName,
  campaignName: state.user.campaignName,
  appImageUrl: state.user.appImageUrl,
  packageName: state.user.packageName,

  location: state.user.location,
  language: state.user.language,
  tracking: state.user.tracking,
  trackingUrl: state.user.trackingUrl,
  impressionUrl: state.user.impressionUrl,
  tokenEvent: state.user.tokenEvent,
  locationCode: state.user.locationCode,
  countryCode: state.user.countryCode,

  budget: state.user.budget,
  focus: state.user.focus,
  target: state.user.target,
  costPerInstall: state.user.costPerInstall,

  adGroupName: state.user.adGroupName,
  logoUrl: state.user.logoUrl,
  headline: state.user.headline,
  description: state.user.description,
  imageUrl: state.user.imageUrl,
  videoUrl: state.user.videoUrl,
  htmlUrl: state.user.htmlUrl,

  campaign_Id: state.user.campaign_Id,
  budget_Id: state.user.budget_Id,

  authToken: state.user.authToken,
  sessionId: state.user.sessionId,
  advertiserId: state.user.advertiserId,

  dataSource: state.user.dataSource,
  segmentName: state.user.segmentName,
  dataType: state.user.dataType,
  dataUpload: state.user.dataUpload,
  uploadedUrl: state.user.uploadedUrl,

  transactionCurrency: state.user.transactionCurrency,
  advertiserIdCampaign: state.user.advertiserIdCampaign,
});

const mapDispatchToProps = {
  increment: (): { type: string } => increment(),
  decrement: (): { type: string } => decrement(),
  setStatus,
  setCampaignSubType,
  setAppPlatform,
  setAppName,
  setCampaignName,
  setAppImageUrl,
  setPackageName,

  setLanguage,
  setLocation,
  setTracking,
  setTrackingUrl,
  setImpressionUrl,
  setTokenEvent,
  addTokenEventObject,
  removeTokenEventObject,
  updateToken,
  updateEvent,
  setLocationCode,
  setCountryCode,

  setBudget,
  setFocus,
  setTarget,
  setCostPerInstall,

  setAdGroupName,
  setLogoUrl,
  setHeadline,
  setDescription,
  setImageUrl,
  setVideoUrl,
  setHtmlUrl,
  setCount,
  setCampaignId,
  setBudgetId,

  setImageFile,
  setVideoFile,
  setHtmlFile,

  setImageUploadError,
  setLogoError,
  setLocationCheck,
  setAdvertiserId,
  setAuthToken,
  setSessionId,

  setDataSource,
  setSegmentName,
  setDataType,
  setDataUpload,
  setUploadedUrl,

  setDataSourceError,
  setSegmentNameError,
  setDataTypeError,
  setDataUploadError,
  setUploadedUrlError,
  setTempTransactionCurrency
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCampaign);
