import React, { FC, useEffect, useRef, useState } from 'react';
import '../../../CSS/Coupon.css';
import { FiCopy } from "react-icons/fi";
import { FaTag } from "react-icons/fa";
import { FaSquareArrowUpRight } from "react-icons/fa6";
import { MdArrowOutward } from "react-icons/md";
import axios from "axios";
import ProductTable from 'react-data-table-component'
import InfoIcon from '@mui/icons-material/Info';
import { Box, ListItemText, MenuItem, Modal, Select, Snackbar, Tooltip, Typography } from '@mui/material';
import { TooltipProps, styled, tooltipClasses } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { apiURL, secretKey } from '../../../Constant';
import DateCustomPicker from '../../custom/DateCustomPicker';
import { RootState } from '../../../REDUX/Reducers/rootReducer';
import { setAdvertiserId, setAuthToken, setCurrencySymbol, setSessionId, setMasterCouponArray, setMasterCountryArray, setSelectedDateRange } from '../../../REDUX/Actions/userActionIndex';
import { connect } from 'react-redux';
import { PurpleCustomButton } from '../../custom/PurpleButton';
import { element } from 'prop-types';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import active from "../../../IMAGE/active.svg";
import stopped from "../../../IMAGE/stopped.svg";
import paused from "../../../IMAGE/paused.svg";
import edit from "../../../IMAGE/editIcon.svg";
import couponWhite from "../../../IMAGE/couponWhite.svg";
import { parse } from 'path';
import CustomLoader from '../../custom/CustomLoader';
import done from "../../../IMAGE/done.svg";
import cancel from "../../../IMAGE/cancel.svg";

const CryptoJS = require('crypto-js')

function SlideTransition(props: TransitionProps) {
    return <Slide {...props} direction="up" />;
  }

  const CustomSelect = styled(Select)(({ theme }) => ({
    fontSize: "14px",
    fontWeight: "600",
    fontFamily: "Manrope",
    width: "100%",
    height: "40px",
    marginRight: '10px',
    borderRadius: '3px',
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
    {
        border: "1px solid black",
    },
}));

const menuItemStyle = {
    fontSize: "14px",
    fontFamily: "Manrope",
    fontWeight: "700",
    '& .MuiMenuItem-selected': {
        backgroundColor: "#6800b8",
    },
}
interface CouponScreenProps {
    advertiserId: any;
    authToken: any;
    sessionId: any;
    dates?: {
        startDate: string;
        endDate: string;
    };
    masterCountryArray: any,
    selectedDateRange: any
}

const CouponScreen: FC<CouponScreenProps> = (props) => {

    const [coupons, setCoupons] = useState<any[]>([])
    const [filterData, setFilterData] = useState<any[]>([])
    const [copied, setCopied] = useState(false);
    const [advertiserID, setAdvertiserID] = useState<any>(props.advertiserId)
    const [sessionID, setSessionID] = useState<any>(props.sessionId)
    const [authTokenID, setAuthTokenID] = useState<any>(props.authToken)
    const [openSession, setOpenSession] = React.useState(false);
    const [currencyList, setCurrencyList] = React.useState<any>([]);
    const [ totalCredit, setTotalCredit] = React.useState<any>(0);
    const [ percentage, setPercentage] = React.useState<any>(0);
    const [selectedRange, setSelectedRange] = useState<any>({
        startDate: props.selectedDateRange? props.selectedDateRange.startDate :  new Date(),
        endDate: props.selectedDateRange? props.selectedDateRange.endDate :new Date(),
    });
    const [clicked, setClicked] = useState(false);
    const [loaderActive, setLoaderActive] = useState<boolean>(false)
    const [statusDivOpen, setStatusDivOpen] = useState<boolean>(false)
    const [IsHoverStatus, setIsHoverStatus] = useState<string>('')
    const [selectedStatus, setselectedStatus] = useState<boolean>(false)
    const [selectedCurrency, setSelectedCurrency] = useState<string>('INR')
    const [currencySymbol, setCurrencySymbol] = useState<string>('')
    const [statsData, setStatsData] = useState<any>(null)
    

    const navigate = useNavigate()

    const statusDiv = (row: any) => {
        return <div style={{
            position: 'relative',display: 'flex', flexDirection: 'column',
             gap: '5px',padding: '7px', margin: '7px', fontSize: '10px', background: '#fff'}}>
            <div style={{display: 'flex', gap: '4px'}}>
                <div style={{display: 'flex', gap: '2px', cursor: 'pointer'}} onClick={() => handleClickStatusDone(row)}> <img src={done}/> <div>Done</div></div>
                <div style={{display: 'flex', gap: '2px', cursor: 'pointer'}} onClick={() => setStatusDivOpen(false)}> <img src={cancel}/><div>Cancel</div></div>
            </div>
            <div onClick={() => setselectedStatus(true)}  style={{display: 'flex', gap: '2px', alignItems: 'center', cursor: 'pointer', background: selectedStatus? '#C4C4C4' : '#fff', padding: '2px'}}><img style={{width: 'auto', height:'auto'}} src={active}/>Active</div>
            <div onClick={() => setselectedStatus(false)} style={{display: 'flex', gap: '2px', alignItems: 'center', cursor: 'pointer', background: !selectedStatus? '#C4C4C4' : '#fff', padding: '2px'}}><img style={{width: 'auto', height:'auto'}} src={stopped}/>Stopped</div>
        </div>
    }

    const handleClickStatusDone = (row: any) => {
        setLoaderActive(true)
        setStatusDivOpen(false)
        const encryptAdvertiserID = CryptoJS.AES.encrypt(JSON.stringify(advertiserID), secretKey).toString()
        let headers = {
            'Content-Type': 'application/json',
            'advertiserid': encryptAdvertiserID,
            'authtoken': authTokenID,
            'sessionid': sessionID
        };
        let params = {
            'couponId': row.CouponId,
            'IsActive': selectedStatus
        }
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(params), secretKey).toString();
        axios
            .post(`${apiURL}/api/admin/coupons/updateCoupon`, { params: encryptedData }, { headers: headers })
            .then((response) => {
                setLoaderActive(false)
                if (response.data.status == true) {
                    getAllCoupons();    
                    // setFilterData(parsedData['transactionsData'])
                } else if (response.data.statuscode == 401) {
                    setOpenSession(true)
                    setTimeout(() => { navigate("/") }, 2000)
                }
                setLoaderActive(false)
            })
            .catch((error) => {
                console.log(error)
            })

    }

    const handleSessionClose = () => {
        setOpenSession(false);
    };

    const handleStatusMouseEnter = (row: any, event: any) => {
        setIsHoverStatus(row.CouponId);
      };
    const handleStatusMouseLeave = (row: any, event: any) => {
        setStatusDivOpen(false);
        setIsHoverStatus('');
      };

    const getAllCoupons = () => {
        setLoaderActive(true)
        const encryptAdvertiserID = CryptoJS.AES.encrypt(JSON.stringify(advertiserID), secretKey).toString()
        let headers = {
            'Content-Type': 'application/json',
            'advertiserid': encryptAdvertiserID,
            'authtoken': authTokenID,
            'sessionid': sessionID
        };
        axios
            .get(`${apiURL}/api/admin/coupons?startDate=${selectedRange? formatChangeDate(selectedRange.startDate): ''}&endDate=${selectedRange? formatChangeDate(selectedRange.endDate): ''}`, { headers: headers })
            .then((response) => {
                
                if (response.data.status == true) {
                    const resData = response.data.data;
                    const bytes = CryptoJS.AES.decrypt(resData, secretKey);
                    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
                    const parsedData = JSON.parse(decryptedData);
                    let tempCouponData = []
                    let tempFilterData = []
                    if (parsedData && parsedData.length>0) {
                        for (let data of parsedData) {
                            tempCouponData.push(data)

                            if (data.Currency == selectedCurrency) {
                                tempFilterData.push(data)
                            }
                        }
                    }
                    
                    setCoupons(tempCouponData)
                    // let filterData  = coupons.filter((item: any) => item.Currency == selectedCurrency)
                    setFilterData(tempFilterData)
                    // setFilterData(parsedData['transactionsData'])
                } else if (response.data.statuscode == 401) {
                    setOpenSession(true)
                    setTimeout(() => { navigate("/") }, 2000)
                }
                setLoaderActive(false)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getCouponsStats = () => {
        const encryptAdvertiserID = CryptoJS.AES.encrypt(JSON.stringify(advertiserID), secretKey).toString()
        let headers = {
            'Content-Type': 'application/json',
            'advertiserid': encryptAdvertiserID,
            'authtoken': authTokenID,
            'sessionid': sessionID
        };
        axios
            .get(`${apiURL}/api/admin/coupons/getCouponStats?startDate=${selectedRange? formatChangeDate(selectedRange.startDate): ''}&endDate=${selectedRange? formatChangeDate(selectedRange.endDate): ''}`, { headers: headers })
            .then((response) => {
                if (response.data.status == true) {
                    const resData = response.data.data;
                    const bytes = CryptoJS.AES.decrypt(resData, secretKey);
                    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
                    const parsedData = JSON.parse(decryptedData);
                    if (parsedData) {
                        if ( parsedData.hasOwnProperty(selectedCurrency)) {
                            let currentAmount  = parsedData[selectedCurrency].currentMonth ? parsedData[selectedCurrency].currentMonth.totalDiscountAmount : 0
                        let previousAmount  = parsedData[selectedCurrency].previousMonth ? parsedData[selectedCurrency].previousMonth.totalDiscountAmount : 0
                        setTotalCredit(currentAmount);
                        previousAmount? setPercentage(((currentAmount - previousAmount)/previousAmount * 100).toFixed(2)) : setPercentage('+ 0');
                        }
                        setStatsData(parsedData)
                    } else {
                        setStatsData(null)
                        setTotalCredit(0);
                    }
                    
                    
                    // setCoupons(parsedData)
                    // setFilterData(parsedData['transactionsData'])
                } else if (response.data.statuscode == 401) {
                    setOpenSession(true)
                    setTimeout(() => { navigate("/") }, 2000)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const formatChangeDate = (dateParam: any) => {
        const inputDateString = dateParam;
        const date = new Date(inputDateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate
    }

    const copyToClipboard = (text: any) => {
        console.log(text)
        navigator.clipboard.writeText(text);
        setCopied(true);
        setTimeout(() => setCopied(false), 3000); // Reset copied state after 3 seconds
      };

    useEffect(() => {
        let arr = props.masterCountryArray.filter((item: any) => item.Code == selectedCurrency)
        if (arr && arr.length) {
            setCurrencySymbol(arr[0].CurrencySymbol)
        }
    }, [])

    useEffect(() => {
        console.log("statsData", statsData);
        if (statsData && statsData.hasOwnProperty(selectedCurrency)) {
            let currentAmount  = statsData[selectedCurrency].currentMonth ? statsData[selectedCurrency].currentMonth.totalDiscountAmount : 0
        let previousAmount  = statsData[selectedCurrency].previousMonth ? statsData[selectedCurrency].previousMonth.totalDiscountAmount : 0
        setTotalCredit(currentAmount);
        previousAmount? setPercentage(((currentAmount - previousAmount)/previousAmount * 100).toFixed(2)) : setPercentage('+ 0');
        } else {
            setTotalCredit(0);
            setPercentage('+ 0')
        }
        let filterData  = coupons.filter((item: any) => item.Currency == selectedCurrency)
        setFilterData(filterData)
        let arr = props.masterCountryArray.filter((item: any) => item.Code == selectedCurrency)
        if (arr && arr.length) {
            setCurrencySymbol(arr[0].CurrencySymbol)
        }
        
    }, [selectedCurrency])

    useEffect(() => {
        // const couponArray = getCouponMaster()
        getAllCoupons();
        let filterData  = coupons.filter((item: any) => item.Currency == selectedCurrency)
        setFilterData(filterData)
        getCouponsStats();
    }, [clicked])

    useEffect(() => {
        if (props.selectedDateRange && props.selectedDateRange.endDate != "" && props.selectedDateRange.startDate != "") {
            const s = new Date(props.selectedDateRange.startDate);
            const e = new Date(props.selectedDateRange.endDate);
            setSelectedRange({
                startDate: s,
                endDate: e
            })
        }
    }, [props.selectedDateRange]);

    

    // const [selectedStartDate, setSelectedStartDate] = useState<Date>(new Date());
    const [selectedStartDate, setSelectedStartDate] = useState<Date>(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());

    const handleDateChange = (startDate: Date, endDate: Date) => {
        localStorage.setItem("startDate", startDate.toString());
        localStorage.setItem("endDate", endDate.toString());
        setSelectedStartDate(startDate);
        setSelectedEndDate(endDate);
    };

    const handleOnClickEditStatus = (row: any) => {
        setStatusDivOpen(true);
        setselectedStatus(row.IsActive)
        console.log("selectedStatus",selectedStatus)
    };

    const cols : any = [
        {
            id: 'couponCode',
            name: 'Coupon Code',
            selector: (row: any) => <div style={{display: 'flex'}}><div>{row.CouponCode}</div><button style={{
                backgroundColor: 'inherit',
                border: 'none',
                display: 'flex',
                alignItems: 'center',
                cursor: "pointer"
            }} onClick={ () => copyToClipboard(row.CouponCode)}><FiCopy/></button></div>,
            width: "15%",
            grow: 1,
            style: {
                borderRight: '1px solid #cccccc',
                borderTop: 'none',
                color: '#333333'
            }
        },
        {
            id: 'couponId',
            name: 'Coupon Id',
            width: "30%",
            selector: (row: any) => row.CouponId,
            style: {
                color: '#333333',
            }
        },
        {
            id: 'createdOn',
            name: 'Created On',
            width: "12%",
            selector: (row: any) => formatChangeDate(row.createdAt),
            sortable: true
        },
        {
            id: 'status',
            name: 'Status',
            width: "15%",
            selector: (row: any) => {
                return <div>
                {(IsHoverStatus == row.CouponId && statusDivOpen) ? statusDiv(row) : <div>{row.IsActive ? 'Active' : 'Stopped'} 
                {IsHoverStatus == row.CouponId ? <img style={{marginLeft: '4px', cursor: 'pointer'}} src={edit} onClick={() => handleOnClickEditStatus(row)}/>: null}
                </div>}
                </div>
            } ,
            conditionalCellStyles: [
                {
                    when: (row : any )=> row.IsActive,
                    style: { color: '#0B8043' },
                },
                {
                    when: (row : any )=> !row.IsActive,
                    style: { color: '#C53929' },
                },
            ],
        },
        {
            id: 'discountPercent',
            name: 'Discount(%)',
            width: "12%",
            selector: (row: any) =>  row.Discount,
        },
        {
            id: 'maxAmount',
            name: 'Max Amount',
            width: "12%",
            selector: (row: any) => row.MaxPaymentAmount,
        },
        {
            id: 'usageLimit',
            name: 'Usage Limit',
            width: "12%",
            selector: (row: any) => row.UsageLimit
        },
        {
            id: 'validUpto',
            name: 'Valid Upto',
            width: "12%",
            selector: (row: any) => formatChangeDate(row.ValidUpto)
        }
    ]
    return (
        <div>
            <div className='coupon-stats-section'>

                <div className='stats-box-header'>
                    <div className='stats-box'>
                        <div style={{display: 'flex', justifyContent:'space-between', width: '100%', gap: '50px'}}>
                            <div style={{fontSize: '16px', fontWeight: '700', lineHeight: '21.86px'}}>Total Coupons Created</div>
                            <div style={{ color: "#6900B8", borderRadius: "0px", backgroundColor: '#6900B81A', display: 'flex', alignItems: 'center'}}><FaTag /></div>
                            
                        </div>
                        <div style={{fontSize: '35px', fontWeight: '700', lineHeight: '54.64px'}}>{coupons? (coupons.length).toLocaleString('en-IN') : 0 }</div>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '13px'}}>
                            <div className='stat-status-banner' style={{backgroundColor: '#0B80431A', color: '#0B8043'}}> <img src={active} /> { coupons ?coupons.filter((item:any)=>item.IsActive).length : 0}</div> 
                            <div className='stat-status-banner' style={{backgroundColor: '#B07F001A', color: '#B07F00'}}> <img src={paused} /> 0</div>
                            <div className='stat-status-banner' style={{backgroundColor: '#C539291A', color: '#C53929'}}> <img src={stopped} /> {coupons ? coupons.filter((item:any)=>!item.IsActive).length: 0}</div>
                        </div>
                    </div>
                    <div className='stats-box'>
                        <div style={{display: 'flex', justifyContent:'space-between', width: '100%', gap: '50px'}}>
                            <div style={{fontSize: '16px', fontWeight: '700', lineHeight: '21.86px'}}>Total Credit Disbursed</div>
                            <div style={{ color: "#6900B8", borderRadius: "0px", backgroundColor: '#6900B81A', display: 'flex', alignItems: 'center'}}><FaSquareArrowUpRight /></div>
                            
                        </div>
                        <div style={{fontSize: '35px', fontWeight: '700', lineHeight: '54.64px'}}>{currencySymbol}{(totalCredit).toLocaleString('en-IN')}</div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: '50px'}}>
                            <div className='stat-status-banner' style={{backgroundColor: '#7412BC1A', color: '#6900B8'}}> {percentage}%</div> 
                            
                            <div className='stat-status-banner' style={{padding: '0'}} > <Link to ='/home/admin/coupons/couponHistory' style={{ color: '#333333', fontSize: '14px', fontWeight: '600', textDecoration: 'none', borderBottom: '1.2px solid #333333'}}>View Statement <MdArrowOutward /></Link></div>
                        </div>
                    </div>
                </div>
                <div className='button-box-header'>
                    <div>
                    <div style={{ marginBottom: '3px', font: 'manrope', fontSize: '14px', fontWeight: '600'}}>Select Currency</div>
                    <CustomSelect
                            name='Currency'
                            value={selectedCurrency}
                            onChange={()=>{}}
                            defaultValue="INR"
                            >
                        {["INR","USD"].map((item: any) => (
                                        <MenuItem sx={menuItemStyle} key={item} value={item} onClick={() => setSelectedCurrency(item)}>
                                            {/* <Checkbox checked={selectedAdvertisers.indexOf(item) > -1} /> */}
                                            <ListItemText primary={item}/>
                                        </MenuItem>
                            ))}
                        </CustomSelect>
                    </div>
                    <div className='button-box' style={{width: '100%'}}>
                        <PurpleCustomButton
                        onClick={() => navigate("/home/admin/coupons/createNewCoupon")}
                        disable={true}
                        style={{width:'80%', display: 'flex', justifyContent: 'center', alignSelf: 'flex-end'}}
                        children={
                            <div className="space-between-div"> <img src={couponWhite} /> <div style={{fontSize: '14px', fontWeight: '700', lineHeight: '21.86px', marginLeft:'7px'}} >Create New</div></div>
                        }
                    /></div>

                    <div className='button-box'><DateCustomPicker onClick={() => setClicked(!clicked)}/></div>
                    
                </div>
            </div>
            <div className='table-container'>
            {loaderActive ? <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "200px"
            }}>
                <CustomLoader />
            </div>
                :<ProductTable
                    defaultSortFieldId='createdOn'
                    responsive={true}
                    highlightOnHover
                    data={filterData}
                    fixedHeader
                    pagination
                    // paginationIconFirstPage={false}
                    // paginationIconLastPage={false}
                    paginationPerPage={10}
                    paginationComponentOptions={{
                        noRowsPerPage: true,
                        rowsPerPageText: 'Rows per page:', // Customize the text for rows per page
                    }}
                    columns={cols}
                    onRowMouseEnter={handleStatusMouseEnter}
                    onRowMouseLeave={handleStatusMouseLeave}
                    customStyles={{
                        headCells: {
                            style: {
                                color: "#6900B8",
                                fontSize: "14px",
                                fontWeight: 600,
                                fontFamily: "Manrope",
                                overflow: 'wrap'
                            },
                        },
                        cells: {
                            style: {
                                color: "rgba(51, 51, 51, 0.7)",
                                fontSize: "13px",
                                fontFamily: "Manrope",
                                fontWeight: 600,
                                border: 'none'
                            },
                        },
                        rows: {
                            style: {
                                border: 'none !important'
                            },
                        },
                        headRow: {
                            style: {
                                border: 'none !important'
                            },
                        }
                    }}
                />} 
            </div>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                open={copied}
                message="Copied to clipboard"
                TransitionComponent={SlideTransition}
                
            />
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={openSession}
                onClose={handleSessionClose}
                message="Session Expired!"
            />
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    currencySymbol: state.user.currencySymbol,
    authToken: state.user.authToken,
    sessionId: state.user.sessionId,
    advertiserId: state.user.advertiserId,
    mastercouponArray : state.user.mastercouponArray,
    masterCountryArray: state.user.masterCountryArray,
    selectedDateRange: state.user.selectedDateRange
});

const mapDispatchToProps = {
    setCurrencySymbol,
    setAdvertiserId,
    setAuthToken,
    setSessionId,
    setMasterCouponArray,
    setMasterCountryArray,
    setSelectedDateRange
};

export default connect(mapStateToProps, mapDispatchToProps)(CouponScreen);