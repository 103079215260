//IMAGES
import ApexLegends from "./IMAGE/ApexLegend.svg";
import MoneyTap from "./IMAGE/MoneyTap.svg";
import Coinswitch from "./IMAGE/Coinswitch.svg";
import Spotify from "./IMAGE/Spotify.svg";
import PaySense from "./IMAGE/PaySense.svg";
import Asphalt from "./IMAGE/Asphalt.svg";
//Edit Images
// import edit from "../../IMAGE/editIcon.svg";
import active from "./IMAGE/active.svg";
import pending from "./IMAGE/pending.svg";
import paused from "./IMAGE/paused.svg";
import dlt from "./IMAGE/delete.svg";
import stopped from "./IMAGE/stopped.svg";
import yourAd from "./IMAGE/yourAdd.svg";
import { Button, Select } from "@mui/material";
import styled from "@emotion/styled";

export const languageArr: string[] = [
  "English",
  "Spanish",
  "French",
  "German",
  "Chinese",
  "Japanese",
  "Korean",
  "Italian",
  "Russian",
  "Arabic",
  "Portuguese",
  "Dutch",
  "Swedish",
  "Finnish",
  "Greek",
  "Turkish",
  "Hindi",
  "Bengali",
  "Urdu",
  "Punjabi",
  "Swahili",
  "Thai",
  "Vietnamese",
  "Czech",
  "Polish",
  "Hungarian",
  "Romanian",
  "Danish",
  "Norwegian",
  "Finnish",
  "Icelandic",
  "Slovak",
  "Catalan",
  "Filipino",
  "Malay",
  "Indonesian",
  "Farsi",
  "Hebrew",
  "Swedish",
  "Estonian",
  "Lithuanian",
  "Latvian",
  "Maltese",
  "Slovenian",
  "Croatian",
  "Serbian",
  "Bulgarian",
  "Macedonian",
  "Marathi",
  "Telgu",
  "Tamil",
  "Kannad",
];

export const EditArray: {
  image: string;
  name: string;
  className: string;
}[] = [
    {
      image: active,
      name: "active",
      className: "active-para",
    },
    {
      image: paused,
      name: "paused",
      className: "paused-para",
    },
    {
      image: stopped,
      name: "disabled",
      className: "stopped-para",
    },
    {
      image: dlt,
      name: "expired",
      className: "delete-para",
    },
    {
      image: pending,
      name: "pending",
      className: "delete-para",
    },
  ];
  export const EditArrayShow: {
    image: string;
    name: string;
    className: string;
  }[] = [
      {
        image: active,
        name: "active",
        className: "active-para",
      },
      {
        image: paused,
        name: "paused",
        className: "paused-para",
      },
      {
        image: stopped,
        name: "disabled",
        className: "stopped-para",
      },
      {
        image: pending,
        name: "pending",
        className: "delete-para",
      },
    ];

export const EditStatusArray: {
  image: string;
  name: string;
  className: string;
}[] = [
    {
      image: active,
      name: "active",
      className: "active-para",
    },
    {
      image: paused,
      name: "paused",
      className: "paused-para",
    },
    {
      image: stopped,
      name: "disabled",
      className: "stopped-para",
    },
  ];

export const HeaderArray: { rowName: string }[] = [
  {
    rowName: "Application Name",
  },
  {
    rowName: "Status",
  },
  {
    rowName: "Campaign",
  },
  {
    rowName: "Goal",
  },
  {
    rowName: "Daily Target",
  },
  {
    rowName: "Impressions",
  },
  {
    rowName: "Clicks",
  },
  {
    rowName: "Installs",
  },
];
export const HeaderArrayCampaign: { rowName: string }[] = [
  {
    rowName: "Group Name",
  },
  {
    rowName: "Status",
  },
  {
    rowName: "Headline",
  },
  {
    rowName: "Description",
  },
  {
    rowName: "Image",
  },
  {
    rowName: "Videos",
  },
  {
    rowName: "HTML",
  },
  // {
  //   rowName: "Reach",
  // },
  // {
  //   rowName: "Media Cost",
  // },
  // {
  //   rowName: "Total",
  // },
];
export const yourAdCampaignHeader: { rowName: string }[] = [
  {
    rowName: "Campaign",
  },
  {
    rowName: "Status",
  },
  {
    rowName: "Impressions",
  },
  {
    rowName: "Clicks",
  },
  {
    rowName: "CTR",
  },
  {
    rowName: "CPC",
  },
  {
    rowName: "CPM",
  },
  {
    rowName: "Reach",
  },
  {
    rowName: "Media Cost",
  },
  {
    rowName: "Total",
  },
];
export const CampaignAdArray: {
  id: number;
  groupName: string;
  status: string;
  impressions: number;
  clicks: number;
  ctr: number;
  cpc: number;
  cpm: number;
  reach: string;
  mediaCost: string;
  total: string;
  image: string;
  isChecked: boolean;
}[] = [
    {
      id: 1,
      groupName: "India_North",
      status: "Paused",
      image: yourAd,
      impressions: 0,
      clicks: 0,
      ctr: 0,
      cpc: 0,
      cpm: 0.14,
      reach: "10,875,876",
      mediaCost: "4,777.45",
      total: "4777.45",

      isChecked: false,
    },
    {
      id: 2,
      groupName: "Sample_Group",
      status: "Stopped",
      image: yourAd,
      impressions: 0,
      clicks: 0,
      ctr: 0,
      cpc: 0,
      cpm: 0.14,
      reach: "10,875,876",
      mediaCost: "4,777.45",
      total: "4777.45",
      isChecked: false,
    },

    {
      id: 3,
      groupName: "Audience_Above20",
      status: "Active",
      image: yourAd,
      impressions: 0,
      clicks: 0,
      ctr: 0,
      cpc: 0,
      cpm: 0.14,
      reach: "10,875,876",
      mediaCost: "4,777.45",
      total: "4777.45",
      isChecked: false,
    },

    {
      id: 4,
      groupName: "Audience_Below40",
      status: "Paused",
      image: yourAd,
      impressions: 0,
      clicks: 0,
      ctr: 0,
      cpc: 0,
      cpm: 0.14,
      reach: "10,875,876",
      mediaCost: "4,777.45",
      total: "4777.45",
      isChecked: false,
    },

    {
      id: 5,
      groupName: "India_WestCoast",
      status: "Active",
      image: yourAd,
      impressions: 0,
      clicks: 0,
      ctr: 0,
      cpc: 0,
      cpm: 0.14,
      reach: "10,875,876",
      mediaCost: "4,777.45",
      total: "4777.45",
      isChecked: false,
    },
    {
      id: 6,
      groupName: "Sample_Group_02",
      status: "Active",
      image: yourAd,
      impressions: 0,
      clicks: 0,
      ctr: 0,
      cpc: 0,
      cpm: 0.14,
      reach: "10,875,876",
      mediaCost: "4,777.45",
      total: "4777.45",
      isChecked: false,
    },
  ];
export const CampaignTotalArray: {
  id: number;
  groupName: string;
  status: string;
  impressions: number;
  clicks: number;
  ctr: number;
  cpc: number;
  cpm: number;
  reach: string;
  mediaCost: string;
  total: string;
  isChecked: boolean;
}[] = [
    {
      id: 1,
      groupName: "Video",
      status: "Paused",
      impressions: 0,
      clicks: 0,
      ctr: 0,
      cpc: 0,
      cpm: 0.14,
      reach: "10,875,876",
      mediaCost: "4,777.45",
      total: "4777.45",

      isChecked: false,
    },
    {
      id: 2,
      groupName: "WL_KYC_OLD",
      status: "Stopped",
      impressions: 0,
      clicks: 0,
      ctr: 0,
      cpc: 0,
      cpm: 0.14,
      reach: "10,875,876",
      mediaCost: "4,777.45",
      total: "4777.45",
      isChecked: false,
    },

    {
      id: 3,
      groupName: "WL_Parser",
      status: "Active",
      impressions: 0,
      clicks: 0,
      ctr: 0,
      cpc: 0,
      cpm: 0.14,
      reach: "10,875,876",
      mediaCost: "4,777.45",
      total: "4777.45",
      isChecked: false,
    },

    {
      id: 4,
      groupName: "WL_thebestappcss",
      status: "Paused",
      impressions: 0,
      clicks: 0,
      ctr: 0,
      cpc: 0,
      cpm: 0.14,
      reach: "10,875,876",
      mediaCost: "4,777.45",
      total: "4777.45",
      isChecked: false,
    },

    {
      id: 5,
      groupName: "WL_thembestappcss",
      status: "Active",
      impressions: 0,
      clicks: 0,
      ctr: 0,
      cpc: 0,
      cpm: 0.14,
      reach: "10,875,876",
      mediaCost: "4,777.45",
      total: "4777.45",
      isChecked: false,
    },
  ];

// export const totalArrayBottom:{
//   impressions:string;
//   clicks:number;
//   ctr:number;
//   cpc:number;
//   cpm:string;
//   reach:string
// mediaCost:string;
// total:string;
// }[] = [{

// }]

export const CampaignArray: {
  id: number;
  applicationName: string;
  status: string;
  campaign: string;
  goal: string;
  dailyTarget: string;
  impressions: number;
  clicks: number;
  installs: number;
  img: string;
  isChecked: boolean;
}[] = [
    {
      id: 1,
      applicationName: "Spotify Music",
      status: "Active",
      campaign: "Test App 01",
      goal: "Brand Awareness",
      dailyTarget: "100",
      impressions: 0,
      clicks: 0,
      installs: 0,
      img: Spotify,
      isChecked: false,
    },
    {
      id: 2,
      applicationName: "Apex Legends",
      status: "Paused",
      campaign: "Test App 02",
      goal: "App Install",
      dailyTarget: "50",
      impressions: 0,
      clicks: 0,
      installs: 0,
      img: ApexLegends,
      isChecked: false,
    },
    {
      id: 3,
      applicationName: "Asphalt",
      status: "Stopped",
      campaign: "Test App 03",
      goal: "Lead",
      dailyTarget: "200",
      impressions: 0,
      clicks: 0,
      installs: 0,
      img: Asphalt,
      isChecked: false,
    },
    {
      id: 4,
      applicationName: "Money Tap",
      status: "Active",
      campaign: "Test App 04",
      goal: "Lead",
      dailyTarget: "300",
      impressions: 0,
      clicks: 0,
      installs: 0,
      img: MoneyTap,
      isChecked: false,
    },
    {
      id: 5,
      applicationName: "PaySense",
      status: "Paused",
      campaign: "Test App 05",
      goal: "Sales",
      dailyTarget: "500",
      impressions: 0,
      clicks: 0,
      installs: 0,
      img: PaySense,
      isChecked: false,
    },
    {
      id: 6,
      applicationName: "Coinsswitch",
      status: "Stopped",
      campaign: "Test App 06",
      goal: "Brand Awareness",
      dailyTarget: "70",
      impressions: 0,
      clicks: 0,
      installs: 0,
      img: Coinswitch,
      isChecked: false,
    },
  ];
export const focusValuesDpd: { name: string; value: string }[] = [
  {
    name: "Install Volume",
    value: "installVolume",
  },
  {
    name: "In-app actions",
    value: "appActions",
  },
  // {
  //   name: "In-app actions value",
  //   value: "appActionsValue",
  // }
];

export const trackValuesDpd: { name: string; value: string }[] = [
  {
    name: "All user",
    value: "allUser",
  },
  {
    name: "User likely to perform in-app action",
    value: "inAppActions",
  },
];

export const TrackingPartnerDpd: { name: string; value: string }[] = [
  {
    name: "Adjust",
    value: "adjust",
  },
  {
    name: "AppMetrica",
    value: "appmetrica",
  },
  {
    name: "AppsFlyer",
    value: "appsflyer",
  },
  {
    name: "Branch",
    value: "branch",
  },
  {
    name: "Singular",
    value: "singular",
  },
  {
    name: "Other",
    value: "other",
  },
];

export const CountriesArray: { name: string; code: string }[] = [
  { name: "Afghanistan", code: "AF" },
  { name: "land Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: 'Cote D"Ivoire', code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: 'Korea, Democratic People"S Republic of', code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: 'Lao People"S Democratic Republic', code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montenegro", code: "ME" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia", code: "RS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

// export const apiURL = "http://65.0.210.45:10056";
// export const apiURL = "http://localhost:10056";
export const apiURL = "https://devApi.admattic.com";

// stripe live key
export const stripeKey = 'pk_live_51NLjJXSCiXEODCQTUQQ1SyG57NwBsBqmaFzSkYG4ReqqJPDMBiAk2GzXKcfywEpDQj7tUn9hCuWaSzIQMJJEA0g200C4cJssNt';

// stripe test key
// export const stripeKey = 'pk_test_51NlVJRSAluW353itf6xtsamlcFcooyUCZ66SEJsnj1ZoE02c602vl6P1fTgivLk8C7UZyCAL0ehfU4V9JL0b0U2F00IFDyTr4l';

export const secretKey = 'Adserving123';

export const customCheckBoxColor = "#6900b8";

export const DownloadButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  backgroundColor: '#6900B8' ,
  borderRadius: '2px',
  padding: '5px 10px',
  color: 'white',
  fontSize: '12px',
  fontWeight: 500,
  width: "auto",
  height: "40px",
  border: "none",
  textTransform: "unset",
  '&:hover': {
      backgroundColor: '#6900B8',
      color: 'white',
  },
}));