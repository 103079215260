// userActions.ts
export const SET_USERNAME = "SET_USERNAME";

//--CAMPAIGN INFO--//

export const CAMPAIGN_SUBTYPE = "CAMPAIGN_SUBTYPE";
export const APP_PLATFORM = "APP_PLATFORM";
export const APP_NAME = "APP_NAME";
export const APP_IMAGE_URL = "APP_IMAGE_URL";
export const PACKAGE_NAME = "PACKAGE_NAME";
export const CAMPAIGN_NAME = "CAMPAIGN_NAME";

//--CAMPAIGN SETTING--//

export const LOCATION = "LOCATION";
export const LANGUAGE = "LANGUAGE";
export const TRACKING = "TRACKING";
export const TRACKING_URL = "TRACKING_URL";
export const IMPRESSION_URL = "IMPRESSION_URL";
export const TOKEN_EVENT = "TOKEN_EVENT";

export const ADD_TOKEN_EVENT_OBJECT = "ADD_OBJECT";
export const REMOVE_TOKEN_EVENT_OBJECT = "REMOVE_OBJECT";
export const UPDATE_TOKEN = "UPDATE_TOKEN";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const ADVERTISERS_LIST = "  ADVERTISERS_LIST";
export const REPORT_SCREEN_TABLE = "REPORT_SCREEN_TABLE";

//--BUDGET BIDDING--//

export const BUDGET = "BUDGET";
export const FOCUS = "FOCUS";
export const TARGET = "TARGET";
export const COST_PER_INSTALL = "COST_PER_INSTALL";

//--AD GROUP--//
export const IMAGE_URL_ERROR = "IMAGE_URL_ERROR";
export const LOGO_URL_ERROR = "LOGO_URL_ERROR";

export const AD_GROUP_NAME = "AD_GROUP_NAME";
export const LOGO_URL = "LOGO_URL";
export const HEADLINE = "HEADLINE";
export const DESCRIPTION = "DESCRIPTION";
export const IMAGE_URL = "IMAGE_URL";
export const VIDEO_URL = "VIDEO_URL";
export const HTML_URL = "HTML_URL";
export const CURRENCY_SYMBOL = "CURRENCY_SYMBOL";
export const ADVERTISER_ID = "ADVERTISER_ID";
export const AUTH_TOKEN = "AUTH_TOKEN";
export const SESSION_ID = "SESSION_ID";
export const USER_EMAIL = "USER_EMAIL";
export const COUNTRY_CODE = "COUNTRY_CODE";
export const LOCATION_CODE = "LOCATION_CODE";
export const CAMPAIGN_ID = "CAMPAIGN_ID";
export const BUDGET_ID = "BUDGET_ID";

export const IMAGE_FILE = "IMAGE_FILE";
export const HTML_FILE = "HTML_FILE";
export const VIDEO_FILE = "VIDEO_FILE";
export const STATUS = "STATUS";
// export const EDIT_CAMPAIGN_ADD_OBJECT = 'ADD_OBJECT';
// export const EDIT_CAMPAIGN_UPDATE_OBJECT = 'UPDATE_OBJECT';
// export const EDIT_CAMPAIGN_DELETE_OBJECT = 'DELETE_OBJECT';
export const MASTER_COUNTRY_ARRAY = "MASTER_COUNTRY_ARRAY";
export const MASTER_LANGUAGE_ARRAY = "MASTER_LANGUAGE_ARRAY";
export const MASTER_COUPON_ARRAY = "MASTER_COUPON_ARRAY";
export const MASTER_MMP_ARRAY = "MASTER_MMP_ARRAY";
export const MASTER_PUBLISHER_ARRAY = "MASTER_PUBLISHER_ARRAY";
export const MASTER_TARGETING_TYPES_ARRAY = "MASTER_TARGETING_TYPES_ARRAY";
export const MASTER_MACROS_ARRAY = "MASTER_MACROS_ARRAY";
export const REMEMBER_ME_EMAIL = "REMEMBER_ME_EMAIL";
export const REMEMBER_ME_PASSWORD = "REMEMBER_ME_PASSWORD";
export const REMEMBER_ME_CHECK = "REMEMBER_ME_CHECK";
export const TOTAL_BALANCE = "TOTAL_BALANCE";

export const LOCATION_CHECK = "LOCATION_CHECK";
export const RESET_STATE = "RESET_STATE";

export const DATA_SOURCE = "DATA_SOURCE";
export const SEGMENT_NAME = "SEGMENT_NAME";
export const DATA_TYPE = "DATA_TYPE";
export const DATA_UPLOAD = "DATA_UPLOAD";
export const UPLOADED_DATA_URL = "UPLOADED_DATA_URL";

// error  messages

export const DATA_SOURCE_ERROR = "DATA_SOURCE_ERROR";
export const SEGMENT_NAME_ERROR = "SEGMENT_NAME_ERROR";
export const DATA_TYPE_ERROR = "DATA_TYPE_ERROR";
export const DATA_UPLOAD_ERROR = "DATA_UPLOAD_ERROR";
export const UPLOADED_DATA_URL_ERROR = "UPLOADED_DATA_URL_ERROR";

export const TRANSACTION_CURRENCY = "TRANSACTION_CURRENCY";
export const TEMP_TRANSACTION_CURRENCY = "TEMP_TRANSACTION_CURRENCY";
export const SELECTED_ADVERTISER_ID = "SELECTED_ADVERTISER_ID";
export const SELECTED_DATE_RANGE = "SELECTED_DATE_RANGE";

export const COUNTRY_CODE_ADVERTISER = "COUNTRY_CODE_ADVERTISER";
export const COMPANY_NAME = "COMPANY_NAME";
export const CAMPAIGN_STATUS = "CAMPAIGN_STATUS";
export const ADVERTISER_ID_ADMIN = "ADVERTISER_ID_ADMIN";
export const PERFORMANCE_REPORT = "PERFORMANCE_REPORT";

export const CLICKS = "CLICKS";
export const IMPRESSIONS = "IMPRESSIONS";
export const CONVERSION = "CONVERSION";
export const INSTALL = "INSTALL";
export const PAYOUT = "PAYOUT";
export const CTR = "CTR";
export const CPI = "CPI";
export const DAILY_AVG_BUDGET = "DAILY_AVG_BUDGET";
export const ADVERTISER_NAME = "ADVERTISER_NAME";
export const COMPANY_IMAGE = "COMPANY_IMAGE";
export const CREDIT_LIMIT = "CREDIT_LIMIT";

export const LAST_BILLED_AMOUNT = "LAST_BILLED_AMOUNT";
export const TOTAL_SPENDS_LAST_MONTH = "TOTAL_SPENDS_LAST_MONTH";
export const CREATED_AT = "CREATED_AT";
export const ACTIVATED_AT = "ACTIVATED_AT";
export const ADVERTISERS_SCREEN_TABLE = "ADVERTISERS_SCREEN_TABLE";
export const CAMPAIGN_TABLE_DATA = "CAMPAIGN_TABLE_DATA";
export const CROPPED_ID = "CROPPED_ID";

export const USER_JOURNEY_ADVERTISER = "USER_JOURNEY_ADVERTISER";
export const USER_JOURNEY_DATA = "USER_JOURNEY_DATA";

export const VIDEO_SOURCE_DB = "VIDEO_SOURCE_DB";
export const VIDEO_NAME_DB = "VIDEO_NAME_DB";
export const VIDEO_DURATION_DB = "VIDEO_DURATION_DB";
export const VIDEO_ARRAY_DB = "VIDEO_ARRAY_DB";

export const IMAGE_LIST_DB = "IMAGE_LIST_DB";
export const IMAGE_ARRAY_DB = "IMAGE_ARRAY_DB";

export const HEADLINE_ERROR = "HEADLINE_ERROR";
export const DESCRIPTION_ERROR = "DESCRIPTION_ERROR";

export const PROTECTED_DATA = "PROTECTED_DATA";

interface setHeadlineError {
  type: typeof HEADLINE_ERROR;
  payload: string;
}

interface setDescriptionError {
  type: typeof DESCRIPTION_ERROR;
  payload: string;
}

interface setVideoSource {
  type: typeof VIDEO_SOURCE_DB;
  payload: string;
}

interface setVideoName {
  type: typeof VIDEO_NAME_DB;
  payload: string;
}

interface setVideoDuration {
  type: typeof VIDEO_DURATION_DB;
  payload: number;
}

interface setVideoArray {
  type: typeof VIDEO_ARRAY_DB;
  payload: any[];
}

interface setImageList {
  type: typeof IMAGE_LIST_DB;
  payload: File[];
}

interface setImageArray {
  type: typeof IMAGE_ARRAY_DB;
  payload: any[];
}

interface setUserJourneyAd {
  type: typeof USER_JOURNEY_ADVERTISER;
  payload: any;
}
interface setUserJourneyData {
  type: typeof USER_JOURNEY_DATA;
  payload: any;
}

interface setAdvertiserScreenTable {
  type: typeof ADVERTISERS_SCREEN_TABLE;
  payload: any;
}

interface setId {
  type: typeof CROPPED_ID;
  payload: any;
}

interface setTableData {
  type: typeof CAMPAIGN_TABLE_DATA;
  payload: any;
}
interface setProtectedData {
  type: typeof PROTECTED_DATA;
  payload: any;
}

interface setCreatedDate {
  type: typeof CREATED_AT;
  payload: any;
}

interface setActivatedDate {
  type: typeof ACTIVATED_AT;
  payload: any;
}

interface setTotalSpendsLastMonth {
  type: typeof TOTAL_SPENDS_LAST_MONTH;
  payload: any;
}

interface setLastBilledAmount {
  type: typeof LAST_BILLED_AMOUNT;
  payload: any;
}

interface setCreditLimit {
  type: typeof CREDIT_LIMIT;
  payload: any;
}

interface setCompanyImage {
  type: typeof COMPANY_IMAGE;
  payload: string;
}

interface setAdvertiserName {
  type: typeof ADVERTISER_NAME;
  payload: string;
}

interface setClicks {
  type: typeof CLICKS;
  payload: string;
}

interface setImpression {
  type: typeof IMPRESSIONS;
  payload: string;
}
interface setConversion {
  type: typeof CONVERSION;
  payload: string;
}
interface setInstall {
  type: typeof INSTALL;
  payload: string;
}
interface setPayout {
  type: typeof PAYOUT;
  payload: string;
}
interface setCtr {
  type: typeof CTR;
  payload: string;
}
interface setCpi {
  type: typeof CPI;
  payload: string;
}
interface setDailyAvgBudget {
  type: typeof DAILY_AVG_BUDGET;
  payload: any;
}

interface setPerformaceReport {
  type: typeof PERFORMANCE_REPORT;
  payload: any;
}

interface setAdvertiserIdCampaign {
  type: typeof ADVERTISER_ID_ADMIN;
  payload: string;
}
export const USER_TYPE = "USER_TYPE";

interface setCampaignStatus {
  type: typeof CAMPAIGN_STATUS;
  payload: string;
}

interface setCompanyName {
  type: typeof COMPANY_NAME;
  payload: string;
}

interface setCountryCodeAdvertiser {
  type: typeof COUNTRY_CODE_ADVERTISER;
  payload: string;
}

interface setLocationCheck {
  type: typeof LOCATION_CHECK;
  payload: string;
}

interface SetUsernameAction {
  type: typeof SET_USERNAME;
  payload: string;
}

interface SetCampaignSubTypeAction {
  type: typeof CAMPAIGN_SUBTYPE;
  payload: string;
}

interface SetAppPlatformAction {
  type: typeof APP_PLATFORM;
  payload: string;
}

interface SetAppNameAction {
  type: typeof APP_NAME;
  payload: string;
}

interface SetCampaignNameAction {
  type: typeof CAMPAIGN_NAME;
  payload: string;
}

interface SetPackageNameAction {
  type: typeof PACKAGE_NAME;
  payload: string;
}

interface SetCurrencySymbolAction {
  type: typeof CURRENCY_SYMBOL;
  payload: any;
}

interface SetAuthTokenAction {
  type: typeof AUTH_TOKEN;
  payload: any;
}

interface SetSessionIdAction {
  type: typeof SESSION_ID;
  payload: any;
}

interface SetAdvertiserIdAction {
  type: typeof ADVERTISER_ID;
  payload: any;
}

interface SetPackageNameAction {
  type: typeof PACKAGE_NAME;
  payload: string;
}

interface SetAppImageUrlAction {
  type: typeof APP_IMAGE_URL;
  payload: string;
}

///////////////////////--------CAMPAIGN --- SETTING----------///////////////////////

interface SetLocationAction {
  type: typeof LOCATION;
  payload: string;
}

interface SetLanguageAction {
  type: typeof LANGUAGE;
  payload: string[];
}

interface SetTrackingAction {
  type: typeof TRACKING;
  payload: string;
}

interface SetTrackingUrlAction {
  type: typeof TRACKING_URL;
  payload: string;
}

interface SetImpressionUrlAction {
  type: typeof IMPRESSION_URL;
  payload: string;
}

interface SetTokenEventAction {
  type: typeof TOKEN_EVENT;
  payload: { token: string; eventName: string }[];
}

interface AddObjectTokenEventAction {
  type: typeof ADD_TOKEN_EVENT_OBJECT;
}

interface RemoveObjectTokenEventAction {
  type: typeof REMOVE_TOKEN_EVENT_OBJECT;
  payload: number; // index of the object to remove
}

interface UpdateTokenAction {
  type: typeof UPDATE_TOKEN;
  payload: { index: number; token: string };
}

interface UpdateEventAction {
  type: typeof UPDATE_EVENT;
  payload: { index: number; event: string };
}

///////////////////////--------CAMPAIGN --- SETTING----------///////////////////////

interface SetBudgetAction {
  type: typeof BUDGET;
  payload: string;
}

interface SetFocusAction {
  type: typeof FOCUS;
  payload: string;
}
interface SetTargetAction {
  type: typeof TARGET;
  payload: string;
}

interface SetCostPerInstallAction {
  type: typeof COST_PER_INSTALL;
  payload: string;
}

//-----CAMPAIGN SETTING----//
interface SetUserEmailAction {
  type: typeof USER_EMAIL;
  payload: any;
}

interface SetCountryCodeAction {
  type: typeof COUNTRY_CODE;
  payload: any;
}

interface SetMasterCountryArrayAction {
  type: typeof MASTER_COUNTRY_ARRAY;
  payload: any;
}

interface SetMasterLanguageArrayAction {
  type: typeof MASTER_LANGUAGE_ARRAY;
  payload: any;
}

interface SetMasterCouponArrayAction {
  type: typeof MASTER_COUPON_ARRAY;
  payload: any;
}

interface SetMasterMacrosArrayAction {
  type: typeof MASTER_MACROS_ARRAY;
  payload: any;
}

interface SetMasterMMPArrayAction {
  type: typeof MASTER_MMP_ARRAY;
  payload: any;
}

interface SetMasterPublisherArrayAction {
  type: typeof MASTER_PUBLISHER_ARRAY;
  payload: any;
}

interface SetMasterTargetingTypesArrayAction {
  type: typeof MASTER_TARGETING_TYPES_ARRAY;
  payload: any;
}

interface SetRememberMeCheckAction {
  type: typeof REMEMBER_ME_CHECK;
  payload: any;
}

interface SetRememberMeEmailAction {
  type: typeof REMEMBER_ME_EMAIL;
  payload: any;
}

interface SetRememberMePasswordAction {
  type: typeof REMEMBER_ME_PASSWORD;
  payload: any;
}

interface SetTotalBalanceAction {
  type: typeof TOTAL_BALANCE;
  payload: any;
}

interface SetAdGroupName {
  type: typeof AD_GROUP_NAME;
  payload: string;
}

interface SetLogoUrl {
  type: typeof LOGO_URL;
  payload: string;
}

interface SetHeadline {
  type: typeof HEADLINE;
  payload: string[];
}

interface SetDescription {
  type: typeof DESCRIPTION;
  payload: string[];
}

interface SetImageUrl {
  type: typeof IMAGE_URL;
  payload: string[];
}

interface SetVideoUrl {
  type: typeof VIDEO_URL;
  payload: string[];
}

interface SetHtmlUrl {
  type: typeof HTML_URL;
  payload: string[];
}
interface SetLocationCode {
  type: typeof LOCATION_CODE;
  payload: string;
}
interface SetBudgetId {
  type: typeof BUDGET_ID;
  payload: string;
}
interface SetCampaignId {
  type: typeof CAMPAIGN_ID;
  payload: string;
}

interface SetImageFile {
  type: typeof IMAGE_FILE;
  payload: string;
}

interface SetHtmlFile {
  type: typeof HTML_FILE;
  payload: File[];
}

interface SetVideoFile {
  type: typeof VIDEO_FILE;
  payload: File[];
}

interface SetStatus {
  type: typeof STATUS;
  payload: string;
}

interface SetImageUploadError {
  type: typeof IMAGE_URL_ERROR;
  payload: string;
}

interface SetLogoError {
  type: typeof LOGO_URL_ERROR;
  payload: string;
}
interface resetState {
  type: typeof RESET_STATE;
}

interface setDataSource {
  type: typeof DATA_SOURCE;
  payload: string;
}

interface setSegmentName {
  type: typeof SEGMENT_NAME;
  payload: string;
}

interface setDataType {
  type: typeof DATA_TYPE;
  payload: string;
}

interface setDataUpload {
  type: typeof DATA_UPLOAD;
  payload: File[];
}

interface setUploadedUrl {
  type: typeof UPLOADED_DATA_URL;
  payload: string;
}

interface setDataSourceError {
  type: typeof DATA_SOURCE_ERROR;
  payload: string;
}

interface setSegmentNameError {
  type: typeof SEGMENT_NAME_ERROR;
  payload: string;
}

interface setDataTypeError {
  type: typeof DATA_TYPE_ERROR;
  payload: string;
}

interface setDataUploadError {
  type: typeof DATA_UPLOAD_ERROR;
  payload: File[];
}

interface setUploadedUrlError {
  type: typeof UPLOADED_DATA_URL_ERROR;
  payload: string;
}

interface setTransactionCurrency {
  type: typeof TRANSACTION_CURRENCY;
  payload: string;
}

interface setTempTransactionCurrency {
  type: typeof TEMP_TRANSACTION_CURRENCY;
  payload: string;
}

interface setSelectedDateRange {
  type: typeof SELECTED_DATE_RANGE;
  payload: object;
}

interface setSelectedAdvertiserId {
  type: typeof SELECTED_ADVERTISER_ID;
  payload: string;
}

interface setUserType {
  type: typeof USER_TYPE;
  payload: string;
}

interface setAdvertisersList {
  type: typeof ADVERTISERS_LIST;
  payload: any[];
}

interface setReportScreenTable {
  type: typeof REPORT_SCREEN_TABLE;
  payload: any[];
}

export type UserActionTypes =
  | setHeadlineError
  | setDescriptionError
  | setVideoSource
  | setVideoName
  | setVideoDuration
  | setVideoArray
  | setImageList
  | setImageArray
  | setUserJourneyAd
  | setUserJourneyData
  | setReportScreenTable
  | setAdvertiserScreenTable
  | setId
  | setTableData
  | setProtectedData
  | setCreatedDate
  | setActivatedDate
  | setTotalSpendsLastMonth
  | setLastBilledAmount
  | setCreditLimit
  | setCompanyImage
  | setAdvertiserName
  | setClicks
  | setImpression
  | setConversion
  | setInstall
  | setPayout
  | setCtr
  | setCpi
  | setDailyAvgBudget
  | setPerformaceReport
  | setAdvertiserIdCampaign
  | setAdvertisersList
  | setUserType
  | setCampaignStatus
  | setCompanyName
  | setCountryCodeAdvertiser
  | setSelectedDateRange
  | setSelectedAdvertiserId
  | setTransactionCurrency
  | setTempTransactionCurrency
  | setDataSource
  | setSegmentName
  | setDataType
  | setUploadedUrl
  | setDataUpload
  | setDataSourceError
  | setSegmentNameError
  | setDataTypeError
  | setUploadedUrlError
  | setDataUploadError
  | resetState
  | setLocationCheck
  | SetImageUploadError
  | SetLogoError
  | SetStatus
  | SetImageFile
  | SetHtmlFile
  | SetVideoFile
  | SetBudgetId
  | SetCampaignId
  | SetPackageNameAction
  | SetAppImageUrlAction
  | SetUsernameAction
  | SetCampaignSubTypeAction
  | SetAppPlatformAction
  | SetAppNameAction
  | SetCampaignNameAction
  | SetLocationAction
  | SetLanguageAction
  | SetTrackingAction
  | SetTrackingUrlAction
  | SetImpressionUrlAction
  | SetTokenEventAction
  | AddObjectTokenEventAction
  | RemoveObjectTokenEventAction
  | UpdateTokenAction
  | UpdateEventAction
  | SetBudgetAction
  | SetFocusAction
  | SetTargetAction
  | SetCostPerInstallAction
  | SetCurrencySymbolAction
  | SetAuthTokenAction
  | SetAdvertiserIdAction
  | SetSessionIdAction
  | SetUserEmailAction
  | SetCountryCodeAction
  | SetAdGroupName
  | SetLogoUrl
  | SetHeadline
  | SetDescription
  | SetImageUrl
  | SetVideoUrl
  | SetHtmlUrl
  | SetLocationCode
  | SetHtmlUrl
  | SetMasterCountryArrayAction
  | SetMasterCouponArrayAction
  | SetMasterLanguageArrayAction
  | SetMasterPublisherArrayAction
  | SetMasterMacrosArrayAction
  | SetMasterTargetingTypesArrayAction
  | SetMasterMMPArrayAction
  | SetRememberMeCheckAction
  | SetRememberMeEmailAction
  | SetRememberMePasswordAction
  | SetTotalBalanceAction
  | SetCurrencySymbolAction;
