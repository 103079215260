import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../Home";
import SignIn from "../OnBoarding/SignIn";
import SignUp from "../OnBoarding/SignUp";
import PasswordReset from "../OnBoarding/PasswordRest";
import ResetPassword from "../OnBoarding/ResetPassword";
import EmailCheck from "../OnBoarding/EmailCheck";
import ForgotPassword from "../OnBoarding/ForgotPassword";

const AppRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/" Component={SignIn} />
      <Route path="/signUp" Component={SignUp} />
      <Route path="/passwordReset" Component={PasswordReset} />
      <Route path="/resetPassword" Component={ResetPassword} />
      <Route path="/emailCheck" Component={EmailCheck} />
      <Route path="/forgotPage" Component={ForgotPassword} />
      <Route path="/home/*" element={<HomePage />} />
    </Routes>
  );
};

export default AppRoutes;
