import React, { FC, useEffect, useState } from 'react';
import '../../../CSS/Coupon.css';
import axios from "axios";
import ProductTable from 'react-data-table-component'
import { Checkbox, CircularProgress, ListItemText, MenuItem, Select, SelectChangeEvent, Snackbar } from '@mui/material';
import { styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { apiURL, customCheckBoxColor, secretKey } from '../../../Constant';
import DateCustomPicker from '../../custom/DateCustomPicker';
import { RootState } from '../../../REDUX/Reducers/rootReducer';
import { setAdvertiserId, setAuthToken, setCurrencySymbol, setSessionId, setMasterCouponArray } from '../../../REDUX/Actions/userActionIndex';
import { connect } from 'react-redux';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import BackCustom from '../../custom/BackCustom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomLoader from '../../custom/CustomLoader';

const CryptoJS = require('crypto-js')


const CustomSelect = styled(Select)(({ theme }) => ({
    fontSize: "14px",
    fontWeight: "600",
    fontFamily: "Manrope",
    width: 300,
    height: "40px",
    marginRight: '10px',
    borderRadius: '3px',
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
    {
        border: "1px solid black",
    }
}));

const menuItemStyle = {
    fontSize: "14px",
    fontFamily: "Manrope",
    fontWeight: "700",
    '& .MuiMenuItem-selected': {
        backgroundColor: "#6800b8",
    },
}

function SlideTransition(props: TransitionProps) {
    return <Slide {...props} direction="up" />;
  }
interface CouponHistoryProps {
    advertiserId: any;
    authToken: any;
    sessionId: any;
    dates?: {
        startDate: string;
        endDate: string;
    };
}

const CouponHistoryScreen: FC<CouponHistoryProps> = (props) => {

    const [coupons, setCoupons] = useState<any[]>([])
    const [filterData, setFilterData] = useState<any[]>([])
    const [copied, setCopied] = useState(false);
    const [advertiserID, setAdvertiserID] = useState<any>(props.advertiserId)
    const [sessionID, setSessionID] = useState<any>(props.sessionId)
    const [authTokenID, setAuthTokenID] = useState<any>(props.authToken)
    const [openSession, setOpenSession] = React.useState(false);
    const [loaderActive, setLoaderActive] = useState<boolean>(false)

    const [advertisersData, setAdvertisersData] = useState<any[]>([])
    const [selectedAdvertisers, setSelectedAdvertisers] = React.useState<string[]>([])
    const navigate = useNavigate()

    const handleSessionClose = () => {
        setOpenSession(false);
    };

    const handleChange = (event: SelectChangeEvent<any>) => {
        const {
            target: { value },
        } = event;
        setSelectedAdvertisers(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const getAllCoupons = () => {
        setLoaderActive(true)
        const encryptAdvertiserID = CryptoJS.AES.encrypt(JSON.stringify(advertiserID), secretKey).toString()
        let headers = {
            'Content-Type': 'application/json',
            'advertiserid': encryptAdvertiserID,
            'authtoken': authTokenID,
            'sessionid': sessionID
        };
        let jsonParams = { 
            advertiserIds: selectedAdvertisers, 
            startDate: props.dates?  formatChangeDate(props.dates.startDate): '', 
            endDate: props.dates ? formatChangeDate(props.dates.endDate): ''
        };
        console.log("coupon history params: ", jsonParams)
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(jsonParams), secretKey).toString();
        axios
            .post(`${apiURL}/api/admin/getCouponHistory`, { params: encryptedData }, { headers: headers })
            .then((response) => {
                setLoaderActive(false)
                if (response.data.status == true) {
                    const resData = response.data.data;
                    const bytes = CryptoJS.AES.decrypt(resData, secretKey);
                    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
                    const parsedData = JSON.parse(decryptedData);
                    console.log("coupon history: ", parsedData)
                    setCoupons(parsedData)
                    // setFilterDasta(parsedData['transactionsData'])
                } else if (response.data.statuscode == 401) {
                    setOpenSession(true)
                    setTimeout(() => { navigate("/") }, 2000)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const formatChangeDate = (dateParam: any) => {
        const inputDateString = dateParam;
        const date = new Date(inputDateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate
    }

    const copyToClipboard = (text: any) => {
        console.log(text)
        navigator.clipboard.writeText(text);
        setCopied(true);
        setTimeout(() => setCopied(false), 3000); // Reset copied state after 3 seconds
      };

    useEffect(() => {
        const startDate = localStorage.getItem("startDate");
        const endDate = localStorage.getItem("endDate");

        if (startDate != null && endDate != null) {
            const s = new Date(startDate);
            const e = new Date(endDate);
            setSelectedStartDate(s);
            setSelectedEndDate(e);

            const startdate = formatChangeDate(s)
            const enddate = formatChangeDate(e)
            const result = coupons.filter((coupon) => {
                const createdat = formatChangeDate(coupon.createdAt)
                return createdat >= startdate && createdat <= enddate
            })
            setFilterData(result)
        } else {
            if (props.dates != null) {
                const s = new Date(props.dates.startDate);
                const e = new Date(props.dates.endDate);
                setSelectedStartDate(s);
                setSelectedEndDate(e);
                const startdate = formatChangeDate(s)
                const enddate = formatChangeDate(e)
                const result = coupons.filter((coupon) => {
                    const createdat = formatChangeDate(coupon.createdAt)
                    return createdat >= startdate && createdat <= enddate
                })
                setFilterData(result)
            }
        }
    }, [coupons, props.dates]);

    // const [selectedStartDate, setSelectedStartDate] = useState<Date>(new Date());
    const [selectedStartDate, setSelectedStartDate] = useState<Date>(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());

    const handleDateChange = (startDate: Date, endDate: Date) => {
        localStorage.setItem("startDate", startDate.toString());
        localStorage.setItem("endDate", endDate.toString());
        setSelectedStartDate(startDate);
        setSelectedEndDate(endDate);
    };

    const getAllAdvertisers = () => {
        setAdLoader(true)
        const encryptAdvertiserID = CryptoJS.AES.encrypt(JSON.stringify(props.advertiserId), secretKey).toString()
        let headers = {
            'Content-Type': 'application/json',
            'advertiserid': encryptAdvertiserID,
            'authtoken': props.authToken,
            'sessionid': props.sessionId
        };
        axios
            .get(`${apiURL}/api/admin/advertisers`, { headers: headers })
            .then((response) => {
                if (response.data.status == true) {
                    const resData = response.data.data;
                    const bytes = CryptoJS.AES.decrypt(resData, secretKey);
                    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
                    const parsedData = JSON.parse(decryptedData);
                    setAdvertisersData(parsedData)
                    console.log("advertisersData",advertisersData)
                    // setFilterData(parsedData['transactionsData'])
                } else if (response.data.statuscode == 401) {
                    setOpenSession(true)
                    setTimeout(() => { navigate("/") }, 2000)
                }
                setAdLoader(false)

            })
            .catch((error) => {
                console.log(error)
            })
            setAdLoader(false)

    }

    useEffect(() => {
        // const couponArray = getCouponMaster()
        getAllAdvertisers();
        getAllCoupons();
    }, [])

    useEffect(() => {
        getAllCoupons();
    }, [selectedAdvertisers, props.dates])

    const cols = [
        {
            id: 'date',
            name: 'Date',
            selector: (row: any) => formatChangeDate(row.createdAt),
            width: "12%",
            sortable: true,
            style: {
                borderTop: 'none'
            }
        },
        {
            id: 'advertiserName',
            name: 'Name of Advertiser',
            width: "26%",
            selector: (row: any) => row.advertisername
        },
        {
            id: 'couponCode',
            name: 'Coupon Code',
            width: "15%",
            selector: (row: any) => row.couponcode,
        },
        {
            id: 'couponId',
            name: 'Coupon UId',
            width: "12%",
            selector: (row: any) => row.CouponId,
        },
        {
            id: 'amount',
            name: 'Amount',
            width: "12%",
            selector: (row: any) => row.Currency + " " + row.DiscountAmount,
        },
        {
            id: 'paymentAmount',
            name: 'Payment Amount',
            width: "12%",
            selector: (row: any) => row.Currency + " " + row.PaymentAmount,
        },
        {
            id: 'notes',
            name: 'Notes',
            width: "10%",
            selector: (row: any) => <div style={{ cursor: "pointer", color: "#6900B8", textDecorationLine: "underline" }} onClick={() => {}}>{row.InvoiceID == null ? "View" : "Receipt"}</div>
        },
    ]

    const [adLoader, setAdLoader] = useState(false)

    const CustomIconComponent: FC<{ loading: boolean }> = ({ loading }) => (
        <div style={{ position: 'relative', display: "flex", alignItems: "center", marginRight: "8px", cursor: "pointer" }}>
            {loading ? (
                <CircularProgress style={{ color: customCheckBoxColor }} size="1rem" />
            ) : (
                <ExpandMoreIcon sx={{
                    color: "#000000",
                    opacity: 0.55
                }} />
            )}
        </div>
    );

    return (
        <div>
            <div className='coupon-stats-section'>
                <div>
                    <BackCustom onClick={()=> window.location.replace("/home/admin/coupons")} />
                    <div style={{
                        font:'Manrope',
                        fontSize: '15px',
                        fontWeight: '700',
                        color: '#333333',
                        marginTop: '-5px'
                    }}>Coupons &gt; Usage Statement</div>
                </div>
                <div>
                <div style={{fontSize: '14px', fontWeight: '700', lineHeight: '19.12px', opacity: '0.8'}}>Select Brand / Agency</div>
                        <CustomSelect
                            multiple
                            value={selectedAdvertisers}
                            IconComponent={(props: any) => <CustomIconComponent loading={adLoader === true && advertisersData.length === 0} {...props} />}
                            onChange={handleChange}
                            renderValue={(selected: any) => {
                                // console.log("selected",selected)
                                let result: any = [];
                                advertisersData.map((item)=> {
                                    // console.log("index",selected.indexOf(item.advertiserId))
                                    if (selected.indexOf(item.advertiserId) > -1) {
                                        result.push(item.advertiserName) 
                                    }
                                })
                                return result ? result.join(', ') : ''
                            }}
                        >
                            {/* <MenuItem sx={menuItemStyle} value="selectAll" onClick={handleToggleAll}>
                                <Checkbox checked={selectAll} />
                                <ListItemText primary="Select All" />
                            </MenuItem> */}
                            {adLoader == false ? (advertisersData.length != 0 ? advertisersData.map((item: any) => (
                                <MenuItem sx={menuItemStyle} key={item.advertiserId} value={item.advertiserId}>
                                    <Checkbox checked={selectedAdvertisers.indexOf(item.advertiserId) > -1} style={{ color: customCheckBoxColor }} />
                                    <ListItemText primary={item.advertiserName} secondary={item.id}/>
                                </MenuItem>
                            )) : <MenuItem className='menuItemStyle'>No Advertiser found</MenuItem>) : <div style={{
                                display: "flex",
                                justifyContent: "center"
                            }}><CircularProgress style={{ color: customCheckBoxColor }} size="1.5rem" /></div>}
                        </CustomSelect>
                </div>
                <div>
                <DateCustomPicker onDateChange={handleDateChange} onClick={() => {
                        const startdate = formatChangeDate(selectedStartDate)
                        const enddate = formatChangeDate(selectedEndDate)
                        const result = coupons.filter((coupon) => {
                            const createdat = formatChangeDate(coupon.createdAt)
                            return createdat >= startdate && createdat <= enddate
                        })
                        setFilterData(result)
                    }} />
                </div>
            </div>
            <div className='table-container' style={{marginTop: '40px', padding: '0 20px'}}>
            {loaderActive ? <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "200px"
            }}>
                <CustomLoader />
            </div>
                :<ProductTable
                    defaultSortFieldId='createdOn'
                    responsive={true}
                    highlightOnHover
                    data={filterData}
                    fixedHeader
                    pagination
                    // paginationIconFirstPage={false}
                    // paginationIconLastPage={false}
                    paginationPerPage={10}
                    paginationComponentOptions={{
                        noRowsPerPage: true,
                        rowsPerPageText: 'Rows per page:', // Customize the text for rows per page
                    }}
                    columns={cols}
                    customStyles={{
                        headCells: {
                            style: {
                                color: "#6900B8",
                                fontSize: "14px",
                                fontWeight: 600,
                                fontFamily: "Manrope",
                                overflow: 'wrap'
                            },
                        },
                        cells: {
                            style: {
                                color: "rgba(51, 51, 51, 0.7)",
                                fontSize: "13px",
                                fontFamily: "Manrope",
                                fontWeight: 600,
                                border: 'none'
                            },
                        },
                        rows: {
                            style: {
                                border: 'none !important'
                            },
                        }
                    }}
                />}
            </div>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                open={copied}
                message="Copied to clipboard"
                TransitionComponent={SlideTransition}
                
            />
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={openSession}
                onClose={handleSessionClose}
                message="Session Expired!"
            />
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    currencySymbol: state.user.currencySymbol,
    authToken: state.user.authToken,
    sessionId: state.user.sessionId,
    advertiserId: state.user.advertiserId,
    mastercouponArray : state.user.mastercouponArray,
});

const mapDispatchToProps = {
    setCurrencySymbol,
    setAdvertiserId,
    setAuthToken,
    setSessionId,
    setMasterCouponArray
};

export default connect(mapStateToProps, mapDispatchToProps)(CouponHistoryScreen);