import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import leftSideBG from "../../IMAGE/leftSideBG.svg";
import rightSideBG from "../../IMAGE/rightSideBG.svg";
import logo from "../../IMAGE/logo.svg";
import "../../CSS/SignIn.css";
import { BiSolidErrorCircle } from "react-icons/bi";
import { MdKeyboardBackspace } from "react-icons/md";
import axios from 'axios';
import { apiURL } from "../../Constant";
const CryptoJS = require('crypto-js')
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const EmailCheck: React.FC = () => {
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const nextPage = async () =>  {
      try {
        let jsonParams = {
          UserEmailID: location.state.email,
          ForgotPassKey: "1234"
        };
        const secretKey = "Adserving123";
        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(jsonParams),
          secretKey
        ).toString();
        const params = {
          params:encryptedData
        }
        const response = await axios.post(`${apiURL}/api/sendResetMail`, params);

        if (response.data.status == true) {
          // window.location.reload()
        
          // const bytes = CryptoJS.AES.decrypt(response.data.data, secretKey);
          // const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
          // const parsedData = JSON.parse(decryptedData);
        } 
      } catch (error) {
        console.log("error", error);
      }
  }

  return (
    <div className="signIn-main">
      <div className="left-img-div">
        <img src={leftSideBG} alt="leftSide" />
      </div>
      <div className="center-div">
        <img className="admattic-logo" src={logo} alt="logo" />

        <div className="mid-box">
          <h2>CHECK YOUR EMAIL</h2>

          <div className="details-box">
            <span className="grey-text center-align margin-top">We sent a password reset link to <br/> {location.state.email} </span>
          </div>
          <button className="signIn-btn btn-width open-email" onClick={() => window.open('https://mail.google.com/mail/u/0/#inbox')}>Open Email app</button>
        </div>

        <div className="resend-email center-align">
            <span className="grey-text" >Didn't recieve the email?</span>
            &nbsp;
            {/* <a className="resend-btn">Click to Resend</a> */}
            <button className="resend-btn" style={{border: "none", backgroundColor: "transparent"}} onClick={nextPage}>Click to Resend</button>
        </div>

        <a href="/" className="signup-box back" >
          <MdKeyboardBackspace/>&nbsp;
          <p>Back to sign in</p>
        </a>
      </div>
      <div className="right-img-div">
        <img src={rightSideBG} alt="rightSide" />
      </div>
    </div>
  );
};

export default EmailCheck;
