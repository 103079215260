import { Select, Snackbar } from "@mui/material";
import React, { FC, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CopyToClipboard from '../../../IMAGE/copyClipboard.svg'
import BackCustom from "../../custom/BackCustom";
import { setAdvertiserId, setAuthToken, setCountryCode, setCurrencySymbol, setMasterCountryArray, setSelectedAdvertiserId, setSessionId, setTotalBalance } from "../../../REDUX/Actions/userActionIndex";
import { RootState } from "../../../REDUX/Reducers/rootReducer";
import { connect } from "react-redux";
import { apiURL, secretKey } from "../../../Constant";
import axios from "axios";
import edit from "../../../IMAGE/edit.svg";
import CreateProfileIcon from '../../../IMAGE/createProfile.svg';
import DashboardScreen from "../../pages/DashboardScreen";
import { useDispatch } from "react-redux";
const CryptoJS = require('crypto-js')

interface HeaderProps {
    currencySymbol: any;
    authToken: any;
    sessionId: any;
    advertiserId: any;
    countryCode: any;
    totalBalance: any;
    masterCountryArray: any;
    selectedAdvertiserId: string;
}

const SelectedAdvertiserScreen: FC<HeaderProps> = (props) => {

    const { currencySymbol, authToken, sessionId, advertiserId, countryCode, totalBalance, masterCountryArray, selectedAdvertiserId } = props

    // const [country, setCountry] = useState("");
    const [bal, setBal] = useState<any>("")
    const [name, setName] = useState<any>("")
    const [orgname, setOrgName] = useState<any>("")
    const [advDetails, setAdvDetails] = useState<any>("")
    const [email, setEmail] = useState<string>("")
    const [image, setImage] = useState("")
    const [country, setCountry] = useState("")
    const [id, setId] = useState<string>("");
    const [createdOpen, setCreatedOpen] = React.useState(false);
    const [openSession, setOpenSession] = React.useState(false);

    const handleSessionClose = () => {
        setOpenSession(false);
    };

    const navigate = useNavigate()

    const getUserDetails = async () => {
        try {
            const encryptAdvertiserID = CryptoJS.AES.encrypt(JSON.stringify(advertiserId), secretKey).toString()
            let headers = {
                'Content-Type': 'application/json',
                'advertiserid': encryptAdvertiserID,
                'authtoken': authToken,
                'sessionid': sessionId
            };

            let jsonParams = { advertiserId: selectedAdvertiserId };
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(jsonParams), secretKey).toString();

            const response = await axios.post(`${apiURL}/api/user/getUser`, { params: encryptedData }, { headers: headers });
            if (response.data.status == true) {
                const resData = response.data.data;
                const bytes = CryptoJS.AES.decrypt(resData, secretKey);
                const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
                const parsedData = JSON.parse(decryptedData);
                setId(parsedData['id'])
                setEmail(parsedData['Email'])
                setCountry(parsedData['Country'])
                setName(parsedData['Name'])
                setOrgName(parsedData['OrganizationName'] == null ? "" : parsedData['OrganizationName'])
                // setUserTypeName(parsedData['UserType'] == null ? "" : parsedData['UserType'])
                setBal(parsedData['BalanceAmount'] == null ? "0" : parsedData['BalanceAmount'])
                // localStorage.setItem("totalBalance", parsedData['BalanceAmount'])
                // dispatch(setTotalBalance(parsedData['BalanceAmount']))
                if (parsedData['ProfilePicture'] == null) {
                    setImage("")
                } else {
                    // const uint8Array = new Uint8Array(resData['ProfilePicture']['data']);
                    const uint8Array = new Uint8Array(parsedData['ProfilePicture']['data']);
                    const blob = new Blob([uint8Array]);
                    const dataUrl = URL.createObjectURL(blob);
                    setImage(dataUrl);
                    // const blob2 = new Blob([Buffer.from(resData['ProfilePicture']['data'])], { type: 'application/octet-stream' });
                }
            } else if (response.data.statuscode == 401) {
                setOpenSession(true)
                setTimeout(() => { navigate("/") }, 1000)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const location = useLocation();

    useEffect(() => {
        if (location) {
            if (location.state) {
                setAdvDetails(location.state.advertiserDetails)
                // handleCurrency()
            }
        }
    }, [location])

    useEffect(() => {
        getUserDetails()
    }, [])

    useEffect(() => {
        handleCurrency()
    }, [country])

    const [findCountry, setFindCountry] = useState<any>()
    const dispatch = useDispatch()

    const handleCurrency = () => {
        let countryArray = []
        countryArray = masterCountryArray
        if (countryArray && Array.isArray(countryArray)) {
            let countryFind = countryArray.find((element: any) => { return country === element.Country })

            setFindCountry(countryFind)
            if (countryFind != undefined) {
                // localStorage.setItem("currency", countryFind.CurrencySymbol);
                // localStorage.setItem("countryCode", countryFind.Code)
                // localStorage.setItem("budgetLimit", countryFind.BudgetLimit)
                dispatch(setCurrencySymbol(countryFind.CurrencySymbol))
                // dispatch(setCountryCode(countryFind.Code))
                // dispatch(setCountryCodeAdvertiser(countryFind.CountryCode));
                // setCurrency(countryFind.CurrencySymbol)

            }
        }
    }

    return <div>
        <div className="div-display">
            <div>
                <BackCustom onClick={() => window.history.back()} />
                <div className="heading">Brands  {">"} {name}</div>
            </div>
            <div>
                <div className='profile-edit'>
                    <img src={image != "" ? image : CreateProfileIcon} height="80px" width="80px" style={{ borderRadius: "4px", marginRight: "15px" }} />
                    <div >
                        <div className='sub-heading' style={{
                            fontWeight: "600",
                            color: "black",
                            display: "flex",
                            alignItems: "center",
                            fontSize: '13px'
                        }}>{name}</div>
                        <div className='sub-heading' style={{
                            textDecorationLine: "underline",
                            fontWeight: "400",
                            color: "#999999",
                            display: "flex",
                            alignItems: "center",
                        }} onClick={() => {
                            navigator.clipboard.writeText(email)
                            setCreatedOpen(true)
                        }
                        }>{email}<img src={CopyToClipboard} style={{
                            margin: "0px 10px",
                            cursor: "pointer"
                        }} /></div>
                        <div className='sub-heading' style={{
                            color: "#6900B8",
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer"
                        }} onClick={() => navigate('/home/admin/advertisers/createAdvertiser', {
                            state: {
                                typeOpen: "edit",
                                advertiserId: selectedAdvertiserId
                            }
                        })}>View / <img src={edit} style={{
                            margin: "0px 5px",
                        }} />Edit Details</div>
                    </div>
                </div>
            </div>
        </div>

        <div className="div-display">
            <div className='acc-detail'>
                <div className='acc-details-content'>
                    <div className='acc-subheading'>Brand Unique ID</div>
                    <div className='acc-subheading-content'>{id}</div>
                </div>
            </div>
            <div className='acc-detail'>
                <div className='acc-details-content'>
                    <div className='acc-subheading'>No. of Campaigns</div>
                    <div className='acc-subheading-content'>{advDetails.campaignsCount} </div>
                </div>
            </div>
            <div className='acc-detail'>
                <div className='acc-details-content'>
                    <div className='acc-subheading'>Wallet Balance</div>
                    <div className='acc-subheading-content'>{currencySymbol} {bal}</div>
                </div>
            </div>
            <div className='acc-detail'>
                <div className='acc-details-content'>
                    <div className='acc-subheading'>Credit Balance</div>
                    <div className='acc-subheading-content'>{currencySymbol} {advDetails.totalCredit}</div>
                </div>
            </div>
            <div className='acc-detail'>
                <div className='acc-details-content'>
                    <div className='acc-subheading'>Total Installs Today</div>
                    <div className='acc-subheading-content'> {advDetails.totalInstallsToday != null ? advDetails.totalInstallsToday : 0}</div>
                </div>
            </div>
        </div>
        <DashboardScreen isadmin={true} campaignCount={advDetails != "" ? advDetails.totalCampaigns : [{
            "TotalCampaigns": "0",
            "ActiveCampaigns": "0",
            "StoppedCampaigns": "0",
            "PausedCampaigns": "0",
            "CampaignsCreatedThisMonth": "0",
            "CampaignsCreatedLastMonth": "0"
        }]} />
        <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={openSession}
            onClose={handleSessionClose}
            message="Session Expired!"
        />
        <Snackbar message="Copied to Clipboard!" open={createdOpen} autoHideDuration={5000} onClose={() => setCreatedOpen(false)} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} />
    </div>;
}

const mapStateToProps = (state: RootState) => ({
    currencySymbol: state.user.currencySymbol,
    authToken: state.user.authToken,
    sessionId: state.user.sessionId,
    advertiserId: state.user.advertiserId,
    countryCode: state.user.countryCode,
    totalBalance: state.user.totalBalance,
    masterCountryArray: state.user.masterCountryArray,
    selectedAdvertiserId: state.user.selectedAdvertiserId
});

const mapDispatchToProps = {
    setCurrencySymbol,
    setAdvertiserId,
    setAuthToken,
    setSessionId,
    setCountryCode,
    setTotalBalance,
    setMasterCountryArray,
    setSelectedAdvertiserId
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectedAdvertiserScreen);