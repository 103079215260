import React, { FC, useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box } from "@mui/material";
import "../../CSS/CreateCampaign.css";
import "../../CSS/SignIn.css";
import { connect, useDispatch } from "react-redux";
import { RootState } from "../../REDUX/Reducers/rootReducer";
import {
  setBudget,
  setFocus,
  setTarget,
  setCostPerInstall,
} from "../../REDUX/Actions/userActionIndex";

interface customButtonProps {
  onClick?: () => void;
  onBackClick?: () => void;
  budget: string;
  target: string;
  focus: string;
  costPerInstall: string;
  transactionCurrency: string;
}

const Review: React.FC<customButtonProps> = ({
  onClick,
  onBackClick,
  budget,
  focus,
  target,
  costPerInstall,
  transactionCurrency,
}) => {
  const [budgetReview, setbudgetReview] = useState<string>("");
  const [focusVal, setfocusVal] = useState<string>("");

  const [imagesNum, setimagesNum] = useState<number>(0);
  const [videoNum, setVideoNum] = useState<number>(0);
  const [htmlNum, sethtmlNum] = useState<number>(0);
  const [headlineNum, setHeadlineNum] = useState<number>(0);
  const [descriptionNum, setDescriptionNum] = useState<number>(0);

  const [currency, setCurrency] = useState<any>(
    localStorage.getItem("currency")
  );

  useEffect(() => {
    // const budget = localStorage.getItem("budget") || "";
    const focusDpd = localStorage.getItem("focusDpd") || "";
    const htmlNum = localStorage.getItem("htmlUrlArray") || "";
    const videoNum = localStorage.getItem("videoUrlArray") || "";
    const imageNum = localStorage.getItem("imageUrlArray") || "";
    const headlineArray = localStorage.getItem("headlines") || "";
    const descriptionArray = localStorage.getItem("description") || "";
    const campData = localStorage.getItem("editingData") || "";

    // setbudgetReview(budget)
    setfocusVal(focusDpd);
    const typeChangeimage = imageNum != "" ? JSON.parse(imageNum) : [];
    setimagesNum(typeChangeimage.length);
    // setimagesNum(Number(imageNum));

    const typeChangeheadline =
      headlineArray != "" ? JSON.parse(headlineArray) : [];
    setHeadlineNum(typeChangeheadline.length);

    const typeChangedescription =
      descriptionArray != "" ? JSON.parse(descriptionArray) : [];
    setDescriptionNum(typeChangedescription.length);

    const typeChangevideo = videoNum != "" ? JSON.parse(videoNum) : [];
    setVideoNum(typeChangevideo.length);

    const typeChangehtml = htmlNum != "" ? JSON.parse(htmlNum) : [];
    sethtmlNum(typeChangehtml.length);

    // if (campData != null && campData != "") {
    //   const data = JSON.parse(campData);
    //   console.log("createCampaign", data.budgetData);
    //   // setbudgetReview(data.budgetData.dailyAvgBudget);
    //   // setfocusVal(data.budgetData.focusParam);
    // }

    // console.log(
    //   "budget",
    //   typeChangedescription,
    //   typeChangeheadline,
    //   typeChangehtml,
    //   typeChangeimage,
    //   typeChangevideo
    // );
    // console.log("focusDpd", focusDpd);
  }, []);

  return (
    <div>
      <h5 className="heading-review">Budget and bidding</h5>
      <div className="campaign-box">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} columns={6}>
            <Grid xs={1}>
              <p className="first-part">Budget</p>
            </Grid>
            <Grid xs={5}>
              <p className="second-part">
                {" "}
                {transactionCurrency === "USD" ? (
                  <span>&#36;</span>
                ) : (
                  <span> &#8377; </span>
                )}{" "}
                {budget}/day
              </p>
            </Grid>
          </Grid>
        </Box>
      </div>
      <div className="campaign-box" style={{ marginTop: 0 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} columns={6}>
            <Grid xs={1}>
              <p className="first-part">Bidding</p>
            </Grid>
            <Grid xs={5}>
              <p className="second-part">{focus}</p>
            </Grid>
          </Grid>
        </Box>
      </div>
      <h5 className="heading-review">Ad group</h5>
      <div className="campaign-box">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} columns={6}>
            <Grid xs={1}>
              <p className="first-part">Ad assests</p>
            </Grid>
            <Grid xs={5}>
              <p className="second-part">
                {imagesNum} image,
                {videoNum} video, {htmlNum} HTML,{" "}
                {/* {headlineNum} headlines, and {descriptionNum} descriptions */}
              </p>
            </Grid>
          </Grid>
        </Box>
      </div>
      <div className="doneButton-box">
        <button className="border-btn" onClick={onBackClick}>
          Cancel
        </button>
        <button className="purple-btn width-adjust" onClick={onClick}>
          Publish Campaign
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  budget: state.user.budget,
  focus: state.user.focus,
  target: state.user.target,
  costPerInstall: state.user.costPerInstall,
  transactionCurrency: state.user.transactionCurrency,
});

const mapDispatchToProps = {
  setBudget,
  setFocus,
  setTarget,
  setCostPerInstall,
};

export default connect(mapStateToProps, mapDispatchToProps)(Review);
