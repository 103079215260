import axios from 'axios';
import { apiURL, secretKey } from '../Constant';
const CryptoJS = require('crypto-js')

export const getMasterdataFunc = async () => {
    await axios.get(`${apiURL}/api/data/getMasterData`,
    )
        .then(res => {
            const resData = res.data.data;
            const bytes = CryptoJS.AES.decrypt(resData, secretKey);
            const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
            const parsedData = JSON.parse(decryptedData);
            if (res.data.status == true) {
                localStorage.setItem("CountryArray", JSON.stringify(parsedData['country']))
                localStorage.setItem("MMPArray", JSON.stringify(parsedData['MMP']))
                localStorage.setItem("LanguageArray", JSON.stringify(parsedData['language']))
                localStorage.setItem("MacrosArray", JSON.stringify(parsedData['macros']))
                localStorage.setItem("PubliserArray", JSON.stringify(parsedData['publisher']))
                localStorage.setItem("couponArray", JSON.stringify(parsedData['coupons']))
                localStorage.setItem("targetingTypesArray", JSON.stringify(parsedData['targetingTypes']))
            }
        })
        .catch((e) => console.log(e))
}

export const getCountryMaster = () => {
    const countryArr = JSON.parse(localStorage.getItem("CountryArray")!)
    return countryArr
}

export const getTargetingTypesMaster = () => {
    const targetingTypesArr = JSON.parse(localStorage.getItem("targetingTypesArray")!)
    return targetingTypesArr
}