import React, { FC, useEffect, useState } from "react";
import {
  CardElement,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  AddressElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import "../../CSS/textfield.css";
import "../../CSS/payments.css";
import { IoWallet } from "react-icons/io5";
import {
  Checkbox,
  FormControlLabel,
  FormControlLabelProps,
  Hidden,
  InputAdornment,
  Radio,
  RadioGroup,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  styled,
  useRadioGroup,
} from "@mui/material";
import { BiSolidErrorCircle } from "react-icons/bi";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { PurpleCustomButton } from "../custom/PurpleButton";
import { useNavigate } from "react-router-dom";
import StripeImage from "../../IMAGE/stripe.svg";
import CustomLoader from "../custom/CustomLoader";
import DiscountIcon from "@mui/icons-material/Discount";
import { apiURL, secretKey } from "../../Constant";
import { connect } from "react-redux";
import {
  setAdvertiserId,
  setAuthToken,
  setCountryCode,
  setCurrencySymbol,
  setMasterCouponArray,
  setSessionId,
  setUserEmail,
  setStatus,
  setCampaignSubType,
  setAppPlatform,
  setAppName,
  setCampaignName,
  setAppImageUrl,
  setPackageName,

  setLanguage,
  setLocation,
  setTracking,
  setTrackingUrl,
  setImpressionUrl,
  setTokenEvent,
  addTokenEventObject,
  removeTokenEventObject,
  updateToken,
  updateEvent,
  setLocationCode,

  setBudget,
  setFocus,
  setTarget,
  setCostPerInstall,

  setAdGroupName,
  setLogoUrl,
  setHeadline,
  setDescription,
  setImageUrl,
  setVideoUrl,
  setHtmlUrl,

  setCampaignId,
  setBudgetId,

  setImageFile,
  setVideoFile,
  setHtmlFile,

  setImageUploadError,
  setLogoError,
  setLocationCheck,

  setDataSource,
  setSegmentName,
  setDataType,
  setDataUpload,
  setUploadedUrl,

  setDataSourceError,
  setSegmentNameError,
  setDataTypeError,
  setDataUploadError,
  setUploadedUrlError,

  setClicks,
  setImpression,
  setConversion,
  setInstall,
  setPayout,
  setCtr,
  setCpi,
  setTransactionCurrency,
  setDailyAvgBudget,

} from "../../REDUX/Actions/userActionIndex";
import { setCount } from "../../REDUX/Actions/counterActionIndex";
import { RootState } from "../../REDUX/Reducers/rootReducer";
import { useDispatch } from "react-redux";
const CryptoJS = require("crypto-js");

const CustomCheckBoxOutlineBlankIcon = styled(CheckBoxOutlineBlankIcon)(
  ({ theme }) => ({
    color: "#6900B8",
  })
);

const CustomCheckBoxIcon = styled(CheckBoxIcon)(({ theme }) => ({
  color: "#6900B8",
}));

const CustomTextField = styled(TextField)({
  width: "100%",
  paddingLeft: "0px",
  "&.MuiTextField-root input": {
    height: 25,
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    border: "none",
    outline: "none",
  },
  "& fieldset": {
    border: "none",
    outline: "none",
    paddingLeft: 0,
  },
  "&.Mui-focused fieldset": {
    border: "none",
    outline: "none",
  },
  "& .MuiFormHelperText-root": {
    color: "red",
    paddingLeft: 0,
    margin: "10px 0px",
  },
});

interface PaymentProp {
  campaignPublish?: boolean;
  paymentType?: string;
  budget?: any;
  budgetProp?: string;
  currencySymbol: any;

  userEmail: any;
  countryCode: any;
  mastercouponArray: any;
  campaignSubtype: string;
  appPlatform: string;
  appName: string;
  campaignName: string;
  appImageUrl: string;
  packageName: string;

  location: string;
  language: string[];
  tracking: string;
  trackingUrl: string;
  impressionUrl: string;
  tokenEvent: {
    token: string;
    eventName: string;
  }[];
  locationCode: string;
  target: string;
  focus: string;
  costPerInstall: string;

  adGroupName: string;
  logoUrl: string;
  headline: string[];
  description: string[];

  imageUrl: string[];
  videoUrl: string[];
  htmlUrl: string[];
  campaign_Id: string;
  budget_Id: string;
  status: string;
  authToken: string;
  sessionId: string;
  advertiserId: string;

  dataSource: string;
  segmentName: string;
  dataType: string;
  dataUpload: File[];
  uploadedUrl: string;
  transactionCurrency: string;
  adminView?: boolean;
  advertiserIdCampaign: string;
}

const PaymentForm: FC<PaymentProp> = (props) => {
  const {
    currencySymbol,
    authToken,
    sessionId,
    advertiserId,
    userEmail,
    countryCode,
    mastercouponArray,
    advertiserIdCampaign,

    campaign_Id,
    budget_Id,
    appName,
    campaignSubtype,
    appPlatform,
    campaignName,
    packageName,
    appImageUrl,
    location,
    language,
    tracking,
    trackingUrl,
    impressionUrl,
    tokenEvent,
    locationCode,
    budget,
    budgetProp,
    focus,
    target,
    costPerInstall,
    adGroupName,
    logoUrl,
    headline,
    description,
    imageUrl,
    videoUrl,
    htmlUrl,
    status,
    dataSource,
    segmentName,
    dataType,
    uploadedUrl,
    dataUpload,
    transactionCurrency,
    adminView = false,
  } = props;

  const [advertiserID, setAdvertiserID] = useState<any>(advertiserId);
  const [sessionID, setSessionID] = useState<any>(sessionId);
  const [authTokenID, setAuthTokenID] = useState<any>(authToken);
  const [currency, setCurrency] = useState<any>(currencySymbol);
  const [userEmailID, setUserEmailID] = useState<any>(userEmail);

  const [loaderActive, setLoaderActive] = useState<boolean>(false);
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const budgetprop = props.budgetProp;
  const [amount, setAmount] = useState<any>("");
  const [actualAmount, setActualAmount] = useState<any>("");

  const [error, setError] = useState<string>("");
  const [cardError, setCardError] = useState<any>("");

  const campaignPublish = props.campaignPublish;
  const campaignname = localStorage.getItem("campaignName");

  const [checked, setChecked] = React.useState(false);
  const [openSession, setOpenSession] = React.useState(false);

  const [couponArray, setCouponArray] = useState<any[]>([]);
  const [couponError, setCouponError] = useState<any>("");
  const [couponCount, setCouponCount] = useState<number>(0);
  const currencyCode = countryCode;

  const handleSaveCard = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleSessionClose = () => {
    setOpenSession(false);
  };

  const [file, setFile] = useState<any>("");
  const [fileName, setFileName] = useState("");
  const [date, setDate] = useState<any>("");
  const [name, setName] = useState("");
  const [customerID, setCustomerID] = useState<any>("");
  const [custID, setCustID] = useState<any>("");
  const [sectionOne, setSectionOne] = useState<boolean>(false);
  const [paymentType, setPaymentType] = useState<string>("stripe");
  const [sectionTwo, setSectionTwo] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const [uploadError, setUploadError] = useState<string>("");
  const [nameErr, setNameErr] = useState("");
  const [selectedRow, setSelectedRow] = useState<any>("");

  const handleRowClick = (row: any) => {
    setSelectedRow(row);
  };

  const [selectedValue, setSelectedValue] = useState<string | undefined>("");
  const dispatch = useDispatch();

  const erase = () => {
    dispatch(setClicks(""));
    dispatch(setImpression(""));
    dispatch(setConversion(""));
    dispatch(setInstall(""));
    dispatch(setPayout(""));
    dispatch(setCtr(""));
    dispatch(setCpi(""));
    dispatch(setTransactionCurrency(""));
    dispatch(setDailyAvgBudget(""));

    dispatch(setLocationCheck(""));
    dispatch(setImageUploadError(""));
    dispatch(setLogoError(""));
    dispatch(setCount(0));
    dispatch(setStatus("pending"));
    dispatch(setCampaignSubType(""));
    dispatch(setAppPlatform(""));
    dispatch(setAppName(""));
    dispatch(setCampaignName(""));
    dispatch(setAppImageUrl(""));
    dispatch(setPackageName(""));

    //CAMPAIGN SETTING

    dispatch(setLanguage([]));
    dispatch(setLocation(""));
    dispatch(setTracking(""));
    dispatch(setTrackingUrl(""));
    dispatch(setImpressionUrl(""));
    dispatch(removeTokenEventObject(0));
    if (tokenEvent.length > 0) {
      tokenEvent.map((item, index) => {
        dispatch(updateToken(index, ""));
        dispatch(updateEvent(index, ""));
      });
    }

    dispatch(setLocationCode(""));

    // BUDGET BIDDING
    dispatch(setBudget(""));
    dispatch(setFocus(""));
    dispatch(setTarget(""));
    dispatch(setCostPerInstall(""));

    dispatch(setAdGroupName(""));
    dispatch(setLogoUrl(""));
    dispatch(setHeadline([""]));
    dispatch(setDescription([""]));
    dispatch(setImageUrl([]));
    dispatch(setVideoUrl([]));
    dispatch(setHtmlUrl([]));
    dispatch(setImageFile(""));
    // const emptyFile = new File([], 'empty.txt', { type: 'text/plain' });
    dispatch(setVideoFile([]));
    dispatch(setHtmlFile([]));

    dispatch(setCampaignId(""));
    dispatch(setBudgetId(""));

    ///////////////////

    dispatch(setDataSource(""));
    dispatch(setDataSourceError(""));

    dispatch(setSegmentName(""));
    dispatch(setSegmentNameError(""));

    dispatch(setDataType(""));
    dispatch(setDataTypeError(""));

    dispatch(setDataUpload([]));
    dispatch(setDataUploadError([]));

    dispatch(setUploadedUrl(""));
    dispatch(setUploadedUrlError(""));
  };

  const saveAdGroup = async (id: string) => {
    // const adGrpName = localStorage.getItem("adGrpName") || "";

    // const htmlUrl = localStorage.getItem("htmlUrlArray") || "";
    // const imageUrl = localStorage.getItem("imageUrlArray") || "";
    // const videoUrl = localStorage.getItem("videoUrlArray") || "";

    // const headline = localStorage.getItem("headlines") || "";
    // const description = localStorage.getItem("description") || "";

    const campaign = localStorage.getItem("campaignId") || "";
    const searchParams = new URLSearchParams(window.location.search);
    const countParam = searchParams.get("id") || "";
    const item = localStorage.getItem("editingData") || "";
    // const navigate = useNavigate()
    // const logoURL = localStorage.getItem("logoUrl") || "";
    let temp = "";
    if (item != "") {
      const data = JSON.parse(item);
      temp = data.id;
      // console.log("createCampaign",data)
    }
    console.log(temp, "asdf", countParam, "adfas", campaign_Id, "adfadf");
    let jsonParam = {
      campaignId: adminView
        ? id
        : temp == ""
          ? countParam != ""
            ? countParam
            : id
          : temp,
      logo: logoUrl,
      name: adGroupName,
      headline: headline,
      description: description,

      // image: imageUrl != "" ? JSON.parse(imageUrl) : [],
      // videos: videoUrl != "" ? JSON.parse(videoUrl) : [],
      // HTML5: htmlUrl != "" ? JSON.parse(htmlUrl) : [],
      image: imageUrl,
      videos: videoUrl,
      HTML5: htmlUrl,
    };
    // const authToken = localStorage.getItem("authToken") || "";
    // const sessionID = localStorage.getItem("sessionID") || ""; // console.log(startDate,endDate)
    // const advertiserId = localStorage.getItem("advertiserID") || "";
    console.log(jsonParam);
    const encryptAdvertiserID = CryptoJS.AES.encrypt(
      JSON.stringify(advertiserId),
      secretKey
    ).toString();

    const header = {
      headers: {
        authtoken: authToken,
        sessionid: sessionId,
        advertiserid: encryptAdvertiserID, // Example authorization header
      },
    };
    // console.log(jsonParam);
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(jsonParam),
      secretKey
    ).toString();

    let url = `${apiURL}/api/campaigns/saveAdGroups`;
    if (authToken != "" && sessionId != "" && advertiserId != "") {
      await axios
        .post(url, { params: encryptedData }, header)
        .then((res) => {
          if (res.data.status) {
            // window.location.replace("/home/campaigns");
            // const budget = localStorage.getItem("budget");
            const campaignName = localStorage.getItem("campaignName");
            const bal = localStorage.getItem("totalBalance");
            erase();
            navigate("/home/campaigns");
            // console.log(budget , bal )

            // if (budget != null && budget != "" && bal != null && bal != "") {
            //   if (parseInt(budget) <= parseInt(bal)) {
            //     erase();
            //     navigate("/home/campaigns");
            //   } else {
            //     // console.log("here 2")
            //     erase();
            //     navigate("/home/addFunds", {
            //       state: {
            //         // campaignname: campaignName,
            //         amount: budget,
            //         paymentType: "stripe",
            //         isCampaignPublished: true,
            //       },
            //     });
            //   }
            // } else if (budget != null && budget != "") {
            //   navigate("/home/addFunds", {
            //     state: {
            //       // campaignname: campaignName,
            //       amount: budget,
            //       paymentType: "stripe",
            //       isCampaignPublished: true,
            //     },
            //   });
            // }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const apiBudget = async (campId: string) => {
    // const budget = localStorage.getItem("budget") || "";
    // const focusDpd = localStorage.getItem("focusDpd") || "";
    // const trackDpd = localStorage.getItem("trackDpd") || "";
    // const installationCost = localStorage.getItem("costPerInstall") || "";
    // const target = localStorage.getItem("targetCheck") || "";
    // const targetApp = localStorage.getItem("targetApp") || "";
    const campaign = localStorage.getItem("campaignId") || "";
    let jsonParam = {
      campaignId: campId != "" ? campId : campaign_Id,
      dailyAvgBudget: budget != "" ? parseInt(budget, 10) : 0,
      // targetUserType: trackDpd,
      isSetTargetCoatPerInstall: false,
      cpiCurrency: transactionCurrency,
      // target != "" ? (target == "true" ? true : false) : false,
      targetCostPerInstall: costPerInstall != "" ? parseInt(costPerInstall) : 0,
      focusParam: focus,
      targetingType: target,
    };
    // const authToken = localStorage.getItem("authToken") || "";
    // const sessionID = localStorage.getItem("sessionID") || ""; // console.log(startDate,endDate)
    // const advertiserId = localStorage.getItem("advertiserID") || "";
    const encryptAdvertiserID = CryptoJS.AES.encrypt(
      JSON.stringify(advertiserId),
      secretKey
    ).toString();

    const header = {
      headers: {
        authtoken: authToken,
        sessionid: sessionId,
        advertiserid: encryptAdvertiserID, // Example authorization header
      },
    };
    console.log(jsonParam);
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(jsonParam),
      secretKey
    ).toString();

    let url = `${apiURL}/api/campaigns/saveBudgetDetails`;
    if (authToken != "" && sessionId != "" && advertiserId != "") {
      await axios
        .post(url, { params: encryptedData }, header)
        .then((res) => {
          //   console.log(res.data);
          if (res.data.status) {
            saveAdGroup(campId);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const apiCallFunction = () => {
    const email = localStorage.getItem("emailID") || "";

    const jsonParam = {
      AdvertiserId: adminView ? advertiserIdCampaign : advertiserId,
      Email: email,
      ApplicationName: appName,
      CampaignName: campaignName,
      SubType: campaignSubtype,
      AppPlatform: appPlatform,
      Location: location,
      TrackingPartner: tracking,
      TrackingURL: trackingUrl.endsWith("&")
        ? trackingUrl.slice(0, -1)
        : trackingUrl,
      Token:
        tokenEvent.length > 0
          ? tokenEvent[0].token !== "" && tokenEvent[0].eventName !== ""
            ? tokenEvent
            : []
          : [],
      FinalClickURL: impressionUrl.endsWith("&")
        ? impressionUrl.slice(0, -1)
        : impressionUrl,

      Currency: transactionCurrency,
      Status: "pending",
      Payouts: [
        {
          payout: 0,
          revenue: costPerInstall,
          geo: [locationCode],
        },
      ],

      IsRetargetingSubType: campaignSubtype == "Retargeting" ? true : false,
      RetargetingOptions: {
        dataSource: dataSource,
        segmentName: dataSource != "SKIP" ? segmentName : "",
        dataType: dataSource != "SKIP" ? dataType : "",
        fileUrl: dataSource != "SKIP" ? uploadedUrl : "",
      },
      // BudgetId: string;
      // Description: string;
      Languages: language,
      AppLookUpDetails: {
        logoUrl: appImageUrl,
        packageName: packageName,
      },
      // TrakierCampaignId?: string,
    };

    console.log(jsonParam);
    console.log(advertiserId);

    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(jsonParam),
      secretKey
    ).toString();

    const url = `${apiURL}/api/campaigns`;
    // const url = `http://localhost:10056/api/campaigns`;

    // const authToken = localStorage.getItem("authToken") || "";
    // const sessionID = localStorage.getItem("sessionID") || ""; // console.log(startDate,endDate)
    const encryptAdvertiserID = CryptoJS.AES.encrypt(
      JSON.stringify(advertiserId),
      secretKey
    ).toString();

    // const advertiserId = localStorage.getItem("advertiserID") || "";
    const header = {
      headers: {
        authtoken: authToken,
        sessionid: sessionId,
        advertiserid: encryptAdvertiserID, // Example authorization header
      },
    };
    if (authToken != "" && sessionId != "" && advertiserId != "") {
      axios
        .post(url, { params: encryptedData }, header)
        .then((res) => {
          console.log("res", res);
          if (res.status == 201) {
            // const resData = res.data;
            // const bytes = CryptoJS.AES.decrypt(resData, secretKey);
            // const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
            // const parsedData = JSON.parse(decryptedData);
            // localStorage.setItem("campaignId", res.data.campaignId);

            dispatch(setCampaignId(res.data.campaignId));
            // console.log(res.data)
            apiBudget(res.data.campaignId);

            // return res.data.campaignId
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleExistingCardSubmit = async () => {
    const encryptAdvertiserID = CryptoJS.AES.encrypt(
      JSON.stringify(advertiserID),
      secretKey
    ).toString();

    const amt = parseInt(amount);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to include the StripeProvider at the root of your app.
      return;
    }

    if (
      amt == 0 ||
      amt < (currencyCode == "INR" ? 5000 : 100) ||
      error != "" ||
      amt == undefined ||
      isNaN(amt)
    ) {
      setError("Invalid Amount");
    } else {
      try {
        let headers = {
          "Content-Type": "application/json",
          advertiserid: encryptAdvertiserID,
          authtoken: authTokenID,
          sessionid: sessionID,
        };
        let jsonParams = {
          amount: amt,
          currency: currencyCode == "INR" ? "INR" : "USD",
          customerId: selectedRow.customer,
          paymentMenthod: selectedRow.id,
          email: userEmailID,
        };

        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(jsonParams),
          secretKey
        ).toString();

        const response = await axios.post(
          `${apiURL}/api/billing/createPaymentIntent`,
          { params: encryptedData },
          { headers: headers }
        );

        const clientSecret = response.data.client_secret;
        const paymentIntentId = response.data.id;

        const result = await stripe.confirmCardPayment(clientSecret, {
          // payment_method: paymentID,
          payment_method: selectedRow.id
        });

        console.log("result", result)

        if (result.error) {
          if (campaignPublish) {
            let paymentJsonParams = {
              paymentStatus: "pending",
              email: userEmailID,
              campaignName: campaignname,
              amount: amt,
              budget: budgetprop,
              name: name,
            };

            const encryptedData = CryptoJS.AES.encrypt(
              JSON.stringify(paymentJsonParams),
              secretKey
            ).toString();

            const paymentMailRes = await axios.post(
              `${apiURL}/api/billing/sendPaymentMail`,
              { params: encryptedData },
              { headers: headers }
            );
            navigate("/home/addFunds/Failed");
          } else {
            navigate("/home/addFunds/Failed");
          }
        } else {
          setLoaderActive(true);
          try {
            let jsonParam = {
              advertiserId: advertiserId,
              customerId: custID,
              transactionType: "credit",
              amount: actualAmount,
              transactionDate: new Date(),
              description: "Description",
              status: "success",
              paymentGatewayResponse: {
                status: "success",
              },
              invoiceId: paymentIntentId,
            };

            const encryptedData = CryptoJS.AES.encrypt(
              JSON.stringify(jsonParam),
              secretKey
            ).toString();

            let headers = {
              "Content-Type": "application/json",
              advertiserid: encryptAdvertiserID,
              authtoken: authTokenID,
              sessionid: sessionID,
            };

            const res = await axios.post(
              `${apiURL}/api/billing/saveTransactionDetails`,
              { params: encryptedData },
              { headers: headers }
            );

            if (res.data.status == true) {
              const resData = res.data.data;
              const bytes = CryptoJS.AES.decrypt(resData, secretKey);
              const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
              const parsedData = JSON.parse(decryptedData);
              if (campaignPublish) {
                let paymentJsonParams = {
                  paymentStatus: "success",
                  email: userEmailID,
                  transactionId: parsedData["TransactionId"],
                  campaignName: campaignname,
                  amount: amt,
                  budget: budgetprop,
                  name: name,
                };

                const encryptedData = CryptoJS.AES.encrypt(
                  JSON.stringify(paymentJsonParams),
                  secretKey
                ).toString();

                const paymentMailRes = await axios.post(
                  `${apiURL}/api/billing/sendPaymentMail`,
                  { params: encryptedData },
                  { headers: headers }
                );
                apiCallFunction();
                navigate("/home/addFunds/Success", {
                  state: {
                    currency: currency,
                    amount: actualAmount,
                    paymentType: "stripe",
                    transactionDetails: parsedData,
                  },
                });
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              } else {
                navigate("/home/addFunds/Success", {
                  state: {
                    currency: currency,
                    amount: actualAmount,
                    paymentType: "stripe",
                    transactionDetails: res.data.data,
                  },
                });
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }
            } else if (res.data.statuscode == 401) {
              setOpenSession(true);
              setTimeout(() => {
                navigate("/");
              }, 2000);
            }
          } catch (error) {
            console.log(error);
            if (campaignPublish) {
              let paymentJsonParams = {
                paymentStatus: "pending",
                email: userEmailID,
                campaignName: campaignname,
                amount: amount,
                budget: budgetprop,
                name: name,
              };
              const encryptedData = CryptoJS.AES.encrypt(
                JSON.stringify(paymentJsonParams),
                secretKey
              ).toString();

              const paymentMailRes = await axios.post(
                `${apiURL}/api/billing/sendPaymentMail`,
                { params: encryptedData },
                { headers: headers }
              );
              navigate("/home/addFunds/Failed");
            } else {
              navigate("/home/addFunds/Failed");
            }
          }
          setLoaderActive(false);
        }
      } catch (e) { }
    }
  };

  function FirstFormControlLabel(props: FormControlLabelProps) {
    const radioGroup = useRadioGroup();
    let checked = false;
    if (radioGroup) {
      checked = radioGroup.value === props.value;
      if (checked) {
        setPaymentMethod("");
        setSectionOne(true);
        setPaymentType(radioGroup.value);
      }
    }
    return <FormControlLabel checked={checked} {...props} />;
  }

  function SecondFormControlLabel(props: FormControlLabelProps) {
    const radioGroup = useRadioGroup();
    let checked = false;
    if (radioGroup) {
      checked = radioGroup.value === props.value;
      if (checked == true) {
        setSectionTwo(true);
        setPaymentMethod(radioGroup.value);
      }
    }
    return <FormControlLabel checked={checked} {...props} />;
  }

  useEffect(() => {
    setAmount(props.budgetProp);
    setActualAmount(props.budgetProp);
    setSelectedValue(props.paymentType);
  }, [props]);

  useEffect(() => {
    getPaymentListMethods();
    const couponArray = JSON.parse(mastercouponArray);
    setCouponArray(couponArray.length != 0 ? couponArray : []);
  }, []);

  const [paymentMethodsList, setPaymentMethodsList] = useState<any[]>([]);

  const getPaymentListMethods = async () => {
    const encryptAdvertiserID = CryptoJS.AES.encrypt(
      JSON.stringify(advertiserID),
      secretKey
    ).toString();
    try {
      let jsonParams = {
        advertiser_Id: advertiserID,
        email: userEmailID,
        name: name,
      };
      let headers = {
        "Content-Type": "application/json",
        advertiserid: encryptAdvertiserID,
        authtoken: authTokenID,
        sessionid: sessionID,
      };
      const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify(jsonParams),
        secretKey
      ).toString();

      const customerRes = await axios.post(
        `${apiURL}/api/billing/createCustomer`,
        { params: encryptedData },
        { headers: headers }
      );

      if (customerRes.data.status == true) {
        const resData = customerRes.data.data;
        const bytes = CryptoJS.AES.decrypt(resData, secretKey);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        const parsedData = JSON.parse(decryptedData);

        setCustomerID(parsedData.CustomerID);
        setCustID(parsedData.CustID);
        let listCustomerParams = {
          customerId: parsedData.CustomerID,
        };
        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(listCustomerParams),
          secretKey
        ).toString();

        const listPaymentResponse = await axios.post(
          `${apiURL}/api/billing/listCustomerPayMethods`,
          { params: encryptedData },
          { headers: headers }
        );
        if (listPaymentResponse.status == 200) {
          const resData = listPaymentResponse.data.data;
          const bytes = CryptoJS.AES.decrypt(resData, secretKey);
          const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
          const parsedData = JSON.parse(decryptedData);

          setPaymentMethodsList(parsedData);
        }
      } else if (customerRes.data.statuscode == 401) {
        setOpenSession(true);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    } catch (e) { }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const amt = parseInt(amount);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to include the StripeProvider at the root of your app.
      return;
    }

    if (
      amt == 0 ||
      amt < (currencyCode == "INR" ? 5000 : 100) ||
      error != "" ||
      amt == undefined ||
      isNaN(amt)
    ) {
      setError("Invalid Amount");
    }
    // else if (name == '') {
    //     setNameErr("Invalid Card Holder's name")
    // }
    else {
      setLoaderActive(true);
      const encryptAdvertiserID = CryptoJS.AES.encrypt(
        JSON.stringify(advertiserID),
        secretKey
      ).toString();

      if (paymentMethod == "addCard") {
        try {
          const cardElement = elements.getElement(CardElement)!;

          var addressElement = elements.getElement(AddressElement)!;
          console.log(addressElement)
          let address: any = "";


          addressElement.getValue()
            .then(function (result) {
              if (result.complete) {
                // Allow user to proceed to the next step
                // Optionally, use value to store the address details
                console.log(result.value)
                address = result.value
              }
            })

          const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: address
          });

          // console.log(paymentMethod)

          let paymentID = "";
          if (paymentMethod != null) {
            paymentID = paymentMethod.id;
          }

          // console.log(paymentID)

          try {
            let jsonParams = {
              email: userEmailID,
              name: name,
            };
            let headers = {
              "Content-Type": "application/json",
              advertiserid: encryptAdvertiserID,
              authtoken: authTokenID,
              sessionid: sessionID,
            };
            const encryptedData = CryptoJS.AES.encrypt(
              JSON.stringify(jsonParams),
              secretKey
            ).toString();

            const customerRes = await axios.post(`${apiURL}/api/billing/createCustomer`, { params: encryptedData }, { headers: headers });

            console.log(customerRes.data)

            if (customerRes.data.status == true) {
              const resData = customerRes.data.data;
              const bytes = CryptoJS.AES.decrypt(resData, secretKey);
              const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
              const parsedData = JSON.parse(decryptedData);

              if (checked) {
                let attachPaymentParams = {
                  customerId: parsedData.CustomerID,
                  paymentMethodId: paymentID,
                };

                const encryptedData = CryptoJS.AES.encrypt(
                  JSON.stringify(attachPaymentParams),
                  secretKey
                ).toString();
                const attachPaymentResponse = await axios.post(
                  `${apiURL}/api/billing/attachPaymentToCustomer`,
                  { params: encryptedData },
                  { headers: headers }
                );
              }

              let createIntentParams = {
                amount: amt,
                currency: currencyCode == "INR" ? "INR" : "USD",
                customerId: parsedData.CustomerID,
                paymentMenthod: paymentID,
                email: userEmailID
              }
              const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(createIntentParams), secretKey).toString();

              const response = await axios.post(`${apiURL}/api/billing/createPaymentIntent`, { params: encryptedData }, { headers: headers });

              console.log(response.data)
              const clientSecret = response.data.client_secret;
              const paymentIntentId = response.data.id;

              const result = await stripe.confirmCardPayment(clientSecret, {
                // payment_method: paymentID,
                payment_method: {
                  card: cardElement,
                  billing_details: address
                },
              },);
              // {
              //     name: name,
              // },
              console.log(result)
              console.log("response.data")
              if (result.error) {
                console.log(result.error)
                if (result.error.type == 'validation_error') {
                  setCardError(result.error.message)
                } else {
                  if (campaignPublish) {
                    let paymentJsonParams = {
                      paymentStatus: "pending",
                      email: userEmailID,
                      campaignName: campaignname,
                      amount: amt,
                      budget: budgetprop,
                      name: name
                    }
                    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(paymentJsonParams), secretKey).toString();

                    const paymentMailRes = await axios.post(`${apiURL}/api/billing/sendPaymentMail`, { params: encryptedData }, { headers: headers });
                  }
                  // navigate('/home/addFunds/Failed')
                }

              } else {
                try {
                  let jsonParams = {
                    advertiserId: advertiserId,
                    customerId: parsedData.CustID,
                    transactionType: "credit",
                    amount: parseInt(actualAmount),
                    transactionDate: new Date(),
                    description: "Description",
                    status: "success",
                    paymentGatewayResponse: {
                      status: "success"
                    },
                    invoiceId: paymentIntentId
                  };

                  console.log(jsonParams)
                  let headers = {
                    'Content-Type': 'application/json',
                    'advertiserid': encryptAdvertiserID,
                    'authtoken': authTokenID,
                    'sessionid': sessionID
                  };

                  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(jsonParams), secretKey).toString();
                  const res = await axios.post(`${apiURL}/api/billing/saveTransactionDetails`, { params: encryptedData }, { headers: headers });

                  if (res.data.status == true) {
                    const resData = res.data.data;
                    const bytes = CryptoJS.AES.decrypt(resData, secretKey);
                    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
                    const parsedData = JSON.parse(decryptedData);
                    if (campaignPublish) {
                      let paymentJsonParams = {
                        paymentStatus: "success",
                        email: userEmailID,
                        transactionId: parsedData["TransactionId"],
                        campaignName: campaignname,
                        amount: amt,
                        budget: budgetprop,
                        name: name
                      }

                      const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(paymentJsonParams), secretKey).toString();

                      const paymentMailRes = await axios.post(`${apiURL}/api/billing/sendPaymentMail`, { params: encryptedData }, { headers: headers });
                      console.log("line 1180")
                      navigate('/home/addFunds/Success', {
                        state: {
                          currency: currency,
                          amount: actualAmount,
                          paymentType: "stripe",
                          transactionDetails: parsedData
                        }
                      })
                      console.log("line 1188")
                      apiCallFunction();
                      setTimeout(() => { window.location.reload() }, 1000)
                    } else {
                      navigate('/home/addFunds/Success', {
                        state: {
                          currency: currency,
                          amount: actualAmount,
                          paymentType: "stripe",
                          transactionDetails: parsedData
                        }
                      })
                      setTimeout(() => { window.location.reload() }, 1000)
                    }
                  } else if (res.data.statuscode == 401) {
                    setOpenSession(true)
                    setTimeout(() => { navigate("/") }, 2000)
                  }
                } catch (error) {
                  console.log(error);
                  if (campaignPublish) {
                    let paymentJsonParams = {
                      paymentStatus: "pending",
                      email: userEmailID,
                      campaignName: campaignname,
                      amount: amt,
                      budget: budgetprop,
                      name: name
                    }
                    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(paymentJsonParams), secretKey).toString();

                    const paymentMailRes = await axios.post(`${apiURL}/api/billing/sendPaymentMail`, { params: encryptedData }, { headers: headers });
                    navigate('/home/addFunds/Failed')
                  } else {
                    navigate('/home/addFunds/Failed')
                  }
                }
              }
            } else if (customerRes.data.statuscode == 401) {
              setOpenSession(true)
              setTimeout(() => { navigate("/") }, 2000)
            }
          } catch (error) {
            console.log(error);
          }


        } catch (error) {
          console.error('Error:', error);
        }
      }
      setLoaderActive(false)

    }
  };

  useEffect(() => {
    var today = new Date();
    const todayDate =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    setDate(todayDate);
  }, []);

  const handleChange = (e: any) => {
    let value = e.target.value;
    switch (e.target.name) {
      case "name":
        setNameErr("");
        setName(value);
        if (value == "" || value == null) {
          setNameErr("Invalid full name.");
        } else {
          setName(value);
        }
        break;
      case "amount":
        setError("");
        setAmount(value);
        setActualAmount(value);
        setCouponCount(0);
        if (
          value == "" ||
          value == null ||
          value.length == 0 ||
          value < (currencyCode == "INR" ? 5000 : 100)
        ) {
          setError("Invalid Amount");
        } else {
          setAmount(value);
          setActualAmount(value);
        }
        break;
      default:
        break;
    }
  };

  const handleBankTransfer = async () => {
    if (file != "") {
      const encryptAdvertiserID = CryptoJS.AES.encrypt(
        JSON.stringify(advertiserID),
        secretKey
      ).toString();

      try {
        var bodyFormData = new FormData();

        bodyFormData.append("advertiserId", advertiserId);
        bodyFormData.append("paymentReciept", file);
        bodyFormData.append("transactionDate", date);

        let headers = {
          advertiserid: encryptAdvertiserID,
          authtoken: authTokenID,
          sessionid: sessionID,
        };

        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(bodyFormData),
          secretKey
        ).toString();

        const response = await axios.post(
          `${apiURL}/api/billing/saveBankPaymentInfo`,
          { params: encryptedData },
          { headers: headers }
        );
        if (response.data.status == true) {
          navigate("/home/addFunds/Success", {
            state: {
              currency: currency,
              amount: 1000,
              paymentType: "bankTransfer",
              transactionDetails: [],
            },
          });
        } else if (response.data.statuscode == 401) {
          setOpenSession(true);
          setTimeout(() => {
            navigate("/");
          }, 2000);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleFileUpload = (e: any) => {
    setFile("");
    setFileName("");
    const filesFormats = ["application/pdf", "image/jpeg"];
    const selectedFile = e.target.files[0];
    const fileType = selectedFile.type;
    const fileName = selectedFile.name;
    const fileExtension = fileName.slice(
      ((fileName.lastIndexOf(".") - 1) >>> 0) + 2
    );
    const isRightFormat =
      filesFormats.includes(fileType) ||
      filesFormats.includes("." + fileExtension.toLowerCase());
    if (isRightFormat) {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    } else {
      setUploadError("Please upload pdf/jpg files only.");
    }
  };

  const [couponCode, setCouponCode] = useState<any>("");
  const [findCoupon, setFindCoupon] = useState<any>();
  const [couponSuccess, setCouponSuccess] = useState<string>();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCouponSuccess("");
    setCouponCode(event.target.value);
  };

  const applyCoupon = () => {
    let amt = parseInt(amount);
    if (amount == "" || couponCode == "") {
      setCouponError("Please enter coupon code!");
    } else if (couponCount != 0) {
      setCouponError("Coupon can't be more than once.");
    } else if (couponArray && Array.isArray(couponArray)) {
      let couponFind = couponArray.find(
        (element: any) => couponCode === element.CouponCode
      );
      setFindCoupon(couponFind);
      if (couponFind != undefined) {
        if (couponFind.IsActive == true) {
          if (couponFind.IsPercentage) {
          } else {
            setCouponError("");
            if (amt >= couponFind.MaxPaymentAmount) {
              setAmount(amt - couponFind.Discount);
              setCouponSuccess("Coupon Applied Successfully");
              setCouponCount(1);
            } else {
              setCouponError(
                "Amount must be at least " + couponFind.MaxPaymentAmount
              );
            }
          }
        } else {
          setCouponError("Coupon Expired!");
        }
      } else {
        setCouponError("Invalid Coupon!");
      }
    }
  };

  return (
    // loaderActive ? <div style={{
    //     display: "flex",
    //     alignItems: "center",
    //     justifyContent: "center"
    // }}>
    //     <CustomLoader />
    // </div>
    //     :

    <div>
      {loaderActive && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 1000,
          }}
        >
          <CustomLoader />
        </div>
      )}
      <div style={loaderActive ? { opacity: 0.5 } : { opacity: 1 }}>
        <div className="heading">
          <IoWallet style={{ color: "#6900B8", marginRight: 5 }} />
          Add Funds
        </div>
        <div
          className="white-container"
          style={{ display: "flex", maxHeight: "auto" }}
        >
          <div style={{ width: "40%" }}>
            <div className="sub-heading">Select Payment Method</div>
            {/* <div className='sub-heading'>Payment Method</div> */}

            <RadioGroup name="use-radio-group" defaultValue="stripe">
              <FirstFormControlLabel
                value="stripe"
                label={
                  <div className="stripe-box">
                    <img src={StripeImage} />
                  </div>
                }
                control={
                  <Radio
                    checkedIcon={<CustomCheckBoxIcon />}
                    icon={<CustomCheckBoxOutlineBlankIcon />}
                  />
                }
                sx={{ marginBottom: "10px" }}
              />
              <FirstFormControlLabel
                value="banktransfer"
                label={<div className="bank-transfer">Bank Transfer</div>}
                control={
                  <Radio
                    checkedIcon={<CustomCheckBoxIcon />}
                    icon={<CustomCheckBoxOutlineBlankIcon />}
                  />
                }
                sx={{ marginBottom: "10px" }}
              />
            </RadioGroup>
            {paymentType == "stripe" ? (
              <div>
                <div className="sub-heading">Execution Time : Instantly</div>
                <div className="sub-heading">
                  Description : You can make Payment via Stripe
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <div
            style={{
              borderRight: "1.2px solid #c4c4c4",
              height: "350px",
              alignSelf: "center",
            }}
          ></div>

          {sectionOne == true ? (
            paymentType == "stripe" ?
              <div style={{ padding: "0px 10px", width: "60%" }}>
                <div>
                  <div className='sub-heading'>Add Amount</div>
                  <div style={{ color: 'rgba(105, 0, 184, 0.45)' }} className='sub-heading'>The minimum recharge amount is {currencyCode == "INR" ? 5000 : 100} {currencyCode == "INR" ? currency : "$"}</div>
                  <div style={{ width: '40%' }}>
                    {/* <CustomTextField children="Enter Amount" type='number' startAdornment='$' /> */}
                    <div className='textfield-Box'>
                      <div className='textfield-head'>
                        <div>Enter Amount</div>
                      </div>
                      <CustomTextField
                        className='textfield'
                        onChange={handleChange}
                        value={amount}
                        name='amount'
                        type='number'
                        InputProps={{
                          style: {
                            paddingLeft: "0px"
                          },
                          startAdornment: (
                            <InputAdornment position='start'>{currencyCode == "INR" ? currency : "$"} </InputAdornment>
                          )
                        }}
                      />
                      {error != "" && error != null && (<div className="error-box">
                        <BiSolidErrorCircle className="error-icon" />
                        <p>{error}</p>
                      </div>)}
                    </div>
                    <div className='coupon-box'>
                      <input
                        type="text"
                        id="couponInput"
                        defaultValue={couponCode}
                        onChange={handleInputChange}
                        placeholder='Type your Coupon Code here'
                      />
                      <button className='couponButton' onClick={applyCoupon}>Apply</button>
                    </div>
                    {couponError != "" && couponError != null && (<div className="error-box">
                      <p>{couponError}</p>
                    </div>)}
                    {couponCount != 0 && couponSuccess != "" && couponSuccess != null && (<div className="error-box">
                      <DiscountIcon fontSize='small' style={{ color: "green" }} />
                      <p style={{ color: "green" }}>{couponSuccess}</p>
                    </div>)}
                  </div>
                </div>
                <div style={{ borderTop: "1px solid #c4c4c4", }}>
                  <div className='sub-heading'>Payment Method</div>
                  <div>
                    <RadioGroup name="use-radio-group-two" >
                      <div style={{ display: "flex" }}>
                        <SecondFormControlLabel value="existingCard" label={<div className='sub-heading'>Select Existing Debit/Credit Card</div>} control={<Radio checkedIcon={<CustomCheckBoxIcon />} icon={<CustomCheckBoxOutlineBlankIcon />} />} />
                        <SecondFormControlLabel value="addCard" label={<div className='sub-heading'>Add a  Debit/Credit Card</div>} control={<Radio checkedIcon={<CustomCheckBoxIcon />} icon={<CustomCheckBoxOutlineBlankIcon />} />} />
                      </div>
                    </RadioGroup>
                  </div>
                  {paymentMethod == 'addCard' ? <div style={{
                    width: "70%", marginBottom: "20px"
                  }}>
                    <div style={{
                      marginBottom: "10px"
                    }}>
                      {/* <div className='textfield-head'>
                                                <div>Enter Address Details</div>
                                            </div> */}

                      <AddressElement
                        options={{
                          mode: "billing",
                          fields: {
                            phone: "never",
                          },
                        }}
                        className="custom-address-element"
                      />

                    </div>
                    <div className='textfield-Box'>
                      <div className='textfield-head'>
                        <div>Enter Card Holder Name</div>
                      </div>
                      <CustomTextField
                        className='textfield'
                        name='name'
                        value={name}
                        onChange={handleChange}
                      />
                      {nameErr != "" && nameErr != null && (<div className="error-box">
                        <BiSolidErrorCircle className="error-icon" />
                        <p>{nameErr}</p>
                      </div>)}
                    </div>


                    <div className='textfield-Box'>
                      <div className='textfield-head'>
                        <div>Enter Card Details</div>
                      </div>
                      <div style={{
                        marginTop: "5px"
                      }}>
                        <CardElement
                          options={{
                            hidePostalCode: true,

                          }}
                          // onChange={() => setCardError("")}
                          onChange={() => setCardError("")}
                        />
                      </div>
                      {cardError != "" && cardError != null && (<div className="error-box">
                        <BiSolidErrorCircle className="error-icon" />
                        <p>{cardError}</p>
                      </div>)}
                    </div>
                    <div className="check-div">
                      <Checkbox
                        size='small'
                        checked={checked}
                        className="chk"
                        onChange={handleSaveCard}
                      />
                      <p>Save card details to Stripe</p>
                    </div>
                  </div> : <div>
                    <TableContainer sx={{ maxHeight: 150, width: 380 }}>
                      <Table sx={{ width: 350 }} aria-label="simple table">
                        <TableBody>
                          {paymentMethod == "existingCard" && paymentMethodsList.map((row) => (
                            <TableRow
                              key={row.id}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer" }}
                              onClick={() => handleRowClick(row)}
                              selected={selectedRow != "" && selectedRow.id === row.id}
                            >
                              <TableCell component="th" scope="row">
                                {row.card['brand']}
                              </TableCell>
                              <TableCell align="right">****</TableCell>
                              <TableCell align="right">{row.card['last4']}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>}
                </div>
              </div> : <div className='heading' style={{
                padding: "0px 10px", width: "60%", display: "flex",
                alignItems: "center", justifyContent: "center"
              }}>Coming Soon</div>
            // <div style={{ padding: "0px 10px", width: "60%", }}>
            //     <div className='sub-heading'>Transfer Receipt</div>
            //     <div style={{ color: "#6900B8", marginBottom: '10px' }} className='heading'>Upload your bank transfer receipt here</div>
            //     <input type="file" id="file-input" name="file-input" style={{ display: "none" }} onChange={handleFileUpload} />
            //     <label id="file-input-label" htmlFor="file-input" className='upload-btn'><div style={{ display: "flex", alignItems: "center" }}><FileUploadOutlinedIcon sx={{ marginRight: "6px" }} />Upload File</div></label>
            //     {fileName == "" ? <div style={{ color: "red", fontSize: "12px", fontWeight: "600" }}>{uploadError}</div> : <div style={{ color: "#6900B8", fontSize: "12px", fontWeight: "600" }}>{fileName}</div>}
            //     {/* <label id="file-input-label" htmlFor="file-input" ><div style={{ display: "flex", alignItems: "center" }}><FileUploadOutlinedIcon />Upload File</div></label> */}
            //     <div style={{ color: "#999999", fontSize: "10px", fontWeight: "600", margin: "10px 0px" }}>Supported file formats: PDF, JPG<br />Maximum file size: 500 kb</div>
            // </div>
          ) : <div className='sub-heading' style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "60%" }}>Select at least one Payment Method</div>}
        </div>

        <div style={{ float: "right" }}>
          <PurpleCustomButton
            children="Make Payment"
            onClick={
              paymentType == "banktransfer"
                ? handleBankTransfer
                : paymentMethod == "existingCard"
                  ? handleExistingCardSubmit
                  : handleSubmit
            }
            disable={sectionOne == true ? true : false}
          />
        </div>

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={openSession}
          onClose={handleSessionClose}
          message="Session Expired!"
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  currencySymbol: state.user.currencySymbol,
  authToken: state.user.authToken,
  sessionId: state.user.sessionId,
  advertiserId: state.user.advertiserId,
  userEmail: state.user.userEmail,
  countryCode: state.user.countryCode,
  mastercouponArray: state.user.mastercouponArray,
  status: state.user.status,

  campaignSubtype: state.user.campaignSubtype,
  appPlatform: state.user.appPlatform,
  appName: state.user.appName,
  campaignName: state.user.campaignName,
  appImageUrl: state.user.appImageUrl,
  packageName: state.user.packageName,

  location: state.user.location,
  language: state.user.language,
  tracking: state.user.tracking,
  trackingUrl: state.user.trackingUrl,
  impressionUrl: state.user.impressionUrl,
  tokenEvent: state.user.tokenEvent,
  locationCode: state.user.locationCode,


  budget: state.user.budget,
  focus: state.user.focus,
  target: state.user.target,
  costPerInstall: state.user.costPerInstall,

  adGroupName: state.user.adGroupName,
  logoUrl: state.user.logoUrl,
  headline: state.user.headline,
  description: state.user.description,
  imageUrl: state.user.imageUrl,
  videoUrl: state.user.videoUrl,
  htmlUrl: state.user.htmlUrl,

  campaign_Id: state.user.campaign_Id,
  budget_Id: state.user.budget_Id,


  dataSource: state.user.dataSource,
  segmentName: state.user.segmentName,
  dataType: state.user.dataType,
  dataUpload: state.user.dataUpload,
  uploadedUrl: state.user.uploadedUrl,

  transactionCurrency: state.user.transactionCurrency,
  advertiserIdCampaign: state.user.advertiserIdCampaign,

});

const mapDispatchToProps = {
  setCurrencySymbol,
  setAdvertiserId,
  setAuthToken,
  setSessionId,
  setUserEmail,
  setCountryCode,
  setMasterCouponArray,
  setStatus,
  setCampaignSubType,
  setAppPlatform,
  setAppName,
  setCampaignName,
  setAppImageUrl,
  setPackageName,

  setLanguage,
  setLocation,
  setTracking,
  setTrackingUrl,
  setImpressionUrl,
  setTokenEvent,
  addTokenEventObject,
  removeTokenEventObject,
  updateToken,
  updateEvent,
  setLocationCode,


  setBudget,
  setFocus,
  setTarget,
  setCostPerInstall,

  setAdGroupName,
  setLogoUrl,
  setHeadline,
  setDescription,
  setImageUrl,
  setVideoUrl,
  setHtmlUrl,
  setCount,
  setCampaignId,
  setBudgetId,

  setImageFile,
  setVideoFile,
  setHtmlFile,

  setImageUploadError,
  setLogoError,
  setLocationCheck,

  setDataSource,
  setSegmentName,
  setDataType,
  setDataUpload,
  setUploadedUrl,

  setDataSourceError,
  setSegmentNameError,
  setDataTypeError,
  setDataUploadError,
  setUploadedUrlError,
  setClicks,
  setImpression,
  setConversion,
  setInstall,
  setPayout,
  setCtr,
  setCpi,
  setTransactionCurrency,
  setDailyAvgBudget,

};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);
