import React, { useEffect, useState } from "react";
import leftSideBG from "../../IMAGE/leftSideBG.svg";
import rightSideBG from "../../IMAGE/rightSideBG.svg";
import logo from "../../IMAGE/logo.svg";
import "../../CSS/SignIn.css";
import { BiSolidErrorCircle } from "react-icons/bi";
import { BsCheckCircleFill } from "react-icons/bs";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
// import { pink } from '@mui/material/colors';
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiURL } from "../../Constant";
const CryptoJS = require("crypto-js");

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const ResetPassword: React.FC = () => {
  const navigate = useNavigate();

  const [error, setError] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [confirmPass, setConfirmPass] = useState<string>("");
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [lowerCase, setLowerCase] = useState<boolean>(false);
  const [upperCase, setUpperCase] = useState<boolean>(false);
  const [numberCase, setNumberCase] = useState<boolean>(false);
  const [specialCase, setSpecialCase] = useState<boolean>(false);
  const [minimumCase, setMinimumCase] = useState<boolean>(false);
  const [key, setKey] = useState<string | null>("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibilityTwo = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleChange = (e: any) => {
    let value = e.target.value;
    switch (e.target.name) {
      case "password":
        setError("");
        const passRegex =
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        const hasUppercase = /[A-Z]/.test(value);
        const hasLowercase = /[a-z]/.test(value);
        const hasSpecialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\\-]/.test(
          value
        );
        const hasNumber = /\d/.test(value);

        if (hasLowercase) {
          setLowerCase(true);
        }

        if (hasUppercase) {
          setUpperCase(true);
        }

        if (hasSpecialCharacter) {
          setSpecialCase(true);
        }

        if (hasNumber) {
          setNumberCase(true);
        }

        if (value.length >= 8) {
          setMinimumCase(true);
        }

        if (
          !passRegex.test(value) ||
          value == "" ||
          value == null ||
          value.length == 0
        ) {
          setError("Invalid password");
        } else {
          setPassword(value);
        }
        break;
      case "confirm-password":
        setError("");
        if (
          !value.match(password) ||
          value == "" ||
          value == null ||
          value.length == 0
        ) {
          setError("Password did not match");
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const keyParam = queryParams.get("key");
    setKey(keyParam);
  }, [key]);

  const nextPage = async () => {
    if (password == "" && confirmPass == "") {
      setError("Invalid Password");
    } else {
      try {
        let jsonParams = {
          Password: password,
          ForgotPassKey: key,
        };

        const secretKey = "Adserving123";
        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(jsonParams),
          secretKey
        ).toString();
        const params = {
          params: encryptedData,
        };
        const response = await axios.post(
          `${apiURL}/api/resetPassword`,
          params
        );
        if (response.data.status == true) {
          navigate("/passwordReset");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="signIn-main">
      <div className="left-img-div">
        <img src={leftSideBG} alt="leftSide" />
      </div>
      <div className="center-div">
        <img className="admattic-logo" src={logo} alt="logo" />

        <div className="mid-box">
          <h2 className="reset-mid">RESET PASSWORD</h2>

          {/* <div className="error-box">
            <BiSolidErrorCircle className="error-icon" />
            <p>Incorrect Email or Password</p>
          </d iv> */}
          <span
            className="grey-text center-align reset-text"
            style={{ fontWeight: 600 }}
          >
            Your new password must be different to
            <br /> previously used password
          </span>

          {error != "" && error != null && (
            <div className="error-box">
              <BiSolidErrorCircle className="error-icon" />
              <p>{error}</p>
            </div>
          )}

          <div className="details-box">
            {/* <div className="email-box">
              <label htmlFor="email"> Email/Password</label>
              <input type="text" name="email" />
            </div> */}

            <div className="pwd-box">
              <label htmlFor="password">Enter New Password</label>
              <div className="hide-button">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  defaultValue={password}
                  onChange={handleChange}
                />
                <button
                  type="button"
                  className="eye-button"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <AiFillEyeInvisible className="eye-icon" />
                  ) : (
                    <AiFillEye className="eye-icon" />
                  )}
                </button>
              </div>
            </div>

            <div className="pwd-box" style={{ marginTop: "60px" }}>
              <label htmlFor="password">Confirm Password</label>
              <div className="hide-button">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  id="password"
                  name="confirm-password"
                  defaultValue={confirmPass}
                  onChange={handleChange}
                />
                <button
                  type="button"
                  className="eye-button"
                  onClick={togglePasswordVisibilityTwo}
                >
                  {showConfirmPassword ? (
                    <AiFillEyeInvisible className="eye-icon" />
                  ) : (
                    <AiFillEye className="eye-icon" />
                  )}
                </button>
              </div>
            </div>
          </div>

          {/* <div className="chk-box">
            <div className="chk-div">
              <Checkbox
                {...label}
                className="chk"
                //     sx={{
                //      color: "#6900B8",
                // }}
              /> */}
          {/* <input type="checkbox" className="chk" /> */}
          {/* <p>Remember Me ?</p>
            </div>
            <a href="https://www.youtube.com/">Forgot Password ?</a>
          </div> */}
          <div className="error-main">
            <div className="error-box error-margin">
              {lowerCase ? (
                <BsCheckCircleFill className="success-icon" />
              ) : (
                <BiSolidErrorCircle className="error-icon" />
              )}
              {lowerCase ? (
                <p className="success-para">One lower case character</p>
              ) : (
                <p className="error-para">One lower case character</p>
              )}
            </div>

            <div className="error-box error-margin">
              {upperCase ? (
                <BsCheckCircleFill className="success-icon" />
              ) : (
                <BiSolidErrorCircle className="error-icon" />
              )}
              {upperCase ? (
                //   <BiSolidErrorCircle className="error-icon" />
                <p className="success-para">One upper case character</p>
              ) : (
                <p className="error-para">One upper case character</p>
              )}
            </div>

            <div className="error-box error-margin">
              {numberCase ? (
                <BsCheckCircleFill className="success-icon" />
              ) : (
                <BiSolidErrorCircle className="error-icon" />
              )}
              {numberCase ? (
                <p className="success-para">One Number</p>
              ) : (
                <p className="error-para">One Number</p>
              )}
            </div>

            <div className="error-box error-margin">
              {specialCase ? (
                <BsCheckCircleFill className="success-icon" />
              ) : (
                <BiSolidErrorCircle className="error-icon" />
              )}
              {specialCase ? (
                // <BiSolidErrorCircle className="error-icon" />
                <p className="success-para">One Special Character</p>
              ) : (
                // <BsCheckCircleFill className="success-para" />
                <p className="error-para">One Special Character</p>
              )}
            </div>
            <div className="error-box error-margin">
              {minimumCase ? (
                <BsCheckCircleFill className="success-icon" />
              ) : (
                <BiSolidErrorCircle className="error-icon" />
              )}
              {minimumCase ? (
                <p className="success-para">8 character minimum</p>
              ) : (
                <p className="error-para">8 character minimum</p>
              )}
            </div>
          </div>
          <button
            className="signIn-btn btn-width reset-margin"
            onClick={nextPage}
          >
            Reset Password
          </button>
        </div>
        {/* <div className="signup-box">
          <p>New to Admattic?&nbsp;&nbsp;</p>
          <a href="https://www.youtube.com/">Sign Up</a>
        </div> */}
      </div>
      <div className="right-img-div">
        <img src={rightSideBG} alt="rightSide" />
      </div>
    </div>
  );
};

export default ResetPassword;
