import React, { FC, useEffect } from 'react';
import '../CSS/header.css';
import AdmatticLogo from "../IMAGE/logo.svg";
import AdminLogo from "../IMAGE/AdminLogo.svg";
import Wallet from "../IMAGE/wallet.svg";
import { Badge, Drawer, InputAdornment, Popover, Slide, SlideProps, Snackbar, TextField, Typography, } from "@mui/material";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from '@mui/icons-material/Person';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { apiURL, secretKey } from '../Constant';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '../REDUX/Reducers/rootReducer';
import { setAdvertiserId, setAdvertisersList, setAuthToken, setCountryCode, setCountryCodeAdvertiser, setCurrencySymbol, setMasterCountryArray, setSessionId, setTotalBalance, setUserType } from '../REDUX/Actions/userActionIndex';
const CryptoJS = require('crypto-js')

type Anchor = 'top' | 'left' | 'bottom' | 'right';

type TransitionProps = Omit<SlideProps, 'direction'>;

function TransitionLeft(props: TransitionProps) {
    return <Slide {...props} direction="left" />;
}

interface HeaderProps {
    currencySymbol: any;
    authToken: any;
    sessionId: any;
    advertiserId: any;
    countryCode: any;
    totalBalance: any;
    masterCountryArray: any;
    usertype: any;
    advertisersList: any[];
}

const Header: FC<HeaderProps> = (props) => {

    const { currencySymbol, authToken, sessionId, advertiserId, countryCode, totalBalance, masterCountryArray, usertype, advertisersList } = props

    const [searchTerm, setSearchTerm] = useState("");
    const [bal, setBal] = useState<any>("")
    const [name, setName] = useState<any>("")
    const [orgname, setOrgName] = useState<any>("")
    const [userType, setUserTypeName] = useState<any>("")
    const [country, setCountry] = useState<string>("")
    const [findCountry, setFindCountry] = useState<any>()

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [advertisersData, setAdvertisersData] = useState<any[]>([])

    const handleChange = (e: any) => {
        setSearchTerm(e.target.value);
    };

    const [openSession, setOpenSession] = React.useState(false);

    const handleSessionClose = () => {
        setOpenSession(false);
    };

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }
                setState({ ...state, [anchor]: open });
            };

    const [image, setImage] = useState("")

    const getUserDetails = async () => {
        try {
            const encryptAdvertiserID = CryptoJS.AES.encrypt(JSON.stringify(advertiserId), secretKey).toString()
            let headers = {
                'Content-Type': 'application/json',
                'advertiserid': encryptAdvertiserID,
                'authtoken': authToken,
                'sessionid': sessionId
            };

            let jsonParams = { advertiserId: advertiserId };
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(jsonParams), secretKey).toString();

            const response = await axios.post(`${apiURL}/api/user/getUser`, { params: encryptedData }, { headers: headers });
            if (response.data.status == true) {
                const resData = response.data.data;
                const bytes = CryptoJS.AES.decrypt(resData, secretKey);
                const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
                const parsedData = JSON.parse(decryptedData);
                setCountry(parsedData['Country'])
                setName(parsedData['Name'])
                setOrgName(parsedData['OrganizationName'] == null ? "" : parsedData['OrganizationName'])
                setUserTypeName(parsedData['UserType'] == null ? "" : parsedData['UserType'])
                setBal(parsedData['BalanceAmount'] == null ? "0" : parsedData['BalanceAmount'])
                localStorage.setItem("totalBalance", parsedData['BalanceAmount'])
                dispatch(setTotalBalance(parsedData['BalanceAmount']))
                dispatch(setUserType(parsedData['UserType']))
                if (parsedData['ProfilePicture'] == null) {
                    setImage("")
                } else {
                    // const uint8Array = new Uint8Array(resData['ProfilePicture']['data']);
                    const uint8Array = new Uint8Array(parsedData['ProfilePicture']['data']);
                    const blob = new Blob([uint8Array]);
                    const dataUrl = URL.createObjectURL(blob);
                    setImage(dataUrl);
                    // const blob2 = new Blob([Buffer.from(resData['ProfilePicture']['data'])], { type: 'application/octet-stream' });
                }

                if (parsedData['UserType'] == "admin" || parsedData['UserType'] == "Admin") {
                    getAllAdvertisers()
                }

            } else if (response.data.statuscode == 401) {
                setOpenSession(true)
                setTimeout(() => { navigate("/") }, 1000)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getAllAdvertisers = () => {
        const encryptAdvertiserID = CryptoJS.AES.encrypt(JSON.stringify(props.advertiserId), secretKey).toString()
        let headers = {
            'Content-Type': 'application/json',
            'advertiserid': encryptAdvertiserID,
            'authtoken': props.authToken,
            'sessionid': props.sessionId
        };
        axios
            .get(`${apiURL}/api/admin/advertisers`, { headers: headers })
            .then((response) => {
                if (response.data.status == true) {
                    const resData = response.data.data;
                    const bytes = CryptoJS.AES.decrypt(resData, secretKey);
                    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
                    const parsedData = JSON.parse(decryptedData);
                    setAdvertisersData(parsedData)
                    dispatch(setAdvertisersList(parsedData))
                } else if (response.data.statuscode == 401) {
                    setOpenSession(true)
                    setTimeout(() => { navigate("/") }, 2000)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        // const couponArray = getCouponMaster()
        // getAllAdvertisers();
        // getAllCoupons();
    }, [])

    useEffect(() => {
        getUserDetails()
        // Simulate an async user type fetch
        setTimeout(() => {
            setLoading(false);
        }, 200);
    }, [])

    useEffect(() => {
        handleCurrency()
    }, [country])

    const handleCurrency = () => {
        let countryArray = []
        countryArray = masterCountryArray
        if (countryArray && Array.isArray(countryArray)) {
            let countryFind = countryArray.find((element: any) => { return country === element.Country })

            setFindCountry(countryFind)
            if (countryFind != undefined) {
                localStorage.setItem("currency", countryFind.CurrencySymbol);
                localStorage.setItem("countryCode", countryFind.Code)
                localStorage.setItem("budgetLimit", countryFind.BudgetLimit)
                dispatch(setCurrencySymbol(countryFind.CurrencySymbol))
                dispatch(setCountryCode(countryFind.Code))
                dispatch(setCountryCodeAdvertiser(countryFind.CountryCode));
                // setCurrency(countryFind.CurrencySymbol)
            }
        }
    }

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [loading, setLoading] = useState(true);

    if (loading) {
        return null; // Render nothing while loading
    }

    return (
        userType.toLowerCase() == "admin" ? (<div className='admincontainer'>
            <div className='app-bar'>
                <div className='logo-div'>
                    <img src={AdminLogo} height="60px" width="120px" />
                </div>
                <div className='main-div' style={{
                    borderLeft: "1px solid white"
                }}>
                    <div className='account-div'>
                        {/* <div className='account-heading'>Account</div>
                        {orgname != "" ? <div style={{ display: "flex", alignItems: "center", justifyContent: "left" }}>{orgname.trim()}{userType != "" ? <div style={{ fontSize: "10px" }}>({userType})</div> : <div></div>}
                        </div> : <div></div>}
                        <div className='account-content'>{name}
                        </div> */}
                    </div>
                    <div className='profile-div' style={{
                        width: "auto"
                    }}>
                        {/* <div className='search-box'>
                            <TextField
                                id="search"
                                type="search"
                                value={searchTerm}
                                onChange={handleChange}
                                sx={{
                                    width: "inherit",
                                    '& .MuiOutlinedInput-root': {
                                        "& fieldset": { border: 'none' },
                                        '&.Mui-focused fieldset': {
                                            border: '1.35px solid #6900B8',
                                            borderRadius: '3px'
                                        }
                                    }
                                }}
                                placeholder='Try "Billing"'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon sx={{ color: "#333333" }} />
                                        </InputAdornment>
                                    ),
                                    style: {
                                        height: 46,
                                    }
                                }}
                            />
                        </div> */}

                        {/* <Link to="/home/addFunds" state={
                            {
                                amount: "",
                                paymentType: "",
                                isCampaignPublished: false,
                            }
                        } style={{ textDecoration: 'none' }}>
                            <div className='wallet-box'>
                                <img src={Wallet} />
                                <h2 style={{ fontFamily: "Verdana, Geneva, Tahoma, sans-serif" }}>{currencySymbol} {bal}</h2>
                                <div className='plus-box'>+</div>
                            </div>
                        </Link> */}
                        <div style={{
                            float: "right"
                        }}>
                            <button className='bell-button bell-button-admin' onClick={handleClick}>
                                <NotificationsNoneIcon />
                            </button>
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                            >
                                <Typography sx={{ p: 1 }} className='sub-heading'>Notifications Coming Soon..</Typography>
                            </Popover>
                            {/* <Drawer
                                sx={{
                                    padding: "10%"
                                }}
                                anchor="right"
                                open={state["right"]}
                                onClose={toggleDrawer("right", false)}
                            >
                                <div className='drawer-notification'>
                                No notifications yet
                                </div>
                            </Drawer> */}
                        </div>
                        <Link to="/home/settings" style={{ borderRadius: "2px", marginLeft: "10px" }}>
                            {
                                image == "" ? <div className='bell-button'>
                                    <PersonIcon />
                                </div> : <div style={{ display: "flex" }}><img src={image} height="42px" width="42px" /></div>
                            }
                        </Link>
                    </div>
                </div>
            </div>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={openSession}
                onClose={handleSessionClose}
                message="Session Expired!"
            />
        </div>) :
            (<div className='container'>
                <div className='app-bar'>
                    <div className='logo-div'>
                        <img src={AdmatticLogo} className='logo-img' />
                    </div>
                    <div className='main-div'>
                        <div className='account-div'>
                            <div className='account-heading'>Account</div>
                            {orgname != "" ? <div style={{ display: "flex", alignItems: "center", justifyContent: "left" }}>{orgname.trim()}{userType != "" ? <div style={{ fontSize: "10px" }}>({userType})</div> : <div></div>}
                            </div> : <div></div>}
                            <div className='account-content'>{name}
                            </div>
                        </div>
                        <div className='profile-div'>
                            {/* <div className='search-box'>
                            <TextField
                                id="search"
                                type="search"
                                value={searchTerm}
                                onChange={handleChange}
                                sx={{
                                    width: "inherit",
                                    '& .MuiOutlinedInput-root': {
                                        "& fieldset": { border: 'none' },
                                        '&.Mui-focused fieldset': {
                                            border: '1.35px solid #6900B8',
                                            borderRadius: '3px'
                                        }
                                    }
                                }}
                                placeholder='Try "Billing"'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon sx={{ color: "#333333" }} />
                                        </InputAdornment>
                                    ),
                                    style: {
                                        height: 46,
                                    }
                                }}
                            />
                        </div> */}
                            <Link to="/home/addFunds" state={
                                {
                                    amount: "",
                                    paymentType: "",
                                    isCampaignPublished: false,
                                }
                            } style={{ textDecoration: 'none' }}>
                                <div className='wallet-box'>
                                    <img src={Wallet} />
                                    <h2 style={{ fontFamily: "Verdana, Geneva, Tahoma, sans-serif" }}>{currencySymbol} {bal}</h2>
                                    <div className='plus-box'>+</div>
                                </div>
                            </Link>
                            <div>
                                <button className='bell-button' onClick={handleClick}>
                                    <NotificationsNoneIcon />
                                </button>
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Typography sx={{ p: 1 }} className='sub-heading'>Notifications Coming Soon..</Typography>
                                </Popover>
                                {/* <Drawer
                                sx={{
                                    padding: "10%"
                                }}
                                anchor="right"
                                open={state["right"]}
                                onClose={toggleDrawer("right", false)}
                            >
                                <div className='drawer-notification'>
                                No notifications yet
                                </div>
                            </Drawer> */}
                            </div>
                            <Link to="/home/settings">
                                {
                                    image == "" ? <div className='bell-button'>
                                        <PersonIcon />
                                    </div> : <div style={{ display: "flex" }}><img src={image} height="46px" width="46px" style={{ borderRadius: "3px" }} /></div>
                                }
                            </Link>
                        </div>
                    </div>
                </div>
                <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    open={openSession}
                    onClose={handleSessionClose}
                    message="Session Expired!"
                />
            </div>)
    );
}

const mapStateToProps = (state: RootState) => ({
    currencySymbol: state.user.currencySymbol,
    authToken: state.user.authToken,
    sessionId: state.user.sessionId,
    advertiserId: state.user.advertiserId,
    countryCode: state.user.countryCode,
    totalBalance: state.user.totalBalance,
    masterCountryArray: state.user.masterCountryArray,
    usertype: state.user.usertype,
    advertisersList: state.user.advertisersList,
});

const mapDispatchToProps = {
    setCurrencySymbol,
    setAdvertiserId,
    setAuthToken,
    setSessionId,
    setCountryCode,
    setTotalBalance,
    setMasterCountryArray,
    setUserType,
    setAdvertisersList
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);