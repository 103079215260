import React, { FC, useState, useEffect, useRef } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import calender from "../../IMAGE/calender.svg";
import "../../CSS/DateCustom.css";
import { setSelectedDateRange } from "../../REDUX/Actions/userActionIndex";
import { RootState } from "../../REDUX/Reducers/rootReducer";
import { connect, useDispatch } from "react-redux";

interface DateCustomPickerProps {
  onDateChange?: (startDate: Date, endDate: Date) => void;
  onClick?: () => void;
  bottom?: string;
  selectedDateRange: any;
  rightPosition?: boolean;
}

const DateCustomPicker: FC<DateCustomPickerProps> = ({
  onDateChange,
  onClick,
  bottom = "30.5pc",
  selectedDateRange,
  rightPosition = false,
  // value
}) => {

  const currentYear = new Date().getFullYear();
  const initialStartDate = new Date(currentYear, 0, 1);
  const initialEndDate = new Date();

  // const [dateRange, setDateRange] = useState<any>({
  //   startDate: initialStartDate,
  //   endDate: initialEndDate,
  // });

  const [dateRange, setDateRange] = useState<any>({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(),
  });

  const [datePicked, setDatePicked] = useState<boolean>(false);
  const [toggleCal, setToggleCal] = useState<string>("none");
  const boxRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (boxRef.current && !boxRef.current.contains(event.target as Node)) {
      setToggleCal("none");
    }
  };

  const dispatch = useDispatch()

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // useEffect(() => {
  //   console.log("selectedDateRange",selectedDateRange);
  //   localStorage.setItem("startDate", initialStartDate.toString());
  //   localStorage.setItem("endDate", initialEndDate.toString());
  //   dispatch(setSelectedDateRange({
  //     startDate: initialStartDate,
  //     endDate: initialEndDate
  //   }))
  // }, []);

  useEffect(() => {
    // const startDate = localStorage.getItem("startDate")!;
    // const endDate = localStorage.getItem("endDate")!;
    // if (startDate != "" && endDate != "" && endDate != null && startDate != null) {
    //   const s = new Date(startDate);
    //   const e = new Date(endDate);
    //   const dateObj = {
    //     startDate: s,
    //     endDate: e,
    //   };
    //   setDateRange(dateObj);
    //   dispatch(setSelectedDateRange({
    //     startDate: startDate,
    //     endDate: endDate
    //   }))
    // } else {
    //   localStorage.setItem("startDate", dateRange.startDate);
    //   localStorage.setItem("endDate", dateRange.endDate);
    //   dispatch(setSelectedDateRange({
    //     startDate: dateRange.startDate,
    //     endDate: dateRange.endDate
    //   }))
    // }
         const s = new Date(selectedDateRange.startDate);
      const e = new Date(selectedDateRange.endDate);
    setDateRange({
      startDate: s,
      endDate: e
    })
  }, [selectedDateRange]);

  const handleSelect = (ranges: any) => {
    if (!datePicked) {
      setDatePicked(true);
    }
    setDateRange(ranges.range1);

    // Call the callback function from the parent component
    if (onDateChange) {
      onDateChange(ranges.range1.startDate, ranges.range1.endDate);
    }
    localStorage.setItem("startDate", ranges.range1.startDate);
    localStorage.setItem("endDate", ranges.range1.endDate);
    dispatch(setSelectedDateRange({
      startDate: ranges.range1.startDate,
      endDate: ranges.range1.endDate
    }))
  };

  const toggleCalender = () => {
    if (toggleCal === "none") {
      setToggleCal("block");
    } else {
      setToggleCal("none");
    }
  };

  const doneClose = () => {
    toggleCalender();
    if (onClick) {
      onClick();
    }
  };

  return (
    <div>
      <div ref={boxRef} className="box" >
        <div style={{ display: "flex", alignItems: "center", position: "relative", }}>
          <h5 style={{ fontWeight: 800, fontSize: "14px", marginRight: "8px" }}>
            Custom
          </h5>
          <div className="calender-btn" onClick={toggleCalender} >
            <img src={calender} alt="calendar" />
            <p>
              {dateRange.startDate.toLocaleDateString("default", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })}
            </p>
            -
            <p>
              {dateRange.endDate.toLocaleDateString("default", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })}
            </p>
          </div>
        </div>
        <div style={{ display: `${toggleCal}`, position: "absolute", right: rightPosition ? "32%" : "15%" }}>
          <DateRangePicker
            ranges={[dateRange]}
            onChange={handleSelect}
            maxDate={new Date()}
          />
          <div className="button-container">
            <button
              className="purple-btn width-adjust"
              onClick={doneClose}
              style={{
                margin: "0 15px",
                cursor: "pointer",
              }}
            >
              Done
            </button>
            <button className="border-btn" onClick={toggleCalender}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedDateRange: state.user.selectedDateRange
});

const mapDispatchToProps = {
  setSelectedDateRange
};

export default connect(mapStateToProps, mapDispatchToProps)(DateCustomPicker);