import React, { FC } from "react";
import styled from "styled-components";
import { AdminNavbarBottomData, AdminNavData, NavbarBottomData, NavData } from "./SideNavData";
import { NavbarMenu, NavbarBottomMenu } from "./NavbarItems";

const SideNavBar = styled.div`
     position: fixed;
     height: 100%;
     display: flex;
     z-index: 999;
     flex-direction: column;
     width: 16%;
     align-items: start;
     background-color: white;
     border-right: 3px solid rgba(0, 0, 0, 0.05);
     border-bottom: 3px solid rgba(0, 0, 0, 0.05);
     margin-top:93px;
`;

const AdminNavbar: FC = () => {
  return <>
    <SideNavBar>
      <div style={{ paddingBottom: 80 , paddingTop: 30, paddingLeft: "20%" }}>
        {AdminNavData.map((item, index) => {
          return <NavbarMenu item={item} key={index} />;
        })}
      </div>
      <hr style={{
        color: "#c4c4c4",
        width: "80%",
        alignSelf: "center"
      }}/>
      <div style={{ paddingLeft: "20%" }}>
        {AdminNavbarBottomData.map((item, index) => {
          return <NavbarBottomMenu item={item} key={index} />;
        })}
      </div>
    </SideNavBar>
  </>
}

export default AdminNavbar;