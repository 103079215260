import React, { ChangeEvent, FC, Fragment, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import "../../CSS/CampaignInfo.css";
import "../../CSS/SignIn.css";
import "../../CSS/EditCampaign.css";
import { AiOutlineSearch } from "react-icons/ai";
import Checkbox from "@mui/material/Checkbox";
import { BiSolidErrorCircle } from "react-icons/bi";
import { styled } from "@mui/material/styles";
import {
  Alert,
  Autocomplete,
  Icon,
  Menu,
  MenuItem,
  Modal,
  Snackbar,
  TextField,
  Select,
  InputLabel,
} from "@mui/material";
import { connect, useDispatch } from "react-redux";
import { RootState } from "../../REDUX/Reducers/rootReducer";
import {
  setCampaignSubType,
  setAppPlatform,
  setAppName,
  setCampaignName,
  setAppImageUrl,
  setPackageName,
  setDataSource,
  setSegmentName,
  setDataType,
  setDataUpload,
  setUploadedUrl,
  setDataSourceError,
  setSegmentNameError,
  setDataTypeError,
  setDataUploadError,
  setUploadedUrlError,
  setAdvertiserName,
  setAdvertiserIdCampaign,
} from "../../REDUX/Actions/userActionIndex";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Dropzone, { useDropzone } from "react-dropzone";
import { Button, Typography } from "@mui/material";
import { EditStatusArray, apiURL, secretKey } from "../../Constant";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import AWS from "aws-sdk";
import { customCheckBoxColor } from "../../Constant";
import { AutoComplete } from "antd";
import { PurpleCustomButton } from "./PurpleButton";
import CreateAdvertiser from "../pages/admin/CreateAdvertiser";
import { useNavigate } from "react-router-dom";
// import fs from 'fs';
const CryptoJS = require("crypto-js");
const label = { inputProps: { "aria-label": "Checkbox demo" } };

interface CustomButtonProps {
  onClick?: (data: boolean) => void;
  onBackClick?: () => void;

  errorCheck?: boolean;
  adminView?: boolean;
  advertiserName: string;
  advertisersList: any;
  campaignSubtype: string;
  appPlatform: string;
  appName: string;
  campaignName: string;
  appImageUrl: string;
  packageName: string;

  dataSource: string;
  segmentName: string;
  dataType: string;
  dataUpload: File[];
  uploadedUrl: string;

  dataSourceError: string;
  segmentNameError: string;
  dataTypeError: string;
  dataUploadError: File[];
  uploadedUrlError: string;
  advertiserId: string;
  authToken: string;
  sessionId: string;
  advertiserIdCampaign: string;
}

// const useStyles = makeStyles((theme) => ({
//   radio: {
//     '&:hover': {
//       backgroundColor: 'rgba(0, 0, 0, 0.08)',
//     },
//   },
//   pinkRadio: {
//     color: purple[800],
//     '&.Mui-checked': {
//       color: purple[600],
//     },
//   },
// }));

const CustomTextField = styled(TextField)({
  width: "100%",
  "&.MuiTextField-root input": {
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    border: "none",
    outline: "none",
  },
  "& fieldset": {
    border: "none",
    outline: "none",
  },
  "&.Mui-focused fieldset": {
    border: "none",
    outline: "none",
  },
  "& .MuiFormHelperText-root": {
    color: "red",
    paddingLeft: 0,
    margin: "10px 0px",
  },
});
const useStylesModal = makeStyles<any>((theme: any) => ({
  root: {
    "& .css-79ws1d-MuiModal-root": {
      // Add your custom styles here
      backgroundColor: "rgba(0,0,0,0.5)",
    },
  },
}));
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      height: "40px",
      width: "100%",
      marginTop: "8px",

      "&:focus-within": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "grey",
          border: "1px solid grey",
        },
      },
      "& .MuiAutocomplete-input": {
        padding: "0 4px 0 5px !important",
        // width: "100px",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid grey",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "1px solid grey",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid grey",
      },
      "&.Mui-focused .MuiAutocomplete-endAdornment .MuiSvgIcon-root": {
        color: "grey",
      },

      "& .MuiAutocomplete-endAdornment .MuiSvgIcon-root": {
        color: "grey",
      },

      "& fieldset": {
        borderColor: "grey",
        borderWidth: "2px",
        height: "40px",
        border: "1px solid grey",
      },
    },
    '& input[type="text"]:focus': {
      // content: '""',
      // display:'none',
      // border: '1px solid green',
      outline: "none",
    },
  },
}));

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "79%",
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const useStylesForm = makeStyles({
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  dropZone: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    border: "2px dashed #ccc",
    borderRadius: "8px",
    transition: "border 0.2s ease",
    "&:hover": {
      borderColor: "#6c63ff",
    },
  },
  button: {
    marginTop: "16px",
  },
});

const CampaignInfo: React.FC<CustomButtonProps> = ({
  authToken,
  sessionId,
  onClick,
  onBackClick,
  errorCheck,
  advertiserId,
  appName,
  campaignSubtype,
  appPlatform,
  campaignName,
  packageName,
  appImageUrl,

  dataSource,
  segmentName,
  dataType,
  dataUpload,
  uploadedUrl,

  dataSourceError,
  segmentNameError,
  dataTypeError,
  dataUploadError,
  uploadedUrlError,
  adminView = false,
  advertiserName,
  advertiserIdCampaign,
  advertisersList,
}) => {
  // const { onClick, onBackClick, errorCheck } = props;
  // const [campaignSubtype, setCampaignSubtype] = useState<string>("");
  // const [appPlatform, setAppPlatform] = useState<string>("");
  // const [campaignName, setCampaignName] = useState<string>("");
  // const [appLookup, setAppLookup] = useState<string>("");
  const classes = useStyles();
  const [files, setFiles] = useState<File[]>([]);

  const dispatch = useDispatch();
  const [campaignDisabled, setCampaignDisabled] = useState<boolean>(false);
  const [appLookupDisabled, setAppLookupDisabled] = useState<boolean>(false);

  const [campaignSubtypeError, setCampaignSubtypeError] = useState<string>("");
  const [appPlatformError, setAppPlatformError] = useState<string>("");
  const [campaignNameError, setCampaignNameError] = useState<string>("");
  const [appLookupError, setAppLookupError] = useState<string>("");
  const [appPlatformDisabled, setAppPlatformDisabled] =
    useState<boolean>(false);
  const [value, setValue] = useState<string>("dataSource");

  const [errorOpen, setErrorOpen] = React.useState(false);
  const [errorMsgSnackBar, setErrorMsgSnackBar] = useState<any>("");
  const [fileName, setFileName] = useState<string>("");
  const [countryArr, setCountryArr] = useState<any[]>([]);
  const [advertiserValue, setAdvertiserValue] = useState<any>({
    advertiserId: "",
    advertiserName: "",
    id: "",
    advertiserProfile: "",
    Country: "",
    email: "",
  });
  const [advertiserError, setAdvertiserError] = useState<string>("");

  const CustomCheck = styled(Checkbox)`
    &.MuiButtonBase-root.MuiCheckbox-root {
      padding: 0 9px;
    }
  `;

  const [advertiserData, setAdvertiserData] = useState<any>([]);
  const [appArray, setAppArray] = useState<any>([]);

  // const getDetailsCampaign = () => {
  //   // const advertiserId = localStorage.getItem("advertiserID") || "";
  //   // const authToken = localStorage.getItem("authToken");
  //   // const sessionID = localStorage.getItem("sessionID");
  //   if (adminView) {
  //     const startDate = new Date();
  //     const endDate = new Date();
  //     const encryptAdvertiserID = CryptoJS.AES.encrypt(
  //       JSON.stringify(advertiserId),
  //       secretKey
  //     ).toString();

  //     const header = {
  //       headers: {
  //         authtoken: authToken,
  //         sessionid: sessionId,
  //         advertiserid: encryptAdvertiserID, // Example authorization header
  //       },
  //     };
  //     const year = startDate.getFullYear();
  //     const month = startDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month index
  //     const day = startDate.getDate();

  //     const startDatFormatted = `${year}-${month < 10 ? "0" + month : month}-${
  //       day < 10 ? "0" + day : day
  //     }`;

  //     const endyear = endDate.getFullYear();
  //     const endmonth = endDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month index
  //     const endday = endDate.getDate();

  //     const endDateFormatted = `${endyear}-${
  //       endmonth < 10 ? "0" + endmonth : endmonth
  //     }-${endday < 10 ? "0" + endday : endday}`;

  //     const jsonParam = {
  //       // advertiserId: isadmin ? selectedAdvertiserId : advertiserId,
  //       startDate: startDatFormatted,
  //       endDate: endDateFormatted,
  //     };

  //     console.log(jsonParam);
  //     const encryptedData = CryptoJS.AES.encrypt(
  //       JSON.stringify(jsonParam),
  //       secretKey
  //     ).toString();

  //     const url = `${apiURL}/api/advertisers/getAllAdvertisers`;
  //     if (authToken != "" && sessionId != "" && advertiserId != "") {
  //       axios
  //         .post(
  //           url,
  //           { params: encryptedData },
  //           {
  //             headers: {
  //               authtoken: authToken,
  //               sessionid: sessionId,
  //               advertiserid: encryptAdvertiserID,
  //               // "Content-Type": "application/json",
  //               // Example authorization header
  //             },
  //           }
  //         )
  //         .then((res) => {
  //           const resData = res.data.data;
  //           const bytes = CryptoJS.AES.decrypt(resData, secretKey);
  //           const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  //           const parsedData = JSON.parse(decryptedData);
  //           console.log(parsedData);
  //           if (parsedData.length != 0) {
  //             if (res.data.status) {
  //               // setdataComing(false);
  //               console.log(res.status);
  //               const resData = res.data.data;
  //               const bytes = CryptoJS.AES.decrypt(resData, secretKey);
  //               const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  //               const parsedData = JSON.parse(decryptedData);
  //               // const allMergedData: any[] = [];
  //               // parsedData.map((val: any) => {
  //               //   if (val.campaignData.length != 0) {
  //               //     const mergedData = val.campaignData.map((campaign: any) => {
  //               //       const upperParams = {
  //               //         id: val.id,
  //               //         advertiserId: val.advertiserId,
  //               //         name: val.name,
  //               //         totalCampaigns: val.totalCampaigns,
  //               //         totalInstalls: val.totalInstalls,
  //               //         totalBudget: val.totalBudget,
  //               //         totalCredit: val.totalCredit,
  //               //         totalSpends: val.totalSpends,
  //               //         totalInstallsToday: val.totalInstallsToday,
  //               //         lastLoginDate: val.lastLoginDate,
  //               //       };

  //               //       return {
  //               //         ...campaign,
  //               //         ...upperParams,
  //               //       };
  //               //     });
  //               //     allMergedData.push(...mergedData);
  //               //   }
  //               // });
  //               console.log(advertiserName);
  //               if (advertiserName != null && advertiserName != "") {
  //                 const data = parsedData.filter((val: any) => {
  //                   return val.advertiserId == advertiserIdCampaign;
  //                 });
  //                 if (data.length > 0) {
  //                   console.log(data[0]);
  //                   let val = {
  //                     id: data[0].id,
  //                     advertiserId: data[0].advertiserId,
  //                     name: data[0].name,
  //                     totalCampaigns: data[0].totalCampaigns,
  //                     totalInstalls: data[0].totalInstalls,
  //                     totalBudget: data[0].totalBudget,
  //                     totalCredit: data[0].totalCredit,
  //                     totalSpends: data[0].totalSpends,
  //                     totalInstallsToday: data[0].totalInstallsToday,
  //                     lastLoginDate: data[0].lastLoginDate,
  //                     campaignData: data[0].campaignData,
  //                     email: data[0].email,
  //                   };
  //                   setAdvertiserValue(val);
  //                 }
  //               }

  //               // console.log("mergedData", parsedData);
  //               setAdvertiserData(parsedData);
  //               // setTableData(allMergedData);
  //               // localStorage.setItem("campaignTable", JSON.stringify(allMergedData));
  //               if (parsedData.length != 0) {
  //                 // setDataContains(true);
  //               }
  //             }
  //           } else {
  //             // setdataComing(false);
  //             // setDataContains(false);
  //             // setTableData([]);
  //           }
  //         })
  //         .catch((err) => {
  //           // setdataComing(false);
  //           console.log(err);
  //         });
  //     }
  //   }
  // };

  function checkForEmptyRowsWithHeaders(file: File) {
    if (!file) {
      // console.error("File object is null or undefined.");
      return false;
    }

    const reader = new FileReader();
    reader.onload = function (event) {
      const fileContent = event.target?.result as string;
      if (fileContent) {
        const lines = fileContent.split("\n");
        const headers = lines[0].trim().split(","); // Assuming CSV format, adjust for XML headers
        let isEmptyRowPresent = false;

        for (let i = 1; i < lines.length; i++) {
          // Start from the second row, assuming the first row is headers
          const line = lines[i].trim();
          if (line === "") {
            isEmptyRowPresent = true;
            break;
          }
        }

        if (isEmptyRowPresent) {
          // alert("File contains empty rows.");
          // setUploadedUrlError("File contains empty rows.");
          return false;
        } else {
          // alert("File does not contain empty rows.");
          return true;
          // setUploadedUrlError("");
        }
      }
    };

    reader.readAsText(file);
  }

  // const { getRootProps, getInputProps, isDragActive } = useDropzone({
  //   accept: 'image/*,video/*',
  const onDrop = (acceptedFiles: any) => {
    dispatch(setUploadedUrlError(""));
    const filesList = acceptedFiles.reduce((fileList: any, file: any) => {
      fileList.items.add(file);
      return fileList;
    }, new DataTransfer()).files;

    acceptedFiles.forEach((file: any) => {
      const checkEmptyRows = checkForEmptyRowsWithHeaders(file);

      if (checkEmptyRows) {
        if (
          file.type === "text/csv" ||
          file.type === "text/xml" ||
          file.type === "application/xml"
        ) {
          dispatch(setDataUpload(acceptedFiles));
          uploadFiles(
            filesList,
            "admattic-internal-data-storage",
            "CustomerCsvData"
          );
          const reader = new FileReader();
          reader.onload = () => {
            const fileContent: string | ArrayBuffer | null = reader.result;
            if (typeof fileContent === "string") {
              let requiredHeaders: string[] = [];
              if (file.type === "text/csv") {
                // CSV specific headers
                if (dataType === "CONTACT") {
                  requiredHeaders = ["Email", "Phone", "Postal Address"];
                } else if (dataType === "USERID") {
                  requiredHeaders = ["UserId"];
                } else if (dataType === "MAID") {
                  requiredHeaders = ["MobileDeviceId"];
                }
              } else {
                // XML specific headers
                if (dataType === "CONTACT") {
                  requiredHeaders = ["Email", "Phone", "PostalAddress"]; // Adjust XML headers as needed
                } else if (dataType === "USERID") {
                  requiredHeaders = ["UserId"];
                } else if (dataType === "MAID") {
                  requiredHeaders = ["MobileDeviceId"];
                }
              }
              // Check if the file contains all required headers
              const containsRequiredHeaders: boolean = requiredHeaders.every(
                (header) => fileContent.includes(header)
              );
              if (containsRequiredHeaders) {
                dispatch(setUploadedUrlError(""));
              } else {
                dispatch(
                  setUploadedUrlError(
                    `File '${file.name}' does not contain all required headers.`
                  )
                );
              }
            }
          };
          reader.readAsText(file);
        } else {
          dispatch(setUploadedUrlError("Please upload only CSV or XML files"));
        }
      } else {
        dispatch(setUploadedUrlError("File contains empty rows."));
      }
    });
  };

  const createEmptyCSV = (filename: string, headers: string[]) => {
    // Write the headers to the CSV file
    const csvContent = headers.join(",") + "\n";

    // Create a Blob with the CSV content
    const blob = new Blob([csvContent], { type: "text/csv" });

    // Create a temporary anchor element
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = filename;

    // Append the anchor element to the body
    document.body.appendChild(a);

    // Click the anchor element to trigger the download
    a.click();

    // Remove the anchor element from the body
    document.body.removeChild(a);
  };

  const checkRequiredHeaders = (
    csvContent: string,
    requiredHeaders: string[]
  ): boolean => {
    // Parse the CSV content into an array of rows
    const rows: string[][] = csvContent
      .trim()
      .split("\n")
      .map((row) => row.split(","));

    // Extract headers from the first row
    const headers: string[] = rows[0];

    // Check if all required headers are present
    for (const header of requiredHeaders) {
      if (!headers.includes(header)) {
        return false;
      }
    }
    return true;
  };

  const generateEmptyCSV = () => {
    const filename = "example.csv";
    if (dataType == "") {
      dispatch(setDataTypeError("Please select data type."));
    } else {
      if (dataType == "CONTACT") {
        const headers = ["Email", "Phone", "Postal Address"]; // Specify your desired headers here
        createEmptyCSV(filename, headers);
      } else if (dataType == "USERID") {
        const headers = ["UserId"]; // Specify your desired headers here
        createEmptyCSV(filename, headers);
      } else if (dataType == "MAID") {
        const headers = ["MobileDeviceId"]; // Specify your desired headers here
        createEmptyCSV(filename, headers);
      }
    }
  };

  useEffect(() => {
    if (uploadedUrl != "" && uploadedUrl != undefined) {
      const lastSlashIndex = uploadedUrl.lastIndexOf("/");
      const startSlashIndex = uploadedUrl.lastIndexOf("?");
      // Use slice to extract the substring after the last slash

      const filename = uploadedUrl.slice(lastSlashIndex + 1, startSlashIndex);
      console.log(filename);
      setFileName(filename);
    }
  }, [uploadedUrl]);

  useEffect(() => {
    // getDetailsCampaign();
    console.log("advertiserName", advertisersList);
    if (advertiserName != null && advertiserName != "") {
      const data = advertisersList.filter((val: any) => {
        return val.advertiserId == advertiserIdCampaign;
      });
      if (data.length > 0) {
        console.log(data[0]);
        let val = {
          advertiserId: data[0].advertiserId,
          advertiserName: data[0].advertiserName,
          id: data[0].id,
          advertiserProfile: data[0].advertiserProfile,
          Country: data[0].Country,
          email: data[0].email,
        };

        setAdvertiserValue(val);
      }
    }

    console.log(advertisersList);
    if (uploadedUrl != "" && uploadedUrl != undefined) {
      const lastSlashIndex = uploadedUrl.lastIndexOf("/");
      // Use slice to extract the substring after the last slash
      const startSlashIndex = uploadedUrl.lastIndexOf("?");

      const filename = uploadedUrl.slice(lastSlashIndex + 1, startSlashIndex);
      setFileName(filename);
    }
  }, []);

  AWS.config.update({
    region: "ap-south-1",
    accessKeyId: "AKIA5VMVCATGUK75O2XE",
    secretAccessKey: "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",
  });

  const s3 = new AWS.S3();

  async function uploadToS3(
    file: File,
    bucketName: string,
    folderName: string,
    fileName: string
  ): Promise<string> {
    const params = {
      Bucket: bucketName,
      Key: `${folderName}/${fileName}`,
      Body: file,
    };

    return new Promise((resolve, reject) => {
      s3.putObject(params, async (err, data) => {
        if (err) {
          console.error("Error uploading to S3:", err);
          reject(err);
        } else {
          // console.log("Successfully uploaded to S3", data);

          // Get the URL for the uploaded file

          const url = s3.getSignedUrl("getObject", {
            Bucket: bucketName,
            Key: `${folderName}/${fileName}`,
          });

          resolve(url);
        }
      });
    });
  }

  const uploadFiles = (
    files: FileList,
    bucketName: string,
    folderName: string
  ) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      uploadToS3(file, bucketName, folderName, file.name)
        .then((res) => {
          console.log(`Uploaded ${res}`);
          // dispatch(setUploadedUrlError(""));
          dispatch(setUploadedUrl(res));
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let val = event.target.value;
    dispatch(setDataSourceError(""));
    dispatch(setDataSource(val));
  };

  const sendDataToParent = () => {
    let message = false;
    // console.log(campaignSubtype != null, campaignSubtype == "");
    if (campaignSubtype === "Retargeting") {
      if (dataSource != "SKIP") {
        if (adminView) {
          if (advertiserName != null && advertiserName == "") {
            setAdvertiserError("Please Select a Advertiser.");
          } else if (dataSource == "" || dataSourceError != "") {
            dispatch(setDataSourceError("Please select a data source"));
          } else if (dataType == "" || dataTypeError != "") {
            dispatch(setDataTypeError("Please select Data Type."));
          } else if (uploadedUrl == "" || uploadedUrlError != "") {
            // console.log("here",);
            dispatch(setUploadedUrlError("Please Upload the CSV or XML file."));
          }
          if (appPlatform != null && appPlatform == "") {
            setAppPlatformError("Please select a value.");
          } else if (appName == null || appName == "") {
            setAppLookupError("Please enter app name ");
          } else if (campaignName != null && campaignName == "") {
            setCampaignNameError("Please enter a campaign name.");
          } else {
            dispatch(setDataSourceError(""));
            dispatch(setSegmentNameError(""));
            dispatch(setDataTypeError(""));
            dispatch(setUploadedUrlError(""));
            setCampaignSubtypeError("");
            setAppPlatformError("");
            setCampaignNameError("");
            setAppLookupError("");
            message = true;
            if (onClick) {
              onClick(message);
            }
          }
        } else if (dataSource == "" || dataSourceError != "") {
          dispatch(setDataSourceError("Please select a data source"));
        } else if (dataType == "" || dataTypeError != "") {
          dispatch(setDataTypeError("Please select Data Type."));
        } else if (uploadedUrl == "" || uploadedUrlError != "") {
          // console.log("here",);
          dispatch(setUploadedUrlError("Please Upload the CSV or XML file."));
        }
        if (appPlatform != null && appPlatform == "") {
          setAppPlatformError("Please select a value.");
        } else if (appName == null || appName == "") {
          setAppLookupError("Please enter app name ");
        } else if (campaignName != null && campaignName == "") {
          setCampaignNameError("Please enter a campaign name.");
        } else {
          dispatch(setDataSourceError(""));
          dispatch(setSegmentNameError(""));
          dispatch(setDataTypeError(""));
          dispatch(setUploadedUrlError(""));
          setCampaignSubtypeError("");
          setAppPlatformError("");
          setCampaignNameError("");
          setAppLookupError("");
          message = true;
          if (onClick) {
            onClick(message);
          }
        }
      } else {
        if (adminView) {
          if (advertiserName != null && advertiserName == "") {
            setAdvertiserError("Please Select a Advertiser.");
          } else if (appPlatform != null && appPlatform == "") {
            setAppPlatformError("Please select a value.");
          } else if (appName == null || appName == "") {
            setAppLookupError("Please enter app name ");
          } else if (campaignName != null && campaignName == "") {
            setCampaignNameError("Please enter a campaign name.");
          } else {
            dispatch(setDataSourceError(""));
            dispatch(setSegmentNameError(""));
            dispatch(setDataTypeError(""));
            dispatch(setUploadedUrlError(""));
            setCampaignSubtypeError("");
            setAppPlatformError("");
            setCampaignNameError("");
            setAppLookupError("");
            message = true;
            if (onClick) {
              onClick(message);
            }
          }
        } else if (appPlatform != null && appPlatform == "") {
          setAppPlatformError("Please select a value.");
        } else if (appName == null || appName == "") {
          setAppLookupError("Please enter app name ");
        } else if (campaignName != null && campaignName == "") {
          setCampaignNameError("Please enter a campaign name.");
        } else {
          dispatch(setDataSourceError(""));
          dispatch(setSegmentNameError(""));
          dispatch(setDataTypeError(""));
          dispatch(setUploadedUrlError(""));
          setCampaignSubtypeError("");
          setAppPlatformError("");
          setCampaignNameError("");
          setAppLookupError("");
          message = true;
          if (onClick) {
            onClick(message);
          }
        }
      }
    } else if (campaignSubtype !== "Digital BillBoards") {
      if (adminView) {
        if (advertiserName != null && advertiserName == "") {
          setAdvertiserError("Please Select a Advertiser.");
        } else if (campaignSubtype != null && campaignSubtype == "") {
          setCampaignSubtypeError("Please select a value.");
        } else if (appPlatform != null && appPlatform == "") {
          setAppPlatformError("Please select a value.");
        } else if (appName == null || appName == "") {
          setAppLookupError("Please enter app name ");
        } else if (campaignName != null && campaignName == "") {
          setCampaignNameError("Please enter a campaign name.");
        } else {
          setCampaignSubtypeError("");
          setAppPlatformError("");
          setCampaignNameError("");
          setAppLookupError("");
          message = true;
          if (onClick) {
            onClick(message);
          }
        }
      } else if (campaignSubtype != null && campaignSubtype == "") {
        setCampaignSubtypeError("Please select a value.");
      } else if (appPlatform != null && appPlatform == "") {
        setAppPlatformError("Please select a value.");
      } else if (appName == null || appName == "") {
        setAppLookupError("Please enter app name ");
      } else if (campaignName != null && campaignName == "") {
        setCampaignNameError("Please enter a campaign name.");
      } else {
        setCampaignSubtypeError("");
        setAppPlatformError("");
        setCampaignNameError("");
        setAppLookupError("");
        message = true;
        if (onClick) {
          onClick(message);
        }
      }
    } else {
      if (adminView) {
        if (advertiserName != null && advertiserName == "") {
          setAdvertiserError("Please Select a Advertiser.");
        } else {
          message = true;
          if (onClick) {
            onClick(message);
          }
        }
      } else {
        message = true;
        if (onClick) {
          onClick(message);
        }
      }
      console.log("here");
    }
  };

  const onChangeLookUp = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setAppLookup(event.target.value);

    dispatch(setAppName(event.target.value));
    setAppLookupError("");
    localStorage.setItem("appLookup", event.target.value);
    localStorage.setItem("packageName", "");
    localStorage.setItem("appIcon", "");
  };

  const handleCheckboxClick = (label: string) => {
    // console.log(campaignSubtype)
    dispatch(setCampaignSubType(label));
    setCampaignSubtypeError("");
    localStorage.setItem("campaignSubtype", label);
  };

  const handleAppPlatform = (label: string) => {
    dispatch(setAppPlatform(label));
    setAppPlatformError("");
    localStorage.setItem("appPlatform", label);
  };

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    dispatch(setCampaignName(value));
    setCampaignNameError("");
    localStorage.setItem("campaignName", value);
  };

  useEffect(() => {
    //   console.log(campaignSubtype,
    // appPlatform,
    // appName,
    // campaignName,
    // appImageUrl,
    // packageName,)
    // const cs = localStorage.getItem("campaignSubtype") || "";
    // const apPlatform = localStorage.getItem("appPlatform") || "";
    // const name = localStorage.getItem("campaignName") || "";
    // const lookup = localStorage.getItem("appLookup") || "";
    // const item = localStorage.getItem("editingData") || "";
    // console.log(item == 'undefined');
    // if (item != "" && item != 'undefined') {
    //   const data = JSON.parse(item);
    //   console.log("CampaignInfo", data);
    //   // console.log(data.applicationName,data.campaignName)
    //   dispatch(setCampaignName(name == "" ? data.campaignName: name));
    //   // setAppLookup(lookup == "" ? data.applicationName : lookup);
    //   dispatch(setAppName(lookup == "" ? data.applicationName : lookup))
    //   setAppPlatform(apPlatform == "" ? data.appPlatform : apPlatform);
    //   setCampaignSubType(cs == "" ? data.subType :cs);
    //   localStorage.setItem("campaignName", name == "" ? data.campaignName: name);
    //   localStorage.setItem("appLookup", lookup == "" ? data.applicationName : lookup);
    //   localStorage.setItem("appPlatform", apPlatform == "" ? data.appPlatform : apPlatform);
    //   localStorage.setItem("campaignSubtype", cs == "" ? data.subType :cs);
    //   setAppPlatformDisabled(true);
    //   setCampaignDisabled(true);
    //   setAppLookupDisabled(false);
    // } else {
    // setAppLookup(lookup);
    // dispatch(setAppName(lookup))
    // dispatch(setCampaignSubType(cs));
    // dispatch(setAppPlatform(apPlatform));
    // dispatch(setCampaignName(name));
    // }
  }, []);

  const getAppDetails = async (appName: any) => {
    const searchUrl = `https://itunes.apple.com/search?term=${encodeURIComponent(
      appName
    )}&entity=macSoftware`;

    try {
      const response = await fetch(searchUrl);
      const data = await response.json();

      if (data.results && data.results.length > 0) {
        // console.log(data.results)
        // Assuming the first result is the most relevant one
        const appDetails = data.results[0];
        setAppArray(data.results);
        // console.log("App Details:", data.results);
        return appDetails;
      } else {
        console.error("No app found with the given name.");
        return null;
      }
    } catch (error) {
      console.error("Error fetching app details:", error);
      return null;
    }
  };

  const fetchPlayStoreAppDetails = async (appName: any, apiKey: any) => {
    let appPlatformName = appPlatform == "IOS" ? "ios" : "android";
    const apiUrl = `https://data.42matters.com/api/v2.0/${appPlatformName}/apps/search.json?q=${encodeURIComponent(
      appName
    )}&access_token=${apiKey}`;

    try {
      const response = await fetch(apiUrl);
      console.log(response);
      const data = await response.json();

      if (data.results && data.results.length > 0) {
        // Assuming the first result is the most relevant one
        const appDetails = data.results[0];
        // console.log(data.results);
        setAppArray(data.results);
        // console.log("Play Store App Details:", appDetails);
        return appDetails;
      } else {
        console.error("No app found with the given name.");
        return null;
      }
    } catch (error) {
      console.error("Error fetching app details:", error);
      return null;
    }
  };

  const [searchResults, setSearchResults] = useState<string[]>([]);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  // useEffect(() => {
  //   const apiKey = '3089b2ff5c46d91e4c2cc4e7389e3918490fdde6';
  //   // if(appPlatform == "Android"){
  //   //   fetchPlayStoreAppDetails(appLookup, apiKey)
  //   // } else {
  //   //   getAppDetails(appLookup)
  //   // }
  //   fetchPlayStoreAppDetails(appLookup, apiKey)
  // }, [appLookup])

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const [packageName, setPackage] = useState<any>("");

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearchButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    // console.log(appName);
    if (appName != "") {
      if (appPlatform != "") {
        const apiKey = "420597fd4417dc0b1a6d84c0f1fc4e0f3ac16e93";
        fetchPlayStoreAppDetails(appName, apiKey);

        setSearchResults(appArray);
        // setDropdownOpen(true);
        setAnchorEl(event.currentTarget);
      } else {
        setSearchResults([]);
        setErrorMsgSnackBar("Please select app's platform.");
        setErrorOpen(true);
      }
    } else {
      setSearchResults([]);
      setErrorMsgSnackBar("Please enter app name to seach.");
      setErrorOpen(true);
    }
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  const navigate = useNavigate();

  const createAdvertiser = () => {
    handleOpen();
    //    navigate('admin/createAdvertiser', {
    //     state: {
    //         typeOpen: "create"
    //     }
    // })
  };

  const modalStyle = useStylesModal();
  const handleSelectedValueChange = (
    event: React.ChangeEvent<{}>,
    newValue: any
  ) => {
    // console.log(
    //   "Selected Value:",
    //   newValue != null &&
    //     newValue.advertiserName != null &&
    //     newValue.advertiserName != ""
    // );
    setAdvertiserError("");
    setAdvertiserValue(newValue);
    if (
      newValue != null &&
      newValue.advertiserName != null &&
      newValue.advertiserName != ""
    ) {
      // localStorage.setItem("locationCode", countryCode);
      dispatch(setAdvertiserName(newValue.advertiserName));
      dispatch(setAdvertiserIdCampaign(newValue.advertiserId));
      localStorage.setItem("emailID", newValue.email);
    }
  };
  return (
    <div>
      {adminView && (
        <div className="advertiser-main">
          <div className="advertiser-box">
            <h1 className="campaign-subtype">Advertiser Name</h1>
            <Autocomplete
              classes={{ root: classes.root }}
              options={advertisersList}
              getOptionLabel={(options) => options.advertiserName}
              value={advertiserValue}
              onChange={handleSelectedValueChange}
              isOptionEqualToValue={(options: any, value: any) =>
                options.advertiserId === value.advertiserId
              }
              renderOption={(props, option) => (
                <Box
                  component="li"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2,1fr)",
                  }}
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <div>
                    <img
                      loading="lazy"
                      width="20"
                      srcSet={option.advertiserProfile}
                      src={option.advertiserProfile}
                      alt=""
                    />
                    {option.advertiserName}
                  </div>
                  {/* <div>
                    <img
                      loading="lazy"
                      width="20"
                      srcSet={option.advertiserProfile}
                      src={option.advertiserProfile}
                      alt=""
                    />
                    {option.advertiserName}
                  </div> */}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
              // {...props}
            />
            {advertiserError != "" ? (
              <div className="error-box" style={{ margin: 0 }}>
                <BiSolidErrorCircle className="error-icon" />
                <p>{advertiserError}</p>
              </div>
            ) : null}
          </div>
          <div className="horizontal-line"></div>
          <div className="create-advertiser">
            <PurpleCustomButton
              disable={true}
              onClick={createAdvertiser}
              children={<div className="button-div">Create Advertiser</div>}
            />
            <h6>
              Create new advertiser first to create a campaign, if you don't
              have any.
            </h6>
          </div>
          <Modal
            classes={{ root: modalStyle.root }}
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <CreateAdvertiser modalView={true} />
            </Box>
          </Modal>
        </div>
      )}
      <div className="campaign-box">
        <h1 className="campaign-subtype">Select a Campaign Subtype</h1>
        <div className="checkbox-container">
          <div className="checkbox-app" style={{ alignItems: "flex-start" }}>
            <CustomCheck
              {...label}
              className="chk"
              checked={campaignSubtype === "App Install"}
              onChange={() => handleCheckboxClick("App Install")}
              style={{ color: customCheckBoxColor }}
            />
            <div className="para-container">
              <p className="main-para">App Install</p>
              <p className="light-para">Get new people to install your app</p>
            </div>
          </div>

          <div className="checkbox-app" style={{ alignItems: "flex-start" }}>
            <CustomCheck
              {...label}
              className="chk"
              checked={campaignSubtype === "App Engagement"}
              onChange={() => handleCheckboxClick("App Engagement")}
              style={{ color: customCheckBoxColor }}
            />
            <div className="para-container">
              <p className="main-para">APP Engagement</p>
              <p className="light-para">
                Get users to take action in your app. Minimum 250 k install to
                run
              </p>
            </div>
          </div>

          <div className="checkbox-app" style={{ alignItems: "flex-start" }}>
            <CustomCheck
              {...label}
              className="chk"
              checked={campaignSubtype === "Digital BillBoards"}
              onChange={() => handleCheckboxClick("Digital BillBoards")}
              style={{ color: customCheckBoxColor }}
            />
            <div className="para-container">
              <p className="main-para">Digital BillBoards</p>
              <p className="light-para">
                Get users to take action in your app. Minimum 250 k install to
                run
              </p>
            </div>
          </div>

          <div className="checkbox-app" style={{ alignItems: "flex-start" }}>
            <CustomCheck
              {...label}
              className="chk"
              checked={campaignSubtype === "Retargeting"}
              onChange={() => handleCheckboxClick("Retargeting")}
              // disabled={true}
              style={{ color: customCheckBoxColor }}
            />
            <div className="para-container">
              <p className="main-para">Retargeting</p>
              <p className="light-para">Get retargeting users</p>
            </div>
          </div>
        </div>

        <div className="data-source-align">
          {campaignSubtype == "Retargeting" && (
            <div className="data-div">
              <h3>Data Source</h3>
              <div className="radioGrp-div">
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={dataSource}
                    onChange={handleChange}
                  >
                    {/* <FormControlLabel
                    value="EXTERNAL"
                    control={
                      <Radio
                        sx={{
                          color: "#6900b8",
                          "&.Mui-checked": {
                            color: "#6900b8",
                          },
                        }}
                      />
                    }
                    label={
                      <span className="heading-label">
                        Connect a new Data Source
                      </span>
                    }
                    //label= "Connect a new Data Source"
                  /> */}
                    <FormControlLabel
                      value="UPLOAD"
                      control={
                        <Radio
                          sx={{
                            color: "#6900b8",
                            "&.Mui-checked": {
                              color: "#6900b8",
                            },
                          }}
                        />
                      }
                      label={
                        <span className="heading-label">
                          Upload a file manually
                        </span>
                      }
                      // label="Upload a file manually"
                    />
                    <FormControlLabel
                      value="SKIP"
                      control={
                        <Radio
                          sx={{
                            color: "#6900b8",
                            "&.Mui-checked": {
                              color: "#6900b8",
                            },
                          }}
                        />
                      }
                      label={
                        <span className="heading-label">
                          Skip this step and set up a data source later
                        </span>
                      }
                      // label="Skip this step and set up a data source later"
                    />
                  </RadioGroup>
                </FormControl>
                {dataSourceError != "" && dataSourceError != undefined ? (
                  <div
                    className="error-box"
                    style={{ width: "50%", margin: 0 }}
                  >
                    <BiSolidErrorCircle className="error-icon" />
                    <p>{dataSourceError}</p>
                  </div>
                ) : null}
              </div>
            </div>
          )}
          {dataSource == "UPLOAD" && campaignSubtype == "Retargeting" && (
            <div className="data-div">
              <h3>Steps to follow :-</h3>
              <div className="radioGrp-div">
                <ol className="steps-list" style={{ margin: "12px 0" }}>
                  <li style={{ marginTop: 0 }}>Enter Segment Name</li>
                  <li>Choose any of one option for Data Type.</li>
                  <li>Download Template File.</li>
                  <li>Add Your Data In To Template File</li>
                  <li>Upload Updated File.</li>
                </ol>
              </div>
            </div>
          )}
          {dataSource == "UPLOAD" && campaignSubtype == "Retargeting" && (
            <div className="data-div">
              <h3>Segment name</h3>
              <div className="radioGrp-div">
                <Box
                  sx={{
                    width: 600,
                    maxWidth: "100%",
                    borderStyle: "none",
                  }}
                >
                  <TextField
                    placeholder="Audience segment name"
                    sx={{
                      "&.MuiTextField-root input": {
                        height: 40,
                        paddingLeft: 1.5,
                        paddingTop: 0,
                        paddingBottom: 0,
                        border: "none",
                        outline: "none",
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6900b8",
                        },
                      },
                    }}
                    fullWidth
                    onChange={(e) => {
                      dispatch(setSegmentNameError(""));
                      dispatch(setSegmentName(e.target.value));
                    }}
                    // label="input"
                    value={segmentName}
                    InputLabelProps={{ shrink: false }}
                    id="fullWidth"
                  />
                </Box>
                {segmentNameError != "" && segmentNameError != undefined ? (
                  <div
                    className="error-box"
                    style={{ width: "50%", margin: 0 }}
                  >
                    <BiSolidErrorCircle className="error-icon" />
                    <p>{segmentNameError}</p>
                  </div>
                ) : null}
              </div>
            </div>
          )}
          {dataSource == "UPLOAD" && campaignSubtype == "Retargeting" && (
            <div className="data-div">
              <h3>Data Type</h3>
              <div className="radioGrp-div">
                <h5>Select the data type that you'd like to upload</h5>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={dataType}
                    onChange={(event) => {
                      dispatch(setDataTypeError(""));
                      dispatch(setDataType(event.target.value));
                    }}
                  >
                    <FormControlLabel
                      value="CONTACT"
                      control={
                        <Radio
                          sx={{
                            color: "#6900b8",
                            "&.Mui-checked": {
                              color: "#6900b8",
                            },
                          }}
                        />
                      }
                      label={
                        <span className="heading-label">
                          Upload Emails, Phones and/or Postal Address
                        </span>
                      }
                      //label= "Connect a new Data Source"
                    />
                    <FormControlLabel
                      value="USERID"
                      control={
                        <Radio
                          sx={{
                            color: "#6900b8",
                            "&.Mui-checked": {
                              color: "#6900b8",
                            },
                          }}
                        />
                      }
                      label={
                        <span className="heading-label">Upload User IDs</span>
                      }
                      // label="Upload a file manually"
                    />
                    <FormControlLabel
                      value="MAID"
                      control={
                        <Radio
                          sx={{
                            color: "#6900b8",
                            "&.Mui-checked": {
                              color: "#6900b8",
                            },
                          }}
                        />
                      }
                      label={
                        <span className="heading-label">
                          Upload Mobile Device IDs
                        </span>
                      }
                      // label="Skip this step and set up a data source later"
                    />
                  </RadioGroup>
                </FormControl>
                {dataTypeError != "" && dataTypeError != undefined ? (
                  <div
                    className="error-box"
                    style={{ width: "50%", margin: 0 }}
                  >
                    <BiSolidErrorCircle className="error-icon" />
                    <p>{dataTypeError}</p>
                  </div>
                ) : null}
              </div>
            </div>
          )}

          {dataSource == "UPLOAD" && campaignSubtype == "Retargeting" && (
            <div className="data-div">
              <h3>Data to upload</h3>
              <div className="radioGrp-div">
                <h5>
                  Upload your CSV OR XML ( or use a{" "}
                  <a onClick={generateEmptyCSV}>template</a> ) compiled with the{" "}
                  <a>formatting guideline </a>{" "}
                </h5>
                <div className="outer-box">
                  <Dropzone
                    // accept={['text/csv']}
                    onDrop={onDrop}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          {uploadedUrl != "" && uploadedUrl != undefined ? (
                            <p style={{ fontSize: "12px", margin: 0 }}>
                              {fileName}
                            </p>
                          ) : (
                            <p style={{ fontSize: "12px", margin: 0 }}>
                              Drop here or{" "}
                              <span
                                style={{
                                  fontWeight: 800,
                                  color: "#6900b8",
                                  cursor: "pointer",
                                }}
                              >
                                Browse
                              </span>
                            </p>
                          )}
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
                {uploadedUrlError != "" && uploadedUrlError != undefined ? (
                  <div
                    className="error-box"
                    style={{ width: "50%", margin: 0 }}
                  >
                    <BiSolidErrorCircle className="error-icon" />
                    <p>{uploadedUrlError}</p>
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </div>
        {/* <div className="checkbox-app" style={{ alignItems: 'flex-start' }}>
          <CustomCheck
            {...label}
            className="chk"
            checked={campaignSubtype === "App Pre-registeration"}
            onChange={() => handleCheckboxClick("App Pre-registeration")}
            
            style={{ color: customCheckBoxColor }}                           
          />
          <div className="para-container">
            <p className="main-para">App Pre-registration (Android only)</p>
            <p className="light-para">Get new people to install your app</p>
          </div>
        </div> */}
        {campaignSubtypeError != "" ? (
          <div className="error-box" style={{ width: "50%", margin: 0 }}>
            <BiSolidErrorCircle className="error-icon" />
            <p>{campaignSubtypeError}</p>
          </div>
        ) : null}
        {/* asdfasdfjasdfaskldfaslkdfjlkasfjasasdfasdfjasdfaskldfaslkdfjlkasfjasasdfasdfjasdfaskldfaslkdfjlkasfjas */}

        {campaignSubtype !== "Digital BillBoards" && (
          <div>
            <div className="divider-line"></div>
            <h1 className="campaign-subtype">
              Select your mobile app’s platform
            </h1>
            <div className="checkbox-container">
              <div className="checkbox-app">
                <Checkbox
                  {...label}
                  className="chk"
                  checked={appPlatform === "Android"}
                  onChange={() => handleAppPlatform("Android")}
                  style={{ color: customCheckBoxColor }}
                  disabled={appLookupDisabled}
                />
                <div className="para-container">
                  <p className="main-para">Android</p>
                  {/* <p className="light-para">Get new people to install your app</p> */}
                </div>
              </div>

              <div className="checkbox-app">
                <Checkbox
                  {...label}
                  className="chk"
                  checked={appPlatform === "IOS"}
                  onChange={() => handleAppPlatform("IOS")}
                  style={{ color: customCheckBoxColor }}
                  disabled={appLookupDisabled}
                />
                <div className="para-container">
                  <p className="main-para">IOS</p>
                  {/* <p className="light-para">
              Get users to take action in your app. Minimum 250 k
              install to run
            </p> */}
                </div>
              </div>

              <div className="checkbox-app">
                <Checkbox
                  {...label}
                  className="chk"
                  checked={appPlatform === "APK"}
                  onChange={() => handleAppPlatform("APK")}
                  style={{ color: customCheckBoxColor }}
                  disabled={appLookupDisabled}
                />
                <div className="para-container">
                  <p className="main-para">APK</p>
                  {/* <p className="light-para">Get new people to install your app</p> */}
                </div>
              </div>
            </div>
            {appPlatformError != "" ? (
              <div className="error-box" style={{ width: "50%", margin: 0 }}>
                <BiSolidErrorCircle className="error-icon" />
                <p>{appPlatformError}</p>
              </div>
            ) : null}
            <div className="divider-line"></div>
            <div className="">
              <h4 className="purple-heading">Look up your App</h4>
              <div className="app-look-box">
                <div className="search-icon-box">
                  <AiOutlineSearch className="search-icon" />
                  {/* <input
              className="search-input"
              type="text"
              placeholder="Enter the app name, package name, publisher, or Play Store URL"
              value={appLookup}
              onChange={onChangeLookUp}
              disabled={appLookupDisabled}
            /> */}
                  <CustomTextField
                    value={appName}
                    placeholder="Enter the app name, package name, publisher, or Play Store URL"
                    onChange={onChangeLookUp}
                    // {...params}
                    // label="Search input"
                    InputProps={
                      {
                        // ...params.InputProps,
                        // type: 'search',
                      }
                    }
                  />

                  <button
                    onClick={handleSearchButtonClick}
                    className="app-search-button"
                  >
                    Search
                  </button>
                </div>
              </div>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {appArray.map((option: any) => (
                  <MenuItem
                    key={option}
                    onClick={() => {
                      // setAppLookup(option.title);
                      // setPackage(option.package_name);
                      dispatch(
                        setAppName(
                          appPlatform == "Android"
                            ? option.title
                            : option.trackCensoredName
                        )
                      );
                      dispatch(
                        setPackageName(
                          appPlatform == "Android"
                            ? option.package_name
                            : option.bundleId
                        )
                      );
                      dispatch(
                        setAppImageUrl(
                          appPlatform == "Android"
                            ? option.icon
                            : option.artworkUrl100
                        )
                      );

                      setDropdownOpen(false);
                      setAnchorEl(null);
                      localStorage.setItem(
                        "appLookup",
                        appPlatform == "Android"
                          ? option.title
                          : option.trackCensoredName
                      );
                      localStorage.setItem(
                        "packageName",
                        appPlatform == "Android"
                          ? option.package_name
                          : option.bundleId
                      );
                      localStorage.setItem(
                        "appIcon",
                        appPlatform == "Android"
                          ? option.icon
                          : option.artworkUrl100
                      );
                    }}
                  >
                    <div className="app-lookup-dropdown">
                      <img
                        style={{
                          marginRight: "10px",
                          width: "20px",
                          height: "20px",
                        }}
                        // src={appPlatform == "Android" ? option.icon : option.artworkUrl100}
                        // alt={appPlatform == "Android" ? option.title : option.trackName}
                        src={
                          appPlatform == "Android"
                            ? option.icon
                            : option.artworkUrl100
                        }
                      />
                      <p
                        key={
                          appPlatform == "Android"
                            ? option.title
                            : option.trackCensoredName
                        }
                      >
                        {appPlatform == "Android"
                          ? option.title
                          : option.trackCensoredName}
                      </p>
                    </div>
                  </MenuItem>
                ))}
              </Menu>
            </div>
            {appLookupError != "" ? (
              <div className="error-box" style={{ width: "50%", margin: 0 }}>
                <BiSolidErrorCircle className="error-icon" />
                <p>{appLookupError}</p>
              </div>
            ) : null}
            <div className="divider-line"></div>
            <h1 className="campaign-subtype">Campaign Name</h1>
            <div className="campaign-name-box">
              <p>Enter Campaign Name</p>
              <input
                type="text"
                value={campaignName}
                onChange={onChangeName}
                disabled={campaignDisabled}
              />
            </div>
            {campaignNameError != "" ? (
              <div className="error-box" style={{ width: "50%", margin: 0 }}>
                <BiSolidErrorCircle className="error-icon" />
                <p>{campaignNameError}</p>
              </div>
            ) : null}
          </div>
        )}
      </div>
      <div
        className="doneButton-box"
        style={{
          float: "right",
        }}
      >
        {/* <button className="border-btn" onClick={() => window.location.replace("/home/campaigns")}>
          Cancel
        </button> */}
        <button className="purple-btn width-adjust" onClick={sendDataToParent}>
          Next
        </button>
      </div>

      <Snackbar
        open={errorOpen}
        autoHideDuration={6000}
        onClose={() => setErrorOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setErrorOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMsgSnackBar}
        </Alert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  campaignSubtype: state.user.campaignSubtype,
  appPlatform: state.user.appPlatform,
  appName: state.user.appName,
  campaignName: state.user.campaignName,
  appImageUrl: state.user.appImageUrl,
  packageName: state.user.packageName,

  dataSource: state.user.dataSource,
  segmentName: state.user.segmentName,
  dataType: state.user.dataType,
  dataUpload: state.user.dataUpload,
  uploadedUrl: state.user.uploadedUrl,

  dataSourceError: state.user.dataSourceError,
  segmentNameError: state.user.segmentNameError,
  dataTypeError: state.user.dataTypeError,
  dataUploadError: state.user.dataUploadError,
  uploadedUrlError: state.user.uploadedUrlError,
  advertiserId: state.user.advertiserId,
  sessionId: state.user.sessionId,
  authToken: state.user.authToken,

  advertiserName: state.user.advertiserName,
  advertiserIdCampaign: state.user.advertiserIdCampaign,
  advertisersList: state.user.advertisersList,
});

const mapDispatchToProps = {
  setCampaignSubType,
  setAppPlatform,
  setAppName,
  setCampaignName,
  setAppImageUrl,
  setPackageName,

  setDataSource,
  setSegmentName,
  setDataType,
  setDataUpload,
  setUploadedUrl,

  setDataSourceError,
  setSegmentNameError,
  setDataTypeError,
  setDataUploadError,
  setUploadedUrlError,
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignInfo);
