import React, { FC } from "react";
import BillingScreen from "../pages/BillingScreen";
import DashboardScreen from "../pages/DashboardScreen";
import ReportScreen from "../pages/ReportScreen";
import SettingsScreen from "../pages/Settings";
import CampaignMainScreen from "../pages/CampaignMainScreen";
import PaymentSuccessScreen from "../addFunds/AddFunds";
import CampaignRecord from "../pages/CampaignRecord";
import AddFundsScreen from "../addFunds/AddFunds";
import EditCampaign from "../pages/EditCampaign";
import CreateCampaign from "../pages/CreateCampaign";
import HelpCentreScreen from "../pages/Help";

export const Dashboard: FC = () => {
    return <DashboardScreen />;
}

export const Campaigns: FC = () => {
    return <CampaignMainScreen shouldHideDiv={false} heading="All Campaigns"/>
}

export const CampRecord: FC = () => {
    return <CampaignRecord />
}

export const EditCamp: FC = () =>{
    return <EditCampaign/>
} 

export const CreateCamp: FC = () =>{
    return <CreateCampaign/>
}

export const Report: FC = () => {
    return <ReportScreen />;
}

export const Billing: FC = () => {
    return <BillingScreen shouldHideDiv={false} subHeading=""/>;
}

export const Settings: FC = () => {
    return <SettingsScreen />;
}

export const AddFunds: FC = () => {
    return <AddFundsScreen />;
}

export const HelpCentre: FC = () => {
    return <HelpCentreScreen />;
}

export const PaymentSucess: FC = () => {
    return <PaymentSuccessScreen />;
}

