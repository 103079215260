import {
  SET_USERNAME,
  CAMPAIGN_SUBTYPE,
  APP_PLATFORM,
  APP_NAME,
  PACKAGE_NAME,
  APP_IMAGE_URL,
  CAMPAIGN_NAME,
  LOCATION,
  LANGUAGE,
  TRACKING,
  TRACKING_URL,
  IMPRESSION_URL,
  TOKEN_EVENT,
  ADD_TOKEN_EVENT_OBJECT,
  REMOVE_TOKEN_EVENT_OBJECT,
  UPDATE_TOKEN,
  UPDATE_EVENT,
  BUDGET,
  TARGET,
  FOCUS,
  COST_PER_INSTALL,
  CURRENCY_SYMBOL,
  UserActionTypes,
  ADVERTISER_ID,
  SESSION_ID,
  AUTH_TOKEN,
  USER_EMAIL,
  COUNTRY_CODE,
  MASTER_COUNTRY_ARRAY,
  MASTER_LANGUAGE_ARRAY,
  MASTER_COUPON_ARRAY,
  MASTER_MMP_ARRAY,
  MASTER_PUBLISHER_ARRAY,
  MASTER_TARGETING_TYPES_ARRAY,
  MASTER_MACROS_ARRAY,
  REMEMBER_ME_CHECK,
  REMEMBER_ME_EMAIL,
  REMEMBER_ME_PASSWORD,
  TOTAL_BALANCE,
  AD_GROUP_NAME,
  LOGO_URL,
  HEADLINE,
  DESCRIPTION,
  IMAGE_URL,
  VIDEO_URL,
  HTML_URL,
  LOCATION_CODE,
  CAMPAIGN_ID,
  BUDGET_ID,
  IMAGE_FILE,
  HTML_FILE,
  VIDEO_FILE,
  STATUS,
  IMAGE_URL_ERROR,
  LOGO_URL_ERROR,
  LOCATION_CHECK,
  RESET_STATE,
  DATA_SOURCE,
  SEGMENT_NAME,
  DATA_TYPE,
  DATA_UPLOAD,
  UPLOADED_DATA_URL,
  DATA_SOURCE_ERROR,
  SEGMENT_NAME_ERROR,
  DATA_TYPE_ERROR,
  DATA_UPLOAD_ERROR,
  UPLOADED_DATA_URL_ERROR,
  TRANSACTION_CURRENCY,
  SELECTED_ADVERTISER_ID,
  SELECTED_DATE_RANGE,
  COUNTRY_CODE_ADVERTISER,
  COMPANY_NAME,
  CAMPAIGN_STATUS,
  ADVERTISER_ID_ADMIN,
  CLICKS,
  IMPRESSIONS,
  CONVERSION,
  INSTALL,
  PAYOUT,
  CTR,
  CPI,
  DAILY_AVG_BUDGET,
  ADVERTISER_NAME,
  COMPANY_IMAGE,
  USER_TYPE,
  ADVERTISERS_LIST,
  ADVERTISERS_SCREEN_TABLE,
  REPORT_SCREEN_TABLE,
  CREDIT_LIMIT,
  TOTAL_SPENDS_LAST_MONTH,
  LAST_BILLED_AMOUNT,
  CREATED_AT,
  ACTIVATED_AT,
  CAMPAIGN_TABLE_DATA,
  CROPPED_ID,
  USER_JOURNEY_ADVERTISER,
  USER_JOURNEY_DATA,
  VIDEO_ARRAY_DB,
  VIDEO_DURATION_DB,
  VIDEO_NAME_DB,
  VIDEO_SOURCE_DB,
  IMAGE_ARRAY_DB,
  IMAGE_LIST_DB,
  HEADLINE_ERROR,
  DESCRIPTION_ERROR,
  TEMP_TRANSACTION_CURRENCY,
  PROTECTED_DATA,
} from "../Actions/userActions";
// userReducer.ts

export interface UserState {
  descriptionError: string;
  headlineError: string;
  videoSource:any;
  videoArray: any;
  videoDuration: number;
  videoName: string;
  imageList:File[];
  imageArray:any[];

  userJourneyAd: any;
  userJourneyData: any;
  croppedId: any;
  tableData: any;
  protectedData:any;
  companyImage: string;
  advertiserName: string;

  campaignStatus: string;
  // campaignStatus: string;
  companyName: string;
  username: string;
  advertiserIdCampaign: string;
  campaignSubtype: string;
  appPlatform: string;
  appName: string;
  campaignName: string;
  appImageUrl: string;
  packageName: string;

  location: string;
  language: string[];
  tracking: string;
  trackingUrl: string;
  impressionUrl: string;
  tokenEvent: {
    token: string;
    eventName: string;
  }[];

  logoError: string;
  imageUploadError: string;

  budget: any;
  focus: string;
  target: string;
  costPerInstall: string;
  currencySymbol: any;
  authToken: any;
  advertiserId: any;
  sessionId: any;
  userEmail: any;
  countryCode: any;
  masterCountryArray: any;
  masterMMPArray: any;
  masterLanguageArray: any;
  masterMacrosArray: any;
  masterPublisherArray: any;
  mastertargetingTypesArray: any;
  mastercouponArray: any;
  rememberMeCheck: any;
  rememberMeEmail: any;
  rememberMePassword: any;
  totalBalance: any;
  adGroupName: string;
  logoUrl: string;
  headline: string[];
  description: string[];
  imageUrl: string[];
  videoUrl: string[];
  htmlUrl: string[];
  locationCode: string;

  campaign_Id: string;
  budget_Id: string;
  videoFile: File[];
  htmlFile: File[];
  imageFile: string;
  status: string;
  locationCheck: string;

  dataSource: string;
  segmentName: string;
  dataType: string;
  dataUpload: File[];
  uploadedUrl: string;

  dataSourceError: string;
  segmentNameError: string;
  dataTypeError: string;
  dataUploadError: File[];
  uploadedUrlError: string;
  transactionCurrency: string;
  tempTransactionCurrency:string;
  selectedAdvertiserId: string;
  selectedDateRange: object;
  countryCodeAdvertiser: string;

  clicks: string;
  impression: string;
  conversion: string;
  install: string;
  payout: string;
  ctr: string;
  cpi: string;
  dailyAvgBudget: string;
  usertype: string;
  advertisersList: any[];
  creditLimit: number;

  totalSpendsLastMonth: number;
  lastBilledAmount: number;
  createdDate: string;
  activatedDate: string;
  advertiserScreenTable: any[];
  reportScreenTable: any[];
}

const initialUserState: UserState = {
  descriptionError: "",
  headlineError: "",
  videoSource:"",
  videoArray: [],
  videoDuration: 0,
  videoName: "",
  imageList:[],
  imageArray:[],

  userJourneyAd: "",
  userJourneyData: [],

  croppedId: "",
  tableData: [],
  protectedData: [],
  createdDate: "",
  activatedDate: "",

  totalSpendsLastMonth: 0,
  lastBilledAmount: 0,
  companyImage: "",

  creditLimit: 0,
  advertiserName: "",
  clicks: "",
  impression: "",
  conversion: "",
  install: "",
  payout: "",
  ctr: "",
  cpi: "",
  dailyAvgBudget: "",

  advertiserIdCampaign: "",
  campaignStatus: "",
  usertype: "",
  // campaignStatus: "",
  companyName: "",
  username: "",
  campaignSubtype: "",
  appPlatform: "",
  appName: "",
  campaignName: "",
  appImageUrl: "",
  packageName: "",
  location: "",
  language: [],
  tracking: "",
  trackingUrl: "",
  impressionUrl: "",
  tokenEvent: [
    {
      token: "",
      eventName: "",
    },
  ],

  budget: "",
  focus: "",
  target: "",
  costPerInstall: "",
  currencySymbol: "",
  authToken: "",
  advertiserId: "",
  sessionId: "",
  userEmail: "",
  countryCode: "",
  masterCountryArray: [],
  masterLanguageArray: [],
  masterMacrosArray: [],
  mastercouponArray: [],
  masterMMPArray: [],
  masterPublisherArray: [],
  mastertargetingTypesArray: [],
  rememberMeCheck: "false",
  rememberMeEmail: "",
  rememberMePassword: "",
  totalBalance: "",
  adGroupName: "",
  logoUrl: "",
  headline: [""],
  description: [""],
  imageUrl: [],
  videoUrl: [],
  htmlUrl: [],
  locationCode: "",
  campaign_Id: "",
  budget_Id: "",
  imageFile: "",
  htmlFile: [],
  videoFile: [],
  status: "pending",
  imageUploadError: "",
  logoError: "",
  locationCheck: "",

  dataSource: "",
  segmentName: "",
  dataType: "",
  dataUpload: [],
  uploadedUrl: "",

  dataSourceError: "",
  segmentNameError: "",
  dataTypeError: "",
  dataUploadError: [],
  uploadedUrlError: "",
  transactionCurrency: "",
  tempTransactionCurrency:"",
  selectedAdvertiserId: "",
  selectedDateRange: {
    startDate: new Date(new Date().getFullYear(), 0, 1),
    endDate: new Date(),
  },
  countryCodeAdvertiser: "",
  advertisersList: [],
  advertiserScreenTable: [],
  reportScreenTable: [],
};

const userReducer = (
  state = initialUserState,
  action: UserActionTypes
): UserState => {
  // console.log(action)
  switch (action.type) {
    case RESET_STATE:
      return initialUserState;
    case HEADLINE_ERROR:
      return {...state, headlineError: action.payload};
    case DESCRIPTION_ERROR:
      return {...state, descriptionError: action.payload};
    case VIDEO_SOURCE_DB:
      return {...state, videoSource:action.payload};
    case VIDEO_ARRAY_DB:
      return  {...state, videoArray:action.payload};
    case VIDEO_DURATION_DB:
      return {...state, videoDuration:action.payload};
    case VIDEO_NAME_DB:
      return {...state, videoName:action.payload};
    case IMAGE_ARRAY_DB:
      return {...state, imageArray:action.payload};
    case IMAGE_LIST_DB:
      return {...state,  imageList:action.payload};     
    case USER_JOURNEY_ADVERTISER:
      return { ...state, userJourneyAd: action.payload };
    case USER_JOURNEY_DATA:
      return { ...state, userJourneyData: action.payload };
    case CROPPED_ID:
      return { ...state, croppedId: action.payload };
    case CAMPAIGN_TABLE_DATA:
      return { ...state, tableData: action.payload };
    case PROTECTED_DATA:
      return { ...state, protectedData: action.payload };
    case CREATED_AT:
      return { ...state, createdDate: action.payload };
    case ACTIVATED_AT:
      return { ...state, activatedDate: action.payload };
    case TOTAL_SPENDS_LAST_MONTH:
      return { ...state, totalSpendsLastMonth: action.payload };
    case LAST_BILLED_AMOUNT:
      return { ...state, lastBilledAmount: action.payload };
    case CREDIT_LIMIT:
      return { ...state, creditLimit: action.payload };
    case COMPANY_IMAGE:
      return { ...state, companyImage: action.payload };
    case ADVERTISER_NAME:
      return { ...state, advertiserName: action.payload };
    case CLICKS:
      return { ...state, clicks: action.payload };
    case IMPRESSIONS:
      return { ...state, impression: action.payload };
    case CONVERSION:
      return { ...state, conversion: action.payload };
    case INSTALL:
      return { ...state, install: action.payload };
    case PAYOUT:
      return { ...state, payout: action.payload };
    case CTR:
      return { ...state, ctr: action.payload };
    case CPI:
      return { ...state, cpi: action.payload };
    case DAILY_AVG_BUDGET:
      return { ...state, dailyAvgBudget: action.payload };
    case ADVERTISER_ID_ADMIN:
      return { ...state, advertiserIdCampaign: action.payload };
    case CAMPAIGN_STATUS:
      return { ...state, campaignStatus: action.payload };
    case COMPANY_NAME:
      return { ...state, companyName: action.payload };
    case COUNTRY_CODE_ADVERTISER:
      return { ...state, countryCodeAdvertiser: action.payload };
    case SET_USERNAME:
      return { ...state, username: action.payload };
    case CAMPAIGN_SUBTYPE:
      // console.log("subtype",{ ...state, campaignSubtype: action.payload })
      return { ...state, campaignSubtype: action.payload };
    case APP_PLATFORM:
      return { ...state, appPlatform: action.payload };
    case APP_NAME:
      return { ...state, appName: action.payload };
    case CAMPAIGN_NAME:
      return { ...state, campaignName: action.payload };
    case PACKAGE_NAME:
      return { ...state, packageName: action.payload };
    case APP_IMAGE_URL:
      return { ...state, appImageUrl: action.payload };
    case LOCATION:
      return { ...state, location: action.payload };
    case LANGUAGE:
      // Check if the payload is a string or an array of strings
      if (typeof action.payload === "string") {
        return { ...state, language: [action.payload] }; // Wrap the string in an array
      } else if (Array.isArray(action.payload)) {
        // Handle the case where it's an array (replace the array)
        const stringsOnly = action.payload.filter(
          (item) => typeof item === "string"
        );
        return { ...state, language: stringsOnly };
      }
      // Handle other cases if needed
      return { ...state, language: action.payload };
    case TRACKING:
      return { ...state, tracking: action.payload };
    case TRACKING_URL:
      return { ...state, trackingUrl: action.payload };
    case IMPRESSION_URL:
      return { ...state, impressionUrl: action.payload };
    case TOKEN_EVENT:
    if (Array.isArray(action.payload)) {
      return { ...state, tokenEvent: action.payload };
    } else {
    return { ...state, tokenEvent: action.payload };
    }
    case ADD_TOKEN_EVENT_OBJECT:
      return {
        ...state,
        tokenEvent: [...state.tokenEvent, { token: "", eventName: "" }],
      };
    case REMOVE_TOKEN_EVENT_OBJECT:
      const updatedData = [...state.tokenEvent];
      updatedData.splice(action.payload, 1);
      return {
        ...state,
        tokenEvent: updatedData,
      };
    case UPDATE_TOKEN:
      if (
        state.tokenEvent &&
        Array.isArray(state.tokenEvent) &&
        state.tokenEvent.length > action.payload.index
      ) {
        const updatedTokenData = [...state.tokenEvent];
        updatedTokenData[action.payload.index] = {
          ...updatedTokenData[action.payload.index],
          token: action.payload.token,
        };
        return {
          ...state,
          tokenEvent: updatedTokenData,
        };
      } else {
        // console.error("Invalid state or action payload:", state, action);
        return state; // Return the current state unchanged
      }
    case UPDATE_EVENT:
      const updatedEventNameData = [...state.tokenEvent];
      if (updatedEventNameData[action.payload.index]) {
        updatedEventNameData[action.payload.index] = {
          ...updatedEventNameData[action.payload.index],
          eventName: action.payload.event,
        };
        return {
          ...state,
          tokenEvent: updatedEventNameData,
        };
      } else {
        // console.error("Invalid index or tokenEvent is not properly initialized.");
        return state; // Return the current state unchanged
      }
    case BUDGET:
      return { ...state, budget: action.payload };
    case FOCUS:
      return { ...state, focus: action.payload };
    case TARGET:
      return { ...state, target: action.payload };
    case AD_GROUP_NAME:
      return { ...state, adGroupName: action.payload };
    case LOGO_URL:
      return { ...state, logoUrl: action.payload };
    case CAMPAIGN_ID:
      return { ...state, campaign_Id: action.payload };
    case BUDGET_ID:
      return { ...state, budget_Id: action.payload };
    case HEADLINE:
      // Check if the payload is a string or an array of strings
      if (typeof action.payload === "string") {
        return { ...state, headline: [action.payload] }; // Wrap the string in an array
      } else if (Array.isArray(action.payload)) {
        // Handle the case where it's an array (replace the array)
        const stringsOnly = action.payload.filter(
          (item) => typeof item === "string"
        );
        return { ...state, headline: stringsOnly };
      }
      // Handle other cases if needed
      // return { ...state, language: action.payload };
      return { ...state, headline: action.payload };
    case DESCRIPTION:
      if (typeof action.payload === "string") {
        return { ...state, description: [action.payload] }; // Wrap the string in an array
      } else if (Array.isArray(action.payload)) {
        // Handle the case where it's an array (replace the array)
        const stringsOnly = action.payload.filter(
          (item) => typeof item === "string"
        );
        return { ...state, description: stringsOnly };
      }
      // Handle other cases if needed
      // return { ...state, language: action.payload };
      return { ...state, description: action.payload };
    case IMAGE_URL:
      if (typeof action.payload === "string") {
        return { ...state, imageUrl: [action.payload] }; // Wrap the string in an array
      } else if (Array.isArray(action.payload)) {
        // Handle the case where it's an array (replace the array)
        const stringsOnly = action.payload.filter(
          (item) => typeof item === "string"
        );
        return { ...state, imageUrl: stringsOnly };
      }
      // Handle other cases if needed
      // return { ...state, language: action.payload };
      return { ...state, imageUrl: action.payload };
    case VIDEO_URL:
      if (typeof action.payload === "string") {
        return { ...state, videoUrl: [action.payload] }; // Wrap the string in an array
      } else if (Array.isArray(action.payload)) {
        // Handle the case where it's an array (replace the array)
        const stringsOnly = action.payload.filter(
          (item) => typeof item === "string"
        );
        return { ...state, videoUrl: stringsOnly };
      }
      // Handle other cases if needed
      // return { ...state, language: action.payload };
      return { ...state, videoUrl: action.payload };
    case HTML_URL:
      if (typeof action.payload === "string") {
        return { ...state, htmlUrl: [action.payload] }; // Wrap the string in an array
      } else if (Array.isArray(action.payload)) {
        // Handle the case where it's an array (replace the array)
        const stringsOnly = action.payload.filter(
          (item) => typeof item === "string"
        );
        return { ...state, htmlUrl: stringsOnly };
      }
      // Handle other cases if needed
      // return { ...state, language: action.payload };
      return { ...state, htmlUrl: action.payload };
    case COST_PER_INSTALL:
      return { ...state, costPerInstall: action.payload };
    case CURRENCY_SYMBOL:
      return { ...state, currencySymbol: action.payload };
    case ADVERTISER_ID:
      return { ...state, advertiserId: action.payload };
    case SESSION_ID:
      return { ...state, sessionId: action.payload };
    case AUTH_TOKEN:
      return { ...state, authToken: action.payload };
    case USER_EMAIL:
      return { ...state, userEmail: action.payload };
    case COUNTRY_CODE:
      return { ...state, countryCode: action.payload };
    case LOCATION_CODE:
      return { ...state, locationCode: action.payload };
    case IMAGE_FILE:
      return { ...state, imageFile: action.payload };
    case VIDEO_FILE:
      return { ...state, videoFile: action.payload };
    case HTML_FILE:
      return { ...state, htmlFile: action.payload };
    case STATUS:
      return { ...state, status: action.payload };
    case MASTER_COUNTRY_ARRAY:
      return { ...state, masterCountryArray: action.payload };
    case MASTER_COUPON_ARRAY:
      return { ...state, mastercouponArray: action.payload };
    case MASTER_TARGETING_TYPES_ARRAY:
      return { ...state, mastertargetingTypesArray: action.payload };
    case MASTER_PUBLISHER_ARRAY:
      return { ...state, masterPublisherArray: action.payload };
    case MASTER_MACROS_ARRAY:
      return { ...state, masterMacrosArray: action.payload };
    case MASTER_MMP_ARRAY:
      return { ...state, masterMMPArray: action.payload };
    case MASTER_LANGUAGE_ARRAY:
      return { ...state, masterLanguageArray: action.payload };
    case REMEMBER_ME_CHECK:
      return { ...state, rememberMeCheck: action.payload };
    case REMEMBER_ME_EMAIL:
      return { ...state, rememberMeEmail: action.payload };
    case REMEMBER_ME_PASSWORD:
      return { ...state, rememberMePassword: action.payload };
    case TOTAL_BALANCE:
      return { ...state, totalBalance: action.payload };
    case IMAGE_URL_ERROR:
      return { ...state, imageUploadError: action.payload };
    case LOGO_URL_ERROR:
      return { ...state, logoError: action.payload };
    case LOCATION_CHECK:
      return { ...state, locationCheck: action.payload };
    case DATA_SOURCE:
      return { ...state, dataSource: action.payload };
    case SEGMENT_NAME:
      return { ...state, segmentName: action.payload };
    case DATA_TYPE:
      return { ...state, dataType: action.payload };
    case DATA_UPLOAD:
      return { ...state, dataUpload: action.payload };
    case UPLOADED_DATA_URL:
      return { ...state, uploadedUrl: action.payload };
    case DATA_SOURCE_ERROR:
      return { ...state, dataSourceError: action.payload };
    case SEGMENT_NAME_ERROR:
      return { ...state, segmentNameError: action.payload };
    case DATA_TYPE_ERROR:
      return { ...state, dataTypeError: action.payload };
    case DATA_UPLOAD_ERROR:
      return { ...state, dataUploadError: action.payload };
    case UPLOADED_DATA_URL_ERROR:
      return { ...state, uploadedUrlError: action.payload };
    case TRANSACTION_CURRENCY:
      return { ...state, transactionCurrency: action.payload };
    case TEMP_TRANSACTION_CURRENCY:
      return { ...state, tempTransactionCurrency: action.payload };
    case SELECTED_DATE_RANGE:
      return { ...state, selectedDateRange: action.payload };
    case SELECTED_ADVERTISER_ID:
      return { ...state, selectedAdvertiserId: action.payload };
    case USER_TYPE:
      return { ...state, usertype: action.payload };
    case ADVERTISERS_LIST:
      return { ...state, advertisersList: action.payload };
    case ADVERTISERS_SCREEN_TABLE:
      return { ...state, advertiserScreenTable: action.payload };
    case REPORT_SCREEN_TABLE:
      return { ...state, reportScreenTable: action.payload };
    default:
      return state;
  }
};

export default userReducer;
