// reducers/index.ts
import {CounterActionTypes ,INCREMENT,DECREMENT,COUNT} from '../Actions/counterActions'
interface AppState {
    count: number;
  }
  
  const initialState: AppState = {
    count: 0,
  };

  const reducer = (state = initialState, action: CounterActionTypes) => {
    switch (action.type) {
      case INCREMENT:
        return { count: state.count + 1 };
      case DECREMENT:
        return { count: state.count - 1 };
      case COUNT:
        return  { ...state,count: action.payload };
      default:
        return state;
    }
  };
  
  export default reducer;
  