import React from 'react'
import CreateCampaign from '../../CreateCampaign'

const CreateAdminCampaign =() => {
  return (
    <div>
        <CreateCampaign adminView = {true} />
    </div>
  )
}

export default CreateAdminCampaign