import { combineReducers } from 'redux';
import reducer from './counterReducer';
import userReducer from './userReducer';

const rootReducer = combineReducers({
  counter: reducer,
  user: userReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;