import React, { FC, useEffect, useRef, useState } from 'react';
import '../../../CSS/Billing.css';
import { ListItemText, MenuItem, Select } from '@mui/material';
// import { styled, } from '@mui/material';
import { RootState } from '../../../REDUX/Reducers/rootReducer';
import { setAdvertiserId, setAdvertisersList, setCurrencySymbol, setMasterCountryArray, setSelectedAdvertiserId } from '../../../REDUX/Actions/userActionIndex';
import { connect } from 'react-redux';
import BillingScreen from '../BillingScreen';
import CreateProfileIcon from '../../../IMAGE/createProfile.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch } from 'react-redux';
// import { CustomSelect } from '../../../Constant';
import BackCustom from '../../custom/BackCustom';
import { useLocation } from 'react-router-dom';
import { styled } from 'styled-components';

interface BillingScreenProps {
    shouldHideDiv: boolean;
    subHeading: string;
    advertiserId: any;
    advertisersList: any;
    selectedAdvertiserId: string;
    currencySymbol: string;
    masterCountryArray: any;
}

const CustomSelect = styled(Select)(({ theme }) => ({
    backgroundColor: "white",
    fontSize: "14px",
    width: "95%",
    height: "40px",
    marginRight: '10px',
    borderRadius: '2px',
    '& fieldset': {
        padding: 0,
        border: '1px solid rgba(51, 51, 51, 0.5)',
        outline: 'none',
    },
    '&.Mui-focused fieldset': {
        border: '1px solid rgba(51, 51, 51, 0.5)',
        outline: 'none',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid rgba(51, 51, 51, 0.5)',
        outline: 'none',
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
    {
        border: "1px solid black",
    },
}));

const AdminBillingScreen: FC<BillingScreenProps> = ({ advertiserId, advertisersList, selectedAdvertiserId, currencySymbol, masterCountryArray }) => {

    const [openSession, setOpenSession] = React.useState(false);

    const handleSessionClose = () => {
        setOpenSession(false);
    };

    const [toggleCal, setToggleCal] = useState<string>("none");
    const boxRef = useRef<HTMLDivElement | null>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (boxRef.current && !boxRef.current.contains(event.target as Node)) {
            setToggleCal("none");
        }
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [createdOpen, setCreatedOpen] = React.useState(false);

    const [filters, setFilters] = useState<any>({
        advertiser: "",
    });

    const handleFilterChange = (filterType: any, value: any) => {
        setFilters((prevFilters: any) => ({
            ...prevFilters,
            [filterType]: value,
        }));
    };

    const dispatch = useDispatch()
    const location = useLocation()

    // useEffect(() => {
    //     if (selectedAdvertiserId == "" || selectedAdvertiserId == undefined || selectedAdvertiserId == null || selectedAdvertiserId == advertiserId) {
    //         setFilters({
    //             advertiser:
    //                 advertisersList.find((advertiser: any) => {
    //                     return advertiser.advertiserId === advertiserId;
    //                 }),
    //         })
    //     } else {
    //         setFilters({
    //             advertiser:
    //                 advertisersList.find((advertiser: any) => {
    //                     return advertiser.advertiserId === selectedAdvertiserId;
    //                 }),
    //         })
    //     }
    // }, [])

    useEffect(() => {
        const updateFilters = () => {
            let advertiser = {};
            if (!selectedAdvertiserId || selectedAdvertiserId === advertiserId) {
                advertiser = advertisersList.find((advertiser1: any) => {
                    if(advertiser1.advertiserId === advertiserId){
                        return advertiser1
                    }
                });
            } else {
                advertiser = advertisersList.find((advertiser1: any) => {
                    if(advertiser1.advertiserId === selectedAdvertiserId){
                        return advertiser1
                    }
                })
            }
            if (advertiser && advertiser !== filters.advertiser) {
                setFilters({ advertiser });
            }
        };
        updateFilters();
    }, [advertisersList]);

    const [findCountry, setFindCountry] = useState<any>()

    const handleCurrency = () => {
        if(filters.advertiser != ""){
            let countryArray = []
            let country = filters.advertiser['Country'];
            countryArray = masterCountryArray
            if (countryArray && Array.isArray(countryArray)) {
                let countryFind = countryArray.find((element: any) => { return country === element.Country })
    
                setFindCountry(countryFind)
                if (countryFind != undefined) {
                    localStorage.setItem("currency", countryFind.CurrencySymbol);
                    localStorage.setItem("countryCode", countryFind.Code)
                    localStorage.setItem("budgetLimit", countryFind.BudgetLimit)
                    dispatch(setCurrencySymbol(countryFind.CurrencySymbol))
                    // dispatch(setCountryCode(countryFind.Code))
                    // dispatch(setCountryCodeAdvertiser(countryFind.CountryCode));
                    // setCurrency(countryFind.CurrencySymbol)
                }
            }
        } 
    }

    useEffect(() => {
        handleCurrency()
        dispatch(setSelectedAdvertiserId(filters.advertiser == "" ? advertiserId : filters.advertiser.advertiserId))
    }, [filters])

    return (
        <div>
            {location && location.state && location.state.viewAll ? <BackCustom onClick={() => window.history.back()} /> : <div></div>}
            <div className='div-display'  style={{
                    marginBottom: "20px"
                }}>
                <div className='select-div-size'>
                    <div className='sub-heading'>Select Brand / Agency</div>
                    <div>
                        <CustomSelect
                            value={filters.advertiser} 
                            IconComponent={ExpandMoreIcon}
                            onChange={(e : any) => {
                                handleFilterChange('advertiser', e.target.value)}
                            }
                        >
                            {advertisersList.map((item: any) => (
                                <MenuItem className='menuItemStyle' key={item} value={item}>
                                    {/* <ListItemText primary={item.advertiserName} secondary={item.id} /> */}
                                    {item.advertiserName}{" ("+item.id+")"}
                                </MenuItem>
                            ))}
                        </CustomSelect>
                    </div>
                </div>

                <div className='select-div-size' >
                    <div className='profile-edit'>
                        <img src={ filters.advertiser["advertiserProfile"] != null && filters.advertiser["advertiserProfile"] != undefined
                        ? `data:image/png;base64,${btoa(String.fromCharCode(...new Uint8Array(filters.advertiser["advertiserProfile"]['data'])))}` 
                        : CreateProfileIcon} height="70px" width="70px" style={{ borderRadius: 5, marginRight: "15px" }} />
                        <div >
                            <div className='sub-heading' style={{
                                color: "#6900B8"
                            }}>Brand Name</div>
                            <div className='heading' style={{
                                fontSize: "20px",
                                fontWeight: 700
                            }}>{filters.advertiser["advertiserName"]}</div>
                        </div>
                    </div>
                </div>
            </div>
            <BillingScreen shouldHideDiv={false} subHeading={''} isadmin={true} />
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    advertiserId: state.user.advertiserId,
    selectedAdvertiserId: state.user.selectedAdvertiserId,
    advertisersList: state.user.advertisersList,
    currencySymbol: state.user.currencySymbol,
    masterCountryArray: state.user.masterCountryArray,
});

const mapDispatchToProps = {
    setAdvertiserId,
    setSelectedAdvertiserId,
    setAdvertisersList,
    setCurrencySymbol,
    setMasterCountryArray,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminBillingScreen);