import React, { FC, useEffect, useState } from 'react';
import '../../../CSS/Coupon.css';
import axios from "axios";
import { Select, SelectChangeEvent, styled , MenuItem, Checkbox, ListItemText, TextField, CircularProgress} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { apiURL, customCheckBoxColor, secretKey } from '../../../Constant';
import { RootState } from '../../../REDUX/Reducers/rootReducer';
import { setAdvertiserId, setAuthToken, setCurrencySymbol, setSessionId, setMasterCouponArray } from '../../../REDUX/Actions/userActionIndex';
import { connect } from 'react-redux';
import { PurpleCustomButton } from '../../custom/PurpleButton';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import BackCustom from '../../custom/BackCustom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {CouponModal} from '../../custom/CouponModal'
const CryptoJS = require('crypto-js')

function SlideTransition(props: TransitionProps) {
    return <Slide {...props} direction="up" />;
}

const CustomTextField = styled(TextField)({
    width: "100%",
    "& .MuiOutlinedInput-root": {
        borderRadius: "2px",
        height: 40,
        paddingTop: 0,
        paddingLeft: 5,
        paddingBottom: 0,
        "& fieldset": {
            border: '1.4px solid rgba(51, 51, 51, 0.3)',
        },
        "&.Mui-focused fieldset": {
            border: '1.4px solid rgba(51, 51, 51, 0.4)',
        },
    },
    '&.MuiTextField-root input': {
        border: 'none',
        outline: 'none',
        paddingLeft: 0,
        paddingTop: 0,
        paddingBottom: 0,
    },
    "& .MuiFormHelperText-root": { color: 'red', paddingLeft: '0', marginBottom: "5px", marginLeft: '2px' },
    "& input::placeholder" : {
         color: '#C4C4C4', 
         margin: "auto", 
         font: 'Manrope', 
         fontWeight: '600',
         fontSize: '12px' 
        }
});

const CustomSelect = styled(Select)(({ theme }) => ({
    fontSize: "14px",
    fontWeight: "600",
    fontFamily: "Manrope",
    width: "100%",
    height: "40px",
    marginRight: '10px',
    borderRadius: '3px',
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
    {
        border: "1px solid black",
    },
}));

const menuItemStyle = {
    fontSize: "14px",
    fontFamily: "Manrope",
    fontWeight: "700",
    '& .MuiMenuItem-selected': {
        backgroundColor: "#6800b8",
    },
}

const inputLabelStyle = {
    color: '#333333', 
    font: 'Manrope', 
    fontWeight: '700' ,
    fontSize: '12px',
    margin: '5px 0' 
}

interface CouponInterface {
    CouponCode: string,
    Discount: string,
    IsActive: boolean,
    MaxPaymentAmount: string,
    UsageLimit: string,
    ValidUpto: string,
    Currency: string
}

interface CreateCouponScreenProps {
    currencySymbol: any,
    authToken: any,
    sessionId: any,
    advertiserId: any,
    mastercouponArray : any,
    masterCountryArray: any
}

const CreateCouponScreen: FC<CreateCouponScreenProps> = (props) => {
    const [advertisersData, setAdvertisersData] = useState<any[]>([])

    const [coupon, setCoupon] = useState({
        CouponCode: '',
        Discount: '',
        IsActive: true,
        MaxPaymentAmount: '',
        UsageLimit: '',
        ValidUpto: '',
        Currency: ''
    });
    const [selectedAdvertisers, setSelectedAdvertisers] = React.useState<string[]>([])
    const [copied, setCopied] = useState(false);
    const [open, setOpen] = useState(false);
    const [openSession, setOpenSession] = React.useState(false);
    const [currencyList, setCurrencyList] = React.useState<any[]>([]);
    const navigate = useNavigate()

    const copyToClipboard = (text: any) => {
        console.log(text)
        navigator.clipboard.writeText(text);
        setCopied(true);
      };

    const getAllCoupons = () => {
        setAdLoader(true)
        const encryptAdvertiserID = CryptoJS.AES.encrypt(JSON.stringify(props.advertiserId), secretKey).toString()
        let headers = {
            'Content-Type': 'application/json',
            'advertiserid': encryptAdvertiserID,
            'authtoken': props.authToken,
            'sessionid': props.sessionId
        };
        axios
            .get(`${apiURL}/api/admin/advertisers`, { headers: headers })
            .then((response) => {
                if (response.data.status == true) {
                    const resData = response.data.data;
                    const bytes = CryptoJS.AES.decrypt(resData, secretKey);
                    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
                    const parsedData = JSON.parse(decryptedData);
                    setAdvertisersData(parsedData)
                    // console.log("advertisersData",advertisersData)
                    // setFilterData(parsedData['transactionsData'])
                } else if (response.data.statuscode == 401) {
                    setOpenSession(true)
                    setTimeout(() => { navigate("/") }, 2000)
                }
                setAdLoader(false)
            })
            .catch((error) => {
                console.log(error)
            })
            setAdLoader(false)
    }

    useEffect(() => {
        // const couponArray = getCouponMaster()
        getAllCoupons();
        let currencies: any = [];
        props.masterCountryArray.map((item: any, index: any) => {
            if (item.Code && currencies.indexOf(item.Code) < 0) {
                
                currencies.push(item.Code);
            }
        })
        setCurrencyList((prevState) => currencies)
    }, [])

    const [errors, setErrors] = useState({
        CouponCode: '',
        Discount: '',
        MaxPaymentAmount: '',
        UsageLimit: '',
        ValidUpto: '',
        Currency: ''
    });

    const validateInput = () => {
        let flag = true;
        if (!coupon.CouponCode || coupon.CouponCode.length < 1) {
            setErrors((prevState) => ({
                ...prevState,
                CouponCode: 'Invalid value',
            }))
            flag = false
        }
        if (!coupon.ValidUpto || coupon.ValidUpto.length < 1) {
            setErrors((prevState) => ({
                ...prevState,
                ValidUpto: 'Invalid value',
            }))
            flag = false
        }
        if (!coupon.Currency || coupon.Currency.length < 1) {
            setErrors((prevState) => ({
                ...prevState,
                Currency: 'Invalid value',
            }))
            flag = false
        }
        if (!coupon.Discount || coupon.Discount.length < 1 || isNaN(parseFloat(coupon.Discount))) {
            if (coupon.Discount && coupon.Discount.length > 0 && isNaN(parseFloat(coupon.Discount))) {
                setErrors((prevState) => ({
                    ...prevState,
                    Discount: 'Only numeric values allowed',
                }))
            } else {
            setErrors((prevState) => ({
                    ...prevState,
                    Discount: 'Invalid value',
                }))
            }
            flag = false
        }

        if (!coupon.MaxPaymentAmount || coupon.MaxPaymentAmount.length < 1 || isNaN(parseFloat(coupon.MaxPaymentAmount))) {
            if (coupon.MaxPaymentAmount && coupon.MaxPaymentAmount.length > 0 && isNaN(parseFloat(coupon.MaxPaymentAmount))) {
                setErrors((prevState) => ({
                    ...prevState,
                    MaxPaymentAmount: 'Only numeric values allowed',
                }))
            } else {
            setErrors((prevState) => ({
                    ...prevState,
                    MaxPaymentAmount: 'Invalid value',
                }))
            }
            flag = false
        }

        if (!coupon.UsageLimit || coupon.UsageLimit.length < 1 || isNaN(parseFloat(coupon.UsageLimit))) {
            if (coupon.UsageLimit && coupon.UsageLimit.length > 0 && isNaN(parseFloat(coupon.UsageLimit))) {
                setErrors((prevState) => ({
                    ...prevState,
                    UsageLimit: 'Only numeric values allowed',
                }))
            } else {
            setErrors((prevState) => ({
                    ...prevState,
                    UsageLimit: 'Invalid value',
                }))
            }
            flag = false
        }
        return flag;
    }

    const handleChange = (event: SelectChangeEvent<any>) => {
        const {
            target: { value },
        } = event;
        setSelectedAdvertisers(
            typeof value === 'string' ? value.split(',') : value,
        );
    }
    
    const handleFormChange = (e : any) => {
        let {value, name} = e.target;
        let state: any = coupon;
        state[name] = value
        setCoupon(prevState => ({...state}));
        console.log('coupon: ', coupon === state)

        let currentErrors: any = errors;
        currentErrors[name] = '';
        if (value == "" || value == null) {
            currentErrors[name] = 'Invalid value';
        }
        setErrors(prevState => ({...currentErrors}))



        
    }

    const onModalClose = () => {
        setOpen(false)
        setCopied(false)
        // navigate("/home/admin/coupons")
    } 

    const onClickCreateCoupon = () => {
        if (!validateInput()) {
            return;
        }
        setOpen(true);
        
        const encryptAdvertiserID = CryptoJS.AES.encrypt(JSON.stringify(props.advertiserId), secretKey).toString()
        let headers = {
            'Content-Type': 'application/json',
            'advertiserid': encryptAdvertiserID,
            'authtoken': props.authToken,
            'sessionid': props.sessionId
        };
        let jsonParams = {  
            CouponCode: coupon.CouponCode,
            IsActive: true,
            IsPercentage: true,
            Discount: parseFloat(coupon.Discount),
            MaxPaymentAmount: parseFloat(coupon.MaxPaymentAmount),
            UsageLimit: parseFloat(coupon.UsageLimit),
            ValidUpto: coupon.ValidUpto,
            Currency: coupon.Currency,
            ValidForAll: selectedAdvertisers.length < 1,
            Advertisers: selectedAdvertisers 
        };
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(jsonParams), secretKey).toString();
        axios
            .post(`${apiURL}/api/admin/coupons`, { params: encryptedData }, { headers: headers })
            .then((response) => {
                if (response.data.status == true) {
                    const resData = response.data.data;
                    const bytes = CryptoJS.AES.decrypt(resData, secretKey);
                    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
                    const parsedData = JSON.parse(decryptedData);
                    setOpen(true);
                } else if (response.data.statuscode == 401) {
                    setOpenSession(true)
                    setTimeout(() => { navigate("/") }, 2000)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const [adLoader, setAdLoader] = useState(false)

    const CustomIconComponent: FC<{ loading: boolean }> = ({ loading }) => (
        <div style={{ position: 'relative', display: "flex", alignItems: "center", marginRight: "8px", cursor: "pointer" }}>
            {loading ? (
                <CircularProgress style={{ color: customCheckBoxColor }} size="1rem" />
            ) : (
                <ExpandMoreIcon sx={{
                    color: "#000000",
                    opacity: 0.55
                }} />
            )}
        </div>
    );

    return (
        <div style={{margin: '0 20px'}}>
            <div style={{display: 'flex', justifyContent: 'space-between'}} >
                <div style={{display: 'flex', flexDirection:'column'}}>
                    <BackCustom onClick={()=> window.location.replace("/home/admin/coupons")} />
                    <div>Create New Coupon</div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', width: '30%'}}>
                        <div>Select Brand / Agency</div>
                        <CustomSelect
                            multiple
                            value={selectedAdvertisers}
                            IconComponent={(props: any) => <CustomIconComponent loading={adLoader === true && advertisersData.length === 0} {...props} />}
                            onChange={handleChange}
                            renderValue={(selected: any) => {
                                // console.log("selected",selected)
                                let result: any = [];
                                advertisersData.map((item)=> {
                                    // console.log("index",selected.indexOf(item.advertiserId))
                                    if (selected.indexOf(item.advertiserId) > -1) {
                                        result.push(item.advertiserName) 
                                    }
                                })
                                return result ? result.join(', ') : ''
                            }}
                        >
                            {/* <MenuItem sx={menuItemStyle} value="selectAll" onClick={handleToggleAll}>
                                <Checkbox checked={selectAll} />
                                <ListItemText primary="Select All" />
                            </MenuItem> */}
                            {adLoader == false ? (advertisersData.length != 0 ? advertisersData.map((item: any) => (
                                <MenuItem sx={menuItemStyle} key={item.advertiserId} value={item.advertiserId}>
                                    <Checkbox checked={selectedAdvertisers.indexOf(item.advertiserId) > -1} />
                                    <ListItemText primary={item.advertiserName} secondary={item.id}/>
                                </MenuItem>
                            ))  : <MenuItem className='menuItemStyle'>No Advertiser found</MenuItem>) : <div style={{
                                display: "flex",
                                justifyContent: "center"
                            }}><CircularProgress style={{ color: customCheckBoxColor }} size="1.5rem" /></div>}
                        </CustomSelect>
                </div>
            </div>
            <div className='white-container' style={{display: 'flex',flexDirection:'column', gap: '20px', padding: '25px 15px', margin: '25px 0'}}>
                <div style={{display: 'flex',justifyContent:'center', gap: '50px'}}>
                <div style={{width: '30%'}}>
                <div style={inputLabelStyle}>Enter Coupon Code</div>
                <CustomTextField
                    name='CouponCode'
                    value={coupon.CouponCode}
                    onChange={handleFormChange}
                    helperText={errors.CouponCode}
                    placeholder='Eg: DIWALI_ADM100'
                    />
                </div>
                <div style={{width: '30%'}}>
                <div style={inputLabelStyle}>Discount {'(%)'}</div>
                <CustomTextField
                    name='Discount'
                    value={coupon.Discount}
                    onChange={handleFormChange}
                    helperText={errors.Discount}
                    placeholder='Eg: 50%'
                    />
                </div>
                <div style={{width: '30%'}}>
                <div style={inputLabelStyle}>Max Amount {'($)'}</div>
                <CustomTextField
                    name='MaxPaymentAmount'
                    value={coupon.MaxPaymentAmount}
                    onChange={handleFormChange}
                    helperText={errors.MaxPaymentAmount}
                    placeholder='Eg: $ 100'
                    />
                </div>
                </div>
                <div style={{display: 'flex',justifyContent:'center', gap: '50px'}}>
                <div style={{width: '30%'}}>
                <div style={inputLabelStyle}>Usage Limit {'(No. of Users)'}</div>
                <CustomTextField
                    name='UsageLimit'
                    value={coupon.UsageLimit}
                    onChange={handleFormChange}
                    helperText={errors.UsageLimit}
                    placeholder='Eg: 100'
                    />
                </div>

                <div style={{width: '30%'}}>
                <div style={inputLabelStyle}>Valid Upto</div>
                <CustomTextField
                    name='ValidUpto'
                    value={coupon.ValidUpto}
                    onChange={handleFormChange}
                    helperText={errors.ValidUpto}
                    placeholder='DD-MM-YYYY'
                    type='date'
                    />
                </div>

                <div style={{width: '30%'}}>
                <div style={inputLabelStyle}>Currency</div>
                <CustomSelect
                    name='Currency'
                    value={coupon.Currency}
                    onChange={handleFormChange}
                    defaultValue="INR"
                    >
                {currencyList.map((item: any) => (
                                <MenuItem sx={menuItemStyle} key={item} value={item}>
                                    {/* <Checkbox checked={selectedAdvertisers.indexOf(item) > -1} /> */}
                                    <ListItemText primary={item}/>
                                </MenuItem>
                    ))}
                </CustomSelect>
                </div>
                </div>

                
            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}><PurpleCustomButton children= "Create Coupon" disable={true} onClick={onClickCreateCoupon} /></div>
            {/* <Modal open={open}></Modal> */}
            <CouponModal open={open} coupon={coupon} onClose={onModalClose} onCopy={copyToClipboard}  copy={copied}></CouponModal>
            {/* <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                open={copied}
                message="Copied to clipboard"
                TransitionComponent={SlideTransition}
                
            /> */}
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    currencySymbol: state.user.currencySymbol,
    authToken: state.user.authToken,
    sessionId: state.user.sessionId,
    advertiserId: state.user.advertiserId,
    mastercouponArray : state.user.mastercouponArray,
    masterCountryArray: state.user.masterCountryArray
});

const mapDispatchToProps = {
    setCurrencySymbol,
    setAdvertiserId,
    setAuthToken,
    setSessionId,
    setMasterCouponArray
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCouponScreen);