import React, { FC, useLayoutEffect } from "react";
import Navbar from "./SideNav/Navbar";
import Header from "./Header";
import { Route, Routes } from 'react-router-dom';
import { adminRouteList, routeList } from "./route/routesList";
import '../CSS/homePage.css'
import { setUserType } from "../REDUX/Actions/userActionIndex";
import { RootState } from "../REDUX/Reducers/rootReducer";
import { Snackbar } from "@mui/material";
import { connect } from "react-redux";
import AdminNavbar from "./SideNav/AdminNavbar";

interface HomePageProps {
  usertype: any;
}

const HomePage: FC<HomePageProps> = (props) => {

  const { usertype } = props
  const [openSession, setOpenSession] = React.useState(false);

  const handleSessionClose = () => {
    setOpenSession(false);
  };

  useLayoutEffect(() => {
    document.body.style.backgroundColor = "#F8F8F8"
  });
  

  return (
    <div>
      <Header />
      <div className="bodyDiv">
        {usertype.toLowerCase() == "admin" ? <AdminNavbar /> : <Navbar />}
        <div className="bodyMainDiv">
          <Routes>
            {usertype.toLowerCase() == "admin" ?
              adminRouteList.map(({ path, component }) => (
                <Route path={path} key={path} Component={component} />
              ))
              : routeList.map(({ path, component }) => (
                <Route path={path} key={path} Component={component} />
              ))}
          </Routes>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={openSession}
        onClose={handleSessionClose}
        message="Session Expired!"
      />
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  usertype: state.user.usertype,
});

const mapDispatchToProps = {
  setUserType
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);