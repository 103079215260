import React, { FC, useState } from "react";
import '../../CSS/dashboard.css';
import '../../CSS/helpCenter.css';
import { qaitems } from "./helpQA";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const HelpCentreScreen: FC = () => {

  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setOpenIndex((prevIndex : any) => (prevIndex === index ? null : index));
  };

  return <div>
    <div>
      {qaitems.map((item, index) => (
        <div key={index} className="qa-main-div">
          <div onClick={() => handleToggle(index)} className="question-box">
            <div>{item.question}</div>
            <div>{openIndex === index ? <ExpandMoreIcon className="arrow-style"/> : <ExpandLessIcon className="arrow-style"/>}</div>
          </div>
          {openIndex === index && <div style={{ marginTop: '10px' }}>{item.answer}</div>}
        </div>
      ))}
      </div>
  </div>
}

export default HelpCentreScreen;

