import React, { useState } from "react";
import leftSideBG from "../../IMAGE/leftSideBG.svg";
import rightSideBG from "../../IMAGE/rightSideBG.svg";
import logo from "../../IMAGE/logo.svg";
import "../../CSS/SignIn.css";
import { BiSolidErrorCircle } from "react-icons/bi";
import { MdKeyboardBackspace } from "react-icons/md";
const label = { inputProps: { "aria-label": "Checkbox demo" } };
const CryptoJS = require('crypto-js')
const PasswordReset: React.FC = () => {
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="signIn-main">
      <div className="left-img-div">
        <img src={leftSideBG} alt="leftSide" />
      </div>
      <div className="center-div">
        <img className="admattic-logo" src={logo} alt="logo" />

        <div className="mid-box">
          <h2>PASSWORD RESET</h2>

          <div className="details-box">
            <span className="grey-text center-align reset-text">Your password has been successfully reset.</span>
          </div>
          <button className="signIn-btn open-email continue-btn" onClick={()=>{
            window.close()          
            }}>Continue</button>
        </div>

        {/* <div className="resend-email center-align">
            <span className="grey-text" >Didn't recieve the email?</span>
            &nbsp;
            <a href="https://www.youtube.com/" className="resend-btn">Click to Resend</a>
        </div> */}

        {/* <a href="https://www.youtube.com/" className="signup-box back" >
          <MdKeyboardBackspace/>&nbsp;
          <p>Back to sign in</p>
        </a> */}
      </div>
      <div className="right-img-div">
        <img src={rightSideBG} alt="rightSide" />
      </div>
    </div>
  );
};

export default PasswordReset;
