import React, { FC, useState, useEffect, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TablePagination } from "@mui/material";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { Link, useNavigate } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"; // Import the icons

// import CampaignMainScreen from "./CampaignMainScreen";
import { LuDownload } from "react-icons/lu";
// import { CampaignArray, EditArray } from "../../Constant";
import {
  EditStatusArray,
  HeaderArrayCampaign,
  CampaignTotalArray,
  EditArray,
  apiURL,
  secretKey,
} from "../../Constant";
import DateCustomPicker from "../custom/DateCustomPicker";
import { PurpleCustomButton } from "../custom/PurpleButton";
import * as XLSX from "xlsx";
import { createObjectCsvWriter } from "csv-writer";
import { saveAs } from "file-saver";
import "../../CSS/CheckboxTab.css";
import "../../CSS/SignIn.css";
import "../../CSS/Campaign.css";
import sound from "../../IMAGE/sound.svg";
import edit from "../../IMAGE/editIcon.svg";
import active from "../../IMAGE/active.svg";
import paused from "../../IMAGE/paused.svg";
import stopped from "../../IMAGE/stopped.svg";
import done from "../../IMAGE/done.svg";
import pending from "../../IMAGE/pending.svg";
import cancel from "../../IMAGE/cancel.svg";
import calender from "../../IMAGE/calender.svg";
import styled from "styled-components";
import { CSVLink } from "react-csv";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import CustomLoader from "../custom/CustomLoader";
import { IoSearchOutline } from "react-icons/io5";
import { connect, useDispatch } from "react-redux";
import { RootState } from "../../REDUX/Reducers/rootReducer";
import {
  setCampaignSubType,
  setAppPlatform,
  setAppName,
  setCampaignName,
  setAppImageUrl,
  setPackageName,
  setLocation,
  setLanguage,
  setTracking,
  setTrackingUrl,
  setImpressionUrl,
  setTokenEvent,
  addTokenEventObject,
  removeTokenEventObject,
  updateToken,
  updateEvent,
  setLocationCode,
  setBudget,
  setFocus,
  setTarget,
  setCostPerInstall,
  setAdGroupName,
  setLogoUrl,
  setHeadline,
  setDescription,
  setImageUrl,
  setVideoUrl,
  setHtmlUrl,
  setCampaignId,
  setBudgetId,
  setStatus,
  setImageFile,
  setVideoFile,
  setHtmlFile,
  setImageUploadError,
  setLogoError,
  setLocationCheck,
  setAdvertiserId,
  setAuthToken,
  setSessionId,
  setDataSource,
  setSegmentName,
  setDataType,
  setDataUpload,
  setUploadedUrl,
  setDataSourceError,
  setSegmentNameError,
  setDataTypeError,
  setDataUploadError,
  setUploadedUrlError,
  setSelectedAdvertiserId,
  setSelectedDateRange,
  setCompanyName,
  setTempTransactionCurrency,
} from "../../REDUX/Actions/userActionIndex";
import { setCount } from "../../REDUX/Actions/counterActionIndex";
import { TableSortLabel } from "@mui/material";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const CryptoJS = require("crypto-js");

interface CampaignMainScreenProps {
  shouldHideDiv: boolean;
  isadmin?: boolean;

  heading?: string;
  dates?: {
    startDate: string;
    endDate: string;
  };
  campaignSubtype: string;
  appPlatform: string;
  appName: string;
  campaignName: string;
  appImageUrl: string;
  packageName: string;

  location: string;
  language: string[];
  tracking: string;
  trackingUrl: string;
  impressionUrl: string;
  tokenEvent: {
    token: string;
    eventName: string;
  }[];
  // showLower?: boolean;
  locationCode: string;

  budget: string;
  target: string;
  focus: string;
  costPerInstall: string;

  adGroupName: string;
  logoUrl: string;
  headline: string[];
  description: string[];

  imageUrl: string[];
  videoUrl: string[];
  htmlUrl: string[];
  count: number;

  budget_Id: string;
  campaign_Id: string;

  status: string;
  authToken: string;
  sessionId: string;
  advertiserId: string;
  dateChanging?: boolean;
  selectedAdvertiserId: string;

  selectedDateRange: any;
}

const CampaignMainScreen: FC<CampaignMainScreenProps> = ({
  dateChanging,
  authToken,
  sessionId,
  advertiserId,
  shouldHideDiv,
  heading,
  dates,
  count,
  appName,
  campaignSubtype,
  appPlatform,
  campaignName,
  packageName,
  appImageUrl,

  location,
  language,
  tracking,
  trackingUrl,
  impressionUrl,
  tokenEvent,
  locationCode,

  budget,
  target,
  focus,
  costPerInstall,

  adGroupName,
  logoUrl,
  headline,
  description,
  imageUrl,
  videoUrl,
  htmlUrl,

  campaign_Id,
  budget_Id,
  status,
  isadmin,
  selectedAdvertiserId,
  selectedDateRange,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [csvData, setCsvData] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false);
  const [dataContains, setDataContains] = useState<boolean>(false);
  const [dataComing, setdataComing] = useState<boolean>(true);
  const [currency, setCurrency] = useState<any>(
    localStorage.getItem("currency")
  );
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());
  const [isHoverStatus, setIsHoverStatus] = useState<string>("");
  const [statusDpd, setStatusDpd] = useState<string>("");
  const [toggleStatusInput, setToggleStatusInput] = useState<boolean>(false);
  const [saveStatusSelected, setSaveSelectedSelected] = useState<string>("");

  const [searchValue, setSearchValue] = useState<string>("");
  const [downloadModal, setdownloadModal] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const rowsPerPageOptions = [5, 10, 25];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const initialStartDate = new Date(new Date().getFullYear(), new Date().getMonth()-2, 1);
  const initialEndDate = new Date();
 
  const [changedDate, setChangedDate]= useState<any>({
  startDate: initialStartDate,
  endDate: initialEndDate
 })

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDateChange = (startDate: Date, endDate: Date) => {
    // getDetailsCampaign(startDate, endDate);
    // console.log(startDate,endDate)
    localStorage.setItem("startDate", startDate.toString());
    localStorage.setItem("endDate", endDate.toString());
    dispatch(
      setSelectedDateRange({
        startDate: startDate,
        endDate: endDate,
      })
    );
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  };

  const onDateSearch = () => {
    setdataComing(true);
    getDetailsCampaign(selectedRange.startDate, selectedRange.endDate);
  };

  useEffect(() => {
    // console.log("dateChanging")
    setdataComing(true);
    onDateSearch();
  }, [dateChanging]);

  const [tableData, setTableData] = useState<any[]>([]);
  const [protectedData, setProtectedData] = useState<
    {
      applicationName: string;
      campaignName: string;
      clicks: number;
      cpc: number;
      cpm: number;
      ctr: number;
      dailyTarget: number;
      goal: string;
      id: string;
      impressions: number;
      isChecked: boolean;
      mediaCost: string;
      reach: string;
      status: string;
      total: string;
      cpi: number;
      installs: string;
      campaignData: {
        campaignId: string;
        AdvertiserId: string;
        TrakierCampaignId: string;
        ApplicationName: string;
        CampaignName: string;
        SubType: string;
        AppPlatform: string;
        AppLookUpDetails: string;
        Location: string;
        CustomerLanguage: string;
        TrackingPartner: string;
        TrackingURL: string;
        ImpressionURL: string;
        EventName: string;
        FinalClickURL: string;
        MandatoryMacros: string;
        OptionalMacros: string;
        FetchedFromTrakier: string;
        Status: string;
        createdAt: string;
        updatedAt: string;
      };
    }[]
  >([]);
  const [saveStatusVal, setSaveStatusVal] = useState<
    {
      applicationName: string;
      campaignName: string;
      clicks: number;
      cpc: number;
      cpm: number;
      ctr: number;
      dailyTarget: number;
      goal: string;
      id: string;
      impressions: number;
      isChecked: boolean;
      mediaCost: string;
      reach: string;
      status: string;
      total: string;
      cpi: number;
      installs: string;

      adGroups: {
        HTML5: string[];
        Status: string;
        adGroupsId: string;
        campaignId: string;
        createdAt: string;
        description: string[];
        headline: string[];
        image: string[];
        logo: string;
        name: string;
        updatedAt: string;
        videos: string[];
      }[];

      campaignData: {
        campaignId: string;
        AdvertiserId: string;
        TrakierCampaignId: string;
        ApplicationName: string;
        CampaignName: string;
        SubType: string;
        AppPlatform: string;
        AppLookUpDetails: {
          logoUrl: string;
          packageName: string;
        };
        Location: string;
        CustomerLanguage: string;
        TrackingPartner: string;
        TrackingURL: string;
        ImpressionURL: string;
        EventName: string;
        FinalClickURL: string;
        MandatoryMacros: string;
        OptionalMacros: string;
        FetchedFromTrakier: string;
        Status: string;
        Languages: string[];
        Token: {
          token: string;
          eventName: string;
        }[];
      };
      createdAt: string;
      updatedAt: string;

      budgetData: {
        budgetId: string;
        campaignId: string;
        createdAt: string;
        dailyAvgBudget: number;
        focusParam: string;
        isSetTargetCoatPerInstall: boolean;
        targetCostPerInstall: number;
        targetUserType: string;
        targetingType: string;
        updatedAt: string;
      };
    }[]
  >([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  // const [dataIfUpdated, setDataIfUpdated] = useState(false);
  // const [constData, setConstData] = useState(CampaignArray);
  //  const [rows, setRows] = useState(CampaignArray);

  const CustomCheck = styled(Checkbox)`
    &.MuiButtonBase-root.MuiCheckbox-root {
      padding: 0 9px;
    }
  `;

  const mergeData = (data: any) => {
    const { campaignData, budgetData, adGroups, ...rest } = data;

    // Flatten adGroups into a single object if needed
    const flatAdGroups = adGroups.reduce(
      (acc: any, adGroup: any, index: number) => {
        acc[`adGroup_${index}`] = adGroup;
        return acc;
      },
      {}
    );

    return {
      ...rest,
      ...campaignData,
      ...budgetData,
    };
  };

  const getDetailsCampaign = (startDate: Date, endDate: Date) => {
    setdataComing(true);

    // const advertiserId = localStorage.getItem("advertiserID") || "";
    // const authToken = localStorage.getItem("authToken");
    // const sessionID = localStorage.getItem("sessionID");
    console.log(startDate, endDate);
    const encryptAdvertiserID = CryptoJS.AES.encrypt(
      JSON.stringify(advertiserId),
      secretKey
    ).toString();

    const header = {
      headers: {
        authtoken: authToken,
        sessionid: sessionId,
        advertiserid: encryptAdvertiserID, // Example authorization header
      },
    };
    const year = startDate.getFullYear();
    const month = startDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month index
    const day = startDate.getDate();

    const startDatFormatted = `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`;

    const endyear = endDate.getFullYear();
    const endmonth = endDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month index
    const endday = endDate.getDate();

    const endDateFormatted = `${endyear}-${
      endmonth < 10 ? "0" + endmonth : endmonth
    }-${endday < 10 ? "0" + endday : endday}`;

    const jsonParam = {
      advertiserId: isadmin ? selectedAdvertiserId : advertiserId,
      startDate: startDatFormatted,
      endDate: endDateFormatted,
    };
    // console.log(jsonParam)

    console.log(jsonParam);
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(jsonParam),
      secretKey
    ).toString();

    const url = `${apiURL}/api/campaigns/getAllCampaigns`;
    if (authToken != "" && sessionId != "" && advertiserId != "") {
      axios
        .post(
          url,
          { params: encryptedData },
          {
            headers: {
              authtoken: authToken,
              sessionid: sessionId,
              advertiserid: encryptAdvertiserID,
              "Content-Type": "application/json",
              // Example authorization header
            },
          }
        )
        .then((res) => {
          const resData = res.data.data;
          const bytes = CryptoJS.AES.decrypt(resData, secretKey);
          const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
          const parsedData = JSON.parse(decryptedData);
          console.log(parsedData);
          if (parsedData.length != 0) {
            if (res.data.status) {
              console.log(parsedData);
              const allMergedData: any[] = [];
              parsedData.map((val: any) => {
                const merege = mergeData(val);
                allMergedData.push(merege);
              });
              console.log("allMergedData", allMergedData);
              setCsvData(allMergedData);
              // setdataComing(false);
              setTableData(parsedData);
              localStorage.setItem("campaignTable", JSON.stringify(parsedData));
              if (parsedData.length != 0) {
                setDataContains(true);
              }
            }
          } else {
            setdataComing(false);
            setDataContains(false);
            setTableData([]);
          }
        })
        .catch((err) => {
          setdataComing(false);
          console.log(err);
        });
    }
  };
  interface filtered {
    id: string;
    applicationName: string;
    campaignName: string;
    goal: string;
    dailyTarget: string;
    status: string;
    impressions: number;
    clicks: number;
    ctr: number | null;
    cpc: number;
    cpm: number | null;
    reach: number;
    mediaCost: number;
    total: number;
    isChecked: boolean; // Define the actual type for 'adGroups'
  }
  const [datePicked, setDatePicked] = useState<boolean>(false);
  const [toggleCal, setToggleCal] = useState<string>("none");
  
  const boxRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (boxRef.current && !boxRef.current.contains(event.target as Node)) {
      setToggleCal("none");
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleSelect = (ranges: any) => {
    if (!datePicked) {
      setDatePicked(true);
    }
    // setDateRange(ranges.range1);

    // Call the callback function from the parent component
    // if (onDateChange) {
    //   onDateChange(ranges.range1.startDate, ranges.range1.endDate);
    // }
    localStorage.setItem("startDate", ranges.range1.startDate);
    localStorage.setItem("endDate", ranges.range1.endDate);
    dispatch(setSelectedDateRange({
      startDate: ranges.range1.startDate,
      endDate: ranges.range1.endDate
    }))
    setChangedDate({
      startDate: ranges.range1.startDate,
      endDate: ranges.range1.endDate
    })
    setSelectedStartDate(ranges.range1.startDate);
    setSelectedEndDate(ranges.range1.endDate);
  };

  const toggleCalender = () => {
    if (toggleCal === "none") {
      setToggleCal("block");
    } else {
      setToggleCal("none");
    }
  };

  const doneClose = () => {
    toggleCalender();
    onDateSearch()
  };


  const saveStatus = (id: string) => {
    const ob = [...saveStatusVal];
    const fil = ob.filter((item) => {
      return item.id == id;
    });
    // console.log(fil);
    if (fil.length != 0) {
      UpdateCampaignApi(fil);
    }
    // console.log(ob);
    setTableData(saveStatusVal);
    setToggleStatusInput(!toggleStatusInput);
  };

  const findIndex = (id: string) => {
    const foundItem = tableData.find((item) => item.id === id);
    if (foundItem) {
      return tableData.indexOf(foundItem);
    } else {
      return -1;
    }
  };
  const performSearch = (value: string) => {
    const searchTerm = value.toLowerCase();
    const campaignData = localStorage.getItem("campaignTable") || "";
    if (campaignData != "" && searchTerm && searchTerm !== null) {
      const data = JSON.parse(campaignData);
      if (data.length != 0) {
        const filteredResults = data.filter((item: any) => {
          return (
            (item.applicationName &&
              item.applicationName.toLowerCase().includes(searchTerm)) ||
            (item.campaignName &&
              item.campaignName.toLowerCase().includes(searchTerm))
          );
        });
        if (searchValue.length > 1 && filteredResults.length > 0) {
          // console.log(filteredResults);
          setTableData(filteredResults);
        }
      }
    }
  };

  const toggleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const campaignData = localStorage.getItem("campaignTable") || "";
    setSearchValue(value);

    if (value == "") {
      if (campaignData != "") {
        const data = JSON.parse(campaignData);
        if (data.length != 0) {
          setTableData(data);
        }
      }
    }
    performSearch(value);
  };

  const toggleStatus = (id: string, value: string) => {
    // console.log("here");
    let statusValue = findIndex(id);

    // Create a copy of the object to update
    const updatedObject = { ...tableData[statusValue] };

    // Update the value property
    updatedObject.campaignData = {
      ...updatedObject.campaignData,
      Status: value !== undefined ? value : "",
    };

    // Create a new array with the updated object
    const updatedArray = [
      ...tableData.slice(0, statusValue),
      updatedObject,
      ...tableData.slice(statusValue + 1),
    ];
    setSaveSelectedSelected(updatedArray[statusValue].campaignData.Status);
    // console.log(updatedArray, tableData);
    setSaveStatusVal(updatedArray);
  };

  const cancelStatus = () => {
    setSaveSelectedSelected("");
    setToggleStatusInput(!toggleStatusInput);
  };

  const toggleStatusButton = (id: string) => {
    // console.log("toggle status button ");
    if (isHoverStatus == id) {
      setToggleStatusInput(!toggleStatusInput);
      setStatusDpd(id);
      setIsHoverStatus(id);
    }
  };

  const UpdateCampaignApi = (data: any) => {
    // const advertiserId = localStorage.getItem("advertiserID") || "";
    const email = localStorage.getItem("emailID") || "";
    const campSubtype = localStorage.getItem("campaignSubtype") || "";
    const appPlatform = localStorage.getItem("appPlatform") || "";
    const selectLanguages = localStorage.getItem("selectedLang") || "";
    const converted = selectLanguages != "" ? JSON.parse(selectLanguages) : [];

    const trackingPart = localStorage.getItem("trackingPartner");
    const trackingURL = localStorage.getItem("trackingUrl") || "";
    const tokenEventData = localStorage.getItem("inputTokenEventData") || "";
    const currency = localStorage.getItem("currency");

    // const countryCode = localStorage.getItem("countryCode") || '';
    // const installationCost = localStorage.getItem("costPerInstall") || "0";
    const currencyCode = localStorage.getItem("countryCode");
    // console.log("data", data);
    // const authToken = localStorage.getItem("authToken");
    // const sessionID = localStorage.getItem("sessionID");
    data.map((item: any) => {
      const jsonParam = {
        AdvertiserId: isadmin ? selectedAdvertiserId : advertiserId,
        CampaignId: item.campaignId,
        Email: email,
        ApplicationName: item.applicationName, // harCoded
        CampaignName: item.campaignName,
        // SubType: item.campSubtype,
        // AppPlatform: appPlatform,
        // AppLookUpDetails: item.lookup,
        // TrackingPartne: trackingPart,
        // TrackingURL: trackingURL,
        // Token: tokenEventData != "" ? JSON.parse(tokenEventData) : [],
        Currency: currencyCode,
        Status: item.campaignData.Status,
        // Payouts: [
        //   {
        //     payout: 0,
        //     revenue: "0",
        //     geo: ["ALL"],
        //   },
        // ],
        Languages: converted,
      };
      // console.log(jsonParam);
      const encryptAdvertiserID = CryptoJS.AES.encrypt(
        JSON.stringify(advertiserId),
        secretKey
      ).toString();

      const url = `${apiURL}/api/campaigns/updateCampaign`;
      if (authToken != "" && sessionId != "" && advertiserId != "") {
        axios
          .post(url, jsonParam, {
            headers: {
              authtoken: authToken,
              sessionid: sessionId,
              advertiserid: encryptAdvertiserID,
              "Content-Type": "application/json",
              // Example authorization header
            },
          })
          .then((res) => {
            console.log("res", res);
            if (res.status == 201) {
              // const resData = res.data;
              // const bytes = CryptoJS.AES.decrypt(resData, secretKey);
              // const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
              // const parsedData = JSON.parse(decryptedData);
              localStorage.setItem("campaignId", res.data.campaignId);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  useEffect(() => {
    // localStorage.setItem("campaignSubtype", "");
    // localStorage.setItem("appPlatform", "");
    // localStorage.setItem("campaignName", "");
    // localStorage.setItem("appLookup", "");
    setdataComing(true);
    dispatch(setLocationCheck(""));
    dispatch(setImageUploadError(""));
    dispatch(setLogoError(""));
    dispatch(setCount(0));
    dispatch(setStatus("pending"));
    dispatch(setCampaignSubType(""));
    dispatch(setAppPlatform(""));
    dispatch(setAppName(""));
    dispatch(setCampaignName(""));
    dispatch(setAppImageUrl(""));
    dispatch(setPackageName(""));

    //CAMPAIGN SETTING

    // localStorage.setItem("locationValue", "");
    // localStorage.setItem("selectedLang", "");
    // localStorage.setItem("appLookup", "");
    // localStorage.setItem("trackingPartner", "");
    // localStorage.setItem("finalClickURL", "");
    // localStorage.setItem("trackingUrl", "");
    // localStorage.setItem("inputTokenEventData", "");
    // localStorage.setItem("packageName", "");
    // localStorage.setItem("appIcon", "");
    // localStorage.setItem("locationCode", "");
    // localStorage.setItem("locationValue", "");
    dispatch(setLanguage([]));
    dispatch(setLocation(""));
    dispatch(setTracking(""));
    dispatch(setTrackingUrl(""));
    dispatch(setImpressionUrl(""));
    dispatch(setTokenEvent([]));
    dispatch(setTempTransactionCurrency(""))
    // dispatch(addTokenEventObject(''))
    // dispatch(removeTokenEventObject(''))
    // dispatch(updateToken(0, ""));
    // dispatch(updateEvent(0, ""));
    dispatch(setLocationCode(""));

    // BUDGET BIDDING
    dispatch(setBudget(""));
    dispatch(setFocus(""));
    dispatch(setTarget(""));
    dispatch(setCostPerInstall(""));

    // localStorage.setItem("budget", "");
    // localStorage.setItem("focusDpd", "");
    // localStorage.setItem("trackDpd", "");
    // localStorage.setItem("costPerInstall", "");
    // localStorage.setItem("targetApp", "");

    //AD GROUP

    // localStorage.setItem("adGrpName", "");
    // localStorage.setItem("htmlUrlArray", `[]`);
    // localStorage.setItem("imageUrlArray", `[""]`);
    // localStorage.setItem("videoUrlArray", `[]`);
    // localStorage.setItem("headlines", `[]`);
    // localStorage.setItem("description", `[]`);
    // localStorage.setItem("logoUrl", "");
    // localStorage.setItem("campaignRecordData", "");

    dispatch(setAdGroupName(""));
    dispatch(setLogoUrl(""));
    dispatch(setHeadline([""]));
    dispatch(setDescription([""]));
    dispatch(setImageUrl([]));
    dispatch(setVideoUrl([]));
    dispatch(setHtmlUrl([]));
    dispatch(setImageFile(""));
    // const emptyFile = new File([], 'empty.txt', { type: 'text/plain' });
    dispatch(setVideoFile([]));
    dispatch(setHtmlFile([]));

    dispatch(setCampaignId(""));
    dispatch(setBudgetId(""));

    dispatch(setDataSource(""));
    dispatch(setDataSourceError(""));

    dispatch(setSegmentName(""));
    dispatch(setSegmentNameError(""));

    dispatch(setDataType(""));
    dispatch(setDataTypeError(""));

    dispatch(setDataUpload([]));
    dispatch(setDataUploadError([]));

    dispatch(setUploadedUrl(""));
    dispatch(setUploadedUrlError(""));

    // getDetailsCampaign(selectedStartDate, selectedEndDate);
    const advertiserId = localStorage.getItem("advertiserID") || "";
  }, []);

  const handleMouseEnter = () => {
    setIsOpenEdit(true);
  };

  const handleMouseLeave = () => {
    setIsOpenEdit(false);
  };
  const handleMouseEnterDownload = () => {
    setIsOpen(true);
  };

  const handleMouseLeaveDownload = () => {
    setIsOpen(false);
  };
  const handleStatusMouseEnter = (id: string) => {
    setIsHoverStatus(id);
  };

  const handleStatusMouseLeave = (id: string) => {
    setIsHoverStatus(id);
  };

  const divStyle = {
    display: shouldHideDiv ? "none" : "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // position: "fixed" as const,
    // top: "130px",
    // width: "80%",
    // height: "50px",
    // zIndex: "50",
    // background: "#F8F8F8",
  };

  const divMarginStyle = {
    marginTop: shouldHideDiv ? "10px" : "85px",
    // display:'flex'
  };

  const setData = (filtered: any) => {
    const stringifyData = JSON.stringify(filtered[0]);
    console.log("function", filtered);

    if (
      filtered.length != 0 &&
      filtered[0] != undefined &&
      filtered[0].campaignData != undefined
    ) {
      dispatch(setCount(0));

      dispatch(
        setCampaignSubType(
          filtered[0].campaignData.SubType != null
            ? filtered[0].campaignData.SubType
            : ""
        )
      );
      // console.log(dispatch(setCampaignSubType(filtered[0].campaignData.SubType)))
      dispatch(
        setAppPlatform(
          filtered[0].campaignData.AppPlatform != null
            ? filtered[0].campaignData.AppPlatform
            : ""
        )
      );
      dispatch(
        setAppName(
          filtered[0].campaignData.ApplicationName != null
            ? filtered[0].campaignData.ApplicationName
            : ""
        )
      );
      dispatch(
        setCampaignName(
          filtered[0].campaignData.CampaignName != null
            ? filtered[0].campaignData.CampaignName
            : ""
        )
      );

      dispatch(
        setTokenEvent(
          filtered[0].campaignData.Token != null &&
            filtered[0].campaignData.Token.length != 0
            ? filtered[0].campaignData.Token
            : [{ token: "", eventName: "" }]
        )
      );
      // if (
      //   filtered[0].campaignData.Token != null &&
      //   filtered[0].campaignData.Token.length != 0
      // ) {
      //   filtered[0].campaignData.Token.map((value: any, index: number) => {
      //     dispatch(updateToken(index, value.token));
      //     dispatch(updateEvent(index, value.eventName));
      //   });
      // }
      // if (
      //   filtered[0].campaignData.Token != null &&
      //   filtered[0].campaignData.Token.length != 0
      // ) {
      //   console.log(filtered[0].campaignData.Token[0].token);
      //   dispatch(
      //     updateToken(
      //       0,
      //       filtered[0].campaignData.Token[0].token != null
      //         ? filtered[0].campaignData.Token[0].token
      //         : ""
      //     )
      //   );
      //   dispatch(
      //     updateEvent(
      //       0,
      //       filtered[0].campaignData.Token[0].eventName != null
      //         ? filtered[0].campaignData.Token[0].eventName
      //         : ""
      //     )
      //   );
      // }

      dispatch(
        setLocationCode(
          filtered[0].campaignData.Location != null
            ? filtered[0].campaignData.Location
            : ""
        )
      );
      //new Changes
      if (filtered[0].campaignData.RetargetingOptions != null) {
        dispatch(
          setDataSource(
            filtered[0].campaignData.RetargetingOptions.dataSource != null
              ? filtered[0].campaignData.RetargetingOptions.dataSource
              : ""
          )
        );
        dispatch(
          setSegmentName(
            filtered[0].campaignData.RetargetingOptions.segmentName != null
              ? filtered[0].campaignData.RetargetingOptions.segmentName
              : ""
          )
        );
        dispatch(
          setDataType(
            filtered[0].campaignData.RetargetingOptions.dataType != null
              ? filtered[0].campaignData.RetargetingOptions.dataType
              : ""
          )
        );
        dispatch(
          setUploadedUrl(
            filtered[0].campaignData.RetargetingOptions.fileUrl != null
              ? filtered[0].campaignData.RetargetingOptions.fileUrl
              : ""
          )
        );
      }
      dispatch(setCampaignId(filtered[0].campaignData.campaignId));

      dispatch(
        setStatus(
          filtered[0].campaignData.Status != null
            ? filtered[0].campaignData.Status
            : ""
        )
      );
      if (filtered[0].campaignData.AppLookUpDetails != null) {
        dispatch(
          setAppImageUrl(
            filtered[0].campaignData.AppLookUpDetails.logoUrl != null
              ? filtered[0].campaignData.AppLookUpDetails.logoUrl
              : ""
          )
        );
        dispatch(
          setPackageName(
            filtered[0].campaignData.AppLookUpDetails.packageName != null
              ? filtered[0].campaignData.AppLookUpDetails.packageName
              : ""
          )
        );
        dispatch(
          setLanguage(
            filtered[0].campaignData.Languages != null
              ? filtered[0].campaignData.Languages
              : ""
          )
        );
        dispatch(
          setLocation(
            filtered[0].campaignData.Location != null
              ? filtered[0].campaignData.Location
              : ""
          )
        );
        if (
          filtered[0].campaignData.Location != null &&
          filtered[0].campaignData.Location != "IN" &&
          filtered[0].campaignData.Location != "ALL"
        ) {
          dispatch(setLocationCheck("another"));
        } else {
          dispatch(
            setLocationCheck(
              filtered[0].campaignData.Location != null
                ? filtered[0].campaignData.Location
                : ""
            )
          );
        }
        dispatch(
          setTracking(
            filtered[0].campaignData.TrackingPartner != null
              ? filtered[0].campaignData.TrackingPartner
              : ""
          )
        );
        dispatch(
          setTrackingUrl(
            filtered[0].campaignData.TrackingURL != null
              ? filtered[0].campaignData.TrackingURL
              : ""
          )
        );
        dispatch(
          setImpressionUrl(
            filtered[0].campaignData.FinalClickURL != null
              ? filtered[0].campaignData.FinalClickURL
              : ""
          )
        );
      }

      // (filtered[0].campaignData.Token.length !=0){
      //   filtered[0].campaignData.Token.map((value: any, index:nuber)=>({

      //   }))

      // }
      // console.log(filtered[0].campaignData.Token != null &&
      //   filtered[0].campaignData.Token.length != 0 )

      // if (
      //   filtered[0].campaignData.Token != null &&
      //   filtered[0].campaignData.Token.length != 0
      // ) {
      //   dispatch(
      //     updateToken(
      //       0,
      //       filtered[0].campaignData.Token[0].token != null
      //         ? filtered[0].campaignData.Token[0].token
      //         : ""
      //     )
      //   );
      //   dispatch(
      //     updateEvent(
      //       0,
      //       filtered[0].campaignData.Token[0].eventName != null
      //         ? filtered[0].campaignData.Token[0].eventName
      //         : ""
      //     )
      //   );
      // }

      // dispatch(addTokenEventObject(filtered[0]))
      // dispatch(removeTokenEventObject(filtered[0]))
      // console.log(dispatch(setBudget(filtered[0])))
      // console.log();
      if (Object.keys(filtered[0].budgetData).length !== 0) {
        dispatch(
          setBudgetId(
            filtered[0].budgetData.budgetId != null
              ? filtered[0].budgetData.budgetId
              : ""
          )
        );
        dispatch(
          setBudget(
            filtered[0].budgetData.dailyAvgBudget != null
              ? filtered[0].budgetData.dailyAvgBudget.toString()
              : ""
          )
        );
        dispatch(
          setFocus(
            filtered[0].budgetData.focusParam != null
              ? filtered[0].budgetData.focusParam
              : ""
          )
        );
        dispatch(
          setTarget(
            filtered[0].budgetData.targetingType != null
              ? filtered[0].budgetData.targetingType
              : ""
          )
        );
        dispatch(
          setCostPerInstall(
            filtered[0].budgetData.targetCostPerInstall != null
              ? filtered[0].budgetData.targetCostPerInstall.toString()
              : ""
          )
        );
      }
      if (filtered[0].adGroups.length != 0) {
        dispatch(setAdGroupName(filtered[0].adGroups[0].name));
        dispatch(setLogoUrl(filtered[0].adGroups[0].logo));
        dispatch(setHeadline(filtered[0].adGroups[0].headline));
        dispatch(setDescription(filtered[0].adGroups[0].description));
        dispatch(setImageUrl(filtered[0].adGroups[0].image));
        dispatch(setVideoUrl(filtered[0].adGroups[0].videos));
        dispatch(setHtmlUrl(filtered[0].adGroups[0].HTML5));
      }
      localStorage.setItem("editingData", stringifyData);

      // console.log(dispatch(setCampaignSubType(filtered[0].campaignData.SubType)))
    }
  };

  const toggleDropdown = (type: string) => {
    dispatch(setCount(0));

    const filtered = tableData.filter((item) => {
      return item.isChecked == true;
    });
    // console.log(filtered,Object.keys(filtered[0].budgetData).length !== 0);
    // for (let index = 0; index < 100; index++) {
    //   setData(filtered);
    // }

    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);

    // console.log("filtered normal module")
    // console.log(filtered)
    setTimeout(() => {
      window.location.replace("/home/campaigns/createCampaign");
    }, 500);

    // if (type == "dwn") {
    //   setIsOpen(!isOpen);
    // } else {
    //   setIsOpenEdit(!isOpenEdit);
    // }
  };
  useEffect(() => {
    console.log(campaignName);
  }, [campaignName]);

  const [sortConfig, setSortConfig] = useState<{
    key: any | null;
    direction: "asc" | "desc";
  }>({
    key: null,
    direction: "asc",
  });

  const requestSort = (key: any) => {
    let direction: "asc" | "desc" = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    sortedData();
  }, [sortConfig]);

  const sortedData = (): any => {
    const sortableData = [...tableData];

    if (sortConfig.key) {
      sortableData.sort((a: any, b: any) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    setTableData([...sortableData]);
  };

  function exportToCSV(data: any[]) {
    // const csvWriter = createObjectCsvWriter({
    //   path: "campaign_data.csv",
    //   header: [
    //     { id: "id", title: "ID" },
    //     { id: "applicationName", title: "Application Name" },
    //     { id: "status", title: "Status" },
    //     { id: "campaign", title: "Campaign" },
    //     { id: "goal", title: "Goal" },
    //     { id: "dailyTarget", title: "Daily Target" },
    //     { id: "impressions", title: "Impressions" },
    //     { id: "clicks", title: "Clicks" },
    //     { id: "installs", title: "Installs" },
    //   ],
    // });
    // console.log(data);
    // csvWriter
    //   .writeRecords(data)
    //   .then(() => {
    //     console.log("CSV data was exported successfully");
    //   })
    //   .catch((error) => {
    //     console.log("CSV data was not exported", error);
    //   });
  }

  const downloadExcel = () => {
    if (tableData.length != 0) {
      const worksheet = XLSX.utils.json_to_sheet(tableData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const dataBlob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(dataBlob, "Campaign Report.xlsx"); // Specify the desired file name here
    } else {
      alert("Download data is not available");
    }
  };

  const fileDownload = (type: string) => {
    if (type == "doc") {
      exportToCSV(tableData);
    } else {
      downloadExcel();
    }
  };
  // const cancelStatus = () => {
  //   setToggleStatusInput(!toggleStatusInput);
  // };

  const closeDropdown = (type: string) => {
    if ((type = "dwn")) {
      setIsOpen(false);
    } else {
      setIsOpenEdit(false);
    }
  };

  const dataToFilter = (data: string) => {
    // console.log(data);
    if (data == "expired") {
      const filteredData = tableData.filter((item) => item.isChecked !== true);
      setTableData(filteredData);
      const updatedData = tableData.map((item) => {
        if (item.isChecked) {
          return { ...item, status: data };
        }
        return item;
      });
      const filtered = updatedData.filter((item) => item.isChecked == true);
      // console.log(filtered);
      if (filtered.length != 0) {
        UpdateCampaignApi(filtered);
      }
    } else {
      const updatedData = tableData.map((item) => {
        if (item.isChecked) {
          return { ...item, status: data };
        }
        return item;
      });
      const filtered = updatedData.filter((item) => item.isChecked == true);
      // console.log(filtered);
      if (filtered.length != 0) {
        UpdateCampaignApi(filtered);
      }
      setTableData(updatedData);
    }
  };

  const onClickNavigate = (id: string) => {
    // console.log(tableData)
    const filteredData = tableData.filter((value) => {
      return value.campaignId == id;
    });
    // console.log(filteredData);
    localStorage.setItem("editingData", "");
    localStorage.setItem("campaignRecordData", JSON.stringify(filteredData));
    window.location.replace(`/home/campaigns/campaignsRecord?id=${id}`);
  };

  const handleCheckboxClick = (id: string) => {
    const UpdatedRow = tableData.map((item) => {
      if (item.id == id) {
        return { ...item, isChecked: !item.isChecked };
      }
      return item;
    });
    let itemCheck = UpdatedRow.map((item) => {
      return { ...item, isChecked: item.id === id };
    });
    const iconcheck = itemCheck.filter((item) => {
      return item.isChecked != true;
    });

    if (itemCheck.length !== iconcheck.length) {
      setShowEdit(true);
    } else {
      setShowEdit(false);
    }
    setTableData(itemCheck);
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    } else {
      setSelectedRows([id]);
    }
  };

  const [selectedRange, setSelectedRange] = useState<any>({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 2, 1),
    endDate: new Date(),
  });

  useEffect(() => {
    // const adId = localStorage.getItem("advertiserID") || ""
    // const item = localStorage.setItem("editingData", "");
    // const startDate = localStorage.getItem("startDate")!;
    // const endDate = localStorage.getItem("endDate")!;
    // if (startDate != "" && endDate != "") {
    //   const s = new Date(startDate);
    //   const e = new Date(endDate);
    //   setSelectedStartDate(s);
    //   setSelectedEndDate(e);
    //   console.log("here", startDate, endDate);
    //   getDetailsCampaign(s, e);
    // } else {
    //   if (dates != null) {
    //     const s = new Date(dates.startDate);
    //     const e = new Date(dates.endDate);
    //     setSelectedStartDate(s);
    //     setSelectedEndDate(e);
    //     console.log(dates);
    //     console.log("here 2", s, e);
    //     getDetailsCampaign(s, e);
    //   }
    // }

    if (selectedDateRange.endDate != "" && selectedDateRange.startDate != "") {
      const s = new Date(selectedDateRange.startDate);
      const e = new Date(selectedDateRange.endDate);
      // setSelectedStartDate(s);
      // setSelectedEndDate(e);
      setSelectedRange({
        startDate: s,
        endDate: e,
      });
    }
  }, [selectedDateRange]);

  useEffect(() => {
    // console.log("useEffect Wroking")
    if (dates != null) {
      const s = new Date(dates.startDate);
      const e = new Date(dates.endDate);
      setSelectedStartDate(s);
      setSelectedEndDate(e);

      // getDetailsCampaign(s, e);
    }
  }, [dates]);

  // useEffect(() => {
  //   getDetailsCampaign(selectedRange.startDate, selectedRange.endDate);
  // }, [selectedRange]);

  const NewCampaign = () => {
    dispatch(setCount(0));
    dispatch(setStatus("pending"));
    dispatch(setCampaignSubType(""));
    dispatch(setAppPlatform(""));
    dispatch(setAppName(""));
    dispatch(setCampaignName(""));
    dispatch(setAppImageUrl(""));
    dispatch(setPackageName(""));

    //CAMPAIGN SETTING

    // localStorage.setItem("locationValue", "");
    // localStorage.setItem("selectedLang", "");
    // localStorage.setItem("appLookup", "");
    // localStorage.setItem("trackingPartner", "");
    // localStorage.setItem("finalClickURL", "");
    // localStorage.setItem("trackingUrl", "");
    // localStorage.setItem("inputTokenEventData", "");
    // localStorage.setItem("packageName", "");
    // localStorage.setItem("appIcon", "");
    // localStorage.setItem("locationCode", "");
    // localStorage.setItem("locationValue", "");
    dispatch(setLanguage([]));
    dispatch(setLocation(""));
    dispatch(setTracking(""));
    dispatch(setTrackingUrl(""));
    dispatch(setImpressionUrl(""));
    // dispatch(
    //   setTokenEvent([
    //     { token: "", eventName: "" },
    //     // other elements...
    //   ])
    // );
    // console.log('tokenEvent', tokenEvent)
    if (tokenEvent.length > 0) {
      tokenEvent.map((item, index) => {
        dispatch(updateToken(index, ""));
        dispatch(updateEvent(index, ""));
      });
    }

    // dispatch(addTokenEventObject(''))
    // dispatch(removeTokenEventObject(''))
    dispatch(setLocationCode(""));

    // BUDGET BIDDING
    dispatch(setBudget(""));
    dispatch(setFocus(""));
    dispatch(setTarget(""));
    dispatch(setCostPerInstall(""));

    // localStorage.setItem("budget", "");
    // localStorage.setItem("focusDpd", "");
    // localStorage.setItem("trackDpd", "");
    // localStorage.setItem("costPerInstall", "");
    // localStorage.setItem("targetApp", "");

    //AD GROUP

    // localStorage.setItem("adGrpName", "");
    // localStorage.setItem("htmlUrlArray", `[]`);
    // localStorage.setItem("imageUrlArray", `[""]`);
    // localStorage.setItem("videoUrlArray", `[]`);
    // localStorage.setItem("headlines", `[]`);
    // localStorage.setItem("description", `[]`);
    // localStorage.setItem("logoUrl", "");
    // localStorage.setItem("campaignRecordData", "");

    dispatch(setAdGroupName(""));
    dispatch(setLogoUrl(""));
    dispatch(setHeadline([]));
    dispatch(setDescription([]));
    dispatch(setImageUrl([]));
    dispatch(setVideoUrl([]));
    dispatch(setHtmlUrl([]));
    dispatch(setImageFile(""));
    // const emptyFile = new File([], 'empty.txt', { type: 'text/plain' });
    dispatch(setVideoFile([]));
    dispatch(setHtmlFile([]));

    dispatch(setCampaignId(""));
    dispatch(setBudgetId(""));
    localStorage.setItem("editingData", "");
    window.location.replace("/home/campaigns/createCampaign");
  };
  const adId = localStorage.getItem("advertiserID") || "";

  return (
    <>
      <div>
        <div className={shouldHideDiv ? "empty" : "fixed-position"}>
          <div style={divStyle}>
            <PurpleCustomButton
              onClick={NewCampaign}
              disable={true}
              children={
                <div className="button-div">
                  <div className="plus">+</div>&nbsp;&nbsp;New Campaign
                </div>
              }
            />
           <div ref={boxRef} className="box" >
        <div style={{ display: "flex", alignItems: "center", position: "relative", }}>
          <h5 style={{ fontWeight: 800, fontSize: "14px", marginRight: "8px" }}>
            Custom
          </h5>
          <div className="calender-btn" onClick={toggleCalender} >
            <img src={calender} alt="calendar" />
            <p>
              {changedDate.startDate.toLocaleDateString("default", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })}
            </p>
            -
            <p>
              {changedDate.endDate.toLocaleDateString("default", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })}
            </p>
          </div>
        </div>
        <div style={{ display: `${toggleCal}`, position: "absolute", right: "32%"  }}>
          <DateRangePicker
            ranges={[changedDate]}
            onChange={handleSelect}
            maxDate={new Date()}
          />
          <div className="button-container">
            <button
              className="purple-btn width-adjust"
              onClick={doneClose}
              style={{
                margin: "0 15px",
                cursor: "pointer",
              }}
            >
              Done
            </button>
            <button className="border-btn" onClick={toggleCalender}>
              Close
            </button>
          </div>
        </div>
      </div>
          </div>
          <div
            className="top-box"
            style={{ display: dataContains ? "flex" : "flex" }}

            // style={divMarginStyle}
          >
            <div className="center-align">
              <img src={sound} />
              <h3>{heading}</h3>
            </div>
            <div className="center-align">
              {isadmin ? (
                <DateCustomPicker
                // onDateChange={handleDateChange}
                // onClick={onDateSearch}
                />
              ) : (
                <div className="center-align" style={{ marginRight: "10px" }}>
                  <input
                    className="input-search"
                    type="text"
                    value={searchValue}
                    onChange={toggleSearch}
                  />
                  <IoSearchOutline
                    className="search-icon"
                    style={{ right: "8px" }}
                  />
                </div>
              )}

              {shouldHideDiv ? (
                isadmin ? (
                  <div></div>
                ) : (
                  <div
                    className="sub-heading"
                    onClick={() => navigate("/home/campaigns")}
                  >
                    All Campaigns
                  </div>
                )
              ) : (
                <div>
                  {showEdit ? (
                    <div className="dropdown">
                      <button
                        className="center-align btn-dwn dropdown-button"
                        onClick={() => toggleDropdown("edit")}
                        style={{ width: "75px" }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        Edit
                        <img src={edit} className="dwn-icon" />
                      </button>
                      {/* {isOpenEdit && (
                      <ul
                        className="dropdown-menu"
                        // onClick={() => closeDropdown("edit")}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        {EditArray.map((item) => {
                          return (
                            <li onClick={() => dataToFilter(item.name)}>
                              <div className="status-div">
                                <img src={item.image} />
                                <p className={item.className}>
                                  {item.name == "disabled"
                                    ? "stopped"
                                    : item.name == "expired"
                                    ? "delete"
                                    : item.name}
                                </p>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    )} */}
                    </div>
                  ) : null}
                </div>
              )}
              {/*                 Edit
                <img src={edit} className="dwn-icon" />
              </button>
              {isOpenEdit && (
                <ul
                  className="dropdown-menu"
                  onClick={() => closeDropdown("edit")}
                >
                  {EditArray.map((item) => {
                    return (
                      <li onClick={() => dataToFilter(item.name)}>
                        <div className="status-div">
                          <img src={item.image} />
                          <p className={item.className}>{item.name}</p>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>} */}

              <div
                className="dropdown"
                style={{ marginRight: "10px", marginLeft: "10px" }}
              >
                <button
                  className="center-align btn-dwn dropdown-button"
                  // onClick={() => toggleDropdown("dwn")}
                  onMouseEnter={handleMouseEnterDownload}
                  onMouseLeave={handleMouseLeaveDownload}
                >
                  Download
                  <LuDownload className="dwn-icon" />
                </button>
                {isOpen && (
                  <ul
                    className="dropdown-menu"
                    // onClick={() => closeDropdown("dwn")}
                    onMouseEnter={handleMouseEnterDownload}
                    onMouseLeave={handleMouseLeaveDownload}
                  >
                    {/* <li onClick={() => fileDownload("doc")}>
                    Document (.csv) file
                  </li> */}
                    <li>
                      {tableData.length == 0 ? (
                        <li
                          onClick={() => {
                            alert("Download data is not available");
                          }}
                        >
                          Document (.csv) file
                        </li>
                      ) : (
                        <CSVLink data={csvData} filename="Campaign Report">
                          Document (.csv) file
                        </CSVLink>
                      )}
                    </li>
                    <li onClick={() => fileDownload("xlsx")}>
                      Excel (.xlsx) file
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="campaigns-box">
          {tableData.length != 0 ? (
            <TableContainer
              component={Paper}
              style={{ marginTop: shouldHideDiv ? "0" : "125px" }}
            >
              <Table style={{ display: dataContains ? "table" : "none" }}>
                <TableHead>
                  <TableRow>
                    {shouldHideDiv ? null : <TableCell></TableCell>}
                    <TableCell
                      style={{
                        fontWeight: 900,
                        color: "#6900b8",
                      }}
                    >
                      Campaign Id
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: 900,
                        color: "#6900b8",
                      }}
                    >
                      Application Name
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: 900,
                        color: "#6900b8",
                      }}
                    >
                      <TableSortLabel
                        direction={
                          sortConfig.key === "status"
                            ? sortConfig.direction
                            : "asc"
                        }
                        active={sortConfig.key === "status"}
                        onClick={() => requestSort("status")}
                        IconComponent={KeyboardArrowDown}
                        style={{
                          fontWeight: 900,
                          color: "#6900b8",
                        }}
                      >
                        Status
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: 900,
                        color: "#6900b8",
                      }}
                    >
                      Campaign
                    </TableCell>
                    {/* <TableCell>Goal</TableCell> */}
                    <TableCell
                      style={{
                        fontWeight: 900,
                        color: "#6900b8",
                      }}
                    >
                      {/* <div></div> */}
                      <TableSortLabel
                        style={{
                          fontWeight: 900,
                          color: "#6900b8",
                        }}
                        direction={
                          sortConfig.key === "dailyTarget"
                            ? sortConfig.direction
                            : "asc"
                        }
                        active={sortConfig.key === "dailyTarget"}
                        onClick={() => requestSort("dailyTarget")}
                        IconComponent={KeyboardArrowDown}
                      >
                        Daily Target
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: 900,
                        color: "#6900b8",
                      }}
                    >
                      <TableSortLabel
                        style={{
                          fontWeight: 900,
                          color: "#6900b8",
                        }}
                        direction={
                          sortConfig.key === "impression"
                            ? sortConfig.direction
                            : "asc"
                        }
                        active={sortConfig.key === "impression"}
                        onClick={() => requestSort("impression")}
                        IconComponent={KeyboardArrowDown}
                      >
                        Impressions
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: 900,
                        color: "#6900b8",
                      }}
                    >
                      <TableSortLabel
                        direction={
                          sortConfig.key === "clicks"
                            ? sortConfig.direction
                            : "asc"
                        }
                        active={sortConfig.key === "clicks"}
                        onClick={() => requestSort("clicks")}
                        IconComponent={KeyboardArrowDown}
                        style={{
                          fontWeight: 900,
                          color: "#6900b8",
                        }}
                      >
                        Clicks
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: 900,
                        color: "#6900b8",
                      }}
                    >
                      <TableSortLabel
                        direction={
                          sortConfig.key === "cpi"
                            ? sortConfig.direction
                            : "asc"
                        }
                        active={sortConfig.key === "cpi"}
                        onClick={() => requestSort("cpi")}
                        IconComponent={KeyboardArrowDown}
                        style={{
                          fontWeight: 900,
                          color: "#6900b8",
                        }}
                      >
                        CPI
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: 900,
                        color: "#6900b8",
                      }}
                    >
                      <TableSortLabel
                        direction={
                          sortConfig.key === "installs"
                            ? sortConfig.direction
                            : "asc"
                        }
                        active={sortConfig.key === "installs"}
                        onClick={() => requestSort("installs")}
                        IconComponent={KeyboardArrowDown}
                        style={{
                          fontWeight: 900,
                          color: "#6900b8",
                        }}
                      >
                        Installs
                      </TableSortLabel>
                    </TableCell>
                    {/* <TableCell>Installs</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    // tableData
                    //   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    //   .map((row) =>
                    //   (

                    tableData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map(
                        (row: any, index: any) => (
                          <TableRow
                            key={row.id}
                            style={{
                              backgroundColor: row.isChecked
                                ? "#f0e6f8"
                                : "transparent",
                            }}
                          >
                            {shouldHideDiv ? null : (
                              <TableCell>
                                <CustomCheck
                                  {...label}
                                  style={{ color: '#6900b8' }}
                                  checked={selectedRows.includes(row.id)}
                                  onChange={() => handleCheckboxClick(row.id)}
                                  className="check-campaign"
                                />
                              </TableCell>
                            )}
                            <TableCell>
                              <div
                                style={{
                                  // width: "251px",
                                  whiteSpace: "nowrap",
                                  overflow: "visible",
                                  // textOverflow: "ellipsis",
                                }}
                              >
                                {row.id}
                              </div>
                            </TableCell>

                            <TableCell>
                              <div
                                className="application-box"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  shouldHideDiv
                                    ? isadmin
                                      ? window.location.replace(
                                          "/home/admin/campaigns"
                                        )
                                      : window.location.replace(
                                          "home/campaigns"
                                        )
                                    : onClickNavigate(row.campaignId);
                                }}
                              >
                                {/* {shouldHideDiv ? null : <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>} */}
                                {/* <img src={row.img} /> */}
                                {row.applicationName}
                              </div>
                            </TableCell>

                            <TableCell>
                              <div
                                onMouseEnter={() =>
                                  handleStatusMouseEnter(row.id)
                                }
                                onMouseLeave={() =>
                                  handleStatusMouseLeave(row.id)
                                }
                              >
                                {row.status != "" ? (
                                  <div style={{ position: "relative" }}>
                                    {row.id === statusDpd &&
                                    toggleStatusInput ? (
                                      <ul
                                        className="dropdown-menu"
                                        style={{ zIndex: 1 }}
                                      >
                                        <div
                                          // className="input-box"
                                          style={{
                                            background: "white",
                                            display: "flex",
                                            justifyContent: "flex-end",
                                          }}
                                        >
                                          <div
                                            className="done-div"
                                            onClick={() => saveStatus(row.id)}
                                          >
                                            <img src={done} />
                                            <p>Done</p>
                                          </div>
                                          <div
                                            className="cancel-div"
                                            onClick={cancelStatus}
                                          >
                                            <img src={cancel} />
                                            <p>Cancel</p>
                                          </div>
                                        </div>
                                        {EditStatusArray.map((item) => (
                                          <li
                                            key={item.name}
                                            onClick={() =>
                                              toggleStatus(row.id, item.name)
                                            }
                                            style={{
                                              background:
                                                item.name == saveStatusSelected
                                                  ? "#ebebeb"
                                                  : "#ffffff",
                                            }}
                                          >
                                            <div className="status-div">
                                              <img src={item.image} />
                                              <p className={item.className}>
                                                {item.name == "disabled"
                                                  ? "Stopped"
                                                  : item.name
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                    item.name.slice(1)}
                                              </p>
                                            </div>
                                          </li>
                                        ))}
                                      </ul>
                                    ) : (
                                      <div className="status-div">
                                        <img
                                          src={
                                            row.campaignData.Status === "active"
                                              ? active
                                              : row.campaignData.Status ===
                                                "paused"
                                              ? paused
                                              : row.campaignData.Status ===
                                                "pending"
                                              ? pending
                                              : stopped
                                          }
                                          style={{
                                            width: "18px",
                                            height: "18px",
                                          }}
                                        />
                                        <p
                                          className={
                                            row.campaignData.Status === "active"
                                              ? "active-para"
                                              : row.campaignData.Status ===
                                                "paused"
                                              ? "paused-para"
                                              : row.campaignData.Status ===
                                                "pending"
                                              ? "pending-para"
                                              : "stopped-para"
                                          }
                                        >
                                          {row.campaignData.Status == "disabled"
                                            ? "Stopped"
                                            : row.campaignData.Status.charAt(
                                                0
                                              ).toUpperCase() +
                                              row.campaignData.Status.slice(1)}
                                        </p>
                                        {row.id == isHoverStatus ? (
                                          <img
                                            onClick={() =>
                                              toggleStatusButton(row.id)
                                            }
                                            src={edit}
                                            style={{
                                              marginLeft: "10px",
                                              cursor: "pointer",
                                            }}
                                          />
                                        ) : null}
                                      </div>
                                    )}
                                  </div>
                                ) : null}
                              </div>
                            </TableCell>

                            <TableCell>{row.campaignName}</TableCell>
                            {/* <TableCell>{row.goal}</TableCell> */}
                            <TableCell>
                              {Object.keys(row.budgetData).length !== 0
                                ? row.budgetData.cpiCurrency == "INR"
                                  ? "₹"
                                  : "$"
                                : "$"}{" "}
                              {row.dailyTarget == "" ? 0 :row.dailyTarget}
                            </TableCell>
                            <TableCell>{row.impressions}</TableCell>
                            <TableCell>{row.clicks}</TableCell>
                            <TableCell>
                              {Object.keys(row.budgetData).length !== 0
                                ? row.budgetData.cpiCurrency == "INR"
                                  ? "₹"
                                  : "$"
                                : "$"}
                              {row.cpi}
                            </TableCell>
                            <TableCell>{row.installs}</TableCell>
                            {/* <TableCell>{row.installs}</TableCell> */}
                          </TableRow>
                        )
                        // ))
                      )
                  }
                  {/* <TableRow >
            <TableCell className="table-cel"></TableCell>
            <TableCell className="table-cel">Application Name</TableCell>
            <TableCell className="table-cel">Status</TableCell>
            <TableCell className="table-cel">Campaign</TableCell>
            <TableCell className="table-cel">Goal</TableCell>
            <TableCell className="table-cel">Daily Target</TableCell>
            <TableCell className="table-cel">Impressions</TableCell>
            <TableCell className="table-cel">Clicks</TableCell>
            <TableCell className="table-cel">Installs</TableCell>
          </TableRow> */}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={tableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          ) : (
            <div>
              {dataComing ? (
                <div
                  style={{
                    display: dataComing ? "flex" : "none",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: shouldHideDiv ? "50px" : "150px",
                  }}
                >
                  <CustomLoader />
                  {/* <CircularProgress color="secondary" /> */}
                </div>
              ) : (
                <h3 style={{ textAlign: "center", fontSize: "14px" }}>
                  No campaign data available for the range selected.
                </h3>
              )}
            </div>
          )}
        </div>
        {/* </div> */}
      </div>
    </>
  );
};
const mapStateToProps = (state: RootState) => ({
  count: state.counter.count,
  campaignSubtype: state.user.campaignSubtype,
  appPlatform: state.user.appPlatform,
  appName: state.user.appName,
  campaignName: state.user.campaignName,
  appImageUrl: state.user.appImageUrl,
  packageName: state.user.packageName,

  location: state.user.location,
  language: state.user.language,
  tracking: state.user.tracking,
  trackingUrl: state.user.trackingUrl,
  impressionUrl: state.user.impressionUrl,
  tokenEvent: state.user.tokenEvent,
  locationCode: state.user.locationCode,

  budget: state.user.budget,
  focus: state.user.focus,
  target: state.user.target,
  costPerInstall: state.user.costPerInstall,

  adGroupName: state.user.adGroupName,
  logoUrl: state.user.logoUrl,
  headline: state.user.headline,
  description: state.user.description,
  imageUrl: state.user.imageUrl,
  videoUrl: state.user.videoUrl,
  htmlUrl: state.user.htmlUrl,

  budget_Id: state.user.budget_Id,
  campaign_Id: state.user.campaign_Id,

  status: state.user.status,
  authToken: state.user.authToken,
  sessionId: state.user.sessionId,
  advertiserId: state.user.advertiserId,
  selectedAdvertiserId: state.user.selectedAdvertiserId,

  selectedDateRange: state.user.selectedDateRange,
});

const mapDispatchToProps = {
  setCount,
  setStatus,
  setCampaignSubType,
  setAppPlatform,
  setAppName,
  setCampaignName,
  setAppImageUrl,
  setPackageName,

  setLanguage,
  setLocation,
  setTracking,
  setTrackingUrl,
  setImpressionUrl,
  setTokenEvent,
  addTokenEventObject,
  removeTokenEventObject,
  updateToken,
  updateEvent,
  setLocationCode,

  setBudget,
  setFocus,
  setTarget,
  setCostPerInstall,

  setAdGroupName,
  setLogoUrl,
  setHeadline,
  setDescription,
  setImageUrl,
  setVideoUrl,
  setHtmlUrl,
  setLogoError,
  setImageUploadError,
  setCampaignId,
  setBudgetId,
  setLocationCheck,
  setAdvertiserId,
  setAuthToken,
  setSessionId,

  setDataSource,
  setSegmentName,
  setDataType,
  setDataUpload,
  setUploadedUrl,

  setDataSourceError,
  setSegmentNameError,
  setDataTypeError,
  setDataUploadError,
  setUploadedUrlError,
  setSelectedAdvertiserId,

  setSelectedDateRange,
  setTempTransactionCurrency
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignMainScreen);
