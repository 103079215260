import React from 'react';
import { IoWallet } from 'react-icons/io5';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { PurpleCustomButton } from '../custom/PurpleButton';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../CSS/payments.css';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Modal, Typography } from '@mui/material';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    height: "40%",
    bgcolor: 'background.paper',
    border: '1px solid #6800b8',
    borderRadius: "3px",
    boxShadow: 24,
    p: 2,
};

const PaymentSuccessScreen = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const currencySymbol = location.state.currency;
    const amount = location.state.amount;
    const paymentType = location.state.paymentType;
    const Details = location.state.transactionDetails;
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const capitalizeFirstLetter = (str: string) => {
        if (str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }
        return "";
       
    };

    return (
        <div>
            <div className='main-heading'><IoWallet style={{ color: "#6900B8", marginRight: 5 }} /><div>Add Funds</div></div>
            <div className="white-box" style={{ width: "96%", padding: "20px", height: "140px", display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>
                <div style={{ display: "flex", alignItems: "center", color: "#55A47E", fontWeight: "700" }}><CheckCircleIcon style={{ marginRight: "10px" }} />Payment successful</div>
                <div style={{ color: "#6900B8", fontSize: "22px", fontWeight: "900" }}>Thank You</div>
                {
                    paymentType == "stripe" ?
                        <div style={{ fontWeight: "700" }}>{currencySymbol} {amount} have been added to your account successfully </div>
                        :
                        <div style={{ fontWeight: "700" }}>Receipt uploaded successfully!</div>
                }
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
                {
                    paymentType == "stripe" ?
                        <div style={{ display: "flex", fontSize: "11px", color: "rgba(51, 51, 51, 0.5)", fontWeight: "700" }}>Transaction ID: {Details['TransactionId']} &nbsp;
                            <div style={{ cursor: "pointer", color: "#6900B8", textDecorationLine: "underline" }} onClick={handleOpen}>View Details</div>
                        </div>
                        : <div></div>
                }
                <Modal
                    open={open}
                    // onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={style}
                >
                    <Box sx={{
                        border: "none",
                        outline: "none"
                    }}>
                        <div style={{
                            float: "right",
                        }}>
                            <button className='close-btn' onClick={handleClose}>
                                <CloseIcon />Close
                            </button>
                        </div>
                        <Typography id="modal-modal-title">
                            <div style={{
                                fontSize: "16px",
                                color: "#6800b8",
                                fontWeight: 600,
                            }}> Transaction Details</div>
                        </Typography>
                        <Typography id="modal-modal-description" className='list-style'>
                            {Details.length == 0 ? "" :
                                <ul>
                                    <li><div className='list-item-style'>Transaction ID : </div>&nbsp;{Details["TransactionId"]}</li>
                                    <li><div className='list-item-style'>Transaction Date : </div>&nbsp; {Details["TransactionDate"]}</li>
                                    <li><div className='list-item-style'>Invoice ID : </div>&nbsp;{Details["InvoiceID"]}</li>
                                    <li><div className='list-item-style'>Transaction Type : </div>&nbsp;{capitalizeFirstLetter(Details["TransactionType"])}</li>
                                    <li><div className='list-item-style'>Amount : </div>&nbsp;{Details["Amount"]}</li>
                                    <li><div className='list-item-style'>Transaction Status : </div>&nbsp;{capitalizeFirstLetter(Details["TransactionStatus"])}</li>
                                    <li><div className='list-item-style'>Balance : </div>&nbsp;{Details["BalanceAmount"]}</li>
                                    <li><div className='list-item-style'>Description : </div>&nbsp;{capitalizeFirstLetter(Details["Discription"])}</li>
                                </ul>
                            }
                        </Typography>
                    </Box>
                </Modal>
                <PurpleCustomButton children="Go to Dashboard" disable={true} onClick={() => navigate('/home')} />
            </div>
        </div>
    );
}

export default PaymentSuccessScreen;