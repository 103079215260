import { Checkbox, ListItemText, Menu, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React, { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import AdvertiserIcon from '../../../IMAGE/advertisers.svg'
import AdvertiserTable from 'react-data-table-component'
import '../../../CSS/homePage.css'
import { PurpleCustomButton } from "../../custom/PurpleButton";
import AddIcon from '@mui/icons-material/Add';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DateCustomPicker from "../../custom/DateCustomPicker";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CSVLink } from "react-csv";
import saveAs from "file-saver";
import * as XLSX from 'xlsx';
import axios, { all } from "axios";
import CreateProfileIcon from '../../../IMAGE/createProfile.svg';
import { apiURL, customCheckBoxColor, secretKey } from "../../../Constant";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { RootState } from "../../../REDUX/Reducers/rootReducer";
import { setAdvertiserId, setAdvertiserScreenTable, setAdvertisersList, setAuthToken, setCurrencySymbol, setMasterTargetingTypesArray, setSelectedAdvertiserId, setSelectedDateRange, setSessionId } from "../../../REDUX/Actions/userActionIndex";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import CustomLoader from "../../custom/CustomLoader";
const CryptoJS = require('crypto-js')

interface AdvertiserScreenProps {
    currencySymbol: any;
    authToken: any;
    sessionId: any;
    advertiserId: any;
    mastertargetingTypesArray: any;
    selectedAdvertiserId: any;
    selectedDateRange: any;
    advertisersList: any;
    advertiserScreenTable: any[];
}

const CustomSelect = styled(Select)(({ theme }) => ({
    backgroundColor: "white",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontWeight: "600",
    width: "90%",
    height: "45px",
    borderRadius: "2px",
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
    {
        border: "1px solid black",
    },
}));

const AdvertiserScreen: FC<AdvertiserScreenProps> = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { currencySymbol, authToken, sessionId, advertiserId, mastertargetingTypesArray, selectedAdvertiserId, selectedDateRange, advertisersList, advertiserScreenTable } = props
    const [advertiserID, setAdvertiserID] = useState<any>(advertiserId)
    const [sessionID, setSessionID] = useState<any>(sessionId)
    const [authTokenID, setAuthTokenID] = useState<any>(authToken)
    const [loaderActive, setLoaderActive] = useState<boolean>(false)
    const categoryArray: any = []
    const [categorySearch, setCategorySearch] = useState<any>('')

    JSON.parse(mastertargetingTypesArray).forEach((element: any) => {
        let item = element.TargetingType;
        if (!categoryArray.includes(item)) {
            categoryArray.push(item)
        }
    });

    const formatChangeDate = (dateParam: any) => {
        const inputDateString = dateParam;
        const date = new Date(inputDateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate
    }

    const [advertisersData, setAdvertisersData] = useState<any[]>([])

    const getAllAdvertisersDetails = async () => {
        try {
            setLoaderActive(true)

            const encryptAdvertiserID = CryptoJS.AES.encrypt(JSON.stringify(advertiserID), secretKey).toString()
            let headers = {
                'Content-Type': 'application/json',
                'advertiserid': encryptAdvertiserID,
                'authtoken': authTokenID,
                'sessionid': sessionID
            };
            let jsonParams = { startDate: formatChangeDate(selectedRange.startDate), endDate: formatChangeDate(selectedRange.endDate) };
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(jsonParams), secretKey).toString();

            const response = await axios.post(`${apiURL}/api/advertisers/getAllAdvertisers`, { params: encryptedData }, { headers: headers });
            if (response.data.status == true) {
                const resData = response.data.data;
                const bytes = CryptoJS.AES.decrypt(resData, secretKey);
                const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
                const parsedData = JSON.parse(decryptedData);
                const resDataArray: any = []
                for (const obj of parsedData) {
                    // if (!advertiserArray.includes(obj.name)) {
                    //     advertiserArray.push(obj.name)
                    // }

                    let img = ''

                    if (obj.advertiserProfile == null) {
                        // setImage("")
                        img = ''
                    } else {
                        const uint8Array = new Uint8Array(obj.advertiserProfile['data']);
                        const blob = new Blob([uint8Array]);
                        const dataUrl = URL.createObjectURL(blob);
                        img = dataUrl;
                        // const blob2 = new Blob([Buffer.from(obj.advertiserProfile['data'])], { type: 'application/octet-stream' });
                        // setSelectedFile(blob2)
                    }

                    resDataArray.push({
                        orgName: obj.orgName,
                        name: obj.name,
                        userType: obj.userType,
                        campaignsCount: obj.totalCampaigns[0]['TotalCampaigns'],
                        totalCampaigns: obj.totalCampaigns,
                        totalInstalls: obj.totalInstalls,
                        totalBudget: obj.totalBudget,
                        totalCredit: obj.totalCredit,
                        totalSpends: obj.totalSpends,
                        lastLoginDate: obj.lastLoginDate,
                        advertiserId: obj.advertiserId,
                        campaignData: obj.campaignData,
                        image: img,
                        country: obj.country,
                        id: obj.id,
                        createdDate: obj.createdDate
                    });
                }
                setAdvertisersData(resDataArray)
                setFilteredAdvertisersData(resDataArray)
                setLoaderActive(false)
                dispatch(setAdvertiserScreenTable(resDataArray))

            } else if (response.data.statuscode == 401) {
                // setOpenSession(true)
                setTimeout(() => { navigate("/") }, 1000)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const [filtersAdvertisersData, setFilteredAdvertisersData] = useState<any[]>(advertisersData)
    const [downloadData, setDownloadData] = useState<any[]>([])

    const formatDate = (dateString: any) => {
        const dateObject = new Date(dateString);

        // Format the date
        const options: any = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZone: 'UTC' // Specify the timezone if needed
        };
        const formattedDate = dateObject.toLocaleString('en-US', options);
        return formattedDate;
    }

    const capitalizeFirstLetter = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const cols: any = [
        {
            id: 'name',
            name: 'Account Manager',
            selector: (row: any) =>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                }} onClick={(e: any) => onRowClickFunc(row, e)}> {<img src={row.image != "" ? row.image : CreateProfileIcon} height="35px" width="35px" style={{ borderRadius: 6, marginRight: 15 }} />}{row.name}</div>,
            // row.name,
            width: "210px",
            style: {
                borderRight: "1.4px solid #cccccc",
                fontWeight: 750,
            },
            wrap: true
        },
        {
            id: 'userType',
            name: 'Brand / Agency',
            selector: (row: any) => capitalizeFirstLetter(row.userType),
            width: "160px"
        },
        {
            id: 'orgName',
            name: 'Organization',
            selector: (row: any) => row.orgName,
            width: "135px"
        },
        {
            id: 'campaignsCount',
            name: 'Campaigns',
            selector: (row: any) => Number(row.campaignsCount),
            width: "145px",
            sortable: true
        },
        {
            id: 'totalBudget',
            name: 'Total Budget',
            selector: (row: any) => Number(row.totalBudget),
            width: "155px",
            sortable: true
        },
        {
            id: 'totalSpends',
            name: 'Total Spends',
            selector: (row: any) => Number(row.totalSpends),
            width: "155px",
            sortable: true
        },
        {
            id: 'totalCredit',
            name: 'Total Credit',
            selector: (row: any) => Number(row.totalCredit),
            width: "145px",
            sortable: true
        },
        {
            id: 'totalInstalls',
            name: 'Installs',
            selector: (row: any) => Number(row.totalInstalls),
            width: "115px",
            sortable: true
        },
        {
            id: 'lastLoginDate',
            name: 'Last Login',
            selector: (row: any) => row.lastLoginDate == "" || row.lastLoginDate == null ? "" : formatDate(row.lastLoginDate),
            width: "230px"
        }
    ]

    const [advertiserArray, setAdvertisersArray] = useState<any[]>([])

    // const [selectAll, setSelectAll] = useState<boolean>(true);

    // const handleToggleAll = () => {
    //     const allValues = advertiserArray.map((option: any) => option);
    //     if (selectAll) { 
    //         setAdvertiserNames(allValues);
    //         setSelectAll(true);
    //         handleChange(advertiserArray)
    //     } else {
    //         setAdvertiserNames([]);      
    //               setSelectAll(false);
    //     }
    // };

    const [filters, setFilters] = useState<any>({
        advertiser: [],
        category: ""
    });

    const handleFilterChange = (filterType: any, value: any) => {
        setFilters((prevFilters: any) => ({
            ...prevFilters,
            [filterType]: value,
        }));
    };

    const getfilteredData = () => {
        // const result = advertisersData.filter((advertiser) => {
        //     return (filters.advertiser.length == 0 || filters.advertiser.includes(advertiser.name));
        // })

        const result = advertisersData.filter((advertiser) => {

            if (selectedDateRange.endDate != "" && selectedDateRange.startDate != "" && advertiser.createdDate != "" && advertiser.createdDate != null) {
                const createdDate = new Date(advertiser.createdDate);
                const s = new Date(selectedDateRange.startDate);
                const e = new Date(selectedDateRange.endDate);

                const isWithinDateRange = createdDate >= s && createdDate <= e;
                const isAdvertiserIncluded = filters.advertiser.length === 0 || filters.advertiser.includes(advertiser.name);

                return isWithinDateRange && isAdvertiserIncluded;
            } else {
                return (filters.advertiser.length == 0 || filters.advertiser.includes(advertiser.name));
            }
        });

        setFilteredAdvertisersData(result)

        const resDataArray: any = []
        if (filters.category != "") {
            for (const obj of result) {
                const campaignObj = obj.campaignData;
                let campaignCount = 0;
                if (campaignObj.length != 0) {

                    campaignObj.forEach((element: any) => {
                        const budgetObj = element.budgetData;

                        const categoryName = budgetObj['targetingType'];
                        if (categoryName != undefined && categoryName == filters.category) {
                            campaignCount = campaignCount + 1;
                        }
                    })
                }

                let img = ""

                if (obj.advertiserProfile != null) {
                    const uint8Array = new Uint8Array(obj.advertiserProfile['data']);
                    const blob = new Blob([uint8Array]);
                    const dataUrl = URL.createObjectURL(blob);
                    img = dataUrl;
                    // const blob2 = new Blob([Buffer.from(obj.advertiserProfile['data'])], { type: 'application/octet-stream' });
                    // setSelectedFile(blob2)
                } else {
                    img = ""
                }

                resDataArray.push({
                    orgName: obj.orgName,
                    name: obj.name,
                    userType: obj.userType,
                    campaignsCount: campaignCount,
                    totalCampaigns: obj.totalCampaigns,
                    totalInstalls: obj.totalInstalls,
                    totalBudget: obj.totalBudget,
                    totalCredit: obj.totalCredit,
                    totalSpends: obj.totalSpends,
                    lastLoginDate: obj.lastLoginDate,
                    advertiserId: obj.advertiserId,
                    campaignData: obj.campaignData,
                    image: img,
                    country: obj.country,
                    id: obj.id,
                    createdDate: obj.createdDate
                });
            }
            setFilteredAdvertisersData(resDataArray)
        }
    }

    useEffect(() => {
        if (filtersAdvertisersData != null) {
            const csvData = filtersAdvertisersData.map(row => {
                const rowData: any = {};
                cols.forEach((field: any) => {
                    rowData[field.id] = row[field.id];
                });
                return rowData;
            });
            setDownloadData(csvData)
        }
    }, [filtersAdvertisersData])

    useEffect(() => {
        getfilteredData()
    }, [filters, advertisersData])

    const onRowClickFunc = (row: any, e: React.MouseEvent<Element, MouseEvent>) => {
        dispatch(setSelectedAdvertiserId(row['advertiserId']))
        navigate('selectedAdvertiser', {
            state: {
                advertiserDetails: row
            }
        })
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [selectedRange, setSelectedRange] = useState<any>({
        startDate: new Date(),
        endDate: new Date(),
    });

    const [selectedStartDate, setSelectedStartDate] = useState<Date>(
        new Date(new Date().getFullYear(), new Date().getMonth() - 2, 1)
    );
    const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());
    
    useEffect(() => {
        dispatch(setSelectedDateRange({
            startDate: selectedStartDate,
            endDate: selectedEndDate
        }))
    }, [])

    useEffect(() => {
        if (selectedDateRange.endDate != "" && selectedDateRange.startDate != "") {
            const s = new Date(selectedDateRange.startDate);
            const e = new Date(selectedDateRange.endDate);
            setSelectedRange({
                startDate: s,
                endDate: e
            })
        }
    }, [selectedDateRange]);

    useEffect(() => {
        if (advertiserScreenTable == null || advertiserScreenTable.length == 0) {
            getAllAdvertisersDetails()
        } else {
            setFilteredAdvertisersData(advertiserScreenTable)
            setAdvertisersData(advertiserScreenTable)
        }
    }, []);

    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(downloadData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(dataBlob, 'Advertisers XLSX.xlsx'); // Specify the desired file name here
    };

    // const groupedOptions = {
    //     section1: advertiserArray.slice(0, Math.ceil(advertiserArray.length / 2)),
    //     section2: advertiserArray.slice(Math.ceil(advertiserArray.length / 2))
    // };

    return <div>
        <div className="fixed-position" style={{ top: '93px', height: '190px' }}>
            <div className='white-container' style={{ display: "flex", clear: "both" }}>
                <div className="select-div-alignment">
                    <div className='select-div-size'>
                        <div className='sub-heading'>Select Brand / Agency</div>
                        <div>
                            <CustomSelect
                                multiple
                                value={filters.advertiser}
                                IconComponent={ExpandMoreIcon}
                                onChange={(e: any) => handleFilterChange('advertiser', e.target.value)}
                                renderValue={(selected: any) => selected.join(', ')}
                            >
                                {advertisersList.map((item: any) => (
                                    <MenuItem className='menuItemStyle' key={item} value={item.advertiserName}>
                                        <Checkbox checked={filters.advertiser.indexOf(item.advertiserName) > -1} style={{ color: customCheckBoxColor }} />
                                        {/* <ListItemText primary= */}
                                        {item.advertiserName}{" (" + item.id + ")"}
                                        {/* secondary={item.id} /> */}
                                    </MenuItem>
                                ))}

                                {/* {Object.entries(groupedOptions).map(([section, options]) => (
                                <optgroup key={section}>
                                    {options.map((item: any) => (
                                            <MenuItem className='menuItemStyle' key={item} value={item}>
                                                <Checkbox checked={options.indexOf(item) > -1} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                    ))}
                                        </optgroup>
                                    ))} */}
                            </CustomSelect>
                        </div>
                    </div>
                    <div className='select-div-size'>
                        <div className='sub-heading'>Select Category</div>
                        <div>
                            <CustomSelect
                                value={filters.category}
                                IconComponent={ExpandMoreIcon}
                                onChange={(e: any) => handleFilterChange('category', e.target.value)}
                            >
                                {/* <MenuItem className='menuItemStyle' value="All Categories">All Categories</MenuItem> */}
                                {categoryArray.map((item: any) => (
                                    <MenuItem className='menuItemStyle' key={item} value={item}>{item}</MenuItem>
                                ))}
                            </CustomSelect>
                        </div>
                    </div>
                    <div className='select-div-size'>
                        <DateCustomPicker onClick={getAllAdvertisersDetails} />
                    </div>
                </div>
            </div>
            <div className="space-between-div">
                <div style={{ display: "flex" }} ><img src={AdvertiserIcon} />&nbsp;&nbsp;<p style={{
                    fontSize: "16px",
                    color: "#333333",
                    fontWeight: 700
                }}>All Agencies / Brands </p></div>
                <div style={{ display: "flex" }} >
                    <div>
                        <button
                            type="button"
                            className="download-btn-style"
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                        >
                            Download <FileDownloadOutlinedIcon sx={{ marginLeft: "5px", height: "20px", }} />
                        </button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem>{downloadData.length == 0 ? (
                                <li
                                    onClick={() => {
                                        alert("No data available to download.");
                                    }}
                                    style={{
                                        fontSize: "12px",
                                        fontWeight: "600",
                                        color: "blue",
                                        textDecorationLine: 'underline'
                                    }}
                                >
                                    Document (.csv) file
                                </li>
                            ) : (<CSVLink data={downloadData} filename='Advertisers CSV' style={{
                                fontSize: "12px",
                                fontWeight: "600",
                                color: "blue",
                            }}>Document (.csv) file</CSVLink>)}</MenuItem>
                            <MenuItem>{downloadData.length == 0 ? (
                                <li
                                    onClick={() => {
                                        alert("No data available to download.");
                                    }}
                                    style={{
                                        fontSize: "12px",
                                        fontWeight: "600",
                                        color: "blue",
                                        textDecorationLine: 'underline'
                                    }}
                                >
                                    Excel (.xlsx) file
                                </li>
                            ) : (<a onClick={downloadExcel} style={{
                                fontSize: "12px",
                                fontWeight: "600",
                                color: "blue",
                                textDecorationLine: 'underline'
                            }}>Excel (.xlsx) file</a>)}</MenuItem>
                        </Menu>
                    </div>

                    <PurpleCustomButton
                        onClick={() => navigate('createAdvertiser', {
                            state: {
                                typeOpen: "create"
                            }
                        })}
                        disable={true}
                        children={
                            <div className="space-between-div">
                                <AddIcon sx={{ height: "20px" }} />  Create New
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
        <div className='white-container' style={{ marginTop: '195px' }}>
            {loaderActive ? <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "200px"
            }}>
                <CustomLoader />
            </div>
                :
                <AdvertiserTable
                    defaultSortFieldId='installs'
                    responsive={true}
                    highlightOnHover
                    data={filtersAdvertisersData}
                    defaultSortAsc={false}
                    fixedHeader
                    pagination
                    // paginationIconFirstPage={false}
                    // paginationIconLastPage={false}
                    paginationPerPage={5}
                    onRowClicked={onRowClickFunc}
                    // paginationIconPrevious={<div style={{
                    //     backgroundColor: "black",
                    //     height: "40px",
                    //     width: "40px",
                    //     borderRadius: "50%",
                    //     display: "flex",
                    //     alignItems: "center",
                    //     marginRight: "5px",
                    //     justifyContent: "center"

                    // }}><ArrowBackIosNewIcon sx={{
                    //     fontSize: "16px",
                    //     color: "white",
                    // }} /></div>}
                    // paginationIconNext={<div style={{
                    //     backgroundColor: "black",
                    //     height: "40px",
                    //     width: "40px",
                    //     borderRadius: "50%",
                    //     display: "flex",
                    //     alignItems: "center",
                    //     marginLeft: "5px",
                    //     justifyContent: "center"
                    // }}><ArrowForwardIosTwoToneIcon sx={{
                    //     fontSize: "16px",
                    //     color: "white",
                    // }} /></div>}
                    paginationComponentOptions={{
                        noRowsPerPage: true,
                        rowsPerPageText: 'Rows per page:',
                    }}
                    columns={cols}
                    conditionalRowStyles={[ // Apply the class to remove bottom border to every row
                        {
                            when: () => true,
                            style: {
                                borderBottom: 'none',
                            },
                        },
                    ]}

                    customStyles={{
                        headRow: {
                            style: {
                                borderBottom: "none",
                            }
                        },
                        rows: {
                            highlightOnHoverStyle: {
                                backgroundColor: "rgba(239, 227, 249, 0.5)",
                                cursor: "pointer"
                            },
                        },
                        headCells: {
                            style: {
                                color: "#6900B8",
                                fontSize: "16px",
                                fontWeight: 800,
                            },
                        },
                        cells: {
                            style: {
                                color: "rgba(51, 51, 51, 1)",
                                fontSize: "14px",
                                fontWeight: 550,
                            },
                        },
                    }}
                />}
        </div>
    </div>;
}

const mapStateToProps = (state: RootState) => ({
    currencySymbol: state.user.currencySymbol,
    authToken: state.user.authToken,
    sessionId: state.user.sessionId,
    advertiserId: state.user.advertiserId,
    mastertargetingTypesArray: state.user.mastertargetingTypesArray,
    selectedAdvertiserId: state.user.selectedAdvertiserId,
    selectedDateRange: state.user.selectedDateRange,
    advertisersList: state.user.advertisersList,
    advertiserScreenTable: state.user.advertiserScreenTable,
});

const mapDispatchToProps = {
    setAdvertisersList,
    setCurrencySymbol,
    setAdvertiserId,
    setAuthToken,
    setSessionId,
    setMasterTargetingTypesArray,
    setSelectedAdvertiserId,
    setSelectedDateRange,
    setAdvertiserScreenTable
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvertiserScreen);