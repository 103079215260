import React from "react";
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { TextFieldProps } from "../../../models/TextfieldModel";
import InputAdornment from "@mui/material/InputAdornment";
import { PurpleCustomButton } from "./PurpleButton";
import { BsCheckCircleFill } from "react-icons/bs";

interface CouponProps {
    coupon: any,
    open: boolean,
    onClose: any,
    onCopy:any,
    copy: boolean
}

export const CouponModal: React.FC<CouponProps> = ({ coupon, open, onClose, onCopy, copy}) => {
    const handleContentClick = (e: any) => {
        e.stopPropagation(); // Prevent click event from reaching the overlay
      };
  return  (
    <div className={`modal-overlay ${open ? 'open' : ''}`} onClick={onClose}>
      <div className="modal" onClick={handleContentClick}>
        <div className="modal-content" style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
          <div style={{display: 'flex',alignItems: 'center', color: '#333333', font: 'Manrope', fontSize: '14px', fontWeight: '700'}}>
            <div>Coupon Created Successfully</div> 
            <BsCheckCircleFill style={{color: '#6900B8', marginLeft: '5px'}}/>
            </div>
          <div style={{
            display: 'flex', flexDirection: 'column', gap: '7px',
            color: '#69B6B8', font: 'Manrope', fontSize: '16px', fontWeight: '400'
            }}>
            <div>Coupon Code: <span style={{color: '#333333', marginLeft: '5px', fontWeight: '700'}}>{coupon.CouponCode}</span></div>
            <div>Usage Limit: <span style={{color: '#333333', marginLeft: '5px', fontWeight: '700'}}>{coupon.UsageLimit}</span></div>
            <div>Valid Upto: <span style={{color: '#333333',  marginLeft: '5px', fontWeight: '700'}}>{coupon.ValidUpto}</span></div>
          </div>
          <div style={{ display: 'flex', justifyContent:'space-between', gap: '20px'}}>
            <PurpleCustomButton style={{backgroundColor: '#E4E4E4', color: '#6900B8', font: 'Manrope', fontSize: '14px', fontWeight: '700',minWidth: '40%'}} children= "Done" disable={true} onClick={onClose}/>
            <PurpleCustomButton style={{font: 'Manrope', fontSize: '14px', fontWeight: '500',minWidth: '40%'}} children= {copy? <div style={{display:'flex', alignItems:'center'}}><div>Copied</div><BsCheckCircleFill style={{color: '#FFFFFF', marginLeft: '5px'}}/></div>:"Copy Code"} disable={true} onClick={!copy? () => onCopy(coupon.CouponCode): ()=>{}} />
          </div>
        </div>
      </div>
    </div>
  );
}