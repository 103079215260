import React, { FC, useEffect, useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  ListItemText,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { RootState } from "../../../REDUX/Reducers/rootReducer";
import { connect } from "react-redux";
import DateCustomPicker from "../../custom/DateCustomPicker";
import "../../../CSS/Campaign.css";
import { useDispatch } from "react-redux";
import {
  setSelectedDateRange,
  setUserJourneyAd,
  setUserJourneyData,
} from "../../../REDUX/Actions/userActionIndex";
import Paper from "@mui/material/Paper";
import { secretKey, apiURL } from "../../../Constant";
import axios from "axios";
import CustomLoader from "../../custom/CustomLoader";
import styled from "styled-components";
const CryptoJS = require("crypto-js");

const CustomSelect = styled(Select)(({ theme }) => ({
  backgroundColor: "white",
  fontSize: "14px",
  fontWeight: "600",
  fontFamily: "Manrope",
  width: "215px",
  height: "40px",
  marginRight: "10px",
  borderRadius: "2px",
  "& fieldset": {
    padding: 0,
    border: "1px solid rgba(51, 51, 51, 0.5)",
    outline: "none",
  },
  "&.Mui-focused fieldset": {
    border: "1px solid rgba(51, 51, 51, 0.5)",
    outline: "none",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "1px solid rgba(51, 51, 51, 0.5)",
    outline: "none",
  },
  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
  {
      border: "1px solid #6900b8",
  },
}));

interface UserJourneyProps {
  advertisersList: any;
  selectedDateRange: any;
  advertiserId: string;
  authToken: string;
  sessionId: string;
  userJourneyAd: any;
  userJourneyData: any;
}

const UserJourney: FC<UserJourneyProps> = ({
  advertisersList,
  selectedDateRange,
  advertiserId,
  authToken,
  sessionId,
  userJourneyAd,
  userJourneyData,
}) => {


  const [dataComing, setdataComing] = useState<boolean>(true);
  const [page, setPage] = useState(0);
  const rowsPerPageOptions = [5, 10, 25];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const dispatch = useDispatch();


  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const updateFilters = () => {
        let advertiser ;
             advertiser = advertisersList.find((advertiser1: any) => {
                if(advertiser1.advertiserId === userJourneyAd.advertiserId){
                    return advertiser1
                }
            })
        // if (!userJourneyAd ||( userJourneyAd && userJourneyAd.advertiserId === advertiserId )) {
        //     advertiser = advertisersList.find((advertiser1: any) => {
        //         if(advertiser1.advertiserId === advertiserId){
        //             return advertiser1
        //         }
        //     });
        // } else {
        //     advertiser = advertisersList.find((advertiser1: any) => {
        //         if(advertiser1.advertiserId === userJourneyAd.advertiserId){
        //             return advertiser1
        //         }
        //     })
        // }
        if (advertiser) {
            dispatch(setUserJourneyAd( advertiser ));
        }
    };
    updateFilters();
}, [advertisersList]);

  const handleFilterChange = (value: any) => {
    dispatch(setUserJourneyAd(value));
  };


  const handleDateChange = (startDate: Date, endDate: Date) => {
    // getDetailsCampaign(startDate, endDate);
    // console.log(startDate,endDate)
    localStorage.setItem("startDate", startDate.toString());
    localStorage.setItem("endDate", endDate.toString());
    dispatch(
      setSelectedDateRange({
        startDate: startDate,
        endDate: endDate,
      })
    );

  };

  const formatChangeDate = (dateParam: any) => {
    const inputDateString = dateParam;
    const date = new Date(inputDateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate
}

  const fetchUserJourney = (adId: string) => {
    try {
      setdataComing(true);
      const encryptAdvertiserID = CryptoJS.AES.encrypt(
        JSON.stringify(advertiserId),
        secretKey
      ).toString();
      const jsonParam = {
        Advertiser_Id: adId,
        startDate: formatChangeDate(selectedDateRange.startDate),
        endDate: formatChangeDate(selectedDateRange.endDate),
      };
      console.log(jsonParam);
      const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify(jsonParam),
        secretKey
      ).toString();
      const url = `${apiURL}/api/admin/getUserLogData`;
      if (authToken != "" && sessionId != "" && advertiserId != "") {
        axios
          .post(
            url,
            { params: encryptedData },
            {
              headers: {
                authtoken: authToken,
                sessionid: sessionId,
                advertiserid: encryptAdvertiserID,
              },
            }
          )
          .then((res) => {
            console.log(res)
            const resData = res.data.data;
            const bytes = CryptoJS.AES.decrypt(resData, secretKey);
            const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
            const parsedData = JSON.parse(decryptedData);
            console.log(parsedData)
            dispatch(setUserJourneyData(parsedData));
            if (parsedData.length > 0) {
              return;
            } else {
              setdataComing(false);
            }
          })
          .catch((err) => {
            setdataComing(false);
            console.log(err);
          });
      }
    } catch (error) {
      setdataComing(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (userJourneyAd != '' && userJourneyAd.advertiserId != "") {
      fetchUserJourney(userJourneyAd.advertiserId);
    }else{
      setdataComing(false);
    }

  }, [userJourneyAd]);
  const searchUserJourney = () => {
    if (userJourneyAd.advertiserId != "") {
      fetchUserJourney(userJourneyAd.advertiserId);
    }
  }

  const formatDate = (inputDate: any) => {
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const sortedData = [...userJourneyData].sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());

  function getTimeFromIsoString(dateString: string): string {
    const date = new Date(dateString);
    const time = date.toLocaleTimeString();
    return time;
  }
  return (
    <div>
      <div className="top-box">
        <div>
          <h5  style={{marginBottom:'8px'}}>Select Brand/Agency</h5>
          <CustomSelect
            value={userJourneyAd}
            IconComponent={ExpandMoreIcon}
            onChange={(e : any) => handleFilterChange(e.target.value)}
          >
            {advertisersList.map((item: any) => (
              <MenuItem className="menuItemStyle" key={item} value={item}>
                <ListItemText
                  primary={item.advertiserName}
                  secondary={item.id}
                />
              </MenuItem>
            ))}
          </CustomSelect>
        </div>
        <DateCustomPicker
          onDateChange={handleDateChange}
          onClick={searchUserJourney}
        />
      </div>

      <div className="table-container">
        {userJourneyData.length != 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Action Type</TableCell>
                  <TableCell>Event</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Action Taken By</TableCell>
                  <TableCell>Date/Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData.length != 0 ? (
                  sortedData .slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  ).map((val: any) => {
                    return (
                      <TableRow>
                        <TableCell>{val.ActionType}</TableCell>
                        <TableCell>{val.EventTag}</TableCell>
                        <TableCell>{val.Description}</TableCell>
                        <TableCell>{userJourneyAd.advertiserName}</TableCell>
                        <TableCell>{formatDate(val.updatedAt)}, {getTimeFromIsoString(val.updatedAt)}</TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <h3 style={{ textAlign: "center", fontSize: "14px" }}>
                    No Data Available to Show on Specific Date Range
                  </h3>
                )}
              </TableBody>
            </Table>
            <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={sortedData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        ) : (
          <div>
            {dataComing ? (
              <div
                style={{
                  display: dataComing ? "flex" : "none",
                  alignItems: "center",
                  justifyContent: "center",
                  // marginTop: shouldHideDiv ? "50px" : "50px",
                }}
              >
                <CustomLoader />
                {/* <CircularProgress color="secondary" /> */}
              </div>
            ) : (
              <h3 style={{ textAlign: "center", fontSize: "14px" }}>
                No data available for the Brand/Agency.
              </h3>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  advertisersList: state.user.advertisersList,
  selectedDateRange: state.user.selectedDateRange,
  advertiserId: state.user.advertiserId,
  authToken: state.user.authToken,
  sessionId: state.user.sessionId,
  userJourneyAd: state.user.userJourneyAd,
  userJourneyData: state.user.userJourneyData,
});
const mapDispatchToProps = {
  setSelectedDateRange,
};
export default connect(mapStateToProps, mapDispatchToProps)(UserJourney);
