import React, { FC, useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import "../../CSS/dashboard.css";
import { PurpleCustomButton } from "../custom/PurpleButton";
import CampaignMainScreen from "./CampaignMainScreen";
import axios from "axios";
import BillingScreen from "./BillingScreen";
import BillingImage from "../../IMAGE/billing.svg";
import { useNavigate } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import calender from "../../IMAGE/calender.svg";
import StraightIcon from "@mui/icons-material/Straight";
import { Snackbar } from "@mui/material";
import DateCustomPicker from "../custom/DateCustomPicker";
import { apiURL, secretKey } from "../../Constant"
// import { connect } from "react-redux";
import { setAdvertiserId, setAuthToken, setCurrencySymbol, setSelectedAdvertiserId, setSelectedDateRange, setSessionId } from "../../REDUX/Actions/userActionIndex";
// import { RootState } from "../../REDUX/Reducers/rootReducer";
import { connect, useDispatch } from "react-redux";
import { RootState } from "../../REDUX/Reducers/rootReducer";
import {
  setCampaignSubType,
  setAppPlatform,
  setAppName,
  setCampaignName,
  setAppImageUrl,
  setPackageName,
  setLocation,
  setLanguage,
  setTracking,
  setTrackingUrl,
  setImpressionUrl,
  setTokenEvent,
  addTokenEventObject,
  removeTokenEventObject,
  updateToken,
  updateEvent,
  setLocationCode,
  setBudget,
  setFocus,
  setTarget,
  setCostPerInstall,
  setAdGroupName,
  setLogoUrl,
  setHeadline,
  setDescription,
  setImageUrl,
  setVideoUrl,
  setHtmlUrl,
  setCampaignId,
  setBudgetId,
  setStatus,
  setImageFile,
  setVideoFile,
  setHtmlFile,

} from "../../REDUX/Actions/userActionIndex";
import { setCount } from "../../REDUX/Actions/counterActionIndex";
const CryptoJS = require('crypto-js')

interface DashboardScreenProps {
  currencySymbol: any;
  authToken: any;
  sessionId: any;
  advertiserId: any;
  isadmin?: boolean;
  selectedAdvertiserId: any;
  selectedDateRange: any;
  campaignCount?: any;
}

const DashboardScreen: FC<DashboardScreenProps> = (props) => {

  const { currencySymbol, authToken, sessionId, advertiserId, isadmin, selectedAdvertiserId, selectedDateRange, campaignCount } = props
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [advertiserID, setAdvertiserID] = useState<any>(isadmin ? selectedAdvertiserId : advertiserId)
  const [sessionID, setSessionID] = useState<any>(sessionId)
  const [authTokenID, setAuthTokenID] = useState<any>(authToken)

  const [dateRange, setDateRange] = useState<any>({
    startDate: new Date(),
    endDate: new Date(),
  });

  const [currentMonth, setCurrentMonth] = useState<any>({
    clicks: 0,
    impressions: 0,
    conversions: 0,
    revenue: 0,
  });

  const [previousMonth, setPreviousMonth] = useState<any>({
    clicks: 0,
    impressions: 0,
    conversions: 0,
    revenue: 0,
  });

  const [percentChange, setPercentChange] = useState({
    clicks: 0,
    impressions: 0,
    conversions: 0,
    revenue: 0,
  });

  const [percentInc, setPercentInc] = useState({
    clicks: true,
    impressions: true,
    conversions: true,
    revenue: true,
  });

  const [noOfClicks, setNoOfClicks] = useState<number>(0);
  const [graphMax, setGraphMax] = useState<number>(400);
  const [totalCost, setTotalCost] = useState<number>(0);
  const [datePicked, setDatePicked] = useState<boolean>(false);
  const [toggleCal, setToggleCal] = useState<string>("none");
  const boxRef = useRef<HTMLDivElement | null>(null);

  const [dateChange, setdateChange] = useState<boolean>(false);

  const [series, setSeries] = useState<any>([
    {
      name: "Cost",
      data: [],
    },
    {
      name: "Clicks",
      data: [],
    },
  ]);

  const graphLabelStyle = {
    colors: "#999999",
    fontSize: "12px",
    fontWeight: 600,
    fontFamily: "Manrope",
    cssClass: "apexcharts-yaxis-label",
  }

  const markersStyle = {
    size: 5,
    shape: ["circle"],
    strokeColors: ["#FF0047", "#6900B8"],
    colors: ["#ffffff"],
  }

  const gridStyle = {
    borderColor: "#e7e7e7",
    row: {
      colors: ["transparent"],
      opacity: 1,
    },
  }

  const chartStyle = {
    height: "auto",
    type: "line",
    toolbar: {
      show: false,
    },
    animations: {
      enabled: true,
      easing: 'linear',
      speed: 1000,
      animateGradually: {
        enabled: true,
        delay: 900
      },
      dynamicAnimation: {
        enabled: true,
        speed: 900
      }
    }
  }

  const legendStyle = {
    position: "top",
    horizontalAlign: "right",
    floating: true,
    offsetY: -25,
    offsetX: -5,
  }

  const [options, setOptions] = useState<any>({
    noData: {
      text: "Loading...",
    },
    chart: chartStyle,
    colors: ["#FF0047", "#6900B8"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 1.5,
    },
    title: {
      text: "",
      align: "left",
    },
    grid: gridStyle,
    markers: markersStyle,
    xaxis: {
      tickAmount: 4,
      type: "category",
      categories: [],
      title: {
        text: "",
      },
      labels: {


        rotate: 0,
        style: graphLabelStyle,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: [
      {
        min: 0,
        max: graphMax,
        tickAmount: graphMax / 100,
        labels: {
          formatter: (val: any) => {
            return currencySymbol + " " + val;
          },
          style: graphLabelStyle,
        },
      },
      {
        min: 0,
        max: graphMax,
        tickAmount: graphMax / 100,
        opposite: true,
        labels: {
          formatter: (val: any) => {
            return val + "k";
          },
          style: graphLabelStyle,
        },
      },
    ],
    crosshairs: {
      show: false,
    },
    legend: legendStyle,
  });

  const handleClickOutside = (event: MouseEvent) => {
    if (boxRef.current && !boxRef.current.contains(event.target as Node)) {
      setToggleCal("none");
    }
  };

  const [openSession, setOpenSession] = React.useState(false);

  const handleSessionClose = () => {
    setOpenSession(false);
  };

  useEffect(() => {
    // const startingDate = localStorage.getItem("startDate");
    // const endingDate = localStorage.getItem("endDate")
    // if (startingDate != null && endingDate != null) {
    //   const storedStartingDate = new Date(startingDate);
    //   const storedEndingDate = new Date(endingDate);
    //   const dateObj = {
    //     startDate: storedStartingDate,
    //     endDate: storedEndingDate
    //   }
    //   setDateRange(dateObj);
    // }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleSelect = (ranges: any) => {
    if (!datePicked) {
      setDatePicked(true);
    }
    setDateRange(ranges.range1);

    localStorage.setItem("startDate", ranges.range1.startDate.toString());
    localStorage.setItem("endDate", ranges.range1.endDate.toString());
  };

  const toggleCalender = () => {
    if (toggleCal == "none") {
      setToggleCal("block");
    } else {
      setToggleCal("none");
    }
  };

  const formatChangeDate = (dateParam: any) => {
    const inputDateString = dateParam;
    const date = new Date(inputDateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const [selectedRange, setSelectedRange] = useState<any>({
    startDate: new Date(),
    endDate: new Date(),
  });

  // useEffect(() => {
  //   if (selectedDateRange.endDate != "" && selectedDateRange.startDate != "") {
  //     const s = new Date(selectedDateRange.startDate);
  //     const e = new Date(selectedDateRange.endDate);
  //     setSelectedRange({
  //       startDate: s,
  //       endDate: e
  //     })
  //   }
  // }, [selectedDateRange]);

  const [dateRangeError, setDateRangeError] = useState<String>("")

  useEffect(() => {
    if (selectedDateRange.endDate != "" && selectedDateRange.startDate != "") {
      const s = new Date(selectedDateRange.startDate);
      const e = new Date(selectedDateRange.endDate);

      // Convert dates to milliseconds
    const startMillis = s.getTime();
    const endMillis = e.getTime();

    // Calculate the difference in milliseconds
    const diffMillis = endMillis - startMillis;

    // Calculate the difference in months
    const millisPerDay = 1000 * 60 * 60 * 24;
    const daysInMonth = 30.436875; // Average days per month
    const diffMonths = diffMillis / (millisPerDay * daysInMonth);

    // Check if the difference is more than 3 months
    if (diffMonths > 3) {
     setDateRangeError("Date range should not be more than 3 months.")
    } else{
      onClickGetCamp()
    }
    }
    
  }, [selectedRange, currencySymbol]);

  useEffect(() => {
    setDateRangeError("")
    if (selectedDateRange.endDate != "" && selectedDateRange.startDate != "") {
      const s = new Date(selectedDateRange.startDate);
      const e = new Date(selectedDateRange.endDate);

      setSelectedRange({
        startDate: s,
        endDate: e
      })
    }
    // onClickGetCamp()
  }, [selectedDateRange]);

  const [selectedStartDate, setSelectedStartDate] = useState<Date>(
    new Date(new Date().getFullYear(), new Date().getMonth()-2, 1)
  );
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());
  useEffect(() => {
    dispatch(setSelectedDateRange({
      startDate: selectedStartDate,
      endDate: selectedEndDate
    }))
  }, []);

  const onClickGetCamp = async () => {

    setSeries([
      {
        name: "Cost",
        data: [],
      },
      {
        name: "Clicks",
        data: [],
      },
    ]);

    setOptions({
      noData: {
        text: "Loading...",
      },
      chart: chartStyle,
      colors: ["#FF0047", "#6900B8"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 1.5,
      },
      title: {
        text: "",
        align: "left",
      },
      grid: gridStyle,
      markers: markersStyle,
      xaxis: {
        tickAmount: 4,
        type: "category",
        categories: [],
        title: {
          text: "",
        },
        labels: {


          rotate: 0,
          style: graphLabelStyle,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: [
        {
          min: 0,
          max: 400,
          tickAmount: 400 / 100,
          labels: {
            formatter: (val: any) => {
              return currencySymbol + " " + val;
            },
            style: graphLabelStyle,
          },
        },
        {
          min: 0,
          max: 400,
          tickAmount: 400 / 100,
          opposite: true,
          labels: {
            formatter: (val: any) => {
              return val + "k";
            },
            style: graphLabelStyle,
          },
        },
      ],
      crosshairs: {
        show: false,
      },
      legend: legendStyle,


    });

    const revData: number[] = [];
    const clicksData: number[] = [];
    const createdDate: any[] = [];
    const getStartDate = formatChangeDate(selectedDateRange.startDate);
    const getEndDate = formatChangeDate(selectedDateRange.endDate);

    try {
      let jsonParams = {
        startDate: getStartDate,
        endDate: getEndDate,
        advertiserId: advertiserID,
      };

      const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(jsonParams), secretKey).toString();
      const encryptAdvertiserID = CryptoJS.AES.encrypt(JSON.stringify(advertiserId), secretKey).toString()

      let headers = {
        "Content-Type": "application/json",
        advertiserid: encryptAdvertiserID,
        authtoken: authTokenID,
        sessionid: sessionID,
      };
      const response = await axios.post(
        `${apiURL}/api/dashboard/getDashboardData`,
        { params: encryptedData },
        { headers: headers }
      );
      if (response.data.status == true) {
        const resData = response.data.data;
        const bytes = CryptoJS.AES.decrypt(resData, secretKey);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        const parsedData = JSON.parse(decryptedData);
        const currentMonthData = parsedData["currentMonthData"];
        const previousMonthData = parsedData["previousMonthData"];

        if (currentMonthData.length != 0) {
          setCurrentMonth({
            clicks: currentMonthData.clicks,
            impressions: currentMonthData.impressions,
            conversions: currentMonthData.grossConversions,
            revenue: currentMonthData.revenue,
          });
        }

        if (previousMonthData.length != 0) {
          setPreviousMonth({
            clicks: previousMonthData.clicks,
            impressions: previousMonthData.impressions,
            conversions: previousMonthData.grossConversions,
            revenue: previousMonthData.revenue,
          });
        }

        const graphData = parsedData["graphData"];

        if (graphData.length != 0) {
          graphData.sort(
            (a: any, b: any) =>
              new Date(a.created).getTime() - new Date(b.created).getTime()
          );

          graphData.map((item: any) => {
            revData.push(item.revenue);
            clicksData.push(item.clicks / 1000);
            createdDate.push(item.created);
          });

          setTotalCost(
            revData.reduce(
              (accumulator, currentValue) => accumulator + currentValue,
              0
            )
          );
          setNoOfClicks(
            clicksData.reduce(
              (accumulator, currentValue) => accumulator + currentValue,
              0
            )
          );

          const clicksGreaterThan400 =
            Math.max(...clicksData) > 400 ? Math.max(...clicksData) : 400;
          const revGreaterThan400 =
            Math.max(...revData) > 400 ? Math.max(...revData) : 400;

          if (
            clicksGreaterThan400 !== undefined &&
            revGreaterThan400 !== undefined &&
            clicksGreaterThan400 >= revGreaterThan400
          ) {
            const greater = Math.ceil(clicksGreaterThan400 / 100) * 100;
            setGraphMax(greater);
            setOptions({
              noData: {
                text: "No Data Found",
              },
              chart: chartStyle,
              colors: ["#FF0047", "#6900B8"],
              dataLabels: {
                enabled: false,
              },
              stroke: {
                curve: "smooth",
                width: 1.5,
              },
              title: {
                text: "",
                align: "left",
              },
              // chartHeight: chartHeight,
              grid: gridStyle,
              markers: markersStyle,
              xaxis: {
                tickAmount: 4,
                categories: createdDate,
                title: {
                  text: "",
                },
                labels: {


                  rotate: 0,
                  style: graphLabelStyle,
                },
                axisTicks: {
                  show: false,
                },
              },
              yaxis: [
                {
                  min: 0,
                  max: greater,
                  tickAmount: 10,
                  labels: {
                    formatter: (val: any) => {
                      return currencySymbol + " " + val;
                    },
                    style: graphLabelStyle,
                  },
                },
                {
                  min: 0,
                  max: greater,
                  tickAmount: 10,
                  opposite: true,
                  labels: {
                    formatter: (val: any) => {
                      return val + "k";
                    },
                    style: graphLabelStyle,
                  },
                },
              ],
              crosshairs: {
                show: false,
              },
              legend: legendStyle,
            });
          } else {
            const greater = Math.ceil(revGreaterThan400 / 100) * 100;
            setGraphMax(greater);
            setOptions({
              noData: {
                text: "No Data Found",
              },
              chart: chartStyle,
              colors: ["#FF0047", "#6900B8"],
              dataLabels: {
                enabled: false,
              },
              stroke: {
                curve: "smooth",
                width: 1.5,
              },
              title: {
                text: "",
                align: "left",
              },
              grid: gridStyle,
              markers: markersStyle,
              xaxis: {
                tickAmount: 4,
                categories: createdDate,
                title: {
                  text: "",
                },
                labels: {


                  rotate: 0,
                  style: graphLabelStyle,
                },
                axisTicks: {
                  show: false,
                },
              },
              yaxis: [{
                min: 0,
                max: greater,
                tickAmount: 10,
                labels: {
                  formatter: (val: any) => {
                    return currencySymbol + " " + val;
                  },
                  style: graphLabelStyle,
                }
              },
              {
                min: 0,
                max: greater,
                tickAmount: 10,
                opposite: true,
                labels: {
                  formatter: (val: any) => {
                    return val + "k";
                  },
                  style: graphLabelStyle,
                }
              }
              ],
              crosshairs: {
                show: false,
              },
              legend: legendStyle,
            });
          }

          setSeries([
            {
              name: "Cost",
              data: revData,
            },
            {
              name: "Clicks",
              data: clicksData,
            },
          ]);

        } else {
          setOptions({
            noData: {
              text: "No Data Found",
            },
            chart: chartStyle,
            colors: ["#FF0047", "#6900B8"],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "smooth",
              width: 1.5,
            },
            title: {
              text: "",
              align: "left",
            },
            grid: gridStyle,
            markers: markersStyle,
            xaxis: {
              tickAmount: 4,
              type: "category",
              categories: [],
              title: {
                text: "",
              },
              labels: {


                rotate: 0,
                style: graphLabelStyle,
              },
              axisTicks: {
                show: false,
              },
            },
            yaxis: [
              {
                min: 0,
                max: 400,
                tickAmount: 400 / 100,
                labels: {
                  formatter: (val: any) => {
                    return currencySymbol + " " + val;
                  },
                  style: graphLabelStyle,
                },
              },
              {
                min: 0,
                max: 400,
                tickAmount: 400 / 100,
                opposite: true,
                labels: {
                  formatter: (val: any) => {
                    return val + "k";
                  },
                  style: graphLabelStyle,
                },
              },
            ],
            crosshairs: {
              show: false,
            },
            legend: legendStyle,


          });
          setSeries([
            {
              name: "Cost",
              data: [],
            },
            {
              name: "Clicks",
              data: [],
            },
          ]);
        }
      } else if (response.data.statuscode == 401) {
        setOpenSession(true);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const posdivStyle = { backgroundColor: "rgba(105, 182, 184, 0.1)", color: "#69B6B8", fontSize: "14px", marginLeft: "5px" }
  const negdivStyle = { backgroundColor: "rgba(255,0,71, 0.1)", color: "#FF0047", fontSize: "14px", marginLeft: "5px" }

  return <div>
    <div className={`first-section fixed-position`} style={{
      display: isadmin ? "none" : "flex",
      height: '70px',
      top: '112px'
    }}>
      <PurpleCustomButton
        onClick={() => { window.location.replace('/home/campaigns/createCampaign') }}
        disable={true}
        children={<div className='button-div'><div className='plus'>+</div>&nbsp;&nbsp;New Campaign</div>}
      />
      <div style={{
        marginTop: "10px"
      }}>
        <DateCustomPicker />
        {dateRangeError != "" ?
          <div className="error-box" style={{
            float: "right",
            marginTop: "0px",
            marginBottom: "10px"
          }}>
            <p>{dateRangeError}</p>
          </div>
          : <div></div>}
      </div>
    </div>
    <div className="second-section" style={{ marginTop: isadmin ? "auto" : "60px" }}>
      <div className="white-container" style={{ width: "55%", height: "350px", margin: "40px 0px" }}>
        <div className="chart-title">
          <div className="grey-border-box">Cost <div>{currencySymbol} {totalCost.toFixed(2)}</div></div>
          <div className="grey-border-box">Clicks <div>{noOfClicks.toFixed(2)} k</div></div>
        </div>
        <ReactApexChart options={options} series={series} type="line" height={280} />
      </div>
      <div className="white-container" style={{ width: "36%", height: "350px", margin: "40px 0px" }}>
        <div style={{ borderBottom: "1px solid rgba(51,51,51,0.2", }}>
          {
            isadmin ? <div>
              <h4 style={{ display: "flex", alignItems: "center" }}> No. of Campaigns &nbsp;&nbsp;
                <span style={{ color: "#6900B8" }}>
                  {campaignCount != undefined ?
                    <div>
                      {Number(campaignCount[0]['CampaignsCreatedThisMonth']) - Number(campaignCount[0]['CampaignsCreatedLastMonth'])}
                    </div> : "0"
                  }
                </span>
                <span> {campaignCount != undefined ?
                  campaignCount[0]['CampaignsCreatedThisMonth'] >= campaignCount[0]['CampaignsCreatedLastMonth'] ? <div className="arrow-style-div"><StraightIcon className="arrow-up-style" /></div> : <div className="arrow-style-div"><StraightIcon className="arrow-up-style" sx={{
                    transform: "rotate(180deg)"
                  }}
                  /></div>
                  : ""}</span>

                <span className="file-size-text">than Last month</span>
              </h4>
              <h1 style={{ color: "#6900B8", }}>{campaignCount != undefined ? campaignCount[0]['TotalCampaigns'] : 0}</h1>
              {/* <span>
                <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '13px' }}>
                  <div className='stat-status-banner' style={{ backgroundColor: '#0B80431A', color: '#0B8043' }}> <img src={active} /> {coupons ? coupons.filter((item: any) => item.IsActive).length : 0}</div>
                  <div className='stat-status-banner' style={{ backgroundColor: '#B07F001A', color: '#B07F00' }}> <img src={paused} /> 0</div>
                  <div className='stat-status-banner' style={{ backgroundColor: '#C539291A', color: '#C53929' }}> <img src={stopped} /> {coupons ? coupons.filter((item: any) => !item.IsActive).length : 0}</div>
                </div>
              </span> */}
              <h4 style={{ display: "flex", alignItems: "center" }}> Total Spendings &nbsp;&nbsp;
                <span style={{ color: "#6900B8" }}>
                  {previousMonth.revenue != 0 ?
                    (((currentMonth.revenue - previousMonth.revenue) / previousMonth.revenue) * 100).toFixed(2)
                    : "+ 0"
                  } %
                </span>
                <span>
                  {
                    currentMonth.revenue >= previousMonth.revenue ? <div className="arrow-style-div"><StraightIcon className="arrow-up-style" /></div> : <div className="arrow-style-div"><StraightIcon className="arrow-up-style" sx={{
                      transform: "rotate(180deg)"
                    }}
                    /></div>
                  }</span><span className="file-size-text">than Last month</span></h4>
              <h1 style={{ color: "#6900B8", }}>{currencySymbol} {currentMonth.revenue.toFixed(2)}</h1>
            </div> : <div>
              <h4 style={{ marginBottom: "60px" }}>Overall Performance</h4>
              <h4 style={{ display: "flex" }}>Spends for last month &nbsp;&nbsp;
                <span style={{ color: "#6900B8" }}>
                  {previousMonth.revenue != 0 ?
                    (((currentMonth.revenue - previousMonth.revenue) / previousMonth.revenue) * 100).toFixed(2)
                    : "+ 0"
                  } %
                </span>
                <span>
                  {
                    currentMonth.revenue >= previousMonth.revenue ? <div className="arrow-style-div"><StraightIcon className="arrow-up-style" /></div> : <div className="arrow-style-div"><StraightIcon className="arrow-up-style" sx={{
                      transform: "rotate(180deg)"
                    }}
                    /></div>
                  }</span></h4>
              <h1 style={{ color: "#6900B8", }}>{currencySymbol} {currentMonth.revenue}</h1>
            </div>
          }

        </div>
        <div style={{ display: "flex", justifyContent: "space-between", margin: "30px 0px" }}>
          <div>
            <div className="heading">Impressions</div>
            <div style={{ display: "flex", alignItems: "center", fontWeight: "600", margin: "10px 0px" }}>
              {currentMonth.impressions}
              <span style={currentMonth.impressions >= previousMonth.impressions ? posdivStyle : negdivStyle}>
                {previousMonth.impressions != 0 ?
                  (((currentMonth.impressions - previousMonth.impressions) / previousMonth.impressions) * 100).toFixed(2)
                  : " + 0"
                } % </span></div>
          </div>
          <div>
            <div className="heading">Clicks</div>
            <div style={{ display: "flex", alignItems: "center", fontWeight: "600", margin: "10px 0px" }}>{currentMonth.clicks}
              <span style={currentMonth.clicks >= previousMonth.clicks ? posdivStyle : negdivStyle}>
                {previousMonth.clicks != 0 ?
                  (((currentMonth.clicks - previousMonth.clicks) / previousMonth.clicks) * 100).toFixed(2)
                  : " + 0"
                } % </span></div>
          </div>
          <div>
            <div className="heading">Conversions</div>
            <div style={{ display: "flex", alignItems: "center", fontWeight: "600", margin: "10px 0px" }}>{currentMonth.conversions}
              <span style={currentMonth.conversions >= previousMonth.conversions ? posdivStyle : negdivStyle}>
                {previousMonth.conversions != 0 ?
                  (((currentMonth.conversions - previousMonth.conversions) / previousMonth.conversions) * 100).toFixed(2)
                  : " + 0"} %
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CampaignMainScreen isadmin={isadmin} shouldHideDiv={true} dates={{
      startDate: selectedRange.startDate,
      endDate: selectedRange.endDate
    }} dateChanging={dateChange} heading={isadmin ? "All Campaigns" : "Top Campaigns"} />
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <div style={{ display: "flex", alignItems: "center", width: "60px", justifyContent: "space-between", }} className="heading"><img src={BillingImage} /><p>Billing</p></div>
      <div className="sub-heading" style={{ cursor: "pointer" }} onClick={() => isadmin ? navigate('/home/admin/billing', {
        state: {
          viewAll: true
        }
      }) : navigate('/home/billing')}>View All Transactions</div>
    </div>
    <div className="white-container">
      <BillingScreen shouldHideDiv={true} isadmin={isadmin} dates={{
        startDate: selectedRange.startDate,
        endDate: selectedRange.endDate
      }} subHeading="Recent Transactions" dateChange={true} />
    </div>
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={openSession}
      onClose={handleSessionClose}
      message="Session Expired!"
    />
  </div>;
}

const mapStateToProps = (state: RootState) => ({
  currencySymbol: state.user.currencySymbol,
  authToken: state.user.authToken,
  sessionId: state.user.sessionId,
  advertiserId: state.user.advertiserId,
  selectedAdvertiserId: state.user.selectedAdvertiserId,
  selectedDateRange: state.user.selectedDateRange
});

const mapDispatchToProps = {
  setCurrencySymbol,
  setAdvertiserId,
  setAuthToken,
  setSessionId,
  setCount,
  setStatus,
  setCampaignSubType,
  setAppPlatform,
  setAppName,
  setCampaignName,
  setAppImageUrl,
  setPackageName,

  setLanguage,
  setLocation,
  setTracking,
  setTrackingUrl,
  setImpressionUrl,
  setTokenEvent,
  addTokenEventObject,
  removeTokenEventObject,
  updateToken,
  updateEvent,
  setLocationCode,

  setBudget,
  setFocus,
  setTarget,
  setCostPerInstall,

  setAdGroupName,
  setLogoUrl,
  setHeadline,
  setDescription,
  setImageUrl,
  setVideoUrl,
  setHtmlUrl,

  setCampaignId,
  setBudgetId,
  setSelectedAdvertiserId,
  setSelectedDateRange
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardScreen);
