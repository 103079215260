import React, { FC, useState } from "react";
import "../../CSS/EditCampaign.css";
import CampaignInfo from "../custom/CampaignInfo";
import CampaignSetting from "../custom/CampaignSetting";
import axios from "axios";
import { apiURL } from "../../Constant";
const EditCampaign: FC = () => {
  const [campaignInfo, setcampaignInfo] = useState<boolean>(true);
  const [campaignSetting, setcampaignSetting] = useState<boolean>(false);

  const toggleButton = (value: string) => {
    switch (value) {
      case "info":
        setcampaignInfo(!campaignInfo);
        setcampaignSetting(!campaignSetting);
        break;
      case "settings":
        setcampaignInfo(!campaignInfo);
        setcampaignSetting(!campaignSetting);
        break;

      default:
        break;
    }
  };
  const updateApi = async () =>{
    const advertiserId = localStorage.getItem("advertiserID") || "";
    const email = localStorage.getItem("emailID") || "";
    const campaignName = localStorage.getItem("campaignName") || "";
    const campSubtype = localStorage.getItem("campaignSubtype") || "";
    const appPlatform = localStorage.getItem("appPlatform") || "";
    const lookup = localStorage.getItem("appLookup") || "";
    const trackingURL = localStorage.getItem("trackingUrl") || "";

    const jsonParam = {
      AdvertiserId : advertiserId,
     Email: email,
     ApplicationName:campaignName,
     CampaignName: campaignName,
     SubType: campSubtype,
     AppPlatform: appPlatform,
     AppLookUpDetails: lookup,
     "Currency": "INR",
     "Status": "Active",
     "Payouts": [
         {
             "payout": 0,
     "revenue": 0,
     "geo": ["ALL"]
         }
     ],
     TrackingURL: trackingURL
   }
   
    const url = `${apiURL}/api/campaigns/saveBudgetDetails`
    await axios.post(url,jsonParam).then((res)=>{
      console.log(res)

    }).catch((err)=>{
      console.log(err)
    })
  }
  const navigate = () =>{
    updateApi();
    window.location.replace("/home/campaigns")
  }
  return (
    <div>
      <h3 className="edit-camp-heading">Edit Campaign</h3>
      <div className="main-box">
        <div
          className={campaignInfo ? "highlight" : ""}
          onClick={() => toggleButton("info")}
        >
          <div className="edit-campaign">Campaign Info</div>
          <div className="underline"></div>
        </div>

        <div
          className={campaignSetting ? "highlight" : ""}
          onClick={() => toggleButton("settings")}
          style={{ marginLeft: "25px" }}
        >
          <div className="edit-campaign" >
            Campaign Settings
          </div>
          <div className="underline"></div>
        </div>
      </div>

      <div >
      {campaignInfo ? <CampaignInfo onClick={()=> toggleButton("info")} onBackClick={()=>window.location.replace("/home/campaigns")} />: null}
      {campaignSetting ? <CampaignSetting onClick={navigate} onClickBack={()=>{toggleButton("setting")}} />: null}
      </div>
    </div>
  );
};

export default EditCampaign;
