import React, { ChangeEvent, FC, useState, useEffect } from "react";
import BackCustom from "../../../custom/BackCustom";
import "../../../../CSS/Campaign.css";
import { PurpleCustomButton } from "../../../custom/PurpleButton";
import editUnderline from "../../../../IMAGE/editUnderline.svg";
// import companyImage from "../../../../IMAGE/companyImage.svg";
import appImage from "../../../../IMAGE/appImage.svg";
import { Fade, Modal, Snackbar } from "@mui/material";
import active from "../../../../IMAGE/active.svg";
import sound from "../../../../IMAGE/sound.svg";
import edit from "../../../../IMAGE/editIcon.svg";
// import active from "../../../../IMAGE/active.svg";
import paused from "../../../../IMAGE/paused.svg";
import stopped from "../../../../IMAGE/stopped.svg";
import done from "../../../../IMAGE/done.svg";
import pending from "../../../../IMAGE/pending.svg";
import { TransitionProps } from "@material-ui/core/transitions";
import Backdrop from "@mui/material/Backdrop";
import uploadPurple from "../../../../IMAGE/uploadPurple.svg";
import { BiSolidErrorCircle } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";

import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  styled,
} from "@mui/material";
import {
  EditStatusArray,
  TrackingPartnerDpd,
  focusValuesDpd,
  apiURL,
  secretKey,
  languageArr,
  EditArray,
  EditArrayShow,
} from "../../../../Constant";
import AWS from "aws-sdk";
import locationImg from "../../../../IMAGE/location.svg";
import add from "../../../../IMAGE/addWithoutBG.svg";
// import styled from "@emotion/styled";
import axios from "axios";
import DateCustomPicker from "../../../custom/DateCustomPicker";
import {
  setCampaignSubType,
  setAppPlatform,
  setAppName,
  setCampaignName,
  setAppImageUrl,
  setPackageName,
  setLocation,
  setLanguage,
  setTracking,
  setTrackingUrl,
  setImpressionUrl,
  setTokenEvent,
  addTokenEventObject,
  removeTokenEventObject,
  updateToken,
  updateEvent,
  setLocationCode,
  setBudget,
  setFocus,
  setTarget,
  setCostPerInstall,
  setAdGroupName,
  setLogoUrl,
  setHeadline,
  setDescription,
  setImageUrl,
  setVideoUrl,
  setHtmlUrl,
  setCampaignId,
  setBudgetId,
  setStatus,
  setImageFile,
  setVideoFile,
  setHtmlFile,
  setImageUploadError,
  setLogoError,
  setLocationCheck,
  setAdvertiserId,
  setAuthToken,
  setSessionId,
  setDataSource,
  setSegmentName,
  setDataType,
  setDataUpload,
  setUploadedUrl,
  setDataSourceError,
  setSegmentNameError,
  setDataTypeError,
  setDataUploadError,
  setUploadedUrlError,
  setSelectedAdvertiserId,
  setSelectedDateRange,
  setClicks,
  setImpression,
  setConversion,
  setInstall,
  setPayout,
  setCtr,
  setCpi,
  setTransactionCurrency,
  setDailyAvgBudget,
  setAdvertiserIdCampaign,
  setCompanyImage,
  setCompanyName,
  setTableData,
} from "../../../../REDUX/Actions/userActionIndex";
import { connect } from "react-redux";
import { RootState } from "../../../../REDUX/Reducers/rootReducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { COMPANY_NAME } from "../../../../REDUX/Actions/userActions";
import { setCount } from "../../../../REDUX/Actions/counterActionIndex";
import {
  getTargetingTypesMaster,
  getCountryMaster,
} from "../../../../TSX/MasterDataFile";
import Slide from "@material-ui/core/Slide";
import { LuCopy } from "react-icons/lu";

const CryptoJS = require("crypto-js");

interface EditCampaignProps {
  croppedId: any;
  campaignSubtype: string;
  appPlatform: string;
  appName: string;
  campaignName: string;
  appImageUrl: string;
  packageName: string;
  countryCodeAdvertiser: string;
  masterCountryArray: string;
  location: string;
  language: string[];
  tracking: string;
  trackingUrl: string;
  impressionUrl: string;
  tokenEvent: {
    token: string;
    eventName: string;
  }[];
  // showLower?: boolean;
  locationCode: string;

  budget: string;
  target: string;
  focus: string;
  costPerInstall: string;

  adGroupName: string;
  logoUrl: string;
  headline: string[];
  description: string[];

  imageUrl: string[];
  videoUrl: string[];
  htmlUrl: string[];
  count: number;

  budget_Id: string;
  campaign_Id: string;

  status: string;
  authToken: string;
  sessionId: string;
  advertiserId: string;
  dateChanging?: boolean;
  selectedAdvertiserId: string;
  selectedDateRange: any;
  companyName: string;

  shouldHideDiv?: boolean;
  isadmin?: boolean;
  heading?: string;
  dates?: {
    startDate: string;
    endDate: string;
  };
  campaignStatus: string;
  advertiserIdCampaign: string;

  transactionCurrency: string;
  clicks: string;
  impression: string;
  conversion: string;
  install: string;
  payout: string;
  ctr: string;
  cpi: string;
  dailyAvgBudget: string;
  dataSource: string;
  segmentName: string;
  dataType: string;
  uploadedUrl: string;
  companyImage: string;
  lastBilledAmount: number;
  totalSpendsLastMonth: number;
  creditLimit: number;
  createdDate: string;
  activatedDate: string;
}

const useStylesTextField = () => ({
  // root: {
  width: "48%",
  height: "35px",
  "& .css-1x5jdmq": {
    padding: "7px 14px",
  },

  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    padding: "6px 14px",
  },
  "& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root": {
    height: "35px",
  },
  "& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root": {
    height: "35px",
  },
  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:focus": {
    outline: "0 !important",
    borderColor: "1px solid green",
  },

  "&.Mui-focused fieldset": {
    borderColor: "rgba(51, 51, 51, 0.3) !important",
    borderWidth: "1px",
    outline: "none",
  },
  "&:hover fieldset": {
    borderColor: "rgba(51, 51, 51, 0.3)",
    borderWidth: "1px",
    outline: "transparent",
  },
  // },
});

const CustomTextFieldTracking = styled(TextField)({
  // root: {
  // width: "48%",
  height: "35px",
  outline: "none",
  border: "1px solid #c4c4c4",
  borderRadius: "4px",

  "& .css-1x5jdmq": {
    padding: "7px 14px",
  },
  "& fieldset": {
    outline: "none",
    border: "none",
  },
  "&.Mui-focused fieldset": {
    outline: "none",
    border: "none",
  },
  "&.MuiTextField-root input": {
    outline: "none",
    border: "none",
  },
  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    padding: "6px 14px",
    border: "1px solid red",
  },
  "& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root": {
    height: "35px",
  },
  "& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root": {
    height: "35px",
  },
  // "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:focus": {
  //   outline: "0 !important",
  //   borderColor: "1px solid green",
  // },

  // "&.Mui-focused fieldset": {
  //   borderColor: "rgba(51, 51, 51, 0.3) !important",
  //   borderWidth: "1px",
  //   outline: "none",
  // },
  "&:hover fieldset": {
    borderColor: "rgba(51, 51, 51, 0.3)",
    borderWidth: "1px",
    outline: "transparent",
  },
  // },
});

const CustomTextField = styled(TextField)({
  // root: {
  width: "48%",
  height: "35px",
  outline: "none",
  border: "1px solid #c4c4c4",
  borderRadius: "4px",

  "& .css-1x5jdmq": {
    padding: "7px 14px",
  },
  "& fieldset": {
    outline: "none",
    border: "none",
  },
  "&.Mui-focused fieldset": {
    outline: "none",
    border: "none",
  },
  "&.MuiTextField-root input": {
    outline: "none",
    border: "none",
  },
  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    padding: "6px 14px",
    border: "1px solid red",
  },
  "& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root": {
    height: "35px",
  },
  "& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root": {
    height: "35px",
  },
  // "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:focus": {
  //   outline: "0 !important",
  //   borderColor: "1px solid green",
  // },

  // "&.Mui-focused fieldset": {
  //   borderColor: "rgba(51, 51, 51, 0.3) !important",
  //   borderWidth: "1px",
  //   outline: "none",
  // },
  "&:hover fieldset": {
    borderColor: "rgba(51, 51, 51, 0.3)",
    borderWidth: "1px",
    outline: "transparent",
  },
  // },
});

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

const EditCampaignAdmin: FC<EditCampaignProps> = ({
  countryCodeAdvertiser,
  masterCountryArray,
  croppedId,
  createdDate,
  activatedDate,
  clicks,
  impression,
  conversion,
  install,
  payout,
  ctr,
  cpi,
  dailyAvgBudget,

  transactionCurrency,
  advertiserIdCampaign,
  dateChanging,
  authToken,
  sessionId,
  advertiserId,
  shouldHideDiv,
  heading,
  dates,
  count,
  appName,
  campaignSubtype,
  campaignStatus,
  appPlatform,
  campaignName,
  packageName,
  appImageUrl,

  location,
  language,
  tracking,
  trackingUrl,
  impressionUrl,
  tokenEvent,
  locationCode,

  budget,
  target,
  focus,
  costPerInstall,

  adGroupName,
  logoUrl,
  headline,
  description,
  imageUrl,
  videoUrl,
  htmlUrl,

  campaign_Id,
  budget_Id,
  status,
  isadmin,
  selectedAdvertiserId,
  selectedDateRange,
  companyName,

  dataSource,
  segmentName,
  dataType,
  uploadedUrl,
  companyImage,
  lastBilledAmount,
  totalSpendsLastMonth,
  creditLimit,
}) => {
  const [editCampaign, seteditCampaign] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [userInput, setUserInput] = useState("");

  const [calState, setCalState] = useState<{
    calConversion: any;
    calClicks: any;
    calInstall: any;
  }>({
    calConversion: 0,
    calClicks: 0,
    calInstall: 0,
  });

  const [state, setState] = useState<{
    campaignSubtype: string;
    appPlatform: string;
    appName: string;
    campaignName: string;
    appImageUrl: string;
    packageName: string;

    location: string;
    language: string[];
    tracking: string;
    trackingUrl: string;
    impressionUrl: string;
    tokenEvent: {
      token: string;
      eventName: string;
    }[];
    // showLower?: boolean;
    locationCode: string;

    budget: string;
    target: string;
    focus: string;
    costPerInstall: string;

    adGroupName: string;
    logoUrl: string;
    headline: string[];
    description: string[];

    imageUrl: string[];
    videoUrl: string[];
    htmlUrl: string[];
    count: number;

    budget_Id: string;
    campaign_Id: string;

    status: string;
    authToken: string;
    sessionId: string;
    advertiserId: string;
    dateChanging?: boolean;
    selectedAdvertiserId: string;
    selectedDateRange: any;
    companyName: string;

    shouldHideDiv?: boolean;
    isadmin?: boolean;
    heading?: string;
    dates?: {
      startDate: string;
      endDate: string;
    };
    campaignStatus: string;
    advertiserIdCampaign: string;

    transactionCurrency: string;
    clicks: string;
    impression: string;
    conversion: string;
    install: string;
    payout: string;
    ctr: string;
    cpi: string;
    dailyAvgBudget: string;
    companyImage: string;
    creditLimit: number;
  }>({
    companyImage: companyImage,
    campaignSubtype: campaignSubtype,
    appPlatform: appPlatform,
    appName: appName,
    campaignName: campaignName,
    appImageUrl: appImageUrl,
    packageName: packageName,

    location: location,
    language: language,
    tracking: tracking,
    trackingUrl: trackingUrl,
    impressionUrl: impressionUrl,
    tokenEvent: tokenEvent,
    // showLower?: boolean;
    locationCode: locationCode,

    budget: budget,
    target: target,
    focus: focus,
    costPerInstall: costPerInstall,

    adGroupName: adGroupName,
    logoUrl: logoUrl,
    headline: headline,
    description: description,

    imageUrl: imageUrl,
    videoUrl: videoUrl,
    htmlUrl: htmlUrl,
    count: count,

    budget_Id: budget_Id,
    campaign_Id: campaign_Id,

    status: status,
    authToken: authToken,
    sessionId: sessionId,
    advertiserId: advertiserId,
    selectedAdvertiserId: selectedAdvertiserId,
    selectedDateRange: selectedDateRange,
    companyName: companyName,

    campaignStatus: campaignStatus,
    advertiserIdCampaign: advertiserIdCampaign,

    transactionCurrency: transactionCurrency,
    clicks: clicks,
    impression: impression,
    conversion: conversion,
    install: install,
    payout: payout,
    ctr: ctr,
    cpi: cpi,
    dailyAvgBudget: dailyAvgBudget,
    creditLimit: creditLimit,
  });

  const [image, setImage] = useState<any>();
  const [selectedRange, setSelectedRange] = useState<any>({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [copied, setCopied] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());
  const [targetAppDpd, setTargetAppDpd] = useState<any>([]);
  const [budgetLimit, setBudgetLimit] = useState<number>(0);
  const [budgetError, setBudgetError] = useState<string>("");
  const [costPerInstallError, setCostPerInstallError] = useState<string>("");
  const [focusDpdError, setFocusDpdError] = useState<string>("");
  const [targetDpdError, settargetDpdError] = useState<string>("");
  const [showTarget, setShowTarget] = useState<boolean>(true);
  const [dailyTargetDisabled, setDailyTargetDisabled] =
    useState<boolean>(false);
  const [trackingError, setTrackingError] = useState<string>("");

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [countryArr, setCountryArr] = useState<any[]>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function SlideTransition(props: TransitionProps) {
    return <Slide {...props} direction="up" />;
  }

  const removeLanguage = (valueToRemove: string) => {
    const updatedArray = language.filter((item) => item !== valueToRemove);
    dispatch(setLanguage(updatedArray));
  };
  const handleSuggestionClick = (suggestion: string) => {
    setUserInput("");
    // setLanguageError("");
    if (!language.includes(suggestion)) {
      dispatch(setLanguage([...language, suggestion]));
      setState((prevState) => ({
        ...prevState,
        language: [...state.language, suggestion],
      }));
    }
    // localStorage.setItem(
    //   "selectedLang",
    //   JSON.stringify([...language, suggestion])
    // );
    setSuggestions([]);
  };
  const fetchSuggestions = (input: string) => {
    // Simulated array of suggestions
    // Filter suggestions based on user input
    const filteredSuggestions = languageArr.filter((suggestion: string) =>
      suggestion.toLowerCase().includes(input.toLowerCase())
    );

    setSuggestions(filteredSuggestions);
  };
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setUserInput(value);
    fetchSuggestions(value);
  };
  const handleCurrency = (label: string) => {
    let countryArray;
    countryArray = masterCountryArray;
    if (countryArray && Array.isArray(countryArray)) {
      let countryFind = countryArray.find((element: any) => {
        return label === element.Code;
      });

      if (countryFind != undefined) {
        setBudgetLimit(countryFind.BudgetLimit);

        if (Number(budget) < countryFind.BudgetLimit) {
          setBudgetError(
            `Please enter minimum value ${countryFind.BudgetLimit}`
          );
        } else {
          setBudgetError("");
        }
      }
    }
  };
  const onChangeTargetApp = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    localStorage.setItem("targetApp", value);
    dispatch(setTarget(value));
    setShowTarget(false);
    settargetDpdError("");
    setDailyTargetDisabled(false);
  };

  const checkAppPlatform = (value: any) => {
    let targetingArray = [];
    targetingArray = getTargetingTypesMaster();
    if (targetingArray && Array.isArray(targetingArray)) {
      let arr: any = [];

      if (transactionCurrency == "USD") {
        // console.log("here");
        if (countryCodeAdvertiser == "IN" && locationCode == "IN") {
          arr = targetingArray.filter((val: any) => {
            return (
              val.Country.indexOf(
                `${countryCodeAdvertiser}-${transactionCurrency}`
              ) != -1
            );
          });
        } else if (countryCodeAdvertiser == "IN" && locationCode != "IN") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(locationCode) != -1;
          });
        } else if (countryCodeAdvertiser != "IN" && locationCode == "IN") {
          arr = targetingArray.filter((val: any) => {
            return (
              val.Country.indexOf(`${locationCode}-${transactionCurrency}`) !=
              -1
            );
          });
        } else {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(countryCodeAdvertiser) != -1;
          });
        }
      } else {
        if (countryCodeAdvertiser == "IN" && locationCode != "IN") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(`${locationCode}-INR`) != -1;
          });
        } else if (countryCodeAdvertiser == "IN") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(locationCode) != -1;
          });
        } else if (countryCodeAdvertiser == "") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(`${locationCode}-INR`) != -1;
          });
        } else {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(`${locationCode}-INR`) != -1;
          });
        }
      }
      console.log("countryCodeAdvertiser", arr);
      setTargetAppDpd(arr);
      if (target != null && focus != null && target != "" && focus != "") {
        setCostPerInstallError("");
        // dispatch(setCostPerInstall(value));
        const minVal = arr.filter((item: any) => item.TargetingType == target);

        // console.log(minVal[0].InstallVolume);
        // console.log(minVal[0].IOS_USD,appPlat)
        console.log("minVal", minVal);
        if (focus == "") {
          setFocusDpdError("Please select a value");
        } else if (target == "") {
          settargetDpdError("Please select a value");
        } else if (minVal.length != 0) {
          // let temp = ""
          // if (currenctCode != "INR") {
          // console.log(focus == "appActions", focus == "installVolume");
          if (focus == "installVolume") {
            if (value == "Android") {
              if (
                minVal[0].InstallVolume.AOS != "" &&
                minVal[0].InstallVolume.AOS > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InstallVolume.AOS}`
                );
              } else {
                setCostPerInstallError("");
              }
            } else if (value == "APK") {
              if (
                minVal[0].InstallVolume.APK != "" &&
                minVal[0].InstallVolume.APK > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InstallVolume.APK}`
                );
              } else {
                setCostPerInstallError("");
              }
            } else {
              if (
                minVal[0].InstallVolume.IOS != "" &&
                minVal[0].InstallVolume.IOS > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InstallVolume.IOS}`
                );
              } else {
                setCostPerInstallError("");
              }
            }
          } else {
            if (value == "Android") {
              if (
                minVal[0].InAppBidding.AOS != "" &&
                minVal[0].InAppBidding.AOS > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InAppBidding.AOS}`
                );
              } else {
                setCostPerInstallError("");
              }
            } else if (value == "APK") {
              if (
                minVal[0].InAppBidding.APK != "" &&
                minVal[0].InAppBidding.APK > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InAppBidding.APK}`
                );
              } else {
                setCostPerInstallError("");
              }
            } else {
              if (
                minVal[0].InAppBidding.IOS != "" &&
                minVal[0].InAppBidding.IOS > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InAppBidding.IOS}`
                );
              } else {
                setCostPerInstallError("");
              }
            }
          }
        }
      }
      setTargetAppDpd(arr);
    }
  };

  const checkAppBidding = (value: any) => {
    let targetingArray = [];
    targetingArray = getTargetingTypesMaster();
    if (targetingArray && Array.isArray(targetingArray)) {
      let arr: any = [];

      if (transactionCurrency == "USD") {
        // console.log("here");
        if (countryCodeAdvertiser == "IN" && locationCode == "IN") {
          arr = targetingArray.filter((val: any) => {
            return (
              val.Country.indexOf(
                `${countryCodeAdvertiser}-${transactionCurrency}`
              ) != -1
            );
          });
        } else if (countryCodeAdvertiser == "IN" && locationCode != "IN") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(locationCode) != -1;
          });
        } else if (countryCodeAdvertiser != "IN" && locationCode == "IN") {
          arr = targetingArray.filter((val: any) => {
            return (
              val.Country.indexOf(`${locationCode}-${transactionCurrency}`) !=
              -1
            );
          });
        } else {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(countryCodeAdvertiser) != -1;
          });
        }
      } else {
        if (countryCodeAdvertiser == "IN" && locationCode != "IN") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(`${locationCode}-INR`) != -1;
          });
        } else if (countryCodeAdvertiser == "IN") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(locationCode) != -1;
          });
        } else if (countryCodeAdvertiser == "") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(`${locationCode}-INR`) != -1;
          });
        } else {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(`${locationCode}-INR`) != -1;
          });
        }
      }
      console.log("countryCodeAdvertiser", arr);
      setTargetAppDpd(arr);
      if (target != null && value != null && target != "" && value != "") {
        setCostPerInstallError("");
        // dispatch(setCostPerInstall(value));
        const minVal = arr.filter((item: any) => item.TargetingType == target);

        // console.log(minVal[0].InstallVolume);
        // console.log(minVal[0].IOS_USD,appPlat)
        console.log("minVal", minVal);
        if (value == "") {
          setFocusDpdError("Please select a value");
        } else if (target == "") {
          settargetDpdError("Please select a value");
        } else if (minVal.length != 0) {
          // let temp = ""
          // if (currenctCode != "INR") {
          // console.log(focus == "appActions", focus == "installVolume");
          if (value == "installVolume") {
            if (appPlatform == "Android") {
              if (
                minVal[0].InstallVolume.AOS != "" &&
                minVal[0].InstallVolume.AOS > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InstallVolume.AOS}`
                );
              } else {
                setCostPerInstallError("");
              }
            } else if (appPlatform == "APK") {
              if (
                minVal[0].InstallVolume.APK != "" &&
                minVal[0].InstallVolume.APK > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InstallVolume.APK}`
                );
              } else {
                setCostPerInstallError("");
              }
            } else {
              if (
                minVal[0].InstallVolume.IOS != "" &&
                minVal[0].InstallVolume.IOS > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InstallVolume.IOS}`
                );
              } else {
                setCostPerInstallError("");
              }
            }
          } else {
            if (appPlatform == "Android") {
              if (
                minVal[0].InAppBidding.AOS != "" &&
                minVal[0].InAppBidding.AOS > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InAppBidding.AOS}`
                );
              } else {
                setCostPerInstallError("");
              }
            } else if (appPlatform == "APK") {
              if (
                minVal[0].InAppBidding.APK != "" &&
                minVal[0].InAppBidding.APK > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InAppBidding.APK}`
                );
              } else {
                setCostPerInstallError("");
              }
            } else {
              if (
                minVal[0].InAppBidding.IOS != "" &&
                minVal[0].InAppBidding.IOS > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InAppBidding.IOS}`
                );
              } else {
                setCostPerInstallError("");
              }
            }
          }
        }
      }
      setTargetAppDpd(arr);
    }
  };

  const checkTransactionCurrency = (value: any) => {
    dispatch(setTransactionCurrency(value));
    handleCurrency(value);

    let targetingArray = [];
    targetingArray = getTargetingTypesMaster();
    if (targetingArray && Array.isArray(targetingArray)) {
      let arr: any = [];
      // value == "USD"
      //   ? targetingArray.filter((val: any) => {
      //       return val.Country.indexOf(locationCode) != -1;
      //     })
      //   : targetingArray.filter((val: any) => {
      //       return val.Country.indexOf("IN-USD") != -1;
      //     });

      if (value == "USD") {
        // console.log("here");
        if (countryCodeAdvertiser == "IN" && locationCode == "IN") {
          arr = targetingArray.filter((val: any) => {
            return (
              val.Country.indexOf(`${countryCodeAdvertiser}-${value}`) != -1
            );
          });
        } else if (countryCodeAdvertiser == "IN" && locationCode != "IN") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(locationCode) != -1;
          });
        } else if (countryCodeAdvertiser != "IN" && locationCode == "IN") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(`${locationCode}-${value}`) != -1;
          });
        } else {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(countryCodeAdvertiser) != -1;
          });
        }
      } else {
        if (countryCodeAdvertiser == "IN" && locationCode != "IN") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(`${locationCode}-INR`) != -1;
          });
        } else if (countryCodeAdvertiser == "IN") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(locationCode) != -1;
          });
        } else if (countryCodeAdvertiser == "") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(`${locationCode}-INR`) != -1;
          });
        } else {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(`${locationCode}-INR`) != -1;
          });
        }
      }
      console.log("countryCodeAdvertiser", arr);
      setTargetAppDpd(arr);
      if (target != null && focus != null && target != "" && focus != "") {
        setCostPerInstallError("");
        // dispatch(setCostPerInstall(value));
        const minVal = arr.filter((item: any) => item.TargetingType == target);

        // console.log(minVal[0].InstallVolume);
        // console.log(minVal[0].IOS_USD,appPlat)
        console.log("minVal", minVal);
        if (focus == "") {
          setFocusDpdError("Please select a value");
        } else if (target == "") {
          settargetDpdError("Please select a value");
        } else if (minVal.length != 0) {
          // let temp = ""
          // if (currenctCode != "INR") {
          // console.log(focus == "appActions", focus == "installVolume");
          if (focus == "installVolume") {
            if (value == "Android") {
              if (
                minVal[0].InstallVolume.AOS != "" &&
                minVal[0].InstallVolume.AOS > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InstallVolume.AOS}`
                );
              } else {
                setCostPerInstallError("");
              }
            } else if (value == "APK") {
              if (
                minVal[0].InstallVolume.APK != "" &&
                minVal[0].InstallVolume.APK > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InstallVolume.APK}`
                );
              } else {
                setCostPerInstallError("");
              }
            } else {
              if (
                minVal[0].InstallVolume.IOS != "" &&
                minVal[0].InstallVolume.IOS > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InstallVolume.IOS}`
                );
              } else {
                setCostPerInstallError("");
              }
            }
          } else {
            if (value == "Android") {
              if (
                minVal[0].InAppBidding.AOS != "" &&
                minVal[0].InAppBidding.AOS > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InAppBidding.AOS}`
                );
              } else {
                setCostPerInstallError("");
              }
            } else if (appPlatform == "APK") {
              if (
                minVal[0].InAppBidding.APK != "" &&
                minVal[0].InAppBidding.APK > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InAppBidding.APK}`
                );
              } else {
                setCostPerInstallError("");
              }
            } else {
              if (
                minVal[0].InAppBidding.IOS != "" &&
                minVal[0].InAppBidding.IOS > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InAppBidding.IOS}`
                );
              } else {
                setCostPerInstallError("");
              }
            }
          }
        }
      }
      setTargetAppDpd(arr);
    }
  };

  const goBack = () => {
    window.history.back();
  };

  // useEffect(() => {
  //   console.log("tokenEvent", tokenEvent != null);
  // }, []);
  const textFieldStyle = useStylesTextField();

  const MAX_WIDTH = 1200;
  const MAX_HEIGHT = 1200;
  function validateImageSize(image: File, width: number, height: number) {
    return new Promise<boolean>((resolve) => {
      const img = new Image();
      img.onload = () => {
        const isValidSize = img.width <= width && img.height <= height;
        resolve(isValidSize);
      };
      img.src = URL.createObjectURL(image);
    });
  }
  const handleChangeAppImage = (e: ChangeEvent<HTMLInputElement>) => {
    console.log(e, "changed");
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files?.[0];
      if (selectedFile) {
        validateImageSize(selectedFile, MAX_WIDTH, MAX_HEIGHT).then(
          (isValidSize) => {
            if (isValidSize) {
              if (e.target.files && e.target.files[0]) {
                // setSelectedFile(e.target.files[0]);
                // setState((prevState)=>({...prevState,appImageUrl:URL.createObjectURL(e.target.files[0])}))
              }

              // dispatch(setLogoUrl(URL.createObjectURL(selectedFile)));
              // setFile(URL.createObjectURL(selectedFile));
              // dispatch(setLogoError(""));
            } else {
              alert(
                `Selected image must be ${MAX_WIDTH}x${MAX_HEIGHT} pixels or smaller.`
              );
            }
          }
        );
      }
      // if (logoError == "") {
      uploadFiles(
        e.target.files,
        "admattic-internal-data-storage",
        "AdservingPlatform/appImageUrl",
        "LOGO"
      );
      // dispatch(setLogoUrl(URL.createObjectURL(e.target.files[0])));
      // }
    }
  };
  AWS.config.update({
    region: "ap-south-1",
    accessKeyId: "AKIA5VMVCATGUK75O2XE",
    secretAccessKey: "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",
  });

  const s3 = new AWS.S3();

  async function uploadToS3(
    file: File,
    bucketName: string,
    folderName: string,
    fileName: string
  ): Promise<string> {
    const params = {
      Bucket: bucketName,
      Key: `${folderName}/${fileName}`,
      Body: file,
    };

    return new Promise((resolve, reject) => {
      s3.putObject(params, async (err, data) => {
        if (err) {
          console.error("Error uploading to S3:", err);
          reject(err);
        } else {
          // console.log("Successfully uploaded to S3", data);

          // Get the URL for the uploaded file

          const url = s3.getSignedUrl("getObject", {
            Bucket: bucketName,
            Key: `${folderName}/${fileName}`,
          });

          resolve(url);
        }
      });
    });
  }
  function cropUrlBeforeQuestionMark(url: string): string {
    const parts = url.split("?"); // Split the URL string based on the question mark
    return parts[0]; // Return the part before the question mark
  }
  const uploadFiles = (
    files: FileList,
    bucketName: string,
    folderName: string,
    fileType: string
  ) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      uploadToS3(file, bucketName, folderName, file.name)
        .then((res) => {
          const croppedUrl = cropUrlBeforeQuestionMark(res);
          // dispatch(setAppImageUrl(croppedUrl.toString()));
          console.log(croppedUrl);
          if (fileType === "LOGO") {
            setState((prevState) => ({
              ...prevState,
              appImageUrl: croppedUrl,
            }));
          } else {
            setState((prevState) => ({
              ...prevState,
              companyImage: croppedUrl,
            }));
          }

          // console.log(croppedUrl);
          // console.log(res);

          // console.log("here", croppedUrl);
          // localStorage.setItem("logoUrl", croppedUrl.toString());
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onChangeDpd = (event: SelectChangeEvent<string>, type: string) => {
    const value = event.target.value;
    switch (type) {
      case "STATUS":
        setState((prevState) => ({ ...prevState, status: value }));
        break;
      case "APP_PLATFORM":
        setState((prevState) => ({ ...prevState, appPlatform: value }));
        break;

      default:
        break;
    }
  };

  const onChangeAll = (event: ChangeEvent<HTMLInputElement>, type: string) => {
    const value = event.target.value;

    switch (type) {
      case "COMPANY_NAME":
        setState((prevState) => ({ ...prevState, companyName: value }));
        break;
      case "CAMPAIGN_NAME":
        setState((prevState) => ({ ...prevState, campaignName: value }));
        break;
      case "APP_NAME":
        setState((prevState) => ({ ...prevState, appName: value }));
        break;
    }
  };

  const CustomSelectStatus = styled(Select)({
    height: 50,
    width: 150,
    "&.Mui-focused fieldset": {
      border: "1.4px solid rgba(51, 51, 51, 0.3)",
      outline: "none",
    },
    "& .MuiSelect-select:focus": {
      background: "transparent",
      border: "none",
      outline: "none",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1.4px solid rgba(51, 51, 51, 0.3)",
      outline: "none",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1.4px solid rgba(51, 51, 51, 0.3)",
      outline: "none",
    },
    "& MuiSelect": {
      // height: 10,
      paddingTop: 0,
      paddingBottom: 0,
      border: "none",
      outline: "none",
    },
    "& fieldset": {
      padding: 0,
      border: "1.4px solid rgba(51, 51, 51, 0.3)",
      outline: "none",
    },
  });

  const CustomSelect = styled(Select)({
    height: 35,

    "&.Mui-focused fieldset": {
      border: "1.4px solid rgba(51, 51, 51, 0.3)",
      outline: "none",
    },
    "& .MuiSelect-select:focus": {
      background: "transparent",
      border: "none",
      outline: "none",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1.4px solid rgba(51, 51, 51, 0.3)",
      outline: "none",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1.4px solid rgba(51, 51, 51, 0.3)",
      outline: "none",
    },
    "& MuiSelect": {
      // height: 10,
      paddingTop: 0,
      paddingBottom: 0,
      border: "none",
      outline: "none",
    },
    "& fieldset": {
      padding: 0,
      border: "1.4px solid rgba(51, 51, 51, 0.3)",
      outline: "none",
    },
  });

  // const CustomTextField = styled(TextField)(({ theme }) => ({
  //   width: "290px",
  //   height: "35px",
  //   "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
  //     padding: "6px 14px",
  //   },

  //   "& .MuiOutlinedInput-root": {
  //     "&.Mui-focused fieldset": {
  //       borderColor: "rgba(51, 51, 51, 0.3)",
  //       borderWidth: "1px",
  //       outline: "transparent",
  //     },
  //     "&:hover fieldset": {
  //       borderColor: "rgba(51, 51, 51, 0.3)",
  //       borderWidth: "1px",
  //       outline: "transparent",
  //     },
  //   },
  // }));

  const handleChangeCustom = (val: any, type: string) => {
    let value = val.target.value;
    switch (type) {
      case "STATUS":
        setState((prevState) => ({ ...prevState, status: value }));
        return;
      case "LOCATION":
        dispatch(setLocation(value));
        dispatch(setLocationCode(value));
        onCheckCountry(value);
        setState((prevState) => ({
          ...prevState,
          location: value,
          locationCode: value,
        }));
        return;
      case "TRANSACTION_CURRENCY":
        dispatch(setTransactionCurrency(value));
        // handleCurrency(value);
        checkTransactionCurrency(value);
        let targetingArray = [];
        targetingArray = getTargetingTypesMaster();
        if (targetingArray && Array.isArray(targetingArray)) {
          let arr: any = [];

          if (value == "USD") {
            // console.log("here");
            if (countryCodeAdvertiser == "IN" && locationCode == "IN") {
              arr = targetingArray.filter((val: any) => {
                return (
                  val.Country.indexOf(`${countryCodeAdvertiser}-${value}`) != -1
                );
              });
            } else if (countryCodeAdvertiser == "IN" && locationCode != "IN") {
              arr = targetingArray.filter((val: any) => {
                return val.Country.indexOf(locationCode) != -1;
              });
            } else if (countryCodeAdvertiser != "IN" && locationCode == "IN") {
              arr = targetingArray.filter((val: any) => {
                return val.Country.indexOf(`${locationCode}-${value}`) != -1;
              });
            } else {
              arr = targetingArray.filter((val: any) => {
                return val.Country.indexOf(countryCodeAdvertiser) != -1;
              });
            }
          } else {
            if (countryCodeAdvertiser == "IN" && locationCode != "IN") {
              arr = targetingArray.filter((val: any) => {
                return val.Country.indexOf(`${locationCode}-INR`) != -1;
              });
            } else if (countryCodeAdvertiser == "IN") {
              arr = targetingArray.filter((val: any) => {
                return val.Country.indexOf(locationCode) != -1;
              });
            } else if (countryCodeAdvertiser == "") {
              arr = targetingArray.filter((val: any) => {
                return val.Country.indexOf(`${locationCode}-INR`) != -1;
              });
            } else {
              arr = targetingArray.filter((val: any) => {
                return val.Country.indexOf(`${locationCode}-INR`) != -1;
              });
            }
          }

          setTargetAppDpd(arr);
        }

        setState((prevState) => ({ ...prevState, transactionCurrency: value }));
        return;
      case "APP_PLATFORM":
        // checkTransactionCurrency(transactionCurrency);
        checkAppPlatform(value);
        dispatch(setAppPlatform(value));
        setState((prevState) => ({ ...prevState, appPlatform: value }));
        return;
      case "TRACKING":
        dispatch(setTracking(value));
        setState((prevState) => ({ ...prevState, tracking: value }));
        if (state.trackingUrl.includes(value) && value != "branch") {
          setTrackingError("");
        } else if (
          state.trackingUrl.includes("app.link") &&
          value == "branch"
        ) {
          setTrackingError("");
        } else {
          setTrackingError("Invalid tracking URL");
        }
        return;
      case "FOCUS":
        dispatch(setFocus(value));
        setFocusDpdError("");
        checkAppBidding(value);
        setState((prevState) => ({ ...prevState, focus: value }));
        return;
      case "TRACKING_URL":
        dispatch(setTrackingUrl(value));
        setState((prevState) => ({ ...prevState, trackingUrl: value }));
        return;
      case "IMPRESSION_URL":
        setState((prevState) => ({ ...prevState, impressionUrl: value }));
        return;
      case "TARGET":
        dispatch(setTarget(value));
        setShowTarget(false);
        settargetDpdError("");
        setDailyTargetDisabled(false);
        onCheckTarget(value);
        // checkTransactionCurrency(transactionCurrency);
        setState((prevState) => ({ ...prevState, target: value }));
        return;
      default:
        return;
    }
  };
  function isValidURL(url: string) {
    // const urlPattern =
    //   /^(https?:\/\/)?([a-z0-9-]+\.)*[a-z0-9-]+\.[a-z]{2,}(:[0-9]+)?(\/.*)?$/i;
    const urlPattern =
      /^(https?:\/\/)?([a-z0-9-]+\.)*[a-z0-9-]+\.[a-z]{2,}(:[0-9]+)?(\/[^?#]*)?(\?[^?#]*)?(#.*)?$/i;

    return urlPattern.test(url);
  }

  const onCheckCountry = (value: string) => {
    console.log(value, transactionCurrency);
    let targetingArray = [];
    targetingArray = getTargetingTypesMaster();
    if (targetingArray && Array.isArray(targetingArray)) {
      let arr: any = [];
      if (transactionCurrency == "USD") {
        // console.log("here");
        if (countryCodeAdvertiser == "IN" && value == "IN") {
          arr = targetingArray.filter((val: any) => {
            return (
              val.Country.indexOf(`${countryCodeAdvertiser}-${value}`) != -1
            );
          });
        } else if (countryCodeAdvertiser == "IN" && value != "IN") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(value) != -1;
          });
        } else if (countryCodeAdvertiser != "IN" && value == "IN") {
          console.log("here");
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(`${value}-${transactionCurrency}`) != -1;
          });
        } else if (countryCodeAdvertiser != "IN" && value != "IN") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(value) != -1;
          });
        } else {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(countryCodeAdvertiser) != -1;
          });
        }
      } else {
        if (countryCodeAdvertiser == "IN" && value != "IN") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(`${value}-INR`) != -1;
          });
        } else if (countryCodeAdvertiser == "IN") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(value) != -1;
          });
        } else if (countryCodeAdvertiser == "") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(`${value}-INR`) != -1;
          });
        } else if (countryCodeAdvertiser != "IN") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(`${value}-INR`) != -1;
          });
        }
      }
      console.log(arr);
      setTargetAppDpd(arr);
      if (target != null && focus != null && target != "" && focus != "") {
        setCostPerInstallError("");
        // dispatch(setCostPerInstall(value));
        const minVal = arr.filter((item: any) => item.TargetingType == target);

        // console.log(minVal[0].InstallVolume);
        // console.log(minVal[0].IOS_USD,appPlat)
        console.log("minVal", minVal);
        if (focus == "") {
          setFocusDpdError("Please select a value");
        } else if (target == "") {
          settargetDpdError("Please select a value");
        } else if (minVal.length != 0) {
          // let temp = ""
          // if (currenctCode != "INR") {
          // console.log(focus == "appActions", focus == "installVolume");
          if (focus == "installVolume") {
            if (appPlatform == "Android") {
              if (
                minVal[0].InstallVolume.AOS != "" &&
                minVal[0].InstallVolume.AOS > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InstallVolume.AOS}`
                );
              } else {
                setCostPerInstallError("");
              }
            } else if (appPlatform == "APK") {
              if (
                minVal[0].InstallVolume.APK != "" &&
                minVal[0].InstallVolume.APK > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InstallVolume.APK}`
                );
              } else {
                setCostPerInstallError("");
              }
            } else {
              if (
                minVal[0].InstallVolume.IOS != "" &&
                minVal[0].InstallVolume.IOS > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InstallVolume.IOS}`
                );
              } else {
                setCostPerInstallError("");
              }
            }
          } else {
            if (appPlatform == "Android") {
              if (
                minVal[0].InAppBidding.AOS != "" &&
                minVal[0].InAppBidding.AOS > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InAppBidding.AOS}`
                );
              } else {
                setCostPerInstallError("");
              }
            } else if (appPlatform == "APK") {
              if (
                minVal[0].InAppBidding.APK != "" &&
                minVal[0].InAppBidding.APK > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InAppBidding.APK}`
                );
              } else {
                setCostPerInstallError("");
              }
            } else {
              if (
                minVal[0].InAppBidding.IOS != "" &&
                minVal[0].InAppBidding.IOS > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InAppBidding.IOS}`
                );
              } else {
                setCostPerInstallError("");
              }
            }
          }
        }
      }
    }
  };

  const onCheckTarget = (value: string) => {
    let targetingArray = [];
    targetingArray = getTargetingTypesMaster();
    if (targetingArray && Array.isArray(targetingArray)) {
      let arr: any = [];
      // value == "USD"
      //   ? targetingArray.filter((val: any) => {
      //       return val.Country.indexOf(locationCode) != -1;
      //     })
      //   : targetingArray.filter((val: any) => {
      //       return val.Country.indexOf("IN-USD") != -1;
      //     });

      if (transactionCurrency == "USD") {
        // console.log("here");
        if (countryCodeAdvertiser == "IN" && locationCode == "IN") {
          arr = targetingArray.filter((val: any) => {
            return (
              val.Country.indexOf(`${countryCodeAdvertiser}-${value}`) != -1
            );
          });
        } else if (countryCodeAdvertiser == "IN" && locationCode != "IN") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(locationCode) != -1;
          });
        } else if (countryCodeAdvertiser != "IN" && locationCode == "IN") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(`${locationCode}-${transactionCurrency}`) != -1;
          });
        } else {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(countryCodeAdvertiser) != -1;
          });
        }
      } else {
        if (countryCodeAdvertiser == "IN" && locationCode != "IN") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(`${locationCode}-INR`) != -1;
          });
        } else if (countryCodeAdvertiser == "IN") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(locationCode) != -1;
          });
        } else if (countryCodeAdvertiser == "") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(`${locationCode}-INR`) != -1;
          });
        } else if (countryCodeAdvertiser != "IN") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(`${locationCode}-INR`) != -1;
          });
        }
      }
      console.log("countryCodeAdvertiser", arr);
      setTargetAppDpd(arr);
      if (value != null && focus != null && value != "" && focus != "") {
        setCostPerInstallError("");
        // dispatch(setCostPerInstall(value));
        const minVal = arr.filter((item: any) => item.TargetingType == value);

        // console.log(minVal[0].InstallVolume);
        // console.log(minVal[0].IOS_USD,appPlat)
        console.log("minVal", minVal);
        if (focus == "") {
          setFocusDpdError("Please select a value");
        } else if (value == "") {
          settargetDpdError("Please select a value");
        } else if (minVal.length != 0) {
          // let temp = ""
          // if (currenctCode != "INR") {
          // console.log(focus == "appActions", focus == "installVolume");
          if (focus == "installVolume") {
            if (appPlatform == "Android") {
              if (
                minVal[0].InstallVolume.AOS != "" &&
                minVal[0].InstallVolume.AOS > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InstallVolume.AOS}`
                );
              } else {
                setCostPerInstallError("");
              }
            } else if (appPlatform == "APK") {
              if (
                minVal[0].InstallVolume.APK != "" &&
                minVal[0].InstallVolume.APK > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InstallVolume.APK}`
                );
              } else {
                setCostPerInstallError("");
              }
            } else {
              if (
                minVal[0].InstallVolume.IOS != "" &&
                minVal[0].InstallVolume.IOS > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InstallVolume.IOS}`
                );
              } else {
                setCostPerInstallError("");
              }
            }
          } else {
            if (appPlatform == "Android") {
              if (
                minVal[0].InAppBidding.AOS != "" &&
                minVal[0].InAppBidding.AOS > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InAppBidding.AOS}`
                );
              } else {
                setCostPerInstallError("");
              }
            } else if (appPlatform == "APK") {
              if (
                minVal[0].InAppBidding.APK != "" &&
                minVal[0].InAppBidding.APK > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InAppBidding.APK}`
                );
              } else {
                setCostPerInstallError("");
              }
            } else {
              if (
                minVal[0].InAppBidding.IOS != "" &&
                minVal[0].InAppBidding.IOS > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InAppBidding.IOS}`
                );
              } else {
                setCostPerInstallError("");
              }
            }
          }
        }
      }
      setTargetAppDpd(arr);
    }
  };

  const toggleEdit = () => {
    seteditCampaign(!editCampaign);
  };

  const onChangeCostPerInstall = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const appPlat = localStorage.getItem("appPlatform");
    const currenctCode = localStorage.getItem("countryCode");

    let value = event.target.value;

    setState((prevState) => ({
      ...prevState,
      costPerInstall: value,
    }));
    setCostPerInstallError("");
    dispatch(setCostPerInstall(value));
    const minVal = targetAppDpd.filter(
      (item: any) => item.TargetingType == target
    );

    // console.log(minVal[0].InstallVolume);
    // console.log(minVal[0].IOS_USD,appPlat)
    console.log("minVal", minVal);
    if (focus == "") {
      setFocusDpdError("Please select a value");
    } else if (target == "") {
      settargetDpdError("Please select a value");
    } else if (minVal.length != 0) {
      // let temp = ""
      // if (currenctCode != "INR") {
      console.log(focus == "appActions", focus == "installVolume");
      if (focus == "installVolume") {
        if (appPlat == "Android") {
          if (
            minVal[0].InstallVolume.AOS != "" &&
            minVal[0].InstallVolume.AOS > Number(value)
          ) {
            setCostPerInstallError(
              `Please enter minimum amount ${minVal[0].InstallVolume.AOS}`
            );
          } else {
            setCostPerInstallError("");
          }
        } else if (appPlat == "APK") {
          if (
            minVal[0].InstallVolume.APK != "" &&
            minVal[0].InstallVolume.APK > Number(value)
          ) {
            setCostPerInstallError(
              `Please enter minimum amount ${minVal[0].InstallVolume.APK}`
            );
          } else {
            setCostPerInstallError("");
          }
        } else {
          if (
            minVal[0].InstallVolume.IOS != "" &&
            minVal[0].InstallVolume.IOS > Number(value)
          ) {
            setCostPerInstallError(
              `Please enter minimum amount ${minVal[0].InstallVolume.IOS}`
            );
          } else {
            setCostPerInstallError("");
          }
        }
      } else {
        if (appPlat == "Android") {
          if (
            minVal[0].InAppBidding.AOS != "" &&
            minVal[0].InAppBidding.AOS > Number(value)
          ) {
            setCostPerInstallError(
              `Please enter minimum amount ${minVal[0].InAppBidding.AOS}`
            );
          } else {
            setCostPerInstallError("");
          }
        } else if (appPlat == "APK") {
          if (
            minVal[0].InAppBidding.APK != "" &&
            minVal[0].InAppBidding.APK > Number(value)
          ) {
            setCostPerInstallError(
              `Please enter minimum amount ${minVal[0].InAppBidding.APK}`
            );
          } else {
            setCostPerInstallError("");
          }
        } else {
          if (
            minVal[0].InAppBidding.IOS != "" &&
            minVal[0].InAppBidding.IOS > Number(value)
          ) {
            setCostPerInstallError(
              `Please enter minimum amount ${minVal[0].InAppBidding.IOS}`
            );
          } else {
            setCostPerInstallError("");
          }
        }
      }

      // }
      // else {
      //   if (appPlat == "Android") {
      //     if (minVal[0].AOS > Number(value)) {
      //       setCostPerInstallError(
      //         `Please enter minimum amount ${minVal[0].AOS}`
      //       );
      //     } else {
      //       setCostPerInstallError("");
      //     }
      //   } else if (appPlat == "APK") {
      //     if (minVal[0].APK > Number(value) && minVal[0].APK != null) {
      //       setCostPerInstallError(
      //         `Please enter minimum amount ${minVal[0].APK}`
      //       );
      //     } else {
      //       setCostPerInstallError("");
      //     }
      //   } else {
      //     if (minVal[0].IOS > Number(value)) {
      //       setCostPerInstallError(
      //         `Please enter minimum amount ${minVal[0].IOS}`
      //       );
      //     } else {
      //       setCostPerInstallError("");
      //     }
      //   }
      // }
    }
    localStorage.setItem("costPerInstall", value);
  };

  const performanceReport = (
    startParamDate: Date,
    endParamDate: Date,
    campaign_Id: string
  ) => {
    // console.log(startDate,endDate)
    const startDate = new Date(startParamDate);
    const endDate = new Date(endParamDate);
    const encryptAdvertiserID = CryptoJS.AES.encrypt(
      JSON.stringify(advertiserId),
      secretKey
    ).toString();

    const year = startDate.getFullYear();
    const month = startDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month index
    const day = startDate.getDate();

    const startDatFormatted = `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`;

    const endyear = endDate.getFullYear();
    const endmonth = endDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month index
    const endday = endDate.getDate();

    const endDateFormatted = `${endyear}-${
      endmonth < 10 ? "0" + endmonth : endmonth
    }-${endday < 10 ? "0" + endday : endday}`;

    const jsonParam = {
      // advertiserId: isadmin ? selectedAdvertiserId : advertiserId,
      startDate: startDatFormatted,
      endDate: endDateFormatted,
      campaignId: [campaign_Id],
    };
    // console.log(startDatFormatted,endDateFormatted)

    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(jsonParam),
      secretKey
    ).toString();

    const url = `${apiURL}/api/admin/campaigns/performanceReport`;
    // const url = `http://localhost:10056/api/admin/campaigns/getAllCampaignTableData`
    if (authToken != "" && sessionId != "" && advertiserId != "") {
      axios
        .post(
          url,
          { params: encryptedData },
          {
            headers: {
              authtoken: authToken,
              sessionid: sessionId,
              advertiserid: encryptAdvertiserID,
            },
          }
        )
        .then((res) => {
          const resData = res.data.data;
          const bytes = CryptoJS.AES.decrypt(resData, secretKey);
          const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
          const parsedData = JSON.parse(decryptedData);
          console.log(parsedData);
          if (res.data.status) {
            if (Object.keys(parsedData).length !== 0) {
              let key = Object.keys(parsedData);
              if (parsedData[advertiserIdCampaign].length != 0) {
                getCalCulations(
                  startParamDate,
                  endParamDate,
                  campaign_Id,
                  parsedData[advertiserIdCampaign][0].Clicks,
                  parsedData[advertiserIdCampaign][0].ExchangeRate,
                  parsedData[advertiserIdCampaign][0].Impressions,
                  parsedData[advertiserIdCampaign][0].Installs
                );
                setState((prevState) => ({
                  ...prevState,
                  clicks: parsedData[advertiserIdCampaign][0].Clicks,
                  cpi: parsedData[advertiserIdCampaign][0].Installs,
                  impression: parsedData[advertiserIdCampaign][0].Impressions,
                  install: parsedData[advertiserIdCampaign][0].Installs,
                  conversion: parsedData[advertiserIdCampaign][0].ExchangeRate,
                }));
              }
            } else {
              getCalCulations(
                startParamDate,
                endParamDate,
                campaign_Id,
                "0",
                "0",
                "0",
                "0"
              );
              setState((prevState) => ({
                ...prevState,
                clicks: "0",
                cpi: "0",
                conversion: "0",
                impression: "0",
                install: "0",
              }));
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  function calculatePercentage(part: any, total: any): number {
    const convertedPart = Number(part);
    const convertedTotal = Number(total);

    if (convertedTotal === 0) {
      return 0;
    }
    return (convertedPart / convertedTotal) * 100;
  }
  const getCalCulations = (
    startParamDate: Date,
    endParamDate: Date,
    campaign_Id: string,
    clicks: any,
    conversion: any,
    impression: any,
    install: any
  ) => {
    const startDate = new Date(startParamDate);
    const endDate = new Date(endParamDate);
    const encryptAdvertiserID = CryptoJS.AES.encrypt(
      JSON.stringify(advertiserId),
      secretKey
    ).toString();

    const year = startDate.getFullYear();
    const month = startDate.getMonth(); // Adding 1 because getMonth() returns zero-based month index
    const day = startDate.getDate();

    const startDatFormatted = `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`;

    const endyear = endDate.getFullYear();
    const endmonth = endDate.getMonth(); // Adding 1 because getMonth() returns zero-based month index
    const endday = endDate.getDate();

    const endDateFormatted = `${endyear}-${
      endmonth < 10 ? "0" + endmonth : endmonth
    }-${endday < 10 ? "0" + endday : endday}`;

    console.log(startDatFormatted, endDateFormatted);
    const jsonParam = {
      // advertiserId: isadmin ? selectedAdvertiserId : advertiserId,
      startDate: startDatFormatted,
      endDate: endDateFormatted,
      campaignId: [campaign_Id],
    };

    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(jsonParam),
      secretKey
    ).toString();

    const url = `${apiURL}/api/admin/campaigns/performanceReport`;
    // const url = `http://localhost:10056/api/admin/campaigns/getAllCampaignTableData`
    if (authToken != "" && sessionId != "" && advertiserId != "") {
      axios
        .post(
          url,
          { params: encryptedData },
          {
            headers: {
              authtoken: authToken,
              sessionid: sessionId,
              advertiserid: encryptAdvertiserID,
            },
          }
        )
        .then((res) => {
          const resData = res.data.data;
          const bytes = CryptoJS.AES.decrypt(resData, secretKey);
          const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
          const parsedData = JSON.parse(decryptedData);
          console.log(Object.keys(parsedData).length !== 0);

          if (res.data.status) {
            if (Object.keys(parsedData).length !== 0) {
              let key = Object.keys(parsedData);
              if (parsedData[advertiserIdCampaign].length != 0) {
                setCalState((prevState) => ({
                  ...prevState,
                  calClicks: calculatePercentage(
                    clicks,
                    parsedData[advertiserIdCampaign][0].Clicks
                  ),
                  calConversion: calculatePercentage(
                    conversion,
                    parsedData[advertiserIdCampaign][0].ExchangeRate
                  ),

                  calInstall: calculatePercentage(
                    install,
                    parsedData[advertiserIdCampaign][0].Installs
                  ),
                }));
                // setState((prevState) => ({
                //   ...prevState,
                //   clicks: parsedData[advertiserIdCampaign][0].Clicks,
                //   cpi: parsedData[advertiserIdCampaign][0].Installs,
                //   impression: parsedData[advertiserIdCampaign][0].Impressions,
                //   install: parsedData[advertiserIdCampaign][0].Installs,
                // }));
              }
            } else {
              const crppedClicks =
                Number(clicks) != 0
                  ? calculatePercentage(clicks, 1)
                  : calculatePercentage(clicks, 0);
              const crppedConversion =
                Number(conversion) != 0
                  ? calculatePercentage(conversion, 1)
                  : calculatePercentage(conversion, 0);
              const crppedInstall =
                Number(install) != 0
                  ? calculatePercentage(install, 1)
                  : calculatePercentage(install, 0);
              setCalState((prevState) => ({
                ...prevState,
                calClicks: cropToThreeDigits(crppedClicks.toString()),
                calConversion: cropToThreeDigits(crppedConversion.toString()),
                calInstall: cropToThreeDigits(crppedInstall.toString()),
              }));
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  function cropToThreeDigits(num: string): string {
    const numStr = num.toString();
    const decimalIndex = numStr.indexOf(".");

    if (decimalIndex === -1) {
      // Not a decimal number, crop to 3 digits
      return numStr.substring(0, 3);
    } else {
      // Decimal number, crop to 2 decimal places
      const integerPart = numStr.substring(0, 3);
      const decimalPart = numStr.substring(decimalIndex + 1, decimalIndex + 3);
      return `${integerPart}.${decimalPart}`;
    }
  }
  const toggleSave = () => {
    if (budget != null && budget == "" && budgetError == "") {
      setBudgetError("Please enter budget.");
    } else if (focus != null && focus == "" && focusDpdError == "") {
      setFocusDpdError("Please enter a value.");
    } else if (target != null && target == "" && targetDpdError == "") {
      settargetDpdError("Please enter a value.");
    } else if (
      costPerInstall != null &&
      costPerInstall == "" &&
      costPerInstallError == ""
    ) {
      setCostPerInstallError("Please enter a cost.");
    }
    if (trackingUrl != null && trackingUrl == "" && trackingError == "") {
      setTrackingError("Please enter a valid tracking URL.");
    } else if (
      budgetError != "" ||
      focusDpdError != "" ||
      targetDpdError != "" ||
      costPerInstallError != "" ||
      trackingError != ""
    ) {
      return;
    } else {
      UpdateCampaignApi();
      dispatch(setTableData([]));
      seteditCampaign(false);

      dispatch(setCampaignSubType(state.campaignSubtype));
      dispatch(setAppPlatform(state.appPlatform));
      dispatch(setAppName(state.appName));
      dispatch(setCampaignName(state.campaignName));
      dispatch(setAppImageUrl(state.appImageUrl));
      dispatch(setPackageName(state.packageName));

      dispatch(setBudget(state.budget));
      dispatch(setFocus(state.target));
      dispatch(setTarget(state.focus));
      dispatch(setCostPerInstall(state.costPerInstall));

      dispatch(setLocation(state.location));
      dispatch(setLanguage(state.language));
      dispatch(setTracking(state.tracking));
      dispatch(setTrackingUrl(state.trackingUrl));
      dispatch(setImpressionUrl(state.impressionUrl));
      dispatch(setTokenEvent(state.tokenEvent));
      dispatch(setLocationCode(state.locationCode));

      dispatch(setCompanyImage(state.companyImage));
      dispatch(setAdGroupName(state.adGroupName));
      dispatch(setLogoUrl(state.logoUrl));
      dispatch(setHeadline(state.headline));
      dispatch(setDescription(state.description));
      dispatch(setImageUrl(state.imageUrl));
      dispatch(setVideoUrl(state.videoUrl));
      dispatch(setHtmlUrl(state.htmlUrl));
      dispatch(setBudgetId(state.budget_Id));
      dispatch(setCampaignId(state.campaign_Id));

      dispatch(setStatus(state.status));
      dispatch(setTransactionCurrency(state.transactionCurrency));
      dispatch(setCompanyName(state.companyName));

      setState({
        companyImage: state.companyImage,
        campaignSubtype: state.campaignSubtype,
        appPlatform: state.appPlatform,
        appName: state.appName,
        campaignName: state.campaignName,
        appImageUrl: state.appImageUrl,
        packageName: state.packageName,

        location: state.location,
        language: state.language,
        tracking: state.tracking,
        trackingUrl: state.trackingUrl,
        impressionUrl: state.impressionUrl,
        tokenEvent: state.tokenEvent,
        // showLower?: boolean;
        locationCode: state.locationCode,

        budget: state.budget,
        target: state.target,
        focus: state.focus,
        costPerInstall: state.costPerInstall,

        adGroupName: state.adGroupName,
        logoUrl: state.logoUrl,
        headline: state.headline,
        description: state.description,

        imageUrl: state.imageUrl,
        videoUrl: state.videoUrl,
        htmlUrl: state.htmlUrl,
        count: count,

        budget_Id: state.budget_Id,
        campaign_Id: state.campaign_Id,

        status: state.status,
        authToken: state.authToken,
        sessionId: state.sessionId,
        advertiserId: state.advertiserId,
        selectedAdvertiserId: state.selectedAdvertiserId,
        selectedDateRange: state.selectedDateRange,
        companyName: state.companyName,

        campaignStatus: state.campaignStatus,
        advertiserIdCampaign: state.advertiserIdCampaign,

        transactionCurrency: state.transactionCurrency,
        clicks: state.clicks,
        impression: state.impression,
        conversion: state.conversion,
        install: state.install,
        payout: state.payout,
        ctr: state.ctr,
        cpi: state.cpi,
        dailyAvgBudget: state.dailyAvgBudget,
        creditLimit: state.creditLimit,
      });
    }
  };

  const budgetUpdate = async (budgetId: string, campaign: string) => {
    console.log(state.budget);
    const jsonParam = {
      budgetId: budgetId,
      campaignId: campaign,
      dailyAvgBudget: state.budget != "" ? parseInt(state.budget, 10) : 0,
      // targetUserType: trackDpd,
      isSetTargetCoatPerInstall: false,
      // target != "" ? (target == "true" ? true : false) : false,
      targetCostPerInstall:
        state.costPerInstall != "" ? parseInt(state.costPerInstall) : 0,
      focusParam: state.focus,
      targetingType: state.target,
      cpiCurrency: state.transactionCurrency,
    };
    console.log("jsonParam", jsonParam);
    const encryptAdvertiserID = CryptoJS.AES.encrypt(
      JSON.stringify(advertiserId),
      secretKey
    ).toString();

    const header = {
      headers: {
        authtoken: authToken,
        sessionid: sessionId,
        advertiserid: encryptAdvertiserID, // Example authorization header
      },
    };

    let url = `${apiURL}/api/campaigns/updateBudgetDetails`;
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(jsonParam),
      secretKey
    ).toString();
    if (authToken != "" && sessionId != "" && advertiserId != "") {
      await axios
        .post(url, { params: encryptedData }, header)
        .then((res) => {
          if (res.data.status) {
            updateCompanyDetails();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const UpdateCampaignApi = () => {
    const email = localStorage.getItem("emailID") || "";
    const campSubtype = localStorage.getItem("campaignSubtype") || "";

    const jsonParam = {
      AdvertiserId: advertiserIdCampaign,
      CampaignId: state.campaign_Id,
      Email: email,
      ApplicationName: state.appName, // harCoded
      CampaignName: state.campaignName,
      SubType: state.campaignSubtype,
      AppPlatform: state.appPlatform,
      AppLookUpDetails: {
        logoUrl: state.appImageUrl,
        packageName: state.packageName,
      },
      TrackingPartner: state.tracking,
      TrackingURL: state.trackingUrl,
      Token: state.tokenEvent,
      Currency: state.transactionCurrency,
      Location: state.location,
      Status: state.status,
      Payouts: [
        {
          payout: 0,
          revenue: state.costPerInstall,
          geo: [state.locationCode],
        },
      ],
      IsRetargetingSubType:
        state.campaignSubtype == "Retargeting" ? true : false,
      RetargetingOptions: {
        dataSource: dataSource,
        segmentName: dataSource != "SKIP" ? segmentName : "",
        dataType: dataSource != "SKIP" ? dataType : "",
        fileUrl: dataSource != "SKIP" ? uploadedUrl : "",
      },
      Languages: state.language,
    };
    console.log(jsonParam);
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(jsonParam),
      secretKey
    ).toString();

    const url = `${apiURL}/api/campaigns/updateCampaign`;

    const encryptAdvertiserID = CryptoJS.AES.encrypt(
      JSON.stringify(advertiserId),
      secretKey
    ).toString();
    const header = {
      headers: {
        authtoken: authToken,
        sessionid: sessionId,
        advertiserid: encryptAdvertiserID, // Example authorization header
      },
    };
    console.log("header", header, advertiserId);
    if (authToken != "" && sessionId != "" && advertiserId != "") {
      axios
        .post(url, { params: encryptedData }, header)
        .then((res) => {
          // console.log("res", res.data.status);
          if (res.data.status) {
            // localStorage.setItem("campaignId", res.data.campaignId);
            // console.log(budget_Id);
            if (budget_Id != "") {
              budgetUpdate(budget_Id, campaign_Id);
            } else {
              // adGroupUpdate(campaign_Id);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // });
  };

  const updateCompanyDetails = async () => {
    try {
      console.log(companyImage, state.companyImage);
      var bodyFormData = new FormData();
      bodyFormData.append("OrganizationName", state.companyName);
      bodyFormData.append(
        "ProfilePictureUrl",
        state.companyImage.length != 0 ? state.companyImage : ""
      );
      bodyFormData.append("CreditLimit", `${state.creditLimit}`);
      bodyFormData.append("Advertiser_Id", state.advertiserIdCampaign);
      const encryptAdvertiserID = CryptoJS.AES.encrypt(
        JSON.stringify(advertiserId),
        secretKey
      ).toString();

      let headers = {
        advertiserid: encryptAdvertiserID,
        authtoken: authToken,
        sessionid: sessionId,
      };
      const response = await axios.post(
        `${apiURL}/api/user/editUser`,
        bodyFormData,
        { headers: headers }
      );
      if (response.data.status == true) {
        console.log(response.data);
      } else if (response.data.statuscode == 401) {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files;
    if (file && file.length > 0) {
      // const fileSizeKB = file.size / 1024;

      // if (fileSizeKB <= maxImageSize && fileSizeKB >= minImageSize) {
      // const reader = new FileReader();
      // reader.onload = (e) => {
      //     setImage(e.target == null ? "" : e.target.result);
      // };
      // reader.readAsDataURL(file);
      // console.log(file);
      // const val = file[0];
      // dispatch(setCompanyImage([...Array.from(file)]));
      // // setState({...state, companyImage:  file});
      // const blob = new Blob([val], { type: val.type });
      // const url = URL.createObjectURL(blob);
      // setImage(url);
      // setProfileUpdate(true)
      // } else {
      // setErrorMsgSnackBar("Invalid Image Size")
      // setErrorOpen(true)
      // }
      uploadFiles(
        file,
        "admattic-internal-data-storage",
        "AdservingPlatform/profilePictureUrl",
        "COMPANY_IMAGE"
      );
    }
  };

  useEffect(() => {
    apiCall();
    const countryMaster = getCountryMaster();
    setCountryArr([...masterCountryArray]);
    console.log("masterCountryArray", masterCountryArray);

    if (transactionCurrency != null && transactionCurrency != "") {
      checkTransactionCurrency(transactionCurrency);
    }
  }, []);

  const apiCall = () => {
    // getDetailsCampaign(selectedRange.startDate, selectedRange.endDate);

    performanceReport(
      selectedDateRange.startDate,
      selectedDateRange.endDate,
      campaign_Id
    );
  };
  const handleDateChange = (startDate: Date, endDate: Date) => {
    // getDetailsCampaign(startDate, endDate);
    console.log(startDate, endDate);
    localStorage.setItem("startDate", startDate.toString());
    localStorage.setItem("endDate", endDate.toString());
    dispatch(
      setSelectedDateRange({
        startDate: startDate,
        endDate: endDate,
      })
    );
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  };
  const erase = () => {
    dispatch(setAdvertiserIdCampaign(""));
    dispatch(setClicks(""));
    dispatch(setImpression(""));
    dispatch(setConversion(""));
    dispatch(setInstall(""));
    dispatch(setPayout(""));
    dispatch(setCtr(""));
    dispatch(setCpi(""));
    dispatch(setTransactionCurrency(""));
    dispatch(setDailyAvgBudget(""));

    dispatch(setLocationCheck(""));
    dispatch(setImageUploadError(""));
    dispatch(setLogoError(""));
    dispatch(setCount(0));
    dispatch(setStatus("pending"));
    dispatch(setCampaignSubType(""));
    dispatch(setAppPlatform(""));
    dispatch(setAppName(""));
    dispatch(setCampaignName(""));
    dispatch(setAppImageUrl(""));
    dispatch(setPackageName(""));

    //CAMPAIGN SETTING

    dispatch(setLanguage([]));
    dispatch(setLocation(""));
    dispatch(setTracking(""));
    dispatch(setTrackingUrl(""));
    dispatch(setImpressionUrl(""));
    dispatch(removeTokenEventObject(0));
    dispatch(
      setTokenEvent([
        { token: "", eventName: "" },
        // other elements...
      ])
    );

    dispatch(setLocationCode(""));

    // BUDGET BIDDING
    dispatch(setBudget(""));
    dispatch(setFocus(""));
    dispatch(setTarget(""));
    dispatch(setCostPerInstall(""));

    dispatch(setAdGroupName(""));
    dispatch(setLogoUrl(""));
    dispatch(setHeadline([""]));
    dispatch(setDescription([""]));
    dispatch(setImageUrl([]));
    dispatch(setVideoUrl([]));
    dispatch(setHtmlUrl([]));
    dispatch(setImageFile(""));
    // const emptyFile = new File([], 'empty.txt', { type: 'text/plain' });
    dispatch(setVideoFile([]));
    dispatch(setHtmlFile([]));

    dispatch(setCampaignId(""));
    dispatch(setBudgetId(""));

    ///////////////////

    dispatch(setDataSource(""));
    dispatch(setDataSourceError(""));

    dispatch(setSegmentName(""));
    dispatch(setSegmentNameError(""));

    dispatch(setDataType(""));
    dispatch(setDataTypeError(""));

    dispatch(setDataUpload([]));
    dispatch(setDataUploadError([]));

    dispatch(setUploadedUrl(""));
    dispatch(setUploadedUrlError(""));
  };

  return (
    <div>
      <BackCustom onClick={goBack} />
      <div className="top-box">
        <h4 style={{ margin: 0 }}>Campaign Details</h4>
        <PurpleCustomButton
          onClick={toggleEdit}
          disable={true}
          children={
            <div className="button-div">
              <img src={editUnderline} />
              &nbsp;&nbsp;Edit Campaign
            </div>
          }
        />
      </div>

      <div className="campaign-profile-container">
        <h4>Profile</h4>
        <div className="top-box" style={{ alignItems: "flex-start" }}>
          <div className="title">
            <div>
              <img className="img-icon" src={state.companyImage} alt="" />
              {editCampaign && (
                <div>
                  <label
                    id="file-input-label"
                    style={{
                      border: "none",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    htmlFor="file-input-profile"
                  >
                    <input
                      type="file"
                      id="file-input-profile"
                      onChange={handleFileChange}
                      name="file-input-profile"
                      style={{ display: "none" }}
                    />
                    <img src={uploadPurple} alt="" />
                    &nbsp;
                    <b>Replace</b>
                  </label>
                </div>
              )}
            </div>
            <div className="info">
              {editCampaign ? (
                <>
                  <div
                    className="campaign-name-box"
                    style={{ margin: "0 0 10px 0" }}
                  >
                    <p>Enter Company Name</p>
                    <input
                      style={{ width: "auto" }}
                      type="text"
                      value={state.companyName}
                      onChange={(e) => onChangeAll(e, "COMPANY_NAME")}
                      // disabled=6{campaignDisabled}
                    />
                  </div>
                  <div
                    className="campaign-name-box"
                    style={{ margin: "0 0 10px 0" }}
                  >
                    <p>Enter Campaign Name</p>
                    <input
                      style={{ width: "auto" }}
                      type="text"
                      value={state.campaignName}
                      onChange={(e) => onChangeAll(e, "CAMPAIGN_NAME")}
                      // disabled=6{campaignDisabled}
                    />
                  </div>
                  <Box sx={{ minWidth: 120 }}>
                    <CustomSelectStatus
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      defaultValue={"active"}
                      value={state.status}
                      //   label="Age"
                      onChange={(e) => handleChangeCustom(e, "STATUS")}
                    >
                      <MenuItem>None</MenuItem>
                      {EditArrayShow.map((item: any, index: any) => {
                        return (
                          <MenuItem
                            key={index}
                            value={item.name}
                            disabled={item.name == "pending"}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <img src={item.image} style={{ width: "23px" }} />
                              <span
                                className={`${item.className} showing-status`}
                              >
                                {item.name == "disabled"
                                  ? "Stopped"
                                  : item.name.charAt(0).toUpperCase() +
                                    item.name.slice(1)}
                              </span>
                            </div>
                          </MenuItem>
                        );
                      })}
                    </CustomSelectStatus>
                  </Box>
                </>
              ) : (
                <>
                  <p>{state.companyName}</p>
                  <p>{state.campaignName}</p>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={
                        status === "active"
                          ? active
                          : status === "paused"
                          ? paused
                          : status === "pending"
                          ? pending
                          : stopped
                      }
                      style={{ width: "20px", height: "20px" }}
                    />
                    <span
                      className={`${
                        status === "active"
                          ? "active-para"
                          : status === "paused"
                          ? "paused-para"
                          : status === "pending"
                          ? "pending-para"
                          : "stopped-para"
                      } showing-status`}
                    >
                      {status === "active"
                        ? "Active"
                        : status === "paused"
                        ? "Paused"
                        : status === "pending"
                        ? "Pending"
                        : "Stopped"}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="title">
            <div>
              <img className="img-icon" src={state.appImageUrl} alt="" />
              {editCampaign && (
                <div>
                  <label
                    id="file-input-label"
                    style={{
                      border: "none",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    htmlFor="file-input-appImage"
                  >
                    <img src={uploadPurple} alt="" />
                    <input
                      type="file"
                      id="file-input-appImage"
                      onChange={handleChangeAppImage}
                      name="file-input-appImage"
                      style={{ display: "none" }}
                    />
                    &nbsp;
                    <b>Replace</b>
                  </label>
                </div>
              )}
            </div>

            <div className="info">
              {editCampaign ? (
                <>
                  <div
                    className="campaign-name-box"
                    style={{ margin: "0 0 10px 0" }}
                  >
                    <p>Enter Application Name</p>
                    <input
                      style={{ width: "auto" }}
                      type="text"
                      value={state.appName}
                      onChange={(e) => onChangeAll(e, "APP_NAME")}
                      // disabled=6{campaignDisabled}
                    />
                  </div>

                  <div
                    className="campaign-name-box"
                    style={{ margin: "0 0 10px 0" }}
                  >
                    <p>Enter Campaign ID</p>
                    <input
                      style={{ width: "auto" }}
                      type="text"
                      value={`${croppedId.slice(
                        0,
                        3
                      )}-${state.campaign_Id.slice(0, 5)}`}
                      // onChange={onChangeName}
                      disabled={true}
                    />
                  </div>
                </>
              ) : (
                <>
                  <p>{state.appName}</p>
                  <p>
                    <a href="">{state.packageName} </a>
                  </p>
                  <a href="">{`${croppedId.slice(
                    0,
                    3
                  )}-${state.campaign_Id.slice(0, 5)}`}</a>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="grid-manager">
        <div className="campaign-profile-container">
          <h4>Details</h4>
          <div className="container-details">
            <div className="vertical-line"></div>
            <div className="row-details">
              <span className="label">OS</span>
              {editCampaign ? (
                <FormControl sx={{ m: 0, minWidth: "48%" }} size="small">
                  <CustomSelect
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={state.appPlatform}
                    onChange={(e) => handleChangeCustom(e, "APP_PLATFORM")}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>

                    <MenuItem key={1} value="IOS">
                      IOS
                    </MenuItem>
                    <MenuItem key={2} value="APK">
                      APK
                    </MenuItem>
                    <MenuItem key={3} value="Android">
                      Android
                    </MenuItem>
                  </CustomSelect>
                </FormControl>
              ) : (
                <span className="value">{state.appPlatform}</span>
              )}
            </div>
            <div className="row-details">
              <span className="label">Category</span>
              {editCampaign ? (
                <FormControl sx={{ m: 0, minWidth: "48%" }} size="small">
                  <CustomSelect
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={state.target}
                    onChange={(e) => handleChangeCustom(e, "TARGET")}
                  >
                    {showTarget && (
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    )}
                    {targetAppDpd.map((item: any, index: number) => (
                      <MenuItem key={index} value={item.TargetingType}>
                        {item.TargetingType}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                </FormControl>
              ) : (
                <span className="value">{state.target}</span>
              )}
              {targetDpdError != "" ? (
                <div className="error-box" style={{ margin: 0 }}>
                  <BiSolidErrorCircle className="error-icon" />
                  <p>{targetDpdError}</p>
                </div>
              ) : null}
            </div>
            <div className="row-details">
              <span className="label">Currency</span>
              {editCampaign ? (
                <FormControl sx={{ m: 0, minWidth: "48%" }} size="small">
                  <CustomSelect
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={state.transactionCurrency}
                    onChange={(e) =>
                      handleChangeCustom(e, "TRANSACTION_CURRENCY")
                    }
                  >
                    {showTarget && (
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    )}
                    <MenuItem key={1} value={"INR"}>
                      INR
                    </MenuItem>
                    <MenuItem key={2} value={"USD"}>
                      USD
                    </MenuItem>
                  </CustomSelect>
                </FormControl>
              ) : (
                <span
                  className="value"
                  // style={{ opacity: editCampaign ? 0.5 : 1.0 }}
                >
                  {state.transactionCurrency}
                </span>
              )}
            </div>
            <div className="row-details">
              <span className="label">Created</span>
              <span
                className="value"
                style={{ opacity: editCampaign ? 0.5 : 1.0 }}
              >
                {createdDate != ""
                  ? new Date(createdDate).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })
                  : createdDate}
              </span>
            </div>
            <div className="row-details">
              <span className="label">Activated Date</span>
              <span
                className="value"
                style={{ opacity: editCampaign ? 0.5 : 1.0 }}
              >
                {activatedDate != ""
                  ? new Date(activatedDate).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })
                  : activatedDate}
              </span>
            </div>
            <div className="row-details">
              <span className="label">Advertiser ID</span>
              <span
                className="value"
                style={{
                  opacity: editCampaign ? 0.5 : 1.0,
                  width: "min-content",
                }}
              >
                {state.advertiserIdCampaign}
              </span>
            </div>
            <div className="row-details">
              <span className="label">Ad Group</span>
              <span
                className="value"
                style={{ opacity: editCampaign ? 0.5 : 1.0 }}
              >
                {state.adGroupName}
              </span>
            </div>
            <div
              className="row-details"
              style={{ justifyContent: "flex-start" }}
            >
              <span className="label">Ad Asset (Creatives)</span>
              <div className="button-group">
                {imageUrl.length != 0 &&
                  imageUrl.map((val: any, index: any) => {
                    return (
                      <a
                        className="image-link"
                        href={val}
                        style={{ paddingLeft: "0px" }}
                      >
                        Image{index + 1}
                      </a>
                    );
                  })}
                {videoUrl.length != 0 &&
                  videoUrl.map((val: any, index: any) => {
                    return (
                      <a
                        className="image-link"
                        href={val}
                        style={{ paddingLeft: "0px" }}
                      >
                        Video{index + 1}
                      </a>
                    );
                  })}
                {htmlUrl.length != 0 &&
                  htmlUrl.map((val: any, index: any) => {
                    return (
                      <a
                        className="image-link"
                        href={val}
                        style={{ paddingLeft: "0px" }}
                      >
                        HTML{index + 1}
                      </a>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>

        <div className="campaign-profile-container">
          <h4>Campaign Details</h4>
          <div className="container-details">
            <div className="vertical-line"></div>
            <div className="row-details">
              <span className="label">Location</span>
              {editCampaign ? (
                <FormControl sx={{ m: 0, minWidth: "48%" }} size="small">
                  <CustomSelect
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={state.locationCode}
                    onChange={(e) => handleChangeCustom(e, "LOCATION")}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {countryArr != undefined &&
                      countryArr.map((item) => (
                        <MenuItem
                          key={item.id}
                          value={item.CountryCode}
                          data-country-code={item.CountryCode}
                        >
                          {item.Country}
                        </MenuItem>
                      ))}
                  </CustomSelect>
                </FormControl>
              ) : (
                <span className="value">
                  {
                    countryArr != undefined &&
                      countryArr
                        .filter((item) => {
                          return item.CountryCode == state.locationCode;
                        })
                        .map((item) => {
                          return item.Country;
                        })
                    // state.locationCode
                  }
                </span>
              )}
              {/* <span
                  className="value"
                  style={{ opacity: editCampaign ? 0.5 : 1.0 }}
                >
                  <img
                    src={locationImg}
                    className="location-icon"
                    alt="location"
                  />
                  {state.locationCode}
                </span> */}
            </div>

            <div className="row-details">
              <span className="label">Language</span>
              {editCampaign ? (
                language.length != 0 ? (
                  <div style={{ display: "flex" }}>
                    {language.map((val) => {
                      return (
                        <div className="edit-language">
                          <span
                            className="value"
                            style={{ opacity: editCampaign ? 0.5 : 1.0 }}
                          >
                            {val},
                          </span>
                        </div>
                      );
                    })}
                    <button className="edit-language-btn" onClick={handleOpen}>
                      <img className="edit-language-img" src={add} />
                      Add
                    </button>
                  </div>
                ) : (
                  <div>
                    <button className="edit-language-btn" onClick={handleOpen}>
                      <img className="edit-language-img" src={add} />
                      Add
                    </button>
                  </div>
                )
              ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {language.map((val) => {
                    return <span className="value">{val}, </span>;
                  })}
                </div>
              )}
            </div>

            <div className="row-details">
              <span className="label">Tracking Partner</span>
              {editCampaign ? (
                <FormControl sx={{ m: 0, minWidth: "48%" }} size="small">
                  <CustomSelect
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={state.tracking}
                    onChange={(e) => handleChangeCustom(e, "TRACKING")}
                  >
                    {TrackingPartnerDpd.map((item: any, index: number) => (
                      <MenuItem key={index} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                </FormControl>
              ) : (
                <span className="value">{state.tracking}</span>
              )}
            </div>
            <div className="row-details">
              <span className="label">Tracking Url</span>
              <div style={{ display: "flex", flexDirection:'column', width:'48%' }}>
                {editCampaign ? (
                  <CustomTextFieldTracking
                    // sx={textFieldStyle}
                    id="outlined-basic"
                    variant="outlined"
                    value={state.trackingUrl}
                    onChange={(e) => {
                      let val = e.target.value;
                      setState((prevState) => ({
                        ...prevState,
                        trackingUrl: e.target.value,
                      }));

                      // localStorage.setItem("trackingUrl", val);
                      dispatch(setTrackingUrl(val));
                      setTrackingError("");
                      // if (isValidURL(val)) {
                      // }
                      // console.log(val.length > 10, isValidURL(val), val.includes("appsflyer"));
                      if (tracking == "appsflyer") {
                        if (
                          val.length > 10 &&
                          isValidURL(val) &&
                          val.includes("appsflyer")
                        ) {
                          return;
                        } else {
                          // console.log("tracking");
                          setTrackingError("Please enter a valid URL.");
                        }
                      } else if (tracking == "branch") {
                        if (
                          val.length > 10 &&
                          isValidURL(val) &&
                          val.includes("app.link")
                          //  && val.includes("%243p")
                        ) {
                          return;
                        } else {
                          setTrackingError("Please enter a valid URL.");
                        }
                      } else if (tracking == "singular") {
                        if (
                          val.length > 10 &&
                          isValidURL(val) &&
                          val.includes("sng")
                        ) {
                          return;
                        } else {
                          setTrackingError("Please enter a valid URL.");
                        }
                      } else if (tracking == "adjust") {
                        if (
                          val.length > 10 &&
                          isValidURL(val) &&
                          val.includes("adjust")
                        ) {
                          return;
                        } else {
                          setTrackingError("Please enter a valid URL.");
                        }
                      } else if (tracking == "appmetrica") {
                        if (
                          val.length > 10 &&
                          isValidURL(val) &&
                          val.includes("appmetrica")
                        ) {
                          return;
                        } else {
                          setTrackingError("Please enter a valid URL.");
                        }
                      }
                      // else if()
                      if (val.length > 10 && isValidURL(val)) {
                        return;
                      } else {
                        setTrackingError("Please enter a valid URL.");
                      }
                      // console.log(val.length, val.slice(0, -val.length + 4));

                      // if (val.length >= 5 && val.slice(0, -val.length + 4) == "http") {
                      //   localStorage.setItem("trackingUrl", val);

                      // } else if (val.length >= 4 && val.slice(0, -val.length + 3) == "www") {
                      //   const adding = "https://" + val;
                      //   localStorage.setItem("trackingUrl", adding);
                      // }
                    }}
                  />
                ) : (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span
                      className="value"
                      style={{
                        width: "200px",
                        height: "26px",
                        overflow: "hidden",
                        marginRight: "15px",
                      }}
                    >
                      {state.trackingUrl}
                    </span>
                    &nbsp;
                    <LuCopy
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setCopied(true);
                        setTimeout(() => setCopied(false), 3000);
                        navigator.clipboard.writeText(state.trackingUrl);
                      }}
                    />
                  </div>
                )}
                {trackingError != "" && (
                  <div className="error-box">
                    <BiSolidErrorCircle className="error-icon" />
                    <p>{trackingError}</p>
                  </div>
                )}
              </div>
            </div>

            <div className="row-details">
              <span className="label">Impression Url</span>
              {editCampaign ? (
                <CustomTextField
                  // sx={textFieldStyle}
                  value={state.impressionUrl}
                  onChange={
                    (e) => handleChangeCustom(e, "IMPRESSION_URL")
                    // setState((prevState) => ({
                    //   ...prevState,
                    //   impressionUrl: e.target.value as string,
                    // }))
                  }
                  id="outlined-basic"
                  variant="outlined"
                />
              ) : (
                <span className="value" style={{ width: "min-content" }}>
                  {state.impressionUrl}
                </span>
              )}
            </div>
            <div className="row-details">
              <span className="label">Token</span>
              {editCampaign ? (
                <CustomTextField
                  // sx={textFieldStyle}
                  id="outlined-basic"
                  variant="outlined"
                  value={
                    state.tokenEvent != null && state.tokenEvent.length > 0
                      ? state.tokenEvent[0].token
                      : ""
                  }
                  // onChange={(e)=>setState((prevState)=>({...prevState, tokenEvent:e.target.value }))}
                  onChange={(e) =>
                    setState((prevState) => ({
                      ...prevState,
                      tokenEvent: [
                        {
                          ...prevState.tokenEvent[0],
                          token: e.target.value,
                        },
                      ],
                    }))
                  }
                />
              ) : (
                state.tokenEvent.map((val) => {
                  return <span className="value">{val.token}</span>;
                })
              )}
            </div>
            <div className="row-details">
              <span className="label">Event Name</span>
              {editCampaign ? (
                <CustomTextField
                  // sx={textFieldStyle}
                  id="outlined-basic"
                  variant="outlined"
                  value={
                    state.tokenEvent != null && state.tokenEvent.length > 0
                      ? state.tokenEvent[0].eventName
                      : ""
                  }
                  onChange={(e) =>
                    setState((prevState) => ({
                      ...prevState,
                      tokenEvent: [
                        {
                          ...prevState.tokenEvent[0],
                          eventName: e.target.value,
                        },
                      ],
                    }))
                  }
                />
              ) : (
                state.tokenEvent.map((val) => {
                  return <span className="value">{val.eventName}</span>;
                })
              )}
            </div>
          </div>
        </div>

        <div
          className="campaign-profile-container"
          style={{ height: "max-content" }}
        >
          <h4>Budget Review</h4>
          <div className="container-details">
            <div className="budget-main-box">
              <div className="budget-sub-box">
                <h4>Total</h4>
                <h3>
                  {transactionCurrency == "INR" ? "₹" : "$"}{" "}
                  {lastBilledAmount + totalSpendsLastMonth}
                </h3>
              </div>

              <div className="budget-sub-box">
                <h4>Spends of Last Month</h4>
                <h3>
                  {transactionCurrency == "INR" ? "₹" : "$"}
                  {totalSpendsLastMonth}
                </h3>
              </div>

              <div className="budget-sub-box">
                <h4>Last Billed Amount</h4>
                <h3>
                  {transactionCurrency == "INR" ? "₹" : "$"} {lastBilledAmount}
                </h3>
              </div>
            </div>
            <div
              className="vertical-line"
              style={{ height: "40%", marginTop: "10px" }}
            ></div>
            <div className="row-details">
              <span className="label">Credit Limit</span>
              {editCampaign ? (
                <CustomTextField
                  // sx={textFieldStyle}
                  id="outlined-basic"
                  variant="outlined"
                  value={state.creditLimit}
                  type="number"
                  onChange={(e) => {
                    setState({
                      ...state,
                      creditLimit: parseInt(e.target.value),
                    });
                  }}
                />
              ) : (
                <span
                  className="value"
                  style={{ opacity: editCampaign ? 0.5 : 1.0 }}
                >
                  {transactionCurrency == "INR" ? "₹" : "$"} {state.creditLimit}
                </span>
              )}
            </div>
            <div className="error-container-box">
              <div className="row-details">
                <span className="label">Daily average budget</span>
                {editCampaign ? (
                  <CustomTextField
                    // fullWidth
                    // sx={textFieldStyle}
                    id="outlined-basic"
                    variant="outlined"
                    value={state.budget}
                    onChange={(e) => {
                      let value = e.target.value;
                      dispatch(setBudget(value));
                      // console.log(budget);
                      // localStorage.setItem("budget", value);

                      if (Number(value) < budgetLimit) {
                        setBudgetError(
                          `Please enter minimum value ${budgetLimit}`
                        );
                      } else {
                        setBudgetError("");
                      }
                      setState((prevState) => ({
                        ...prevState,
                        budget: e.target.value,
                      }));
                    }}
                  />
                ) : (
                  <span
                    className="value"
                    style={{ opacity: editCampaign ? 0.5 : 1.0 }}
                  >
                    {transactionCurrency == "INR" ? "₹" : "$"} {state.budget}
                  </span>
                )}
              </div>
              {budgetError != "" ? (
                <div className="error-box error-adjust">
                  <BiSolidErrorCircle className="error-icon" />
                  <p>{budgetError}</p>
                </div>
              ) : null}
            </div>

            <div className="row-details">
              <span className="label">Bidding</span>
              {editCampaign ? (
                <FormControl sx={{ m: 0, minWidth: "48%" }} size="small">
                  <CustomSelect
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={state.focus}
                    onChange={(e) => handleChangeCustom(e, "FOCUS")}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {/* <MenuItem value="InstallVolume">
                      Install Volume (All users)
                    </MenuItem>
                    <MenuItem value="appAction">In app-actions</MenuItem> */}
                    {focusValuesDpd.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                  {focusDpdError != "" ? (
                    <div
                      className="error-box"
                      style={{ width: "50%", margin: 0 }}
                    >
                      <BiSolidErrorCircle className="error-icon" />
                      <p>{focusDpdError}</p>
                    </div>
                  ) : null}
                </FormControl>
              ) : (
                <span
                  className="value"
                  style={{ opacity: editCampaign ? 0.5 : 1.0 }}
                >
                  {state.focus}
                </span>
              )}
            </div>
            <div className="error-container-box">
              <div className="row-details">
                <span className="label">Cost per install</span>
                {editCampaign ? (
                  <CustomTextField
                    // sx={textFieldStyle}
                    id="outlined-basic"
                    variant="outlined"
                    value={state.costPerInstall}
                    onChange={onChangeCostPerInstall}
                  />
                ) : (
                  <span
                    className="value"
                    style={{ opacity: editCampaign ? 0.5 : 1.0 }}
                  >
                    {transactionCurrency == "INR" ? "₹" : "$"}{" "}
                    {state.costPerInstall}
                  </span>
                )}
              </div>
            </div>
            {costPerInstallError != "" ? (
              <div className="error-box error-adjust">
                <BiSolidErrorCircle className="error-icon" />
                <p>{costPerInstallError}</p>
              </div>
            ) : null}
          </div>
        </div>

        <div
          className="campaign-profile-container"
          style={{ height: "max-content" }}
        >
          <div className="top-box">
            <h4>Performance Report</h4>
            <DateCustomPicker
              bottom={"73.5pc"}
              onDateChange={handleDateChange}
              onClick={apiCall}
            />
          </div>

          <div className="performance-details">
            <div className="chart-wrapper">
              <h5>Clicks</h5>
              <h6>
                {state.clicks}
                <span className="increase">+{calState.calClicks}%</span>
              </h6>
            </div>

            <div className="chart-wrapper">
              <h5>Impression</h5>
              <h6>{state.impression}</h6>
            </div>

            <div className="chart-wrapper">
              <h5>Conversions</h5>
              <h6>
                {state.conversion}
                <span className="increase">+{calState.calConversion}%</span>
              </h6>
            </div>

            <div className="chart-wrapper">
              <h5>Install</h5>
              <h6>
                {state.install}{" "}
                <span className="increase">+{calState.calInstall}%</span>
              </h6>
            </div>

            <div className="chart-wrapper">
              <h5>Payout</h5>
              <h6>
                {transactionCurrency == "INR" ? "₹" : "$"}
                {state.payout}
              </h6>
            </div>

            <div className="chart-wrapper">
              <h5>CTR</h5>
              <h6>{state.ctr}%</h6>
            </div>

            <div className="chart-wrapper">
              <h5>CTC</h5>
              <h6>{state.cpi}%</h6>
            </div>

            <div className="chart-wrapper">
              <h5>Daily Budget</h5>
              <h6>
                {state.dailyAvgBudget}
                {/* <span className="decrease">-9%</span> */}
              </h6>
            </div>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={copied}
          message="Copied to clipboard"
          TransitionComponent={SlideTransition}
        />
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="search-icon-box" style={{ width: "auto" }}>
              <AiOutlineSearch
                className="search-icon"
                style={{ position: "relative" }}
              />
              <input
                className="search-input"
                type="text"
                placeholder="start typing or select a language"
                value={userInput}
                onChange={handleInputChange}
              />
            </div>
            <ul
              className="dropdown-menu"
              style={{
                height: "17vh",
                overflow: "auto",
                background: "transparent",
              }}
            >
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion}
                </li>
              ))}
            </ul>
            <h1
              className="campaign-subtype"
              style={{ margin: "25px 0 15px 0" }}
            >
              Selected Languages
            </h1>
            <div style={{ display: "flex" }}>
              {language.map((item) => (
                <button className="purple-btn language-selected" key={item}>
                  {item}
                  <RxCross2
                    className="cancel-icon"
                    onClick={() => removeLanguage(item)}
                  />
                </button>
              ))}
            </div>
          </Box>
        </Fade>
      </Modal>

      {editCampaign && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            marginBottom: "10px",
          }}
        >
          <PurpleCustomButton
            onClick={toggleSave}
            disable={true}
            children={<div className="button-div">Save Changes</div>}
          />
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state: RootState) => ({
  count: state.counter.count,
  campaignSubtype: state.user.campaignSubtype,
  appPlatform: state.user.appPlatform,
  appName: state.user.appName,
  campaignName: state.user.campaignName,
  appImageUrl: state.user.appImageUrl,
  packageName: state.user.packageName,

  location: state.user.location,
  language: state.user.language,
  tracking: state.user.tracking,
  trackingUrl: state.user.trackingUrl,
  impressionUrl: state.user.impressionUrl,
  tokenEvent: state.user.tokenEvent,
  locationCode: state.user.locationCode,

  budget: state.user.budget,
  focus: state.user.focus,
  target: state.user.target,
  costPerInstall: state.user.costPerInstall,

  adGroupName: state.user.adGroupName,
  logoUrl: state.user.logoUrl,
  headline: state.user.headline,
  description: state.user.description,
  imageUrl: state.user.imageUrl,
  videoUrl: state.user.videoUrl,
  htmlUrl: state.user.htmlUrl,

  budget_Id: state.user.budget_Id,
  campaign_Id: state.user.campaign_Id,

  status: state.user.status,
  authToken: state.user.authToken,
  sessionId: state.user.sessionId,
  advertiserId: state.user.advertiserId,
  selectedAdvertiserId: state.user.selectedAdvertiserId,

  selectedDateRange: state.user.selectedDateRange,
  companyName: state.user.companyName,
  campaignStatus: state.user.campaignStatus,
  advertiserIdCampaign: state.user.advertiserIdCampaign,
  transactionCurrency: state.user.transactionCurrency,

  clicks: state.user.clicks,
  impression: state.user.impression,
  conversion: state.user.conversion,
  install: state.user.install,
  payout: state.user.payout,
  ctr: state.user.ctr,
  cpi: state.user.cpi,
  dailyAvgBudget: state.user.dailyAvgBudget,

  dataSource: state.user.dataSource,
  segmentName: state.user.segmentName,
  dataType: state.user.dataType,
  uploadedUrl: state.user.uploadedUrl,

  companyImage: state.user.companyImage,
  lastBilledAmount: state.user.lastBilledAmount,
  totalSpendsLastMonth: state.user.totalSpendsLastMonth,
  creditLimit: state.user.creditLimit,
  createdDate: state.user.createdDate,
  activatedDate: state.user.activatedDate,
  croppedId: state.user.croppedId,
  countryCodeAdvertiser: state.user.countryCodeAdvertiser,
  masterCountryArray: state.user.masterCountryArray,
});

const mapDispatchToProps = {
  setStatus,
  setCampaignSubType,
  setAppPlatform,
  setAppName,
  setCampaignName,
  setAppImageUrl,
  setPackageName,

  setLocation,
  setLanguage,
  setTracking,
  setTrackingUrl,
  setImpressionUrl,
  setTokenEvent,
  addTokenEventObject,
  removeTokenEventObject,
  updateToken,
  updateEvent,
  setLocationCode,

  setBudget,
  setFocus,
  setTarget,
  setCostPerInstall,

  setAdGroupName,
  setLogoUrl,
  setHeadline,
  setDescription,
  setImageUrl,
  setVideoUrl,
  setHtmlUrl,
  setLogoError,
  setImageUploadError,
  setBudgetId,
  setCampaignId,
  setLocationCheck,
  setAdvertiserId,
  setAuthToken,
  setSessionId,

  setDataSource,
  setSegmentName,
  setDataType,
  setDataUpload,
  setUploadedUrl,

  setDataSourceError,
  setSegmentNameError,
  setDataTypeError,
  setDataUploadError,
  setUploadedUrlError,
  setSelectedAdvertiserId,

  setSelectedDateRange,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCampaignAdmin);
