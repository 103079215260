import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import '../../../CSS/Coupon.css';
import { FiCopy } from "react-icons/fi";
import { FaTag } from "react-icons/fa";
import { FaSquareArrowUpRight } from "react-icons/fa6";
import { MdArrowOutward } from "react-icons/md";
import axios from "axios";
import ProductTable from 'react-data-table-component'
import InfoIcon from '@mui/icons-material/Info';
import { Box, ListItemText, Menu, MenuItem, Modal, Select, SelectChangeEvent, Snackbar, Tooltip, Typography } from '@mui/material';
import { TooltipProps, styled, tooltipClasses } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { apiURL, secretKey } from '../../../Constant';
import DateCustomPicker from '../../custom/DateCustomPicker';
import { RootState } from '../../../REDUX/Reducers/rootReducer';
import { setAdvertiserId, setAuthToken, setCurrencySymbol, setSessionId, setMasterCouponArray, setMasterCountryArray, setSelectedDateRange } from '../../../REDUX/Actions/userActionIndex';
import { connect, useDispatch } from 'react-redux';
import { PurpleCustomButton } from '../../custom/PurpleButton';
import { element } from 'prop-types';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import active from "../../../IMAGE/active.svg";
import stopped from "../../../IMAGE/stopped.svg";
import paused from "../../../IMAGE/paused.svg";
import couponWhite from "../../../IMAGE/couponWhite.svg";
import noProfilePhoto from "../../../IMAGE/noProfilePhoto.jpg";
import { parse } from 'path';
import CustomLoader from '../../custom/CustomLoader';
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';
import saveAs from "file-saver";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import AdvertiserIcon from '../../../IMAGE/advertisers.svg'
// import { useDispatch } from 'react-redux';

const CryptoJS = require('crypto-js')

function SlideTransition(props: TransitionProps) {
    return <Slide {...props} direction="up" />;
}

const getDataUrlForImage = (buffer: any) => {
    const uint8Array = new Uint8Array(buffer['data']);
    const blob = new Blob([uint8Array]);
    const dataUrl = URL.createObjectURL(blob);
    console.log(dataUrl)
    return dataUrl
}
const formatChangeDate = (dateParam: any) => {
    const inputDateString = dateParam;
    const date = new Date(inputDateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate
}
interface AdminDashboardProps {
    advertiserId: any;
    authToken: any;
    sessionId: any;
    selectedDateRange: any,
    masterCountryArray: any
}

const CustomSelect = styled(Select)(({ theme }) => ({
    fontSize: "12px",
    fontWeight: "700",
    width: "100px",
    height: "40px",
    marginRight: '10px',
    borderRadius: '3px',
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
    {
        border: "1px solid black",
    },
}));

const menuItemStyle = {
    fontSize: "14px",
    fontWeight: "700",
    '& .MuiMenuItem-selected': {
        backgroundColor: "#6800b8",
    },
}

const statBoxStyle: any = {
    fontSize: "14px",
    fontWeight: "700",
    minHeight: '50px',
    width: '27%',
    border: '1px solid rgba(196, 196, 196, 0.5)',
    borderRadius: '2px',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column              ',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly',
    padding: '15px',
    gap: '10px',
}
const getPercentage = (current: any, previous: any) => {
    if (previous > 0) {
        let percentage: any = ((current - previous) / previous * 100).toFixed(2)
        if (percentage > 0) {
            return <span style={{ backgroundColor: '#0B80431A', color: '#0B8043', marginLeft: '3px' }}>{`(+ ${percentage}%)`}</span>
        } else {
            return <span style={{ backgroundColor: '#C539291A', color: '#C53929', marginLeft: '3px' }}>{`(- ${percentage}%)`}</span>
        }
    } else {
        return <span style={{ backgroundColor: '#0B80431A', color: '#0B8043', marginLeft: '3px' }}>{'(+ 0%)'}</span>
    }
}

function getCurrentYearDates() {
    const today = new Date();
  
    // Create a new date for the first day of the current year
    const firstDateOfYear = new Date(today.getFullYear(), 0, 1); // January is month 0
  
    // Format the dates as desired (e.g., YYYY-MM-DD)
    const formattedFirstDateOfYear = firstDateOfYear.toISOString().split('T')[0];
    const formattedCurrentDate = today.toISOString().split('T')[0];
  
    return {
      firstDateOfYear: formattedFirstDateOfYear,
      currentDate: formattedCurrentDate
    };
  }

const AdminDashboardScreen: FC<AdminDashboardProps> = (props) => {

    // const [selectedRange, setSelectedRange] = useState<any>({
    //     startDate: props.selectedDateRange ? props.selectedDateRange.startDate : new Date(),
    //     endDate: props.selectedDateRange ? props.selectedDateRange.endDate : new Date(),
    // });

    const currentYear = new Date().getFullYear();
    const startOfYear = new Date(currentYear, 0, 1);
    const [selectedStartDate, setSelectedStartDate] = useState<Date>(startOfYear);
    const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());

    const [selectedRange, setSelectedRange] = useState<any>({
        startDate: selectedStartDate,
        endDate: new Date(),
    });

    const dispatch = useDispatch()

    const [clicked, setClicked] = useState(false);
    const [openSession, setOpenSession] = React.useState(false);
    const [tableData, setTableData] = useState<any[]>([])
    const [filteredData, setFilteredData] = useState<any[]>([])
    const [loaderActive, setLoaderActive] = useState<boolean>(false)
    const [selectedCurrency, setSelectedCurrency] = useState<string>('INR');
    const [currecySymbol, setCurrencySymbol] = useState<string>('')
    const [campaignStats, setCampaignStats] = useState<any>({
        total: 0,
        active: 0,
        paused: 0,
        stopped: 0
    });
    const [advertiserStats, setAdvertiserStats] = useState<any>({
        previousMonth: 0,
        currentMonth: 0
    });
    const [userSpendsStats, setUserSpendsStats] = useState<any>({
        previousMonth: 0,
        currentMonth: 0
    });
    const [installsStats, setInstallsStats] = useState<any>({
        previousMonth: 0,
        currentMonth: 0
    });
    const [averageCPI, setAverageCPI] = useState<number>(0)
    const [margin, setMargin] = useState<number>(0)
    const navigate = useNavigate()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [downloadData, setDownloadData] = useState<any[]>([])

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    // const dispatch = useDispatch();

    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(downloadData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(dataBlob, 'Advertisers XLSX.xlsx'); // Specify the desired file name here
    };

    const getTableData = () => {
        setLoaderActive(true)
        const encryptAdvertiserID = CryptoJS.AES.encrypt(JSON.stringify(props.advertiserId), secretKey).toString()
        let headers = {
            "authtoken": props.authToken,
            "sessionid": props.sessionId,
            "advertiserid": encryptAdvertiserID
        }
        axios
            .get(`${apiURL}/api/admin/dashboard/getAdvertiserTableData?startDate=${selectedRange ? formatChangeDate(selectedRange.startDate) : ''}&endDate=${selectedRange ? formatChangeDate(selectedRange.endDate) : ''}&currency=${selectedCurrency}`, { headers: headers })
            .then((response) => {
                if (response.data.status == true) {
                    const resData = response.data.data;
                    const bytes = CryptoJS.AES.decrypt(resData, secretKey);
                    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
                    const parsedData = JSON.parse(decryptedData);
                    if (parsedData && parsedData.advertiserData) {
                        let tempTableData = []
                        let tempfilterData = []
                        let tempCurrentSpends = 0;
                        let tempPreviousSpends = 0;
                        let tempCurrentInstalls = 0;
                        let tempPreviousInstalls = 0;
                        let totalCpi = 0;
                        let totalPayout = 0;
                        for (let data of parsedData.advertiserData) {
                            console.log("Total Installs", tempCurrentSpends, data.totalSpends)
                            tempTableData.push(data)
                            tempCurrentInstalls += data.totalInstalls ? data.totalInstalls : 0;
                            tempPreviousInstalls += data.totalInstallsPreviousMonth ? data.totalInstallsPreviousMonth : 0;
                            tempCurrentSpends += data.totalSpends ? parseFloat(data.totalSpends) : 0;
                            tempPreviousSpends += data.totalSpendsPreviousMonth ? parseFloat(data.totalSpendsPreviousMonth) : 0;
                            totalCpi += data.totalCpi ? data.totalCpi : 0;
                            totalPayout += data.totalPayout ? data.totalPayout : 0;
                            if (data.advertiserCurrency == selectedCurrency) {
                                tempfilterData.push(data)
                            }
                        }

                        setTableData(tempTableData)
                        setFilteredData(tempfilterData)
                        setUserSpendsStats((prevFilters: any) => ({
                            ...prevFilters,
                            previousMonth: tempCurrentSpends,
                            currentMonth: tempPreviousSpends,
                        }));
                        setInstallsStats((prevFilters: any) => ({
                            ...prevFilters,
                            previousMonth: tempCurrentInstalls,
                            currentMonth: tempPreviousInstalls,
                        }));
                        setMargin(totalCpi - totalPayout)
                        if (parsedData.campaignStats && parsedData.campaignStats.total) {
                            setAverageCPI(totalCpi / parsedData.campaignStats.total)
                        }
                        // setTableData((prevFilters: any) => (parsedData['advertiserData']))
                        // let filterData = tableData.filter((item: any) => item.advertiserCurrency == selectedCurrency)
                        // setFilteredData((prevFilters: any) => (filterData))
                        console.log("tableData", tableData)
                        console.log("filterData", filteredData)
                        console.log("selectedCurrency", selectedCurrency)

                    }
                    if (parsedData && parsedData.campaignStats)
                        setCampaignStats((prevFilters: any) => ({
                            ...prevFilters,
                            total: parsedData.campaignStats.total ? parsedData.campaignStats.total : 0,
                            active: parsedData.campaignStats.active ? parsedData.campaignStats.active : 0,
                            paused: parsedData.campaignStats.paused ? parsedData.campaignStats.paused : 0,
                            stopped: parsedData.campaignStats.stopped ? parsedData.campaignStats.stopped : 0
                        }));
                    if (parsedData && parsedData.advertiserCount) {
                        setAdvertiserStats((prevFilters: any) => ({
                            ...prevFilters,
                            previousMonth: parsedData.advertiserCount.previousMonth ? parsedData.advertiserCount.previousMonth : 0,
                            currentMonth: parsedData.advertiserCount.currentMonth ? parsedData.advertiserCount.currentMonth : 0,
                        }));
                    }
                    // setFilterData(parsedData['transactionsData'])
                } else if (response.data.statuscode == 401) {
                    setOpenSession(true)
                    setTimeout(() => { navigate("/") }, 2000)
                }
                setLoaderActive(false)
            })
            .catch((error) => {
                setLoaderActive(false)
                console.log(error)
            })
    }

    // useEffect(() => {
    //     if (props.selectedDateRange && props.selectedDateRange.endDate != "" && props.selectedDateRange.startDate != "") {
    //         const s = new Date(props.selectedDateRange.startDate);
    //         const e = new Date(props.selectedDateRange.endDate);
    //         setSelectedRange({
    //             startDate: s,
    //             endDate: e
    //         })
    //     }
    // }, [props.selectedDateRange]);

    // useEffect(() => {
    //     // const couponArray = getCouponMaster()
    //     getTableData();
    //     console.log(tableData)
    //     // let filterData = tableData.filter((item: any) => item.advertiserCurrency == selectedCurrency)
    //     // setFilteredData(filterData)
    // }, [clicked])

    useEffect(() => {
        // let filterData = tableData.filter((item: any) => item.advertiserCurrency == selectedCurrency)
        // setFilteredData(filterData)
        getTableData();
        let arr = props.masterCountryArray.filter((item: any) => {
            return item.Code == selectedCurrency
        })

        if (arr && arr.length) {
            setCurrencySymbol(arr[0].CurrencySymbol)
        }
    }, [selectedCurrency])

    // useEffect(() => {
    //     const currentSpends = filteredData.reduce((partialSum, item) => partialSum + parseFloat(item.totalSpends), 0
    //     )
    //     const previousSpends = filteredData.reduce((partialSum, item) => partialSum + parseFloat(item.totalSpendsPreviousMonth), 0)

    //     const currentInstalls = filteredData.reduce((partialSum, item) => partialSum + parseFloat(item.totalInstalls), 0)
    //     const previousInstalls = filteredData.reduce((partialSum, item) => partialSum + parseFloat(item.totalInstallsPreviousMonth), 0)

    //     const totalCPI = filteredData.reduce((partialSum, item) => partialSum + parseFloat(item.totalCpi), 0)
    //     const totalPayout = filteredData.reduce((partialSum, item) => partialSum + parseFloat(item.totalPayout), 0)

    //     let averageCpi = filteredData.length ? totalCPI / filteredData.length : 0;
    //     setUserSpendsStats((prevFilters: any) => ({
    //         ...prevFilters,
    //         previousMonth: currentSpends,
    //         currentMonth: previousSpends,
    //     }));

    //     setInstallsStats((prevFilters: any) => ({
    //         ...prevFilters,
    //         previousMonth: currentInstalls,
    //         currentMonth: previousInstalls,
    //     }));

    //     setAverageCPI(averageCPI);
    //     setMargin(totalCPI - totalPayout)

    // }, [filteredData])

    useEffect(() => {
        const csvData = filteredData.map(row => {
            const rowData: any = {};
            cols.forEach((field: any) => {
                rowData[field.id] = row[field.id];
            });
            return rowData;
        });
        setDownloadData(csvData)
    }, [filteredData])

    // useEffect(() => {
    //     let arr = props.masterCountryArray.filter((item: any) => {
    //         return item.Code == selectedCurrency
    //     })

    //     if (arr && arr.length) {
    //         setCurrencySymbol(arr[0].CurrencySymbol)
    //     }
    // }, [selectedCurrency])

    // const handleFormChange = (e: SelectChangeEvent<any>) => {
    //     const value = e.target.value
    //     setSelectedCurrency(value)
    //     console.log("selectedCurrency",selectedCurrency,value)
    //     let filterData  = tableData.filter((item: any) => item.advertiserCurrency == selectedCurrency)
    //     setFilteredData(filterData)
    // }

    const cols: any = [
        {
            id: 'name',
            name: 'Brand Name',
            selector: (row: any) => <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}> <img style={{ height: '34px', width: '34px' }} src={row.profilePicture ? getDataUrlForImage(row.profilePicture) : noProfilePhoto} /> <div>{row.name}</div></div>,
            width: "20%",
            grow: 1,
            style: {
                borderRight: '1px solid #cccccc',
                borderTop: 'none',
                color: '#333333'
            }
        },
        {
            id: 'totalCampaigns',
            name: 'Campaigns',
            width: "13%",
            selector: (row: any) => row.totalCampaigns,
            sortable: true,
            style: {
                color: '#333333',
            }
        },
        {
            id: 'totalBudget',
            name: 'Daily Budget',
            width: "15%",
            sortable: true,
            selector: (row: any) => currecySymbol + ' ' + row.totalBudget,
        },
        {
            id: 'totalClicks',
            name: 'Clicks',
            width: "12%",
            sortable: true,
            selector: (row: any) => row.totalClicks,
        },
        {
            id: 'totalImpressions',
            name: 'Impressions',
            width: "14%",
            sortable: true,
            selector: (row: any) => row.totalImpressions,
        },
        {
            id: 'totalInstalls',
            name: 'Installs',
            width: "12%",
            sortable: true,
            selector: (row: any) => row.totalInstalls,
        },
        {
            id: 'totalSpends',
            name: 'Total Spendings',
            width: "17%",
            sortable: true,
            selector: (row: any) => <div>{currecySymbol + ' ' + row.totalSpends}{getPercentage(row.totalSpends, row.totalSpendsPreviousMonth)}</div>
        }
    ]



    useEffect(() => {
        dispatch(
            setSelectedDateRange({
                startDate: selectedStartDate,
                endDate: selectedEndDate,
            })
        );
    }, [])


    useEffect(() => {
        if (props.selectedDateRange && props.selectedDateRange.endDate != "" && props.selectedDateRange.startDate != "") {
            const s = new Date(props.selectedDateRange.startDate);
            const e = new Date(props.selectedDateRange.endDate);
            setSelectedRange({
                startDate: s,
                endDate: e
            })
        }
    }, [props.selectedDateRange])

    useEffect(() => {
        getTableData();
    }, [clicked && selectedRange])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
            <div className='fixed-position' style={{ top: '92px', height: '80px', display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '30%', fontSize: '14px', fontWeight: '700' }}>
                    <div style={{ marginBottom: '3px' }}>Select Currency</div>
                    <CustomSelect
                        name='Currency'
                        value={selectedCurrency}
                        onChange={() => { }}
                        defaultValue="INR"
                    >
                        {["INR", "USD"].map((item: any) => (
                            <MenuItem sx={menuItemStyle} key={item} value={item} onClick={() => setSelectedCurrency(item)}>
                                {/* <Checkbox checked={selectedAdvertisers.indexOf(item) > -1} /> */}
                                <ListItemText primary={item} />
                            </MenuItem>
                        ))}
                    </CustomSelect>
                </div>
                <DateCustomPicker onClick={() => setClicked(!clicked)} ></DateCustomPicker>
            </div>
            <div style={{
                display: 'flex', justifyContent: 'center', gap: '30px',
                flexWrap: 'wrap', backgroundColor: '#ffffff',
                padding: '20px', marginTop: '81px'
            }}>
                {loaderActive ? <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "200px"
                }}>
                    <CustomLoader />
                </div>
                    :
                    <div style={{
                        display: 'flex', justifyContent: 'center', gap: '30px',
                        flexWrap: 'wrap', backgroundColor: '#ffffff',
                        padding: '20px'
                    }}>
                        <div style={statBoxStyle}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: '50px' }}>
                                <div style={{ fontSize: '16px', fontWeight: '700', lineHeight: '21.86px' }}>Total Brands</div>
                                <div style={{ color: "#6900B8", borderRadius: "0px", backgroundColor: '#6900B81A', display: 'flex', alignItems: 'center' }}><FaTag /></div>

                            </div>
                            <div style={{ fontSize: '35px', fontWeight: '700', lineHeight: '54.64px' }}>{(advertiserStats.currentMonth).toLocaleString('en-IN')}</div>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '8px' }}>
                                <div style={{ backgroundColor: '#7412BC1A', color: '#6900B8' }}> {advertiserStats.previousMonth ? ((advertiserStats.currentMonth - advertiserStats.previousMonth) / advertiserStats.previousMonth * 100).toFixed(2) : '+0'} {' %'}</div>
                                <div style={{ color: '#999999', fontSize: '14px' }}> New Last month</div>
                            </div>
                        </div>
                        <div style={statBoxStyle}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: '50px' }}>
                                <div style={{ fontSize: '16px', fontWeight: '700', lineHeight: '21.86px' }}>Total User Spendings</div>
                                <div style={{ color: "#6900B8", borderRadius: "0px", backgroundColor: '#6900B81A', display: 'flex', alignItems: 'center' }}><FaTag /></div>

                            </div>
                            <div style={{ fontSize: '35px', fontWeight: '700', lineHeight: '54.64px' }}> {currecySymbol + ' '}{(userSpendsStats.currentMonth).toLocaleString('en-IN')}</div>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '8px' }}>
                                <div style={{ backgroundColor: '#7412BC1A', color: '#6900B8' }}> {userSpendsStats.previousMonth ? ((userSpendsStats.currentMonth - userSpendsStats.previousMonth) / userSpendsStats.previousMonth * 100).toFixed(2) : '+0'} {' %'}</div>
                                <div style={{ color: '#999999', fontSize: '14px' }}> More Than Last month</div>
                            </div>
                        </div>
                        <div style={statBoxStyle}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: '50px' }}>
                                <div style={{ fontSize: '16px', fontWeight: '700', lineHeight: '21.86px' }}>Total Installs</div>
                                <div style={{ color: "#6900B8", borderRadius: "0px", backgroundColor: '#6900B81A', display: 'flex', alignItems: 'center' }}><FaTag /></div>

                            </div>
                            <div style={{ fontSize: '35px', fontWeight: '700', lineHeight: '54.64px' }}> {(installsStats.currentMonth).toLocaleString('en-IN')}</div>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '8px' }}>
                                <div style={{ backgroundColor: '#7412BC1A', color: '#6900B8' }}> {installsStats.previousMonth ? ((installsStats.currentMonth - installsStats.previousMonth) / installsStats.previousMonth * 100).toFixed(2) : '+0'} {' %'}</div>
                                <div style={{ color: '#999999', fontSize: '14px' }}> Increased</div>
                            </div>
                        </div>
                        <div style={statBoxStyle}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: '50px' }}>
                                <div style={{ fontSize: '16px', fontWeight: '700', lineHeight: '21.86px' }}>Total Campaigns</div>
                                <div style={{ color: "#6900B8", borderRadius: "0px", backgroundColor: '#6900B81A', display: 'flex', alignItems: 'center' }}><FaTag /></div>

                            </div>
                            <div style={{ fontSize: '35px', fontWeight: '700', lineHeight: '54.64px' }}>{(campaignStats.total).toLocaleString('en-IN')}</div>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '13px' }}>
                                <div className='stat-status-banner' style={{ backgroundColor: '#0B80431A', color: '#0B8043' }}> <img src={active} /> {campaignStats.active}</div>
                                <div className='stat-status-banner' style={{ backgroundColor: '#B07F001A', color: '#B07F00' }}> <img src={paused} />{campaignStats.paused}</div>
                                <div className='stat-status-banner' style={{ backgroundColor: '#C539291A', color: '#C53929' }}> <img src={stopped} /> {campaignStats.stopped}</div>
                            </div>
                        </div>
                        <div style={statBoxStyle}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: '50px' }}>
                                <div style={{ fontSize: '16px', fontWeight: '700', lineHeight: '21.86px' }}>Average Cost Per Install</div>
                                <div style={{ color: "#6900B8", borderRadius: "0px", backgroundColor: '#6900B81A', display: 'flex', alignItems: 'center' }}><FaTag /></div>

                            </div>
                            <div style={{ fontSize: '35px', fontWeight: '700', lineHeight: '54.64px' }}> {currecySymbol + ' '}{(averageCPI).toFixed(2)}</div>
                        </div>
                        <div style={statBoxStyle}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: '50px' }}>
                                <div style={{ fontSize: '16px', fontWeight: '700', lineHeight: '21.86px' }}>Margin</div>
                                <div style={{ color: "#6900B8", borderRadius: "0px", backgroundColor: '#6900B81A', display: 'flex', alignItems: 'center' }}><FaTag /></div>

                            </div>
                            <div style={{ fontSize: '35px', fontWeight: '700', lineHeight: '54.64px' }}> {currecySymbol + ' '}{(margin).toFixed(2)}</div>
                        </div>
                    </div>}
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', gap: '0' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '20px' }}>
                    <div style={{ display: "flex" }} ><img src={AdvertiserIcon} /><p className="heading">Top Brands </p></div>
                    <div style={{ display: "flex" }} >
                        <div style={{ display: 'flex', gap: '15px' }}>
                            <button
                                type="button"
                                className="download-btn-style"
                                id="basic-button"
                                onClick={() => navigate('/home/admin/advertisers')}
                                style={{ textDecoration: 'underline' }}
                            >
                                All Brands
                            </button>
                            <button
                                type="button"
                                className="download-btn-style"
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                            >
                                Download <FileDownloadOutlinedIcon sx={{ marginLeft: "5px", height: "20px" }} />
                            </button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem>{downloadData.length == 0 ? (
                                    <li
                                        onClick={() => {
                                            alert("No data available to download.");
                                        }}
                                        style={{
                                            fontSize: "12px",
                                            fontWeight: "600",
                                            color: "blue",
                                            textDecorationLine: 'underline'
                                        }}
                                    >
                                        Document (.csv) file
                                    </li>
                                ) : (<CSVLink data={downloadData} filename='Advertisers CSV' style={{
                                    fontSize: "12px",
                                    fontWeight: "600",
                                    color: "blue",
                                }}>Document (.csv) file</CSVLink>)}</MenuItem>
                                <MenuItem>{downloadData.length == 0 ? (
                                    <li
                                        onClick={() => {
                                            alert("No data available to download.");
                                        }}
                                        style={{
                                            fontSize: "12px",
                                            fontWeight: "600",
                                            color: "blue",
                                            textDecorationLine: 'underline'
                                        }}
                                    >
                                        Excel (.xlsx) file
                                    </li>
                                ) : (<a onClick={downloadExcel} style={{
                                    fontSize: "12px",
                                    fontWeight: "600",
                                    color: "blue",
                                    textDecorationLine: 'underline'
                                }}>Excel (.xlsx) file</a>)}</MenuItem>
                            </Menu>
                        </div>
                    </div>
                </div>
                <div className='table-container'>
                    {loaderActive ? <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "200px"
                    }}>
                        <CustomLoader />
                    </div>
                        : <ProductTable
                            defaultSortFieldId='spends'
                            responsive={true}
                            highlightOnHover
                            data={tableData}
                            fixedHeader
                            pagination
                            // paginationIconFirstPage={false}
                            // paginationIconLastPage={false}
                            paginationPerPage={10}
                            paginationComponentOptions={{
                                noRowsPerPage: true,
                                rowsPerPageText: 'Rows per page:', // Customize the text for rows per page
                            }}
                            columns={cols}
                            customStyles={{
                                headCells: {
                                    style: {
                                        color: "#6900B8",
                                        fontSize: "14px",
                                        fontWeight: 600,
                                        fontFamily: "Manrope",
                                        overflow: 'wrap'
                                    },
                                },
                                cells: {
                                    style: {
                                        color: "rgba(51, 51, 51, 0.7)",
                                        fontSize: "13px",
                                        fontFamily: "Manrope",
                                        fontWeight: 600,
                                        border: 'none'
                                    },
                                },
                                rows: {
                                    style: {
                                        border: 'none !important'
                                    },
                                },
                                headRow: {
                                    style: {
                                        border: 'none !important'
                                    },
                                }
                            }}
                        />}
                </div>
            </div>

        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    currencySymbol: state.user.currencySymbol,
    authToken: state.user.authToken,
    sessionId: state.user.sessionId,
    advertiserId: state.user.advertiserId,
    selectedDateRange: state.user.selectedDateRange,
    masterCountryArray: state.user.masterCountryArray
});

const mapDispatchToProps = {
    setCurrencySymbol,
    setAdvertiserId,
    setAuthToken,
    setSessionId,
    setSelectedDateRange,
    setMasterCountryArray
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboardScreen);