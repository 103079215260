import React, { FC, useState, useRef, useEffect } from "react";
import { ChangeEvent } from "react";
import "../../CSS/CreateCampaign.css";
import { IoCloudUploadOutline } from "react-icons/io5";
import TextField from "@mui/material/TextField";
import { BiChevronUp, BiChevronDown } from "react-icons/bi";
import { AiOutlineFolderOpen } from "react-icons/ai";
import "../../CSS/ImageUpload.css";

import phoneLayout from "../../IMAGE/phoneLayout.svg";
import phoneTop from "../../IMAGE/phoneTop.svg";
import phoneHeadline from "../../IMAGE/phoneHeadline.svg";
import phoneFooter from "../../IMAGE/phoneFooter.svg";

import cross from "../../IMAGE/cross.svg";
import { BiSolidErrorCircle } from "react-icons/bi";
// import React from 'react';
import AWS from "aws-sdk";
import axios from "axios";
import { apiURL, secretKey } from "../../Constant";
import { RootState } from "../../REDUX/Reducers/rootReducer";

import {
  setAdGroupName,
  setLogoUrl,
  setHeadline,
  setDescription,
  setImageUrl,
  setVideoUrl,
  setHtmlUrl,
  setImageFile,
  setVideoFile,
  setHtmlFile,
  setLogoError,
  setImageUploadError,
  setAdvertiserId,
  setAuthToken,
  setSessionId,
  setHeadlineError,
  setDescriptionError,
} from "../../REDUX/Actions/userActionIndex";
import { connect, useDispatch } from "react-redux";
const CryptoJS = require("crypto-js");

interface ClickProp {
  handleClick?: (data: string) => void;
  onBackClick?: () => void;
  descriptionError: string;
  headlineError: string;
  adGroupName: string;
  logoUrl: string;
  headline: string[];
  description: string[];

  imageUrl: string[];
  videoUrl: string[];
  htmlUrl: string[];

  htmlFile: File[];
  imageFile: string;
  videoFile: File[];
  imageUploadError: string;
  logoError: string;
  authToken: string;
  sessionId: string;
  advertiserId: string;
  advertiserIdCampaign: string;
}

const AdGroup: React.FC<ClickProp> = ({
  descriptionError,
  headlineError,
  advertiserIdCampaign,
  authToken,
  sessionId,
  advertiserId,
  htmlFile,
  imageFile,
  videoFile,
  handleClick,
  onBackClick,
  adGroupName,
  logoUrl,
  headline,
  description,
  imageUrl,
  videoUrl,
  htmlUrl,
  imageUploadError,
  logoError,
}) => {
  // const { handleClick, onBackClick } = prop;
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState<boolean>(true);
  // const [file, setFile] = useState<string | undefined>();
  // const [inputs, setInputs] = useState<string[]>([""]); // Initialize with one input
  // const [description, setDescription] = useState<string[]>([""]);
  // const [adGrpName, setAdGrpName] = useState<string>("");
  // const [logoError, setLogoError] = useState<string>("");

  // const [upload, setUpload] = useState<string>("");
  // const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  // const [video, setVideo] = useState<File[]>([]);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [sources, setSources] = useState<string[]>([]);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

  // const [imageUrl, setImageUrl] = useState<string[]>([]);
  // const [videoUrl, setVideoUrl] = useState<string[]>([]);
  // const [htmlUrl, setHtmlUrl] = useState<string[]>([]);

  const [html, setHtml] = useState<File[]>([]);
  const [defaultImage, setDefaultImage] = useState<string[]>([]);

  const [grpNameError, setGrpNameError] = useState<string>("");
  const [idCheck, setIdCheck] = useState<string>("");
  // const [headlineError, setHeadlineError] = useState<string>("");
  // const [descriptionError, setDescriptionError] = useState<string>("");
  // const [imageUploadError, setImageUploadError] = useState<string>("");

  const MAX_WIDTH = 250;
  const MAX_HEIGHT = 250;
  function validateImageSize(image: File, width: number, height: number) {
    return new Promise<boolean>((resolve) => {
      const img = new Image();
      img.onload = () => {
        const isValidSize = img.width <= width && img.height <= height;
        resolve(isValidSize);
      };
      img.src = URL.createObjectURL(image);
    });
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        validateImageSize(selectedFile, MAX_WIDTH, MAX_HEIGHT).then(
          (isValidSize) => {
            if (isValidSize) {
              dispatch(setLogoUrl(URL.createObjectURL(selectedFile)));
              // setFile(URL.createObjectURL(selectedFile));
              dispatch(setLogoError(""));
              if (e.target.files && e.target.files.length > 0) {
                uploadFiles(
                  e.target.files,
                  "admattic-internal-data-storage",
                  "AdservingPlatform/Logo",
                  "LOGO"
                );
              }
              // dispatch(setLogoUrl(URL.createObjectURL(e.target.files[0])));
            } else {
              dispatch(setLogoUrl(""));
              // setFile(undefined);
              dispatch(
                setLogoError(
                  `Selected image must be ${MAX_WIDTH}x${MAX_HEIGHT} pixels or smaller.`
                )
              );
            }
          }
        );
      }
    }
  }

  const apiUpdate = (adgrpId: string) => {
    const adGrpName = localStorage.getItem("adGrpName") || "";
    // const htmlUrl = localStorage.getItem("htmlUrlArray") || "";
    // const imageUrl = localStorage.getItem("imageUrlArray") || "";
    // const videoUrl = localStorage.getItem("videoUrlArray") || "";

    // const headline = localStorage.getItem("headlines") || "";
    // const description = localStorage.getItem("description") || "";
    const searchParams = new URLSearchParams(window.location.search);
    const logoURL = localStorage.getItem("logoUrl") || "";
    // console.log(adgrpId)
    const jsonParam = {
      adGroupId: adgrpId,
      name: adGroupName,
      headline: headline.length != 0 ? headline : headline,
      logo: logoUrl,
      description: description.length != 0 ? description : description,
      // image: imageUrl != "" ? JSON.parse(imageUrl) : [],
      // videos: videoUrl != "" ? JSON.parse(videoUrl) : [],
      // HTML5: htmlUrl != "" ? JSON.parse(htmlUrl) : [],
      // Status:"active",
      image: imageUrl.length != 0 ? imageUrl : imageUrl,
      videos: videoUrl.length != 0 ? videoUrl : videoUrl,
      HTML5: htmlUrl.length != 0 ? htmlUrl : htmlUrl,
    };
    // const authToken = localStorage.getItem("authToken") || "";
    // const sessionID = localStorage.getItem("sessionID") || ""; // console.log(startDate,endDate)
    // const advertiserId = localStorage.getItem("advertiserID") || "";
    console.log(jsonParam);
    const encryptAdvertiserID = CryptoJS.AES.encrypt(
      JSON.stringify(advertiserId),
      secretKey
    ).toString();

    const header = {
      headers: {
        authtoken: authToken,
        sessionid: sessionId,
        advertiserid: encryptAdvertiserID, // Example authorization header
      },
    };
    // console.log(jsonParam);
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(jsonParam),
      secretKey
    ).toString();

    const url = `${apiURL}/api/campaigns/updateAdGroups`;
    if (authToken != "" && sessionId != "" && advertiserId != "") {
      axios
        .post(url, { params: encryptedData }, header)
        .then((res) => {
          // console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleError = () => {
    const queryParams = new URLSearchParams(window.location.search);
    // Get the "id" parameter from the query string
    const id = queryParams.get("id") || "NO";
    // console.log(logoError != "", logoUrl != undefined, logoUrl.length);
    let message;
    if (adGroupName != null && adGroupName == "") {
      setGrpNameError("Please enter a Ad Group Name.");
    } else if (
      (logoUrl != undefined && logoUrl.length == 0) ||
      logoError != ""
    ) {
      dispatch(setLogoError("Please enter a logo. "));
    } else if (headline != null && headline.length > 0 && headline[0] == "") {
      dispatch(setHeadlineError("please enter headline."));
    } else if (
      description != null &&
      description.length > 0 &&
      description[0] == ""
    ) {
      dispatch(setDescriptionError("please enter description."));
    } else if (
      (imageFile != undefined && imageFile.length == 0) ||
      imageUploadError != ""
    ) {
      dispatch(setImageUploadError("Please enter atleast one image."));
    }
    // console.log("headline",headline[0]=='',"description",description[0]=='')
    else if (grpNameError == "") {
      // localStorage.setItem("imageLength", imageFile.length.toString());
      // console.log(upload.length)
      const edit = localStorage.getItem("editingData") || "";
      console.log(edit);
      if (edit != "") {
        const converted = JSON.parse(edit);
        // console.log(converted.adGroups);
        if (converted.adGroups.length != 0) {
          apiUpdate(converted.adGroups[0].adGroupsId);
          if (handleClick) {
            // saveAdGroup(campaign);
            handleClick("UPDATE");
          }
        } else {
          if (handleClick) {
            // saveAdGroup(campaign);
            handleClick("NO");
          }
        }
      } else if (id == "NO") {
        // const campaign = localStorage.getItem("campaignId") || "";
        if (handleClick) {
          // saveAdGroup(campaign);
          handleClick("NO");
        }
      } else {
        saveAdGroup(id);
        // if (prop.handleClick) {
        // prop.handleClick();
        // }
      }
    }
  };

  const saveAdGroup = async (id: string) => {
    // const adGrpName = localStorage.getItem("adGrpName") || "";
    // const htmlUrl = localStorage.getItem("htmlUrlArray") || "";
    // const imageUrl = localStorage.getItem("imageUrlArray") || "";
    // const videoUrl = localStorage.getItem("videoUrlArray") || "";

    // const headline = localStorage.getItem("headlines") || `[""]`;
    // const description = localStorage.getItem("description") || `[""]`;
    const campaign = localStorage.getItem("campaignId") || "";
    const searchParams = new URLSearchParams(window.location.search);
    // const logoURL = localStorage.getItem("logoUrl") || "";
    const countParam = searchParams.get("id") || "";
    let jsonParam = {
      // campaignId: countParam != "" ? countParam : campaign,
      campaignId: id,
      name: adGroupName,
      headline: headline.length != 0 ? headline : headline,
      logo: logoUrl,
      description: description.length != 0 ? description : description,
      image: imageUrl.length != 0 ? imageUrl : imageUrl,
      videos: videoUrl.length != 0 ? videoUrl : videoUrl,
      HTML5: htmlUrl.length != 0 ? htmlUrl : htmlUrl,
    };
    console.log(jsonParam);
    // const authToken = localStorage.getItem("authToken") || "";
    // const sessionID = localStorage.getItem("sessionID") || ""; // console.log(startDate,endDate)
    // const advertiserId = localStorage.getItem("advertiserID") || "";
    const encryptAdvertiserID = CryptoJS.AES.encrypt(
      JSON.stringify(advertiserId),
      secretKey
    ).toString();

    const header = {
      headers: {
        authtoken: authToken,
        sessionid: sessionId,
        advertiserid: encryptAdvertiserID, // Example authorization header
      },
    };
    // console.log(jsonParam);
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(jsonParam),
      secretKey
    ).toString();

    let url = `${apiURL}/api/campaigns/saveAdGroups`;
    if (authToken != "" && sessionId != "" && advertiserId != "") {
      await axios
        .post(url, { params: encryptedData }, header)
        .then((res) => {
          console.log(id);
          if (handleClick) {
            handleClick(id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let val = event.target.value;
    // console.log(val);
    setGrpNameError("");
    dispatch(setAdGroupName(val));
    localStorage.setItem("adGrpName", val);
  };

  AWS.config.update({
    region: "ap-south-1",
    accessKeyId: "AKIA5VMVCATGUK75O2XE",
    secretAccessKey: "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",
  });

  const s3 = new AWS.S3();

  async function uploadToS3(
    file: File,
    bucketName: string,
    folderName: string,
    fileName: string
  ): Promise<string> {
    const params = {
      Bucket: bucketName,
      Key: `${folderName}/${fileName}`,
      Body: file,
    };

    return new Promise((resolve, reject) => {
      s3.putObject(params, async (err, data) => {
        if (err) {
          console.error("Error uploading to S3:", err);
          reject(err);
        } else {
          // console.log("Successfully uploaded to S3", data);

          // Get the URL for the uploaded file

          const url = s3.getSignedUrl("getObject", {
            Bucket: bucketName,
            Key: `${folderName}/${fileName}`,
          });

          resolve(url);
        }
      });
    });
  }
  const handleRemove = (index: number) => {
    const newSources = [...sources];
    newSources.splice(index, 1);
    setSources(newSources);
  };

  const removeFile = (fileIndex: number) => {
    // const updatedFiles = [...selectedFiles];
    const updatedFiles = [...htmlFile];

    updatedFiles.splice(fileIndex, 1);
    // setSelectedFiles(updatedFiles);
    dispatch(setHtmlFile(updatedFiles));
  };

  function cropUrlBeforeQuestionMark(url: string): string {
    const parts = url.split("?"); // Split the URL string based on the question mark
    return parts[0]; // Return the part before the question mark
  }

  const uploadFiles = (
    files: FileList,
    bucketName: string,
    folderName: string,
    fileType: string
  ) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      uploadToS3(file, bucketName, folderName, file.name)
        .then((res) => {
          const croppedUrl = cropUrlBeforeQuestionMark(res);
          // console.log(croppedUrl);
          // console.log(res);
          if (fileType == "HTML") {
            // setHtmlUrl((prevUrl) => [...prevUrl, res]);
            dispatch(setHtmlUrl([croppedUrl]));
            const dataVideo = [...htmlUrl, croppedUrl];
            // console.log(dataVideo)
            localStorage.setItem("htmlUrlArray", JSON.stringify(dataVideo));
          } else if (fileType == "VIDEO") {
            // (setVideoUrl((prevUrl) => [...prevUrl, croppedUrl]));
            const dataVideo = [...videoUrl, croppedUrl];
            dispatch(setVideoUrl([croppedUrl]));
            // console.log(dataVideo)
            localStorage.setItem("videoUrlArray", JSON.stringify(dataVideo));
          } else if (fileType == "IMAGE") {
            dispatch(setImageUrl([croppedUrl]));
            // setUpload(croppedUrl)
            const dataVideo = [croppedUrl];
            // console.log(dataVideo)
            localStorage.setItem("imageUrlArray", JSON.stringify(dataVideo));
          } else if (fileType == "LOGO") {
            // console.log("here", croppedUrl);
            dispatch(setLogoUrl(croppedUrl.toString()));
            localStorage.setItem("logoUrl", croppedUrl.toString());
          }
        })
        .catch((err) => {});
    }
  };
  const togglebutton = () => {
    setButtonDisabled(!buttonDisabled);
  };
  //HTML FILE UPLOAD
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      // setSelectedFiles((prevSelectedFiles) => [
      //   ...prevSelectedFiles,
      //   ...Array.from(files),
      // ]);
      dispatch(setHtmlFile([...Array.from(files)]));
      // setSelectedFiles([...Array.from(files)]);
      // setHtml((prev) => [...prev, ...Array.from(files)]);
      // localStorage.setItem("htmlNum", JSON.stringify(data));

      // const data = [...html, files];
      uploadFiles(
        files,
        "admattic-internal-data-storage",
        "AdservingPlatform/html",
        "HTML"
      );
      // event.target.value = ""; // Reset the input value to allow selecting the same file again
    }
  };

  // const onDrop = (pictureFiles: File[], pictureDataURLs: string[]) => {
  //   if (pictureFiles.length > 0) {
  //     const filesAsFileList = new DataTransfer();
  //     for (const file of pictureFiles) {
  //       filesAsFileList.items.add(file);
  //     }
  //     uploadFiles(
  //       filesAsFileList.files,
  //       "segumento-data",
  //       "AdservingPlatform/Images",
  //       "IMAGE"
  //     );
  //     setUpload((prevFile) => [...prevFile, ...pictureFiles]);
  //   }
  // };

  // const onDrop = (pictureFiles: File[], pictureDataURLs: string[]) => {
  const onDrop = (e: ChangeEvent<HTMLInputElement>) => {
    // console.log("onDrop");
    const minWidth = 700;
    const minHeight = 700;

    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        validateImageSize(selectedFile, minWidth, minHeight).then(
          (isValidSize) => {
            if (isValidSize) {
              // setUpload(URL.createObjectURL(selectedFile));
              dispatch(setImageFile(URL.createObjectURL(selectedFile)));
              dispatch(setImageUploadError(""));
              if (e.target.files && e.target.files.length > 0) {
                uploadFiles(
                  e.target.files,
                  "admattic-internal-data-storage",
                  "AdservingPlatform/Images",
                  "IMAGE"
                );
                // setUpload(URL.createObjectURL(e.target.files[0]));
              }
            } else {
              // setUpload("");
              dispatch(setImageFile(""));
              dispatch(
                setImageUploadError(
                  `Selected image must be ${minWidth}x${minHeight} pixels or smaller.`
                )
              );
            }
          }
        );
      }
    }

    // console.log(pictureFiles);
    // const filesAsFileList = new DataTransfer();
    // let allFilesMeetResolution = true;

    // for (const file of e.target.files) {
    //   const image = new Image();
    //   image.onload = () => {
    //     if (image.width > minWidth || image.height > minHeight) {
    //       // Image does not meet the resolution requirements
    //       allFilesMeetResolution = false;
    //       setImageUploadError(`Image ${file.name} should be of 700 x 700`);
    //     }
    //   };

    // Set the source of the image to the selected file
    // image.src = URL.createObjectURL(file);
    // console.log(allFilesMeetResolution);
    // Add the file to the file list only if it meets the resolution requirements
    // if (allFilesMeetResolution) {
    // console.log(`Image ${file.name} does not meet the resolution requirements`);
    // setUpload(pictureFiles);
    // filesAsFileList.items.add(file);
    // }
    // }

    // if (allFilesMeetResolution) {
    // Proceed with the upload logic

    // } else {
    // Display an error message or take appropriate action
    // console.log("One or more images do not meet the resolution requirements");
    // }
  };

  useEffect(() => {
    // localStorage.setItem("imageUrlArray", "");
    // localStorage.setItem("htmlUrlArray", "");
    // localStorage.setItem("videoUrlArray", "");
    // localStorage.setItem("headlines", "");
    // localStorage.setItem("description", "");
    console.log(logoUrl);
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id") || "";
    setIdCheck(id);
    if (headline.length == 0) {
      dispatch(setHeadline([""]));
    }
    if (description.length == 0) {
      dispatch(setDescription([""]));
    }
    // console.log(htmlUrl, htmlFile, imageUrl, imageFile, videoUrl, videoFile);
    // console.log(
    //   htmlUrl.length,
    //   htmlFile.length,
    //   imageUrl.length,
    //   imageFile.length,
    //   videoUrl.length,
    //   videoFile.length
    // );

    if (htmlFile.length != 0) {
      dispatch(setHtmlFile(htmlUrl.map((url) => new File([url], url))));
    }
    if (imageUrl.length != 0) {
      if (imageUrl[imageUrl.length - 1] != "") {
        setDefaultImage(imageUrl);
        dispatch(setImageFile(imageUrl[0]));
        setDefaultImage(imageUrl);
      }
    }
    if (imageFile != undefined && imageFile.length != 0) {
    }
    if (videoUrl.length != 0) {
      if (videoUrl[videoUrl.length - 1] != "") {
        setSources(videoUrl);
        setSources(videoUrl);
      }
    }
    if (videoFile.length != 0) {
    }

    // const adGrpName = localStorage.getItem("adGrpName") || "";
    // const htmlUrl = localStorage.getItem("htmlUrlArray") || `[""]`;
    // const imageUrl = localStorage.getItem("imageUrlArray") || `[""]`;
    // const videoUrl = localStorage.getItem("videoUrlArray") || `[""]`;
    // const headline = localStorage.getItem("headlines") || `[""]`;
    // const description = localStorage.getItem("description") || `[""]`;
    // const logoURL = localStorage.getItem("logoUrl") || "";
    // console.log(htmlUrl, imageUrl, videoUrl, headline, description, logoURL);
    // const html = JSON.parse(htmlUrl);
    // const image = JSON.parse(imageUrl);
    // const video = JSON.parse(videoUrl);
    // const descri = JSON.parse(description);
    // const head = JSON.parse(headline);
    // console.log( head);
    // const data = localStorage.getItem("editingData") || "";
    // if (data != "" && data != "undefined") {
    //   const convertedData = JSON.parse(data);

    //   if (convertedData.adGroups.length != 0) {
    //     const queryParams = new URLSearchParams(window.location.search);
    //     const id = queryParams.get("id") || "";
    //     console.log(convertedData);
    //     setIdCheck(id);
    //     console.log(
    //       adGrpName == "" ? convertedData.adGroups[0].name : adGrpName,

    //       head[0],
    //       convertedData.adGroups[0].headline,
    //       head.length,
    //       descri[0],
    //       convertedData.adGroups[0].description,
    //       descri.length
    //     );
    //     localStorage.setItem(
    //       "adGrpName",
    //       adGrpName == "" ? convertedData.adGroups[0].name : adGrpName
    //     );
    //     localStorage.setItem(
    //       "htmlUrlArray",
    //       JSON.stringify(html[0] == "" ? convertedData.adGroups[0].HTML5 : html)
    //     );
    //     localStorage.setItem(
    //       "imageUrlArray",
    //       JSON.stringify(
    //         image[0] == "" ? convertedData.adGroups[0].image : image
    //       )
    //     );
    //     localStorage.setItem(
    //       "videoUrlArray",
    //       JSON.stringify(
    //         video[0] == "" ? convertedData.adGroups[0].videos : video
    //       )
    //     );
    //     localStorage.setItem(
    //       "headlines",
    //       JSON.stringify(
    //         head.length == 0 ? convertedData.adGroups[0].headline : head
    //       )
    //     );
    //     localStorage.setItem(
    //       "description",
    //       JSON.stringify(
    //         descri.length == 0 ? convertedData.adGroups[0].description : descri
    //       )
    //     );
    //     localStorage.setItem(
    //       "logoUrl",
    //       logoURL == "" ? convertedData.adGroups[0].logo : logoURL
    //     );
    //     // console.log(
    //     //   convertedData.adGroups
    //     //   // JSON.parse(convertedData.adGroups[0].HTML5),
    //     //   // JSON.parse(convertedData.adGroups[0].image),
    //     //   // JSON.parse(convertedData.adGroups[0].videos),
    //     //   // JSON.parse(convertedData.adGroups[0].headline),
    //     //   // convertedData.adGroups[0].description,
    //     //   // convertedData.adGroups[0].logo
    //     // );
    //     // console.log(htmlUrl, imageUrl, videoUrl, headline, description, logoURL);
    //     const htmln =
    //       html[0] == ""
    //         ? convertedData.adGroups[0].HTML5
    //         : convertedData.adGroups[0].HTML5;
    //     const imagen =
    //       image[0] == ""
    //         ? convertedData.adGroups[0].image
    //         : convertedData.adGroups[0].image;
    //     const videon =
    //       video[0] == ""
    //         ? convertedData.adGroups[0].videos
    //         : convertedData.adGroups[0].videos;
    //     const headn =
    //       head[0] == ""
    //         ? convertedData.adGroups[0].headline
    //         : convertedData.adGroups[0].headline;
    //     const descrin =
    //       descri[0] == ""
    //         ? convertedData.adGroups[0].description
    //         : convertedData.adGroups[0].description;
    // (head.map((val:any)=>{
    // }))

    // dispatch(setHtmlUrl(htmln));
    // setImageUrl(image);
    // const filesArray: File[] = Array.from(htmlUrl);
    // setSelectedFiles(htmlUrl[0]);

    // setUpload(imageUrl);
    // dispatch(setVideoUrl(videon));

    // setInputs(headn);
    // dispatch(setHeadline(headn))
    // console.log(JSON.parse(head));
    // if (headn.length == 0) {
    //   // setInputs([""]);
    //   dispatch(setHeadline([""]))
    //   if (descrin.length == 0) {
    //     dispatch(setDescription([""]));
    //   }
    // } else {
    //   dispatch(setDescription(descrin));
    // }
    // dispatch(setLogoUrl(convertedData.adGroups[0].logo))
    // setFile(convertedData.adGroups[0].logo);
    // dispatch(setAdGroupName(convertedData.adGroups[0].name));
    // }
    // }
    // else {
    // dispatch(setHtmlUrl(html));
    // setSelectedFiles(htmlUrl.map((url) =>{return new File([url], url)}));
    // setSelectedFiles(htmlUrl.map((url) => new File([url], url)));
    // setUpload(imageUrl[0]);

    // setImageUrl(image);
    // dispatch(setVideoUrl(video));
    // console.log(descri);
    // if (head.length == 0) {
    //   // setInputs([""]);
    //   dispatch(setHeadline([""]))
    //   if (descri.length == 0) {
    //     dispatch(setDescription([""]));
    //   }
    // } else {
    //   dispatch(setDescription(descri));
    //   // setInputs(head);
    //   dispatch(setHeadline(head))
    // }
    // dispatch(setLogoUrl(logoURL))
    // // setFile(logoURL);
    // dispatch(setAdGroupName(adGrpName));
    // }
  }, []);

  // VIDEO UPLOAD
  const handleFileChangeVideo = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      uploadFiles(
        files,
        "admattic-internal-data-storage",
        "AdservingPlatform/Video",
        "VIDEO"
      );
      // const data = [...video, files];
      const data = [files];

      // setVideo((prev) => [ ...Array.from(files)]);
      // setVideo([...Array.from(files)]);
      dispatch(setVideoFile([...Array.from(files)]));
      // localStorage.setItem("videoNum", JSON.stringify(data));

      const file = files[0];
      const url = URL.createObjectURL(file);
      setSources((prevSources) => [...prevSources, url]);
      event.target.value = ""; // Reset the input value to allow selecting the same file again
    }
  };

  const handleInputChange = (index: number, value: string) => {
    if (value.length <= 150) {
      dispatch(setHeadlineError(""));
      const newInputs = [...headline];
      newInputs[index] = value;
      // setInputs(newInputs);
      dispatch(setHeadline(newInputs));
      localStorage.setItem("headlines", JSON.stringify(newInputs));
    }
  };
  const handleDescription = (index: number, value: string) => {
    if (value.length <= 150) {
      dispatch(setDescriptionError(""));
      const newInputs = [...description];
      newInputs[index] = value;
      dispatch(setDescription(newInputs));
      localStorage.setItem("description", JSON.stringify(newInputs));
    }
  };

  const addInput = () => {
    // setInputs([...inputs, ""]);
    dispatch(setHeadline([...headline, ""]));
  };
  const addDescription = () => {
    dispatch(setDescription([...description, ""]));
  };
  const removeInput = (index: number) => {
    const newInputs = [...headline];
    newInputs.splice(index, 1);
    // setInputs(newInputs);
    dispatch(setHeadline(newInputs));
  };
  const removeDescription = (index: number) => {
    const newInputs = [...description];
    newInputs.splice(index, 1);
    dispatch(setDescription(newInputs));
  };

  const divStyle: React.CSSProperties = {
    backgroundImage: `url(${imageFile !== "" ? imageFile : ""})`,
    // e.target.files
    backgroundSize: "cover", // Optional: Adjust to your needs
    backgroundRepeat: "no-repeat", // Optional: Adjust to your needs
    backgroundPosition: "center", // Optional: Adjust to your needs
    // Other styles...
  };

  return (
    <div>
      <div className="ad-group-main">
        <p className="dark-text">Create AD Groups</p>
        <p className="light-text">
          (For more accurate targe ting, organize each ad group around a
          specific theme, audience, or message)
        </p>
      </div>

      <div className="upper-box">
        <div>
          <div className="box">
            {/* <Stack
            component="form"
            sx={{
              width: "25ch",
            }}
            spacing={2}
            noValidate
            autoComplete="off"
          > */}
            {/* <CustomTextField/> */}
            <TextField
              hiddenLabel
              id="filled-hidden-label-small"
              // disabled={buttonDisabled}
              defaultValue="Enter a Ad Group Name"
              className="hideborder"
              variant="filled"
              size="small"
              sx={{
                "& label.Mui-focused": {
                  color: "#6900b8",
                },
                // focused color for input with variant='standard'
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#6900b8",
                },
                // focused color for input with variant='filled'
                "& .MuiFilledInput-underline:after": {
                  borderBottomColor: "#6900b8",
                },
                // focused color for input with variant='outlined'
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#6900b8",
                  },
                },
              }}
              value={adGroupName}
              onChange={onChangeInput}
            />
            {/* <img
              src={edit}
              alt="edit"
              className="edit-adGrp"
              onClick={togglebutton}
            /> */}
          </div>
          {grpNameError != "" ? (
            <div className="error-box" style={{ width: "100%", margin: 0 }}>
              <BiSolidErrorCircle className="error-icon" />
              <p>{grpNameError}</p>
            </div>
          ) : null}
          {/* </Stack> */}
        </div>
        <div
          className="box"
          onClick={() => setShowModal(!showModal)}
          style={{ cursor: "pointer" }}
        >
          <h3 className="camp-text">Collapse</h3>
          <BiChevronUp className="up-icon" />
        </div>
      </div>
      <div style={{ display: showModal ? "block" : "none" }}>
        <div className="app">
          <h4>Add Logo:&nbsp;&nbsp;&nbsp;&nbsp;</h4>
          <div>
            <div className="image-container">
              {logoUrl != "" ? (
                <img
                  src={logoUrl}
                  alt="Uploaded"
                  style={{ width: "110px", height: "110px" }}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <IoCloudUploadOutline style={{ fontSize: "40px" }} />
                  <p style={{ margin: 0, fontSize: "12px" }}>no file choosen</p>
                </div>
              )}
            </div>
            <input
              type="file"
              id="file-input"
              onChange={handleChange}
              name="file-input"
            />

            <label id="file-input-label" htmlFor="file-input">
              Select a File
            </label>
            {/* <input type="file" onChange={handleChange} /> */}
          </div>
        </div>
        {logoError != "" && logoError != null && (
          <div className="error-box" style={{ width: "50%", margin: 0 }}>
            <BiSolidErrorCircle className="error-icon" />
            <p>{logoError}</p>
          </div>
        )}
        <div className="campaign-box">
          <h4>Headlines</h4>
          <div className="box-divider">
            <div className="box-adjustment">
              {headline
                ? headline.map((input, index) => (
                    <div>
                      <div key={index} className="inside-text-input">
                        <p>Headline{index + 1}</p>
                        {/* <img src={cross} onClick={() => removeInput(index)} /> */}
                        <input
                          type="text"
                          value={input}
                          onChange={(e) =>
                            handleInputChange(index, e.target.value)
                          }
                          placeholder="Enter text here"
                        />

                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: 600,
                            opacity: 0.6,
                          }}
                        >
                          {headline[index].length}/150
                        </div>
                      </div>
                      {headlineError != "" && headlineError != null && (
                        <div
                          className="error-box"
                          style={{ width: "50%", margin: 0 }}
                        >
                          <BiSolidErrorCircle className="error-icon" />
                          <p>{headlineError}</p>
                        </div>
                      )}
                    </div>
                  ))
                : null}
            </div>
            {/* <button className="black-white-btn" onClick={addInput}>
              + Add Headline
            </button> */}
          </div>
        </div>

        <div className="campaign-box">
          <h4>Description</h4>
          <div className="box-divider">
            <div className="box-adjustment">
              {description
                ? description.map((input, index) => (
                    <div>
                      <div key={index} className="inside-text-input">
                        <p style={{ top: "10px" }}>Description{index + 1}</p>
                        {/* <img src={cross} onClick={() => removeDescription(index)} /> */}
                        <input
                          type="text"
                          value={input}
                          onChange={(e) =>
                            handleDescription(index, e.target.value)
                          }
                          placeholder="Enter text here"
                          style={{ padding: "20px 30px 10px 7px" }}
                        />
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: 600,
                            opacity: 0.6,
                          }}
                        >
                          {description[index].length}/150
                        </div>
                      </div>
                      {descriptionError != "" && descriptionError != null && (
                        <div className="error-box" style={{ margin: 0 }}>
                          <BiSolidErrorCircle className="error-icon" />
                          <p>{descriptionError}</p>
                        </div>
                      )}
                    </div>
                  ))
                : null}
            </div>
            {/* <button className="black-white-btn" onClick={addDescription}>
              + Add Description
            </button> */}
          </div>
        </div>
        <div
          style={{ width: "100%", display: "flex", alignItems: "flex-start" }}
        >
          <div style={{ width: "70%", marginRight: "25px" }}>
            <div className="campaign-box">
              <h4>Images</h4>
              {/* <ImageUploader
                withIcon={false}
                withLabel={false}
                withPreview={true}
                buttonText="+ Add Image"
                fileSizeError="File size is too big"
                fileTypeError="This extension is not supported"
                onChange={onDrop}
                imgExtension={[".jpg", ".png", ".jpeg", ".svg"]}
                maxFileSize={5242880}
                {...{ defaultImages: defaultImage }}
                // defaultImages={defaultImage}
              /> */}
              <div style={{ height: "168px" }}>
                <div className="image-container">
                  {imageFile != "" ? (
                    <img
                      src={imageFile}
                      alt="Uploaded"
                      style={{ width: "110px", height: "110px" }}
                    />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "10px",
                      }}
                    >
                      <IoCloudUploadOutline style={{ fontSize: "40px" }} />
                      <p style={{ margin: 0, fontSize: "12px" }}>
                        no file choosen
                      </p>
                    </div>
                  )}
                </div>

                <label className="new-img-btn" htmlFor="file-input-img">
                  <input
                    type="file"
                    id="file-input-img"
                    onChange={onDrop}
                    name="file-input-img"
                  />
                  Select a Image
                </label>
                {/* <input type="file" onChange={handleChange} /> */}
              </div>
            </div>
            {imageUploadError != "" && imageUploadError != null && (
              <div className="error-box" style={{ width: "50%", margin: 0 }}>
                <BiSolidErrorCircle className="error-icon" />
                <p>{imageUploadError}</p>
              </div>
            )}
            <div
              style={{
                fontSize: "12px",
                fontWeight: 600,
                opacity: 0.6,
                margin: "10px 0 0 0",
              }}
            >
              Note: Image should be of maximum 700 x 700
            </div>
            <div className="campaign-box">
              <h4>Videos</h4>

              <div className="box-adjustment">
                {sources.map((source, index) => (
                  <div key={index}>
                    <div className="add-box">
                      <img
                        src={cross}
                        onClick={() => handleRemove(index)}
                        style={{
                          position: "absolute",
                          top: "8px",
                          right: "8px",
                        }}
                      />
                      <h5>{source}</h5>
                    </div>
                  </div>
                ))}
              </div>

              <div className="file-upload-container">
                <label
                  className="black-white-btn"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "14px",
                  }}
                >
                  <input
                    ref={inputRef}
                    className="VideoInput_input"
                    type="file"
                    onChange={handleFileChangeVideo}
                    accept=".mov,.mp4"
                    style={{ display: "none" }}
                  />
                  + Add Video
                </label>
              </div>
            </div>

            <div className="campaign-box">
              <h4>HTML</h4>
              <div className="box-adjustment">
                {htmlFile.length != 0 ? (
                  <div>
                    {htmlFile.map((file, index) => (
                      <div key={index} className="selected-file">
                        <AiOutlineFolderOpen className="file-icon" />
                        <span>
                          {file.name
                            ? file.name
                              ? null
                              : `${htmlFile[index].slice(0, 17)}`
                            : null}
                        </span>
                        <img src={cross} onClick={() => removeFile(index)} />
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
              <div className="file-upload-container">
                <label
                  className="black-white-btn"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "14px",
                  }}
                >
                  <input
                    type="file"
                    accept=".html"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                  + Add HTML5
                </label>

                {/* <button onClick={handleUpload}>Upload</button> */}
              </div>
            </div>
          </div>
          <div style={{ width: "40%" }}>
            <p
              className="dark-text"
              style={{
                fontWeight: 600,
                fontSize: "14px",
                opacity: 0.6,
                marginTop: "35px",
              }}
            >
              Media Group
            </p>
            {/* <img src={mobileView} style={{ width: "80%", marginTop: "35px" }} /> */}
            <div className="images-container">
              <img src={phoneLayout} className="phn-layout" />
              <div className="images-width" style={{ zIndex: 0 }}>
                <img src={phoneTop} className="phn-top" />
                <img src={phoneHeadline} className="phn-headline" />
                <div className="image-show" style={divStyle}>
                  <div className="img-heading">
                    {headline
                      ? headline[headline.length - 1] == ""
                        ? "Headlines"
                        : headline[headline.length - 1]
                      : ""}
                  </div>

                  <div className="img-description">
                    {description
                      ? description[description.length - 1] == ""
                        ? "Description"
                        : description[description.length - 1]
                      : "Description"}
                  </div>

                  <button className="const-btn">Install -&gt;</button>
                </div>
                <img src={phoneFooter} className="phn-footer" />
              </div>
            </div>
            <p style={{ fontSize: "10px", opacity: 0.5 }}>
              Previews shown here are examples and don't include all possible
              formats. You're responsible for the content of your ads. Please
              make sure that your provided assets don't violate policy, either
              individually, or in combination.
            </p>
          </div>
        </div>
      </div>
      <div className="doneButton-box" style={{ marginTop: "10vh" }}>
        <button
          onClick={onBackClick}
          className="border-btn"
          style={{ visibility: idCheck != "" ? "hidden" : "visible" }}
        >
          Cancel
        </button>
        <button className="purple-btn width-adjust" onClick={handleError}>
          {idCheck != "" ? "Done" : "Next"}
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  adGroupName: state.user.adGroupName,
  logoUrl: state.user.logoUrl,
  headline: state.user.headline,
  description: state.user.description,
  imageUrl: state.user.imageUrl,
  videoUrl: state.user.videoUrl,
  htmlUrl: state.user.htmlUrl,
  descriptionError: state.user.descriptionError,
  headlineError: state.user.headlineError,

  htmlFile: state.user.htmlFile,
  videoFile: state.user.videoFile,
  imageFile: state.user.imageFile,
  logoError: state.user.logoError,
  imageUploadError: state.user.imageUploadError,

  authToken: state.user.authToken,
  sessionId: state.user.sessionId,
  advertiserId: state.user.advertiserId,
  advertiserIdCampaign: state.user.advertiserIdCampaign,
});

const mapDispatchToProps = {
  setDescriptionError,
  setHeadlineError,
  setAdGroupName,
  setLogoUrl,
  setHeadline,
  setDescription,
  setImageUrl,
  setVideoUrl,
  setHtmlUrl,
  setVideoFile,
  setImageFile,
  setHtmlFile,
  setImageUploadError,
  setLogoError,
  setAdvertiserId,
  setAuthToken,
  setSessionId,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdGroup);
