import React, { useState, useEffect, useRef, FC } from 'react';
import { MenuItem, Button, TextField, Menu, ListSubheader, Snackbar, Checkbox, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import '../../CSS/report.css';
import '../../CSS/textfield.css';
import '../../CSS/DateCustom.css'
import RefreshIcon from '@mui/icons-material/Refresh';
import ProductTable from 'react-data-table-component'
import { PurpleCustomButton } from '../custom/PurpleButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import RemoveCircleOutlinedIcon from '@mui/icons-material/RemoveCircleOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { Cascader } from 'antd';
import { DateRangePicker } from 'react-date-range';
import calender from "../../IMAGE/calender.svg";
import { useNavigate } from 'react-router-dom';
import CustomLoader from '../custom/CustomLoader';
import { DownloadButton, apiURL, customCheckBoxColor, secretKey } from '../../Constant';
import { connect } from 'react-redux';
import { setAdvertiserId, setAuthToken, setCurrencySymbol, setSessionId } from '../../REDUX/Actions/userActionIndex';
import { RootState } from '../../REDUX/Reducers/rootReducer';
import { SignalCellularNullTwoTone } from '@mui/icons-material';

const CryptoJS = require('crypto-js')

interface Option {
  value: string | number;
  label: string;
  children?: Option[];
}

interface ReportScreenProps {
  currencySymbol: any;
  authToken: any;
  sessionId: any;
  advertiserId: any;
}

const CustomSelect = styled(Select)(({ theme }) => ({
  backgroundColor: "white",
  fontFamily: "Manrope",
  fontSize: "14px",
  fontWeight: "600",
  width: "90%",
  height: "45px",
  borderRadius: "2px",
  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
  {
    border: "1px solid black",
  },
}));

const SearchBox = styled(TextField)(() => ({
  width: "100%",
  marginRight: '10px',
  borderRadius: 0,
  '&.MuiTextField-root input': {
    height: "30px",
    padding: "5px",
    border: "1px solid rgba(51, 51, 51, 0.3)",
    outline: 'none',
  },
  '& fieldset': {
    border: "none",
    outline: 'none',
  },
  '&.Mui-focused fieldset': {
    border: "none",
    outline: 'none',
  },
}))

const BlackButton = styled('button')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  backgroundColor: 'black',
  borderRadius: '3px',
  color: 'white',
  fontSize: '13px',
  padding: '5px 10px',
  fontWeight: 600,

  width: "auto",
}));

const RefreshBtn = styled('button')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  backgroundColor: 'rgba(153, 153, 153, 0.2)',
  borderRadius: '3px',
  color: '#6900B8',
  fontSize: '13px',
  padding: '8px 16px',
  fontWeight: 600,

  width: "120px",
  border: "none",
  marginLeft: "10px"
}));

const cascaderStyle = {
  width: '180px',
  height: '40px',
  marginRight: '10px',
  borderRadius: 0,

}

function ParseFloat(str: any, val: any) {
  str = str.toString();
  str = str.slice(0, (str.indexOf(".")) + val + 1);
  return Number(str);
}

const ReportScreen: React.FC<ReportScreenProps> = (props) => {

  const { currencySymbol, authToken, sessionId, advertiserId } = props

  const [advertiserID, setAdvertiserID] = useState<any>(advertiserId)
  const [sessionID, setSessionID] = useState<any>(sessionId)
  const [authTokenID, setAuthTokenID] = useState<any>(authToken)
  const [currency, setCurrency] = useState<any>(currencySymbol)

  const [appArray, setAppArray] = useState<any[]>([])
  const [adArray, setAdArray] = useState<any[]>([])
  const [campArray, setCampArray] = useState<any[]>([])
  const navigate = useNavigate()

  const options: Option[] = [
    {
      value: 'status',
      label: 'Status',
      children: [
        {
          value: 'active',
          label: 'Active',
        },
        {
          value: 'paused',
          label: 'Paused',
        },
        {
          value: 'stopped',
          label: 'Stopped',
        },
      ],
    },
    {
      value: 'time',
      label: 'Time',
      children: [
        {
          value: 'today',
          label: 'Today',
        },
        {
          value: 'yesterday',
          label: 'Yesterday',
        },
        {
          value: 'thisWeek',
          label: 'This Week',
        },
        {
          value: 'thisMonth',
          label: 'This Month',
        },
        {
          value: 'lastMonth',
          label: 'LastMonth',
        },
      ],
    },
  ];

  const status: any = [
    {
      value: 'active',
      label: 'active',
    },
    {
      value: 'pending',
      label: 'pending',
    },
    {
      value: 'paused',
      label: 'paused',
    },
    {
      value: 'disabled',
      label: 'disabled',
    },
    {
      value: 'expired',
      label: 'expired',
    },
  ]

  const timeArray = [
    {
      value: 'today',
      label: 'Today',
    },
    {
      value: 'yesterday',
      label: 'Yesterday',
    },
    {
      value: 'thisWeek',
      label: 'This Week',
    },
    {
      value: 'thisMonth',
      label: 'This Month',
    },
    {
      value: 'lastMonth',
      label: 'Last Month',
    },
  ]

  const groupByMenu = [
    {
      name: "applicationName",
      label: "Application Name"
    },
    {
      name: "campaignName",
      label: "Campaign Name"
    }
  ]
  const [products, setProducts] = useState<any[]>([])
  const [applicationProducts, setAppProducts] = useState<any[]>([])
  const [filteredProducts, setFilteredProducts] = useState<any[]>([])
  const [adSearch, setAdSearch] = React.useState('')
  const [statusSearch, setStatusSearch] = useState<any>('')
  const [timeSearch, setTimeSearch] = useState<any>('')
  const [campaignSearch, setCampaignSearch] = React.useState('')
  const [groupBySearch, setGroupBySearch] = React.useState('campaignName')
  const [appSearch, setAppSearch] = useState('')
  const [loaderActive, setLoaderActive] = useState<boolean>(false)
  const [datePicked, setDatePicked] = useState<boolean>(false);
  const [toggleCal, setToggleCal] = useState<string>("none");
  const boxRef = useRef<HTMLDivElement | null>(null);
  const [openSession, setOpenSession] = React.useState(false);

  const handleSessionClose = () => {
    setOpenSession(false);
  };

  const toggleCalender = () => {
    if (toggleCal == "none") {
      setToggleCal("block");
    } else {
      setToggleCal("none");
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (boxRef.current && !boxRef.current.contains(event.target as Node)) {
      setToggleCal("none");
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleSelect = (ranges: any) => {
    if (!datePicked) {
      setDatePicked(true);
    }
    setDateRange(ranges.range1);
  };

  const formatChangeDate = (dateParam: any) => {
    const inputDateString = dateParam;
    const date = new Date(inputDateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate
  }

  const [dateRange, setDateRange] = useState<any>({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(),
  });

  const [dateFilter, setDateFilter] = useState<string>("")
  const [isExecuting, setIsExecuting] = useState(false);

  const [appLoader, setAppLoader] = useState(false)
  const [campaignLoader, setCampaignLoader] = useState(false)

  const [cols, setColumns] = useState<any>([
    {
      id: 'campaign',
      name: 'Campaign',
      selector: (row: any) => row.campaignName,
    },
    {
      id: 'date',
      name: 'Date',
      selector: (row: any) => row.date,
      sortable: dateFilter == "daily" ? true : false
    },
    {
      id: 'impressions',
      name: 'Impressions',
      selector: (row: any) => row.impressions,
      sortable: true,
    },
    {
      id: 'clicks',
      name: 'Clicks',
      selector: (row: any) => row.clicks,
      sortable: true
    },
    {
      id: 'ctr',
      name: 'CTR',
      selector: (row: any) => row.ctr,
      sortable: true
    },
    {
      id: 'cpc',
      name: 'CPC',
      selector: (row: any) => currency + " " + (row.cpc).toFixed(2),
    },
    {
      id: 'cpi',
      name: 'CPI',
      selector: (row: any) => currency + " " + (row.cpi).toFixed(2),
    },
    {
      id: 'installs',
      name: 'Installs',
      selector: (row: any) => row.installs,
      sortable: true
    },
    // {
    //   name: 'IPM',
    //   selector: (row: any) => row.ipm,
    //   width: '10%'
    // },
  ])

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredProducts);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(dataBlob, 'Report XLSX.xlsx'); // Specify the desired file name here
  };

  const onClickGetReport = async () => {
    setIsExecuting(true)
    setLoaderActive(true)
    setAppLoader(true)
    setCampaignLoader(true)
    try {
      const encryptAdvertiserID = CryptoJS.AES.encrypt(JSON.stringify(advertiserID), secretKey).toString()

      let headers = {
        'Content-Type': 'application/json',
        'advertiserid': encryptAdvertiserID,
        'authtoken': authTokenID,
        'sessionid': sessionID,
      };
      if (dateFilter != "") {
        if (dateRange.startDate != null && dateRange.endDate != null) {
          setDateRangeError("")

          let jsonParams = {
            startDate: formatChangeDate(dateRange.startDate),
            endDate: formatChangeDate(dateRange.endDate),
            advertiserId: advertiserId,
            dateFilter: dateFilter
          };

          const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(jsonParams), secretKey).toString();

          const response = await axios.post(`${apiURL}/api/reports/getReportsData`, { params: encryptedData }, { headers: headers });

          if (response.data.status == true) {
            const resData = response.data.data;
            const bytes = CryptoJS.AES.decrypt(resData, secretKey);
            const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
            const parsedData = JSON.parse(decryptedData);
            for (const obj of parsedData) {
              const appName = obj.applicationName;
              const campName = obj.campaignName;
              if (appName != null && !appArray.includes(appName)) {
                appArray.push(appName);
              }

              if (campName != null && !campArray.includes(campName)) {
                campArray.push(campName)
              }
              // const adGroup = obj.adGroups
              // for (const ad of adGroup) {
              //   const adName = ad.name
              //   if (!adArray.includes(adName)) {
              //     adArray.push(adName);
              //   }
              // }
            }
            const resDataArray: any = [];

            parsedData.length != 0 && parsedData.forEach((obj: any) => {
              const key = obj.applicationName;
              const campaignName = obj.campaignName;
              const records = obj.records;
              const status = obj.status;
              // const adGroups = obj.adGroups
              const cpi = obj.cpi;
              const ctr = obj.ctr;

              if (records.length != 0) {
                records.forEach((record: any) => {
                  const clicks = record.clicks;
                  // const ctr = record.ctr;
                  const cpc = record.cpc;
                  // const ipm = obj.ipm;
                  const installs = record.installs;
                  // const cpi = record.cpi;
                  const impressions = record.impressions;
                  const date = record.date;

                  resDataArray.push({
                    applicationName: key,
                    campaignName: campaignName,
                    // adGroups: adGroups,
                    impressions: impressions,
                    clicks: clicks,
                    cpc: cpc,
                    ctr: ctr,
                    // ipm: ipm,
                    installs: installs,
                    cpi: cpi,
                    status: status,
                    date: date,
                  });
                })

                if (dateFilter == "daily") {
                  resDataArray.sort(
                    (a: any, b: any) =>
                      new Date(a.date).getTime() - new Date(b.date).getTime()
                  );
                }
              }
            });

            setProducts(resDataArray)
            setFilteredProducts(resDataArray)
            // setProducts(resData)
            // setFilteredProducts(resData)
          } else if (response.data.statuscode == 401) {
            setOpenSession(true)
            setTimeout(() => { navigate("/") }, 2000)
          }
        } else {
          setDateRangeError("Select date range.")
        }
      } else {
        setDateRangeError("")
        let jsonParams = {
          startDate: dateRange.startDate == null ? "" : formatChangeDate(dateRange.startDate),
          endDate: dateRange.endDate == null ? "" : formatChangeDate(dateRange.endDate),
          advertiserId: advertiserId,
          dateFilter: "none"
        };

        const response = await axios.post(`${apiURL}/api/reports/getReportsData`, jsonParams, { headers: headers });

        if (response.data.status == true) {
          const resData = response.data.data;
          const bytes = CryptoJS.AES.decrypt(resData, secretKey);
          const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
          const parsedData = JSON.parse(decryptedData);
          for (const obj of parsedData) {
            const appName = obj.applicationName;
            const campName = obj.campaignName;
            if (appName != null && !appArray.includes(appName)) {
              appArray.push(appName);
            }

            if (campName != null && !campArray.includes(campName)) {
              campArray.push(campName)
            }
            // const adGroup = obj.adGroups
            // for (const ad of adGroup) {
            //   const adName = ad.name
            //   if (!adArray.includes(adName)) {
            //     adArray.push(adName);
            //   }
            // }
          }
          const resDataArray: any = [];

          parsedData.forEach((obj: any) => {
            const key = obj.applicationName;
            const campaignName = obj.campaignName;
            const records = obj.records;
            const status = obj.status;
            const cpi = obj.cpi;
            const ctr = obj.ctr;
            // const adGroups = obj.adGroups

            if (records.length != 0) {
              records.forEach((record: any) => {
                const clicks = record.clicks;
                // const ctr = record.ctr;
                const cpc = record.cpc;
                // const ipm = obj.ipm;
                const installs = record.installs;
                // const cpi = record.cpi;
                const impressions = record.impressions;
                const date = record.date;

                resDataArray.push({
                  applicationName: key,
                  campaignName: campaignName,
                  // adGroups: adGroups,
                  impressions: impressions,
                  clicks: clicks,
                  cpc: cpc,
                  ctr: ctr,
                  // ipm: ipm,
                  installs: installs,
                  cpi: cpi,
                  status: status,
                  date: date
                });
              })
            }
          });
          setProducts(resDataArray)
          setFilteredProducts(resDataArray)
        } else if (response.data.statuscode == 401) {
          setOpenSession(true)
          setTimeout(() => { navigate("/") }, 2000)
        }
      }
      setLoaderActive(false)
      onSelectGroupByApplication()
      groupBySearchFunction()
    } catch (error) {
      console.log(error);
    }
    setIsExecuting(false)
    setAppLoader(false)
    setCampaignLoader(false)
  };

  useEffect(() => {
    onClickGetReport()
  }, [])

  useEffect(() => {
    onClickGetReport()
  }, [dateFilter])

  const listHeaderStyle = {
    fontSize: "16px",
    color: "#6800b8",
    fontWeight: "700",
  }

  const handleTimeSelect = (e: any) => {
    const currentDate = new Date()
    setTimeSearch(e.target.value)
    switch (e.target.value) {
      case 'today':
        const todayStartDate = new Date(currentDate)
        todayStartDate.setDate(todayStartDate.getDate() - 1)
        setDateRange({
          startDate: formatChangeDate(todayStartDate),
          endDate: formatChangeDate(currentDate)
        })
        break;
      case 'yesterday':
        const yesterdayStartDate = new Date(currentDate)
        yesterdayStartDate.setDate(yesterdayStartDate.getDate() - 2)
        const yesterdayEndDate = new Date(currentDate)
        yesterdayEndDate.setDate(yesterdayEndDate.getDate() - 1)
        setDateRange({
          startDate: formatChangeDate(yesterdayStartDate),
          endDate: formatChangeDate(yesterdayEndDate),
        })
        break;
      case 'thisWeek':
        const currentDayOfWeek = currentDate.getDay();
        const startDate = new Date(currentDate);
        startDate.setDate(currentDate.getDate() - currentDayOfWeek);
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6 + 1);
        setDateRange({
          startDate: formatChangeDate(startDate),
          endDate: formatChangeDate(endDate),
        })
        break;
      case 'lastMonth':
        const lastMonth = new Date(currentDate);
        lastMonth.setMonth(lastMonth.getMonth() - 1); // Subtract 1 month
        const lastMonthStartDate = new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 1);
        lastMonthStartDate.setDate(lastMonthStartDate.getDate() - 1)
        const lastMonthEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
        setDateRange({
          startDate: formatChangeDate(lastMonthStartDate),
          endDate: formatChangeDate(lastMonthEndDate)
        })
        break;
      case 'thisMonth':
        const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        startOfMonth.setDate(startOfMonth.getDate() - 1)
        const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        setDateRange({
          startDate: formatChangeDate(startOfMonth),
          endDate: formatChangeDate(endOfMonth),
        })
        break;
      default:
        break;
    }
  }

  const groupBySearchFunction = () => {
    if (groupBySearch == 'campaignName') {
      const result = products.filter((product) => {
        // const objects = product.adGroups.map((ad: any) => {
        //   return ad.name
        // })
        const isCampMatch = campaignSearch == "" ? true : product.campaignName === campaignSearch
        const isAppMatch = appSearch == "" ? true : product.applicationName === appSearch
        // const isAdMatch = adSearch == "" ? true : objects.includes(adSearch)
        const isstatus = statusSearch == "" ? true : product.status === statusSearch
        return isCampMatch && isAppMatch && isstatus
      })
      setFilteredProducts(result)
    } else if (groupBySearch == 'applicationName') {
      const result = applicationProducts.filter((product) => {
        // const objects = product.adGroups.map((ad: any) => {
        //   return ad.name
        // })
        const isCampMatch = campaignSearch == "" ? true : product.campaignName === campaignSearch
        const isAppMatch = appSearch == "" ? true : product.applicationName === appSearch
        // const isAdMatch = adSearch == "" ? true : objects.includes(adSearch)
        const isstatus = statusSearch == "" ? true : product.status === statusSearch
        return isCampMatch && isAppMatch && isstatus
      })
      setFilteredProducts(result)
    }
  }

  useEffect(() => {
    if (!isExecuting) {
      groupBySearchFunction()
    }
  }, [isExecuting, groupBySearch, dateFilter, dateRange, appSearch, campaignSearch, statusSearch])

  const [count, setCount] = useState<number>(1);
  const [addFilterEnable, setAddFilterEnable] = useState(true)

  const onAddFilters = () => {
    if (count != 2) {
      setCount(count + 1)
    } else {
      setAddFilterEnable(false)
    }
  };

  const onSelectGroupByApplication = () => {
    const sortByAppNameArray: any = [];

    products.forEach((obj) => {
      const key = obj.applicationName;
      const campaignName = obj.campaignName;
      const status = obj.status;
      // const adGroups = obj.adGroups
      const cpi = obj.cpi;
      const ctr = obj.ctr;

      const clicks = obj.clicks;
      // const ctr = record.ctr;
      const cpc = obj.cpc;
      // const ipm = obj.ipm;
      const installs = obj.installs;
      // const cpi = record.cpi;
      const impressions = obj.impressions;
      const date = obj.date;

      const existingObj = sortByAppNameArray.find((item: any) => item.applicationName === key);

      if (existingObj) {
        existingObj.impressions += impressions;
        existingObj.clicks += clicks;
        existingObj.cpc += cpc;
        existingObj.ctr += ctr;
        // existingObj.ipm += ipm;
        existingObj.cpi += cpi;
        existingObj.installs += installs;

      } else {
        sortByAppNameArray.push({
          applicationName: key,
          campaignName: campaignName,
          // adGroups: adGroups,
          impressions: impressions,
          clicks: clicks,
          cpc: cpc,
          ctr: ctr,
          // ipm: ipm,
          installs: installs,
          cpi: cpi,
          status: status,
          date: date
        });
      }
    });
    setAppProducts(sortByAppNameArray)
    if (groupBySearch == "applicationName") {
      setFilteredProducts(sortByAppNameArray)
    }
  }

  const handleChangeGroupBy = (e: any) => {
    let value = e.target.value;
    switch (e.target.value) {
      case 'applicationName':
        setGroupBySearch(value)
        setColumns([
          {
            name: 'Application',
            selector: (row: any) => row.applicationName,
            width: '20%',
          },
          {
            name: 'Date',
            selector: (row: any) => row.date,
            width: '25%'
          },
          {
            name: 'Impressions',
            selector: (row: any) => row.impressions,
            width: '15%'
          },
          {
            name: 'Clicks',
            selector: (row: any) => row.clicks,
            width: '15%'
          },
          {
            name: 'CTR',
            selector: (row: any) => row.ctr,
            width: '10%'
          },
          {
            name: 'CPC',
            selector: (row: any) => currency + " " + (row.cpc).toFixed(2),
            width: '10%'
          },
          {
            name: 'CPI',
            selector: (row: any) => currency + " " + (row.cpi).toFixed(2),
            width: '10%'
          },
          {
            name: 'Installs',
            selector: (row: any) => row.installs,
            width: '10%'
          },
          // {
          //   name: 'IPM',
          //   selector: (row: any) => row.ipm,
          //   width: '10%'
          // },

        ])
        onSelectGroupByApplication()
        break;
      case "campaignName":
        setGroupBySearch(value)
        setColumns([
          {
            name: 'Campaign',
            selector: (row: any) => row.campaignName,
            width: '20%',
          },
          {
            name: 'Date',
            selector: (row: any) => row.date,
            width: '25%'
          },
          {
            name: 'Impressions',
            selector: (row: any) => row.impressions,
            width: '15%'
          },
          {
            name: 'Clicks',
            selector: (row: any) => row.clicks,
            width: '15%'
          },
          {
            name: 'CTR',
            selector: (row: any) => row.ctr,
            width: '10%'
          },
          {
            name: 'CPC',
            selector: (row: any) => currency + " " + (row.cpc).toFixed(2),
            width: '10%'
          },
          {
            name: 'CPI',
            selector: (row: any) => currency + " " + (row.cpi).toFixed(2),
            width: '10%'
          },
          {
            name: 'Installs',
            selector: (row: any) => row.installs,
            width: '10%'
          },
          // {
          //   name: 'IPM',
          //   selector: (row: any) => row.ipm,
          //   width: '10%'
          // },
        ])
        setFilteredProducts(products)
        break;
      default:
        break;
    }
  }

  const funnelFilter = [
    {
      name: "Daily",
      value: "daily"
    },
    {
      name: "Weekly",
      value: "weekly"
    },
    {
      name: "Monthly",
      value: "monthly"
    }
  ]

  const [dateRangeError, setDateRangeError] = useState<String>("")
  const [anchorE2, setAnchorE2] = React.useState<null | HTMLElement>(null);
  const openE2 = Boolean(anchorE2);
  const handleClickE2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorE2(event.currentTarget);
  };
  const handleCloseE2 = () => {
    setAnchorE2(null);
  };

  const [filters, setFilters] = useState<any>({
    campaign: [],
    status: '',
    application: [],
    groupBy: "campaignName"
  });

  const handleFilterChange = (filterType: any, value: any) => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      [filterType]: value,
    }));
  };

  const columns = [
    filters.groupBy === 'campaignName' ? {
      id: "campaign",
      name: 'Campaign',
      selector: (row: any) => row.campaignName,
      width: "200px",
      wrap: true,
      style: {
        borderRight: "1.4px solid #cccccc",
        fontWeight: 750
      }
    } : {
      id: "application",
      name: 'Application',
      selector: (row: any) => row.applicationName,
      width: "200px",
      wrap: true,
      style: {
        borderRight: "1.4px solid #cccccc",
        fontWeight: 750
      }
    },
    filters.groupBy === 'campaignName' ? {
      id: "application",
      name: 'Application',
      selector: (row: any) => row.applicationName,
      width: "200px",
      wrap: true,
      // style: {
      //   borderRight: "1.4px solid #cccccc",
      //   fontWeight: 750
      // }
    } : {},
    {
      id: "date",
      name: 'Date',
      selector: (row: any) => row.date,
      sortable: true,
      width: "220px"
    },
    {
      id: "impressions",
      name: 'Impressions',
      selector: (row: any) => row.impressions,
      sortable: true,
      width: "150px"
    },
    {
      id: "clicks",
      name: 'Clicks',
      selector: (row: any) => row.clicks,
      sortable: true,
      width: "140px"
    },
    {
      id: "ctr",
      name: 'CTR',
      selector: (row: any) => row.ctr,
      sortable: true,
      width: "100px"
    },
    {
      id: "cpc",
      name: 'CPC',
      selector: (row: any) => Number(row.cpc),
      format: (row: any) => currencySymbol + " " + Number(row.cpc).toFixed(2), // Format display with currency symbol
      width: "100px",
      sortable: true
    },
    {
      id: "cpi",
      name: 'CPI',
      selector: (row: any) => Number(row.cpi),
      format: (row: any) => currencySymbol + " " + Number(row.cpi).toFixed(2), // Format display with currency symbol
      width: "100px",
      sortable: true
    },
    {
      id: "installs",
      name: 'Installs',
      selector: (row: any) => row.installs,
      sortable: true,
      width: "120px"
    },
    // {
    //   name: 'IPM',
    //   selector: (row: any) => row.ipm,
    //   width: '10%'
    // },
  ]

  const filteredData = products.filter(item => {
    return (
      // (filters.campaign === '' || item.campaignName === filters.campaign) &&
      // (filters.status === '' || item.status === filters.status) &&
      // (filters.application === '' || item.applicationName === filters.application)
      (filters.campaign.length == 0 || filters.campaign.includes(item.campaignName)) &&
      (filters.application.length == 0 || filters.application.includes(item.applicationName)) &&
      (filters.status === "" || filters.status.includes(item.status))
    );
  });

  const CustomIconComponent: FC<{ loading: boolean }> = ({ loading }) => (
    <div style={{ position: 'relative', display: "flex", alignItems: "center", marginRight: "8px", cursor: "pointer" }}>
      {loading ? (
        <CircularProgress style={{ color: customCheckBoxColor }} size="1rem" />
      ) : (
        <ExpandMoreIcon sx={{
          color: "#000000",
          opacity: 0.55
        }}/>
      )}
    </div>
  );

  return (
    <div>
      <div className='fixed-position' style={{ top: '110px', height: '115px' }}>

        <div className='white-container ' style={{ display: "flex", alignItems: "end", justifyContent: "space-between", clear: "both" }}>
          <div className='select-div-size'>
            <div className='sub-heading'>Select Application</div>
            <div>
              {/* <CustomSelect
              value={appSearch}
              IconComponent={ExpandMoreIcon}
              onChange={(e: any) => setAppSearch(e.target.value)}
            >
              {appArray.map((item: any) => (
                <MenuItem className='menuItemStyle' key={item} value={item}>{item}</MenuItem>
              ))}
            </CustomSelect> */}
              <CustomSelect
                multiple
                value={filters.application}
                IconComponent={(props : any) => <CustomIconComponent loading={appLoader === true && appArray.length === 0} {...props} />}
                onChange={(e: any) => handleFilterChange('application', e.target.value)}
                renderValue={(selected: any) => selected.join(', ')}
              >
                {appLoader == false ? (appArray.length != 0 ? appArray.map((item: any) => (
                  // <MenuItem className='menuItemStyle' key={item} value={item}>{item}</MenuItem>
                  <MenuItem className='menuItemStyle' key={item} value={item}>
                    <Checkbox checked={filters.application.indexOf(item) > -1} style={{ color: customCheckBoxColor }} />
                    {/* <ListItemText primary={item.advertiserName} secondary={item.id} /> */}
                    {item}
                  </MenuItem>
                )) : <MenuItem className='menuItemStyle'>No Application found</MenuItem>) : <div style={{
                  display: "flex",
                  justifyContent: "center"
                }}><CircularProgress style={{ color: customCheckBoxColor }} size="1.5rem" /></div>}
              </CustomSelect>
            </div>
          </div>
          <div className='select-div-size'>
            <div className='sub-heading'>Select Campaign</div>
            <div>
              {/* <CustomSelect
              value={campaignSearch}
              IconComponent={ExpandMoreIcon}
              onChange={(e: any) => setCampaignSearch(e.target.value)}
            >
              {campArray.map((item: any) => (
                <MenuItem className='menuItemStyle' key={item} value={item}>{item}</MenuItem>
              ))}
            </CustomSelect> */}
              <CustomSelect
                multiple
                value={filters.campaign}
                IconComponent={(props : any) => <CustomIconComponent loading={campaignLoader === true && campArray.length === 0} {...props} />}
                onChange={(e: any) => handleFilterChange('campaign', e.target.value)}
                renderValue={(selected: any) => selected.join(', ')}
              >
                {campaignLoader == false ? (campArray.length != 0 ? campArray.map((item: any) => (
                  // <MenuItem className='menuItemStyle' key={item} value={item}>{item}</MenuItem>
                  <MenuItem className='menuItemStyle' key={item} value={item}>
                    <Checkbox checked={filters.campaign.indexOf(item) > -1} style={{ color: customCheckBoxColor }} />
                    {/* <ListItemText primary={item} /> */}
                    {item}
                  </MenuItem>
                )) : <MenuItem className='menuItemStyle'>No Application found</MenuItem>) : <div style={{
                  display: "flex",
                  justifyContent: "center"
                }}><CircularProgress style={{ color: customCheckBoxColor }} size="1.5rem" /></div>}
              </CustomSelect>
            </div>
          </div>
          {/* <div className='select-div-size'>
          <div className='sub-heading'>Select AD</div>
          <div>
            <CustomSelect
              value={adSearch}
              IconComponent={ExpandMoreIcon}
              onChange={(e: any) => setAdSearch(e.target.value)}
            >
              {adArray.map((item: any) => (
                <MenuItem className='menuItemStyle' key={item} value={item}>{item}</MenuItem>
              ))}
            </CustomSelect>
          </div>
        </div> */}

          <div className='select-div-size'>
            <div className='date-div'>
              <div ref={boxRef} className="box">
                <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
                  <h5 style={{ fontWeight: 800, fontSize: "12px", }}>
                    {dateFilter == "" ? "Custom" : dateFilter}
                  </h5>
                  <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClickE2}
                    sx={{
                      color: "#6900B8",
                    }}
                  >
                    <FilterAltIcon sx={
                      {
                        cursor: "pointer"
                      }
                    } />
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorE2}
                    open={openE2}
                    onClose={handleCloseE2}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    {
                      funnelFilter.map((option: any) => (
                        <MenuItem key={option} onClick={() => {
                          setDateFilter(option.value)
                          setAnchorE2(null)
                        }} >{option.name}</MenuItem>
                      ))
                    }
                  </Menu>
                  <div className="calender-btn" onClick={toggleCalender}>
                    <img src={calender} />
                    <p>{dateRange.startDate == null ? "Start Date" : dateRange.startDate.toLocaleDateString()}</p> -
                    <p>{dateRange.endDate == null ? "End Date" : dateRange.endDate.toLocaleDateString()}</p>
                  </div>
                </div>
                <div style={{ display: `${toggleCal}`, position: "absolute", right: "15%" }} >
                  <div>
                    <DateRangePicker ranges={[dateRange]} moveRangeOnFirstSelection={false} onChange={handleSelect} maxDate={new Date()} />
                  </div>
                  <div className="button-container">
                    <button
                      className="purple-btn width-adjust"
                      onClick={() => {
                        onClickGetReport();
                        toggleCalender();
                      }}
                      style={{
                        margin: '0 15px',
                        cursor: "pointer"
                      }}
                    >
                      Done
                    </button>
                    <button
                      className="border-btn"
                      onClick={toggleCalender}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
              {dateRangeError != "" ?
                <div className="error-box" style={{
                  float: "right",
                  marginBottom: "10px"
                }}>
                  <p>{dateRangeError}</p>
                </div>
                : <div></div>}
            </div>
          </div>
        </div>
      </div>

      <div className='white-container div-display' style={{ marginTop: '126px' }}>
        <div style={{ width: "65%" }}>
          {[...Array(count)].map((_, i) => <div key='filter-div' className='filter-div'>
            <div>
              <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                <div className='sub-heading'>Filter {i + 1}</div>
                {/* <div onClick={() => {
                if (count == 1) {
                  setCount(count)
                } else {
                  setCount(count - 1)
                }
              }}><RemoveCircleOutlinedIcon sx={{
                color: 'red',
                height: '16px'
              }} /></div> */}
              </div>
              <div>
                {/* <CustomSelect
                  value={i == 0 ? statusSearch : timeSearch}
                  IconComponent={ExpandMoreIcon}
                  onChange={(e: any) =>
                    i == 0 ?
                      setStatusSearch(e.target.value) :
                      handleTimeSelect(e)
                  }
                  sx={{
                    width: '200px'
                  }}
                >
                  {
                    i == 0 ? <ListSubheader sx={listHeaderStyle}>Status</ListSubheader> : <ListSubheader sx={listHeaderStyle}>Time</ListSubheader>
                  }
                  {
                    i == 0 ?
                      status.map((item: any) => (
                        <MenuItem className='menuItemStyle' key={item.value} value={item.value}>{item.label}</MenuItem>
                      )) :
                      timeArray.map((item: any) => (
                        <MenuItem className='menuItemStyle' key={item.value} value={item.value}>{item.label}</MenuItem>
                      ))
                  }
                </CustomSelect> */}
                <CustomSelect
                  value={i == 0 ? filters.status : timeSearch}
                  IconComponent={ExpandMoreIcon}
                  onChange={(e: any) =>
                    i == 0 ?
                      handleFilterChange('status', e.target.value) :
                      handleTimeSelect(e)
                  }
                  sx={{
                    width: '200px !important'
                  }}
                >
                  {
                    i == 0 ? <ListSubheader sx={listHeaderStyle}>Status</ListSubheader> : <ListSubheader sx={listHeaderStyle}>Time</ListSubheader>
                  }
                  {
                    i == 0 ?
                      status.map((item: any) => (
                        <MenuItem className='menuItemStyle' key={item.value} value={item.value}>{item.label}</MenuItem>
                      )) :
                      timeArray.map((item: any) => (
                        <MenuItem className='menuItemStyle' key={item.value} value={item.value}>{item.label}</MenuItem>
                      ))
                  }
                </CustomSelect>
                {/* <Cascader options={options} onChange={onChange} style={cascaderStyle} /> */}
              </div>
            </div>
          </div>)}
          {/* {
          count == 2 ? <div></div> : <PurpleCustomButton
            children={<div style={{ display: "flex", alignItems: "center" }}><div style={{ fontSize: "16px", marginRight: "6px" }}>+</div>Add Filters</div>}
            onClick={onAddFilters}
            disable={addFilterEnable}
          />
        } */}
        </div>
        <div style={{ width: "25%" }}>
          <div className='sub-heading'>Group by</div>
          <div style={{ display: 'flex', }}>
            {/* <CustomSelect
              name={groupBySearch}
              value={groupBySearch}
              IconComponent={ExpandMoreIcon}
              onChange={handleChangeGroupBy}
              sx={{ width: '240px' }}
            >
              {groupByMenu.map((make, index) => (
                <MenuItem className='menuItemStyle' key={index} value={make.name}>{make.label}</MenuItem>
              ))}
            </CustomSelect> */}

            <CustomSelect
              // name={groupBySearch}
              value={filters.groupBy}
              IconComponent={ExpandMoreIcon}
              onChange={(e: any) => handleFilterChange("groupBy", e.target.value)}
              sx={{ width: '240px' }}
            >
              {groupByMenu.map((make, index) => (
                <MenuItem className='menuItemStyle' key={index} value={make.name}>{make.label}</MenuItem>
              ))}
            </CustomSelect>
            {/* <BlackButton autoCapitalize='false'>
            <DescriptionIcon />Create Report
          </BlackButton> */}
          </div>
        </div>
      </div>
      <div className='white-container' >
        {loaderActive ? <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "200px"
        }}>
          <CustomLoader />
          {/* <CircularProgress size="1.5rem" sx={{ color: "#6800b8" }} /> */}
        </div> : <ProductTable
          data={filteredData}
          pagination
          // paginationIconFirstPage={false}
          // paginationIconLastPage={false}
          // paginationRowsPerPageOptions={[5, 10, 25]}
          paginationPerPage={5}
          paginationComponentOptions={{
            noRowsPerPage: true,
            rowsPerPageText: 'Rows per page:', // Customize the text for rows per page
          }}
          fixedHeader
          defaultSortFieldId="installs"
          columns={columns}
          conditionalRowStyles={[ // Apply the class to remove bottom border to every row
            {
              when: () => true,
              style: {
                borderBottom: 'none',
              },
            },
          ]}
          customStyles={{
            headRow: {
              style: {
                borderBottom: "none"
              }
            },
            headCells: {
              style: {
                color: "#6900B8",
                fontSize: "16px",
                fontWeight: 800,
              },
            },
            cells: {
              style: {
                color: "#333333",
                fontSize: "14px",
                fontWeight: 550,
              },
            },
          }}
        />}
      </div>
      <div style={{
        display: "flex",
        float: "right",
      }}>
        <div>
          <DownloadButton
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <FileDownloadOutlinedIcon sx={{ marginRight: "4px" }} /> Download Report
          </DownloadButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem>{filteredData.length == 0 ? (
              <li
                onClick={() => {
                  alert("No data available to download.");
                }}
                style={{
                  fontSize: "12px",
                  fontWeight: "600",
                  color: "blue",
                  textDecorationLine: 'underline'
                }}
              >
                Document (.csv) file
              </li>
            ) : (<CSVLink data={filteredData} filename='Report CSV' style={{
              fontSize: "12px",
              fontWeight: "600",
              color: "blue",
            }}>Document (.csv) file</CSVLink>)}</MenuItem>
            <MenuItem>{filteredData.length == 0 ? (
              <li
                onClick={() => {
                  alert("No data available to download.");
                }}
                style={{
                  fontSize: "12px",
                  fontWeight: "600",
                  color: "blue",
                  textDecorationLine: 'underline'
                }}
              >
                Excel (.xlsx) file
              </li>
            ) : (<a onClick={downloadExcel} style={{
              fontSize: "12px",
              fontWeight: "600",
              color: "blue",
              textDecorationLine: 'underline'
            }}>Excel (.xlsx) file</a>)}</MenuItem>
          </Menu>
        </div>
        <RefreshBtn onClick={() => window.location.reload()}><RefreshIcon />Refresh</RefreshBtn>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={openSession}
        onClose={handleSessionClose}
        message="Session Expired!"
      />
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  currencySymbol: state.user.currencySymbol,
  authToken: state.user.authToken,
  sessionId: state.user.sessionId,
  advertiserId: state.user.advertiserId
});

const mapDispatchToProps = {
  setCurrencySymbol,
  setAdvertiserId,
  setAuthToken,
  setSessionId
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportScreen);