import React from "react";
import { Navbaritem } from "../../../models/Navbaritem";
import { ReactComponent as DashboardIcon} from '../../IMAGE/dashboardIcon.svg';
import { ReactComponent as AdvertisersIcon} from '../../IMAGE/advertisersIcon.svg';
import { ReactComponent as BillingIcon} from '../../IMAGE/billingIcon.svg';
import { ReactComponent as ReportIcon } from '../../IMAGE/reportIcon.svg';
import { ReactComponent as CampaignIcon} from '../../IMAGE/campaignIcon.svg';
import { ReactComponent as SettingsIcon} from '../../IMAGE/settings.svg';
import { ReactComponent as ActiveDashboardIcon} from '../../IMAGE/activeDashboard.svg';
import { ReactComponent as ActiveAdvertisersIcon} from '../../IMAGE/activeAdvertisers.svg';
import { ReactComponent as ActiveBillingIcon} from '../../IMAGE/activeBilling.svg';
import { ReactComponent as ActiveReportIcon } from '../../IMAGE/activeReport.svg';
import { ReactComponent as ActiveCampaignIcon} from '../../IMAGE/activeCampaign.svg';
import { ReactComponent as ActiveSettingsIcon} from '../../IMAGE/activeSetting.svg';
import { ReactComponent as ActiveCouponIcon} from '../../IMAGE/activeCoupon.svg';
import { ReactComponent as CouponIcon} from '../../IMAGE/coupon.svg';
import ActiveHelpCenterIcon from '../../IMAGE/activeHelpCenter.png';
import ActiveLogoutIcon from "../../IMAGE/activeLogout.png";
import { ReactComponent as LogoutIcon} from '../../IMAGE/logout.svg';
import { ReactComponent as HelpCentreIcon} from '../../IMAGE/help.svg';

export const NavData: Navbaritem[] = [
    {
        title: 'Dashboard',
        icon: <DashboardIcon />,
        activeIcon: <ActiveDashboardIcon />,
        path: '/home',
    },
    {
        title: 'Campaigns',
        icon: <CampaignIcon />,
        activeIcon: <ActiveCampaignIcon />,
        path: '/home/campaigns',
        color: '#FF0047'
    },
    {
        title: 'Report',
        icon: <ReportIcon />,
        activeIcon: <ActiveReportIcon />,
        path: '/home/report',
    },
    {
        title: 'Billing',
        icon: <BillingIcon />,
        activeIcon: <ActiveBillingIcon />,
        path: '/home/billing',
    },
]

export const NavbarBottomData: Navbaritem[] = [
    {
        title: 'Settings',
        icon: <SettingsIcon />,
        activeIcon: <ActiveSettingsIcon />,
        path: '/home/settings',
    },
    {
        title: 'Help Centre',
        icon: <HelpCentreIcon />,
        activeIcon: <img src={ActiveHelpCenterIcon} height="18px" width="18px" />,
        path: '/home/help',
    },
    {
        title: 'Logout',
        icon: <LogoutIcon />,
        activeIcon: <img src={ActiveLogoutIcon} height="18px" width="18px" />,
        path: '/?logout=true',
    },
]

export const AdminNavData: Navbaritem[] = [
    {
        title: 'Dashboard',
        icon: <DashboardIcon />,
        activeIcon: <ActiveDashboardIcon />,
        path: '/home',
    },
    {
        title: 'Advertisers',
        icon: <AdvertisersIcon />,
        activeIcon: <ActiveAdvertisersIcon />,
        path: '/home/admin/advertisers',
    },
    {
        title: 'Campaigns',
        icon: <CampaignIcon />,
        activeIcon: <ActiveCampaignIcon />,
        path: '/home/admin/campaigns',
        color: '#FF0047'
    },
    {
        title: 'Report',
        icon: <ReportIcon />,
        activeIcon: <ActiveReportIcon />,
        path: '/home/admin/report',
    },
    {
        title: 'Billing',
        icon: <BillingIcon />,
        activeIcon: <ActiveBillingIcon />,
        path: '/home/admin/billing',
    },
    {
        title: 'Coupons',
        icon: <CouponIcon/>,
        activeIcon: <ActiveCouponIcon />,
        path: '/home/admin/coupons',
    },
    {
        title: 'User Journey',
        icon: <AdvertisersIcon />,
        activeIcon: <ActiveAdvertisersIcon />,
        path: '/home/admin/journey',
    },
]

export const AdminNavbarBottomData: Navbaritem[] = [
    {
        title: 'Settings',
        icon: <SettingsIcon />,
        activeIcon: <ActiveSettingsIcon />,
        path: '/home/settings',
    },
    {
        title: 'Logout',
        icon: <LogoutIcon />,
        activeIcon: <img src={ActiveLogoutIcon} height="18px" width="18px" />,
        path: '/?logout=true',
    },
]