import React, { FC } from "react";
import "../../CSS/CustomLoader.css";
import rotatinglogo from "../../IMAGE/rotatinglogo.svg";

const CustomLoader: FC = () => {
  return (
    <div>
      {/* <div className="loader-container">
        <img src={rotatinglogo} alt="Loader" className="rotating-image" />
      </div> */}
      <div className="progress">
        <div className="color"></div>
      </div>
    </div>
  );
};

export default CustomLoader;
