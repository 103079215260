import React, { FC, useEffect, useRef, useState } from 'react';
import '../../CSS/Billing.css';
import { IoWallet, IoInformationCircleSharp } from 'react-icons/io5'
import axios from "axios";
import ProductTable from 'react-data-table-component'
import InfoIcon from '@mui/icons-material/Info';
import { Box, Button, Menu, MenuItem, Modal, Snackbar, Tooltip, Typography } from '@mui/material';
import { TooltipProps, styled, tooltipClasses } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { DownloadButton, apiURL, secretKey } from '../../Constant';
import DateCustomPicker from '../custom/DateCustomPicker';
import { RootState } from '../../REDUX/Reducers/rootReducer';
import { setAdvertiserId, setAuthToken, setCurrencySymbol, setSelectedAdvertiserId, setSelectedDateRange, setSessionId } from '../../REDUX/Actions/userActionIndex';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import * as XLSX from "xlsx";
import RefreshIcon from '@mui/icons-material/Refresh';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import saveAs from 'file-saver';
import CustomLoader from '../custom/CustomLoader';

const CryptoJS = require('crypto-js')

interface BillingScreenProps {
    shouldHideDiv: boolean;
    heading?: string;
    subHeading: string;
    dateChange?: boolean;
    dates?: {
        startDate: string;
        endDate: string;
    };
    currencySymbol: any;
    authToken: any;
    sessionId: any;
    advertiserId: any;
    isadmin?: boolean;
    selectedAdvertiserId: any;
    selectedDateRange: any;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        fontSize: 12,
        color: "#6900B8",
    },
}));

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: "500px",
    minHeight: "200px",
    bgcolor: 'background.paper',
    border: '1px solid #6800b8',
    outline: 'none',
    borderRadius: "3px",
    boxShadow: 24,
    padding: "10px 30px",
    paddingBottom: "20px"
};

const RefreshBtn = styled('button')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    backgroundColor: 'rgba(153, 153, 153, 0.2)',
    borderRadius: '2px',
    color: '#6900B8',
    padding: '5px 10px',
    width: "auto",
    height: "40px",
    border: "none",
    marginRight: "10px"
}));

const BillingScreen: FC<BillingScreenProps> = ({ dateChange, shouldHideDiv, heading, subHeading, dates, currencySymbol, advertiserId, authToken, isadmin, sessionId, selectedAdvertiserId, selectedDateRange }) => {

    const [advertiserID, setAdvertiserID] = useState<string>(advertiserId)
    const [sessionID, setSessionID] = useState<any>(sessionId)
    const [authTokenID, setAuthTokenID] = useState<any>(authToken)

    const [selectedRow, setSelectedRow] = useState<any>("");
    const navigate = useNavigate()

    const [openSession, setOpenSession] = React.useState(false);

    const handleSessionClose = () => {
        setOpenSession(false);
    };

    const [toggleCal, setToggleCal] = useState<string>("none");
    const boxRef = useRef<HTMLDivElement | null>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (boxRef.current && !boxRef.current.contains(event.target as Node)) {
            setToggleCal("none");
        }
    };

    const [open, setOpen] = React.useState(false);
    const [openAllInvoice, setOpenAllInvoice] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const capitalizeFirstLetter = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const divStyle = {
        display: shouldHideDiv ? 'none' : 'block',
    };

    const formatChangeDate = (dateParam: any) => {
        const inputDateString = dateParam;
        const date = new Date(inputDateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate
    }

    const [transactions, setTransactions] = useState<any[]>([])
    const [filterData, setFilterData] = useState<any[]>([])
    const [bal, setBal] = useState<any>()
    const [lastBilledAmt, setLastBilledAmt] = useState<any>()
    const [creditLimit, setCreditLimit] = useState<any>()
    const [invoiceArr, setInvoiceArr] = useState<any[]>([])
    const [loading, setLoading] = React.useState(false);

    const cols: any = [
        {
            id: 'TransactionDate',
            name: 'Date',
            selector: (row: any) => formatChangeDate(row.TransactionDate),
            width: "12%",
            sortable: true
        },
        {
            id: 'Discription',
            name: 'Operations',
            width: "27%",
            selector: (row: any) => (row.Discription == null || row.Discription == "" || row.Discription == "Description") ?
                // <p>Expenditure for the period from <br />{formatChangeDate(dateRange.startDate == null ? formatChangeDate(row.createdAt) : dateRange.startDate)} to {formatChangeDate(dateRange.endDate == null ? formatChangeDate(row.createdAt) : dateRange.endDate)}</p>
                "Credited to wallet via Stripe"
                : row.Discription,
            wrap: true
        },
        {
            id: 'TransactionType',
            name: 'Operations Type',
            width: "15%",
            selector: (row: any) => capitalizeFirstLetter(row.TransactionType),
        },
        {
            id: 'TransactionStatus',
            name: 'Status',
            width: "12%",
            selector: (row: any) => capitalizeFirstLetter(row.TransactionStatus),
        },
        {
            id: 'Amount',
            name: 'Cost',
            width: "12%",
            selector: (row: any) => currencySymbol + " " + row.Amount,
        },
        {
            id: 'BalanceAmount',
            name: 'Balance',
            width: "12%",
            selector: (row: any) => currencySymbol + " " + row.BalanceAmount,
        },
        {
            id: 'InvoiceID',
            name: 'Notes',
            width: "10%",
            selector: (row: any) => <div style={{ cursor: "pointer", color: "#690047", textDecorationLine: "underline", fontWeight: 700 }} onClick={() => onClickInvoice(row)}>{row.InvoiceID == null ? "View" : "Receipt"}</div>
        },
    ]

    const InvoiceCols = [
        {
            id: 'id',
            name: 'Invoice Id',
            width: "40%",
            selector: (row: any) => row.id
        },
        {
            id: 'LastBilledAmount',
            name: 'Amount',
            width: "20%",
            selector: (row: any) => currencySymbol + ' ' + row.LastBilledAmount
        },
        {
            id: 'Month',
            name: 'Month',
            width: "20%",
            selector: (row: any) => row.Month
        },
        {
            id: 'InvoiceUrl',
            name: 'Invoice',
            width: "20%",
            selector: (row: any) => <div style={{ cursor: "pointer", color: "#6900B8", textDecorationLine: "underline" }}><a href={row.InvoiceUrl} target='_blank'>Invoice</a></div>
        },

    ]

    const onClickInvoice = async (invId: any) => {
        setSelectedRow(invId)
        if (invId.InvoiceID != null && invId.InvoiceID != "") {
            const encryptAdvertiserID = CryptoJS.AES.encrypt(JSON.stringify(advertiserId), secretKey).toString()

            let headers = {
                'Content-Type': 'application/json',
                'advertiserid': encryptAdvertiserID,
                'authtoken': authTokenID,
                'sessionid': sessionID
            };
            await axios.get(`${apiURL}/api/billing/getInvoice?invoiceId=${invId.InvoiceID}`, { headers: headers })
                .then(response => {
                    if (response.data.status == true) {
                        const resData = response.data.data;
                        const bytes = CryptoJS.AES.decrypt(resData, secretKey);
                        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
                        const parsedData = JSON.parse(decryptedData);
                        window.open(parsedData['receipt_url'])
                    } else if (response.data.statuscode == 401) {
                        setOpenSession(true)
                        setTimeout(() => { navigate("/") }, 2000)
                    }
                })
                .catch(e => console.log(e))
        } else {
            setOpen(true)
        }
    }

    const onGetTransactionDetails = () => {
        const encryptAdvertiserID = CryptoJS.AES.encrypt(JSON.stringify(advertiserId), secretKey).toString()
        let headers = {
            'Content-Type': 'application/json',
            'advertiserid': encryptAdvertiserID,
            'authtoken': authTokenID,
            'sessionid': sessionID
        };
        axios
            .get(`${apiURL}/api/billing/getAllTransactions?advertiserId=${advertiserID}`, { headers: headers })
            .then((response) => {
                if (response.data.status == true) {
                    const resData = response.data.data;
                    const bytes = CryptoJS.AES.decrypt(resData, secretKey);
                    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
                    const parsedData = JSON.parse(decryptedData);
                    setCreditLimit(parsedData['creditLimit'])
                    setBal(parsedData['balance'])
                    setLastBilledAmt(parsedData['lastBilledAmount'])
                    setTransactions(parsedData['transactionsData'])
                    // setFilterData(parsedData['transactionsData'])
                } else if (response.data.statuscode == 401) {
                    setOpenSession(true)
                    setTimeout(() => { navigate("/") }, 2000)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getAllInvoices = async () => {
        setInvoiceArr([])
            setLoading(true);
            const encryptAdvertiserID = CryptoJS.AES.encrypt(JSON.stringify(advertiserId), secretKey).toString()

            let headers = {
                'Content-Type': 'application/json',
                'advertiserid': encryptAdvertiserID,
                'authtoken': authTokenID,
                'sessionid': sessionID
            };
            await axios.get(`${apiURL}/api/billing/getAllInvoicesForAdvertiser?advertiserId=${advertiserID}`, { headers: headers })
                .then(response => {
                    if (response.data.status == true) {
                        const resData = response.data.data;
                        const bytes = CryptoJS.AES.decrypt(resData, secretKey);
                        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
                        const parsedData = JSON.parse(decryptedData);
                        if (parsedData && parsedData.length) {
                            setInvoiceArr(parsedData)
                        }
                        
                    } else if (response.data.statuscode == 401) {
                        setOpenSession(true)
                        setTimeout(() => { navigate("/") }, 2000)
                    }
                    setLoading(false);
                })
                .catch(e => {
                    setLoading(false);
                    console.log(e)
                })
    }

    useEffect(() => {
        onGetTransactionDetails()
    }, [advertiserID]);

    useEffect(() => {
        setAdvertiserID(selectedAdvertiserId)
    }, [selectedAdvertiserId]);

    const [selectedRange, setSelectedRange] = useState<any>({
        startDate: new Date(),
        endDate: new Date(),
    });

    const [downloadData, setDownloadData] = useState<any[]>([])

    useEffect(() => {
        const startDate = selectedDateRange.startDate;
        const endDate = selectedDateRange.endDate;

        if (startDate != null && endDate != null) {
            const s = new Date(startDate);
            const e = new Date(endDate);
            // setSelectedStartDate(s);
            // setSelectedEndDate(e);
            setSelectedRange({
                startDate: s,
                endDate: e
            })
            const startdate = formatChangeDate(s)
            const enddate = formatChangeDate(e)
            const result = transactions.filter((transaction) => {
                const createdat = formatChangeDate(transaction.createdAt)
                return createdat >= startdate && createdat <= enddate
            })
            setFilterData(result)
        } else {
            if (dates != null) {
                const s = new Date(dates.startDate);
                const e = new Date(dates.endDate);
                // setSelectedStartDate(s);
                // setSelectedEndDate(e);
                setSelectedRange({
                    startDate: s,
                    endDate: e
                })
                const startdate = formatChangeDate(s)
                const enddate = formatChangeDate(e)
                const result = transactions.filter((transaction) => {
                    const createdat = formatChangeDate(transaction.createdAt)
                    return createdat >= startdate && createdat <= enddate
                })
                setFilterData(result)
            }
        }
    }, [transactions, dates, selectedDateRange]);

    useEffect(() => {
        const csvData = filterData.map(row => {
            const rowData: any = {};
            cols.forEach((field: any) => {
                rowData[field.id] = row[field.id];
            });
            return rowData;
        });
        setDownloadData(csvData)
    }, [filterData, transactions]);

    // const [selectedStartDate, setSelectedStartDate] = useState<Date>(new Date());
    // const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openBtn = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseBtn = () => {
        setAnchorEl(null);
    };

    const onClickViewMore =() => {
        setOpenAllInvoice(true)
        getAllInvoices();
    }

    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(downloadData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(dataBlob, 'Transaction XLXS.xlsx'); // Specify the desired file name here
    };
    return (
        <div>
            <div className='acc-details' style={{ alignItems: "end", marginTop:'25px' }}>
                <div style={{ display: 'flex' }}>
                    <div className='acc-detail'>
                        <div style={divStyle}>
                            <div className='main-heading'><IoWallet style={{ color: "#6900B8", marginRight: 5 }} /><div className='heading'>Account balance</div></div>
                        </div>
                        <div className='acc-details-content'>
                            <div className='acc-subheading'>Total</div>
                            <div className='acc-subheading-content'>{currencySymbol} {bal}</div>
                        </div>
                    </div>
                    <div className='acc-detail' style={{ marginLeft: "40px" }}>
                        <div style={divStyle}>
                            <div className='main-heading'><IoInformationCircleSharp style={{ color: "#6900B8", marginRight: 5 }} /><div className='heading'>Additional Information</div></div>
                        </div>
                        <div style={{ display: "flex", }}>
                            <div className='acc-details-content' style={{ marginRight: "10px" , paddingRight:'10px'}}>
                                <div className='acc-subheading'>Last Billed Amount</div>
                                <div style={{display:'flex', justifyContent:'space-between', alignItems: 'flex-end', gap:'20px'}}>
                                <div className='acc-subheading-content'>{currencySymbol} {lastBilledAmt}</div>
                                <div style={{fontSize:'12px', textDecoration:'underline', marginBottom:'5px', cursor: 'pointer'}} onClick={onClickViewMore}>view more</div>
                                </div>
                            </div>
                            <div className='acc-details-content' >
                                <div className='acc-subheading'> Credit Limit <LightTooltip title="Credit limit will add after 90 days." placement='top-start'><InfoIcon style={{ height: 15, cursor: "pointer" }} /></LightTooltip></div>
                                <div className='acc-subheading-content'>{currencySymbol}  {creditLimit}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={divStyle}>
                    <DateCustomPicker onClick={() => {
                        const startdate = formatChangeDate(selectedRange.startDate)
                        const enddate = formatChangeDate(selectedRange.endDate)
                        const result = transactions.filter((transaction) => {
                            const createdat = formatChangeDate(transaction.createdAt)
                            return createdat >= startdate && createdat <= enddate
                        })
                        setFilterData(result)
                    }} />

                </div>
            </div>
            {shouldHideDiv ? <div className='sub-heading'>Recent Transactions</div> : <div></div>}
            <div className='white-container'>
                <ProductTable
                    defaultSortFieldId='date'
                    defaultSortAsc={shouldHideDiv ? false : true}
                    responsive={true}
                    highlightOnHover
                    data={filterData}
                    fixedHeader
                    pagination
                    // paginationIconFirstPage={false}
                    // paginationIconLastPage={false}
                    paginationPerPage={10}
                    paginationComponentOptions={{
                        noRowsPerPage: true,
                        rowsPerPageText: 'Rows per page:', // Customize the text for rows per page
                    }}
                    columns={cols}
                    customStyles={{
                        headCells: {
                            style: {
                                color: "#6900B8",
                                fontSize: "14px",
                                fontWeight: 750,
                            },
                        },
                        cells: {
                            style: {
                                color: "rgba(51, 51, 51, 0.8)",
                                fontSize: "13px",
                                fontWeight: 600,
                            },
                        },
                    }}
                />
            </div>
            {
                isadmin && !shouldHideDiv ? <div style={{
                    display: "flex",
                    float: "right",
                }}>
                    <RefreshBtn onClick={() => window.location.reload()}><RefreshIcon sx={{
                        color: "#333333"
                    }} /></RefreshBtn>
                    <div>
                        <DownloadButton
                            id="basic-button"
                            aria-controls={openBtn ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openBtn ? 'true' : undefined}
                            onClick={handleClick}
                        >
                            <FileDownloadOutlinedIcon sx={{ marginRight: "4px" }} /> Download Report
                        </DownloadButton>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openBtn}
                            onClose={handleCloseBtn}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem>{downloadData.length == 0 ? (
                                <li
                                    onClick={() => {
                                        alert("No data available to download.");
                                    }}
                                    style={{
                                        fontSize: "12px",
                                        fontWeight: "600",
                                        color: "blue",
                                        textDecorationLine: 'underline'
                                    }}
                                >
                                    Document (.csv) file
                                </li>
                            ) : (<CSVLink data={downloadData} filename='Transaction CSV' style={{
                                fontSize: "12px",
                                fontWeight: "600",
                                color: "blue",

                            }}>Document (.csv) file</CSVLink>)}</MenuItem>
                            <MenuItem>{downloadData.length == 0 ? (
                                <li
                                    onClick={() => {
                                        alert("No data available to download.");
                                    }}
                                    style={{
                                        fontSize: "12px",
                                        fontWeight: "600",
                                        color: "blue",
                                        textDecorationLine: 'underline'
                                    }}
                                >
                                    Excel (.xlsx) file
                                </li>
                            ) : (<a onClick={downloadExcel} style={{
                                fontSize: "12px",
                                fontWeight: "600",
                                color: "blue",
                                textDecorationLine: 'underline'
                            }}>Excel (.xlsx) file</a>)}</MenuItem>
                        </Menu>
                    </div>
                </div> : <div></div>
            }
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={openSession}
                onClose={handleSessionClose}
                message="Session Expired!"
            />
            <Modal
                open={open}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title"
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "10px"
                        }}
                    >
                        <div style={{
                            fontSize: "16px",
                            color: "#6800b8",
                            fontWeight: 600,
                        }}> Transaction Details</div>
                        <button className='close-btn' onClick={handleClose}>
                            <CloseIcon />Close
                        </button>
                    </Typography>
                    <Typography id="modal-modal-description" className='list-style'>
                        {selectedRow.length == 0 ? "" :
                            <ul style={{
                                padding: 0,
                                margin: 0
                            }}>
                                <li><div className='list-item-style'>Transaction ID : </div>&nbsp;{selectedRow["TransactionId"]}</li>
                                <li><div className='list-item-style'>Transaction Date : </div>&nbsp; {selectedRow["TransactionDate"]}</li>
                                <li><div className='list-item-style'>Transaction Type : </div>&nbsp;{capitalizeFirstLetter(selectedRow["TransactionType"])}</li>
                                <li><div className='list-item-style'>Amount : </div>&nbsp;{currencySymbol}{selectedRow["Amount"]}</li>
                                <li><div className='list-item-style'>Transaction Status : </div>&nbsp;{capitalizeFirstLetter(selectedRow["TransactionStatus"])}</li>
                                <li><div className='list-item-style'>Balance : </div>&nbsp;{currencySymbol}{selectedRow["BalanceAmount"]}</li>
                                <li><div className='list-item-style'>Description : </div>&nbsp;{selectedRow["Discription"]}</li>
                            </ul>
                        }
                    </Typography>
                </Box>
            </Modal>
            
            <Modal
                open={openAllInvoice}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title"
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "10px"
                        }}
                    >
                        <div style={{
                            fontSize: "16px",
                            color: "#6800b8",
                            fontWeight: 600,
                        }}> All Invoices</div>
                        <button className='close-btn' onClick={() => setOpenAllInvoice(false)}>
                            <CloseIcon />Close
                        </button>
                    </Typography>
                    <Typography id="modal-modal-description" className='list-style'>
                    {loading ? <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "200px"
                        }}>
                        <CustomLoader />
                        {/* <CircularProgress size="1.5rem" sx={{ color: "#6800b8" }} /> */}
                        </div> : <div>
                        <ProductTable
                    defaultSortFieldId='Month'
                    defaultSortAsc={true}
                    responsive={true}
                    highlightOnHover
                    data={invoiceArr}
                    fixedHeader
                    pagination
                    // paginationIconFirstPage={false}
                    // paginationIconLastPage={false}
                    paginationPerPage={5}
                    paginationComponentOptions={{
                        noRowsPerPage: true,
                        rowsPerPageText: 'Rows per page:', // Customize the text for rows per page
                    }}
                    columns={InvoiceCols}
                    customStyles={{
                        headCells: {
                            style: {
                                color: "#6900B8",
                                fontSize: "16px",
                                fontWeight: 800,
                            },
                        },
                        cells: {
                            style: {
                                color: "rgba(51, 51, 51, 0.6)",
                                fontSize: "13px",
                                fontWeight: 600,
                            },
                        },
                    }}
                />
                        </div> }
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    currencySymbol: state.user.currencySymbol,
    authToken: state.user.authToken,
    sessionId: state.user.sessionId,
    advertiserId: state.user.advertiserId,
    selectedAdvertiserId: state.user.selectedAdvertiserId,
    selectedDateRange: state.user.selectedDateRange
});

const mapDispatchToProps = {
    setCurrencySymbol,
    setAdvertiserId,
    setAuthToken,
    setSessionId,
    setSelectedAdvertiserId,
    setSelectedDateRange
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingScreen);