import { Billing, Campaigns, Dashboard, Report, Settings, CampRecord, AddFunds, EditCamp, CreateCamp, HelpCentre } from '../SideNav/NavScreens';
import PaymentSuccess from '../../TSX/addFunds/paymentSuccess';
import PaymentFail from '../../TSX/addFunds/paymentFail';
import { Advertisers, CreateAdvertiser, AdminSettings, SelectedAdvertiser, CampaignAdminScreen, EditCampaignAdminScreen, AdminCreateCampaign, AdminReport, AdminBillingScreen , Coupon, CreateCoupon, CouponHistory, AdminDashboard, AdminUserJourney} from '../SideNav/AdminNavScreens';
// import EditCampaignAdmin from '../pages/admin/Campaigns/EditCampaignAdmin';

export const routeList = [
    {
        path: '/',
        component: Dashboard
    },
    {
        path: '/billing',
        component: Billing
    },
    {
        path: '/campaigns',
        component: Campaigns,
    },
    {
        path: '/campaigns/campaignsRecord',
        component: CampRecord,
    },
    {
        path: '/campaigns/createCampaign',
        component: CreateCamp
    },
    {
        path: '/settings',
        component: Settings
    },
    {
        path: '/report',
        component: Report
    },
    {
        path: '/help',
        component: HelpCentre
    },
    {
        path: '/addFunds',
        component: AddFunds
    },
    {
        path: '/addFunds/Success',
        component: PaymentSuccess
    },
    {
        path: '/addFunds/Failed',
        component: PaymentFail
    },
    {
        path: "/campaigns/editCampaign",
        component: EditCamp,
    },
]

export const adminRouteList = [
    {
        path: '/',
        component: AdminDashboard
    },
    {
        path: '/admin/advertisers',
        component: Advertisers
    },
    {
        path: '/admin/advertisers/selectedAdvertiser',
        component: SelectedAdvertiser
    },
    {
        path: '/admin/report',
        component: AdminReport
    },
    {
        path: '/admin/billing',
        component: AdminBillingScreen
    },
    {
        path: '/admin/advertisers/createAdvertiser',
        component: CreateAdvertiser
    },

    {
        path: '/settings',
        component: AdminSettings

    },
    {
        path:'/admin/campaigns',
        component: CampaignAdminScreen
    },
    {
        path: '/admin/campaigns/editCampaignAdmin',
        component: EditCampaignAdminScreen
    },
    {
        path: '/admin/campaigns/adminCreateCampaign',
        component:AdminCreateCampaign,
    },
    {
        path: '/admin/coupons',
        component: Coupon
    },
    {
        path: '/admin/coupons/createNewCoupon',
        component: CreateCoupon
    },
    
    {
        path: '/admin/coupons/couponHistory',
        component: CouponHistory
    },
    {
        path:'/admin/journey',
        component: AdminUserJourney,
    }
]