import { QAItem } from "../../../models/help";

const handleButtonClick = () => {
    const mail = 'support@admattic.com'
    window.open(`https://mail.google.com/?view=cm&fs=1&to=${mail}`, '_blank');
  };

export const qaitems: QAItem[] = [
    {
        question: "How can I see the estimated CPI for the campaign after it's live?",
        answer: "You'll find the estimated CPI on the campaign dashboard once the campaign is live.",
    },
    {
        question: 'Can I add multiple languages in the campaign language targeting?',
        answer: 'Absolutely! You can target multiple languages within a campaign to broaden its reach.',
    },
    {
        question: 'Will the campaign continue if the budget is exhausted? How can I add more budget ?',
        answer: 'Once your budget runs out, the campaign pauses. To add more budget, simply top up using payment options like Stripe or bank transfers.',
    },
    {
        question: 'How do I create additional ad groups?',
        answer: 'Access the campaign details and click "Add a new ad group." After publishing, allow Upto 1 hour for activation before checking data.',
    },
    {
        question: "When will I see the campaign numbers once it's live?",
        answer: 'Upon publishing the campaign, expect real-time numbers. If not immediately visible, please allow up to 1 hour for the data to show.',
    },
    {
        question: " How can I edit the campaign after it's live?        ",
        answer: ' To edit a live campaign, select the campaign and click “edit” in the top right corner to make necessary changes.        ',
    },
    {
        question: ' How can I control the budget after the campaign is live?   ',
        answer: ' To edit the campaign budget, navigate to the “budget and bidding” section in the dashboard. Changes may take up to 1 hour to reflect.        ',
    },
    {
        question: ' I can’t find my app in the “Look up your App” search bar.        ',
        answer: " If your app isn't found, double-check the spelling and ensure it's listed on the Play Store/App Store. Newly listed apps might take time to appear in our database.        ",
    },
    {
        question: " I want to add funds to my Wallet, how can I do the same?        ",
        answer: "  You can add funds by accessing the wallet section and using payment methods such as Stripe or Bank Transfer to top up your account.        ",
    },
    {
        question: " There is no balance in my wallet, can I still run the campaigns?        ",
        answer: "  Unfortunately, campaigns require a sufficient wallet balance to initiate and run. Please add funds to your wallet, and within up to 1 hour, your campaigns will be active and running smoothly.        ",
    },
    {
        question: " Where can I see the invoices and other Billing details?        ",
        answer: "  Access transaction and invoice details within the billing section of your account.        ",
    },
    {
        question: " Can I create more than one account on the platform?        ",
        answer: "  : Unfortunately, you can only have one account per user on the platform. If you need help with your current account or have any questions, please contact our support team.        ",
    },
    {
        question: " Is the data real-time?        ",
        answer: "   The data is near real-time, with periodic updates on the dashboard.        ",
    },
    {
        question: " How do I download a report?        ",
        answer: "  Simply click the 'download report' button in the reporting section to download campaign reports.        ",
    },
    {
        question: " Can I change countries after creating an account?        ",
        answer: "  Unfortunately, changing countries within an account isn't supported. If necessary, creating a new account might be required. For further information or assistance, please feel free to reach out to our team        ",
    },
    {
        question: " How do I raise tickets for query resolution?        ",
        answer: <div>You can connect with our support team at <a onClick={handleButtonClick} style={{textDecorationLine: "underline", cursor: "pointer", color: "blue" }}>support@admattic.com</a> for any query resolutions, and our team will respond promptly within 24 hours to assist you.</div>       ,
    },
    {
        question: " What creative formats does the platform support?",
        answer: "Our platform accommodates diverse formats, such as Video, HTML Files, Images, and Scripts.        ",
    },
    {
        question: "Need further assistance?",
        answer: <div>If you need further assistance, please contact our support team at <a onClick={handleButtonClick} style={{textDecorationLine: "underline", cursor: "pointer", color: "blue" }}>support@admattic.com</a>.</div>
    }

]