import React, { useState, useEffect, useRef, FC } from 'react';
import { MenuItem, Button, Menu, Snackbar, Checkbox, ListItemText, CircularProgress } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import '../../../CSS/report.css';
import '../../../CSS/textfield.css';
import '../../../CSS/DateCustom.css'
import RefreshIcon from '@mui/icons-material/Refresh';
import AdminReportTable from 'react-data-table-component'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import CustomLoader from '../../custom/CustomLoader';
import { DownloadButton, apiURL, customCheckBoxColor, secretKey } from '../../../Constant';
import { connect } from 'react-redux';
import { setAdvertiserId, setAdvertisersList, setAuthToken, setCurrencySymbol, setMasterCountryArray, setReportScreenTable, setSelectedDateRange, setSessionId } from '../../../REDUX/Actions/userActionIndex';
import { RootState } from '../../../REDUX/Reducers/rootReducer';
import DateCustomPicker from '../../custom/DateCustomPicker';
import active from "../../../../IMAGE/active.svg";
import paused from "../../../../IMAGE/paused.svg";
import stopped from "../../../../IMAGE/stopped.svg";
import CreateProfileIcon from '../../../IMAGE/createProfile.svg';
import { useDispatch } from 'react-redux';
import { styled } from 'styled-components';
// import styled from '@emotion/styled';
const CryptoJS = require('crypto-js')

interface AdminReportScreenProps {
    currencySymbol: any;
    authToken: any;
    sessionId: any;
    advertiserId: any;
    selectedDateRange: any;
    advertisersList: any;
    reportScreenTable: any[];
    masterCountryArray: any[];
}

// const DownloadButton = styled(Button)(({ theme }) => ({
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'space-evenly',
//     backgroundColor: '#6900B8',
//     borderRadius: '2px',
//     padding: '5px 10px',
//     color: 'white',
//     fontSize: '12px',
//     fontWeight: 500,
//     width: "auto",
//     height: "40px",
//     border: "none",
//     textTransform: "unset",
//     '&:hover': {
//         backgroundColor: '#6900B8',
//         color: 'white',
//     },
// }));

const CustomSelect = styled(Select)(({ theme }) => ({
    backgroundColor: "white",
    fontFamily: "Manrope",
    fontSize: "14px",
    fontWeight: "600",
    width: "90%",
    height: "45px",
    borderRadius: "2px",
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
    {
        border: "1px solid black",
    },
}));

const RefreshBtn = styled('button')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    backgroundColor: 'rgba(153, 153, 153, 0.2)',
    borderRadius: '2px',
    color: '#6900B8',
    padding: '5px 10px',
    width: "auto",
    height: "40px",
    border: "none",
    marginRight: "10px"
}));

// const CustomSelect = styled(Select)(({ theme }) => ({
//     fontSize: "14px",
//     fontWeight: "600",
//     height: "40px",
//     marginRight: '10px',
//     borderRadius: '2px',
//     width: "95%",
//     '& fieldset': {

//         border: '1px solid rgba(51, 51, 51, 0.5)',
//         outline: 'none',
//     },
//     '&.Mui-focused fieldset': {
//         border: '1px solid rgba(51, 51, 51, 0.5)',
//         outline: 'none',
//     },
//     '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//         border: '1px solid rgba(51, 51, 51, 0.5)',
//         outline: 'none',
//     },
// }));

const AdminReportScreen: React.FC<AdminReportScreenProps> = (props) => {

    const { currencySymbol, authToken, sessionId, advertiserId, selectedDateRange, advertisersList, reportScreenTable, masterCountryArray } = props

    const [appArray, setAppArray] = useState<any[]>([])
    const [campArray, setCampArray] = useState<any[]>([])
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [selectedStartDate, setSelectedStartDate] = useState<Date>(
        new Date(new Date().getFullYear(), new Date().getMonth() - 2, 1)
    );
    const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());

    const [selectedRange, setSelectedRange] = useState<any>({
        startDate: selectedStartDate,
        endDate: new Date(),
    });

    const status: any = [
        {
            value: 'active',
            label: 'Active',
        },
        {
            value: 'pending',
            label: 'Pending',
        },
        {
            value: 'paused',
            label: 'Paused',
        },
        {
            value: 'disabled',
            label: 'Disabled',
        },
        {
            value: 'expired',
            label: 'Expired',
        },
    ]

    const groupByMenu = [
        {
            name: "applicationName",
            label: "Application Name"
        },
        {
            name: "campaignName",
            label: "Campaign Name"
        }
    ]

    const [groupBySearch, setGroupBySearch] = React.useState('campaignName')
    const [loaderActive, setLoaderActive] = useState<boolean>(false)
    const [toggleCal, setToggleCal] = useState<string>("none");
    const boxRef = useRef<HTMLDivElement | null>(null);
    const [openSession, setOpenSession] = React.useState(false);
    const [filters, setFilters] = useState<any>({
        campaign: [],
        status: '',
        application: [],
        groupBy: "campaignName",
        advertiser: [],
        field: ['Brand Name', 'Date', 'Status', 'Impressions', 'CPC', 'CTR', 'CPI', 'Installs', 'Clicks', 'Campaign Name']
    });

    const handleSessionClose = () => {
        setOpenSession(false);
    };

    const toggleCalender = () => {
        if (toggleCal == "none") {
            setToggleCal("block");
        } else {
            setToggleCal("none");
        }
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (boxRef.current && !boxRef.current.contains(event.target as Node)) {
            setToggleCal("none");
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const formatChangeDate = (dateParam: any) => {
        const inputDateString = dateParam;
        const date = new Date(inputDateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate
    }

    const cols = [
        {
            id: 'advertiserName',
            name: 'Brand Name',
            selector: (row: any) => row.advertiserName,
        },
        filters.groupBy === 'applicationName' ? {
            id: "application",
            name: 'Application',
            selector: (row: any) => row.applicationName,
        } : {
            id: "campaignName",
            name: 'Campaign Name',
            selector: (row: any) => row.campaignName,
        },
        {
            id: 'date',
            name: 'Date',
            selector: (row: any) => row.date,
            sortable: true
        },
        {
            id: 'status',
            name: 'Status',
            selector: (row: any) => <p
                className={
                    row.status === "active"
                        ? "active-para"
                        : row.status ===
                            "paused"
                            ? "paused-para"
                            : row.status ===
                                "pending"
                                ? "pending-para"
                                : "stopped-para"
                }
            >
                {row.status == "disabled"
                    ? "Stopped"
                    : row.status.charAt(
                        0
                    ).toUpperCase() +
                    row.status.slice(1)}
            </p>
        },
        {
            id: 'impressions',
            name: 'Impressions',
            selector: (row: any) => row.impressions,
        },
        {
            id: 'clicks',
            name: 'Clicks',
            selector: (row: any) => row.clicks,
            sortable: true
        },
        {
            id: 'ctr',
            name: 'CTR',
            selector: (row: any) => row.ctr,
            sortable: true
        },
        {
            id: 'cpc',
            name: 'CPC',
            selector: (row: any) => Number(row.cpc).toFixed(2),
            format: (row: any) => row.currencySym + " " + Number(row.cpc).toFixed(2), // Format display with currency symbol
            sortable: true
        },
        {
            id: 'installs',
            name: 'Installs',
            selector: (row: any) => row.installs,
            sortable: true,
        },
        {
            id: 'cpi',
            name: 'CPI',
            selector: (row: any) => Number(row.cpi),
            format: (row: any) => row.currencySym + " " + Number(row.cpi).toFixed(2), // Format display with currency symbol
            sortable: true
        },
        {
            id: 'advertiserID',
            name: 'Brand ID',
            selector: (row: any) => row.id,
        },
        {
            id: 'location',
            name: 'Geo-Location',
            selector: (row: any) => row.location,
        },
        {
            id: 'campId',
            name: 'Campaign ID',
            selector: (row: any) => row.campId,
        },
        {
            id: 'category',
            name: 'Category',
            selector: (row: any) => row.category,
        },
        {
            id: "month",
            name: 'Month',
            selector: (row: any) => "",
            width: "120px",
        },
        {
            id: "totalBudget",
            name: 'Total Budget',
            selector: (row: any) => row.totalBudget,
            width: "130px",
        },
        {
            id: "cost",
            name: 'Total Spendings',
            selector: (row: any) => row.cost.toFixed(2),
            width: "130px",
        },
        {
            id: "margin",
            name: 'Margin',
            selector: (row: any) => row.margin.toFixed(2),
            width: "120px",
        },
    ]

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(downloadData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(dataBlob, 'Report XLSX.xlsx'); // Specify the desired file name here
    };

    const [advertisersData, setAdvertisersData] = useState<any[]>([])
    const [advertiserCompleteData, setAdvertiserCompleteData] = useState<any[]>([])

    const getAllAdvertisersDetails = async () => {

        try {
            appArray.length = 0
            campArray.length = 0
            setAppLoader(true)
            setCampaignLoader(true)
            setLoaderActive(true)

            const encryptAdvertiserID = CryptoJS.AES.encrypt(JSON.stringify(advertiserId), secretKey).toString()
            let headers = {
                'Content-Type': 'application/json',
                'advertiserid': encryptAdvertiserID,
                'authtoken': authToken,
                'sessionid': sessionId
            };
            let jsonParams = { startDate: formatChangeDate(selectedRange.startDate), endDate: formatChangeDate(selectedRange.endDate) };
            console.log(jsonParams)
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(jsonParams), secretKey).toString();

            const response = await axios.post(`${apiURL}/api/advertisers/getAllAdvertisers`, { params: encryptedData }, { headers: headers });
            console.log(response)
            if (response.data.status == true) {
                const resData = response.data.data;
                const bytes = CryptoJS.AES.decrypt(resData, secretKey);
                const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
                const parsedData = JSON.parse(decryptedData);
                const resDataArray: any = [];
                // for (const obj of parsedData) {
                //     let img = ''
                //     if (obj.advertiserProfile == null) {
                //         // setImage("")
                //     } else {
                //         const uint8Array = new Uint8Array(obj.advertiserProfile['data']);
                //         const blob = new Blob([uint8Array]);
                //         const dataUrl = URL.createObjectURL(blob);
                //         img = dataUrl;
                //         // const blob2 = new Blob([Buffer.from(obj.advertiserProfile['data'])], { type: 'application/octet-stream' });
                //         // setSelectedFile(blob2)
                //     }
                //     const singleObj = obj.campaignData
                //     if (singleObj.length != 0) {
                //         singleObj.forEach((element: any) => {
                //             const appName = element.applicationName;
                //             if (appName != null && !appArray.includes(appName)) {
                //                 appArray.push(appName);
                //             }
                //             const campName = element.campaignName;
                //             if (campName != null && !campArray.includes(campName)) {
                //                 campArray.push(campName)
                //             }
                //             resDataArray.push({
                //                 advId: obj.id,
                //                 advertiserName: obj.name,
                //                 applicationName: element.applicationName,
                //                 campaignName: element.campaignName,
                //                 impressions: element.impressions,
                //                 clicks: element.clicks,
                //                 cpc: element.cpc,
                //                 ctr: element.ctr,
                //                 category: element.budgetData != undefined ? element.budgetData.targetingType : "",
                //                 location: element.campaignData != undefined ? element.campaignData.Location : "",  
                //                 installs: element.installs,
                //                 cpi: element.cpi,
                //                 status: element.status,
                //                 image: img,
                //                 date: formatChangeDate(selectedRange.startDate) + "-" + formatChangeDate(selectedRange.endDate)
                //             });
                //         })
                //     }
                // }
                setLoaderActive(false)
                // setAdvertisersData(resDataArray)
                // setFilteredAdvertisersData(resDataArray)
                // setDownloadData(resDataArray)
                dispatch(setReportScreenTable(parsedData))
                setAdvertiserCompleteData(parsedData)
            } else if (response.data.statuscode == 401) {
                // setOpenSession(true)
                setTimeout(() => { navigate("/") }, 1000)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const [filtersAdvertisersData, setFilteredAdvertisersData] = useState<any[]>(advertisersData)
    const [downloadData, setDownloadData] = useState<any[]>([])

    const funnelFilter = [
        {
            name: "Daily",
            value: "daily"
        },
        {
            name: "Weekly",
            value: "weekly"
        },
        {
            name: "Monthly",
            value: "monthly"
        }
    ]

    const [appLoader, setAppLoader] = useState(false)
    const [campaignLoader, setCampaignLoader] = useState(false)

    const [anchorE2, setAnchorE2] = React.useState<null | HTMLElement>(null);
    const openE2 = Boolean(anchorE2);
    const handleClickE2 = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorE2(event.currentTarget);
    };
    const handleCloseE2 = () => {
        setAnchorE2(null);
    };

    const handleFilterChange = (filterType: any, value: any) => {
        setFilters((prevFilters: any) => ({
            ...prevFilters,
            [filterType]: value,
        }));
    };

    const [filteredcolumns, setFilteredColumns] = useState<any[]>(cols)
    const [clicked, setClicked] = useState(false);

    useEffect(() => {
        if (selectedDateRange.endDate != "" && selectedDateRange.startDate != "") {
            const s = new Date(selectedDateRange.startDate);
            const e = new Date(selectedDateRange.endDate);
            setSelectedRange({
                startDate: s,
                endDate: e
            })
        }
    }, [selectedDateRange]);



    const getReportData = () => {
        setAppLoader(true)
        setCampaignLoader(true)
        if (reportScreenTable != null && reportScreenTable.length != 0) {

            const resDataArray: any = [];

            for (const obj of reportScreenTable) {
                let img = ''

                if (obj.advertiserProfile == null) {
                    // setImage("")
                } else {
                    const uint8Array = new Uint8Array(obj.advertiserProfile['data']);
                    const blob = new Blob([uint8Array]);
                    const dataUrl = URL.createObjectURL(blob);
                    img = dataUrl;
                    // const blob2 = new Blob([Buffer.from(obj.advertiserProfile['data'])], { type: 'application/octet-stream' });
                    // setSelectedFile(blob2)
                }

                const singleObj = obj.campaignData

                if (singleObj != null) {
                    singleObj.forEach((element: any) => {


                        const appName = element.applicationName;
                        if (appName != null && !appArray.includes(appName)) {
                            appArray.push(appName);
                        }

                        const campName = element.campaignName;
                        if (campName != null && !campArray.includes(campName)) {
                            campArray.push(campName)
                        }
                        let currencySym = ""

                        if (element.budgetData != undefined || element.budgetData != null) {
                            let arr = masterCountryArray.filter((item: any) => {
                                return item.Code == element.budgetData.cpiCurrency
                            })

                            if (arr && arr.length) {
                                currencySym = arr[0].CurrencySymbol
                            } else {
                                let countryArray = []
                                countryArray = masterCountryArray
                                if (countryArray && Array.isArray(countryArray)) {
                                    let countryFind = countryArray.find((element: any) => { return obj.Country === element.Country })

                                    if (countryFind != undefined) {
                                        currencySym = countryFind.CurrencySymbol
                                    }
                                }
                            }
                        }

                        resDataArray.push({
                            advertiserID: obj.id,
                            advertiserName: obj.name,
                            applicationName: element.applicationName,
                            campaignName: element.campaignName,
                            impressions: element.impressions,
                            clicks: element.clicks,
                            currencySym: currencySym,
                            cpc: element.cpc,
                            ctr: element.ctr,
                            category: element.budgetData != undefined ? element.budgetData.targetingType : "",
                            location: element.campaignData != undefined ? element.campaignData.Location : "",
                            installs: element.installs,
                            cpi: element.cpi,
                            status: element.status,
                            image: img,
                            campId: element.id,
                            createdAt: element.campaignData != undefined ? element.campaignData.createdAt : "",
                            totalBudget: element.budgetData != undefined ? element.budgetData.dailyAvgBudget : "",
                            cost: element.cost,
                            margin: element.margin,
                            month: new Date(selectedDateRange.startDate).toLocaleString("default", { month: "short" }),
                            date: formatChangeDate(selectedDateRange.startDate) + "-" + formatChangeDate(selectedDateRange.endDate)
                        });
                    })
                }
            }
            setAppLoader(false)
            setCampaignLoader(false)
            setAdvertisersData(resDataArray)
            setFilteredAdvertisersData(resDataArray)
            setDownloadData(resDataArray)
        }
        setAppLoader(false)
        setCampaignLoader(false)
    }

    useEffect(() => {
        getReportData()
    }, [advertiserCompleteData]);

    // useEffect(() => {
    //     if (reportScreenTable == null || reportScreenTable.length == 0) {
    //         getAllAdvertisersDetails()
    //     } else {
    //         setAdvertiserCompleteData(reportScreenTable)
    //     }
    // }, []);

    // useEffect(() => {
    //     if (selectedDateRange.endDate != "" && selectedDateRange.startDate != "") {
    //         const s = new Date(selectedDateRange.startDate);
    //         const e = new Date(selectedDateRange.endDate);
    //         setSelectedRange({
    //             startDate: s,
    //             endDate: e
    //         })
    //     }
    // }, []);

    useEffect(() => {
        dispatch(setSelectedDateRange({
            startDate: selectedStartDate,
            endDate: selectedEndDate
        }))
    }, [])

    useEffect(() => {
        getAllAdvertisersDetails()
    }, [selectedRange && clicked])

    const getfilteredData = () => {
        appArray.length = 0
        campArray.length = 0
        setAppLoader(true)
        setCampaignLoader(true)
        if (filters.advertiser.length == 0) {
            advertiserCompleteData.forEach((advertiser) => {
                const singleObj = advertiser.campaignData
                if (singleObj != null) {
                    singleObj.forEach((element: any) => {
                        const appName = element.applicationName;
                        if (appName != null && !appArray.includes(appName)) {
                            appArray.push(appName);
                        }
                        const campName = element.campaignName;
                        if (campName != null && !campArray.includes(campName)) {
                            campArray.push(campName)
                        }
                    })
                }
                setAppLoader(false)
                setCampaignLoader(false)
            })
        } else {
            advertiserCompleteData.forEach((advertiser) => {
                if (filters.advertiser.includes(advertiser.name)) {
                    const singleObj = advertiser.campaignData
                    if (singleObj.length != 0) {
                        singleObj.forEach((element: any) => {

                            const appName = element.applicationName;
                            if (appName != null && !appArray.includes(appName)) {
                                appArray.push(appName);
                            }
                            const campName = element.campaignName;
                            if (campName != null && !campArray.includes(campName)) {
                                campArray.push(campName)
                            }
                        })
                    }
                }
                setAppLoader(false)
                setCampaignLoader(false)
            })
        }
        const result = advertisersData.filter((advertiser) => {
            return (
                (filters.advertiser.length == 0 || filters.advertiser.includes(advertiser.advertiserName)) &&
                (filters.campaign.length == 0 || filters.campaign.includes(advertiser.campaignName)) &&
                (filters.application.length == 0 || filters.application.includes(advertiser.applicationName)) &&
                (filters.status === "" || filters.status.includes(advertiser.status))
            );
        })

        const checkGroupBy = [
            {
                id: 'advertiserName',
                name: 'Brand Name',
                selector: (row: any) =>
                    <div style={{
                        display: "flex",
                        alignItems: "center"
                    }}> {<img src={row.image != "" ? row.image : CreateProfileIcon} height="35px" width="35px" style={{ borderRadius: 6, marginRight: 10 }} />}{row.advertiserName}</div>,
                width: "210px",
                wrap: true,
                style: {
                    borderRight: "1.4px solid #cccccc",
                    fontWeight: 750
                }
            },
            filters.groupBy === 'applicationName' ? {
                id: "application",
                name: 'Application',
                selector: (row: any) => row.applicationName,
                wrap: true,
                width: "165px",
                style: {
                    fontWeight: 750
                }
            } : {
                id: "campaignName",
                name: 'Campaign Name',
                selector: (row: any) => row.campaignName,
                wrap: true,
                width: "165px",
                style: {
                    fontWeight: 750
                }
            },
            {
                id: 'date',
                name: 'Date',
                selector: (row: any) => formatChangeDate(selectedDateRange.startDate) + "-" + formatChangeDate(selectedDateRange.endDate),
                // sortable: dateFilter == "daily" ? true : false
                width: "200px",
                sortable: true
            },
            {
                id: 'status',
                name: 'Status',
                selector: (row: any) =>
                    <p
                        className={
                            row.status === "active"
                                ? "active-para"
                                : row.status ===
                                    "paused"
                                    ? "paused-para"
                                    : row.status ===
                                        "pending"
                                        ? "pending-para"
                                        : "stopped-para"
                        }
                    >
                        {row.status == "disabled"
                            ? "Stopped"
                            : row.status.charAt(
                                0
                            ).toUpperCase() +
                            row.status.slice(1)}
                    </p>,
                width: "90px",
            },
            {
                id: "impressions",
                name: 'Impressions',
                selector: (row: any) => Number(row.impressions),
                sortable: true,
                width: "150px",
            },
            {
                id: "clicks",
                name: 'Clicks',
                selector: (row: any) => Number(row.clicks),
                sortable: true,
                width: "100px",
            },
            {
                id: "ctr",
                name: 'CTR',
                selector: (row: any) => Number(row.ctr),
                sortable: true,
                width: "90px",
            },
            {
                id: "cpc",
                name: 'CPC',
                selector: (row: any) => Number(row.cpc).toFixed(2),
                format: (row: any) => row.currencySym + " " + Number(row.cpc).toFixed(2), // Format display with currency symbol
                width: "90px",
                sortable: true
            },
            {
                id: "installs",
                name: 'Installs',
                selector: (row: any) => Number(row.installs),
                sortable: true,
                width: "115px",
            },
            {
                id: "cpi",
                name: 'CPI',
                selector: (row: any) => Number(row.cpi),
                format: (row: any) => row.currencySym + " " + Number(row.cpi).toFixed(2), // Format display with currency symbol
                width: "120px",
                sortable: true,
            },
            {
                id: 'location',
                name: 'Geo-Location',
                selector: (row: any) => row.location,
                width: "140px",
            },
            {
                id: 'advertiserID',
                name: 'Brand ID',
                selector: (row: any) => row.advertiserID,
                width: "115px",
            },
            {
                id: 'campId',
                name: 'Campaign ID',
                selector: (row: any) => row.campId,
                width: "140px",
            },
            {
                id: 'category',
                name: 'Category',
                selector: (row: any) => row.category,
            },
            {
                id: "month",
                name: 'Month',
                selector: (row: any) => row.month,
                width: "100px",
            },
            {
                id: "totalBudget",
                name: 'Total Budget',
                selector: (row: any) => row.totalBudget != null ? Number(row.totalBudget) : "",
                width: "160px",
                sortable: true
            },
            {
                id: "cost",
                name: 'Total Spendings',
                selector: (row: any) => row.cost.toFixed(2),
                width: "160px",
            },
            {
                id: "margin",
                name: 'Margin',
                selector: (row: any) => row.margin.toFixed(2),
                width: "100px",
            },
        ]

        // setFilters((prevFilters: any) => ({
        //     ...prevFilters,
        //     field: ['Advertiser Name', filters.groupBy == 'campaignName' ? 'Campaign Name' : 'Application', 'Date', 'Status', 'Impressions', 'CPC', 'CTR', 'CPI', 'Installs', 'Clicks']
        // }));

        const checkColumns = filters.field.length == 0 ? checkGroupBy : checkGroupBy.filter((col: any) => { return (filters.field.includes(col.name)); })

        setFilteredColumns(checkColumns.length > 0 ? checkColumns : [])
        setFilteredAdvertisersData(result)
        const csvData = result.map(row => {
            const rowData: any = {};
            checkColumns.forEach((field: any) => {
                rowData[field.id] = row[field.id];
            });
            return rowData;
        });
        setDownloadData(csvData)
    }

    useEffect(() => {
        getfilteredData()
    }, [filters, advertisersData])

    const CustomIconComponent: FC<{ loading: boolean }> = ({ loading }) => (
        <div style={{ position: 'relative', display: "flex", alignItems: "center", marginRight: "8px", cursor: "pointer" }}>
            {loading ? (
                <CircularProgress style={{ color: customCheckBoxColor }} size="1rem" />
            ) : (
                <ExpandMoreIcon sx={{
                    color: "#000000",
                    opacity: 0.55
                }} />
            )}
        </div>
    );

    return (
        <div>
            <div className='white-container'>
                <div className='div-display' style={{
                    marginBottom: "15px"
                }}>
                    <div className='select-div-size'>
                        <div className='sub-heading'>Select Brand / Agency</div>
                        <div>
                            <CustomSelect
                                multiple
                                value={filters.advertiser}
                                IconComponent={ExpandMoreIcon}
                                onChange={(e: any) => handleFilterChange('advertiser', e.target.value)}
                                renderValue={(selected: any) => selected.join(', ')}
                            >
                                {advertisersList.map((item: any) => (
                                    <MenuItem className='menuItemStyle' key={item} value={item.advertiserName}>
                                        <Checkbox checked={filters.advertiser.indexOf(item.advertiserName) > -1} style={{ color: customCheckBoxColor }} />
                                        {/* <ListItemText primary={item.advertiserName} secondary={item.id} /> */}
                                        {item.advertiserName}{" (" + item.id + ")"}
                                    </MenuItem>
                                ))}
                            </CustomSelect>
                        </div>
                    </div>
                    <div className='select-div-size'>
                        <div className='sub-heading'>Select Application(s)</div>
                        <div>
                            <CustomSelect
                                multiple
                                value={filters.application}
                                IconComponent={(props) => <CustomIconComponent loading={appLoader === true && appArray.length === 0} {...props} />}
                                renderValue={(selected: any) => selected.join(', ')}
                                onChange={(e: any) => handleFilterChange('application', e.target.value)}
                            >
                                {appLoader == false ? (appArray.length != 0 ? appArray.map((item: any) => (
                                    <MenuItem className='menuItemStyle' key={item} value={item}>
                                        <Checkbox checked={filters.application.indexOf(item) > -1} style={{ color: customCheckBoxColor }} />
                                        {/* <ListItemText primary={item} /> */}
                                        {item}
                                    </MenuItem>
                                )) : <MenuItem className='menuItemStyle'>No Application found</MenuItem>) : <div style={{
                                    display: "flex",
                                    justifyContent: "center"
                                }}><CircularProgress style={{ color: customCheckBoxColor }} size="1.5rem" /></div>}
                            </CustomSelect>
                        </div>
                    </div>
                    <div className='select-div-size'>
                        <div className='sub-heading'>Select Campaign(s)</div>
                        <div>
                            <CustomSelect
                                multiple
                                value={filters.campaign}
                                IconComponent={(props) => <CustomIconComponent loading={campaignLoader === true && campArray.length === 0} {...props} />}
                                onChange={(e: any) => handleFilterChange('campaign', e.target.value)}
                                renderValue={(selected: any) => selected.join(', ')}
                            >
                                {campaignLoader == false ? (campArray.length != 0 ? campArray.map((item: any) => (
                                    <MenuItem className='menuItemStyle' key={item} value={item}>
                                        <Checkbox checked={filters.campaign.indexOf(item) > -1} style={{ color: customCheckBoxColor }} />
                                        {/* <ListItemText primary={item} /> */}
                                        {item}
                                    </MenuItem>)) : <MenuItem className='menuItemStyle'>No Campaign found</MenuItem>) : <div style={{
                                        display: "flex",
                                        justifyContent: "center"
                                    }}><CircularProgress style={{ color: customCheckBoxColor }} size="1.5rem" /></div>}
                            </CustomSelect>
                        </div>
                    </div>
                </div>
                <div className='div-display'>
                    <div className='select-div-size'>
                        <div className='sub-heading'>Group by</div>
                        <div>
                            <CustomSelect
                                name={groupBySearch}
                                value={filters.groupBy}
                                IconComponent={ExpandMoreIcon}
                                onChange={(e: any) => handleFilterChange("groupBy", e.target.value)
                                }
                            >
                                {groupByMenu.map((make, index) => (
                                    <MenuItem className='menuItemStyle' key={index} value={make.name}>{make.label}</MenuItem>
                                ))}
                            </CustomSelect>
                        </div>
                    </div>
                    <div className='select-div-size'>
                        <div className='sub-heading'>Status</div>
                        <div>
                            <CustomSelect
                                value={filters.status}
                                IconComponent={ExpandMoreIcon}
                                onChange={(e: any) =>
                                    handleFilterChange('status', e.target.value)
                                }
                            >
                                {status.map((item: any) => (
                                    <MenuItem className='menuItemStyle' key={item.value} value={item.value}>{item.label}</MenuItem>
                                ))}
                            </CustomSelect>
                        </div>
                    </div>
                    <div className='select-div-size'>
                        <div className='sub-heading'>Fields</div>
                        <div>
                            <CustomSelect
                                multiple
                                value={filters.field}
                                IconComponent={ExpandMoreIcon}
                                onChange={(e: any) => handleFilterChange("field", e.target.value)}
                                renderValue={(selected: any) => selected.join(', ')}
                            >
                                {cols.map((item: any) => (
                                    <MenuItem className='menuItemStyle' key={item.id} value={item.name}>
                                        <Checkbox checked={filters.field.indexOf(item.name) > -1} style={{ color: customCheckBoxColor }} />
                                        {/* <ListItemText primary={item.name} /> */}
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </CustomSelect>
                        </div>
                    </div>
                </div>
            </div>
            <div className='div-display' style={{
                alignItems: "center"
            }}>
                {/* <div>
                    <div ref={boxRef} className="box">
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <h5 style={{ fontWeight: 800, fontSize: "12px", marginRight: "8px" }}>
                                {dateFilter == "" ? "Custom" : dateFilter}
                            </h5>
                            <Button
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClickE2}
                                sx={{
                                    color: "#6900B8"
                                }}
                            >
                                <FilterAltIcon sx={
                                    {
                                        cursor: "pointer"
                                    }
                                } />
                            </Button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorE2}
                                open={openE2}
                                onClose={handleCloseE2}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                {
                                    funnelFilter.map((option: any) => (
                                        <MenuItem key={option} onClick={() => {
                                            setDateFilter(option.value)
                                            setAnchorE2(null)
                                        }} >{option.name}</MenuItem>
                                    ))
                                }
                            </Menu>
                            <div className="calender-btn" onClick={toggleCalender}>
                                <img src={calender} />
                                <p>{dateRange.startDate == null ? "Start Date" : dateRange.startDate.toLocaleDateString()}</p> -
                                <p>{dateRange.endDate == null ? "End Date" : dateRange.endDate.toLocaleDateString()}</p>
                            </div>
                        </div>
                        <div style={{ display: `${toggleCal}`, zIndex: "100" }} >
                            <div>
                                <DateRangePicker ranges={[dateRange]} moveRangeOnFirstSelection={false} onChange={handleSelect} maxDate={new Date()} />
                            </div>
                            <div className="button-container">
                                <button
                                    className="purple-btn width-adjust"
                                    onClick={() => {
                                        onClickGetReport();
                                        toggleCalender();
                                    }}
                                    style={{
                                        margin: '0 15px',
                                        cursor: "pointer"
                                    }}
                                >
                                    Done
                                </button>
                                <button
                                    className="border-btn"
                                    onClick={toggleCalender}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                    {dateRangeError != "" ?
                        <div className="error-box" style={{
                            float: "right",
                            marginBottom: "10px"
                        }}>
                            <p>{dateRangeError}</p>
                        </div>
                        : <div></div>}
                </div> */}
                <DateCustomPicker onClick={() => setClicked(!clicked)} />
                <div style={{
                    display: "flex",
                    float: "right",
                }}>
                    <RefreshBtn onClick={() => window.location.reload()}><RefreshIcon sx={{
                        color: "#333333"
                    }} /></RefreshBtn>
                    <div>
                        <DownloadButton
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                        >
                            <FileDownloadOutlinedIcon sx={{ marginRight: "4px" }} /> Download Report
                        </DownloadButton>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem>{downloadData.length == 0 ? (
                                <li
                                    onClick={() => {
                                        alert("No data available to download.");
                                    }}
                                    style={{
                                        fontSize: "12px",
                                        fontWeight: "600",
                                        color: "blue",
                                        textDecorationLine: 'underline'
                                    }}
                                >
                                    Document (.csv) file
                                </li>
                            ) : (<CSVLink data={downloadData} filename='Report CSV' style={{
                                fontSize: "12px",
                                fontWeight: "600",
                                color: "blue",

                            }}>Document (.csv) file</CSVLink>)}</MenuItem>
                            <MenuItem>{downloadData.length == 0 ? (
                                <li
                                    onClick={() => {
                                        alert("No data available to download.");
                                    }}
                                    style={{
                                        fontSize: "12px",
                                        fontWeight: "600",
                                        color: "blue",
                                        textDecorationLine: 'underline'
                                    }}
                                >
                                    Excel (.xlsx) file
                                </li>
                            ) : (<a onClick={downloadExcel} style={{
                                fontSize: "12px",
                                fontWeight: "600",
                                color: "blue",
                                textDecorationLine: 'underline'
                            }}>Excel (.xlsx) file</a>)}</MenuItem>
                        </Menu>
                    </div>
                </div>
            </div>

            <div className='white-container' >
                {loaderActive ? <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "200px"
                }}>
                    <CustomLoader />
                </div> : <AdminReportTable
                    data={filtersAdvertisersData}
                    pagination={true}
                    paginationPerPage={5}
                    paginationComponentOptions={{
                        noRowsPerPage: true,
                        rowsPerPageText: 'Rows per page:', // Customize the text for rows per page
                    }}
                    fixedHeader
                    defaultSortFieldId="installs"
                    defaultSortAsc={false}
                    columns={filteredcolumns}
                    conditionalRowStyles={[ // Apply the class to remove bottom border to every row
                        {
                            when: () => true,
                            style: {
                                borderBottom: 'none',
                            },
                        },
                    ]}
                    customStyles={{
                        headRow: {
                            style: {
                                borderBottom: "none"
                            }
                        },
                        headCells: {
                            style: {
                                color: "#6900B8",
                                fontSize: "16px",
                                fontWeight: 800,
                            },
                        },
                        cells: {
                            style: {
                                color: "#333333",
                                fontSize: "14px",
                                fontWeight: 550,
                            },
                        },
                    }}
                />}
            </div>

            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={openSession}
                onClose={handleSessionClose}
                message="Session Expired!"
            />
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    currencySymbol: state.user.currencySymbol,
    authToken: state.user.authToken,
    sessionId: state.user.sessionId,
    advertiserId: state.user.advertiserId,
    selectedDateRange: state.user.selectedDateRange,
    advertisersList: state.user.advertisersList,
    reportScreenTable: state.user.reportScreenTable,
    masterCountryArray: state.user.masterCountryArray,
});

const mapDispatchToProps = {
    setAdvertisersList,
    setCurrencySymbol,
    setAdvertiserId,
    setAuthToken,
    setSessionId,
    setSelectedDateRange,
    setReportScreenTable,
    setMasterCountryArray
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminReportScreen);