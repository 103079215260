import React, { useState, useEffect, useRef } from "react";
import { FiUpload } from "react-icons/fi";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { PurpleCustomButton } from "../custom/PurpleButton";
import { customCheckBoxColor } from "../../Constant";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import { RootState } from "../../REDUX/Reducers/rootReducer";
import { connect } from "react-redux";
import {
  setImageArray,
  setImageFile,
  setImageList,
  setVideoArray,
  setVideoDuration,
  setVideoFile,
  setVideoUrl,
  setVideoSource,
  setVideoName,
} from "../../REDUX/Actions/userActionIndex";
import { useDispatch } from "react-redux";
import AWS from "aws-sdk";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  padding: "10px",
};
const CustomCheck = styled(Checkbox)`
  &.MuiButtonBase-root.MuiCheckbox-root {
    padding: 0;
  }
`;
interface AddCreativesProps {
  videoSource: any;
  videoArray: any;
  videoDuration: number;
  videoName: string;
  imageList: File[];
  imageArray: any;
}
const AddCreatives: React.FC<AddCreativesProps> = ({
  videoSource,
  videoArray,
  videoDuration,
  videoName,
  imageList,
  imageArray,
}) => {
  const [uploadSwitch, setuploadSwitch] = useState<boolean>(true);
  const [videoSwitch, setVideoSwitch] = useState<boolean>(true);
  const [imageSwitch, setImageSwitch] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [imageOpen, setImageOpen] = useState<boolean>(false);

  //   const [videoSource, setVideoSource] = useState<string | null>(null);
  //   const [videoName, setVideoName] = useState<string>("");
  const [duration, setDuration] = useState<number | null>(null);

  AWS.config.update({
    region: "ap-south-1",
    accessKeyId: "AKIA5VMVCATGUK75O2XE",
    secretAccessKey: "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",
  });

  const s3 = new AWS.S3();

  async function uploadToS3(
    file: File,
    bucketName: string,
    folderName: string,
    fileName: string
  ): Promise<string> {
    const params = {
      Bucket: bucketName,
      Key: `${folderName}/${fileName}`,
      Body: file,
    };

    return new Promise((resolve, reject) => {
      s3.putObject(params, async (err, data) => {
        if (err) {
          console.error("Error uploading to S3:", err);
          reject(err);
        } else {
          // console.log("Successfully uploaded to S3", data);

          // Get the URL for the uploaded file

          const url = s3.getSignedUrl("getObject", {
            Bucket: bucketName,
            Key: `${folderName}/${fileName}`,
          });

          resolve(url);
        }
      });
    });
  }

  function cropUrlBeforeQuestionMark(url: string): string {
    const parts = url.split("?"); // Split the URL string based on the question mark
    return parts[0]; // Return the part before the question mark
  }

  const uploadFiles = (
    files: FileList,
    bucketName: string,
    folderName: string,
    fileType: string
  ) => {
    const tempData: any[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      uploadToS3(file, bucketName, folderName, file.name)
        .then((res) => {
          const croppedUrl = cropUrlBeforeQuestionMark(res);
          console.log("file", croppedUrl);
          // console.log(croppedUrl);
          //   console.log(res);
          if (fileType == "VIDEO") {
            dispatch(setVideoArray([...videoArray, croppedUrl]));
          }
          if (fileType == "IMAGE") {
            // console.log("here", imageArray);
            tempData.push(croppedUrl);
            // if (i == files.length-1) {

            //   dispatch(setImageArray([...imageArray, croppedUrl]));
            // }
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
    if ((fileType = "IMAGE")) {
      dispatch(setImageArray(tempData));
    }
  };

  const handleVideoMetadata = (
    event: React.SyntheticEvent<HTMLVideoElement, Event>
  ) => {
    const target = event.target as HTMLVideoElement;
    setDuration(target.duration);
  };

  const dispatch = useDispatch();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleImageOpen = () => setImageOpen(true);
  const handleImageClose = () => setImageOpen(false);

  const switchUpload = (val: string) => {
    if (val == "video") {
      setuploadSwitch(true);
    } else {
      setuploadSwitch(false);
    }
  };

  const videoUpload = (val: string) => {
    if (val == "library") {
      setVideoSwitch(true);
    } else {
      setVideoSwitch(false);
    }
  };
  const imageUpload = (val: string) => {
    if (val == "library") {
      setImageSwitch(true);
    } else {
      setImageSwitch(false);
    }
  };
  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      console.log(file);
      const fileTypes = ["video/mp4", "video/m4v", "video/x-matroska"];
      if (fileTypes.includes(file.type)) {
        handleClose();
        dispatch(setVideoName(file.name));
        const url = URL.createObjectURL(file);
        dispatch(setVideoSource(url));
        if (event.target.files && event.target.files.length > 0) {
          uploadFiles(
            event.target.files,
            "admattic-internal-data-storage",
            "AdservingPlatform/digitalBillboardVideo",
            "VIDEO"
          );
        }
      } else {
        alert("Please select a valid video file in MP4, M4V, or MKV format.");
      }
    }
  };
  const handleImageSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log(event.target.files?.length> 0);
    if (event.target.files && event.target.files.length > 0) {
      uploadFiles(
        event.target.files,
        "admattic-internal-data-storage",
        "AdservingPlatform/digitalBillboardImages",
        "IMAGE"
      );
    }
  };

  return (
    <div>
      <div className="config-campaign">
        <h2 className="light-header-txt">Upload Creatives Here</h2>
        <div className="upload-container">
          <h3
            style={{
              opacity: uploadSwitch ? 1 : 0.5,
              borderBottom: uploadSwitch ? "2px solid #6900b8" : "none",
            }}
            onClick={() => switchUpload("video")}
          >
            Add a Video
          </h3>
          <h3
            style={{
              marginLeft: "10px",
              opacity: uploadSwitch ? 0.5 : 1,
              borderBottom: uploadSwitch ? "none" : "2px solid #6900b8",
            }}
            onClick={() => switchUpload("slideShow")}
          >
            Add Static Slideshow
          </h3>
        </div>

        {uploadSwitch ? (
          <div className="video-upload">
            {videoSource ? (
              <div className="video-container">
                <div>
                  <div>
                    <h3 className="light-para-txt">Preview</h3>
                    <div
                      className="top-box"
                      style={{
                        justifyContent: "flex-start",
                        margin: "10px 0 20px 0",
                      }}
                    >
                      <CustomCheck
                        {...label}
                        className="chk"
                        // checked={campaignSubtype === "App Install"}
                        // onChange={() => handleCheckboxClick("App Install")}
                        style={{ color: customCheckBoxColor }}
                      />
                      <h5 className="font-prop" style={{ marginLeft: "10px" }}>
                        Show BillBoard Mockup
                      </h5>
                    </div>
                    <div style={{ margin: "10px 0 20px 0" }}>
                      <h3 className="light-para-txt">File Name:</h3>
                      <p className="font-prop">{videoName}</p>
                    </div>

                    <div style={{ margin: "10px 0 20px 0" }}>
                      <h3 className="light-para-txt">Duration</h3>
                      <p className="font-prop">{duration} sec</p>
                    </div>

                    <div>
                      <input
                        type="file"
                        id="file-input"
                        name="file-input"
                        accept="video/mp4, video/m4v, video/x-matroska"
                        max="50000000"
                        onChange={handleFileInputChange}
                      />

                      <label id="file-input-video" htmlFor="file-input">
                        <div
                          className="purple-btn"
                          style={{
                            width: "fit-content",
                            display: "flex",
                            alignItems: "center",
                            padding: "0 10px",
                            fontSize: "13px",
                          }}
                        >
                          Change / Remove Video File
                        </div>
                      </label>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div className="simple-box"></div>
                  <div className="outer-box">
                    <div className="inner-box">
                      <video
                        width="300"
                        controls
                        onLoadedMetadata={handleVideoMetadata}
                      >
                        <source src={videoSource} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="center-upload">
                <button onClick={handleOpen}>
                  <FiUpload className="upload" style={{ marginRight: "5px" }} />
                  Upload File
                </button>
                <p>Recommended Video Duration: 10 Seconds</p>
              </div>
            )}
          </div>
        ) : (
          <div>
            <div className="video-upload">
              {imageArray.length > 0 ? (
                <div>
                  {imageArray.map((val: any) => {
                    return (
                      <div onClick={handleImageOpen}>
                        {/* {val.split("/").pop()} */}
                        {val}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="center-upload">
                  <button onClick={handleImageOpen}>
                    <FiUpload
                      className="upload"
                      style={{ marginRight: "5px" }}
                    />
                    Upload File
                  </button>
                  <p>Maximum 10 Files</p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="upload-modal">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="upload-container">
              <h3
                style={{
                  opacity: videoSwitch ? 1 : 0.5,
                  borderBottom: videoSwitch ? "2px solid #6900b8" : "none",
                }}
                onClick={() => videoUpload("library")}
              >
                Choose From Assests library
              </h3>
              <h3
                style={{
                  marginLeft: "10px",
                  opacity: videoSwitch ? 0.5 : 1,
                  borderBottom: videoSwitch ? "none" : "2px solid #6900b8",
                }}
                onClick={() => videoUpload("device")}
              >
                Upload From Device
              </h3>
            </div>

            {videoSwitch ? (
              <div style={{ height: "40vh" }}>assests library</div>
            ) : (
              <div style={{ height: "38.5vh" }}>
                <p className="light-para-txt" style={{ marginTop: "10px" }}>
                  Note: video that you upload from your computer automatically
                  get added to your library.
                </p>
                <div className="input-box">
                  <div>
                    <input
                      type="file"
                      id="file-input"
                      //   onChange={handleChange}
                      name="file-input"
                      accept="video/mp4, video/m4v, video/x-matroska"
                      max="50000000"
                      onChange={handleFileInputChange}
                    />

                    <label id="file-input-video" htmlFor="file-input">
                      <div
                        className="purple-btn"
                        style={{
                          width: "fit-content",
                          display: "flex",
                          alignItems: "center",
                          padding: "0 10px",
                          fontSize: "13px",
                        }}
                      >
                        <FiUpload style={{ marginRight: "10px" }} />
                        Click to select and upload files from your computer
                      </div>
                    </label>
                  </div>
                  <div
                    className="top-box"
                    style={{ width: "70%", marginTop: "8px" }}
                  >
                    <p className="light-para-txt">
                      Supported video formats: mp4, m4v, mkv.
                    </p>
                    <p className="light-para-txt">Maximum size limit: 5 mb</p>
                  </div>
                </div>
              </div>
            )}
          </Box>
        </Modal>
      </div>

      <div className="upload-modal">
        <Modal
          open={imageOpen}
          onClose={handleImageClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="upload-container">
              <h3
                style={{
                  opacity: imageSwitch ? 1 : 0.5,
                  borderBottom: imageSwitch ? "2px solid #6900b8" : "none",
                }}
                onClick={() => imageUpload("library")}
              >
                Choose From Assests library
              </h3>
              <h3
                style={{
                  marginLeft: "10px",
                  opacity: imageSwitch ? 0.5 : 1,
                  borderBottom: imageSwitch ? "none" : "2px solid #6900b8",
                }}
                onClick={() => imageUpload("device")}
              >
                Upload From Device
              </h3>
            </div>

            {imageSwitch ? (
              <div style={{ height: "40vh" }}>assests library</div>
            ) : (
              <div style={{ height: "38.5vh" }}>
                <p className="light-para-txt" style={{ marginTop: "10px" }}>
                  Note: Images that you upload from your computer automatically
                  get added to your library.
                </p>
                <div className="input-box">
                  <div>
                    <input
                      type="file"
                      id="file-input"
                      //   onChange={handleChange}
                      multiple
                      name="file-input"
                      accept="image/jpeg, image/png, image/webp"
                      max="50000000"
                      onChange={handleImageSelect}
                    />

                    <label id="file-input-video" htmlFor="file-input">
                      <div
                        className="purple-btn"
                        style={{
                          width: "fit-content",
                          display: "flex",
                          alignItems: "center",
                          padding: "0 10px",
                          fontSize: "13px",
                        }}
                      >
                        <FiUpload style={{ marginRight: "10px" }} />
                        Click to select and upload files from your computer
                      </div>
                    </label>
                  </div>
                  <div
                    className="top-box"
                    style={{ width: "70%", marginTop: "8px" }}
                  >
                    <p className="light-para-txt">
                      Supported image formats: jpeg, .png, .webp
                    </p>
                    <p className="light-para-txt">
                      Maximum size per file: 2000 kb
                    </p>
                  </div>
                </div>
              </div>
            )}
          </Box>
        </Modal>
      </div>
      <div className="top-box">
        <div className="note-txt">
          <span>Note:</span>
          <p>
            {" "}
            Billboards will display each ad for approximately{" "}
            <b>10-15 seconds </b>in a continuous loop with a set interval
            between repetitions.
          </p>
        </div>
        <div
          className="doneButton-box"
          style={{
            float: "right",
          }}
        >
          {/* <button className="border-btn" onClick={() => window.location.replace("/home/campaigns")}>
          Cancel
        </button> */}
          <button
            className="purple-btn width-adjust"
            // onClick={sendDataToParent}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  videoSource: state.user.videoSource,
  videoArray: state.user.videoArray,
  videoDuration: state.user.videoDuration,
  videoName: state.user.videoName,
  imageList: state.user.imageList,
  imageArray: state.user.imageArray,
});

const mapDispatchToProps = {
  setVideoSource,
  setVideoArray,
  setVideoDuration,
  setVideoFile,
  setVideoUrl,
  setImageArray,
  setImageList,
  setVideoName,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCreatives);
