import React, { FC, useEffect, useState } from 'react';
import '../../CSS/payments.css';
import { Appearance, StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import {
    Elements,
} from '@stripe/react-stripe-js';
import PaymentForm from './paymentFile';
import { useLocation } from 'react-router-dom';
import { stripeKey } from '../../Constant';

const appearance: Appearance = {
    theme: 'stripe', // Can be 'stripe', 'flat', 'night', etc.
    labels: "floating",
    variables: {
        // colorPrimary: '#0570de',
        colorBackground: '#ffffff',
        colorText: '#333',
        borderRadius: "0px",
        focusBoxShadow: "none",
        focusOutline: "none",
        colorTextSecondary: "#333333"
        // Add more customization as needed
    },
    rules: {
        '.Label': {
            fontSize: "5px",
            color: 'rgba(51, 51, 51, 0.9)',            
        },
        '.Input': {
            border: "1.4px solid rgba(51, 51, 51, 0.3)",
            padding: "5px",
            borderRadius: "2px",
        },
        '.Input:focus': {
            border: "1.4px solid rgba(51, 51, 51, 0.3)",
            padding: "5px",
        }
    },
};

const elementsOptions: StripeElementsOptions = {
    appearance,
};

const AddFundsScreen = () => {

    const [budget, setBudget] = useState<any>("")
    const [campaignPublish, setCampaignPublish] = useState(false)
    const [paymentType, setPaymentType] = useState("")
    const location = useLocation()

    const stripePromise = loadStripe(stripeKey);

    console.log("Testing Project")

    useEffect(() => {
        if (location) {
            if (location.state) {
                setBudget(location.state.amount)
                setCampaignPublish(location.state.isCampaignPublished)
                setPaymentType(location.state.paymentType)
            }
        }
    }, [location])

    return (
        <div>
            <Elements stripe={stripePromise} options={elementsOptions}>
                <PaymentForm budgetProp={budget} campaignPublish={campaignPublish} paymentType={paymentType} />
            </Elements>
        </div>
    );
}

export default AddFundsScreen;