import React, { useState, FC } from "react";
import leftSideBG from "../../IMAGE/leftSideBG.svg";
import rightSideBG from "../../IMAGE/rightSideBG.svg";
import logo from "../../IMAGE/logo.svg";
import "../../CSS/SignIn.css";
import { BiSolidErrorCircle } from "react-icons/bi";
import axios from 'axios';
import { MdKeyboardBackspace } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { apiURL } from "../../Constant";
const CryptoJS = require('crypto-js')

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const ForgotPassword : FC = () => {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");

  const navigate = useNavigate();
  const location = useLocation();

  // const [showPassword, setShowPassword] = useState<boolean>(false);

  // const togglePasswordVisibility = () => {
  //   setShowPassword(!showPassword);
  // };

  const handleChange = (e: any) => {
    let value = e.target.value;
    switch (e.target.name) {
      case "email":
        setError("")
        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!(emailRegex.test(value)) || value == "" || value == null || value.length == 0) {
          setError("Invalid Email or Password")
        } else {
          setEmail(value.toLowerCase());
        }
        break;
      default:
        break;
    }
  }
  
  const nextPage = async () =>  {
    if (email == "") {
      setError("Invalid Email");
    } else {
      try {
        let jsonParams = {
          UserEmailID: email.toLowerCase(),
          ForgotPassKey: "1234"
        };
        const secretKey = "Adserving123";
        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(jsonParams),
          secretKey
        ).toString();
        const params = {
          params:encryptedData
        }

        const response = await axios.post(`${apiURL}/api/sendResetMail`, params);
        // const bytes = CryptoJS.AES.decrypt(response.data.data, secretKey);
        // const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        // const parsedData = JSON.parse(decryptedData);
        if (response.data.status == true) {
          // const bytes = CryptoJS.AES.decrypt(response.data.data, secretKey);
          // const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
          // const parsedData = JSON.parse(decryptedData);
          navigate("/emailCheck", {state: {
            email: email
          }})      
          } 
      } catch (error) {
        console.log(error);
      } 
    }
  }

  return (
    <div className="signIn-main">
      <div className="left-img-div">
        <img src={leftSideBG} alt="leftSide" />
      </div>
      <div className="center-div">
        <img className="admattic-logo" src={logo} alt="logo" />

        <div className="mid-box">
          <h2>FORGOT PASSWORD</h2>
          {error != "" && error != null && (<div className="error-box" >

            <BiSolidErrorCircle className="error-icon" />
            <p>Please enter a valid email</p>
          </div>)}

          <div className="details-box">
            <div className="email-box">
              <label htmlFor="email">Enter Email</label>
              <input type="text" name="email" onChange={handleChange} defaultValue={email}/>
            </div>
          </div>
          <button className="signIn-btn proceed-btn" onClick={nextPage}>Proceed</button>

        </div>
        <a href="/" className="signup-box back" >
          <MdKeyboardBackspace/>&nbsp;
          <p>Back to sign in</p>
        </a>
      </div>
      <div className="right-img-div">
        <img src={rightSideBG} alt="rightSide" />
      </div>
    </div>
  );
};

export default ForgotPassword;
