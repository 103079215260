// counterActions.ts
import { INCREMENT, DECREMENT, CounterActionTypes, COUNT } from './counterActions';

export const increment = (): CounterActionTypes => ({
  type: INCREMENT,
});

export const decrement = (): CounterActionTypes => ({
  type: DECREMENT,
});

export const setCount = (value: number):CounterActionTypes =>({
  type:COUNT,
  payload:value
})

