// counterActions.ts
export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';
export const COUNT = 'COUNT';
interface IncrementAction {
  type: typeof INCREMENT;
}

interface DecrementAction {
  type: typeof DECREMENT;
}

interface SetCount  {
  type:typeof COUNT;
  payload: number;
}

export type CounterActionTypes = SetCount | IncrementAction | DecrementAction;
