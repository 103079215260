import React, { FC, useEffect, useState } from "react";
import { Navbaritem } from "../../../models/Navbaritem";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "@mui/material";

type NavbarLinkProps = {
    item: Navbaritem
}

const NavbarLink = styled(Link)`
   display: flex;
   align-items: center;
   font-size: 14px;
   color: #333333;
   font-weight: 600;
   text-decoration: none;
`;

const NavbarBottomLink = styled(Link)`
   display: flex;
   align-items: center;
   font-size: 14px;
   font-weight: 600;
   color: #999999;
   text-decoration: none;
`;

const NavIcon = styled.div`
   display: flex;
   width: 40px;
   padding: 10px 0px;
`;

const activeStyle = {
    color: "#FF0047"
}

export const NavbarMenu: FC<NavbarLinkProps> = ({ item }) => {
    const [activeLink, setActiveLink] = useState('');
    const location = useLocation();

    useEffect(() => {
        setActiveLink(location.pathname.includes('/admin') ? location.pathname.replace('/home/admin/', '')
            : location.pathname.replace('/home/', ''));
    }, [location.pathname]);

    return (
        <NavbarLink to={item.path} style={activeLink.includes(item.path.includes('/admin') ? item.path.replace('/home/admin/', '')
            : item.path.replace('/home/', '')) ? activeStyle : {}}>
            {activeLink.includes(item.path.includes('/admin') ? item.path.replace('/home/admin/', '')
                : item.path.replace('/home/', '')) ? <NavIcon>{item.activeIcon}</NavIcon> : <NavIcon>{item.icon}</NavIcon>}
            {item.title}
        </NavbarLink>
    );
}

export const NavbarBottomMenu: FC<NavbarLinkProps> = ({ item }) => {
    const [activeLink, setActiveLink] = useState('');
    const location = useLocation();

    useEffect(() => {
        setActiveLink(location.pathname);
    }, [location.pathname]);
    return (
        <NavbarBottomLink to={item.path} style={activeLink.includes(item.path) ? activeStyle : {}}>
            {activeLink.includes(item.path) ? <NavIcon>{item.activeIcon}</NavIcon> : <NavIcon>{item.icon}</NavIcon>}
            {item.title}
        </NavbarBottomLink>
    );
}