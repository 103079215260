import React, { FC, useState, useEffect } from "react";
import "../../CSS/CreateCampaign.css";
import "../../CSS/SignIn.css";
import { FiChevronDown } from "react-icons/fi";
import { focusValuesDpd, trackValuesDpd } from "../../Constant";
import Checkbox from "@mui/material/Checkbox";
import { TiInfoLarge } from "react-icons/ti";
import { BiSolidErrorCircle } from "react-icons/bi";
import { getTargetingTypesMaster } from "./../MasterDataFile";
import { connect, useDispatch } from "react-redux";
import { RootState } from "../../REDUX/Reducers/rootReducer";
import {
  setBudget,
  setFocus,
  setTarget,
  setCostPerInstall,
  setMasterCountryArray,
  setTransactionCurrency,
  setTempTransactionCurrency,
} from "../../REDUX/Actions/userActionIndex";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

interface customButtonProps {
  onClick?: (data: boolean) => void;
  onBackClick?: () => void;
  budget: string;
  target: string;
  focus: string;
  costPerInstall: string;
  masterCountryArray: any;
  countryCode: string;
  transactionCurrency: string;
  locationCode: string;
  currencySymbol: string;
  countryCodeAdvertiser: string;
  appPlatform: string;
  campaignSubtype: string;
  tempTransactionCurrency: string;
}

const BudgetBinding: React.FC<customButtonProps> = ({
  onClick,
  onBackClick,
  budget,
  focus,
  target,
  costPerInstall,
  campaignSubtype,
  masterCountryArray,
  countryCode,
  transactionCurrency,
  tempTransactionCurrency,
  locationCode,
  currencySymbol,
  countryCodeAdvertiser,
  appPlatform,
}) => {
  // const { onClick, onBackClick } = props;

  const dispatch = useDispatch();
  // const [budget, setBudget] = useState<string>("");
  // const [focusDpd, setFocusDpd] = useState<string>("");
  const [trackDpd, setTrackDpd] = useState<string>("");
  const [chkTarget, setChkTarget] = useState<boolean>(false);
  // const [costPerInstall, setCostPerInstall] = useState<string>("");
  // const [showFocus, setFocus] = useState(true);
  const [showFocus, setFocusShow] = useState(true);

  const [showtrack, setTrack] = useState(true);
  const [currency, setCurrency] = useState<any>(
    localStorage.getItem("currency")
  );
  const [dailyTargetDisabled, setDailyTargetDisabled] =
    useState<boolean>(false);

  const [budgetError, setBudgetError] = useState<string>("");
  const [focusDpdError, setFocusDpdError] = useState<string>("");
  const [trackDpdError, setTrackDpdError] = useState<string>("");
  const [costPerInstallError, setCostPerInstallError] = useState<string>("");
  const [budgetLimit, setBudgetLimit] = useState<number>(0);

  // const [targetApp, setTargetApp] = useState<string>("");
  const [targetDpdError, settargetDpdError] = useState<string>("");
  const [targetAppDpd, setTargetAppDpd] = useState<any>([]);
  const [showTarget, setShowTarget] = useState<boolean>(true);
  const [checkSymbol, setcheckSymbol] = useState<string>();
  const [userCurrencyCCode, setUserCurrencyCode] = useState<string>();

  const [disableFocus, setDisableFocus] = useState<boolean>(false);

  const onChangeTargetApp = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    localStorage.setItem("targetApp", value);
    dispatch(setTarget(value));
    setShowTarget(false);
    settargetDpdError("");
    setDailyTargetDisabled(false);
  };

  useEffect(() => {
    if (transactionCurrency != null && transactionCurrency != "") {
      checkTransactionCurrency(transactionCurrency);
    }
  }, [target, focus]);

  const checkTransactionCurrency = (value: any) => {
    dispatch(setTransactionCurrency(value));
    handleCurrency(value);

    let targetingArray = [];
    targetingArray = getTargetingTypesMaster();
    if (targetingArray && Array.isArray(targetingArray)) {
      let arr: any = [];
      // value == "USD"
      //   ? targetingArray.filter((val: any) => {
      //       return val.Country.indexOf(locationCode) != -1;
      //     })
      //   : targetingArray.filter((val: any) => {
      //       return val.Country.indexOf("IN-USD") != -1;
      //     });
      if (value == "USD") {
        if (countryCodeAdvertiser == "IN" && locationCode == "IN") {
          arr = targetingArray.filter((val: any) => {
            return (
              val.Country.indexOf(`${countryCodeAdvertiser}-${value}`) != -1
            );
          });
        } else if (countryCodeAdvertiser == "IN" && locationCode != "IN") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(locationCode) != -1;
          });
        } else if (countryCodeAdvertiser != "IN" && locationCode == "IN") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(`${locationCode}-${value}`) != -1;
          });
        } else {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(locationCode) != -1;
          });
        }
      } else {
        if (countryCodeAdvertiser == "IN" && locationCode != "IN") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(`${locationCode}-INR`) != -1;
          });
        } else if (countryCodeAdvertiser == "IN") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(locationCode) != -1;
          });
        } else if(countryCodeAdvertiser == ""){
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(`${locationCode}-INR`) != -1;
          });
        }
          else {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(`${countryCodeAdvertiser}-INR`) != -1;
          });
        }
      }
      console.log("countryCodeAdvertiser", arr);
      setTargetAppDpd(arr);
      if (target != null && focus != null && target != "" && focus != "") {
        setCostPerInstallError("");
        // dispatch(setCostPerInstall(value));
        const minVal = arr.filter((item: any) => item.TargetingType == target);

        // console.log(minVal[0].InstallVolume);
        // console.log(minVal[0].IOS_USD,appPlat)
        console.log("minVal", minVal);
        if (focus == "") {
          setFocusDpdError("Please select a value");
        } else if (target == "") {
          settargetDpdError("Please select a value");
        } else if (minVal.length != 0) {
          // let temp = ""
          // if (currenctCode != "INR") {
          // console.log(focus == "appActions", focus == "installVolume");
          if (focus == "installVolume") {
            if (appPlatform == "Android") {
              if (
                minVal[0].InstallVolume.AOS != "" &&
                minVal[0].InstallVolume.AOS > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InstallVolume.AOS}`
                );
              } else {
                setCostPerInstallError("");
              }
            } else if (appPlatform == "APK") {
              if (
                minVal[0].InstallVolume.APK != "" &&
                minVal[0].InstallVolume.APK > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InstallVolume.APK}`
                );
              } else {
                setCostPerInstallError("");
              }
            } else {
              if (
                minVal[0].InstallVolume.IOS != "" &&
                minVal[0].InstallVolume.IOS > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InstallVolume.IOS}`
                );
              } else {
                setCostPerInstallError("");
              }
            }
          } else {
            if (appPlatform == "Android") {
              if (
                minVal[0].InAppBidding.AOS != "" &&
                minVal[0].InAppBidding.AOS > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InAppBidding.AOS}`
                );
              } else {
                setCostPerInstallError("");
              }
            } else if (appPlatform == "APK") {
              if (
                minVal[0].InAppBidding.APK != "" &&
                minVal[0].InAppBidding.APK > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InAppBidding.APK}`
                );
              } else {
                setCostPerInstallError("");
              }
            } else {
              if (
                minVal[0].InAppBidding.IOS != "" &&
                minVal[0].InAppBidding.IOS > Number(costPerInstall)
              ) {
                setCostPerInstallError(
                  `Please enter minimum amount ${minVal[0].InAppBidding.IOS}`
                );
              } else {
                setCostPerInstallError("");
              }
            }
          }
        }
      }
      setTargetAppDpd(arr);
    }
  };

  const onChangeTransactionCurrency = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = event.target.value;
    dispatch(setTransactionCurrency(value));
    dispatch(setTempTransactionCurrency(value));
    dispatch(setBudget(""));
    dispatch(setCostPerInstall(""));
    handleCurrency(value);
    checkTransactionCurrency(value);
    let targetingArray = [];
    targetingArray = getTargetingTypesMaster();
    if (targetingArray && Array.isArray(targetingArray)) {
      let arr: any = [];
      if (value == "USD") {
        console.log("here");
        if (countryCodeAdvertiser == "IN" && locationCode == "IN") {
          arr = targetingArray.filter((val: any) => {
            return (
              val.Country.indexOf(`${countryCodeAdvertiser}-${value}`) != -1
            );
          });
        } else if (countryCodeAdvertiser == "IN" && locationCode != "IN") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(locationCode) != -1;
          });
        } else if (countryCodeAdvertiser != "IN" && locationCode == "IN") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(`${locationCode}-${value}`) != -1;
          });
        } else {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(countryCodeAdvertiser) != -1;
          });
        }
      } else {
        if (countryCodeAdvertiser == "IN" && locationCode != "IN") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(`${locationCode}-INR`) != -1;
          });
        } else if (countryCodeAdvertiser == "IN") {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(locationCode) != -1;
          });
        }else if(countryCodeAdvertiser == ""){
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(`${locationCode}-INR`) != -1;
          });
        } 
        else {
          arr = targetingArray.filter((val: any) => {
            return val.Country.indexOf(`${countryCodeAdvertiser}-INR`) != -1;
          });
        }
      }

      setTargetAppDpd(arr);
      onCheckCostPerInstall("", arr);
    }
  };

  const errorHandling = () => {
    const item = localStorage.getItem("editingData") || "";
    if (item != "" && item != "undefined") {
      const data = JSON.parse(item);
      // console.log("CampaignInfo", data);
    }
    let message = false;
    if (budget != null && budget == "" && item == "") {
      setBudgetError("Please enter budget.");
    } else if (focus != null && focus == "") {
      setFocusDpdError("Please enter a value.");
    }
    // else if (trackDpd != null && trackDpd == "") {
    //   setTrackDpdError("Please enter a value.");
    // }
    else if (target != null && target == "") {
      settargetDpdError("Please enter a value.");
    } else if (
      (costPerInstall != null && costPerInstall == "" && item == "") ||
      costPerInstallError != ""
    ) {
      setCostPerInstallError("Please enter a minimum cost.");
    } else {
      message = true;
      if (onClick) {
        onClick(message);
      }
    }
  };

  useEffect(() => {
    if (campaignSubtype === "App Install") {
      dispatch(setFocus("installVolume"));
      setFocusShow(false);
      setDisableFocus(true);
    } else if (campaignSubtype === "App Engagement") {
      dispatch(setFocus("appActions"));
      setFocusShow(false);
      setDisableFocus(true);
    }
  }, []);

  useEffect(() => {
    if (masterCountryArray.length > 0) {
      const arr =
        locationCode == "ALL"
          ? masterCountryArray.filter((value: any) => {
              return value.CountryCode == "US";
            })
          : masterCountryArray.filter((value: any) => {
              return value.CountryCode == locationCode;
            });
      // console.log("currencySymbol", arr[0].CurrencySymbol != currencySymbol);
      console.log("arr", arr);

      if (arr.length > 0) {
        setBudgetLimit(arr[0].BudgetLimit);
      }
      let targetingArray = [];
      targetingArray = getTargetingTypesMaster();
      if (targetingArray && Array.isArray(targetingArray)) {
        console.log(arr[0].Code);
        // setTargetAppDpd(targetingArray);
        setcheckSymbol(arr[0].CurrencySymbol);
        if (tempTransactionCurrency != null && tempTransactionCurrency != "") {
          checkTransactionCurrency(tempTransactionCurrency);
        } else if (transactionCurrency != null && transactionCurrency != "") {
          checkTransactionCurrency(transactionCurrency);
        } else {
          if (currencySymbol == "₹" && arr[0].CurrencySymbol == "$") {
            if (arr.length > 0) {
              const targetValue = targetingArray.filter((value: any) => {
                return value.Country.indexOf("IN-USD") != -1;
              });
              console.log(targetingArray);
              if (targetValue.length > 0) {
                setTargetAppDpd(targetValue);
              } else if (arr[0].Code == "USD") {
                const targetValue = targetingArray.filter((value: any) => {
                  return value.Country.indexOf(locationCode) != -1;
                });
                if (targetValue.length > 0) {
                  setTargetAppDpd(targetValue);
                }
              } else {
                const targetValue = targetingArray.filter((value: any) => {
                  return (
                    value.Country.indexOf(`${countryCodeAdvertiser}-INR`) != -1
                  );
                });
                if (targetValue.length > 0) {
                  setTargetAppDpd(targetValue);
                }
              }
            }
          } else if (currencySymbol == "$" && arr[0].CurrencySymbol == "₹") {
            if (arr.length > 0) {
              const targetValue = targetingArray.filter((value: any) => {
                return value.Country.indexOf("US-INR") != -1;
              });
              if (targetValue.length > 0) {
                setTargetAppDpd(targetValue);
              }
            }
          } else {
            if (arr.length > 0) {
              const targetValue = targetingArray.filter((value: any) => {
                return value.Country.indexOf(arr[0].CountryCode) != -1;
              });
              console.log(targetValue, "targetValue", arr, "arr");
              if (targetValue.length > 0) {
                setTargetAppDpd(targetValue);
              } else if (arr[0].Code == "USD") {
                const targetValue = targetingArray.filter((value: any) => {
                  return value.Country.indexOf("US") != -1;
                });
                if (targetValue.length > 0) {
                  setTargetAppDpd(targetValue);
                }
              } else {
                const targetValue = targetingArray.filter((value: any) => {
                  return value.Country.indexOf("IN") != -1;
                });
                if (targetValue.length > 0) {
                  setTargetAppDpd(targetValue);
                }
              }
            }
          }
        }
      }
    }
    // if (target == "true") {
    //   setChkTarget(true);
    // } else {
    //   setChkTarget(false);
    // }
    const budgetLimi = localStorage.getItem("budgetLimit") || "0";
    // console.log(budgetLimi);
    if (budgetLimi == "null") {
      setBudgetLimit(0);
    }
    // console.log(budgetLimi)

    // const item = localStorage.getItem("editingData") || "";
    // if (item != "" && item != "undefined") {
    //   // const data = JSON.parse(item);
    //   // console.log("createCampaign", data);
    //   setDailyTargetDisabled(true);
    // } else {
    //   setDailyTargetDisabled(false);
    // }
    // const campData = localStorage.getItem("editingData") || "";
    // if (campData != null && campData != "" && campData != undefined) {
    //   const data = JSON.parse(campData);
    //   console.log("createCampaign", Object.keys(data.budgetData).length === 0);
    //   if (Object.keys(data.budgetData).length !== 0) {
    //     dispatch(
    //       setBudget(budget == "" ? data.budgetData.dailyAvgBudget : budget)
    //     );
    //     dispatch(
    //       setFocus(focusDpd == "" ? data.budgetData.focusParam : focusDpd)
    //     );
    //     dispatch(
    //       setTarget(targetApp == "" ? data.budgetData.targetingType : targetApp)
    //     );
    //     dispatch(
    //       setCostPerInstall(
    //         installationCost == ""
    //           ? data.budgetData.targetCostPerInstall
    //           : installationCost
    //       )
    //     );
    //     // (data.budgetData.dailyAvgBudget)
    //     localStorage.setItem(
    //       "budget",
    //       budget == "" ? data.budgetData.dailyAvgBudget : budget
    //     );
    //     localStorage.setItem(
    //       "focusDpd",
    //       focusDpd == "" ? data.budgetData.focusParam : focusDpd
    //     );
    //     localStorage.setItem(
    //       "targetApp",
    //       targetApp == "" ? data.budgetData.targetingType : targetApp
    //     );
    //     localStorage.setItem(
    //       "costPerInstall",
    //       installationCost == ""
    //         ? data.budgetData.targetCostPerInstall
    //         : installationCost
    //     );
    //   }
    // }
  }, []);

  const handleCurrency = (label: string) => {
    let countryArray = [];
    countryArray = masterCountryArray;
    if (countryArray && Array.isArray(countryArray)) {
      let countryFind = countryArray.find((element: any) => {
        return label === element.Code;
      });

      // setFindCountry(countryFind)
      // console.log(countryFind)
      if (countryFind != undefined) {
        // localStorage.setItem("currency", countryFind.CurrencySymbol);
        // localStorage.setItem("countryCode", countryFind.Code)
        // localStorage.setItem("budgetLimit", countryFind.BudgetLimit)
        // console.log(countryFind);

        setBudgetLimit(countryFind.BudgetLimit);

        if (Number(budget) < countryFind.BudgetLimit) {
          setBudgetError(
            `Please enter minimum value ${countryFind.BudgetLimit}`
          );
        } else {
          setBudgetError("");
        }
        // dispatch(setCurrencySymbol(countryFind.CurrencySymbol))
        // dispatch(setCountryCode(countryFind.Code))
        // setCurrency(countryFind.CurrencySymbol)
      }
    }
  };

  const onChangeBudget = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    dispatch(setBudget(value));
    // console.log(budget);
    // localStorage.setItem("budget", value);

    if (Number(value) < budgetLimit) {
      setBudgetError(`Please enter minimum value ${budgetLimit}`);
    } else {
      setBudgetError("");
    }
  };

  const onCheckTarget = (event: React.ChangeEvent<HTMLInputElement>) => {
    let check = event.target.checked;
    setChkTarget(check);
    setTrack(false);
    // localStorage.setItem("targetCheck", check.toString());
  };

  const onChangefocusDpd = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let value = event.target.value;
    dispatch(setFocus(value));
    setFocusShow(false);
    setFocusDpdError("");
    // localStorage.setItem("focusDpd", value);
  };

  const onChangeTrackDpd = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let value = event.target.value;
    setTrackDpd(value);
    setTrackDpdError("");
    localStorage.setItem("trackDpd", value);
  };

  const checkCostPerInstall = (value: string) => {
    setCostPerInstallError("");
    // dispatch(setCostPerInstall(value));
    const minVal = targetAppDpd.filter(
      (item: any) => item.TargetingType == value
    );

    // console.log(minVal[0].InstallVolume);
    // console.log(minVal[0].IOS_USD,appPlat)
    console.log("minVal", minVal);
    if (focus == "") {
      setFocusDpdError("Please select a value");
    } else if (target == "") {
      settargetDpdError("Please select a value");
    } else if (minVal.length != 0) {
      // let temp = ""
      // if (currenctCode != "INR") {
      // console.log(focus == "appActions", focus == "installVolume");
      if (focus == "installVolume") {
        if (appPlatform == "Android") {
          if (
            minVal[0].InstallVolume.AOS != "" &&
            minVal[0].InstallVolume.AOS > Number(costPerInstall)
          ) {
            setCostPerInstallError(
              `Please enter minimum amount ${minVal[0].InstallVolume.AOS}`
            );
          } else {
            setCostPerInstallError("");
          }
        } else if (appPlatform == "APK") {
          if (
            minVal[0].InstallVolume.APK != "" &&
            minVal[0].InstallVolume.APK > Number(costPerInstall)
          ) {
            setCostPerInstallError(
              `Please enter minimum amount ${minVal[0].InstallVolume.APK}`
            );
          } else {
            setCostPerInstallError("");
          }
        } else {
          if (
            minVal[0].InstallVolume.IOS != "" &&
            minVal[0].InstallVolume.IOS > Number(costPerInstall)
          ) {
            setCostPerInstallError(
              `Please enter minimum amount ${minVal[0].InstallVolume.IOS}`
            );
          } else {
            setCostPerInstallError("");
          }
        }
      } else {
        if (appPlatform == "Android") {
          if (
            minVal[0].InAppBidding.AOS != "" &&
            minVal[0].InAppBidding.AOS > Number(costPerInstall)
          ) {
            setCostPerInstallError(
              `Please enter minimum amount ${minVal[0].InAppBidding.AOS}`
            );
          } else {
            setCostPerInstallError("");
          }
        } else if (appPlatform == "APK") {
          if (
            minVal[0].InAppBidding.APK != "" &&
            minVal[0].InAppBidding.APK > Number(costPerInstall)
          ) {
            setCostPerInstallError(
              `Please enter minimum amount ${minVal[0].InAppBidding.APK}`
            );
          } else {
            setCostPerInstallError("");
          }
        } else {
          if (
            minVal[0].InAppBidding.IOS != "" &&
            minVal[0].InAppBidding.IOS > Number(costPerInstall)
          ) {
            setCostPerInstallError(
              `Please enter minimum amount ${minVal[0].InAppBidding.IOS}`
            );
          } else {
            setCostPerInstallError("");
          }
        }
      }
    }
    // localStorage.setItem("costPerInstall", value);
  };

  const onCheckCostPerInstall = (value: string, arr: any) => {
    setCostPerInstallError("");
    dispatch(setCostPerInstall(value));
    const minVal = arr.filter((item: any) => item.TargetingType == target);

    // console.log(minVal[0].InstallVolume);
    // console.log(minVal[0].IOS_USD,appPlat)
    console.log("minVal", minVal);
    if (focus == "") {
      setFocusDpdError("Please select a value");
    } else if (target == "") {
      settargetDpdError("Please select a value");
    } else if (minVal.length != 0) {
      // let temp = ""
      // if (currenctCode != "INR") {
      console.log(focus == "appActions", focus == "installVolume");
      if (focus == "installVolume") {
        if (appPlatform == "Android") {
          if (
            minVal[0].InstallVolume.AOS != "" &&
            minVal[0].InstallVolume.AOS > Number(value)
          ) {
            setCostPerInstallError(
              `Please enter minimum amount ${minVal[0].InstallVolume.AOS}`
            );
          } else {
            setCostPerInstallError("");
          }
        } else if (appPlatform == "APK") {
          if (
            minVal[0].InstallVolume.APK != "" &&
            minVal[0].InstallVolume.APK > Number(value)
          ) {
            setCostPerInstallError(
              `Please enter minimum amount ${minVal[0].InstallVolume.APK}`
            );
          } else {
            setCostPerInstallError("");
          }
        } else {
          if (
            minVal[0].InstallVolume.IOS != "" &&
            minVal[0].InstallVolume.IOS > Number(value)
          ) {
            setCostPerInstallError(
              `Please enter minimum amount ${minVal[0].InstallVolume.IOS}`
            );
          } else {
            setCostPerInstallError("");
          }
        }
      } else {
        if (appPlatform == "Android") {
          if (
            minVal[0].InAppBidding.AOS != "" &&
            minVal[0].InAppBidding.AOS > Number(value)
          ) {
            setCostPerInstallError(
              `Please enter minimum amount ${minVal[0].InAppBidding.AOS}`
            );
          } else {
            setCostPerInstallError("");
          }
        } else if (appPlatform == "APK") {
          if (
            minVal[0].InAppBidding.APK != "" &&
            minVal[0].InAppBidding.APK > Number(value)
          ) {
            setCostPerInstallError(
              `Please enter minimum amount ${minVal[0].InAppBidding.APK}`
            );
          } else {
            setCostPerInstallError("");
          }
        } else {
          if (
            minVal[0].InAppBidding.IOS != "" &&
            minVal[0].InAppBidding.IOS > Number(value)
          ) {
            setCostPerInstallError(
              `Please enter minimum amount ${minVal[0].InAppBidding.IOS}`
            );
          } else {
            setCostPerInstallError("");
          }
        }
      }
    }
  };

  const onChangeCostPerInstall = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const appPlat = localStorage.getItem("appPlatform");
    const currenctCode = localStorage.getItem("countryCode");
    let value = event.target.value;
    setCostPerInstallError("");
    dispatch(setCostPerInstall(value));
    const minVal = targetAppDpd.filter(
      (item: any) => item.TargetingType == target
    );

    // console.log(minVal[0].InstallVolume);
    // console.log(minVal[0].IOS_USD,appPlat)
    console.log("minVal", minVal);
    if (focus == "") {
      setFocusDpdError("Please select a value");
    } else if (target == "") {
      settargetDpdError("Please select a value");
    } else if (minVal.length != 0) {
      // let temp = ""
      // if (currenctCode != "INR") {
      console.log(focus == "appActions", focus == "installVolume");
      if (focus == "installVolume") {
        if (appPlatform == "Android") {
          if (
            minVal[0].InstallVolume.AOS != "" &&
            minVal[0].InstallVolume.AOS > Number(value)
          ) {
            setCostPerInstallError(
              `Please enter minimum amount ${minVal[0].InstallVolume.AOS}`
            );
          } else {
            setCostPerInstallError("");
          }
        } else if (appPlatform == "APK") {
          if (
            minVal[0].InstallVolume.APK != "" &&
            minVal[0].InstallVolume.APK > Number(value)
          ) {
            setCostPerInstallError(
              `Please enter minimum amount ${minVal[0].InstallVolume.APK}`
            );
          } else {
            setCostPerInstallError("");
          }
        } else {
          if (
            minVal[0].InstallVolume.IOS != "" &&
            minVal[0].InstallVolume.IOS > Number(value)
          ) {
            setCostPerInstallError(
              `Please enter minimum amount ${minVal[0].InstallVolume.IOS}`
            );
          } else {
            setCostPerInstallError("");
          }
        }
      } else {
        if (appPlatform == "Android") {
          if (
            minVal[0].InAppBidding.AOS != "" &&
            minVal[0].InAppBidding.AOS > Number(value)
          ) {
            setCostPerInstallError(
              `Please enter minimum amount ${minVal[0].InAppBidding.AOS}`
            );
          } else {
            setCostPerInstallError("");
          }
        } else if (appPlatform == "APK") {
          if (
            minVal[0].InAppBidding.APK != "" &&
            minVal[0].InAppBidding.APK > Number(value)
          ) {
            setCostPerInstallError(
              `Please enter minimum amount ${minVal[0].InAppBidding.APK}`
            );
          } else {
            setCostPerInstallError("");
          }
        } else {
          if (
            minVal[0].InAppBidding.IOS != "" &&
            minVal[0].InAppBidding.IOS > Number(value)
          ) {
            setCostPerInstallError(
              `Please enter minimum amount ${minVal[0].InAppBidding.IOS}`
            );
          } else {
            setCostPerInstallError("");
          }
        }
      }

      // }
      // else {
      //   if (appPlat == "Android") {
      //     if (minVal[0].AOS > Number(value)) {
      //       setCostPerInstallError(
      //         `Please enter minimum amount ${minVal[0].AOS}`
      //       );
      //     } else {
      //       setCostPerInstallError("");
      //     }
      //   } else if (appPlat == "APK") {
      //     if (minVal[0].APK > Number(value) && minVal[0].APK != null) {
      //       setCostPerInstallError(
      //         `Please enter minimum amount ${minVal[0].APK}`
      //       );
      //     } else {
      //       setCostPerInstallError("");
      //     }
      //   } else {
      //     if (minVal[0].IOS > Number(value)) {
      //       setCostPerInstallError(
      //         `Please enter minimum amount ${minVal[0].IOS}`
      //       );
      //     } else {
      //       setCostPerInstallError("");
      //     }
      //   }
      // }
    }
    localStorage.setItem("costPerInstall", value);
  };

  return (
    <div>
      <div className="campaign-box">
        <h4 className="budget-heading">Budget</h4>
        {checkSymbol != currencySymbol && (
          <div>
            <p className="dpd-heading">Select Campaign Transaction Currency</p>
            <div className="hide-button" style={{ width: "50vh" }}>
              <select
                style={{
                  position: "relative",
                  width: "100%",
                  height: "38px",
                  borderRadius: "3px",
                }}
                value={transactionCurrency}
                onChange={onChangeTransactionCurrency}
              >
                {transactionCurrency ? (
                  <option>Please select a value</option>
                ) : null}
                <option key={1} value={"INR"}>
                  INR
                </option>
                <option key={2} value={"USD"}>
                  USD
                </option>
                {/* {targetAppDpd
                .map((item: any, index: number) => (
              <option key={index} value={item.TargetingType}>
                {item.TargetingType}
              </option>
            ))} */}
              </select>
              <FiChevronDown
                className="down-icon"
                style={{ top: "11px", right: "15px" }}
              />
            </div>
          </div>
        )}

        <p className="budget-para">
          Select your average daily budget for this campaign
        </p>
        <div className="budget-input">
          {/* <p>{currency}</p> */}
          {transactionCurrency === "USD" ? <p>&#36;</p> : <p> &#8377; </p>}
          <input
            type="text"
            className="small"
            value={budget}
            onChange={onChangeBudget}
            // disabled={dailyTargetDisabled}
          />
        </div>
        <span className="very-small-text">
          Minimum daily budget{" "}
          {transactionCurrency === "USD" ? (
            <span>&#36;</span>
          ) : (
            <span> &#8377; </span>
          )}{" "}
          {budgetLimit}
        </span>
        {budgetError != "" ? (
          <div className="error-box" style={{ width: "50%", margin: 0 }}>
            <BiSolidErrorCircle className="error-icon" />
            <p>{budgetError}</p>
          </div>
        ) : null}
      </div>

      <div className="campaign-box">
        <h4 className="budget-heading">Bidding</h4>
        <p className="dpd-heading">What do you want to focus on ?</p>
        <div className="hide-button" style={{ width: "50vh" }}>
          <select
            style={{
              position: "relative",
              width: "100%",
              height: "38px",
              borderRadius: "3px",
            }}
            value={focus}
            onChange={onChangefocusDpd}
            disabled={disableFocus}
          >
            {showFocus ? <option>Please select a value</option> : null}
            {focusValuesDpd.map((item, index) => (
              <option key={index} value={item.value}>
                {item.name}
              </option>
            ))}
          </select>
          <FiChevronDown
            className="down-icon"
            style={{ top: "11px", right: "15px" }}
          />
        </div>
        {focusDpdError != "" ? (
          <div className="error-box" style={{ width: "50%", margin: 0 }}>
            <BiSolidErrorCircle className="error-icon" />
            <p>{focusDpdError}</p>
          </div>
        ) : null}
        {/* <p className="dpd-heading">How do you want to track install volume ?</p>
        <div className="hide-button" style={{ width: "50vh" }}>
          <select
            style={{
              position: "relative",
              width: "100%",
              height: "38px",
              borderRadius: "3px",
            }}
            value={trackDpd}
            onChange={onChangeTrackDpd}
          >
            {showtrack ? <option>Please select a value</option> : null}
            {trackValuesDpd.map((item) => (
              <option value={item.value}>{item.name}</option>
            ))}
          </select>
          <FiChevronDown
            className="down-icon"
            style={{ top: "11px", right: "15px" }}
          />
        </div>
        {trackDpdError != "" ? (
          <div className="error-box" style={{ width: "50%", margin: 0 }}>
            <BiSolidErrorCircle className="error-icon" />
            <p>{trackDpdError}</p>
          </div>
        ) : null} */}

        <p className="dpd-heading">Select Category</p>
        <div className="hide-button" style={{ width: "50vh" }}>
          <select
            style={{
              position: "relative",
              width: "100%",
              height: "38px",
              borderRadius: "3px",
            }}
            value={target}
            onChange={onChangeTargetApp}
          >
            {showTarget ? <option>Please select a value</option> : null}
            {targetAppDpd.map((item: any, index: number) => (
              <option key={index} value={item.TargetingType}>
                {item.TargetingType}
              </option>
            ))}
          </select>
          <FiChevronDown
            className="down-icon"
            style={{ top: "11px", right: "15px" }}
          />
        </div>
        {targetDpdError != "" ? (
          <div className="error-box" style={{ width: "50%", margin: 0 }}>
            <BiSolidErrorCircle className="error-icon" />
            <p>{targetDpdError}</p>
          </div>
        ) : null}

        {/* <div className="check-container">
          <Checkbox
            {...label}
            className="chk"
            checked={chkTarget}
            onChange={onCheckTarget}
            
            style={{ color: customCheckBoxColor }}
          />
          <h3>Set a target coat per install (optional)</h3>
        </div> */}

        {/* <p className="dpd-heading">Select Campaign Transaction Currency</p>
        <div className="hide-button" style={{ width: "50vh" }}>
          <select
            style={{
              position: "relative",
              width: "100%",
              height: "38px",
              borderRadius: "3px",
            }}
            value={transactionCurrency}
            onChange={onChangeTransactionCurrency}
          >
            {transactionCurrency ? (
              <option>Please select a value</option>
            ) : null}
            <option key={1} value={"INR"}>
              INR
            </option>
            <option key={2} value={"USD"}>
              USD
            </option>
            {targetAppDpd.map((item: any, index: number) => (
              <option key={index} value={item.TargetingType}>
                {item.TargetingType}
              </option>
            ))}
          </select>
          <FiChevronDown
            className="down-icon"
            style={{ top: "11px", right: "15px" }}
          />
        </div> */}
        {/* {targetDpdError != "" ? (
          <div className="error-box" style={{ width: "50%", margin: 0 }}>
            <BiSolidErrorCircle className="error-icon" />
            <p>{targetDpdError}</p>
          </div>
        ) : null} */}

        <p className="budget-para" style={{ fontSize: "12px" }}>
          Target cost per install
        </p>
        <div className="budget-input">
          {transactionCurrency === "USD" ? <p>&#36;</p> : <p> &#8377; </p>}
          <input
            type="text"
            className="small"
            onChange={onChangeCostPerInstall}
            value={costPerInstall}
            disabled={dailyTargetDisabled}
          />
        </div>
        {costPerInstallError != "" ? (
          <div className="error-box" style={{ width: "50%", margin: 0 }}>
            <BiSolidErrorCircle className="error-icon" />
            <p>{costPerInstallError}</p>
          </div>
        ) : null}
        <div className="info-container">
          <TiInfoLarge className="info-icon" />
          <p>
            This campaign will use the Target CPA bid strategy to help you get
            the most
            <br /> conversions at or below your budget.
            <a>Learn more</a>
          </p>
        </div>
      </div>

      <div className="doneButton-box">
        <button
          className="border-btn"
          onClick={onBackClick}
          // style={{ visibility: "hidden" }}
        >
          Cancel
        </button>
        <button className="purple-btn width-adjust" onClick={errorHandling}>
          Next
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  budget: state.user.budget,
  focus: state.user.focus,
  target: state.user.target,
  costPerInstall: state.user.costPerInstall,
  masterCountryArray: state.user.masterCountryArray,
  countryCode: state.user.countryCode,
  transactionCurrency: state.user.transactionCurrency,
  locationCode: state.user.locationCode,
  currencySymbol: state.user.currencySymbol,
  countryCodeAdvertiser: state.user.countryCodeAdvertiser,
  appPlatform: state.user.appPlatform,
  campaignSubtype: state.user.campaignSubtype,
  tempTransactionCurrency: state.user.tempTransactionCurrency,
});

const mapDispatchToProps = {
  setBudget,
  setFocus,
  setTarget,
  setCostPerInstall,
  setMasterCountryArray,
  setTempTransactionCurrency,
};

export default connect(mapStateToProps, mapDispatchToProps)(BudgetBinding);
