import React, { useState, useCallback, FC, useEffect, useRef } from "react";
import ImageUploader from "react-images-upload";
import FileWithPreview from "react-images-upload";
import "../../CSS/Configure.css";
import { LuUpload } from "react-icons/lu";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  styled,
} from "@mui/material";
import edit from "../../IMAGE/edit.svg";
import add from "../../IMAGE/Add.svg";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { connect, useDispatch } from "react-redux";
import { setSelectedDateRange } from "../../REDUX/Actions/userActionIndex";
import { RootState } from "../../REDUX/Reducers/rootReducer";
import { DateRangePicker } from "react-date-range";
import whiteCalender from "../../IMAGE/whiteCalender.svg";

interface ConfigCampaign {
  selectedDateRange: any;
  onClick?: (data: boolean) => void;
  onBackClick?: () => void;
}

const ConfigureCampaign: FC<ConfigCampaign> = ({ selectedDateRange,  onClick, onBackClick }) => {
  const CustomSelect = styled(Select)({
    height: 35,

    "&.Mui-focused fieldset": {
      border: "1.4px solid rgba(51, 51, 51, 0.3)",
      outline: "none",
    },
    "& .MuiSelect-select:focus": {
      background: "transparent",
      border: "none",
      outline: "none",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1.4px solid rgba(51, 51, 51, 0.3)",
      outline: "none",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1.4px solid rgba(51, 51, 51, 0.3)",
      outline: "none",
    },
    "& MuiSelect": {
      // height: 10,
      paddingTop: 0,
      paddingBottom: 0,
      border: "none",
      outline: "none",
    },
    "& fieldset": {
      padding: 0,
      border: "1.4px solid rgba(51, 51, 51, 0.3)",
      outline: "none",
    },
  });
  const containerStyle: React.CSSProperties = {
    width: "100%",
    height: "300px",
  };

  const center = {
    lat: -3.745,
    lng: -38.523,
  };
  const [pictures, setPictures] = useState<any[]>([]);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [toggleCal, setToggleCal] = useState<string>("none");
  const [dateRange, setDateRange] = useState<any>({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(),
  });
  const boxRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (boxRef.current && !boxRef.current.contains(event.target as Node)) {
      setToggleCal("none");
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const startDate = localStorage.getItem("startDate")!;
    const endDate = localStorage.getItem("endDate")!;
    if (
      startDate != "" &&
      endDate != "" &&
      endDate != null &&
      startDate != null
    ) {
      const s = new Date(startDate);
      const e = new Date(endDate);
      const dateObj = {
        startDate: s,
        endDate: e,
      };
      setDateRange(dateObj);
      dispatch(
        setSelectedDateRange({
          startDate: startDate,
          endDate: endDate,
        })
      );
    } else {
      localStorage.setItem("startDate", dateRange.startDate);
      localStorage.setItem("endDate", dateRange.endDate);
      dispatch(
        setSelectedDateRange({
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        })
      );
    }
  }, []);

  const handleSelect = (ranges: any) => {
    // if (!datePicked) {
    //   setDatePicked(true);
    // }
    setDateRange(ranges.range1);

    // Call the callback function from the parent component
    // if (onDateChange) {
    //   onDateChange(ranges.range1.startDate, ranges.range1.endDate);
    // }
    localStorage.setItem("startDate", ranges.range1.startDate);
    localStorage.setItem("endDate", ranges.range1.endDate);
    dispatch(
      setSelectedDateRange({
        startDate: ranges.range1.startDate,
        endDate: ranges.range1.endDate,
      })
    );
  };

  const toggleCalender = () => {
    if (toggleCal === "none") {
      setToggleCal("block");
    } else {
      setToggleCal("none");
    }
  };

  const doneClose = () => {
    toggleCalender();
    // if (onClick) {
    //   onClick();
    // }
  };

  const sendDataToParent  = ()  =>{
    let message= false;

    message = true;
    if (onClick) {
        onClick(message)
    }
  }

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDYzxtmOw45CDXQ4eZqKRyKoo9gH-EzQtE",
  });

  const onLoad = useCallback(function callback(map: google.maps.Map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map: google.maps.Map | null) {
    setMap(null);
  }, []);

  const onDrop = (pictureFiles: File[], pictureDataURLs: string[]) => {
    const pictureObjects = pictureFiles.map((file, index) => ({
      file,
      dataURL: pictureDataURLs[index],
    }));

    setPictures((prevPictures) => [...prevPictures, ...pictureObjects]);
  };
  return (
    <div>
      <div className="config-campaign">
        <h2 className="light-header-txt">Set Campaign Name</h2>
        <div className="config-grid-container">
          <div className="grid-container">
            <h3 className="light-para-txt">Enter Campaign Name</h3>
            <input className="input-txt" type="text" />
          </div>
          <div className="grid-container">
            <h3 className="light-para-txt">Add Campaign Description</h3>
            <textarea className="textarea"></textarea>
          </div>
          <div className="grid-container">
            <h3 className="light-para-txt">Add Campaign Logo(Optional)</h3>

            <div>
              <input
                type="file"
                id="file-input"
                //   onChange={handleChange}
                name="file-input"
              />

              <label id="file-input-logo" htmlFor="file-input">
                <div className="file-input-logo">
                  <LuUpload className="upload-logo" />
                  <h2 className="light-header-txt" style={{ fontSize: "14px" }}>
                    Upload Logo Image
                  </h2>
                  <h2
                    className="light-header-txt"
                    style={{ fontSize: "12px", textAlign: "center" }}
                  >
                    (File Type: JPEG, PNG, WEBP | Under 600KB)
                  </h2>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="config-campaign">
        <h2 className="light-header-txt">Configure Campaign</h2>
        <div>
          <div className="config-grid">
            <div className="config-one">
              <div className="screen-grp">
                <div className="grp-one">
                  <h4>Screen Group</h4>
                  <button className="edit-btn">
                    <img src={edit} />
                    Edit Name
                  </button>
                </div>
                <button className="add-grp">
                  <img src={add} />
                  Add a Screen Group
                </button>
              </div>
              <div className="map-option">
                <h3 className="light-para-txt">
                  Select Display Location Category
                </h3>

                <FormControl sx={{ m: 0, minWidth: "100%" }} size="small">
                  <CustomSelect
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    // value={state.appPlatform}
                    // onChange={(e) => handleChangeCustom(e, "APP_PLATFORM")}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>

                    <MenuItem key={1} value="IOS">
                      IOS
                    </MenuItem>
                    <MenuItem key={2} value="APK">
                      APK
                    </MenuItem>
                    <MenuItem key={3} value="Android">
                      Android
                    </MenuItem>
                  </CustomSelect>
                </FormControl>
                <div className="grid-box">
                  <div>
                    <h3 className="light-para-txt">Select Target State</h3>
                    <FormControl sx={{ m: 0, minWidth: "100%" }} size="small">
                      <CustomSelect
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        // value={state.appPlatform}
                        // onChange={(e) => handleChangeCustom(e, "APP_PLATFORM")}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>

                        <MenuItem key={1} value="IOS">
                          IOS
                        </MenuItem>
                        <MenuItem key={2} value="APK">
                          APK
                        </MenuItem>
                        <MenuItem key={3} value="Android">
                          Android
                        </MenuItem>
                      </CustomSelect>
                    </FormControl>
                  </div>
                  <div>
                    <h3 className="light-para-txt">Select Target City</h3>

                    <FormControl sx={{ m: 0, minWidth: "100%" }} size="small">
                      <CustomSelect
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        // value={state.appPlatform}
                        // onChange={(e) => handleChangeCustom(e, "APP_PLATFORM")}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>

                        <MenuItem key={1} value="IOS">
                          IOS
                        </MenuItem>
                        <MenuItem key={2} value="APK">
                          APK
                        </MenuItem>
                        <MenuItem key={3} value="Android">
                          Android
                        </MenuItem>
                      </CustomSelect>
                    </FormControl>
                  </div>
                </div>

                <h3 className="light-para-txt">Select Area (Optional)</h3>
                <FormControl sx={{ m: 0, minWidth: "100%" }} size="small">
                  <CustomSelect
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    // value={state.appPlatform}
                    // onChange={(e) => handleChangeCustom(e, "APP_PLATFORM")}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>

                    <MenuItem key={1} value="IOS">
                      IOS
                    </MenuItem>
                    <MenuItem key={2} value="APK">
                      APK
                    </MenuItem>
                    <MenuItem key={3} value="Android">
                      Android
                    </MenuItem>
                  </CustomSelect>
                </FormControl>

                <div>
                  <div className="location-preview">
                    <h3 className="light-para-txt">Location Preview</h3>
                    <h5>
                      Total Number of Screens:14 <span> View List</span>{" "}
                    </h5>
                  </div>
                  <div>
                    {isLoaded && (
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={10}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                      >
                        {/* Child components, such as markers, info windows, etc. */}
                        <></>
                      </GoogleMap>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="vertical-line"
              style={{ position: "relative", left: "auto", height: "100%" }}
            ></div>
            <div>
              <div
                className="config-grid purple-bg"
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <div className="top-box ">
                  <h4>Available CPM</h4>
                  <h4>₹ 00.00</h4>
                </div>
                <div
                  className="vertical-line"
                  style={{ position: "relative", left: "auto" }}
                ></div>
                <div className="top-box">
                  <h4>Number of Screens</h4>
                  <h4>14</h4>
                </div>
              </div>
              <div
                className="config-grid"
                style={{
                  padding: "0px 6px",
                  justifyContent: "center",
                  margin: "15px 0 10px 0",
                  alignItems: "center",
                }}
              >
                <div className="calender-box">
                  <h3 className="light-para-txt">Campaign Start Date</h3>
                  <div className="top-box cal-select" onClick={toggleCalender}>
                    <img src={whiteCalender} />
                    <h4>
                      {dateRange.startDate.toLocaleDateString("default", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })}
                    </h4>
                  </div>
                </div>

                <div>-</div>

                <div className="calender-box">
                  <h3 className="light-para-txt">Campaign End Date</h3>
                  <div className="top-box cal-select" onClick={toggleCalender}>
                    <img src={whiteCalender} />
                    <h4>
                      {dateRange.endDate.toLocaleDateString("default", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })}
                    </h4>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: `${toggleCal}`,
                  position: "absolute",
                  right: "5%",
                }}
              >
                <DateRangePicker
                  ranges={[dateRange]}
                  onChange={handleSelect}
                  maxDate={new Date()}
                />
                <div
                  className="button-container"
                  style={{
                    right: "auto",
                    justifyContent: "end",
                    top: "21.5pc",
                  }}
                >
                  <button
                    className="purple-btn width-adjust"
                    onClick={doneClose}
                    style={{
                      margin: "0 15px",
                      cursor: "pointer",
                    }}
                  >
                    Done
                  </button>
                  <button className="border-btn" onClick={toggleCalender}>
                    Close
                  </button>
                </div>
              </div>
              <div>
                <h3 className="light-para-txt">Select Area (Optional)</h3>
                <FormControl sx={{ m: 0, minWidth: "100%" }} size="small">
                  <CustomSelect
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    // value={state.appPlatform}
                    // onChange={(e) => handleChangeCustom(e, "APP_PLATFORM")}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>

                    <MenuItem key={1} value="IOS">
                      IOS
                    </MenuItem>
                    <MenuItem key={2} value="APK">
                      APK
                    </MenuItem>
                    <MenuItem key={3} value="Android">
                      Android
                    </MenuItem>
                  </CustomSelect>
                </FormControl>
              </div>
              <div
                className="config-grid"
                style={{
                  margin: "10px 0 0 0",
                  padding: "0 11px 0 0",
                  alignItems: "center",
                }}
              >
                <div className="grid-container">
                  <h3 className="light-para-txt">
                    Enter Target Impressions / Day
                  </h3>
                  <input
                    style={{ height: "35px", width: "-webkit-fill-available" }}
                    className="input-txt"
                    type="text"
                  />
                </div>
                <div></div>
                <div className="grid-container">
                  <h3 className="light-para-txt">Enter Campaign Budget</h3>
                  <input
                    style={{ height: "35px", width: "-webkit-fill-available" }}
                    className="input-txt"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="divider-line"></div>

      <div className="top-box">
        <div className="note-txt">
          <span>Note:</span>
          <p>
            {" "}
            Billboards will display each ad for approximately{" "}
            <b>10-15 seconds </b>in a continuous loop with a set interval
            between repetitions.
          </p>
        </div>
        <div
          className="doneButton-box"
          style={{
            float: "right",
          }}
        >
          {/* <button className="border-btn" onClick={() => window.location.replace("/home/campaigns")}>
          Cancel
        </button> */}
          <button
            className="purple-btn width-adjust"
            onClick={sendDataToParent}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: RootState) => ({
  selectedDateRange: state.user.selectedDateRange,
});

const mapDispatchToProps = {
  setSelectedDateRange,
};
export default connect(mapStateToProps, mapDispatchToProps)(ConfigureCampaign);
