import React, { FC } from "react";
import AdvertiserScreen from "../pages/admin/AdvertiserScreen";
import CreateAdvertiserScreen from "../pages/admin/CreateAdvertiser";
import ReportScreen from "../pages/ReportScreen";
import BillingScreen from "../pages/BillingScreen";
import SettingsScreen from "../pages/Settings";
import SelectedAdvertiserScreen from "../pages/admin/SelectedAdvertiserScreen";
import AdminReportScreen from "../pages/admin/AdminReportScreen";
import CampaignScreen from "../pages/admin/Campaigns/CampaignScreen";
import CouponScreen from "../pages/admin/CouponScreen";
import CreateCouponScreen from "../pages/admin/CreateCoupon";
import CouponHistoryScreen from "../pages/admin/CouponHistory";
import EditCampaignAdmin from "../pages/admin/Campaigns/EditCampaignAdmin";
import CreateAdminCampaign from "../pages/admin/Campaigns/CreateAdminCampaign";
import AdminBilling from "../pages/admin/AdminBilling";
import AdminDashboardScreen from "../pages/admin/AdminDashboard";
import UserJourney from "../pages/admin/UserJourney";

export const Advertisers: FC = () => {
    return <AdvertiserScreen />;
}

export const SelectedAdvertiser: FC = () => {
    return <SelectedAdvertiserScreen />;
}

export const CreateAdvertiser: FC = () => {
    return <CreateAdvertiserScreen />;
}

export const AdminReport: FC = () => {
    return <AdminReportScreen />;
}

export const CampaignAdminScreen: FC = () => {
    return <CampaignScreen/>;
}

export const EditCampaignAdminScreen: FC = () => {
    return <EditCampaignAdmin/>;
}

export const Report: FC = () => {
    return <ReportScreen />;
}

export const AdminBillingScreen: FC = () => {
    return <AdminBilling shouldHideDiv={false} subHeading=""/>;
}

export const AdminSettings: FC = () => {
    return <SettingsScreen />;
}
export const Coupon: FC = () => {
    return <CouponScreen/>;
}

export const CreateCoupon: FC = () => {
    return <CreateCouponScreen/>;
}

export const CouponHistory: FC = () => {
    return <CouponHistoryScreen/>;
}
export const AdminCreateCampaign:  FC = () =>{
    return <CreateAdminCampaign/>
}

export const AdminDashboard: FC = () => {
    return <AdminDashboardScreen />;
}
export const AdminUserJourney: FC = () => {
    return <UserJourney/>;
}