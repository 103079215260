import React, { useState, useEffect } from "react";
import leftSideBG from "../../IMAGE/leftSideBG.svg";
import rightSideBG from "../../IMAGE/rightSideBG.svg";
import termsAndCondition from "../../IMAGE/termsAndCondition.svg";
import logo from "../../IMAGE/logo.svg";
import "../../CSS/SignIn.css";
import { BiSolidErrorCircle } from "react-icons/bi";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import Checkbox from "@mui/material/Checkbox";
import { FiChevronDown } from "react-icons/fi";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Alert, Snackbar } from "@mui/material";
import CustomLoader from "../custom/CustomLoader";
import { apiURL, customCheckBoxColor, secretKey } from "../../Constant";
import { setMasterCountryArray } from "../../REDUX/Actions/userActionIndex";
import { RootState } from "../../REDUX/Reducers/rootReducer";
import { connect, useDispatch } from "react-redux";
import TagManager from "react-gtm-module";
// import PhoneInput from 'react-phone-input-2';
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumberIntl, isPossiblePhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import "react-phone-input-2/lib/style.css";
import { isValidPhoneNumber } from 'react-phone-number-input/mobile'
import { set } from "date-fns";
import styled from "@emotion/styled";

const CryptoJS = require('crypto-js')

const style = {
  box: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40pc",
    bgcolor: "background.paper",
    border: "2px solid #F3F3F3",
    height: "30pc",
    boxShadow: 24,
    p: 4,
    outline: "none",
  },
};

const StyledPhoneInput = styled(PhoneInput)`
display: flex;
align-items: center;
border: 1.3px solid rgba(51,51,51,0.5);
height: 40px;
border-radius: 3px;
.PhoneInputInput {
  border: none;
  outline: none;
    padding-left: 20px; // Adjust padding to account for flag width
    border-radius: 4px;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
}

.PhoneInputCountry {
    // position: absolute;
    left: 10px;
}

.PhoneInputCountryIcon {
    // width: 24px;
    // height: 24px;
}
`;

const label = { inputProps: { "aria-label": "Checkbox demo" } };

interface SignUpProps {
  masterCountryArray: any,
}

const SignUp: React.FC<SignUpProps> = ({ masterCountryArray }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const [errorOpen, setErrorOpen] = React.useState(false);
  const [errorMsgSnackBar, setErrorMsgSnackBar] = useState<any>("")

  const [advertiserId, setAdvertiserId] = useState<string>("");

  const [nameError, setNameError] = useState<string>("");
  const [mobileErr, setMobileErr] = useState<string>("");
  const [orgNameError, setOrgNameError] = useState<string>("");
  const [userTypeError, setUserTypeError] = useState<string>("");
  const [countryError, setCountryError] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [otpError, setOtpError] = useState<string>("");
  const [passerror, setPassError] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [mobile, setMobile] = useState<any>("");
  const [orgName, setOrgName] = useState<string>("");
  const [country, setCountry] = useState<string>("United States");
  const [userType, setUserType] = useState<string>("agency");
  const [userOtp, setUserOtp] = useState<string>("");
  const [apiOtp, setApiOtp] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmpassword, setConfirmPassword] = useState<string>("");
  const [showCreatePassword, setShowCreatePassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [showOtpDiv, setShowOtpDiv] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [termsandconditions, setTermsAndConditions] = useState(false);
  const [termsandconditionsErr, setTermsAndConditionsErr] = useState("");
  const [loader, setLoader] = useState(false);

  // const isValidMobileNumber = (value: string): boolean => {
  //   return (/^(\+\d{1,3}[- ]?)?\d{10}$/.test(value))
  // };

  const [timerExpired, setTimerExpired] = useState<any>(false);
  const [otpTimer, setOtpTimer] = useState<any>(null);
  const [timeRemaining, setTimeRemaining] = useState<any>(300);

  const userTypeArray = [
    {
      name: "Brand",
      value: "brand",
    },
    {
      name: "Agency",
      value: "agency",
    },
  ];

  const toggleCreatePasswordVisibility = () => {
    setShowCreatePassword(!showCreatePassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const getMasterdata = async () => {
    await axios.get(`${apiURL}/api/data/getMasterData`,
    )
      .then(res => {
        const resData = res.data.data;
        const bytes = CryptoJS.AES.decrypt(resData, secretKey);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        const parsedData = JSON.parse(decryptedData);
        if (res.data.status == true) {
          dispatch(setMasterCountryArray(parsedData['country']))
        }
      })
      .catch((e) => console.log(e))
  }

  const handleChange = (e: any) => {
    let value = e.target.value;
    const passRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    switch (e.target.name) {
      case "name":
        setNameError("");
        if (value == "" || value == null || value.length == 0) {
          setNameError("Invalid Name");
        } else {
          setName(value);
        }
        break;
      case "userType":
        setUserTypeError("");
        if (value == "" || value == null || value.length == 0) {
          setUserTypeError("Invalid User Type");
        } else {
          setUserType(value);
        }
        break;
      case "orgName":
        setOrgNameError("");
        if (value == "" || value == null || value.length == 0) {
          setOrgNameError("Invalid Organisation Name");
        } else {
          setOrgName(value);
        }
        break;
      case "country":
        setCountry(value);
        setCountryError("");
        if (value == "" || value == null || value.length == 0) {
          setCountryError("Invalid Country");
        } else {
          setCountry(value);
        }
        break;
      case "email":
        setShowOtpDiv(false)
        const emailRegex =
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const emailRegexNew = /^(?!.*@(?:gmail\.com|hotmail\.com|yahoo\.com)$)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        setEmailError("");
        if (!emailRegexNew.test(value) || value == "" || value == null || value.length == 0) {
          setEmailError("Invalid Email");
        } else {
          setEmail(value.toLowerCase());
        }
        break;
      case "mobileNumber":
        setMobileErr("");
        if (!isValidPhoneNumber(value)) {
          setMobileErr("Invalid Mobile number.");
          if (value == "" || value == null) {
            setMobileErr("");
          }
        } else {
          setMobile(value);
        }
        break;
      case "otp":
        setOtpError("");
        if (value == "" || value == null || value.length != 4) {
          setOtpError("Invalid OTP");
        } else {
          setUserOtp(value);
        }
        break;
      case "password":
        setPassError("");
        if (
          !passRegex.test(value) ||
          value == "" ||
          value == null ||
          value.length == 0
        ) {
          setPassError("Invalid password ");
        } else {
          setPassword(value);

          if (value != confirmpassword) {
            setPassError("Password did not match");
          } else {
            setPassError("");
          }
        }
        break;
      case "confirm-password":
        setPassError("");
        if (value == "" || value == null || value.length == 0) {
          setPassError("Invalid Confirm Password");
        } else {
          setConfirmPassword(value);
          if (value != password) {
            setPassError("Password did not match");
          } else {
            setPassError("");
          }
        }
        break;
      case "check":
        setTermsAndConditionsErr("");
        setTermsAndConditions(!termsandconditions);
        if (e.target.checked == false) {
          setTermsAndConditionsErr("Please check terms and conditions.");
        } else {
          setTermsAndConditionsErr("");
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (timerExpired) {
      clearInterval(otpTimer);
    }
  }, [timerExpired]);

  useEffect(() => {
    if (timeRemaining <= 0) {
      setTimerExpired(true);
      clearInterval(otpTimer);
    }
  }, [timeRemaining]);

  // Clear timer on component unmount
  useEffect(() => {
    return () => {
      if (otpTimer) {
        clearTimeout(otpTimer);
        clearInterval(otpTimer);
      }
    };
  }, [otpTimer]);

  const onClickGetOtp = async () => {
    if (email != "" && emailError == "") {
      setLoader(true);
      await axios
        .get(`${apiURL}/api/getOtp?UserEmailID=${email.toLowerCase()}`)
        .then(async (res) => {
          if (res.data.status == true) {
            const bytes = CryptoJS.AES.decrypt(res.data.data, secretKey);
            const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
            const parsedData = JSON.parse(decryptedData);
            setLoader(false);
            setApiOtp(parsedData.otp);
            setShowOtpDiv(true);
            // Clear any existing timers
            if (otpTimer) {
              setTimerExpired(false);
              setTimeRemaining(300)
              clearTimeout(otpTimer)
              clearInterval(otpTimer);
            }

            // Set a timer for 5 minutes (300,000 milliseconds)
            const timer = setTimeout(() => {
              setTimerExpired(true);
              setShowOtpDiv(false);
            }, 300000);

            // Set interval to update the countdown every second
            const interval = setInterval(() => {
              setTimeRemaining((prev: any) => prev - 1);
            }, 1000);

            // Save timer and interval IDs
            setOtpTimer(timer);
            setOtpTimer(interval);
          }
        })
        .catch((e) => {
          setLoader(false);
          console.log(e);
        });
    } else {
      setLoader(false);
      setEmailError("Invalid Email");
    }
  };

  const onClickVerifyOtp = async () => {
    if (userOtp != "" && otpError == "") {
      setOtpError("");
      if (!userOtp.match(apiOtp)) {
        setOtpError("Invalid OTP");
      } else {
        setUserOtp(userOtp);
        setShowOtpDiv(false);

        if (name == "" || nameError != "") {
          setNameError("Invalid Name");
        } else {
          let jsonParams = {
            Email: email.toLowerCase(),
            Password: password,
            Name: name,
            Country: country,
            OrganizationName: orgName,
            UserType: userType,
            OtpVerified: false,
            MobileNumber: mobile
          };

          const encryptedData = CryptoJS.AES.encrypt(
            JSON.stringify(jsonParams),
            secretKey
          ).toString();
          const params = {
            params: encryptedData,
          };
          const response = await axios.post(`${apiURL}/api/registorUser`, params);
          if (response.data.status == true) {
            if (response.data.statusCode == 201) {
              const bytes = CryptoJS.AES.decrypt(response.data.data, secretKey);
              const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
              const parsedData = JSON.parse(decryptedData);
              setAdvertiserId(parsedData.Advertiser_Id)
              setErrorMsgSnackBar("User already exists!")
              setErrorOpen(true)

            } else {
              const bytes = CryptoJS.AES.decrypt(response.data.data, secretKey);
              const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
              const parsedData = JSON.parse(decryptedData);
              setAdvertiserId(parsedData.Advertiser_Id)
              TagManager.dataLayer({
                dataLayer: {
                  event: 'signUp',
                }
              })
            }
          }
        }

      }
    }
  };

  useEffect(() => {
    getMasterdata()
  }, []);

  const nextPage = async () => {
    if (orgName == "" || orgNameError != "") {
      setOrgNameError("Invalid Organisation Name");
    } else if (name == "" || nameError != "") {
      setNameError("Invalid Name");
    } else if (email == "" || emailError != "") {
      setEmailError("Invalid Email-ID");
    } else if (mobileErr != "") {
      setMobileErr("Invalid Mobile Number");
    } else if (!userOtp.match(apiOtp) || userOtp == "" || otpError != "") {
      setShowOtpDiv(true);
      setOtpError("Please Verify Otp");
      onClickGetOtp();
    } else if (password == "" || confirmpassword == "") {
      setPassError("Invalid Password");
    } else if (termsandconditionsErr != "" || termsandconditions == false) {
      setTermsAndConditionsErr("Please check terms and conditions.");
    } else {
      try {
        var bodyFormData = new FormData()
        bodyFormData.append("MobileNumber", mobile)
        bodyFormData.append("Email", email.toLowerCase())
        bodyFormData.append("OrganizationName", orgName)
        bodyFormData.append("Password", password)
        bodyFormData.append("Name", name)
        bodyFormData.append("Country", country)
        bodyFormData.append("UserType", userType)
        bodyFormData.append("Advertiser_Id", advertiserId)

        const response = await axios.post(`${apiURL}/api/onboarding/editUser`, bodyFormData);
        if (response.data.status == true) {
          // setSuccessOpen(true)
          navigate("/");

        } else if (response.data.statuscode == 401) {
          // setOpenSession(true)
          setTimeout(() => { navigate("/") }, 2000)
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleOnChange = (e: any) => {
    // setMobile("")
    if (e != undefined) {
      setMobileErr("");
      let phoneNumber: any = parsePhoneNumber(e)
      if (phoneNumber) {
        if (isValidPhoneNumber(phoneNumber.number)) {
          setMobile(phoneNumber.number);
        } else {
          setMobileErr("Invalid Mobile Number.");
        }
      } else {
        setMobileErr("Invalid Mobile Number.");
      }
    }
  };

  return (
    <div className="signIn-main">
      <div className="left-img-div">
        <img src={leftSideBG} alt="leftSide" />
      </div>
      <div className="center-div">
        <img className="admattic-logo" src={logo} alt="logo" />
        <div className="mid-box">
          <h2>SIGN UP</h2>
          <div className="details-box">
            <div className="email-box input-margins">
              <label htmlFor="email"> Organisation Name *</label>
              <div>
                <input
                  type="text"
                  name="orgName"
                  defaultValue={orgName}
                  onChange={handleChange}
                />
              </div>
              {orgNameError != "" && orgNameError != null && (
                <div className="error-box" style={{ width: "80%", margin: 0 }}>
                  <BiSolidErrorCircle className="error-icon" />
                  <p>{orgNameError}</p>
                </div>
              )}
            </div>
            <div className="email-box input-margins ">
              <label htmlFor="name">Account Manager (Full Name)*</label>
              <input
                type="text"
                name="name"
                className="padding-details"
                defaultValue={name}
                onChange={handleChange}
              />
            </div>
            {nameError != "" && nameError != null && (
              <div className="error-box" style={{ width: "50%", margin: 0 }}>
                <BiSolidErrorCircle className="error-icon" />
                <p>{nameError}</p>
              </div>
            )}
            <div className="pwd-box country-box input-margins">
              <label>Country *</label>
              <div className="hide-button">
                <select name="country" value={country} onChange={handleChange}>
                  {masterCountryArray != null &&
                    masterCountryArray.map((item: any) => (
                      <option key={item.Country} value={item.Country}>
                        {item.Country}
                      </option>
                    ))}
                </select>
                <FiChevronDown className="down-icon" />
              </div>
            </div>

            <div className="email-box icons-margin" >
              <label htmlFor="email" className="signup-email">
                Email *
              </label>
              <div className="otp-box">
                <input
                  type="text"
                  name="email"
                  className="otp-input"
                  defaultValue={email}
                  onChange={handleChange}
                />
                <button className="purple-btn" onClick={onClickGetOtp}>
                  Get OTP
                </button>
              </div>
            </div>
            {emailError != "" && emailError != null && (
              <div className="error-box" style={{ width: "50%", margin: 0 }}>
                <BiSolidErrorCircle className="error-icon" />
                <p>{emailError}</p>
              </div>
            )}
            {
              loader == false ? (
                showOtpDiv == true ? (
                  <div className="email-box">
                    {/* <label htmlFor="email" className="signup-email">Email</label> */}
                    <div className="otp-box" style={{ marginTop: "20px" }}>
                      <input
                        type="text"
                        name="otp"
                        className="otp-input code-box"
                        onChange={handleChange}
                      />
                      <button
                        className="purple-btn otp-verify"
                        onClick={onClickVerifyOtp}
                      >
                        OTP Verify
                      </button>
                    </div>
                    {!timerExpired ? <label
                    >Time remaining: {timeRemaining}s</label> : <p></p>}


                    {otpError != "" && otpError != null && (
                      <div
                        className="error-box"
                        style={{ width: "50%", margin: 0 }}
                      >
                        <BiSolidErrorCircle className="error-icon" />
                        <p>{otpError}</p>
                      </div>
                    )}
                  </div>

                ) : (
                  <div></div>
                )
              ) : (
                <div style={{ margin: "10px 0px" }}>
                  <CustomLoader />
                </div>
              )
              // <CircularProgress size="1rem" sx={{ color: "#6800b8", marginLeft: "50%", }} />
            }
            {timerExpired && <div
              className="error-box"
              style={{ width: "50%", margin: 0 }}
            >
              <p>OTP Expired</p>
            </div>}
            <div className="email-box input-margins">
              <label htmlFor="email"> Mobile Number*</label>
              <div>
                {/* <input
                  type="text"
                  name="mobileNumber"
                  defaultValue={mobile}
                  onChange={handleChange}
                /> */}
                <StyledPhoneInput
                  limitMaxLength={true}
                  focusInputOnCountrySelection
                  // initialValueFormat="national"
                  international
                  countryCallingCodeEditable={false}

                  defaultCountry="IN"
                  value={mobile}
                  inputProps={{
                    name: 'phone',
                    required: true,
                    autoFocus: true,
                  }}
                  style={{
                    width: "104%",
                  }}
                  onChange={handleOnChange}
                // inputComponent={CustomMobileField}

                />
              </div>
              {mobileErr != "" && mobileErr != null && (
                <div className="error-box" style={{ width: "80%", margin: 0 }}>
                  <BiSolidErrorCircle className="error-icon" />
                  <p>{mobileErr}</p>
                </div>
              )}
            </div>

            <div className="pwd-box country-box input-margins">
              <label>User Type *</label>
              <div className="hide-button">
                <select
                  name="userType"
                  value={userType}
                  onChange={handleChange}
                >
                  {userTypeArray.map((item: any) => (
                    <option key={item.value} value={item.value}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <FiChevronDown className="down-icon" />
              </div>
            </div>
            {userTypeError != "" && userTypeError != null && (
              <div className="error-box" style={{ width: "50%", margin: 0 }}>
                <BiSolidErrorCircle className="error-icon" />
                <p>{userTypeError}</p>
              </div>
            )}
            <div
              className="email-box pwd-box input-margins"
              style={{ marginTop: "60px" }}
            >
              <label htmlFor="email"> Create password *</label>
              <div className="hide-button">
                <input
                  type={showCreatePassword ? "text" : "password"}
                  name="password"
                  defaultValue={password}
                  onChange={handleChange}
                />
                <button
                  type="button"
                  className="eye-button"
                  onClick={toggleCreatePasswordVisibility}
                >
                  {showCreatePassword ? (
                    <AiFillEyeInvisible className="eye-icon" />
                  ) : (
                    <AiFillEye className="eye-icon" />
                  )}
                </button>
              </div>
            </div>
            <div
              className="email-box pwd-box input-margins"
              style={{ marginTop: "60px" }}
            >
              <label htmlFor="email"> Confirm Password *</label>
              <div className="hide-button">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirm-password"
                  defaultValue={confirmpassword}
                  onChange={handleChange}
                />
                <button
                  type="button"
                  className="eye-button"
                  onClick={toggleConfirmPasswordVisibility}
                >
                  {showConfirmPassword ? (
                    <AiFillEyeInvisible className="eye-icon" />
                  ) : (
                    <AiFillEye className="eye-icon" />
                  )}
                </button>
              </div>
            </div>
            <div className="mandatory-box" style={{ marginTop: "50px" }}>
              {/* <div className="error-box" style={{ width: "50%", margin: 0 }}>
                <BiSolidErrorCircle className="error-icon" />
                <p>Password did not match</p>
              </div> */}
              {passerror != "" && passerror != null && (
                <div className="error-box" style={{ width: "50%", margin: 0 }}>
                  <BiSolidErrorCircle className="error-icon" />
                  <p>{passerror}</p>
                </div>
              )}
              {
                name != "" && nameError == "" && email != "" && emailError == "" && mobileErr == "" && userOtp != "" && otpError == "" && orgName != "" && orgNameError == "" && password != "" && confirmpassword != "" && passerror == "" ?
                  <p className="mandatory-para" style={{ color: "green" }}>* All Fields are Mandatory</p>
                  :
                  <p className="mandatory-para">* All Fields are Mandatory</p>

              }
            </div>
            <div className="mandatory-para" style={{ color: password == "" || confirmpassword == "" || passerror != "" ? "red" : "green" }}>
              {" "}
              * A password must contain minimum 8 characters with at least one
              upper case, <br /> one lower case, one number, and
              one special character
            </div>
            <div className="chk-box" style={{ marginTop: "10px" }}>
              <div className="chk-div">
                <Checkbox
                  {...label}
                  className="chk"
                  onChange={handleChange}
                  name="check"
                  checked={termsandconditions}
                  style={{ color: customCheckBoxColor }}
                />
                {/* <input type="checkbox" className="chk" /> */}
                <p>
                  I Accept to <u className="terms-condition" onClick={handleOpen}>Admattic's terms</u> of use
                </p>
                {/* <Button onClick={handleOpen}>Open modal</Button> */}
              </div>
            </div>

            {termsandconditionsErr != "" && termsandconditions == false && (
              <div className="error-box" style={{ width: "100%", margin: 0 }}>
                <BiSolidErrorCircle className="error-icon" />
                <p>{termsandconditionsErr}</p>
              </div>
            )}
          </div>
          <button
            className="signIn-btn"
            style={{ margin: "2% auto" }}
            onClick={nextPage}
          >
            Sign Up
          </button>
          {/* {error != "" && error != null && (
            <div className="error-box" style={{ width: "50%", margin: 0 }}>
              <BiSolidErrorCircle className="error-icon" />
              <p>{error}</p>
            </div>
          )} */}
        </div>
        <div className="signup-box">
          <p>New to Admattic?&nbsp;&nbsp;</p>
          <a href="/">Sign In</a>
        </div>
      </div>
      <div className="right-img-div">
        <img src={rightSideBG} alt="rightSide" />
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        sx={style}
      >
        <Box sx={style.box} className="modal-box">
          {/* <Typography id="modal-modal-title" className="" variant="h6" component="h2"> */}
          <div className="signup-box">
            <img src={termsAndCondition} className="tnc-icon" alt="icon-tac" />
            <h3 className="modal-text">Terms and Condition</h3>
          </div>
          {/* </Typography> */}
          <Typography
            id="modal-modal-description"
            className="middle-box"
            sx={{ mt: 2 }}
          >
            <b>Service Description:</b> Admattic provides an application
            discovery technology (referred to as the "Service") that facilitates
            the connection between Publishers and Advertisers through our online
            platform. This allows Advertisers to display ads on Publisher
            Properties based on specific user characteristics. It's important to
            note that Admattic does not develop any specific Publisher Property
            and is not accountable for the content, functionality, or
            performance of any ad or Publisher Property. As a user, you may come
            across our Service when downloading or visiting a Publisher
            Property, as our Advertisers may request us to serve ads on that
            particular Publisher Property.
            <br />
            <br />
            <b>Eligibility:</b> By using our services, you confirm that you are
            at least eighteen (18) years old and possess the full ability and
            competence to agree to the terms, conditions, obligations,
            affirmations, representations, and warranties outlined in the
            General Terms of Use. You commit to abiding by and complying with
            these General Terms of Use.
            <br />
            <br />
            <b>Member Accounts: </b>Whether you are a Publisher or an
            Advertiser, the creation of a password-protected account ("Account")
            is mandatory to access certain features of the Website and the
            Service. It is your responsibility to maintain the strict
            confidentiality of your password. You will be held accountable for
            any unauthorized access or use of your Account, including associated
            fees, costs, or expenses incurred by you or any party using your
            password, authorized or not. Promptly inform Admattic of any
            unauthorized use or security breaches. Ensure that you log out of
            your Account at the end of each session. Safeguarding your password,
            controlling access to your Account, and notifying Admattic when
            canceling your Account are your sole responsibilities. Admattic
            shall not be held responsible or liable, and you waive any claims
            against Admattic, for any loss or damage resulting from your failure
            to adhere to this provision.
            <br />
            <br />
            <b>Dormant Accounts: </b>To utilize Admattic services, it is
            essential to keep your account active. Your account status is
            determined by our system, considering your activity and spending as
            an Advertiser or earnings as a Publisher. Please take note that if
            your account remains inactive for a consecutive 12-month period or
            more, and the account holds a balance greater than zero dollars
            ($0.00 USD), ANY AMOUNTS IN YOUR ACCOUNT’S BALANCE, INCLUDING ANY
            OUTSTANDING FEES OWED TO YOU, SHALL BE DEEMED FORFEITED AND FULLY
            DEDUCTED TO ADMATTIC. A "dormant account" is defined as one that
            exhibits no activity for 12 consecutive calendar months or more
            (i.e., zero logins, zero clicks, zero conversions, zero referrals,
            zero referral earnings, and zero payments) as identified by
            Admattic's systems. It is important to note that the dormant account
            process also applies to temporarily blocked accounts. Upon resuming
            the use of Admattic services, your account will be promptly marked
            as active. You acknowledge and agree that fees already deducted
            during the dormant account process will not be refunded even if your
            account status becomes active again. PLEASE BE AWARE, THAT IF YOU NO
            LONGER WISH TO USE ADMATTIC SERVICES AND WANT TO AVOID THE DORMANT
            ACCOUNT PROCESS, YOU MUST PROPERLY TERMINATE YOUR CONTRACT WITH
            ADMATTIC IN ACCORDANCE WITH ADMATTIC GENERAL TERMS AND CONDITIONS.
            <br />
            <br />
            <b>Intellectual Property Ownership: </b>Unless stated otherwise, all
            text, content, and documents accessible via the Website or the
            Service, including names, logos, trademarks, service marks, brand
            identities, characters, trade names, graphics, designs, copyrights,
            trade dress, or other intellectual property featured on the Website
            or the Service (the "Content"), are either owned by Admattic or used
            with permission or under license from a third party. They are
            protected under copyright, trademark, and other intellectual
            property and proprietary rights laws. Between Admattic and you, all
            rights, titles, and interests in and to the Content will always
            remain with Admattic and/or its respective licensors. All brand
            names, product names, titles, slogans, logos, or service names, and
            other marks used via the Website or the Service are registered
            and/or common law trade names,trademarks, or service marks of
            Admattic.
            <br />
            <br />
            <b>Limited Use;</b> Usage Restrictions: You are allowed to use the
            Website and/or any services and products offered via the Website
            solely for lawful purposes as outlined in the General Terms of Use
            and your relevant Admattic Agreement, depending on whether you are
            an Advertiser or Publisher. Any unauthorized use or misuse of any
            Service is strictly prohibited. Admattic grants you a non-exclusive,
            limited, personal, non-transferable, revocable license to access and
            use the Website and/or the Service, without the right to sublicense,
            under the following conditions: • Without express written consent
            from Admattic, you shall not: (a) copy, retransmit, modify,
            disseminate, display, perform, reuse, re-post, broadcast, circulate,
            or distribute the Website, Service, or the Content, or modify or
            re-use any portion of the foregoing, (b) use any trade-name,
            trademark, or brand name of Admattic in meta-tags, keywords, and/or
            hidden text, (c) create derivative works from or commercially
            exploit the Website, Service, or the Content, in whole or in part,
            and (d) use the Website, Service, or the Content, and/or any portion
            thereof, in any manner that may present a false or misleading
            impression, attribution, or statement about Admattic or any third
            party. Admattic retains all other rights. • You shall not alter,
            remove, or obscure any copyright notice, digital watermarks,
            proprietary legends, or any other notice included in the Website,
            Service, or the Content. Except as expressly provided herein,
            nothing via the Service shall be construed as conferring any license
            under Admattic’s and/or its licensors’ intellectual property rights,
            whether by estoppel, implication, or otherwise. Admattic reserves
            the right to revoke any of the foregoing rights and/or your access
            to the Website and the Service, or any part thereof, including the
            blocking of your IP Address, at any time without prior notice.
            <br />
            <br />
            <b>Disclaimers: </b>The Website, Service, Content, and all other
            features provided through the foregoing are offered to you "AS IS"
            and "AS AVAILABLE" without any warranty concerning the Website
            and/or the Service and/or Content, either express or implied,
            including but not limited to, fitness for a particular purpose,
            title, or non-infringement. If applicable law does not permit
            excluding express or implied warranties, Admattic grants the minimum
            express or implied warranty required by such applicable law. No
            advice or information, whether oral or written, obtained by you from
            Admattic, its employees, agents, suppliers, or any other persons
            shall create any warranty, representation, or guarantee not
            expressly stated in this section. Additionally, Admattic does not
            guarantee that the Website and/or the Service will be uninterrupted,
            secure, or error-free, or that your use of the Website and/or the
            Service will meet your expectations, or that the Website, the
            Service, the Content, or any portion thereof, is correct, accurate,
            or reliable. Admattic reserves the right to modify any part of the
            Website at any time and without notice.
            <br />
            <br />
            <b>Limitation of Liability:</b> Your use of this website is at your
            own risk. Neither Admattic nor its affiliates, nor any of its
            respective officers, directors, agents, or other representatives
            will be liable for any damages, direct, indirect, incidental,
            consequential, special, or punitive, including, without limitation,
            loss of data, income, profit, or goodwill, loss of or damage to
            property, and claims of third parties arising out of your access to
            or use of this Website or arising out of any action taken in
            response to or as a result of any Content or other information
            available via this Website, however caused, whether based on breach
            of contract, tort (including negligence), proprietary rights
            infringement, product liability, or otherwise. The foregoing shall
            apply even if Admattic was advised of the possibility of such
            damages. If you become dissatisfied in any way with the Website, the
            Service, or any applicable Admattic Agreement, your sole and
            exclusive remedy is to stop your use of this Website and Service.
            You hereby waive any and all claims against Admattic and its agents,
            representatives, and licensors arising out of your use of the
            Website. Some states do not allow the disclaimer of implied
            warranties or the exclusion or limitation of certain types of
            damages; accordingly, these provisions may not apply to you. If any
            portion of this limitation on liability is found to be invalid or
            unenforceable for any reason, then the aggregate liability of
            Admattic and its affiliates shall not exceed one hundred dollars
            ($100). The limitation of liability herein is a fundamental element
            of the basis of the bargain and reflects a fair allocation of risk.
            The Website would not be provided without such limitations, and you
            agree that the limitations and exclusions of liability, disclaimers,
            and exclusive remedies specified herein will survive even if found
            to have failed in their essential purpose.
            <br />
            <br />
            <b>No Crawling; Framing; Links; Third Party Sites:</b>
            Crawling, scraping, indexing, framing, in-line linking, or other
            methods of association with the Website, by any means, including
            accessing the Website by automated means (such as robots or
            spiders), are expressly prohibited without prior written approval
            from Admattic. Admattic may permit operators of public search
            engines to use spiders to index materials from the Website for the
            sole purpose of creating publicly available searchable indices of
            the materials, but not caches or archives of such materials.
            <br />
            <br />
            <b>Assignment:</b> The General Terms of Use, and any rights and
            licenses granted hereunder, may not be transferred or assigned by
            you, but may be assigned by Admattic without restriction.
            <br />
            <br />
            <b>Choice of Law: </b> By using the Website and/or the Service, you
            agree that this agreement shall be governed by and construed in
            accordance with the laws of India without regard to the provisions
            of conflicts of law. Any dispute arising from or related to the
            Privacy Policy and/or your use of the Website and the Service shall
            be governed by the laws of India and shall be subject to the
            exclusive jurisdiction of the courts in India. You submit to the
            jurisdiction and venue of such courts and waive any objection based
            on an inconvenient forum.
            <br />
            <br />
            <b>Modifications:</b> Admattic may revise these General Terms of Use
            from time to time. To keep you informed of any changes, Admattic
            notes the date the General Terms of Use were last updated above.
            Your use of the Website following the posting of any revised General
            Terms of Use shall be deemed acceptance of the revised policy.
            Admattic strongly recommends reviewing the General Terms of Use
            periodically.
            <br />
            <br />
            <b>Miscellaneous:</b> Admattic’s failure to enforce any provision of
            the General Terms of Use shall not be deemed a waiver of such
            provision nor of the right to enforce such provision. If any part of
            the General Terms of Use is determined to be invalid or
            unenforceable pursuant to applicable law, including, but not limited
            to, the warranty disclaimers and liability limitations set forth
            above, then the invalid or unenforceable provision will be deemed
            superseded by a valid, enforceable provision that most closely
            matches the intent of the original provision, and the remainder of
            the General Terms of Use shall continue in effect. A printed version
            of the General Terms of Use and of any notice given in electronic
            form shall be admissible in judicial or administrative proceedings
            based upon or relating to the General Terms of Use to the same
            extent and subject to the same conditions as other business
            documents and records originally generated and maintained in printed
            form.
          </Typography>
          <div className="tac-btn-box">
            <button
              className="decline-btn"
              onClick={() => {
                setTermsAndConditions(false);
                handleClose();
              }}
            >
              Decline
            </button>
            <button
              className="accept-btn"
              onClick={() => {
                setTermsAndConditions(true);
                handleClose();
              }}
            >
              Accept
            </button>
          </div>
        </Box>
      </Modal>
      <Snackbar open={errorOpen} autoHideDuration={6000} onClose={() => setErrorOpen(false)} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <Alert onClose={() => setErrorOpen(false)} severity="error" sx={{ width: '100%' }}>
          {errorMsgSnackBar}
        </Alert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  masterCountryArray: state.user.masterCountryArray,
});

const mapDispatchToProps = {
  setMasterCountryArray,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);