import React, { useRef, useState, FC, useEffect } from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { LuDownload } from "react-icons/lu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosTwoToneIcon from "@mui/icons-material/ArrowForwardIosTwoTone";
import { styled } from "styled-components";
import DateCustomPicker from "../../../custom/DateCustomPicker";
import { PurpleCustomButton } from "../../../custom/PurpleButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TablePagination } from "@mui/material";
import Paper from "@mui/material/Paper";
import { CSVLink } from "react-csv";
import "../../../../CSS/Campaign.css";
import "../../../../CSS/CheckboxTab.css";
import "../../../../CSS/SignIn.css";
// import edit from "../../../../IMAGE/editIcon.svg";
// import sound from "../../../../IMAGE/sound.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  EditStatusArray,
  apiURL,
  customCheckBoxColor,
  secretKey,
} from "../../../../Constant";
import { RootState } from "../../../../REDUX/Reducers/rootReducer";
import { connect } from "react-redux";
import {
  setCampaignSubType,
  setAppPlatform,
  setAppName,
  setCampaignName,
  setAppImageUrl,
  setPackageName,
  setLocation,
  setLanguage,
  setTracking,
  setTrackingUrl,
  setImpressionUrl,
  setTokenEvent,
  addTokenEventObject,
  removeTokenEventObject,
  updateToken,
  updateEvent,
  setLocationCode,
  setBudget,
  setFocus,
  setTarget,
  setCostPerInstall,
  setAdGroupName,
  setLogoUrl,
  setHeadline,
  setDescription,
  setImageUrl,
  setVideoUrl,
  setHtmlUrl,
  setCampaignId,
  setBudgetId,
  setStatus,
  setImageFile,
  setVideoFile,
  setHtmlFile,
  setImageUploadError,
  setLogoError,
  setLocationCheck,
  setAdvertiserId,
  setAuthToken,
  setSessionId,
  setDataSource,
  setSegmentName,
  setDataType,
  setDataUpload,
  setUploadedUrl,
  setDataSourceError,
  setSegmentNameError,
  setDataTypeError,
  setDataUploadError,
  setUploadedUrlError,
  setSelectedAdvertiserId,
  setSelectedDateRange,
  setCompanyName,
  setCampaignStatus,
  setAdvertiserIdCampaign,
  setTransactionCurrency,
  setClicks,
  setImpression,
  setConversion,
  setInstall,
  setCtr,
  setCpi,
  setDailyAvgBudget,
  setPayout,
  setCreditLimit,
  setLastBilledAmount,
  setTotalSpendsLastMonth,
  setCreatedDate,
  setActivatedDate,
  setCompanyImage,
  setTableData,
  setId,
  setAdvertiserName,
  setTempTransactionCurrency,
  setProtectedData,
} from "../../../../REDUX/Actions/userActionIndex";
import { setCount } from "../../../../REDUX/Actions/counterActionIndex";
import { TableSortLabel } from "@mui/material";
import { useDispatch } from "react-redux";
import { DateRangePicker } from "react-date-range";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import CustomLoader from "../../../custom/CustomLoader";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import sound from "../../../../IMAGE/sound.svg";
import calender from "../../../../IMAGE/calender.svg";
import edit from "../../../../IMAGE/editIcon.svg";
import active from "../../../../IMAGE/active.svg";
import paused from "../../../../IMAGE/paused.svg";
import stopped from "../../../../IMAGE/stopped.svg";
import done from "../../../../IMAGE/done.svg";
import pending from "../../../../IMAGE/pending.svg";
import cancel from "../../../../IMAGE/cancel.svg";
import { table } from "console";
import { faIR } from "date-fns/locale";
const CryptoJS = require("crypto-js");

interface CampaignAdminScreenProps {
  advertisersList: any;
  shouldHideDiv?: boolean;
  isadmin?: boolean;
  heading?: string;
  dates?: {
    startDate: string;
    endDate: string;
  };
  campaignSubtype: string;
  appPlatform: string;
  appName: string;
  campaignName: string;
  appImageUrl: string;
  packageName: string;

  location: string;
  language: string[];
  tracking: string;
  trackingUrl: string;
  impressionUrl: string;
  tokenEvent: {
    token: string;
    eventName: string;
  }[];
  // showLower?: boolean;
  locationCode: string;

  budget: string;
  target: string;
  focus: string;
  costPerInstall: string;

  adGroupName: string;
  logoUrl: string;
  headline: string[];
  description: string[];

  imageUrl: string[];
  videoUrl: string[];
  htmlUrl: string[];
  count: number;

  budget_Id: string;
  campaign_Id: string;

  status: string;
  authToken: string;
  sessionId: string;
  advertiserId: string;
  dateChanging?: boolean;
  selectedAdvertiserId: string;

  advertiserIdCampaign: string;
  selectedDateRange: any;
  campaignStatus: string;
  tableData: any;
  croppedId: any;
  protectedData: any;
}
const CampaignScreen: FC<CampaignAdminScreenProps> = ({
  croppedId,
  campaignStatus,
  dateChanging,
  authToken,
  sessionId,
  advertiserId,
  shouldHideDiv,
  tableData,
  heading,
  dates,
  protectedData,
  count,
  appName,
  campaignSubtype,
  appPlatform,
  campaignName,
  packageName,
  appImageUrl,

  location,
  language,
  tracking,
  trackingUrl,
  impressionUrl,
  tokenEvent,
  locationCode,

  budget,
  target,
  focus,
  costPerInstall,

  adGroupName,
  logoUrl,
  headline,
  description,
  imageUrl,
  videoUrl,
  htmlUrl,

  campaign_Id,
  budget_Id,
  status,
  isadmin,
  selectedAdvertiserId,
  selectedDateRange,
  advertisersList,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false);
  const [dataContains, setDataContains] = useState<boolean>(true);
  const [dataComing, setdataComing] = useState<boolean>(true);
  const [currency, setCurrency] = useState<any>(
    localStorage.getItem("currency")
  );
  // const [backupData, setBackupData] = useState<>
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(
    new Date(new Date().getFullYear(), new Date().getMonth() - 2, 1)
  );
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());
  const initialStartDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth() - 2,
    1
  );
  const initialEndDate = new Date();
  const [changedDate, setChangedDate] = useState<any>({
    startDate: initialStartDate,
    endDate: initialEndDate,
  });
  const [isHoverStatus, setIsHoverStatus] = useState<string>("");
  const [statusDpd, setStatusDpd] = useState<string>("");
  const [toggleStatusInput, setToggleStatusInput] = useState<boolean>(false);
  const [saveStatusSelected, setSaveSelectedSelected] = useState<string>("");

  const [searchValue, setSearchValue] = useState<string>("");
  const [downloadModal, setdownloadModal] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const rowsPerPageOptions = [5, 10, 25];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const [datePicked, setDatePicked] = useState<boolean>(false);
  const [toggleCal, setToggleCal] = useState<string>("none");
  const boxRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (boxRef.current && !boxRef.current.contains(event.target as Node)) {
      setToggleCal("none");
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleSelect = (ranges: any) => {
    if (!datePicked) {
      setDatePicked(true);
    }
    // setDateRange(ranges.range1);

    // Call the callback function from the parent component
    // if (onDateChange) {
    //   onDateChange(ranges.range1.startDate, ranges.range1.endDate);
    // }
    localStorage.setItem("startDate", ranges.range1.startDate);
    localStorage.setItem("endDate", ranges.range1.endDate);
    dispatch(
      setSelectedDateRange({
        startDate: ranges.range1.startDate,
        endDate: ranges.range1.endDate,
      })
    );
    setChangedDate({
      startDate: ranges.range1.startDate,
      endDate: ranges.range1.endDate,
    });
    setSelectedStartDate(ranges.range1.startDate);
    setSelectedEndDate(ranges.range1.endDate);
  };

  const toggleCalender = () => {
    if (toggleCal === "none") {
      setToggleCal("block");
    } else {
      setToggleCal("none");
    }
  };

  const doneClose = () => {
    toggleCalender();
    onDateSearch();
  };

  // const [isOpen, setIsOpen] = useState<boolean>(false);
  // const [selectedStartDate, setSelectedStartDate] = useState<Date>(new Date());
  // const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    dispatch(
      setSelectedDateRange({
        startDate: selectedStartDate,
        endDate: selectedEndDate,
      })
    );
  }, []);

  const handleDateChange = (startDate: Date, endDate: Date) => {
    // getDetailsCampaign(startDate, endDate);
    // console.log(startDate,endDate)
    localStorage.setItem("startDate", startDate.toString());
    localStorage.setItem("endDate", endDate.toString());
    dispatch(
      setSelectedDateRange({
        startDate: startDate,
        endDate: endDate,
      })
    );
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  };

  const onDateSearch = () => {
    setdataComing(true);
    // const startDate = new Date(selectedDateRange.startDate);
    // const endDate = new Date(selectedDateRange.endDate);
    const startDate = new Date(selectedStartDate);
    const endDate = new Date(selectedEndDate);
    dispatch(
      setSelectedDateRange({
        startDate: startDate,
        endDate: endDate,
      })
    );
    getDetailsCampaign(startDate, endDate);
  };

  useEffect(() => {
    console.log("dateChanging");
    setdataComing(true);
    if (tableData.length == 0) {
      onDateSearch();
    } else {
      if (protectedData != undefined && protectedData.length > 0) {
        const data = protectedData;
        // console.log(tableData);
        setTimeout(() => {
          dispatch(setTableData(data));
        }, 500);
      }
    }
  }, [dateChanging]);

  // const [tableData, setTableData] = useState<any[]>([]);
  // const [protectedData, setProtectedData] = useState<any[]>([...tableData]);
  const [saveStatusVal, setSaveStatusVal] = useState<
    {
      applicationName: string;
      campaignName: string;
      clicks: number;
      cpc: number;
      cpm: number;
      ctr: number;
      dailyTarget: number;
      goal: string;
      id: string;
      impressions: number;
      isChecked: boolean;
      mediaCost: string;
      reach: string;
      status: string;
      total: string;
      cpi: number;
      installs: string;

      adGroups: {
        HTML5: string[];
        Status: string;
        adGroupsId: string;
        campaignId: string;
        createdAt: string;
        description: string[];
        headline: string[];
        image: string[];
        logo: string;
        name: string;
        updatedAt: string;
        videos: string[];
      }[];

      campaignData: {
        campaignId: string;
        AdvertiserId: string;
        TrakierCampaignId: string;
        ApplicationName: string;
        CampaignName: string;
        SubType: string;
        AppPlatform: string;
        AppLookUpDetails: {
          logoUrl: string;
          packageName: string;
        };
        Location: string;
        CustomerLanguage: string;
        TrackingPartner: string;
        TrackingURL: string;
        ImpressionURL: string;
        EventName: string;
        FinalClickURL: string;
        MandatoryMacros: string;
        OptionalMacros: string;
        FetchedFromTrakier: string;
        Status: string;
        Languages: string[];
        Token: {
          token: string;
          eventName: string;
        }[];
      };
      createdAt: string;
      updatedAt: string;

      budgetData: {
        budgetId: string;
        campaignId: string;
        createdAt: string;
        cpiCurrency: string;
        dailyAvgBudget: number;
        focusParam: string;
        isSetTargetCoatPerInstall: boolean;
        targetCostPerInstall: number;
        targetUserType: string;
        targetingType: string;
        updatedAt: string;
      };
    }[]
  >([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [advertiserNames, setAdvertiserNames] = useState<string[]>([]);
  const [advertisersData, setAdvertisersData] = useState<any[]>([]);
  const [applicationName, setApplicationName] = useState<string[]>([]);
  const [applicationData, setApplicationData] = useState<any[]>([]);
  const [campaignNames, setcampaignNames] = useState<any[]>([]);
  const [campaignData, setcampaignData] = useState<any[]>([]);

  // const [applicationId,setApplicationId] = useState<string[]>([])
  // const [dataIfUpdated, setDataIfUpdated] = useState(false);
  // const [constData, setConstData] = useState(CampaignArray);
  //  const [rows, setRows] = useState(CampaignArray);

  const handleChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setAdvertiserNames(typeof value === "string" ? value.split(",") : value);
  };
  const handleChangeApplication = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setApplicationName(typeof value === "string" ? value.split(",") : value);
  };
  const handleChangeCampaign = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setcampaignNames(typeof value === "string" ? value.split(",") : value);
  };
  const advertiserArray: any = [];
  const applicationArray: any = [];
  const campaignArray: any = [];

  advertisersList.forEach((obj: any) => {
    advertiserArray.push(obj.name);
  });
  applicationData.forEach((obj) => {
    applicationArray.push(obj.name);
  });
  campaignData.forEach((obj) => {
    campaignArray.push(obj.name);
  });

  const filteredData = () => {
    setPage(0);
    setRowsPerPage(rowsPerPageOptions[0]);
    let result =
      advertiserNames.length == 0
        ? protectedData
        : protectedData.filter((product: any) => {
            return advertiserNames.includes(product.name);
            // return advertiserNames.some(name => product.name.toLowerCase().includes(name.toLowerCase()));
          });

    let campaign =
      protectedData.length == 0
        ? protectedData
        : protectedData.filter((product: any) => {
            return campaignName.includes(product.campaignName);
            // return advertiserNames.some(name => product.name.toLowerCase().includes(name.toLowerCase()));
          });

    let filtered =
      applicationName.length == 0
        ? campaign
        : campaign.filter((product: any) => {
            return applicationName.includes(product.applicationName);
            // return advertiserNames.some(name => product.name.toLowerCase().includes(name.toLowerCase()));
          });
    // console.log("campaign", filtered);
    const filteredArray = result.filter(
      (obj: any, index: any, array: any) =>
        array.findIndex(
          (o: any) => o.applicationName === obj.applicationName
        ) === index
    );

    const campaignTemp = result.filter(
      (obj: any, index: any, array: any) =>
        array.findIndex((o: any) => o.campaignName === obj.campaignName) ===
        index
    );
    // const advertiserData = protectedData.filter(
    //   (obj, index, array) =>
    //     array.findIndex((o) => o.name === obj.name) === index
    // );
    // console.log("filteredArray", campaign,campaignTemp);
    // console.log();
    // setAdvertisersData(advertiserData);
    setcampaignData(campaignTemp);
    setApplicationData(filteredArray);
    setdataComing(false);
    dispatch(setTableData(filtered));
  };

  useEffect(() => {
    if (protectedData != undefined && protectedData.length > 0) {
      filteredData();
      let result =
        advertiserNames.length == 0
          ? protectedData
          : protectedData.filter((product: any) => {
              console.log(product.name);
              return advertiserNames.includes(product.name);
              // return advertiserNames.some(name => product.name.toLowerCase().includes(name.toLowerCase()));
            });

      let campaign =
        campaignNames.length == 0
          ? result
          : result.filter((product: any) => {
              console.log(product.name);
              return campaignNames.includes(product.campaignName);
              // return advertiserNames.some(name => product.name.toLowerCase().includes(name.toLowerCase()));
            });
      let filtered =
        applicationName.length == 0
          ? campaign
          : campaign.filter((product: any) => {
              console.log(product.name);
              return applicationName.includes(product.applicationName);
              // return advertiserNames.some(name => product.name.toLowerCase().includes(name.toLowerCase()));
            });

      const filteredArray = result.filter(
        (obj: any, index: any, array: any) =>
          array.findIndex(
            (o: any) => o.applicationName === obj.applicationName
          ) === index
      );

      const campaignTemp = result.filter(
        (obj: any, index: any, array: any) =>
          array.findIndex((o: any) => o.campaignName === obj.campaignName) ===
          index
      );
      // console.log(filteredArray);
      setcampaignData(campaignTemp);
      setApplicationData(filteredArray);
      setdataComing(false);
      dispatch(setTableData(filtered));
    }
  }, [advertiserNames, applicationName, campaignNames]);

  useEffect(() => {
    if (protectedData != undefined && protectedData.length > 0) {
      let result =
        advertiserNames.length == 0
          ? protectedData
          : protectedData.filter((product: any) => {
              console.log(product.name);
              return advertiserNames.includes(product.name);
              // return advertiserNames.some(name => product.name.toLowerCase().includes(name.toLowerCase()));
            });

      let campaign =
        campaignNames.length == 0
          ? result
          : result.filter((product: any) => {
              console.log(product.name);
              return campaignNames.includes(product.campaignName);
              // return advertiserNames.some(name => product.name.toLowerCase().includes(name.toLowerCase()));
            });
      let filtered =
        applicationName.length == 0
          ? campaign
          : campaign.filter((product: any) => {
              console.log(product.name);
              return applicationName.includes(product.applicationName);
              // return advertiserNames.some(name => product.name.toLowerCase().includes(name.toLowerCase()));
            });

      const filteredArray = filtered.filter(
        (obj: any, index: any, array: any) =>
          array.findIndex(
            (o: any) => o.applicationName === obj.applicationName
          ) === index
      );

      const campaignTemp = campaign.filter(
        (obj: any, index: any, array: any) =>
          array.findIndex((o: any) => o.campaignName === obj.campaignName) ===
          index
      );
      // console.log(filteredArray);
      setcampaignData(campaignTemp);
      setApplicationData(filteredArray);
      setdataComing(false);
      dispatch(setTableData(filtered));
    }
  }, [protectedData]);

  const getDetailsCampaign = (startDate: Date, endDate: Date) => {
    setAppLoader(true);
    setCampaignLoader(true);

    setdataComing(true);
    dispatch(setTableData([]));
    dispatch(setProtectedData([]));

    const encryptAdvertiserID = CryptoJS.AES.encrypt(
      JSON.stringify(advertiserId),
      secretKey
    ).toString();

    const year = startDate.getFullYear();
    const month = startDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month index
    const day = startDate.getDate();

    const startDatFormatted = `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`;

    const endyear = endDate.getFullYear();
    const endmonth = endDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month index
    const endday = endDate.getDate();

    const endDateFormatted = `${endyear}-${
      endmonth < 10 ? "0" + endmonth : endmonth
    }-${endday < 10 ? "0" + endday : endday}`;

    const jsonParam = {
      // advertiserId: isadmin ? selectedAdvertiserId : advertiserId,
      startDate: startDatFormatted,
      endDate: endDateFormatted,
    };

    console.log(jsonParam);
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(jsonParam),
      secretKey
    ).toString();

    // const url = `${apiURL}/api/advertisers/getAllAdvertisers`;
    const url = `${apiURL}/api/admin/campaigns/getAllCampaignTableData`;
    // const url = `http://localhost:10056/api/admin/campaigns/getAllCampaignTableData`
    if (authToken != "" && sessionId != "" && advertiserId != "") {
      axios
        .post(
          url,
          { params: encryptedData },
          {
            headers: {
              authtoken: authToken,
              sessionid: sessionId,
              advertiserid: encryptAdvertiserID,
              // "Content-Type": "application/json",
              // Example authorization header
            },
          }
        )
        .then((res) => {
          const resData = res.data.data;
          const bytes = CryptoJS.AES.decrypt(resData, secretKey);
          const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
          const parsedData = JSON.parse(decryptedData);
          // console.log(parsedData)
          if (parsedData.length != 0) {
            if (res.data.status) {
              // setdataComing(false);
              console.log(res.status);
              const resData = res.data.data;
              const bytes = CryptoJS.AES.decrypt(resData, secretKey);
              const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
              const parsedData = JSON.parse(decryptedData);
              // setAdvertisersData(parsedData);

              const allMergedData: any[] = [];
              parsedData.map((val: any) => {
                if (val.campaignData.length != 0) {
                  const mergedData = val.campaignData.map((campaign: any) => {
                    const upperParams = {
                      id: val.id,
                      advertiserId: val.advertiserId,
                      name: val.name,
                      totalCampaigns: val.totalCampaigns,
                      totalInstalls: val.totalInstalls,
                      totalBudget: val.totalBudget,
                      totalCredit: val.totalCredit,
                      totalSpends: val.totalSpends,
                      totalInstallsToday: val.totalInstallsToday,
                      lastLoginDate: val.lastLoginDate,

                      lastBilledAmount: val.lastBilledAmount,
                      totalSpendsLastMonth: val.totalSpendsLastMonth,
                      companyName: val.companyName,
                      profilePicture: val.profilePicture,
                      profilePictureUrl: val.profilePictureUrl,
                      creditLimit: val.creditLimit,
                      email: val.email,
                    };

                    return {
                      ...campaign,
                      ...upperParams,
                    };
                  });
                  allMergedData.push(...mergedData);
                }
              });
              const appData = allMergedData.filter((val: any) => {
                return val.applicationName != null;
              });

              console.log("mergedData", appData);
              const filteredArray = appData.filter(
                (obj, index, array) =>
                  array.findIndex(
                    (o) => o.applicationName === obj.applicationName
                  ) === index
              );

              const campaignTemp = appData.filter(
                (obj, index, array) =>
                  array.findIndex(
                    (o) => o.campaignName === obj.campaignName
                  ) === index
              );

              setcampaignData(campaignTemp);
              setApplicationData(filteredArray);
              dispatch(setTableData(appData));

              dispatch(setProtectedData(appData));
              // localStorage.setItem(
              //   "campaignTable",
              //   JSON.stringify(appData)
              // );
              if (parsedData.length != 0) {
                setDataContains(true);
              }
            }
          } else {
            setdataComing(false);
            setDataContains(false);
          }
          setAppLoader(false);
          setCampaignLoader(false);
        })
        .catch((err) => {
          setAppLoader(false);
          setCampaignLoader(false);
          setdataComing(false);
          console.log(err);
        });
    }
  };
  interface filtered {
    id: string;
    applicationName: string;
    campaignName: string;
    goal: string;
    dailyTarget: string;
    status: string;
    impressions: number;
    clicks: number;
    ctr: number | null;
    cpc: number;
    cpm: number | null;
    reach: number;
    mediaCost: number;
    total: number;
    isChecked: boolean; // Define the actual type for 'adGroups'
  }

  const saveStatus = (id: string) => {
    const ob = [...saveStatusVal];
    const fil = ob.filter((item) => {
      return item.id == id;
    });
    // console.log(fil);
    if (fil.length != 0) {
      UpdateCampaignApi(fil);
    }
    // console.log(ob);
    dispatch(setTableData(saveStatusVal));

    setToggleStatusInput(!toggleStatusInput);
  };

  const findIndex = (id: string) => {
    const foundItem = tableData.find((item: any) => item.id === id);
    if (foundItem) {
      return tableData.indexOf(foundItem);
    } else {
      return -1;
    }
  };
  const performSearch = (value: string) => {
    const searchTerm = value.toLowerCase();
    const campaignData = localStorage.getItem("campaignTable") || "";
    if (campaignData != "" && searchTerm && searchTerm !== null) {
      const data = JSON.parse(campaignData);
      if (data.length != 0) {
        const filteredResults = data.filter((item: any) => {
          return (
            (item.applicationName &&
              item.applicationName.toLowerCase().includes(searchTerm)) ||
            (item.campaignName &&
              item.campaignName.toLowerCase().includes(searchTerm))
          );
        });
        if (searchValue.length > 1 && filteredResults.length > 0) {
          // console.log(filteredResults);
          dispatch(setTableData(filteredResults));
        }
      }
    }
  };

  const toggleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const campaignData = localStorage.getItem("campaignTable") || "";
    setSearchValue(value);

    if (value == "") {
      if (campaignData != "") {
        const data = JSON.parse(campaignData);
        if (data.length != 0) {
          dispatch(setTableData(data));
        }
      }
    }
    performSearch(value);
  };

  const toggleStatus = (id: string, value: string) => {
    // console.log("here");
    let statusValue = findIndex(id);

    // Create a copy of the object to update
    const updatedObject = { ...tableData[statusValue] };

    // Update the value property
    updatedObject.campaignData = {
      ...updatedObject.campaignData,
      Status: value !== undefined ? value : "",
    };

    // Create a new array with the updated object
    const updatedArray = [
      ...tableData.slice(0, statusValue),
      updatedObject,
      ...tableData.slice(statusValue + 1),
    ];
    setSaveSelectedSelected(updatedArray[statusValue].campaignData.Status);
    // console.log(updatedArray, tableData);
    setSaveStatusVal(updatedArray);
  };

  const cancelStatus = () => {
    setSaveSelectedSelected("");
    setToggleStatusInput(!toggleStatusInput);
  };

  const toggleStatusButton = (id: string) => {
    // console.log("toggle status button ");
    if (isHoverStatus == id) {
      setToggleStatusInput(!toggleStatusInput);
      setStatusDpd(id);
      setIsHoverStatus(id);
    }
  };

  const UpdateCampaignApi = (data: any) => {
    // const advertiserId = localStorage.getItem("advertiserID") || "";
    const email = localStorage.getItem("emailID") || "";
    const campSubtype = localStorage.getItem("campaignSubtype") || "";
    const appPlatform = localStorage.getItem("appPlatform") || "";
    const selectLanguages = localStorage.getItem("selectedLang") || "";
    const converted = selectLanguages != "" ? JSON.parse(selectLanguages) : [];

    const trackingPart = localStorage.getItem("trackingPartner");
    const trackingURL = localStorage.getItem("trackingUrl") || "";
    const tokenEventData = localStorage.getItem("inputTokenEventData") || "";
    const currency = localStorage.getItem("currency");

    // const countryCode = localStorage.getItem("countryCode") || '';
    // const installationCost = localStorage.getItem("costPerInstall") || "0";
    const currencyCode = localStorage.getItem("countryCode");
    // console.log("data", data);
    // const authToken = localStorage.getItem("authToken");
    // const sessionID = localStorage.getItem("sessionID");
    data.map((item: any) => {
      const jsonParam = {
        AdvertiserId: isadmin ? selectedAdvertiserId : advertiserId,
        CampaignId: item.id,
        Email: email,
        ApplicationName: item.applicationName, // harCoded
        CampaignName: item.campaignName,
        // SubType: item.campSubtype,
        // AppPlatform: appPlatform,
        // AppLookUpDetails: item.lookup,
        // TrackingPartne: trackingPart,
        // TrackingURL: trackingURL,
        // Token: tokenEventData != "" ? JSON.parse(tokenEventData) : [],
        Currency: currencyCode,
        Status: item.campaignData.Status,
        // Payouts: [
        //   {
        //     payout: 0,
        //     revenue: "0",
        //     geo: ["ALL"],
        //   },
        // ],
        Languages: converted,
      };
      // console.log(jsonParam);
      const encryptAdvertiserID = CryptoJS.AES.encrypt(
        JSON.stringify(advertiserId),
        secretKey
      ).toString();

      const url = `${apiURL}/api/campaigns/updateCampaign`;
      if (authToken != "" && sessionId != "" && advertiserId != "") {
        axios
          .post(url, jsonParam, {
            headers: {
              authtoken: authToken,
              sessionid: sessionId,
              advertiserid: encryptAdvertiserID,
              "Content-Type": "application/json",
              // Example authorization header
            },
          })
          .then((res) => {
            // console.log("res", res);
            if (res.status == 201) {
              // const resData = res.data;
              // const bytes = CryptoJS.AES.decrypt(resData, secretKey);
              // const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
              // const parsedData = JSON.parse(decryptedData);
              localStorage.setItem("campaignId", res.data.campaignId);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  useEffect(() => {
    // console.log(tableData);
    // if (tableData.length == 0) {
    //   onDateSearch();
    // }
    setdataComing(true);
    dispatch(setCompanyName(""));
    dispatch(setLocationCheck(""));
    dispatch(setImageUploadError(""));
    dispatch(setLogoError(""));
    dispatch(setCount(0));
    dispatch(setStatus("pending"));
    dispatch(setCampaignSubType(""));
    dispatch(setAppPlatform(""));
    dispatch(setAppName(""));
    dispatch(setCampaignName(""));
    dispatch(setAppImageUrl(""));
    dispatch(setPackageName(""));

    //CAMPAIGN SETTING

    dispatch(setLanguage([]));
    dispatch(setLocation(""));
    dispatch(setTracking(""));
    dispatch(setTrackingUrl(""));
    dispatch(setImpressionUrl(""));
    dispatch(setTokenEvent([]));
    dispatch(setTempTransactionCurrency(""));
    // dispatch(addTokenEventObject(''))
    // dispatch(removeTokenEventObject(''))
    // dispatch(updateToken(0, ""));
    // dispatch(updateEvent(0, ""));
    dispatch(setLocationCode(""));

    // BUDGET BIDDING
    dispatch(setBudget(""));
    dispatch(setFocus(""));
    dispatch(setTarget(""));
    dispatch(setCostPerInstall(""));

    // localStorage.setItem("budget", "");
    // localStorage.setItem("focusDpd", "");
    // localStorage.setItem("trackDpd", "");
    // localStorage.setItem("costPerInstall", "");
    // localStorage.setItem("targetApp", "");

    //----AD GROUP

    dispatch(setAdGroupName(""));
    dispatch(setLogoUrl(""));
    dispatch(setHeadline([""]));
    dispatch(setDescription([""]));
    dispatch(setImageUrl([]));
    dispatch(setVideoUrl([]));
    dispatch(setHtmlUrl([]));
    dispatch(setImageFile(""));
    // const emptyFile = new File([], 'empty.txt', { type: 'text/plain' });
    dispatch(setVideoFile([]));
    dispatch(setHtmlFile([]));

    dispatch(setCampaignId(""));
    dispatch(setBudgetId(""));

    dispatch(setDataSource(""));
    dispatch(setDataSourceError(""));

    dispatch(setSegmentName(""));
    dispatch(setSegmentNameError(""));

    dispatch(setDataType(""));
    dispatch(setDataTypeError(""));

    dispatch(setDataUpload([]));
    dispatch(setDataUploadError([]));

    dispatch(setUploadedUrl(""));
    dispatch(setUploadedUrlError(""));

    // getDetailsCampaign(selectedStartDate, selectedEndDate);
  }, []);

  const handleMouseEnterDownload = () => {
    setIsOpen(true);
  };

  const handleMouseLeaveDownload = () => {
    setIsOpen(false);
  };
  const handleStatusMouseEnter = (id: string) => {
    setIsHoverStatus(id);
  };

  const handleStatusMouseLeave = (id: string) => {
    setIsHoverStatus(id);
  };

  const setData = (filtered: any) => {
    const stringifyData = JSON.stringify(filtered[0]);
    console.log("function", filtered);
    localStorage.setItem("editingData", stringifyData);
    if (
      filtered.length != 0 &&
      filtered[0] != undefined &&
      filtered[0].campaignData != undefined
    ) {
      dispatch(setAdvertiserIdCampaign(filtered[0].advertiserId));
      // console.log(filtered[0].advertiserId)
      dispatch(setCount(0));
      dispatch(
        setCampaignStatus(
          filtered[0].campaignData.Status != null
            ? filtered[0].campaignData.Status
            : ""
        )
      );
      dispatch(setId(filtered[0].id != null ? filtered[0].id : ""));
      dispatch(
        setCreatedDate(
          filtered[0].campaignData.createdAt != null
            ? filtered[0].campaignData.createdAt
            : ""
        )
      );
      dispatch(
        setActivatedDate(
          filtered[0].campaignData.updatedAt != null
            ? filtered[0].campaignData.updatedAt
            : ""
        )
      );

      dispatch(setClicks(filtered[0].clicks ? filtered[0].clicks : "0"));
      dispatch(
        setImpression(filtered[0].impression ? filtered[0].impression : "0")
      );
      dispatch(
        setConversion(filtered[0].installs ? filtered[0].installs : "0")
      );
      dispatch(setInstall(filtered[0].installs ? filtered[0].installs : "0"));
      dispatch(setPayout(filtered[0].cpi ? filtered[0].cpi : "0"));
      dispatch(setCtr(filtered[0].ctr ? filtered[0].ctr : "0"));
      dispatch(setCpi(filtered[0].cpi ? filtered[0].cpi : "0"));

      dispatch(
        setAdvertiserIdCampaign(
          filtered[0].advertiserId != null ? filtered[0].advertiserId : ""
        )
      );

      dispatch(
        setCompanyName(
          filtered[0].companyName != null && filtered[0].companyName != "null"
            ? filtered[0].companyName
            : ""
        )
      );
      dispatch(
        setCampaignSubType(
          filtered[0].campaignData.SubType != null
            ? filtered[0].campaignData.SubType
            : ""
        )
      );
      // console.log(dispatch(setCampaignSubType(filtered[0].campaignData.SubType)))
      dispatch(
        setAppPlatform(
          filtered[0].campaignData.AppPlatform != null
            ? filtered[0].campaignData.AppPlatform
            : ""
        )
      );

      dispatch(
        setCompanyImage(
          filtered[0].campaignData.profilePictureUrl != null
            ? filtered[0].campaignData.profilePictureUrl
            : ""
        )
      );

      dispatch(
        setAppName(
          filtered[0].campaignData.ApplicationName != null
            ? filtered[0].campaignData.ApplicationName
            : ""
        )
      );
      dispatch(
        setCreditLimit(
          filtered[0].creditLimit != null
            ? parseInt(filtered[0].creditLimit)
            : 0
        )
      );

      dispatch(
        setLastBilledAmount(
          filtered[0].lastBilledAmount != null
            ? filtered[0].lastBilledAmount
            : 0
        )
      );
      dispatch(
        setTotalSpendsLastMonth(
          filtered[0].totalSpendsLastMonth != null
            ? filtered[0].totalSpendsLastMonth
            : 0
        )
      );

      dispatch(
        setCampaignName(
          filtered[0].campaignData.CampaignName != null
            ? filtered[0].campaignData.CampaignName
            : ""
        )
      );

      dispatch(
        setTokenEvent(
          filtered[0].campaignData.Token != null &&
            filtered[0].campaignData.Token.length != 0
            ? filtered[0].campaignData.Token
            : [{ token: "", eventName: "" }]
        )
      );

      dispatch(
        setLocationCode(
          filtered[0].campaignData.Location != null
            ? filtered[0].campaignData.Location
            : ""
        )
      );
      //new Changes
      if (filtered[0].campaignData.RetargetingOptions != null) {
        dispatch(
          setDataSource(
            filtered[0].campaignData.RetargetingOptions.dataSource != null
              ? filtered[0].campaignData.RetargetingOptions.dataSource
              : ""
          )
        );
        dispatch(
          setSegmentName(
            filtered[0].campaignData.RetargetingOptions.segmentName != null
              ? filtered[0].campaignData.RetargetingOptions.segmentName
              : ""
          )
        );
        dispatch(
          setDataType(
            filtered[0].campaignData.RetargetingOptions.dataType != null
              ? filtered[0].campaignData.RetargetingOptions.dataType
              : ""
          )
        );
        dispatch(
          setUploadedUrl(
            filtered[0].campaignData.RetargetingOptions.fileUrl != null
              ? filtered[0].campaignData.RetargetingOptions.fileUrl
              : ""
          )
        );
      }
      dispatch(
        setCampaignId(
          filtered[0].campaignData.campaignId != null
            ? filtered[0].campaignData.campaignId
            : ""
        )
      );

      dispatch(
        setStatus(
          filtered[0].campaignData.Status != null
            ? filtered[0].campaignData.Status
            : ""
        )
      );
      if (filtered[0].campaignData.AppLookUpDetails != null) {
        dispatch(
          setAppImageUrl(
            filtered[0].campaignData.AppLookUpDetails.logoUrl != null
              ? filtered[0].campaignData.AppLookUpDetails.logoUrl
              : ""
          )
        );
        dispatch(
          setPackageName(
            filtered[0].campaignData.AppLookUpDetails.packageName != null
              ? filtered[0].campaignData.AppLookUpDetails.packageName
              : ""
          )
        );
        dispatch(
          setLanguage(
            filtered[0].campaignData.Languages != null
              ? filtered[0].campaignData.Languages
              : ""
          )
        );
        dispatch(
          setLocation(
            filtered[0].campaignData.Location != null
              ? filtered[0].campaignData.Location
              : ""
          )
        );
        if (
          filtered[0].campaignData.Location != null &&
          filtered[0].campaignData.Location != "IN" &&
          filtered[0].campaignData.Location != "ALL"
        ) {
          dispatch(setLocationCheck("another"));
        } else {
          dispatch(
            setLocationCheck(
              filtered[0].campaignData.Location != null
                ? filtered[0].campaignData.Location
                : ""
            )
          );
        }
        dispatch(
          setTracking(
            filtered[0].campaignData.TrackingPartner != null
              ? filtered[0].campaignData.TrackingPartner
              : ""
          )
        );
        dispatch(
          setTrackingUrl(
            filtered[0].campaignData.TrackingURL != null
              ? filtered[0].campaignData.TrackingURL
              : ""
          )
        );
        dispatch(
          setImpressionUrl(
            filtered[0].campaignData.FinalClickURL != null
              ? filtered[0].campaignData.FinalClickURL
              : ""
          )
        );
      }

      // (filtered[0].campaignData.Token.length !=0){
      //   filtered[0].campaignData.Token.map((value: any, index:nuber)=>({

      //   }))

      // }
      // console.log(filtered[0].campaignData.Token != null &&
      //   filtered[0].campaignData.Token.length != 0 )

      // if (
      //   filtered[0].campaignData.Token != null &&
      //   filtered[0].campaignData.Token.length != 0
      // ) {
      //   dispatch(
      //     updateToken(
      //       0,
      //       filtered[0].campaignData.Token[0].token != null
      //         ? filtered[0].campaignData.Token[0].token
      //         : ""
      //     )
      //   );
      //   dispatch(
      //     updateEvent(
      //       0,
      //       filtered[0].campaignData.Token[0].eventName != null
      //         ? filtered[0].campaignData.Token[0].eventName
      //         : ""
      //     )
      //   );
      // }

      // dispatch(addTokenEventObject(filtered[0]))
      // dispatch(removeTokenEventObject(filtered[0]))
      // console.log(dispatch(setBudget(filtered[0])))
      // console.log();
      if (Object.keys(filtered[0].budgetData).length !== 0) {
        dispatch(
          setBudgetId(
            filtered[0].budgetData.budgetId != null
              ? filtered[0].budgetData.budgetId
              : ""
          )
        );
        dispatch(
          setBudget(
            filtered[0].budgetData.dailyAvgBudget != null
              ? filtered[0].budgetData.dailyAvgBudget.toString()
              : ""
          )
        );
        dispatch(
          setFocus(
            filtered[0].budgetData.focusParam != null
              ? filtered[0].budgetData.focusParam
              : ""
          )
        );
        dispatch(
          setTarget(
            filtered[0].budgetData.targetingType != null
              ? filtered[0].budgetData.targetingType
              : ""
          )
        );
        dispatch(
          setCostPerInstall(
            filtered[0].budgetData.targetCostPerInstall != null
              ? filtered[0].budgetData.targetCostPerInstall.toString()
              : ""
          )
        );

        dispatch(
          setTransactionCurrency(
            filtered[0].budgetData.cpiCurrency != null
              ? filtered[0].budgetData.cpiCurrency
              : ""
          )
        );
        dispatch(
          setDailyAvgBudget(
            filtered[0].budgetData.dailyAvgBudget != null
              ? filtered[0].budgetData.dailyAvgBudget
              : "0"
          )
        );
      }
      if (filtered[0].adGroups.length != 0) {
        dispatch(setAdGroupName(filtered[0].adGroups[0].name));
        dispatch(setLogoUrl(filtered[0].adGroups[0].logo));
        dispatch(setHeadline(filtered[0].adGroups[0].headline));
        dispatch(setDescription(filtered[0].adGroups[0].description));
        dispatch(setImageUrl(filtered[0].adGroups[0].image));
        dispatch(setVideoUrl(filtered[0].adGroups[0].videos));
        dispatch(setHtmlUrl(filtered[0].adGroups[0].HTML5));
      }

      // console.log(dispatch(setCampaignSubType(filtered[0].campaignData.SubType)))
    }
  };

  const toggleDropdown = (data: any) => {
    dispatch(setCount(0));

    const filtered = data.filter((item: any) => {
      return item.isChecked == true;
    });
    // console.log(filtered,Object.keys(filtered[0].budgetData).length !== 0);

    // setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);
    setData(filtered);

    // window.location.replace("/home/campaigns/createCampaign");

    if (filtered.length > 0) {
      setTimeout(() => {
        navigate("editCampaignAdmin");
      }, 500);
    }
    // if (type == "dwn") {
    //   setIsOpen(!isOpen);
    // } else {
    //   setIsOpenEdit(!isOpenEdit);
    // }
  };

  const [sortConfig, setSortConfig] = useState<{
    key: any | null;
    direction: "asc" | "desc";
  }>({
    key: null,
    direction: "asc",
  });

  const requestSort = (key: any) => {
    let direction: "asc" | "desc" = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    sortedData();
  }, [sortConfig]);

  const sortedData = (): any => {
    const sortableData = [...tableData];

    if (sortConfig.key) {
      sortableData.sort((a: any, b: any) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    dispatch(setTableData([...sortableData]));
  };

  function exportToCSV(data: any[]) {
    // const csvWriter = createObjectCsvWriter({
    //   path: "campaign_data.csv",
    //   header: [
    //     { id: "id", title: "ID" },
    //     { id: "applicationName", title: "Application Name" },
    //     { id: "status", title: "Status" },
    //     { id: "campaign", title: "Campaign" },
    //     { id: "goal", title: "Goal" },
    //     { id: "dailyTarget", title: "Daily Target" },
    //     { id: "impressions", title: "Impressions" },
    //     { id: "clicks", title: "Clicks" },
    //     { id: "installs", title: "Installs" },
    //   ],
    // });
    // console.log(data);
    // csvWriter
    //   .writeRecords(data)
    //   .then(() => {
    //     console.log("CSV data was exported successfully");
    //   })
    //   .catch((error) => {
    //     console.log("CSV data was not exported", error);
    //   });
  }

  const downloadExcel = () => {
    if (tableData.length != 0) {
      const worksheet = XLSX.utils.json_to_sheet(tableData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const dataBlob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(dataBlob, "Campaign Report.xlsx"); // Specify the desired file name here
    } else {
      alert("Download data is not available");
    }
  };

  const fileDownload = (type: string) => {
    if (type == "doc") {
      exportToCSV(tableData);
    } else {
      downloadExcel();
    }
  };
  // const cancelStatus = () => {
  //   setToggleStatusInput(!toggleStatusInput);
  // };

  const closeDropdown = (type: string) => {
    if ((type = "dwn")) {
      setIsOpen(false);
    } else {
      setIsOpenEdit(false);
    }
  };

  const dataToFilter = (data: string) => {
    // console.log(data);
    if (data == "expired") {
      const filteredData = tableData.filter(
        (item: any) => item.isChecked !== true
      );
      dispatch(setTableData(filteredData));
      const updatedData = tableData.map((item: any) => {
        if (item.isChecked) {
          return { ...item, status: data };
        }
        return item;
      });
      const filtered = updatedData.filter(
        (item: any) => item.isChecked == true
      );
      // console.log(filtered);
      if (filtered.length != 0) {
        UpdateCampaignApi(filtered);
      }
    } else {
      const updatedData = tableData.map((item: any) => {
        if (item.isChecked) {
          return { ...item, status: data };
        }
        return item;
      });
      const filtered = updatedData.filter(
        (item: any) => item.isChecked == true
      );
      // console.log(filtered);
      if (filtered.length != 0) {
        UpdateCampaignApi(filtered);
      }
      dispatch(setTableData(updatedData));
    }
  };

  const onClickNavigate = (id: string) => {
    // console.log(tableData)
    const filteredData = tableData.filter((value: any) => {
      return value.campaignData.campaignId == id;
    });
    // console.log(filteredData);
    // localStorage.setItem("editingData", "");
    localStorage.setItem("campaignRecordData", JSON.stringify(filteredData));
    window.location.replace(`/home/campaigns/campaignsRecord?id=${id}`);
  };

  const handleCheckboxClick = (id: string) => {
    const UpdatedRow = tableData.map((item: any) => {
      console.log(item.campaignData.campaignId == id);
      if (item.campaignData.campaignId == id) {
        return { ...item, isChecked: !item.isChecked };
      }
      return item;
    });
    // console.log(UpdatedRow)
    let itemCheck = UpdatedRow.map((item: any) => {
      return { ...item, isChecked: item.campaignData.campaignId === id };
    });
    const iconcheck = itemCheck.filter((item: any) => {
      return item.isChecked != true;
    });

    if (itemCheck.length !== iconcheck.length) {
      setShowEdit(true);
    } else {
      setShowEdit(false);
    }
    dispatch(setTableData(itemCheck));
    toggleDropdown(itemCheck);
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    } else {
      setSelectedRows([id]);
    }
  };

  const [selectedRange, setSelectedRange] = useState<any>({
    startDate: new Date(),
    endDate: new Date(),
  });

  useEffect(() => {
    // const adId = localStorage.getItem("advertiserID") || ""
    // const item = localStorage.setItem("editingData", "");
    // const startDate = localStorage.getItem("startDate")!;
    // const endDate = localStorage.getItem("endDate")!;
    // if (startDate != "" && endDate != "") {
    //   const s = new Date(startDate);
    //   const e = new Date(endDate);
    //   setSelectedStartDate(s);
    //   setSelectedEndDate(e);
    //   console.log("here", startDate, endDate);
    //   getDetailsCampaign(s, e);
    // } else {
    //   if (dates != null) {
    //     const s = new Date(dates.startDate);
    //     const e = new Date(dates.endDate);
    //     setSelectedStartDate(s);
    //     setSelectedEndDate(e);
    //     console.log(dates);
    //     console.log("here 2", s, e);
    //     getDetailsCampaign(s, e);
    //   }
    // }

    if (selectedDateRange.endDate != "" && selectedDateRange.startDate != "") {
      const s = new Date(selectedDateRange.startDate);
      const e = new Date(selectedDateRange.endDate);
      // setSelectedStartDate(s);
      // setSelectedEndDate(e);
      setSelectedRange({
        startDate: s,
        endDate: e,
      });
    }
  }, [selectedDateRange]);

  useEffect(() => {
    // console.log("useEffect Wroking")
    if (dates != null) {
      const s = new Date(dates.startDate);
      const e = new Date(dates.endDate);
      setSelectedStartDate(s);
      setSelectedEndDate(e);
    }
  }, [dates]);

  useEffect(() => {
    // getDetailsCampaign(selectedRange.startDate, selectedRange.endDate);
  }, [selectedRange]);

  const NewCampaign = () => {
    dispatch(setCount(0));
    dispatch(setStatus("pending"));
    dispatch(setCampaignSubType(""));
    dispatch(setAppPlatform(""));
    dispatch(setAppName(""));
    dispatch(setCampaignName(""));
    dispatch(setAppImageUrl(""));
    dispatch(setPackageName(""));
    dispatch(setCreditLimit(""));
    dispatch(setCreditLimit(0));
    dispatch(setLastBilledAmount(0));
    dispatch(setTotalSpendsLastMonth(0));
    dispatch(setCompanyImage(""));
    dispatch(setAdvertiserName(""));
    dispatch(setAdvertiserIdCampaign(""));
    //CAMPAIGN SETTING

    dispatch(setLanguage([]));
    dispatch(setLocation(""));
    dispatch(setTracking(""));
    dispatch(setTrackingUrl(""));
    dispatch(setImpressionUrl(""));
    dispatch(
      setTokenEvent([
        { token: "", eventName: "" },
        // other elements...
      ])
    );
    dispatch(setLocationCode(""));

    // BUDGET BIDDING
    dispatch(setBudget(""));
    dispatch(setFocus(""));
    dispatch(setTarget(""));
    dispatch(setCostPerInstall(""));

    dispatch(setAdGroupName(""));
    dispatch(setLogoUrl(""));
    dispatch(setHeadline([]));
    dispatch(setDescription([]));
    dispatch(setImageUrl([]));
    dispatch(setVideoUrl([]));
    dispatch(setHtmlUrl([]));
    dispatch(setImageFile(""));
    dispatch(setVideoFile([]));
    dispatch(setHtmlFile([]));

    dispatch(setCampaignId(""));
    dispatch(setBudgetId(""));
    localStorage.setItem("editingData", "");
    navigate("adminCreateCampaign");
  };

  const CustomSelect = styled(Select)(({ theme }) => ({
    fontSize: "14px",
    fontWeight: "600",
    fontFamily: "Manrope",
    width: "95%",
    height: "40px",
    marginRight: "10px",
    borderRadius: "3px",
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid black",
    },
  }));
  const menuItemStyle = {
    fontSize: "12px",
    fontFamily: "Manrope",
    fontWeight: 600,
    "& .MuiMenuItem-selected": {
      backgroundColor: "#6800b8",
    },
  };
  const CustomCheck = styled(Checkbox)`
    &.MuiButtonBase-root.MuiCheckbox-root {
      padding: 0 9px;
    }
  `;

  const apiCall = () => {
    // Convert dates to milliseconds
    const startMillis = selectedDateRange.startDate.getTime();
    const endMillis = selectedDateRange.endDate.getTime();

    // Calculate the difference in milliseconds
    const diffMillis = endMillis - startMillis;

    // Calculate the difference in months
    const millisPerDay = 1000 * 60 * 60 * 24;
    const daysInMonth = 30.436875; // Average days per month
    const diffMonths = diffMillis / (millisPerDay * daysInMonth);

    // Check if the difference is more than 3 months
    if (diffMonths > 3) {
      alert("The date range should not be more than 3 months.");
      // const userConfirmed = confirm("The date range should not be more than 3 months. Do you want to proceed anyway?");
      // if (!userConfirmed) {
      //   // User clicked "Cancel"
      //   console.log("User canceled the action.");
      //   return;
      // }else{
      const start = new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 2,
        1
      );
      const end = new Date();
      dispatch(
        setSelectedDateRange({
          startDate: start,
          endDate: end,
        })
      );
      // }
      return;
    } else {
      getDetailsCampaign(changedDate.startDate, changedDate.endDate);
    }
    // performanceReport(selectedRange.startDate, selectedRange.endDate,"441dc9f5-7af2-4220-abae-02f584ef9553")
  };

  const [appLoader, setAppLoader] = useState(false);
  const [campaignLoader, setCampaignLoader] = useState(false);

  const CustomIconComponent: FC<{ loading: boolean }> = ({ loading }) => (
    <div
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        marginRight: "8px",
        cursor: "pointer",
      }}
    >
      {loading ? (
        <CircularProgress style={{ color: customCheckBoxColor }} size="1rem" />
      ) : (
        <ExpandMoreIcon
          sx={{
            color: "#000000",
            opacity: 0.55,
          }}
        />
      )}
    </div>
  );

  return (
    <div>
      <div className="fixed-position" style={{ height: "228px" }}>
        <div className="campaign-screen-header">
          <div>
            <div ref={boxRef} className="box">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <h5
                  style={{
                    fontWeight: 800,
                    fontSize: "14px",
                    marginRight: "8px",
                  }}
                >
                  Custom
                </h5>
                <div className="calender-btn" onClick={toggleCalender}>
                  <img src={calender} alt="calendar" />
                  <p>
                    {changedDate.startDate.toLocaleDateString("default", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}
                  </p>
                  -
                  <p>
                    {changedDate.endDate.toLocaleDateString("default", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: `${toggleCal}`,
                  position: "absolute",
                  right: "32%",
                }}
              >
                <DateRangePicker
                  ranges={[changedDate]}
                  onChange={handleSelect}
                  maxDate={new Date()}
                />
                <div className="button-container">
                  <button
                    className="purple-btn width-adjust"
                    onClick={doneClose}
                    style={{
                      margin: "0 15px",
                      cursor: "pointer",
                    }}
                  >
                    Done
                  </button>
                  <button className="border-btn" onClick={toggleCalender}>
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* <DateCustomPicker
            onDateChange={handleDateChange}
            onClick={apiCall}
            rightPosition={true}
          /> */}
          <div
            style={{
              marginLeft: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* <PurpleCustomButton
            onClick={toggleDropdown}
            disable={true}
            children={<div className="button-div">Edit Campaign</div>}
          />
          &nbsp;&nbsp;&nbsp;&nbsp; */}
            <button className="transparent-btn" onClick={NewCampaign}>
              + &nbsp;&nbsp;New Campaign
            </button>
          </div>
        </div>

        <div
          className="white-container"
          style={{ display: "flex", clear: "both" }}
        >
          <div className="select-div-alignment">
            <div className="select-div-size">
              <div className="sub-heading">Select Brand / Agency</div>
              <div>
                <CustomSelect
                  multiple
                  value={advertiserNames}
                  IconComponent={ExpandMoreIcon}
                  onChange={handleChange}
                  renderValue={(selected: any) => selected.join(", ")}
                >
                  {/* <MenuItem sx={menuItemStyle} value="selectAll" onClick={handleToggleAll}>
                        <Checkbox checked={selectAll} />
                        <ListItemText primary="Select All" />
                    </MenuItem> */}
                  {advertisersList.map((item: any) => (
                    <MenuItem
                      sx={menuItemStyle}
                      key={item.id}
                      value={item.advertiserName}
                    >
                      <CustomCheck
                        style={{ color: "#6900b8" }}
                        checked={
                          advertiserNames.indexOf(item.advertiserName) > -1
                        }
                      />
                      <ListItemText primary={item.advertiserName} />
                    </MenuItem>
                  ))}
                </CustomSelect>
              </div>
            </div>
            <div className="select-div-size">
              <div className="sub-heading">Campaign</div>
              <div>
                <CustomSelect
                  multiple
                  value={campaignNames}
                  IconComponent={(props) => (
                    <CustomIconComponent
                      loading={campaignLoader === true}
                      {...props}
                    />
                  )}
                  onChange={handleChangeCampaign}
                  renderValue={(selected: any) => selected.join(", ")}
                >
                  {campaignLoader == false ? (
                    campaignData.length != 0 ? (
                      campaignData.map((item: any) => (
                        <MenuItem
                          sx={menuItemStyle}
                          key={item.id}
                          value={item.campaignName}
                        >
                          <CustomCheck
                            style={{ color: "#6900b8" }}
                            checked={
                              campaignNames.indexOf(item.campaignName) > -1
                            }
                          />
                          <ListItemText primary={item.campaignName} />
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem className="menuItemStyle">
                        No Campaign found
                      </MenuItem>
                    )
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress
                        style={{ color: customCheckBoxColor }}
                        size="1.5rem"
                      />
                    </div>
                  )}
                </CustomSelect>
              </div>
            </div>
            <div className="select-div-size">
              <div className="sub-heading">Application</div>
              <CustomSelect
                multiple
                value={applicationName}
                IconComponent={(props) => (
                  <CustomIconComponent
                    loading={appLoader === true}
                    {...props}
                  />
                )}
                onChange={handleChangeApplication}
                renderValue={(selected: any) => selected.join(", ")}
              >
                {appLoader == false ? (
                  applicationData.length != 0 ? (
                    applicationData.map((item: any) => (
                      <MenuItem
                        sx={menuItemStyle}
                        key={item.id}
                        value={item.applicationName}
                      >
                        <CustomCheck
                          checked={
                            applicationName.indexOf(item.applicationName) > -1
                          }
                          style={{ color: "#6900b8" }}
                        />
                        <ListItemText primary={item.applicationName} />
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem className="menuItemStyle">
                      No Application found
                    </MenuItem>
                  )
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress
                      style={{ color: customCheckBoxColor }}
                      size="1.5rem"
                    />
                  </div>
                )}
              </CustomSelect>
            </div>
          </div>
        </div>

        <div
          className="top-box"
          // style={{ display: dataContains ? "flex" : "none" }}
        >
          <div className="center-align">
            <img src={sound} />
            <h3>All Campaigns</h3>&nbsp;
            <span>
              (Showing {tableData.length} of Total{" "}
              {protectedData != undefined && protectedData.length})
            </span>
          </div>

          <div className="center-align">
            {/* <div className="dropdown">
            <button
              className="center-align btn-dwn dropdown-button"
              onClick={toggleDropdown}
              style={{ width: "75px" }}
              // onMouseEnter={handleMouseEnter}
              // onMouseLeave={handleMouseLeave}
            >
              Edit
              <img src={edit} className="dwn-icon" />
            </button>{" "}
          </div> */}

            <div
              className="dropdown"
              style={{ marginRight: "10px", marginLeft: "10px" }}
            >
              <button
                className="center-align btn-dwn dropdown-button"
                // onClick={() => toggleDropdown("dwn")}
                onMouseEnter={handleMouseEnterDownload}
                onMouseLeave={handleMouseLeaveDownload}
              >
                Download
                <LuDownload className="dwn-icon" />
              </button>

              {isOpen && (
                <ul
                  className="dropdown-menu"
                  // onClick={() => closeDropdown("dwn")}
                  onMouseEnter={handleMouseEnterDownload}
                  onMouseLeave={handleMouseLeaveDownload}
                >
                  <li>
                    {tableData.length == 0 ? (
                      <li
                        onClick={() => {
                          alert("Download data is not available");
                        }}
                      >
                        Document (.csv) file
                      </li>
                    ) : (
                      <CSVLink data={tableData} filename="Campaign Report">
                        Document (.csv) file
                      </CSVLink>
                    )}
                  </li>
                  <li onClick={() => fileDownload("xlsx")}>
                    Excel (.xlsx) file
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="campaigns-box">
        {tableData.length != 0 ? (
          <TableContainer component={Paper} style={{ marginTop: "230px" }}>
            <Table style={{ display: dataContains ? "table" : "none" }}>
              <TableHead>
                <TableRow>
                  {/* {shouldHideDiv ? null : <TableCell></TableCell>} */}
                  <TableCell
                    style={{
                      borderBottom: "none",
                      fontWeight: 900,
                      color: "#6900b8",
                    }}
                  >
                    Campaigns
                  </TableCell>
                  <TableCell
                    // style={{ fontWeight: 900, color: "#6900b8" }}
                    style={{
                      borderBottom: "none",
                      fontWeight: 900,
                      color: "#6900b8",
                    }}
                  >
                    Brand
                  </TableCell>
                  <TableCell
                    // style={{ fontWeight: 900, color: "#6900b8" }}
                    style={{
                      borderBottom: "none",
                      fontWeight: 900,
                      color: "#6900b8",
                    }}
                  >
                    <TableSortLabel
                      direction={
                        sortConfig.key === "status"
                          ? sortConfig.direction
                          : "asc"
                      }
                      active={sortConfig.key === "status"}
                      onClick={() => requestSort("status")}
                      IconComponent={KeyboardArrowDown}
                      style={{ fontWeight: 900, color: "#6900b8" }}
                    >
                      Status
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    // style={{ fontWeight: 900, color: "#6900b8" }}
                    style={{
                      borderBottom: "none",
                      fontWeight: 900,
                      color: "#6900b8",
                    }}
                  >
                    Application
                  </TableCell>
                  <TableCell
                    // style={{ fontWeight: 900, color: "#6900b8" }}
                    style={{
                      borderBottom: "none",
                      fontWeight: 900,
                      color: "#6900b8",
                    }}
                  >
                    Category
                  </TableCell>
                  <TableCell
                    //  style={{ fontWeight: 900, color: "#6900b8" }}
                    style={{
                      borderBottom: "none",
                      fontWeight: 900,
                      color: "#6900b8",
                    }}
                  >
                    <TableSortLabel
                      direction={
                        sortConfig.key === "appPlatform"
                          ? sortConfig.direction
                          : "asc"
                      }
                      active={sortConfig.key === "appPlatform"}
                      onClick={() => requestSort("appPlatform")}
                      IconComponent={KeyboardArrowDown}
                      style={{ fontWeight: 900, color: "#6900b8" }}
                    >
                      OS
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    // style={{ fontWeight: 900, color: "#6900b8" }}
                    style={{
                      borderBottom: "none",
                      fontWeight: 900,
                      color: "#6900b8",
                    }}
                  >
                    GEO
                    {/* <TableSortLabel
                      direction={
                        sortConfig.key === "targetingType"
                          ? sortConfig.direction
                          : "asc"
                      }
                      active={sortConfig.key === "targetingType"}
                      onClick={() => requestSort("targetingType")}
                      IconComponent={KeyboardArrowDown}
                      sx={{fontWeight:900}}
                    >
                      GEO
                    </TableSortLabel> */}
                  </TableCell>

                  <TableCell
                    // sx={{ fontWeight: 900, color: "#6900b8" }}
                    style={{
                      borderBottom: "none",
                      fontWeight: 900,
                      color: "#6900b8",
                    }}
                  >
                    <TableSortLabel
                      direction={
                        sortConfig.key === "cpi" ? sortConfig.direction : "asc"
                      }
                      active={sortConfig.key === "cpi"}
                      onClick={() => requestSort("cpi")}
                      IconComponent={KeyboardArrowDown}
                      style={{ fontWeight: 900, color: "#6900b8" }}
                    >
                      CPI
                    </TableSortLabel>
                  </TableCell>
                  {/* <TableCell>
                    <TableSortLabel
                      direction={
                        sortConfig.key === "installs"
                          ? sortConfig.direction
                          : "asc"
                      }
                      active={sortConfig.key === "installs"}
                      onClick={() => requestSort("installs")}
                      IconComponent={KeyboardArrowDown}
                    >
                      Installs
                    </TableSortLabel>
                  </TableCell> */}
                  {/* <TableCell>Installs</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  // tableData
                  //   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  //   .map((row) =>
                  //   (
                  tableData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(
                      (row: any, index: any) => (
                        <TableRow
                          key={row.campaignData.campaignId}
                          style={{
                            backgroundColor: row.isChecked
                              ? "#f0e6f8"
                              : "transparent",
                          }}
                          onClick={() =>
                            handleCheckboxClick(row.campaignData.campaignId)
                          }
                        >
                          {/* {shouldHideDiv ? null : (
                            <TableCell>
                              <CustomCheck
                                checked={selectedRows.includes(
                                  row.campaignData.campaignId
                                )}
                                onChange={() =>
                                  handleCheckboxClick(
                                    row.campaignData.campaignId
                                  )
                                }
                                className="check-campaign"
                              />
                            </TableCell>
                          )} */}

                          <TableCell
                            style={{
                              borderBottom: "none",
                              borderRight: "1px solid #999999",
                            }}
                          >
                            <div
                              style={{
                                // width: "251px",
                                whiteSpace: "nowrap",
                                overflow: "visible",
                                // textOverflow: "ellipsis",
                                // borderRight:'1px solid black'
                              }}
                            >
                              {row.campaignName}
                            </div>
                          </TableCell>
                          <TableCell
                            style={{
                              borderBottom: "none",
                            }}
                          >
                            <div
                              className="application-box"
                              style={{ cursor: "pointer" }}
                              // onClick={() => {
                              //   // shouldHideDiv
                              //   //   ? window.location.replace("home/campaigns")
                              //   //   : onClickNavigate(
                              //   //       row.campaignData.campaignId
                              //   //     );
                              // }}
                            >
                              {/* {shouldHideDiv ? null : <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>} */}
                              {/* <img src={row.img} /> */}
                              {row.name}
                            </div>
                          </TableCell>

                          <TableCell
                            style={{
                              borderBottom: "none",
                            }}
                          >
                            <div
                              onMouseEnter={() =>
                                handleStatusMouseEnter(
                                  row.campaignData.campaignId
                                )
                              }
                              onMouseLeave={() =>
                                handleStatusMouseLeave(
                                  row.campaignData.campaignId
                                )
                              }
                            >
                              {row.status != "" ? (
                                <div style={{ position: "relative" }}>
                                  {/* ( */}
                                  <div className="status-div">
                                    <img
                                      src={
                                        row.campaignData.Status === "active"
                                          ? active
                                          : row.campaignData.Status === "paused"
                                          ? paused
                                          : row.campaignData.Status ===
                                            "pending"
                                          ? pending
                                          : stopped
                                      }
                                      style={{
                                        width: "18px",
                                        height: "18px",
                                      }}
                                    />
                                    <p
                                      className={
                                        row.campaignData.Status === "active"
                                          ? "active-para"
                                          : row.campaignData.Status === "paused"
                                          ? "paused-para"
                                          : row.campaignData.Status ===
                                            "pending"
                                          ? "pending-para"
                                          : "stopped-para"
                                      }
                                    >
                                      {row.campaignData.Status == "disabled"
                                        ? "Stopped"
                                        : row.campaignData.Status.charAt(
                                            0
                                          ).toUpperCase() +
                                          row.campaignData.Status.slice(1)}
                                    </p>
                                    {/* {row.campaignData.campaignId == isHoverStatus ? (
                                        <img
                                          onClick={() =>
                                            toggleStatusButton(row.campaignData.campaignId)
                                          }
                                          src={edit}
                                          style={{
                                            marginLeft: "10px",
                                            cursor: "pointer",
                                          }}
                                        />
                                      ) : null} */}
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </TableCell>
                          <TableCell
                            style={{
                              borderBottom: "none",
                            }}
                          >
                            {row.campaignData.ApplicationName}
                          </TableCell>
                          <TableCell
                            style={{
                              borderBottom: "none",
                            }}
                          >
                            {row.budgetData.targetingType}
                          </TableCell>
                          <TableCell
                            style={{
                              borderBottom: "none",
                            }}
                          >
                            {row.campaignData.AppPlatform}
                          </TableCell>
                          <TableCell
                            style={{
                              borderBottom: "none",
                            }}
                          >
                            {row.campaignData.Location}
                          </TableCell>
                          {/* <TableCell>{row.clicks}</TableCell> */}
                          <TableCell
                            style={{
                              borderBottom: "none",
                            }}
                          >
                            {Object.keys(row.budgetData).length !== 0
                              ? row.budgetData.cpiCurrency == "INR"
                                ? "₹"
                                : "$"
                              : "$"}
                            {row.cpi}
                          </TableCell>
                        </TableRow>
                      )
                      // ))
                    )
                }
                {/* <TableRow >
            <TableCell className="table-cel"></TableCell>
            <TableCell className="table-cel">Application Name</TableCell>
            <TableCell className="table-cel">Status</TableCell>
            <TableCell className="table-cel">Campaign</TableCell>
            <TableCell className="table-cel">Goal</TableCell>
            <TableCell className="table-cel">Daily Target</TableCell>
            <TableCell className="table-cel">Impressions</TableCell>
            <TableCell className="table-cel">Clicks</TableCell>
            <TableCell className="table-cel">Installs</TableCell>
          </TableRow> */}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={tableData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        ) : (
          <div>
            {dataComing ? (
              <div
                style={{
                  display: dataComing ? "flex" : "none",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: shouldHideDiv ? "50px" : "250px",
                }}
              >
                <CustomLoader />
                {/* <CircularProgress color="secondary" /> */}
              </div>
            ) : (
              <h3
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  marginTop: shouldHideDiv ? "50px" : "250px",
                }}
              >
                No campaign data available for the range selected or Brand or
                Advertiser Selected.
              </h3>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  count: state.counter.count,
  campaignSubtype: state.user.campaignSubtype,
  appPlatform: state.user.appPlatform,
  appName: state.user.appName,
  campaignName: state.user.campaignName,
  appImageUrl: state.user.appImageUrl,
  packageName: state.user.packageName,

  location: state.user.location,
  language: state.user.language,
  tracking: state.user.tracking,
  trackingUrl: state.user.trackingUrl,
  impressionUrl: state.user.impressionUrl,
  tokenEvent: state.user.tokenEvent,
  locationCode: state.user.locationCode,

  budget: state.user.budget,
  focus: state.user.focus,
  target: state.user.target,
  costPerInstall: state.user.costPerInstall,

  adGroupName: state.user.adGroupName,
  logoUrl: state.user.logoUrl,
  headline: state.user.headline,
  description: state.user.description,
  imageUrl: state.user.imageUrl,
  videoUrl: state.user.videoUrl,
  htmlUrl: state.user.htmlUrl,

  budget_Id: state.user.budget_Id,
  campaign_Id: state.user.campaign_Id,

  status: state.user.status,
  authToken: state.user.authToken,
  sessionId: state.user.sessionId,
  advertiserId: state.user.advertiserId,
  selectedAdvertiserId: state.user.selectedAdvertiserId,

  selectedDateRange: state.user.selectedDateRange,
  campaignStatus: state.user.campaignStatus,

  advertiserIdCampaign: state.user.advertiserIdCampaign,
  tableData: state.user.tableData,
  advertisersList: state.user.advertisersList,
  croppedId: state.user.croppedId,
  protectedData: state.user.protectedData,
});

const mapDispatchToProps = {
  setTableData,
  setCount,
  setStatus,
  setCampaignSubType,
  setAppPlatform,
  setAppName,
  setCampaignName,
  setAppImageUrl,
  setPackageName,

  setLanguage,
  setLocation,
  setTracking,
  setTrackingUrl,
  setImpressionUrl,
  setTokenEvent,
  addTokenEventObject,
  removeTokenEventObject,
  updateToken,
  updateEvent,
  setLocationCode,

  setBudget,
  setFocus,
  setTarget,
  setCostPerInstall,

  setAdGroupName,
  setLogoUrl,
  setHeadline,
  setDescription,
  setImageUrl,
  setVideoUrl,
  setHtmlUrl,
  setLogoError,
  setImageUploadError,
  setCampaignId,
  setBudgetId,
  setLocationCheck,
  setAdvertiserId,
  setAuthToken,
  setSessionId,

  setDataSource,
  setSegmentName,
  setDataType,
  setDataUpload,
  setUploadedUrl,

  setDataSourceError,
  setSegmentNameError,
  setDataTypeError,
  setDataUploadError,
  setUploadedUrlError,
  setSelectedAdvertiserId,

  setSelectedDateRange,
  setTempTransactionCurrency,
  setProtectedData,
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignScreen);
