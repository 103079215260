import React,{FC} from 'react'
import backBtn from '../../IMAGE/backBtn.svg';

type ChildProp = {
onClick :()=> void
}

const BackCustom:React.FC<ChildProp> = ({onClick}) => {
  return (
    <div onClick={onClick} style={{display:"flex",alignItems:'center',cursor:'pointer'}}>
        <img src={backBtn} alt="back-btn" style={{marginRight:'6px'}}/>
        <p style={{fontWeight:700, fontSize: '13px' ,color:'#6900b8'}}>Go Back</p>
    </div>
  )
}

export default BackCustom