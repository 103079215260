import { RootState } from "../../REDUX/Reducers/rootReducer";
import {
  setAuthToken,
  setSessionId,
  setAdvertiserId,
  setImageUrl,
  setAdGroupName,
  setLogoUrl,
  setHeadline,
  setDescription,
  setVideoUrl,
  setHtmlUrl,
  setImageFile,
  setVideoFile,
  setHtmlFile,
} from "../../REDUX/Actions/userActionIndex";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import React, { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import BackCustom from "../custom/BackCustom";
import DateCustomPicker from "../custom/DateCustomPicker";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import spotify from "../../IMAGE/sp.svg";
import {
  EditStatusArray,
  HeaderArrayCampaign,
  CampaignTotalArray,
  EditArray,
  apiURL,
  secretKey,
} from "../../Constant";
import { FiChevronDown } from "react-icons/fi";
import { LuDownload } from "react-icons/lu";
import { ChangeEvent } from "react";

import "../../CSS/CampaignRecord.css";
import "../../CSS/SignIn.css";
import "../../CSS/Campaign.css";
import "../../CSS/CheckboxTab.css";
import { CampaignArray } from "../../Constant";
import active from "../../IMAGE/active.svg";
import paused from "../../IMAGE/paused.svg";
import stopped from "../../IMAGE/stopped.svg";
import edit from "../../IMAGE/edit.svg";
import AdGroup from "../../IMAGE/adGroup.svg";
import Add from "../../IMAGE/Add.svg";
import done from "../../IMAGE/done.svg";
import cancel from "../../IMAGE/cancel.svg";
import { stat } from "fs/promises";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
// import { createObjectCsvWriter } from "csv-writer";
import { saveAs } from "file-saver";
import CustomLoader from "../custom/CustomLoader";
import { setCount } from "../../REDUX/Actions/counterActionIndex";
const CryptoJS = require("crypto-js");

interface CampaignProps {
  authToken: string;
  sessionId: string;
  advertiserId: string;
}

const CampaignRecord: FC<CampaignProps> = ({
  authToken,
  sessionId,
  advertiserId,
}) => {
  const [tableData, setTableData] = useState<
    {
      HTML5: string[];
      Status: string;

      adGroupsId: string;
      campaignId: string;

      createdAt: string;
      applicationName: string;

      description: string;
      updatedAt: string;

      headline: string;
      image: string[];

      videos: string[];
      name: string;
      // cpc: number;
      // cpm: number;
      // ctr: number;
      // impressions: number;
      // isChecked: boolean;
      // mediaCost: string;
      // name: string;
      // status: string;
      // total: string;
      // id: string;
      // clicks: number;
      // reach: string;
    }[]
  >([]);
  const [impressions, setimpressions] = useState<number>(0);
  const [clicksTotal, setclicksTotal] = useState<number>(0);
  const [ctrTotal, setctrTotal] = useState<number>(0);
  const [cpmTotal, setcpmTotal] = useState<number>(0);
  const [cpcTotal, setcpcTotal] = useState<number>(0);
  const [reachTotal, setReachTotal] = useState<number>(0);
  const [mediaCost, setmediaCost] = useState<number>(0);

  const [header, setHeader] = useState(HeaderArrayCampaign);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<string>("");
  const [inputShow, setInputShow] = useState<boolean>(true);
  const [valueShow, setvalueShow] = useState<boolean>(true);
  const [dataComing, setdataComing] = useState<boolean>(true);
  // const [changeColour, setChangeColour] = useState<boolean>(false);
  const [changeId, setChangeId] = useState<string>("");
  const [selectedValue, setSelectedValue] = useState<boolean>(false);
  const [updateValueIndex, setUpdateValueIndex] = useState<number>();
  const [inputValue, setInputValue] = useState<
    { adGroupsId: string; name: string }[]
  >([]);

  const [idToPass, setIdToPass] = useState<string>("");
  const [isHoverStatus, setIsHoverStatus] = useState<string>("");
  const [statusDpd, setStatusDpd] = useState<string>("");
  const [toggleStatusInput, setToggleStatusInput] = useState<boolean>(false);
  const [saveStatusVal, setSaveStatusVal] = useState<
    {
      HTML5: string[];
      Status: string;

      adGroupsId: string;
      campaignId: string;

      createdAt: string;
      applicationName: string;

      description: string;
      updatedAt: string;

      headline: string;
      image: string[];

      videos: string[];
      name: string;
      // cpc: number;
      // cpm: number;
      // ctr: number;
      // impressions: number;
      // isChecked: boolean;
      // mediaCost: string;
      // name: string;
      // status: string;
      // total: string;
      // id: string;
      // clicks: number;
      // reach: string;
    }[]
  >([]);
  const [statusValue, setStatusValue] = useState<string>("");
  const [headerData, setHeaderData] = useState<any[]>([]);
  const [currency, setCurrency] = useState<any>(
    localStorage.getItem("currency")
  );
  const dispatch = useDispatch();

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    let val = event.target.value;
    setStatusValue(val);
    UpdateCampaignApi(val);
  };

  const navigate = () => {
    dispatch(setCount(3));
    dispatch(setImageUrl([]));
    dispatch(setAdGroupName(""));
    dispatch(setLogoUrl(""));
    dispatch(setHeadline([""]));
    dispatch(setDescription([""]));
    dispatch(setVideoUrl([]));
    dispatch(setHtmlUrl([]));
    dispatch(setImageFile(""));
    dispatch(setVideoFile([]));
    dispatch(setHtmlFile([]));

    window.location.replace(`createCampaign?count=3&id=${idToPass}`);
  };
  const findIndex = (id: string) => {
    const foundItem = tableData.find((item) => item.adGroupsId === id);
    if (foundItem) {
      return tableData.indexOf(foundItem);
    } else {
      return -1;
    }
  };
  const UpdateCampaignApi = (status: string) => {
    // const advertiserId = localStorage.getItem("advertiserID") || "";
    const email = localStorage.getItem("emailID") || "";
    const campSubtype = localStorage.getItem("campaignSubtype") || "";
    const appPlatform = localStorage.getItem("appPlatform") || "";
    const selectLanguages = localStorage.getItem("selectedLang") || "";
    const converted = selectLanguages != "" ? JSON.parse(selectLanguages) : [];

    const trackingPart = localStorage.getItem("trackingPartner");
    const trackingURL = localStorage.getItem("trackingUrl") || "";
    const tokenEventData = localStorage.getItem("inputTokenEventData") || "";
    const currency = localStorage.getItem("currency");
    const installationCost = localStorage.getItem("costPerInstall") || "0";
    const geoLocation = localStorage.getItem("locationCode");
    // const countryCode = localStorage.getItem("countryCode") || '';
    // console.log("data", data);
    const currencyCode = localStorage.getItem("countryCode");
    const jsonParam = {
      AdvertiserId: advertiserId,
      CampaignId: headerData[0].campaignId,
      Email: email,
      ApplicationName: headerData[0].applicationName, // harCoded
      CampaignName: headerData[0].campaignName,
      // SubType: item.campSubtype,
      // AppPlatform: appPlatform,
      // AppLookUpDetails: item.lookup,
      // TrackingPartne: trackingPart,
      // TrackingURL: trackingURL,
      // Token: tokenEventData != "" ? JSON.parse(tokenEventData) : [],
      Currency: currencyCode,
      Status: status,
      // Payouts: [
      //   {
      //     payout: 0,
      //     revenue: installationCost,
      //     geo: [geoLocation],
      //   },
      // ],
      Languages: converted,
    };
    // const authToken = localStorage.getItem("authToken");
    // const sessionID = localStorage.getItem("sessionID");
    // console.log(startDate,endDate)
    const encryptAdvertiserID = CryptoJS.AES.encrypt(
      JSON.stringify(advertiserId),
      secretKey
    ).toString();

    const header = {
      headers: {
        authtoken: authToken,
        sessionid: sessionId,
        advertiserid: encryptAdvertiserID, // Example authorization header
      },
    };
    // console.log(jsonParam);
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(jsonParam),
      secretKey
    ).toString();

    const url = `${apiURL}/api/campaigns/updateCampaign`;
    if (authToken != "" && sessionId != "" && advertiserId != "") {
      axios
        .post(url, { params: encryptedData }, header)
        .then((res) => {
          console.log("res", res);

          if (res.status == 200) {
            // const resData = res.data;
            // const bytes = CryptoJS.AES.decrypt(resData, secretKey);
            // const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
            // const parsedData = JSON.parse(decryptedData);
            localStorage.setItem("campaignId", res.data.campaignId);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const toggleInput = (id: string) => {
    setChangeId(id);
    let indexValue = findIndex(id);
    setUpdateValueIndex(indexValue);

    if (tableData[indexValue]) {
      const { adGroupsId, name } = tableData[indexValue];
      const tempObj = { adGroupsId, name };
      // console.log(inputValue)
      setInputValue((prevInputValue) => [...prevInputValue, tempObj]);
    }
    // setInputValue(tableData[indexValue].name);
    if (isHovered == id) {
      setSelectedValue(!selectedValue);
      setvalueShow(!valueShow);
    }
  };
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  // const [selectedStartDate, setSelectedStartDate] = useState<Date>();
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());

  const getDetailsCampaign = (
    campaignId: string,
    startDate: Date,
    endDate: Date
  ) => {
    const campaign = localStorage.getItem("campaignRecordData") || "";
    const conversion = JSON.parse(campaign);
    const advertiserIdGet = localStorage.getItem("advertiserID") || "";
    const year = startDate.getFullYear();
    const month = startDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month index
    const day = startDate.getDate();
    const startDatFormatted = `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`;

    const endyear = endDate.getFullYear();
    const endmonth = endDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month index
    const endday = endDate.getDate();

    const endDateFormatted = `${endyear}-${
      endmonth < 10 ? "0" + endmonth : endmonth
    }-${endday < 10 ? "0" + endday : endday}`;
    const authToken = localStorage.getItem("authToken") || "";
    const sessionID = localStorage.getItem("sessionID") || ""; // console.log(startDate,endDate)
    const advertiserId = localStorage.getItem("advertiserID") || "";

    const jsonParam = {
      advertiserId: advertiserId,
      startDate: startDatFormatted,
      endDate: endDateFormatted,
    };
    console.log(jsonParam);
    const encryptAdvertiserID = CryptoJS.AES.encrypt(
      JSON.stringify(advertiserId),
      secretKey
    ).toString();

    const header = {
      headers: {
        authtoken: authToken,
        sessionid: sessionID,
        advertiserid: encryptAdvertiserID, // Example authorization header
      },
    };
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(jsonParam),
      secretKey
    ).toString();

    const url = `${apiURL}/api/campaigns/getAllCampaigns`;
    axios
      .post(url, { params: encryptedData }, header)
      .then((res) => {
        if (res.data.status) {
          const resData = res.data.data;
          const bytes = CryptoJS.AES.decrypt(resData, secretKey);
          const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
          const parsedData = JSON.parse(decryptedData);
          const headerFilter = parsedData.filter((item: any) => {
            return item.campaignId == campaignId;
          });
          console.log(headerFilter, campaignId, parsedData);
          setHeaderData(headerFilter);
          // setStatusValue(headerFilter[0].campaingnData.status)
          // console.log(headerFilter);

          // setTableData([...tableData ,...headerFilter[0].adGroups]);
          // console.log(headerFilter[0].adGroups);
          setSaveStatusVal(headerFilter[0].adGroups);
          const flattenedArray = parsedData.flatMap((val: any) => {
            const adGroup = val.adGroups; // adGroup Array
            const {
              ctr,
              cpc,
              cpm,
              applicationName,
              total,
              isChecked,
              impressions,
              mediaCost,
              id,
              reach,
              clicks,
            } = val;
            return adGroup.map((item: any) => ({
              ...item,
              clicks,
              ctr,
              cpm,
              applicationName,
              cpc,
              total,
              isChecked,
              impressions,
              mediaCost,
              id,
              reach,
            }));
          });
          // console.log(flattenedArray);
          const keysToRemove = [
            "campaignId",
            "headline",
            "description",
            "image",
            "videos",
            "HTML5",
            "Status",
            "createdAt",
            "updatedAt",
            "clicks",
            "ctr",
            "cpm",
            "applicationName",
            "cpc",
            "total",
            "isChecked",
            "impressions",
            "mediaCost",
            "id",
            "reach",
          ]; // Define the keys you want to remove

          // console.log(filteredArray);
          // console.log(headerFilter)
          const initialTotal = {
            impressions: 0,
            clicks: 0,
            ctr: 0,
            cpm: 0,
            cpc: 0,
          };

          const total = flattenedArray.reduce((accumulator: any, item: any) => {
            accumulator.impressions += item.impressions;
            accumulator.clicks += item.clicks;
            accumulator.ctr += item.ctr;
            accumulator.cpm += item.cpm;
            accumulator.cpc += item.cpc;

            if (!isNaN(Number(item.reach))) {
              accumulator.reach += Number(item.reach);
            }

            if (!isNaN(Number(item.mediaCost))) {
              accumulator.mediaCost += Number(item.mediaCost);
            }

            return accumulator;
          }, initialTotal);
          setimpressions(total.impressions);
          setclicksTotal(total.clicks);
          setctrTotal(total.ctr);
          setcpmTotal(total.cpm);
          setcpcTotal(total.cpc);

          // console.log(!isNaN(total.reach));
          if (!isNaN(total.reach)) {
            setReachTotal(total.reach);
          }

          if (!isNaN(total.mediaCost)) {
            setmediaCost(total.mediaCost);
          }

          const updated = flattenedArray.filter((item: any) => {
            return item.campaignId == campaignId;
          });
          // console.log(updated);
          const filteredArray = updated.map((item: any) => {
            // Create a new object without the specified keys
            const {
              campaignId,
              headline,
              description,
              image,
              videos,
              HTML5,
              Status,
              createdAt,
              updatedAt,
              clicks,
              ctr,
              cpm,
              applicationName,
              cpc,
              total,
              isChecked,
              impressions,
              mediaCost,
              id,
              reach,
              ...rest
            } = item;
            return rest; // Return the object without the removed keys
          });
          // console.log(filteredArray);
          setInputValue(filteredArray);
          // saveStatusValue(updated.Status)
          // setTableData(updated);
          // console.log(updated);
          // setSaveStatusVal(updated);

          localStorage.setItem("campaignTable", JSON.stringify(parsedData));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllADGroup = async (id: string) => {
    setdataComing(true);
    try {
      const advertiserId = localStorage.getItem("advertiserID") || "";
      const authToken = localStorage.getItem("authToken");
      const sessionID = localStorage.getItem("sessionID");
      const encryptAdvertiserID = CryptoJS.AES.encrypt(
        JSON.stringify(advertiserId),
        secretKey
      ).toString();

      const url = `${apiURL}/api/campaigns/getAdGroups?campaignId=${id}`;
      await axios
        .get(url, {
          headers: {
            authtoken: authToken,
            sessionid: sessionID,
            advertiserid: encryptAdvertiserID,
            "Content-Type": "application/json",
            // Example authorization header
          },
        })
        .then((res) => {
          if (res.data.status) {
            const resData = res.data.data;
            const bytes = CryptoJS.AES.decrypt(resData, secretKey);
            const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
            const parsedData = JSON.parse(decryptedData);
            if (parsedData) {
              console.log(parsedData);
              setdataComing(false);
              setTableData([...tableData, ...parsedData]);
              // setTableData(parsedData);
              setSaveStatusVal(parsedData);
            } else {
              setdataComing(false);
            }
          }
          // console.log(conversion[0].adGroups);
        })
        .catch((err) => {
          console.log(err);
          setdataComing(false);
        });
    } catch (err) {
      console.log(err);
      setdataComing(false);
    }
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const productId = urlParams.get("id") || "";
    const tableData = localStorage.getItem("campaignTable") || "";
    const campaign = localStorage.getItem("campaignRecordData") || "";
    const conversion = JSON.parse(campaign);
    console.log("conversion", conversion);
    // console.log(headerData[0]);
    if (productId != "") {
      getAllADGroup(productId);
    } else if (conversion[0].campaignId != "") {
      getAllADGroup(conversion[0].campaignId);
    } else if (campaign != "") {
      const conversion = JSON.parse(campaign);
      // setStatusValue(headerFilter.status)
      console.log(conversion);
      setHeaderData(conversion);
      setimpressions(conversion[0].impressions);
      setclicksTotal(conversion[0].clicks);
      setctrTotal(conversion[0].ctr);
      setcpmTotal(conversion[0].cpm);
      setcpcTotal(conversion[0].cpc);
      const filteredArray = conversion.map((item: any) => {
        // Create a new object without the specified keys
        const {
          campaignId,
          headline,
          description,
          image,
          videos,
          HTML5,
          Status,
          createdAt,
          updatedAt,
          clicks,
          ctr,
          cpm,
          applicationName,
          cpc,
          total,
          isChecked,
          impressions,
          mediaCost,
          id,
          reach,
          ...rest
        } = item;
        return rest; // Return the object without the removed keys
      });
      // console.log(filteredArray);
      setInputValue(filteredArray);
      // console.log(headerFilter[0].adGroups);
    }
    // const arr = JSON.parse(tableData);
    // setHeaderData(arr)

    // getDetailsCampaign(productId);
    if (
      conversion &&
      conversion.length > 0 &&
      conversion[0].adGroups.length > 0 &&
      conversion[0].adGroups[0].adGroupsId
    ) {
      setIdToPass(conversion[0].adGroups[0].campaignId);
    }
    // let filteredData = headerData.filter((item) => {
    //   return item.id.toString() == productId;
    // });
    // console.log(filteredData);
    // setHeaderData(filteredData);
    const startDate = localStorage.getItem("startDate") || selectedStartDate;
    const endDate = localStorage.getItem("endDate") || selectedEndDate;

    if (startDate != null && endDate != null) {
      const s = new Date(startDate);
      const e = new Date(endDate);
      setSelectedStartDate(s);
      setSelectedEndDate(e);
      getDetailsCampaign(
        conversion[0].campaignId,
        selectedStartDate,
        selectedEndDate
      );
    } else {
      // if (dates != null) {
      //   console.log(dates);
      //   const s = new Date(dates.startDate);
      //   const e = new Date(dates.endDate);
      //   setSelectedStartDate(s);
      //   setSelectedEndDate(e);
      //   getDetailsCampaign(s, e);
      // }
    }
    localStorage.setItem("campaignId", conversion[0].campaignId);
    // if (filteredData && filteredData[0].status !== undefined) {
    //   setStatusValue(filteredData[0].status);
    // }
  }, []);

  const toggleStatus = (id: string, value: string) => {
    console.log("here", id);
    let statusValue = findIndex(id);
    const updatedArray = [...tableData];
    console.log(statusValue);
    // setChangeColour(true)
    // setToggleStatusInput(updatedArray[statusValue].status)
    // setToggleStatusInput(!toggleStatusInput);
    if (value != null) {
      // Update the value of the object at the specified index
      updatedArray[statusValue] = {
        ...updatedArray[statusValue], // Spread the existing object properties
        Status: value !== undefined ? value : "", // Update the 'value' property
      };
      updatedArray.filter((item) => {
        return item.campaignId == idToPass;
      });
      console.log(updatedArray);
      setSaveStatusVal(updatedArray);
    }
  };
  const saveStatus = (id: string) => {
    const ob = [...saveStatusVal];
    const fil = ob.filter((item) => {
      return item.adGroupsId == id;
    });
    console.log(fil);
    if (fil.length != 0) {
      apiUpdate(id, fil[0].name, fil[0].Status);
    }
    console.log(ob);
    // setTableData(saveStatusVal);
    setToggleStatusInput(!toggleStatusInput);
  };

  const cancelStatus = () => {
    setToggleStatusInput(!toggleStatusInput);
  };

  const toggleStatusButton = (id: string) => {
    // console.log("toggle status button ");
    if (isHoverStatus == id) {
      setToggleStatusInput(!toggleStatusInput);
      setStatusDpd(id);
      setIsHoverStatus(id);
    }
  };

  const onChangeEdit = (adGroupsIdToUpdate: string, newValue: string) => {
    // Copy the state array
    const updatedInputValue = [...inputValue];

    // Find the index of the object with the matching adGroupsId
    const index = updatedInputValue.findIndex(
      (item) => item.adGroupsId === adGroupsIdToUpdate
    );

    if (index !== -1) {
      // Update the object's name property with the new value
      updatedInputValue[index] = {
        ...updatedInputValue[index],
        name: newValue,
      };

      // Set the updated array back in the state
      setInputValue(updatedInputValue);
    }
  };

  const apiUpdate = (adgrpId: string, nameAd: string, status: string) => {
    const jsonParam = {
      adGroupId: adgrpId,
      name: nameAd,
      Status: status,
    };
    const authToken = localStorage.getItem("authToken") || "";
    const sessionID = localStorage.getItem("sessionID") || ""; // console.log(startDate,endDate)
    const advertiserId = localStorage.getItem("advertiserID") || "";
    const encryptAdvertiserID = CryptoJS.AES.encrypt(
      JSON.stringify(advertiserId),
      secretKey
    ).toString();

    const header = {
      headers: {
        authtoken: authToken,
        sessionid: sessionID,
        advertiserid: encryptAdvertiserID, // Example authorization header
      },
    };
    console.log(jsonParam);
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(jsonParam),
      secretKey
    ).toString();

    const url = `${apiURL}/api/campaigns/updateAdGroups`;
    axios
      .post(url, { params: encryptedData }, header)
      .then((res) => {
        console.log(res);
        getAllADGroup(idToPass);
        // getDetailsCampaign(idToPass, selectedStartDate, selectedEndDate);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const doneClick = (id: string) => {
    const updatedArray = [...tableData];
    if (updateValueIndex !== undefined) {
      // Update the value of the object at the specified index
      updatedArray[updateValueIndex] = {
        ...updatedArray[updateValueIndex], // Spread the existing object properties
        name: inputValue !== undefined ? inputValue[updateValueIndex].name : "", // Update the 'value' property
      };
      getAllADGroup(idToPass);
      // getDetailsCampaign(idToPass, selectedStartDate, selectedEndDate);
    }
    if (isHovered == id && inputShow) {
      setSelectedValue(!selectedValue);
      setvalueShow(!valueShow);
    }
    const ob = updatedArray;
    const foundItem = ob.find((item) => item.adGroupsId === id);

    if (foundItem !== undefined) {
      apiUpdate(id, foundItem.name, foundItem.Status);
    }

    setTableData(updatedArray);
  };

  const handleStatusMouseEnter = (id: string) => {
    setIsHoverStatus(id);
  };

  const handleStatusMouseLeave = (id: string) => {
    setIsHoverStatus(id);
  };

  const cancelClick = (id: string) => {
    if (isHovered == id && inputShow) {
      setSelectedValue(!selectedValue);
      setvalueShow(!valueShow);
    }
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "buffer",
    });
    const dataBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(dataBlob, "data.xlsx"); // Specify the desired file name here
  };
  const fileDownload = (type: string) => {
    if (type == "doc") {
      let header = [
        "adGroupsId",
        "campaignId",
        "name",
        "headline",
        "description",
        "image",
        "videos",
        "HTML5",
        "Status",
        "createdAt",
        "updatedAt",
        "clicks",
        "ctr",
        "cpm",
        "applicationName",
        "cpc",
        "total",
        "isChecked",
        "impressions",
        "mediaCost",
        "id",
        "reach",
      ];
      const csvData = arrayToCsv(tableData, header);
      const fileName = "AdGroupData.csv";
      downloadCsv(csvData, fileName);
    } else {
      downloadExcel();
    }
  };
  const downloadCsv = (csvData: string, fileName: string) => {
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const arrayToCsv = (data: any[], headers: string[]) => {
    const headerRow = headers.join(",");
    const bodyRows = data.map((row) =>
      headers.map((header) => row[header]).join(",")
    );

    return `${headerRow}\n${bodyRows.join("\n")}`;
  };

  const toggleDropdown = (type: string) => {
    // console.log("function",type)

    if (type == "dwn") {
      setIsOpen(!isOpen);
    } else {
      setIsOpenEdit(!isOpenEdit);
    }
  };

  const closeDropdown = (type: string) => {
    if ((type = "dwn")) {
      setIsOpen(false);
    } else {
      setIsOpenEdit(false);
    }
  };

  // const handleCheckboxClick = (id: string) => {
  //   const UpdatedRow = tableData.map((item) => {
  //     if (item.id === id) {
  //       return { ...item, isChecked: !item.isChecked };
  //     }
  //     return item;
  //   });
  //   setTableData(UpdatedRow);

  //   // If 'selectedRows' also contains numbers, convert 'id' to a number before using it
  //   // const numericId = typeof id === 'string' ? Number(id) : id;

  //   if (selectedRows.includes(id)) {
  //     setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
  //   } else {
  //     setSelectedRows([...selectedRows, id]);
  //   }
  // };

  const handleMouseEnter = (id: string) => {
    // Function to be called when the mouse enters the element
    // if (!selectedValue) {
    setIsHovered(id);
    // }
  };

  const handleMouseLeave = (id: string) => {
    // Function to be called when the mouse leaves the element
    // if (!selectedValue) {
    setIsHovered(id);
    // }
  };

  const handleClick = () => {
    window.location.replace("/home/campaigns");
  };
  const checkBoxColor = {
    color: "#6900B8",
    "&.Mui-checked": {
      color: "#6900B8",
    },
  };

  // const InsertNewRow = () => {
  //   {
  //     let data = {
  //       id: rows.length + 1,
  //       groupName: "Video",
  //       status: "Paused",
  //       impressions: 0,
  //       clicks: 0,
  //       ctr: 0,
  //       cpc: 0,
  //       cpm: 0.14,
  //       reach: "10,875,876",
  //       mediaCost: "4,777.45",
  //       total: "4777.45",
  //       isChecked: false,
  //     };
  //     // setRows([...rows,data]);
  //   }
  // };

  return (
    <div>
      <div className="upper-box">
        <div className="back" style={{ marginTop: "20px" }}>
          <BackCustom onClick={handleClick} />
          <p>
            Your Campaigns &gt;{" "}
            {headerData[0] && headerData[0].applicationName !== undefined
              ? headerData[0].applicationName
              : null}
          </p>
        </div>
        {/* <DateCustomPicker /> */}
      </div>

      <div className="data-box">
        <div>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} columns={9}>
              <Grid xs={3}>
                <div className="block">
                  {/* <img src={headerData[0].img} /> */}
                  <div>
                    <h5 className="header">Application Name</h5>
                    <p className="main-word">
                      {headerData[0] &&
                      headerData[0].applicationName !== undefined
                        ? headerData[0].applicationName
                        : null}
                      {/* {headerData[0].applicationName ? headerData[0].applicationName : null} */}
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid xs={3}>
                <div className="block">
                  <div>
                    <h5 className="header">
                      Status&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </h5>
                    <div className="hide-button">
                      <select
                        style={{
                          width: "100%",
                          height: "37px",
                          borderRadius: "3px",
                          marginTop: "10px",
                        }}
                        value={
                          statusValue == ""
                            ? headerData[0] &&
                              headerData[0].status !== undefined
                              ? headerData[0].status
                              : statusValue
                            : statusValue
                        }
                        onChange={handleSelectChange}
                      >
                        {EditArray.map((item) => {
                          return (
                            <option
                              value={item.name}
                              disabled={item.name == "pending"}
                            >
                              {/* <div className="status-div"> */}
                              <img src={item.image} />
                              <p className={item.className}>
                                {item.name == "disabled"
                                  ? "Stopped"
                                  : item.name == "expired"
                                  ? "Delete"
                                  : item.name.charAt(0).toUpperCase() +
                                    item.name.slice(1)}
                              </p>
                              {/* </div> */}
                            </option>
                          );
                        })}
                      </select>
                      <FiChevronDown
                        className="down-icon"
                        style={{ right: "6px", top: "20px" }}
                      />
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid xs={1}>
                <div className="block">
                  <div>
                    <h5 className="header">Revenue</h5>
                    <p className="main-word">{currency} 99.25</p>
                  </div>
                </div>
              </Grid>
              <Grid xs={1}>
                <div className="block">
                  <div>
                    <h5 className="header">Installs</h5>
                    {/* <p className="main-word">{headerData[0].installs}</p> */}
                    {/* {headerData[0] && headerData[0] !== undefined
                        ? headerData[0] == ""
                          ? "N/A"
                          : headerData[0]
                        : "N/A"} */}
                    <p className="main-word">0</p>
                  </div>
                </div>
              </Grid>
              <Grid xs={1}>
                <div className="block">
                  <div>
                    <h5 className="header">Total Cost</h5>
                    <p className="main-word">
                      {currency}{" "}
                      {headerData[0] && headerData[0].total !== undefined
                        ? headerData[0].total == ""
                          ? "0"
                          : headerData[0].total
                        : "0"}
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
        <div className="divider"></div>
        <div>
          <Box>
            <Grid container spacing={1} columns={8}>
              <Grid xs={1}>
                <div className="block">
                  <div>
                    <h5 className="header">Clicks</h5>
                    <p className="main-word">
                      {headerData[0] && headerData[0].clicks !== undefined
                        ? headerData[0].clicks
                        : null}
                      {/* {headerData[0].clicks ? headerData[0].clicks : null} */}
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid xs={1}>
                <div className="block">
                  <div>
                    <h5 className="header">Impressions</h5>
                    <p className="main-word">
                      {headerData[0] && headerData[0].impressions !== undefined
                        ? headerData[0].impressions
                        : null}
                      {/* {headerData[0].impressions ? headerData[0].clicks : null} */}
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid xs={1}>
                <div className="block">
                  <div>
                    <h5 className="header">CPM</h5>
                    <p className="main-word">
                      {currency}{" "}
                      {headerData[0] && headerData[0].cpm !== undefined
                        ? headerData[0].cpm
                        : null}
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid xs={1}>
                <div className="block">
                  <div>
                    <h5 className="header">CTR</h5>
                    <p className="main-word">
                      {headerData[0] && headerData[0].ctr !== undefined
                        ? headerData[0].ctr
                        : null}
                      %
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid xs={1}>
                <div className="block">
                  <div>
                    <h5 className="header">CPC</h5>
                    <p className="main-word">
                      {currency}&nbsp;
                      {headerData[0] &&
                      headerData[0].cpc !== undefined &&
                      headerData[0].cpc != null
                        ? parseFloat(headerData[0].cpc).toFixed(2)
                        : "0"}
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid xs={1}>
                <div className="block">
                  <div>
                    <h5 className="header">Reach</h5>
                    <p className="main-word">
                      {headerData[0] && headerData[0].reach !== undefined
                        ? headerData[0].reach == ""
                          ? "0"
                          : headerData[0].reach
                        : "0"}
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid xs={1}>
                <div className="block">
                  <div>
                    <h5 className="header">Daily Target Budget</h5>
                    <p className="main-word">
                      {currency}&nbsp;
                      {headerData[0] && headerData[0].dailyTarget !== undefined
                        ? headerData[0].dailyTarget
                        : null}
                      {/* {headerData[0].dailyTarget ? headerData[0].dailyTarget : null } */}
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid xs={1}>
                <div className="block">
                  <div>
                    <h5 className="header">Events</h5>
                    <p className="main-word">N/A</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>

      <button className="new-group" onClick={navigate}>
        <img src={Add} />
        <p>New Group</p>
      </button>
      <div className="top-box">
        <div className="center-align">
          <img src={AdGroup} style={{ marginRight: "10px" }} />
          <h3 style={{ fontSize: "16px", fontWeight: 800 }}>AD Group</h3>
        </div>

        <div className="center-align">
          <div className="dropdown">
            <button
              className="center-align btn-dwn dropdown-button"
              onClick={() => toggleDropdown("dwn")}
            >
              Download
              <LuDownload className="dwn-icon" />
            </button>
            {isOpen && (
              <ul
                className="dropdown-menu"
                onClick={() => closeDropdown("dwn")}
              >
                <li onClick={() => fileDownload("doc")}>
                  {/* <CSVLink
                    data={tableData}
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                    separator={","}
                    filename={"my-file.csv"}
                  > */}
                  Document (.csv) file
                  {/* </CSVLink> */}
                </li>
                <li onClick={() => fileDownload("xlsx")}>Excel (.xlsx) file</li>
              </ul>
            )}
          </div>
        </div>
      </div>
      <div className="table-main">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell></TableCell> */}
                {header.map((val) => {
                  return <TableCell>{val.rowName}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.length != 0 ? (
                tableData.map((row, index) => (
                  <TableRow
                    key={row.adGroupsId}
                    // style={{
                    //   backgroundColor: row.isChecked
                    //     ? "#f0e6f8"
                    //     : "transparent",
                    // }}
                  >
                    {/* <TableCell>
                      <Checkbox
                        checked={selectedRows.includes(row.id)}
                        onChange={() => handleCheckboxClick(row.id)}
                        className="check-campaign"
                        color="secondary"
                      />
                    </TableCell> */}
                    {/* <TableCell>{row.id}</TableCell> */}
                    <TableCell>
                      <div
                        className="application-box"
                        onMouseEnter={() => handleMouseEnter(row.adGroupsId)}
                        onMouseLeave={() => handleMouseLeave(row.adGroupsId)}
                      >
                        {changeId == row.adGroupsId && selectedValue ? (
                          <div>
                            <div className="input-box">
                              <div
                                className="done-div"
                                onClick={() => doneClick(row.adGroupsId)}
                              >
                                <img src={done} />
                                <p>Done</p>
                              </div>
                              <div
                                className="cancel-div"
                                onClick={() => cancelClick(row.adGroupsId)}
                              >
                                <img src={cancel} />
                                <p>Cancel</p>
                              </div>
                            </div>
                            <input
                              type="text"
                              className="input-campaign"
                              value={inputValue[index].name}
                              onChange={(e) =>
                                onChangeEdit(row.adGroupsId, e.target.value)
                              }
                            />
                          </div>
                        ) : (
                          <div style={{ display: "flex" }}>
                            {row.adGroupsId == isHovered && inputShow ? (
                              <div style={{ display: "flex" }}>
                                <p>{row.name}</p>
                                <img
                                  onClick={() => toggleInput(row.adGroupsId)}
                                  src={edit}
                                  style={{
                                    marginLeft: "10px",
                                    cursor: "pointer",
                                  }}
                                />
                              </div>
                            ) : (
                              <p>{row.name}</p>
                            )}
                          </div>
                        )}
                      </div>
                    </TableCell>

                    <TableCell>
                      <div
                        onMouseEnter={() =>
                          handleStatusMouseEnter(row.adGroupsId)
                        }
                        onMouseLeave={() =>
                          handleStatusMouseLeave(row.adGroupsId)
                        }
                      >
                        {row.Status != "" ? (
                          <div>
                            {row.adGroupsId === statusDpd &&
                            toggleStatusInput ? (
                              <ul className="dropdown-menu">
                                <div
                                  className="input-box"
                                  style={{ background: "white" }}
                                >
                                  <div
                                    className="done-div"
                                    onClick={() => saveStatus(row.adGroupsId)}
                                  >
                                    <img src={done} />
                                    <p>Done</p>
                                  </div>
                                  <div
                                    className="cancel-div"
                                    onClick={cancelStatus}
                                  >
                                    <img src={cancel} />
                                    <p>Cancel</p>
                                  </div>
                                </div>
                                {EditStatusArray.map((item) => (
                                  <li
                                    key={item.name}
                                    onClick={() =>
                                      toggleStatus(row.adGroupsId, item.name)
                                    }
                                    style={{
                                      background:
                                        item.name == saveStatusVal[index].Status
                                          ? "#ebebeb"
                                          : "#ffffff",
                                    }}
                                  >
                                    <div className="status-div">
                                      <img src={item.image} />
                                      <p className={item.className}>
                                        {item.name == "disabled"
                                          ? "Stopped"
                                          : item.name.charAt(0).toUpperCase() +
                                            item.name.slice(1)}
                                      </p>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              <div className="status-div">
                                <img
                                  src={
                                    row.Status === "active"
                                      ? active
                                      : row.Status === "paused"
                                      ? paused
                                      : stopped
                                  }
                                />
                                <p
                                  className={
                                    row.Status === "active"
                                      ? "active-para"
                                      : row.Status === "paused"
                                      ? "paused-para"
                                      : "stopped-para"
                                  }
                                >
                                  {row.Status == "disabled"
                                    ? "Stopped"
                                    : row.Status.charAt(0).toUpperCase() +
                                      row.Status.slice(1)}
                                </p>
                                {row.adGroupsId == isHoverStatus ? (
                                  <img
                                    onClick={() =>
                                      toggleStatusButton(row.adGroupsId)
                                    }
                                    src={edit}
                                    style={{
                                      marginLeft: "10px",
                                      cursor: "pointer",
                                    }}
                                  />
                                ) : null}
                              </div>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </TableCell>

                    <TableCell>
                      {
                        row.headline != "" ? row.headline : ""
                        // : ""
                      }
                    </TableCell>
                    <TableCell>
                      {/* {row.description != "" ? JSON.parse(row.description) : ""} */}
                      {row.description != ""
                        ? // ? /^\[.*\]$/.test(JSON.parse(row.description))
                          //   ? JSON.parse(row.description).slice(2, -2)
                          // :
                          row.description
                        : ""}
                    </TableCell>
                    <TableCell>
                      {row.image.map((value: any, index: any) => {
                        return <a href={value}>&nbsp;Image {index + 1}</a>;
                      })}
                      {/* {row.image } */}
                    </TableCell>
                    <TableCell>
                      {row.videos.map((value: any, index: any) => {
                        return <a href={value}>&nbsp;Videos {index + 1}</a>;
                      })}
                      {/* {row.videos} */}
                    </TableCell>
                    <TableCell>
                      {row.HTML5.map((value: any, index: any) => {
                        return <a href={value}>&nbsp;HTML {index + 1}</a>;
                      })}
                      {/* {row.HTML5} */}
                    </TableCell>
                    {/* <TableCell>{row.reach == "" ? "0" : row.reach}</TableCell>
                    <TableCell>
                      {row.mediaCost == "" ? "0" : row.mediaCost}
                    </TableCell> */}
                    {/* <TableCell>{row.total}</TableCell> */}
                  </TableRow>
                ))
              ) : dataComing ? (
                <TableRow>
                  {/* <TableCell>&nbsp;</TableCell> */}
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>
                    {/* <CircularProgress color="secondary" /> */}
                    <CustomLoader />
                  </TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  {/* <TableCell>&nbsp;</TableCell> */}
                </TableRow>
              ) : (
                <TableRow>
                  {/* <TableCell>&nbsp;</TableCell> */}
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>
                    {/* <CircularProgress color="secondary" /> */}
                    <h4>No Data Availabe</h4>
                  </TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  {/* <TableCell>&nbsp;</TableCell> */}
                </TableRow>
              )}
              {/* <TableRow> */}
              {/* <TableCell className="table-cel">Total</TableCell> */}
              {/* <TableCell className="table-cel"></TableCell> */}
              {/* <TableCell className="table-cel"></TableCell> */}
              {/* <TableCell className="table-cel">{impressions}</TableCell> */}
              {/* <TableCell className="table-cel">{clicksTotal}</TableCell> */}
              {/* <TableCell className="table-cel">{ctrTotal}</TableCell> */}
              {/* <TableCell className="table-cel">
                  {currency} {cpcTotal.toFixed(2)}
                </TableCell>
                <TableCell className="table-cel">
                  {currency} {cpmTotal}
                </TableCell> */}
              {/* <TableCell className="table-cel">{reachTotal}</TableCell>
                <TableCell className="table-cel">
                  {currency}
                  &nbsp;{mediaCost}
                </TableCell> */}
              {/* <TableCell className="table-cel">Total</TableCell> */}
              {/* </TableRow> */}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  authToken: state.user.authToken,
  sessionId: state.user.sessionId,
  advertiserId: state.user.advertiserId,
});

const mapDispatchToProps = {
  setAuthToken,
  setSessionId,
  setAdvertiserId,
  setImageUrl,
  setAdGroupName,
  setLogoUrl,
  setHeadline,
  setDescription,
  setVideoUrl,
  setHtmlUrl,
  setImageFile,
  setVideoFile,
  setHtmlFile,
  setCount,
};
export default connect(mapStateToProps, mapDispatchToProps)(CampaignRecord);
