import React, { FC, useEffect, useState, useRef } from 'react';
import '../../CSS/settings.css'
import '../../CSS/textfield.css'
import '../../CSS/purpleBtn.css'
import { TextField, Select, Alert, Snackbar, OutlinedInput } from '@mui/material';
import EditIcon from "../../IMAGE/editIcon.svg";
import SettingsIcon from '@mui/icons-material/Settings';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
// import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
// import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import styled from '@emotion/styled';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { apiURL, secretKey } from '../../Constant';
import { setAdvertiserId, setAuthToken, setCurrencySymbol, setMasterCountryArray, setSessionId } from '../../REDUX/Actions/userActionIndex';
import { connect } from 'react-redux';
import { RootState } from '../../REDUX/Reducers/rootReducer';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input'
import { isValidPhoneNumber } from 'react-phone-number-input/mobile'
import "react-phone-input-2/lib/style.css";
const CryptoJS = require('crypto-js')

interface SettingsScreenProps {
    currencySymbol: any;
    authToken: any;
    sessionId: any;
    advertiserId: any;
    masterCountryArray: any;
}

const CustomTextField = styled(TextField)({
    '&.MuiTextField-root input': {
        height: 25,
        paddingLeft: 0,
        paddingTop: 0,
        paddingBottom: 0,
        border: 'none',
        outline: 'none',
    },
    '& fieldset': {
        border: 'none',
        outline: 'none',
    },
    '&.Mui-focused fieldset': {
        border: 'none',
        outline: 'none',
    },
    "& .MuiFormHelperText-root": { color: 'red', paddingLeft: 0, margin: '10px 0px' },

});

const CustomSelect = styled(Select)({
    width: '100%',
    height: 10,
    padding: 0,
    '& MuiSelect': {
        height: 20,
        paddingLeft: 0,
        paddingTop: 0,
        paddingBottom: 0,
        border: 'none',
        outline: 'none',
    },
    '& fieldset': {
        padding: 0,
        border: 'none',
        outline: 'none',
    },
    '&.Mui-focused fieldset': {
        border: 'none',
        outline: 'none',
    },
});

const CustomMobileField = styled('input')({
    width: "100%",
    // height: 35,
    fontSize: "15px",
    // fontWeight: 500,
    color: "rgba(0, 0, 0, 0.38)",
    border: 'none',
    borderRadius: "2px",
    '&:focus': {
        // border: "1.4px solid rgba(51, 51, 51, 1)",
        outline: "none"
    }
});

const SettingsScreen: FC<SettingsScreenProps> = (props) => {

    const { currencySymbol, authToken, sessionId, advertiserId, masterCountryArray } = props

    const navigate = useNavigate()
    const [openSession, setOpenSession] = React.useState(false);

    const handleSessionClose = () => {
        setOpenSession(false);
    };

    const minImageSize = 70;
    const maxImageSize = 150;

    const [formState, setFormState] = useState<any>({
        name: "",
        mobile: "",
        email: "",
        orgName: "",
        taxId: "",
        country: "",
        userType: ""
    });

    const [errorMsgSnackBar, setErrorMsgSnackBar] = useState<any>("")

    const [isProfileUpdate, setProfileUpdate] = useState<boolean>(false)
    const [previousState, setPreviousState] = useState<any>({});

    const [passEnabled, setPassEnabled] = useState(false);
    const [emailOtp, setEmailOtp] = useState(false);

    const [nameEnable, setNameEnabled] = useState(false);
    const [mobileEnable, setMobileEnabled] = useState(false);
    const [emailEnable, setEmailEnabled] = useState(false);
    const [image, setImage] = useState<any | null>("")
    const [otpError, setOtpError] = useState<string>("");

    const [pass, setPass] = useState<string>("");
    const [userOtp, setUserOtp] = useState<string>("");
    const [apiOtp, setApiOtp] = useState<string>("");
    const [nameErr, setNameErr] = useState("");
    const [mobileErr, setMobileErr] = useState<string>("");
    const [emailErr, setEmailErr] = useState("");
    const [taxIdErr, setTaxIdErr] = useState("");
    const [countryErr, setCountryErr] = useState("");
    const [passErr, setPassErr] = useState("");
    const [successOpen, setSuccessOpen] = React.useState(false);
    const [errorOpen, setErrorOpen] = React.useState(false);
    const [infoOpen, setInfoOpen] = React.useState(false);
    const [id, setId] = useState<string>("");

    const [showPassword, setShowPassword] = React.useState(false);

    const inputRef = useRef<any>(null)

    const isValidMobileNumber = (value: string): boolean => {
        return (/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/.test(value))
    };

    const getUserDetails = async () => {
        try {
            let jsonParams = {
                advertiserId: advertiserId,
            };
            const encryptAdvertiserID = CryptoJS.AES.encrypt(JSON.stringify(advertiserId), secretKey).toString()

            let headers = {
                'Content-Type': 'application/json',
                'advertiserid': encryptAdvertiserID,
                'authtoken': authToken,
                'sessionid': sessionId
            };
            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(jsonParams), secretKey).toString();

            const response = await axios.post(`${apiURL}/api/user/getUser`, { params: encryptedData }, { headers: headers });
            if (response.data.status == true) {
                const resData = response.data.data;
                const bytes = CryptoJS.AES.decrypt(resData, secretKey);
                const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
                const parsedData = JSON.parse(decryptedData);
                setId(parsedData['id'])
                setFormState({
                    name: parsedData['Name'],
                    email: parsedData['Email'],
                    mobile: parsedData['MobileNumber'] == "null" ? "" : parsedData['MobileNumber'],
                    orgName: parsedData['OrganizationName'] == "null" ? "" : parsedData['OrganizationName'],
                    taxId: parsedData['TaxID'] == 'null' ? "" : parsedData['TaxID'],
                    country: parsedData['Country'],
                    userType: parsedData['UserType'],
                });

                setPreviousState({
                    name: parsedData['Name'],
                    email: parsedData['Email'],
                    mobile: parsedData['MobileNumber'] == "null" ? "" : parsedData['MobileNumber'],
                    orgName: parsedData['OrganizationName'] == "null" ? "" : parsedData['OrganizationName'],
                    taxId: parsedData['TaxID'] == 'null' ? "" : parsedData['TaxID'],
                    country: parsedData['Country'],
                    userType: parsedData['UserType'],
                })

                if (parsedData['ProfilePicture'] == null) {
                    setImage("")
                } else {
                    const uint8Array = new Uint8Array(parsedData['ProfilePicture']['data']);
                    const blob = new Blob([uint8Array]);
                    const dataUrl = URL.createObjectURL(blob);
                    setImage(dataUrl);
                    const blob2 = new Blob([Buffer.from(parsedData['ProfilePicture']['data'])], { type: 'application/octet-stream' });
                    setSelectedFile(blob2)
                }
            } else if (response.data.statuscode == 401) {
                setOpenSession(true)
                setTimeout(() => { navigate("/") }, 2000)
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getUserDetails()
    }, [])

    const handleChange = (e: any) => {
        let value = e.target.value;
        switch (e.target.name) {
            case "name":
                setNameErr("");
                // setName(value);
                setFormState({
                    name: value,
                    email: formState.email,
                    mobile: formState.mobile,
                    orgName: formState.orgName,
                    taxId: formState.taxId,
                    country: formState.country,
                    userType: formState.userType,
                });
                if (value == "" || value == null) {
                    setNameErr("Invalid full name.");
                } else {
                    // setName(value);
                    setFormState({
                        name: value,
                        email: formState.email,
                        mobile: formState.mobile,
                        orgName: formState.orgName,
                        taxId: formState.taxId,
                        country: formState.country,
                        userType: formState.userType,
                    });
                }
                break;
            case "mobile":
                setMobileErr("");
                // setMobile(value);
                setFormState({
                    name: formState.name,
                    email: formState.email,
                    mobile: value,
                    orgName: formState.orgName,
                    taxId: formState.taxId,
                    country: formState.country,
                    userType: formState.userType,
                });
                // const mobileRegex = /^[0]?[6789]\d{9}$/;
                // let regphn = /^[6-9]\d{9}$/
                // const validMobileNoRegex = /^\(?([6-9]{1})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/;
                // const validMobileNoRegexSameNumber = /(?!(\d)\1{2}\D?\1{3}\D?\1{4})\d{3}([-. ]?)\d{3}\2\d{4}/;
                if (!isValidMobileNumber(value) || value == "" || value == null) {
                    setMobileErr("Invalid Phone number.");
                } else {
                    // setMobile(value);
                    setFormState({
                        name: formState.name,
                        email: formState.email,
                        mobile: value,
                        orgName: formState.orgName,
                        taxId: formState.taxId,
                        country: formState.country,
                        userType: formState.userType,
                    });
                }
                break;
            case "email":
                setEmailErr("");
                // setEmail(value);
                setFormState({
                    name: formState.name,
                    email: value,
                    mobile: formState.mobile,
                    orgName: formState.orgName,
                    taxId: formState.taxId,
                    country: formState.country,
                    userType: formState.userType,
                });
                const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

                if (!(emailRegex.test(value)) || value == "" || value == null) {
                    setEmailErr("Invalid Email id.");
                } else {
                    // setEmail(value);
                    setFormState({
                        name: formState.name,
                        email: value,
                        mobile: formState.mobile,
                        orgName: formState.orgName,
                        taxId: formState.taxId,
                        country: formState.country,
                        userType: formState.userType,
                    });
                }
                break;
            case "countryName":
                // setCountry(value);
                setFormState({
                    name: formState.name,
                    email: formState.email,
                    mobile: formState.mobile,
                    orgName: formState.orgName,
                    taxId: formState.taxId,
                    country: value,
                    userType: formState.userType,
                });
                break;
            case "taxID":
                // setTaxIdErr("");
                // if (value == null || value.length == 0 || value == "") {
                //     setTaxIdErr("Invalid Tax ID.");
                // } else {
                //     setTaxId(value);
                // }
                // setTaxId(value);
                setFormState({
                    name: formState.name,
                    email: formState.email,
                    mobile: formState.mobile,
                    orgName: formState.orgName,
                    taxId: value,
                    country: formState.country,
                    userType: formState.userType,
                });
                break;
            case "orgName":
                setFormState({
                    name: formState.name,
                    email: formState.email,
                    mobile: formState.mobile,
                    orgName: value,
                    taxId: formState.taxId,
                    country: formState.country,
                    userType: formState.userType,
                });
                break;
            case "otp":
                setOtpError("")
                if (value == "" || value == null || value.length != 4) {
                    setOtpError("Invalid OTP")
                } else {
                    setUserOtp(value);
                }
                break;
            case "password":
                setPassEnabled(false)
                setPassErr("");
                const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
                if (!(passRegex.test(value)) || value == "" || value == null || value.length == 0) {
                    setPassErr("Invalid Password");
                } else {
                    setPass(value);
                }
                break;
            default:
                break;
        }
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [selectedFile, setSelectedFile] = useState<any>();

    const OnClickUpdate = async () => {

        if (nameErr != "" || mobileErr != "" || emailErr != "") {
            // setNameErr("Invalid Username.");
            setErrorMsgSnackBar("Invalid Details")
            setErrorOpen(true)
        } else if (previousState.name == formState.name &&
            previousState.email == formState.email &&
            previousState.mobile == formState.mobile &&
            previousState.orgName == formState.orgName &&
            previousState.country == formState.country &&
            previousState.taxId == formState.taxId && isProfileUpdate == false) {
            setInfoOpen(true)
        } else if (formState.email == "") {
            // setMobileEnabled(false)
            setErrorMsgSnackBar("Invalid Details")
            setErrorOpen(true)
        }
        // else if (passEnabled == true || emailOtp == true) {
        // setEmailErr("Invalid Email-ID.");
        // setMobileEnabled(false)
        //     setErrorOpen(true)
        // } 
        else {
            setNameEnabled(false)
            setEmailEnabled(false)
            setMobileEnabled(false)
            try {
                var bodyFormData = new FormData()
                bodyFormData.append("MobileNumber", formState.mobile)
                bodyFormData.append("Email", formState.email)
                bodyFormData.append("OrganizationName", formState.orgName)
                bodyFormData.append("Name", formState.name)
                // bodyFormData.append("Password", pass)
                bodyFormData.append("Country", formState.country)
                bodyFormData.append("profilePicture", selectedFile)
                bodyFormData.append("Advertiser_Id", advertiserId)
                bodyFormData.append("TaxID", formState.taxId)
                bodyFormData.append("UserType", formState.userType)

                const encryptAdvertiserID = CryptoJS.AES.encrypt(JSON.stringify(advertiserId), secretKey).toString()

                let headers = {
                    // 'Content-Type': 'application/json',
                    'advertiserid': encryptAdvertiserID,
                    'authtoken': authToken,
                    'sessionid': sessionId
                };
                // const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(bodyFormData), secretKey).toString();
                const response = await axios.post(`${apiURL}/api/user/editUser`, bodyFormData, { headers: headers });
                if (response.data.status == true) {
                    setSuccessOpen(true)
                } else if (response.data.statuscode == 401) {
                    setOpenSession(true)
                    setTimeout(() => { navigate("/") }, 2000)
                }
                setTimeout(() => { window.location.reload() }, 2000)
            } catch (error) {
                console.log(error);
            }
        }
    };

    const onClickGetOtp = async () => {
        await axios.get(`${apiURL}/api/getOtp?UserEmailID=${formState.email}&forEmailUpdate=true`)
            .then(res => {
                if (res.data.status == true) {
                    const resData = res.data.data;
                    const bytes = CryptoJS.AES.decrypt(resData, secretKey);
                    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
                    const parsedData = JSON.parse(decryptedData);
                    setApiOtp(parsedData.otp)
                } else if (res.data.statuscode == 401) {
                    setOpenSession(true)
                    setTimeout(() => { navigate("/") }, 2000)
                }
            })
            .catch((e) => console.log(e))
    };

    const onClickVerifyOtp = () => {
        setOtpError("")
        if (!userOtp.match(apiOtp)) {
            setOtpError("Invalid OTP")
        } else {
            setUserOtp(userOtp);
            setPassEnabled(false)
            setEmailOtp(false)
            OnClickUpdate()
        }
    };

    const handleFileChange = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            const fileSizeKB = file.size / 1024;

            if (fileSizeKB <= maxImageSize && fileSizeKB >= minImageSize) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    setImage(e.target == null ? "" : e.target.result);
                };
                reader.readAsDataURL(file);
                setSelectedFile(file);
                setProfileUpdate(true)
            } else {
                setErrorMsgSnackBar("Invalid Image Size")
                setErrorOpen(true)
            }
        }
    };

    const handleOnChange = (e: any) => {
        // setMobile("")
        if (e != undefined) {
            setMobileErr("");
            let phoneNumber: any = parsePhoneNumber(e)
            if (phoneNumber) {
                if (isValidPhoneNumber(phoneNumber.number)) {
                    setFormState((prevFilters: any) => ({
                        ...prevFilters,
                        mobile: phoneNumber.number,
                    }));
                } else {
                    setMobileErr("Invalid Mobile Number.");
                }
            } else {
                setMobileErr("Invalid Mobile Number.");
            }
        }
    };

    return (
        <div>
            <div className='main-head'>
                <SettingsIcon style={{ color: "#6900B8", marginRight: 10 }} />
                <div className='heading'>Update your Account Settings</div>
            </div>
            <div className='white-container'>
                <div className='sub-heading'>Edit Profile Picture</div>
                <div className='profile-edit'>
                    {
                        image != "" ? <img src={image} height="80px" width="80px" style={{ borderRadius: "4px" }} /> : <div className='profile-box'>
                            <FileUploadOutlinedIcon />
                            <div>Upload Picture</div>
                        </div>
                    }
                    <div>
                        <input
                            type="file"
                            id="file-input"
                            name="file-input"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                            ref={inputRef}
                            accept=".png, .jpg, .jpeg"
                        />
                        <label id="file-input-label" htmlFor="file-input" className='choose-file-button'>Choose a file</label>
                        {/* <input className='choose-file-button' type='file' onChange={onImageChange} ref={inputRef} /> */}
                        <div className='file-size-text'>Acceptable format jpg, png only <br />
                            Max file size is 500 kb and min size 70 kb <br />
                            Pixel File accepted of max size 500x500 and min 70x70
                        </div>
                    </div>
                </div>
                <div className='account-details'>
                    <div className='acc-details-column'>
                        <div className='sub-heading'>Personal Information</div>
                        <div>
                            <div className={nameEnable ? 'textfield-Box textfield-box-enable' : 'textfield-Box'}>
                                <div className='textfield-head'>
                                    <div>Enter Username</div>
                                    {nameEnable ? <div>
                                        <button className='field-update-btn' onClick={() => {
                                            if (formState.name != "" && nameErr == "") {
                                                // setName(name)
                                                // setFormState((prevState) => ({
                                                //     ...prevState,
                                                //     name: value,
                                                //   }));
                                                setNameEnabled(false)
                                                OnClickUpdate()
                                            } else {
                                                setNameErr("Invalid Username")
                                            }
                                        }}>Update</button>
                                    </div> : <div><img src={EditIcon} className="editIconButton" onClick={() => setNameEnabled(true)} /></div>}
                                </div>
                                <div>
                                    <CustomTextField
                                        className='textfield'
                                        name='name'
                                        value={formState.name}
                                        onChange={handleChange}
                                        disabled={!nameEnable}
                                        helperText={nameErr}
                                    />
                                    {/* {nameEnable ? <button className='field-update-btn' onClick={() => {
                                        if (formState.name != "" && nameErr == "") {
                                            // setName(name)
                                            // setFormState((prevState) => ({
                                            //     ...prevState,
                                            //     name: value,
                                            //   }));
                                            setNameEnabled(false)
                                            OnClickUpdate()
                                        } else {
                                            setNameErr("Invalid Username")
                                        }
                                    }}>Update</button> : <div></div>} */}
                                </div>
                            </div>
                            <div className={mobileEnable ? 'textfield-Box textfield-box-enable' : 'textfield-Box'}>
                                <div className='textfield-head'>
                                    <div>Phone Number</div>
                                    {mobileEnable ? <button className='field-update-btn' onClick={() => {
                                        if (formState.mobile != "" && mobileErr == "") {
                                            // setMobile(mobile)
                                            setMobileEnabled(false)
                                            OnClickUpdate()
                                        } else {
                                            setMobileErr("Invalid Mobile Number")
                                        }
                                    }}>Update</button> : <div><img src={EditIcon} className="editIconButton" onClick={() => setMobileEnabled(true)} /></div>}
                                </div>
                                <div>
                                    {/* <CustomTextField
                                        className='textfield'
                                        name='mobile'
                                        type='tel'
                                        value={formState.mobile}
                                        // defaultValue={mobile}
                                        onChange={handleChange}
                                        disabled={!mobileEnable}
                                        helperText={mobileErr}
                                    /> */}
                                    <PhoneInput
                                        limitMaxLength={true}
                                        focusInputOnCountrySelection
                                        // initialValueFormat="national"
                                        disabled={!mobileEnable}
                                        international
                                        countryCallingCodeEditable={false}
                                        defaultCountry="IN"
                                        value={formState.mobile}
                                        inputProps={{
                                            name: 'phone',
                                            required: true,
                                            autoFocus: true,
                                        }}
                                        onChange={handleOnChange}
                                        inputComponent={CustomMobileField}
                                    />
                                    {/* {mobileEnable ? <button className='field-update-btn' onClick={() => {
                                        if (formState.mobile != "" && mobileErr == "") {
                                            // setMobile(mobile)
                                            setMobileEnabled(false)
                                            OnClickUpdate()
                                        } else {
                                            setMobileErr("Invalid Mobile Number")
                                        }
                                    }}>Update</button> : <div></div>} */}
                                    {mobileErr != "" ? <div style={{
                                        color: "#FF0000",
                                        fontSize: "12px",
                                        margin: "10px 0px",
                                        fontFamily: "Arial",
                                    }}>{mobileErr}</div> : <div></div>}
                                </div>
                            </div>
                            <div className={emailEnable ? 'textfield-Box textfield-box-enable' : 'textfield-Box'}>
                                <div className='textfield-head'>
                                    <div>Email ID</div>
                                    {emailEnable ? <div>
                                        <button className='field-update-btn' onClick={() => {
                                            if (formState.email != "" && emailErr == "") {
                                                // setEmail(email)
                                                if (previousState.email != formState.email) {
                                                    setEmailEnabled(false)
                                                    setEmailOtp(true)
                                                    onClickGetOtp();
                                                } else {
                                                    setInfoOpen(true)
                                                    setEmailEnabled(false)
                                                }
                                            } else {
                                                setEmailErr("Invalid Email-ID")
                                            }
                                        }}>Update</button>
                                    </div> : <div><img src={EditIcon} className="editIconButton" onClick={() => setEmailEnabled(true)} /></div>}
                                </div>
                                <div>
                                    <CustomTextField
                                        className='textfield'
                                        name='email'
                                        value={formState.email}
                                        type='email'
                                        onChange={handleChange}
                                        disabled={!emailEnable}
                                        helperText={emailErr}
                                    />
                                    {/* {emailEnable ? <button className='field-update-btn' onClick={() => {
                                        if (formState.email != "" && emailErr == "") {
                                            // setEmail(email)
                                            if (previousState.email != formState.email) {
                                                setEmailEnabled(false)
                                                setEmailOtp(true)
                                                onClickGetOtp();
                                            } else {
                                                setInfoOpen(true)
                                                setEmailEnabled(false)
                                            }
                                        } else {
                                            setEmailErr("Invalid Email-ID")
                                        }
                                    }}>Update</button> : <div></div>} */}
                                </div>
                            </div>
                            {emailOtp ?
                                <div>
                                    <div style={{ color: 'rgba(105, 0, 184, 0.45)' }} className='sub-heading'>A 4-digit OTP was sent to your registered Email ID</div>
                                    <div style={{ display: 'flex' }}>
                                        <div className='textfield-Box'>
                                            <div className='textfield-head'>
                                                <div>OTP</div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <CustomTextField
                                                    className='textfield'
                                                    type='text'
                                                    // defaultValue={pass}
                                                    onChange={handleChange}
                                                    name='otp'
                                                    helperText={otpError}
                                                />
                                                <div>
                                                    <button
                                                        // aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        // edge='start'
                                                        style={{
                                                            backgroundColor: 'transparent',
                                                            border: 'none'
                                                        }}
                                                    >
                                                    </button>
                                                </div>
                                                <div>
                                                </div>
                                            </div>
                                        </div>
                                        <button disabled={userOtp.length == 4 && otpError == "" ? false : true} className={userOtp != "" && otpError == "" ? "password-btn password-btn-enable" : 'password-btn'} onClick={
                                            () => {
                                                onClickVerifyOtp();
                                            }
                                        }>Verify OTP</button>
                                    </div>
                                </div>
                                : <div></div>
                            }
                        </div>
                    </div>
                    <div className='acc-details-column'>
                        <div className='sub-heading'>Company Information</div>
                        <div>
                            <div className='textfield-Box'>
                                <div className='textfield-head'>
                                    <div>Company Information</div>
                                </div>
                                <CustomTextField
                                    className='textfield'
                                    value={formState.orgName}
                                    onChange={handleChange}
                                    name='orgName'
                                />
                            </div>
                            <div className='textfield-Box'>
                                <div className='textfield-head'>
                                    <div>Enter TAX ID</div>
                                </div>
                                <CustomTextField
                                    className='textfield'
                                    value={formState.taxId}
                                    onChange={handleChange}
                                    name='taxID'
                                />
                            </div>
                            <div className='textfield-Box'>
                                <div className='textfield-head'>
                                    <div>Select Country</div>
                                </div>
                                <CustomSelect
                                    SelectDisplayProps={{ style: { padding: 0 } }}
                                    disabled={true}
                                    value={formState.country}
                                    onChange={handleChange}
                                    IconComponent={ExpandMoreIcon}
                                    name='countryName'
                                >
                                    {masterCountryArray != null && masterCountryArray.map((item: any) => (
                                        <option key={item.id} value={item.Country}>{item.Country}</option>
                                    ))}
                                </CustomSelect>
                            </div>
                        </div>
                    </div>
                    <div className='acc-details-column' style={{ display: "none" }}>
                        <div className='sub-heading'>Security Information</div>
                        <div style={{ display: 'flex' }}>
                            <div className='textfield-Box'>
                                <div className='textfield-head'>
                                    <div>Password</div>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <CustomTextField
                                        className='textfield'
                                        type={showPassword ? 'text' : 'password'}
                                        defaultValue={pass}
                                        onChange={handleChange}
                                        name='password'
                                        helperText={passErr}
                                    />
                                    <div>
                                        <button
                                            // aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            // edge='start'
                                            style={{
                                                backgroundColor: 'transparent',
                                                border: 'none'
                                            }}
                                        >
                                            {/* {showPassword ? <VisibilityOutlinedIcon sx={{ color: '#6900B8' }} /> : <VisibilityOffOutlinedIcon sx={{ color: '#6900B8' }} />} */}
                                        </button>
                                    </div>

                                    <div>
                                    </div>
                                </div>
                            </div>
                            {/* <CustomTextField children='Password' name='password'
                                        type={showPassword ? 'text' : 'password'}
                             endAdornment={<IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOutlinedIcon sx={{color:'#6900B8'}} /> : <VisibilityOffOutlinedIcon sx={{color:'#6900B8'}}/>}
                            </IconButton>} /> */}
                            <button disabled={pass != "" && pass != null && passErr == "" ? false : true} className={pass != "" && passErr == "" ? 'password-btn-enable password-btn' : 'password-btn'} onClick={() => {
                                setPassEnabled(true)
                                onClickGetOtp();
                            }}>Update</button>
                        </div>
                        {passEnabled ?
                            <div>
                                <div style={{ color: 'rgba(105, 0, 184, 0.45)' }} className='sub-heading'>A 4-digit OTP was sent to your registered Email ID</div>
                                <div style={{ display: 'flex' }}>
                                    <div className='textfield-Box'>
                                        <div className='textfield-head'>
                                            <div>Password</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <CustomTextField
                                                className='textfield'
                                                type='text'
                                                // defaultValue={pass}
                                                onChange={handleChange}
                                                name='otp'
                                                helperText={otpError}
                                            />
                                            <div>
                                                <button
                                                    // aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    // edge='start'
                                                    style={{
                                                        backgroundColor: 'transparent',
                                                        border: 'none'
                                                    }}
                                                >
                                                </button>
                                            </div>
                                            <div>
                                            </div>
                                        </div>
                                    </div>
                                    <button disabled={userOtp.length == 4 && otpError == "" ? false : true} className={userOtp != "" && otpError == "" ? "password-btn password-btn-enable" : 'password-btn'} onClick={
                                        () => {
                                            onClickVerifyOtp();
                                        }
                                    }>Verify OTP</button>
                                </div>
                            </div>
                            : <div></div>
                        }
                    </div>
                    <div className='acc-details-column'>
                        <div className='sub-heading'>Details</div>
                        <div className='textfield-Box'>
                            <div className='textfield-head'>
                                <div>Brand ID</div>
                            </div>
                            <div >
                                <CustomTextField
                                    className='textfield'
                                    name='advertiserId'
                                    value={id}
                                    // onChange={handleChange}
                                    // disabled={!nameEnable}
                                    // helperText={nameErr}
                                    disabled
                                    style={{
                                        width: "100%"
                                    }}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ float: "right" }}>
                <button
                    className='btn-style'
                    children="Done"
                    onClick={OnClickUpdate}
                ></button>
            </div>
            <Snackbar open={errorOpen} autoHideDuration={6000} onClose={() => setErrorOpen(false)} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                <Alert onClose={() => setErrorOpen(false)} severity="error" sx={{ width: '100%' }}>
                    {errorMsgSnackBar}
                </Alert>
            </Snackbar>
            <Snackbar open={infoOpen} autoHideDuration={6000} onClose={() => setInfoOpen(false)} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                <Alert onClose={() => setInfoOpen(false)} severity="info" sx={{ width: '100%' }}>
                    Details same as previous!
                </Alert>
            </Snackbar>
            <Snackbar open={successOpen} autoHideDuration={6000} onClose={() => setSuccessOpen(false)} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                <Alert onClose={() => setSuccessOpen(false)} severity="success" sx={{ width: '100%' }} >
                    Account Details Updated!
                </Alert>
            </Snackbar>
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    currencySymbol: state.user.currencySymbol,
    authToken: state.user.authToken,
    sessionId: state.user.sessionId,
    advertiserId: state.user.advertiserId,
    masterCountryArray: state.user.masterCountryArray,
});

const mapDispatchToProps = {
    setCurrencySymbol,
    setAdvertiserId,
    setAuthToken,
    setSessionId,
    setMasterCountryArray,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsScreen);