import React, { FC, useState, ChangeEvent, useEffect } from "react";
import { RootState } from "../../REDUX/Reducers/rootReducer";

import {
  setLocation,
  setLanguage,
  setTracking,
  setTrackingUrl,
  setImpressionUrl,
  setTokenEvent,
  addTokenEventObject,
  removeTokenEventObject,
  updateToken,
  updateEvent,
  setLocationCode,
  setCountryCode,
  setPackageName,
  setImageUrl,
  setLocationCheck,
  setTransactionCurrency,
} from "../../REDUX/Actions/userActionIndex";
import { connect, useDispatch } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import "../../CSS/CampaignInfo.css";
import "../../CSS/SignIn.css";
import "../../CSS/Campaign.css";
import "../../CSS/EditCampaign.css";
import { FiChevronDown } from "react-icons/fi";
import { AiOutlineSearch } from "react-icons/ai";
import mobileApp from "../../IMAGE/mobileApp.svg";

import { languageArr, apiURL, secretKey } from "../../Constant";
import { RxCross2 } from "react-icons/rx";
import { CountriesArray, TrackingPartnerDpd } from "../../Constant";
import { RiDeleteBinLine } from "react-icons/ri";
import { BiSolidErrorCircle } from "react-icons/bi";
import axios from "axios";
import { getCountryMaster } from "../MasterDataFile";
import { Autocomplete, TextField } from "@mui/material";
import styled from "@emotion/styled";
import { makeStyles } from "@mui/styles";
const CryptoJS = require("crypto-js");

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      height: "40px",
      width: "350px",
      marginTop: "8px",
      "&:focus-within": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "grey",
          border: "1px solid grey",
        },
      },
      "& .MuiAutocomplete-input": {
        padding: "0 4px 0 5px !important",
        // width: "100px",
      },

      "&.Mui-focused .MuiAutocomplete-endAdornment .MuiSvgIcon-root": {
        color: "grey",
      },

      "& .MuiAutocomplete-endAdornment .MuiSvgIcon-root": {
        color: "grey",
      },

      "& fieldset": {
        borderColor: "grey",
        borderWidth: "2px",
        height: "40px",
      },
    },
    '& input[type="text"]:focus': {
      // content: '""',
      // display:'none',
      // border: '1px solid green',
      outline: "none",
    },
  },
}));

interface CustomButtonProps {
  onClick?: (data: boolean) => void;
  onClickBack?: () => void;
  location: string;
  language: string[];
  tracking: string;
  trackingUrl: string;
  impressionUrl: string;
  tokenEvent: {
    token: string;
    eventName: string;
  }[];
  // showLower?: boolean;
  locationCode: string;
  appName: string;
  packageName: string;
  appImageUrl: string;
  countryCode: string;
  locationCheck: string;
  countryCodeAdvertiser: string;
  masterCountryArray: string;
}

const CampaignSetting: React.FC<CustomButtonProps> = ({
  onClick,
  onClickBack,
  location,
  language,
  tracking,
  trackingUrl,
  impressionUrl,
  tokenEvent,
  locationCode,
  appName,
  packageName,
  appImageUrl,
  countryCode,
  locationCheck,
  countryCodeAdvertiser,
  masterCountryArray,
}) => {
  // const { onClick, onClickBack } = props;
  const dispatch = useDispatch();
  const [userInput, setUserInput] = useState("");
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [another, setAnother] = useState("");
  // const [selectedLang, setSelectedLang] = useState<string[]>([]);
  // Simulated function to fetch suggestions (replace with actual API call)
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [changesMade, setChangesMade] = useState<boolean>(false);
  // const [location, setLocation] = useState<string>("");
  const [showLocationDpd, setShowLocationDpd] = useState<boolean>(false);
  const [locationVal, setLocationVal] = useState<string>("");
  // const [appName, setAppName] = useState<string>("");
  const [countryArr, setCountryArr] = useState<any[]>([]);

  const CustomAutoComplete = styled(Autocomplete)({
    "& .MuiOutlinedInput-root": {
      // ".css-wb57ya-MuiFormControl-root-MuiTextField-root"
      "& .css-wb57ya-MuiFormControl-root-MuiTextField-root": {
        width: "100px",
        marginTop: "5px",
      },
      "& .Mui-focused .MuiAutocomplete-endAdornment .MuiSvgIcon-root": {
        color: "red",
      },

      "& .MuiAutocomplete-endAdornment .MuiSvgIcon-root": {
        color: "blue",
      },

      "& .MuiAutocomplete-inputRoot": {
        paddingRight: "32px",
      },

      "& fieldset": {
        borderColor: "green",
        borderWidth: "2px",
        height: "40px",
      },
    },
  });
  // const [inputData, setInputData] = useState<
  //   { token: string; eventName: string }[]
  // >([{ token: "", eventName: "" }]);

  // const [tracking, setTracking] = useState<string>("");
  // const [trackingPartner, setTrackingPartner] = useState<string>("");
  const [trackingMacros, setTrackingMacros] = useState<
    {
      id: number;
      name: string;
      displayName: string;
      value: string;
      isMandatory: boolean;
      trackingAppId: string;
      description: string;
      createdAt: string;
      updatedAt: string;
    }[]
  >([]);

  const [optionalMacros, setOptionalMacros] = useState<
    {
      id: number;
      name: string;
      displayName: string;
      value: string;
      isMandatory: boolean;
      trackingAppId: string;
      description: string;
      createdAt: string;
      updatedAt: string;
    }[]
  >([]);

  const [impressionMacro, setImpressionMacros] = useState<
    {
      id: number;
      name: string;
      displayName: string;
      value: string;
      isMandatory: boolean;
      trackingAppId: string;
      description: string;
      createdAt: string;
      updatedAt: string;
    }[]
  >([]);
  // const [impressionVal, setImpressionVal] = useState<string>("");

  const [finalClickURL, setFinalClickURL] = useState<string>("");
  const [finalURL, setFinalURL] = useState<string>("");

  const [locationError, setLocationError] = useState<string>("");
  const [languageError, setLanguageError] = useState<string>("");
  const [trackingpartnerError, setPartnerError] = useState<string>("");
  const [trackingError, settrackingError] = useState<string>("");
  const [impressionUrlError, setimpressionUrlError] = useState<string>("");
  const [tokenError, setTokenError] = useState<string>("");
  const [finalURLError, setfinalUrlError] = useState<string>("");

  const [selectedValue, setSelectedValue] = useState<any>({
    id: "",
    Country: "",
    CountryCode: "",
    Currency: "",
    Code: "",
    CurrencySymbol: "",
    BudgetLimit: "",
    isActive: "",
    createdAt: "",
    updatedAt: "",
  });
  const classes = useStyles();
  // const [selectedValue, setSelectedValue] = useState<IData | null>(null);

  const handleSelectedValueChange = (
    event: React.ChangeEvent<{}>,
    newValue: any
  ) => {
    console.log("Selected Value:", newValue);
    setSelectedValue(newValue);
    if (
      newValue != null &&
      newValue.CountryCode != null &&
      newValue.CountryCode != ""
    ) {
      // localStorage.setItem("locationCode", countryCode);
      dispatch(setLocationCode(newValue.CountryCode));
      dispatch(setCountryCode("USD"));
      dispatch(setLocation(newValue.CountryCode));
    }
  };

  const errorHandling = () => {
    let message = false;
    console.log(
      tokenEvent.length != 0,
      tokenEvent
      // tokenEvent[0].token == "" ,
      // tokenEvent[0].eventName == "" ,
      // tokenError != ""
    );
    if (location != null && location == "" && locationError == "") {
      setLocationError("Please select a location.");
    } else if (
      language != null &&
      language.length == 0 &&
      languageError == ""
    ) {
      setLanguageError("Please enter a Language.");
      return;
    } else if (
      tracking != null &&
      tracking == "" &&
      trackingpartnerError == ""
    ) {
      setPartnerError("Please select a tracking partner");
      return;
    } else if (
      trackingUrl != null &&
      trackingUrl == "" &&
      trackingError == ""
    ) {
      settrackingError("Please enter a tracking URL");
      return;
    }
    //  else if (
    //   impressionVal != null &&
    //   impressionVal == "" &&
    //   impressionUrlError == ""
    // ) {
    //   setimpressionUrlError("Please enter a Impression Url.");
    //   return;
    // }
    else if (tokenEvent.length != 0) {
      if (
        tokenEvent[0].token == "" ||
        tokenEvent[0].eventName == ""
        //  ||tokenError == ""
      ) {
        message = false;
        setTokenError(
          "Please enter Token and event name If not want to add then remove it."
        );
      } else if (
        locationError == "" &&
        languageError == "" &&
        trackingpartnerError == "" &&
        trackingError == ""
      ) {
        if (locationCode == "IN") {
          dispatch(setTransactionCurrency("INR"));
          if (onClick) {
            message = true;
            onClick(message);
          }
        } else {
          dispatch(setTransactionCurrency("USD"));
          if (onClick) {
            message = true;
            onClick(message);
          }
        }
      }
    }
    //  else if (finalClickURL != null && finalClickURL == "") {
    //   setfinalUrlError("Please enter a finalUrl");
    // }
    else if (
      locationError == "" &&
      languageError == "" &&
      trackingpartnerError == "" &&
      trackingError == ""
      // && tokenError == ""
    ) {
      // console.log("transactionCurrency",transactionCurrency)
      if (locationCode == "IN") {
        dispatch(setTransactionCurrency("INR"));
        if (onClick) {
          message = true;
          onClick(message);
        }
      } else {
        dispatch(setTransactionCurrency("USD"));
        if (onClick) {
          message = true;
          onClick(message);
        }
      }
    }
  };

  const onClickTrackingpartner = () => {
    setIsClicked(true);
  };

  const onChangeTrackingPartner = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const val = event.target.value;

    dispatch(setTracking(val));
    setPartnerError("");
    setChangesMade(true);
    localStorage.setItem("trackingPartner", val);
  };

  const OnChangeFinalClickURL = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    let val = event.target.value;
    // setfinalUrlError("");
    setFinalClickURL(val);
    if (isValidURL(val)) {
      setfinalUrlError("");
    } else {
      setfinalUrlError("Please enter a valid url");
    }
    localStorage.setItem("finalClickURL", val);
  };

  const OnChangeFinalURL = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    let val = event.target.value;
    setFinalURL(val);
    localStorage.setItem("finalURL", val);
  };

  const onChangeImpression = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    let val = event.target.value;
    localStorage.setItem("finalClickURL", val);
    dispatch(setImpressionUrl(val));
    setimpressionUrlError("");
    // if (isValidURL(val)) {
    //   setimpressionUrlError("");
    // } else {
    // }
    let searchTerm = "impression";
    // console.log(isValidURL(val));
    if (tracking == "appsflyer") {
      // console.log("here")
      if (
        val.length > 10 &&
        isValidURL(val) &&
        val.includes(searchTerm) &&
        val.includes("impression.appsflyer.com")
      ) {
        return;
      } else {
        setimpressionUrlError("Please enter a valid URL");
      }
    } else if (val.length > 10 && isValidURL(val) && val.includes(searchTerm)) {
      return;
    } else {
      setimpressionUrlError("Please enter a valid URL");
    }
  };
  // const paramArray: string[] = [];
  // const url = new URL(val);
  // Access the search parameters using the URL object
  // const urlSearchParams = url.searchParams;
  // Iterate through the parameters
  // urlSearchParams.forEach((value, key) => {
  //   // console.log(`${key}: ${value}`);
  //   if (!paramArray.includes(key)) {
  //     paramArray.push(key);
  //     // console.log(`Value ${value} added to the array.`);
  //   }
  // });
  // const trackingArray: string[] = [];
  // impressionMacro.map((val) => {
  //   // (); // Returns 'value1'
  //   if (!trackingArray.includes(val.value)) {
  //     trackingArray.push(val.value);
  //     // console.log(`Value ${value} added to the array.`);
  //   }
  // });
  // // console.log(paramArray, trackingArray);
  // const allValuesPresent = trackingArray.every((value) =>
  //   paramArray.includes(value)
  // );
  // console.log(allValuesPresent);
  // if (allValuesPresent) {
  //   setimpressionUrlError("");
  // } else {
  //   // console.log("here")
  //   setimpressionUrlError(`Please enter all Mandatory macros.`);
  // }
  // const searchParams = new URLSearchParams(val);
  // const clickId = searchParams.get("click_id");
  // const googleAid = searchParams.get("google_aid");
  // const deviceIp = searchParams.get("device_ip");
  // const publisherId = searchParams.get("afpub_id");
  // const pubAppName = searchParams.get("pub_appname");
  // const trackingData = [
  //   { name: "Click ID", value: clickId },
  //   { name: "Android Advertising ID (GAID)", value: googleAid },
  //   { name: "Device IP", value: deviceIp },
  //   { name: "Publisher ID", value: publisherId },
  //   { name: "Publisher App Name", value: pubAppName },
  // ];

  // const trackingMacrosDataA = trackingData.filter(
  //   (item) => item.value !== null
  // ) as { name: string; value: string }[];

  // Update the trackingMacros state based on conditions
  // setImpressionMacros(trackingMacrosDataA);

  //IMPRESSION MACRO
  // localStorage.setItem(
  //   "ImpressionMacros",
  //   JSON.stringify(trackingMacrosDataA)
  // );

  const checkImpressionUrl = (val: string) => {
    // let val = event.target.value;
    localStorage.setItem("finalClickURL", val);
    dispatch(setImpressionUrl(val));
    setimpressionUrlError("");
    // if (isValidURL(val)) {
    //   setimpressionUrlError("");
    // } else {
    // }
    let searchTerm = "impression";
    // console.log(isValidURL(val));
    if (tracking == "appsflyer") {
      // console.log("here")
      if (
        val.length > 10 &&
        isValidURL(val) &&
        val.includes(searchTerm) &&
        val.includes("impression.appsflyer.com")
      ) {
        return;
      } else {
        setimpressionUrlError("Please enter a valid URL");
      }
    } else if (val.length > 10 && isValidURL(val) && val.includes(searchTerm)) {
      return;
    } else {
      setimpressionUrlError("Please enter a valid URL");
    }
  };
  function isValidURL(url: string) {
    // const urlPattern =
    //   /^(https?:\/\/)?([a-z0-9-]+\.)*[a-z0-9-]+\.[a-z]{2,}(:[0-9]+)?(\/.*)?$/i;
    const urlPattern =
      /^(https?:\/\/)?([a-z0-9-]+\.)*[a-z0-9-]+\.[a-z]{2,}(:[0-9]+)?(\/[^?#]*)?(\?[^?#]*)?(#.*)?$/i;

    return urlPattern.test(url);
  }

  const checkTrackingUrl = (val: string) => {
    // let val = event.target.value;
    localStorage.setItem("trackingUrl", val);
    dispatch(setTrackingUrl(val));
    settrackingError("");
    // if (isValidURL(val)) {
    // }
    // console.log(val.length > 10, isValidURL(val), val.includes("appsflyer"));
    if (tracking == "appsflyer") {
      if (val.length > 10 && isValidURL(val) && val.includes("appsflyer")) {
        return;
      } else {
        // console.log("tracking");
        settrackingError("Please enter a valid URL.");
      }
    } else if (tracking == "branch") {
      if (
        val.length > 10 &&
        isValidURL(val) &&
        val.includes("app.link")
        //  && val.includes("%243p")
      ) {
        return;
      } else {
        settrackingError("Please enter a valid URL.");
      }
    } else if (tracking == "singular") {
      if (val.length > 10 && isValidURL(val) && val.includes("sng")) {
        return;
      } else {
        settrackingError("Please enter a valid URL.");
      }
    } else if (tracking == "adjust") {
      if (val.length > 10 && isValidURL(val) && val.includes("adjust")) {
        return;
      } else {
        settrackingError("Please enter a valid URL.");
      }
    } else if (tracking == "appmetrica") {
      if (val.length > 10 && isValidURL(val) && val.includes("appmetrica")) {
        return;
      } else {
        settrackingError("Please enter a valid URL.");
      }
    }
    // else if()
    if (val.length > 10 && isValidURL(val)) {
      return;
      // const paramArray: string[] = [];
      // const url = new URL(val);
      // Access the search parameters using the URL object
      // const urlSearchParams = url.searchParams;
      // Iterate through the parameters
      // urlSearchParams.forEach((value, key) => {
      //   // console.log(`${key}: ${value}`);
      //   if (!paramArray.includes(key)) {
      //     paramArray.push(key);
      //     // console.log(`Value ${value} added to the array.`);
      //   }
      // });

      // const trackingArray: string[] = [];
      // trackingMacros.map((val) => {
      //   // (); // Returns 'value1'
      //   if (!trackingArray.includes(val.value)) {
      //     trackingArray.push(val.value);
      //     // console.log(`Value ${value} added to the array.`);
      //   }
      // });
      // // console.log(paramArray, trackingArray);
      // const allValuesPresent = trackingArray.every((value) =>
      //   paramArray.includes(value)
      // );
      // console.log(allValuesPresent);
      // if (allValuesPresent) {
      //   settrackingError("");
      // } else {
      //   // console.log("here")
      //   settrackingError(`Please enter all Mandatory macros.`);
      // }
    } else {
      settrackingError("Please enter a valid URL.");
    }
    // console.log(val.length, val.slice(0, -val.length + 4));

    if (val.length >= 5 && val.slice(0, -val.length + 4) == "http") {
      localStorage.setItem("trackingUrl", val);
    } else if (val.length >= 4 && val.slice(0, -val.length + 3) == "www") {
      const adding = "https://" + val;
      localStorage.setItem("trackingUrl", adding);
    }

    // const searchParams = new URLSearchParams(val);
    // const clickId = searchParams.get("click_id");
    // const googleAid = searchParams.get("google_aid");
    // const deviceIp = searchParams.get("device_ip");
    // const publisherId = searchParams.get("afpub_id");
    // const pubAppName = searchParams.get("pub_appname");
    // // console.log("clickId",searchParams);
    // const trackingData = [
    //   { name: "Click ID", value: clickId },
    //   { name: "Android Advertising ID (GAID)", value: googleAid },
    //   { name: "Device IP", value: deviceIp },
    //   { name: "Publisher ID", value: publisherId },
    //   { name: "Publisher App Name", value: pubAppName },
    // ];

    // const trackingMacrosDataA = trackingData.filter(
    //   (item) => item.value !== null
    // ) as { name: string; value: string }[];

    // // Update the trackingMacros state based on conditions
    // localStorage.setItem("trackingMacros", JSON.stringify(trackingMacrosDataA));
    // setTrackingMacros(trackingMacrosDataA);

    const optionalData = [
      { name: "Android Advertising ID (GAID)", value: "googleAid" },
    ];

    // const optionalMacrosData = optionalData.filter(
    //   (item) => item.value !== null
    // ) as { name: string; value: string }[];
    localStorage.setItem(
      "TrackingOptionalMacros",
      JSON.stringify(optionalData)
    );
    // setOptionalMacros(optionalMacrosData);
  };

  const onChangeTracking = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    let val = event.target.value;
    localStorage.setItem("trackingUrl", val);
    dispatch(setTrackingUrl(val));
    settrackingError("");
    // if (isValidURL(val)) {
    // }
    // console.log(val.length > 10, isValidURL(val), val.includes("appsflyer"));
    if (tracking == "appsflyer") {
      if (val.length > 10 && isValidURL(val) && val.includes("appsflyer")) {
        return;
      } else {
        // console.log("tracking");
        settrackingError("Please enter a valid URL.");
      }
    } else if (tracking == "branch") {
      if (
        val.length > 10 &&
        isValidURL(val) &&
        val.includes("app.link")
        //  && val.includes("%243p")
      ) {
        return;
      } else {
        settrackingError("Please enter a valid URL.");
      }
    } else if (tracking == "singular") {
      if (val.length > 10 && isValidURL(val) && val.includes("sng")) {
        return;
      } else {
        settrackingError("Please enter a valid URL.");
      }
    } else if (tracking == "adjust") {
      if (val.length > 10 && isValidURL(val) && val.includes("adjust")) {
        return;
      } else {
        settrackingError("Please enter a valid URL.");
      }
    } else if (tracking == "appmetrica") {
      if (val.length > 10 && isValidURL(val) && val.includes("appmetrica")) {
        return;
      } else {
        settrackingError("Please enter a valid URL.");
      }
    }
    // else if()
    if (val.length > 10 && isValidURL(val)) {
      return;
      // const paramArray: string[] = [];
      // const url = new URL(val);
      // Access the search parameters using the URL object
      // const urlSearchParams = url.searchParams;
      // Iterate through the parameters
      // urlSearchParams.forEach((value, key) => {
      //   // console.log(`${key}: ${value}`);
      //   if (!paramArray.includes(key)) {
      //     paramArray.push(key);
      //     // console.log(`Value ${value} added to the array.`);
      //   }
      // });

      // const trackingArray: string[] = [];
      // trackingMacros.map((val) => {
      //   // (); // Returns 'value1'
      //   if (!trackingArray.includes(val.value)) {
      //     trackingArray.push(val.value);
      //     // console.log(`Value ${value} added to the array.`);
      //   }
      // });
      // // console.log(paramArray, trackingArray);
      // const allValuesPresent = trackingArray.every((value) =>
      //   paramArray.includes(value)
      // );
      // console.log(allValuesPresent);
      // if (allValuesPresent) {
      //   settrackingError("");
      // } else {
      //   // console.log("here")
      //   settrackingError(`Please enter all Mandatory macros.`);
      // }
    } else {
      settrackingError("Please enter a valid URL.");
    }
    // console.log(val.length, val.slice(0, -val.length + 4));

    if (val.length >= 5 && val.slice(0, -val.length + 4) == "http") {
      localStorage.setItem("trackingUrl", val);
    } else if (val.length >= 4 && val.slice(0, -val.length + 3) == "www") {
      const adding = "https://" + val;
      localStorage.setItem("trackingUrl", adding);
    }

    // const searchParams = new URLSearchParams(val);
    // const clickId = searchParams.get("click_id");
    // const googleAid = searchParams.get("google_aid");
    // const deviceIp = searchParams.get("device_ip");
    // const publisherId = searchParams.get("afpub_id");
    // const pubAppName = searchParams.get("pub_appname");
    // // console.log("clickId",searchParams);
    // const trackingData = [
    //   { name: "Click ID", value: clickId },
    //   { name: "Android Advertising ID (GAID)", value: googleAid },
    //   { name: "Device IP", value: deviceIp },
    //   { name: "Publisher ID", value: publisherId },
    //   { name: "Publisher App Name", value: pubAppName },
    // ];

    // const trackingMacrosDataA = trackingData.filter(
    //   (item) => item.value !== null
    // ) as { name: string; value: string }[];

    // // Update the trackingMacros state based on conditions
    // localStorage.setItem("trackingMacros", JSON.stringify(trackingMacrosDataA));
    // setTrackingMacros(trackingMacrosDataA);

    const optionalData = [
      { name: "Android Advertising ID (GAID)", value: "googleAid" },
    ];

    // const optionalMacrosData = optionalData.filter(
    //   (item) => item.value !== null
    // ) as { name: string; value: string }[];
    localStorage.setItem(
      "TrackingOptionalMacros",
      JSON.stringify(optionalData)
    );
    // setOptionalMacros(optionalMacrosData);
  };

  // handle input boxes
  const handleAddInputBox = () => {
    setTokenError("");
    dispatch(addTokenEventObject());
  };

  const handleRemoveInputBox = (index: number) => {
    setTokenError("");
    dispatch(removeTokenEventObject(index));
  };

  // const handleTokenChange = (value: string, index: number) => {
  //   const updatedInputBoxes = [...token];
  //   updatedInputBoxes[index] = value;
  //   updatedInputBoxes;
  //   setToken(updatedInputBoxes);
  //   localStorage.setItem("token", JSON.stringify(updatedInputBoxes));
  // };

  // const handleEventChange = (value: string, index: number) => {
  //   const updatedInputBoxes = [...eventName];
  //   updatedInputBoxes[index] = value;
  //   updatedInputBoxes;
  //   setEventName(updatedInputBoxes);
  //   localStorage.setItem("eventName", JSON.stringify(updatedInputBoxes));
  // };
  const handleTokenEventChange = (
    value: string,
    index: number,
    field: "token" | "eventName"
  ) => {
    setTokenError("");

    if (field == "token") {
      dispatch(updateToken(index, value));
    } else {
      dispatch(updateEvent(index, value));
    }

    // setInputData((prevData) => {
    //   const updatedData = [...prevData];
    //   updatedData[index][field] = value;
    //   return updatedData;
    // });
  };

  useEffect(() => {
    localStorage.setItem("inputTokenEventData", JSON.stringify(tokenEvent));
  }, [tokenEvent]);

  useEffect(() => {
    console.log(tokenEvent);
    // onChangeCheckbox(locationCheck);
    const countryMaster = getCountryMaster();
    // console.log("locationCode",locationCode)
    setCountryArr([...masterCountryArray]);
    if (locationCode != null && locationCode != "" && locationCode != "ALL") {
      const locationName = countryMaster.filter((val: any) => {
        return val.CountryCode == locationCode;
      });
      if (locationName.length != 0) {
        const val = {
          id: locationName[0].id,
          Country: locationName[0].Country,
          CountryCode: locationName[0].CountryCode,
          Currency: locationName[0].Currency,
          Code: locationName[0].Code,
          CurrencySymbol: locationName[0].CurrencySymbol,
          BudgetLimit: locationName[0].BudgetLimit,
          isActive: locationName[0].isActive,
          createdAt: locationName[0].createdAt,
          updatedAt: locationName[0].updatedAt,
        };
        // console.log(locationName);
        setLocationVal(locationName[0].CountryCode);
        setSelectedValue(val);
      }
      // setCountryArr(countryMaster);
    } else if (locationCode == "") {
      const locationName = countryMaster.filter((val: any) => {
        return val.CountryCode == "US";
      });
      if (locationName.length != 0) {
        const val = {
          id: locationName[0].id,
          Country: locationName[0].Country,
          CountryCode: locationName[0].CountryCode,
          Currency: locationName[0].Currency,
          Code: locationName[0].Code,
          CurrencySymbol: locationName[0].CurrencySymbol,
          BudgetLimit: locationName[0].BudgetLimit,
          isActive: locationName[0].isActive,
          createdAt: locationName[0].createdAt,
          updatedAt: locationName[0].updatedAt,
        };
        // console.log(locationName);
        dispatch(setLocationCode(val.CountryCode));
        dispatch(setCountryCode("USD"));
        dispatch(setLocation(val.CountryCode));
        setSelectedValue(val);
      }
    }
    // console.log(locationName, locationCode == "");

    // checkTrackingUrl(trackingUrl);
    // checkImpressionUrl(impressionUrl);
    // const location = localStorage.getItem("locationValue") || "";
    // const selectedLanguage = localStorage.getItem("selectedLang") || "";
    // const appNamefetched = localStorage.getItem("appLookup") || "";
    // const trackingPartner = localStorage.getItem("trackingPartner") || "";
    // const finalClickurl = localStorage.getItem("finalClickURL") || "";
    // const trackingurl = localStorage.getItem("trackingUrl") || "";
    // const tokenEvent = localStorage.getItem("inputTokenEventData") || "";
    // const converted =
    //   selectedLanguage != "" ? JSON.parse(selectedLanguage) : [];
    // const convertedToken =
    //   tokenEvent != ""
    //     ? JSON.parse(tokenEvent)
    //     : [{ token: "", eventName: "" }];

    // console.log(tokenEvent);
    // setAppName(appNamefetched);
    // // localStorage.setItem("inputTokenEventData", "");
    // const item = localStorage.getItem("editingData") || "";
    // if (item != "") {
    //   const data = JSON.parse(item);
    //   console.log("Campaign Setting", data.campaignData);

    //   // localStorage.setItem("locationCode", data.campaignData.Location);
    //   dispatch(setLocationCode(data.campaignData.Location));
    //   dispatch(setPackageName(data.campaignData.AppLookUpDetails.packageName));
    //   // localStorage.setItem(
    //   //   "packageName",
    //   //   data.campaignData.AppLookUpDetails.packageName
    //   // );
    //   dispatch(setImageUrl(data.campaignData.AppLookUpDetails.logoUrl));
    //   // localStorage.setItem(
    //   //   "appIcon",
    //   //   data.campaignData.AppLookUpDetails.logoUrl
    //   // );
    //   // localStorage.setItem(
    //   //   "trackingPartner",
    //   //   trackingPartner == ""
    //   //     ? data.campaignData.TrackingPartner
    //   //     : trackingPartner
    //   // );
    //   dispatch(
    //     setTracking(
    //       tracking == "" ? data.campaignData.TrackingPartner : tracking
    //     )
    //   );
    //   // localStorage.setItem(
    //   //   "locationValue",
    //   //   location == "" ? data.campaignData.Location : location
    //   // );
    //   dispatch(
    //     setLocation(location == "" ? data.campaignData.Location : location)
    //   );
    //   // localStorage.setItem(
    //   //   "selectedLang",
    //   converted.length == 0
    //     ? JSON.stringify(
    //         // data.campaignData.Languages.length == 0
    //         //   ? converted
    //         //   :
    //         data.campaignData.Languages
    //       )
    //     : JSON.stringify(converted);
    //   // );
    //   dispatch(
    //     setLanguage(
    //       converted.length == 0
    //         ? // data.campaignData.Languages.length == 0
    //           //   ? converted
    //           //   :
    //           data.campaignData.Languages
    //         : converted
    //     )
    //   );
    //   dispatch(
    //     setTokenEvent(
    //       tokenEvent == ""
    //         ? // localStorage.setItem(
    //           //   "inputTokenEventData",
    //           //   tokenEvent == ""
    //           //     ? JSON.stringify(
    //           //         data.campaignData.Token || [{ token: "", eventName: "" }]
    //           //       )
    //           //     : tokenEvent
    //           // );
    //           data.campaignData.Token || [{ token: "", eventName: "" }]
    //         : tokenEvent
    //     )
    //   );

    //   dispatch(setTrackingUrl(trackingurl == "" ? data.campaignData.TrackingURL : trackingurl))

    //   // localStorage.setItem(
    //   //   "trackingUrl",
    //   //   trackingurl == "" ? data.campaignData.TrackingURL : trackingurl
    //   // );

    //   dispatch(
    //     setTracking(
    //       trackingPartner == ""
    //         ? data.campaignData.TrackingPartner
    //         : trackingPartner
    //     )
    //   );
    //   onChangeCheckbox(data.campaignData.Location || "");
    //   if (location == "") {
    //     if (
    //       data.campaignData.Location === "ALL" ||
    //       data.campaignData.Location === "IN"
    //     ) {
    //       setShowLocationDpd(false);
    //       setLocationVal(data.campaignData.Location);
    //       dispatch(setLocation(data.campaignData.Location));
    //     } else if (data.campaignData.Location == "") {
    //       setShowLocationDpd(false);
    //     } else {
    //       setShowLocationDpd(true);
    //       setLocationVal(data.campaignData.Location);
    //       dispatch(setLocation("another"));
    //     }
    //   } else {
    //     if (location === "ALL" || location === "IN") {
    //       setShowLocationDpd(false);
    //       setLocationVal(location);
    //       dispatch(setLocation(location));
    //     } else if (location == "") {
    //       setShowLocationDpd(false);
    //     } else {
    //       setShowLocationDpd(true);
    //       setLocationVal(location);

    //       dispatch(setLocation("another"));
    //     }
    //   }

    //   dispatch(
    //     setLanguage(
    //       converted.length == 0
    //         ? data.campaignData.Languages.length == 0
    //           ? converted
    //           : data.campaignData.Languages
    //         : converted
    //     )
    //   );
    //   convertedToken.length == 1
    //     ? data.campaignData.Token || dispatch(addTokenEventObject())
    //     : convertedToken.map((value: any, index: number) => {
    //         dispatch(updateToken(index, value.token));
    //         dispatch(updateEvent(index, value.eventName));
    //       });

    //   dispatch(
    //     setTrackingUrl(
    //       trackingurl == "" ? data.campaignData.TrackingURL : trackingurl
    //     )
    //   );
    //   // console.log(changesMade , trackingurl , data.campaignData.TrackingURL)

    //   dispatch(
    //     setImpressionUrl(
    //       finalClickurl == ""
    //         ? data.campaignData.FinalClickURL || ""
    //         : finalClickurl
    //     )
    //   );

    //   if (data.campaignData.TrackingPartner != null && trackingPartner == "") {
    //     dispatch(setTracking(data.campaignData.TrackingPartner));
    //   } else {
    //     dispatch(setTracking(trackingPartner));
    //   }
    // } else {
    //   dispatch(setTracking(trackingPartner));
    //   dispatch(setImpressionUrl(finalClickurl));
    //   dispatch(setTrackingUrl(trackingurl));
    //   convertedToken.map((value: any, index: number) => {
    //     dispatch(updateToken(index, value.token));
    //     dispatch(updateEvent(index, value.eventName));
    //   });
    //   // setInputData(convertedToken);
    //   // setSelectedLang([...selectedLang, suggestion]);
    //   // setSelectedLang(converted);
    //   dispatch(setLanguage(converted));
    //   if (location === "ALL" || location === "IN") {
    //     setShowLocationDpd(false);
    //     setLocationVal(location);
    //     dispatch(setLocation(location));
    //   } else if (location == "") {
    //     setShowLocationDpd(false);
    //   } else {
    //     setShowLocationDpd(true);
    //     setLocationVal(location);
    //     dispatch(setLocation("another"));
    //   }
    // }
    // const campSubtype = localStorage.getItem("") || "" ;
    // const appPlatform = localStorage.getItem("appPlatform") || "";
    // const campaignName = localStorage.getItem("campaignName") || "";
    apiMacros();
  }, []);

  const apiMacros = async () => {
    const authToken = localStorage.getItem("authToken") || "";
    const sessionID = localStorage.getItem("sessionID") || ""; // console.log(startDate,endDate)
    const advertiserId = localStorage.getItem("advertiserID") || "";
    // const advertiserId = localStorage.getItem("advertiserID") || "";
    const encryptAdvertiserID = CryptoJS.AES.encrypt(
      JSON.stringify(advertiserId),
      secretKey
    ).toString();

    const url = `${apiURL}/api/data/getMasterData`;
    const header = {
      headers: {
        authtoken: authToken,
        sessionid: sessionID,
        advertiserid: encryptAdvertiserID, // Example authorization header
      },
    };
    await axios
      .get(url, header)
      .then((res) => {
        if (res.data) {
          const resData = res.data.data;
          const bytes = CryptoJS.AES.decrypt(resData, secretKey);
          const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
          const parsedData = JSON.parse(decryptedData);

          // console.log(parsedData);
          const macroData = parsedData.macros;
          localStorage.setItem("ImpressionMacros", JSON.stringify(macroData));
          localStorage.setItem("trackingMacros", JSON.stringify(macroData));
          setTrackingMacros(macroData);
          setImpressionMacros(macroData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChangeCheckbox = (label: string) => {
    localStorage.setItem("locationValue", label);
    setLocationError("");
    // console.log(label)
    if (label == "another") {
      setShowLocationDpd(true);
      // dispatch(setLocation(label));
      // setAnother(label)
      setLocationVal("");
      dispatch(setLocationCheck(label));
    } else {
      // setAnother("")
      dispatch(setLocationCheck(label));
      setShowLocationDpd(false);
      setLocationVal(label);
      dispatch(setLocation(label));
      // localStorage.setItem("locationCode", label);
      dispatch(setLocationCode(label));
      if (label == "IN") {
        dispatch(setTransactionCurrency("INR"));
        dispatch(setCountryCode("INR"));
      } else {
        dispatch(setTransactionCurrency("USD"));
        dispatch(setCountryCode("USD"));
      }
    }
  };

  const handleSlectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = event.target.selectedOptions[0];
    const countryCode = selectedOption.getAttribute("data-country-code");
    // const currencyCode = countryArr.filter((val:any)=>{
    //  return val.CountryCode == countryCode
    // })
    // console.log(currencyCode)
    onChangeCheckbox("another");
    // console.log(currencyCode)
    // localStorage.setItem("locationValue", event.target.value);
    setLocationVal(event.target.value);
    console.log(event.target.value);
    if (countryCode != null && countryCode != "") {
      // localStorage.setItem("locationCode", countryCode);
      dispatch(setLocationCode(countryCode));
      dispatch(setCountryCode("USD"));
      dispatch(setLocation(event.target.value));
    }
    // Use the countryCode as the additional value
    // console.log("Selected Country Code:", countryCode);
  };

  const fetchSuggestions = (input: string) => {
    // Simulated array of suggestions
    // Filter suggestions based on user input
    const filteredSuggestions = languageArr.filter((suggestion: string) =>
      suggestion.toLowerCase().includes(input.toLowerCase())
    );

    setSuggestions(filteredSuggestions);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setUserInput(value);
    fetchSuggestions(value);
  };

  const handleSuggestionClick = (suggestion: string) => {
    setUserInput("");
    setLanguageError("");
    if (!language.includes(suggestion)) {
      dispatch(setLanguage([...language, suggestion]));
    }
    // localStorage.setItem(
    //   "selectedLang",
    //   JSON.stringify([...language, suggestion])
    // );
    setSuggestions([]);
  };
  const removeLanguage = (valueToRemove: string) => {
    const updatedArray = language.filter((item) => item !== valueToRemove);
    dispatch(setLanguage(updatedArray));
  };

  const MacroDataAdd = (data: string) => {
    let datatoAdd = "";
    const urlObject = new URL(trackingUrl);
    // Check if there are any search parameters
    if (urlObject.searchParams.has(data)) {
      return;
    } else {
      if (trackingUrl !== "") {
        if (trackingUrl.includes("?") && trackingUrl.includes("&")) {
          datatoAdd = `${trackingUrl}&${data}=[]`;
        } else if (trackingUrl.includes("?")) {
          datatoAdd = `${trackingUrl}&${data}=[]`;
        } else {
          datatoAdd = `${trackingUrl}?${data}=[]`;
        }

        dispatch(setTrackingUrl(datatoAdd));
      }
    }
    const paramArray: string[] = [];
    const url = new URL(datatoAdd);
    // Access the search parameters using the URL object
    const urlSearchParams = url.searchParams;
    // Iterate through the parameters
    urlSearchParams.forEach((value, key) => {
      // console.log(`${key}: ${value}`);
      if (!paramArray.includes(key)) {
        paramArray.push(key);
        // console.log(`Value ${value} added to the array.`);
      }
    });
    const trackingArray: string[] = [];
    impressionMacro.map((val) => {
      // (); // Returns 'value1'
      if (!trackingArray.includes(val.value)) {
        trackingArray.push(val.value);
        // console.log(`Value ${value} added to the array.`);
      }
    });
    const allValuesPresent = trackingArray.every((value) =>
      paramArray.includes(value)
    );
    // console.log(allValuesPresent);
    if (allValuesPresent) {
      settrackingError("");
    } else {
      // console.log("here")
      settrackingError(`Please enter all Mandatory macros.`);
    }
  };

  const impressionDataAdd = (data: string) => {
    let datatoAddImpression = "";

    const urlObject = new URL(impressionUrl);
    // Check if there are any search parameters
    if (urlObject.searchParams.has(data)) {
      return;
    } else {
      if (impressionUrl !== "") {
        if (impressionUrl.includes("?") && impressionUrl.includes("&")) {
          datatoAddImpression = `${impressionUrl}&${data}=[]`;
        } else if (impressionUrl.includes("?")) {
          datatoAddImpression = `${impressionUrl}&${data}=[]`;
        } else {
          datatoAddImpression = `${impressionUrl}?${data}=[]`;
        }

        dispatch(setImpressionUrl(datatoAddImpression));
      }
    }
  };

  const appImage = localStorage.getItem("appIcon")!;
  return (
    <div>
      <div className="campaign-box" style={{ padding: "15px 0px 20px 8px " }}>
        <h1 className="campaign-subtype">Mobile app</h1>
        <div className="first-container">
          {/* <img src={mobileApp} /> */}

          {appImageUrl != "" ? (
            <div style={{ display: "flex" }}>
              <img
                src={appImageUrl}
                height="46px"
                width="46px"
                style={{ borderRadius: "3px" }}
              />
            </div>
          ) : (
            <div></div>
          )}
          <div className="app-para" style={{ marginLeft: "12px" }}>
            <p style={{ marginBottom: "8px" }}>{appName}</p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <a className="app-url" href="https://www.google.com/">
                {packageName}
              </a>{" "}
              {/* &nbsp;- */}
              {/* <p>Gyrodile Network</p> */}
            </div>
          </div>
        </div>
      </div>

      <div className="campaign-box" style={{ padding: "15px 0px 20px 8px " }}>
        <h1 className="campaign-subtype">Location</h1>
        <div>
          {/* <div className="checkbox-app" style={{ alignItems: "center" }}>
            <Checkbox
              {...label}
              className="chk"
              checked={locationCheck === "ALL"}
              onChange={() => onChangeCheckbox("ALL")}
              style={{ color: customCheckBoxColor }}
            />
            <div className="para-container">
              <p
                className="campaign-subtype"
                style={{ fontWeight: 500, alignItems: "center" }}
              >
                All countries and territories
              </p>
            </div>
          </div>
          <div className="checkbox-app" style={{ alignItems: "center" }}>
            <Checkbox
              {...label}
              className="chk"
              checked={locationCheck === "IN"}
              onChange={() => onChangeCheckbox("IN")}
              style={{ color: customCheckBoxColor }}
            />
            <div className="para-container">
              <p
                className="campaign-subtype"
                style={{ fontWeight: 500, alignItems: "center" }}
              >
                India
              </p>
            </div>
          </div>
          <div className="checkbox-app" style={{ alignItems: "center" }}>
            <Checkbox
              {...label}
              className="chk"
              checked={locationCheck === "another"}
              onChange={() => onChangeCheckbox("another")}
              
              style={{ color: customCheckBoxColor }}
            />
            <div className="para-container">
              <p
                className="campaign-subtype"
                style={{ fontWeight: 500, alignItems: "center" }}
              >
                Enter another Location
              </p>
            </div>
          </div> */}
          {/* {showLocationDpd ?( */}
          <div className="pwd-box country-box input-margins">
            <label style={{ fontSize: "12px", opacity: 0.6 }}>
              Select your Location
            </label>
            <Autocomplete
              classes={{ root: classes.root }}
              options={countryArr}
              getOptionLabel={(options) => options.Country}
              value={selectedValue}
              onChange={handleSelectedValueChange}
              isOptionEqualToValue={(options, value) =>
                options.CountryCode === value.CountryCode
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
              // {...props}
            />
            {/* <div className="hide-button" style={{ width: "35vh" }}>
              <select
                style={{
                  position: "relative",
                  width: "35vh",
                  height: "38px",
                }}
                onChange={(e) => handleSlectChange(e)}
                value={locationVal}
              >
                {countryArr.map((item) => (
                  <option
                    key={item.id}
                    value={item.CountryCode}
                    data-country-code={item.CountryCode}
                  >
                    {item.Country}
                  </option>
                ))}
              </select>
              <FiChevronDown
                className="down-icon"
                style={{ top: "15px", right: "15px" }}
              />
            </div> */}
          </div>

          {/* <div className="hide-button" style={{ width: "35vh" }}> */}

          {/* </div> */}

          {/* ) : null} */}
        </div>
        {locationError != "" ? (
          <div className="error-box" style={{ width: "50%", margin: 0 }}>
            <BiSolidErrorCircle className="error-icon" />
            <p>{locationError}</p>
          </div>
        ) : null}
      </div>

      <div className="campaign-box" style={{ padding: "15px 0px 20px 8px " }}>
        <h1 className="campaign-subtype">Language</h1>
        <div className="">
          <h4 className="purple-heading" style={{ color: "black" }}>
            Select a language your customers speak
          </h4>
          <div className="search-icon-box">
            <AiOutlineSearch
              className="search-icon"
              style={{ position: "relative" }}
            />
            <input
              className="search-input"
              type="text"
              placeholder="start typing or select a language"
              value={userInput}
              onChange={handleInputChange}
            />
          </div>
          <ul
            className="dropdown-menu"
            style={{
              height: "17vh",
              overflow: "auto",
              background: "transparent",
            }}
          >
            {suggestions.map((suggestion, index) => (
              <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                {suggestion}
              </li>
            ))}
          </ul>
          <h1 className="campaign-subtype" style={{ margin: "25px 0 15px 0" }}>
            Selected Languages
          </h1>
          <div style={{ display: "flex" }}>
            {language.map((item) => (
              <button className="purple-btn language-selected" key={item}>
                {item}
                <RxCross2
                  className="cancel-icon"
                  onClick={() => removeLanguage(item)}
                />
              </button>
            ))}
          </div>
        </div>
      </div>
      {languageError != "" ? (
        <div className="error-box" style={{ width: "50%", margin: 0 }}>
          <BiSolidErrorCircle className="error-icon" />
          <p>{languageError}</p>
        </div>
      ) : null}
      <div className="campaign-box" style={{ padding: "15px 0px 20px 8px " }}>
        <h1 className="campaign-subtype">Tracking</h1>

        <h4
          className="purple-heading"
          style={{ color: "black", marginBottom: "5px" }}
        >
          Conversion tracking
        </h4>
        <p className="light-para-conversion">
          Users clicking on your ad, will be redirected to the store through
          your tracking URL. Make sure to use a valid tracking URL, generated by
          any of the <br /> supported attribution companies.
        </p>
        <div className="divider-line" style={{ margin: "15px 0" }}></div>

        <div className="pwd-box country-box input-margins">
          <label>Select your tracking partner</label>
          <div className="hide-button" style={{ width: "26%" }}>
            <select
              style={{ position: "relative", height: "38px" }}
              onChange={onChangeTrackingPartner}
              value={tracking}
              onClick={onClickTrackingpartner}
            >
              {tracking == "" ? (
                <option key={0} value={""}>
                  Please select a value
                </option>
              ) : null}
              {TrackingPartnerDpd.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.name}
                </option>
              ))}
            </select>
            <FiChevronDown
              className="down-icon"
              style={{ top: "15px", right: "12px" }}
            />
          </div>
          {trackingpartnerError != "" ? (
            <div className="error-box" style={{ width: "50%", margin: 0 }}>
              <BiSolidErrorCircle className="error-icon" />
              <p>{trackingpartnerError}</p>
            </div>
          ) : null}
        </div>

        <div className="divider-line" style={{ margin: "15px 0" }}></div>
        <div>
          <h1 className="campaign-subtype">Tracking</h1>
          <div className="textarea-box">
            <p style={{ fontSize: "12px", opacity: 0.6 }}>
              Make sure to enable view through attribution in the integrated
              partner's platform.
            </p>
            <textarea
              id="paragraphInput"
              rows={6}
              //  cols={40}
              value={trackingUrl}
              onChange={onChangeTracking}
              placeholder="Insert your tracking URL and include the mandatory macros"
            ></textarea>
          </div>
        </div>
        {trackingError != "" ? (
          <div className="error-box" style={{ width: "50%", margin: 0 }}>
            <BiSolidErrorCircle className="error-icon" />
            <p>{trackingError}</p>
          </div>
        ) : null}
        <div className="eg-container">
          <h3>EXAMPLE :</h3> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <p>
            https://redirect.appmetrica.yandex.com/serve/TRACKING_ID?&
            <br />
            click_id=xapnt1x&google_aid=xapnt6x&device_ip=xapnt5x&device_ua=
            {"apnt_httpuseragent"}& <br />
            click_timestamp={"apnt_clicktimestamp"}
            &afpub_id=xapnt17x&site_id=xapnt20x&creative_id={"apnt_crtname"}&
            <br />
            c_id={"apnt_campid"}&c_name={"apnt_campname"}
            &pub_appname=xapnt21x&package={"apnt_anpackage"}
            {"apnt_iosid"}
          </p>
        </div>
        <div className="divider-line" style={{ margin: "15px 0" }}></div>

        {/* <div className="macros-container">
          <div className="macros-box" style={{ marginRight: "20px" }}>
            {trackingMacros.length != 0 ? <h2>Mandatory macros:</h2> : null}
            {trackingMacros.map((item) => (
              <div className="btn-container">
                <button
                  onClick={() => MacroDataAdd(item.value)}
                  style={{ cursor: "pointer" }}
                >
                  {item.value}
                </button>
                <h5>{item.displayName}</h5>
              </div>
            ))}
          </div>

          <div className="macros-box">
            {optionalMacros.length != 0 ? <h2>Optional macros:</h2> : null}
            {optionalMacros.map((item) => (
              <div className="btn-container">
                <button>{item.value}</button>
                <h5>{item.name}</h5>
              </div>
            ))}

          </div>
        </div> */}

        {/* <div className="divider-line"></div> */}

        <div className="textarea-box">
          <p style={{ fontSize: "12px", opacity: 0.6 }}>
            <b>Impression URL</b>
            &nbsp; (Make sure to enable view through attribution in the
            integrated partner's platform.)(Optional)
          </p>
          <textarea
            id="paragraphInput"
            rows={6}
            //  cols={40}
            placeholder="Insert your tracking URL and include the mandatory macros"
            value={impressionUrl}
            onChange={onChangeImpression}
          ></textarea>
        </div>
        {impressionUrlError != "" ? (
          <div className="error-box" style={{ width: "50%", margin: 0 }}>
            <BiSolidErrorCircle className="error-icon" />
            <p>{impressionUrlError}</p>
          </div>
        ) : null}

        {/* <div className="macros-container">
          <div className="macros-box" style={{ marginRight: "20px" }}>
            <h2>Mandatory macros:</h2>
            {impressionMacro.map((item) => (
              <div className="btn-container">
                <button onClick={() => impressionDataAdd(item.value)}>
                  {item.value}
                </button>
                <h5>{item.name}</h5>
              </div>
            ))}
          </div>
        </div> */}

        <div className="add-new">
          <div className="token-container">
            <div className="label-input">
              <label>Token</label>
              {tokenEvent.map((value, index) => (
                <input
                  key={index}
                  type="text"
                  placeholder="Token"
                  value={value.token}
                  onChange={(e) =>
                    handleTokenEventChange(e.target.value, index, "token")
                  }
                />
              ))}
            </div>
            <div className="label-input">
              <label>Event Name</label>
              {tokenEvent.map((value, index) => (
                <input
                  key={index}
                  type="text"
                  placeholder="Event Name"
                  value={value.eventName}
                  onChange={(e) =>
                    handleTokenEventChange(e.target.value, index, "eventName")
                  }
                />
              ))}
            </div>
            <div style={{ margin: "22px 0 0 0" }}>
              {tokenEvent.map((value, index) => (
                <div
                  key={index}
                  className="delete-btn"
                  onClick={() => handleRemoveInputBox(index)}
                >
                  <RiDeleteBinLine className="delete-icon" />
                  <h4>Remove</h4>
                </div>
              ))}
            </div>
          </div>
          <button onClick={handleAddInputBox} className="add-btn">
            Add New
          </button>
        </div>
        {tokenError != "" ? (
          <div className="error-box" style={{ width: "50%", margin: 0 }}>
            <BiSolidErrorCircle className="error-icon" />
            <p>{tokenError}</p>
          </div>
        ) : null}
        {/* <div className="divider-line"></div> */}

        {/* <div className="textarea-box">
          <p style={{ fontSize: "12px", opacity: 0.6 }}>
            <b>Final Click URL</b>
            &nbsp; (Preview your postback URL and save it.)
          </p>
          <textarea
            id="paragraphInput"
            rows={6}
            //  cols={40}
            placeholder="Insert your tracking URL and include the mandatory macros"
            value={finalClickURL}
            onChange={OnChangeFinalClickURL}
          ></textarea>
        </div> */}
        {/* {finalURLError != "" ? (
          <div className="error-box" style={{ width: "50%", margin: 0 }}>
            <BiSolidErrorCircle className="error-icon" />
            <p>{finalURLError}</p>
          </div>
        ) : null} */}

        {/* <div className="divider-line"></div>

        <div className="textarea-box">
          <p style={{ fontSize: "12px", opacity: 0.6 }}>
            <b>Final Click URL</b>
          </p>
          <textarea
            id="paragraphInput"
            rows={6}
            //  cols={40}
            placeholder="Insert your tracking URL and include the mandatory macros"
            value={finalURL}
            onChange={OnChangeFinalURL}
          ></textarea>
        </div> */}
      </div>

      <div className="doneButton-box">
        <button className="border-btn" onClick={onClickBack}>
          Cancel
        </button>
        <button className="purple-btn width-adjust" onClick={errorHandling}>
          Next
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  location: state.user.location,
  language: state.user.language,
  tracking: state.user.tracking,
  trackingUrl: state.user.trackingUrl,
  impressionUrl: state.user.impressionUrl,
  tokenEvent: state.user.tokenEvent,
  locationCode: state.user.locationCode,
  appName: state.user.appName,
  packageName: state.user.packageName,
  appImageUrl: state.user.appImageUrl,
  countryCode: state.user.countryCode,
  locationCheck: state.user.locationCheck,
  countryCodeAdvertiser: state.user.countryCodeAdvertiser,
  masterCountryArray: state.user.masterCountryArray,
});
const mapDispatchToProps = {
  setLanguage,
  setLocation,
  setTracking,
  setTrackingUrl,
  setImpressionUrl,
  setTokenEvent,
  addTokenEventObject,
  removeTokenEventObject,
  updateToken,
  updateEvent,
  setLocationCode,
  setCountryCode,
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignSetting);
